<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Юный фотохудожник»
		</h2>
		<p class="EventsPage-date">
			с 12 июля по 01 августа 2023 года.
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/21336-programma-yunyi-fotokhudozhnik"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит еженедельные курсы по программе «Юный фотохудожник» для учащихся
			общеобразовательных организаций Иркутской области с 10 до 17 лет.
		</p>

		<p class="EventsPage-text">
			Актуальность программы «Юный фотохудожник» заключается в приобщении учащихся к фотографии во всех её аспектах,
			привитии художественного вкуса, что, в свою очередь, оказывает благоприятное воздействие на формирование
			нравственных качеств, развитие эстетических чувств, творческого самовыражения; помогает адаптироваться в сложной
			обстановке современного мира, а также в последующем самоопределении.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Говорова Анастасия Андреевна, специалист по связям с общественностью Государственного автономного нетипового
				учреждения дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных
				детей «Персей».
			</li>
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, заведующий отделом информационно-технического обеспечения Государственного
				автономного нетипового учреждения дополнительного образования Иркутской области «Региональный центр выявления и
				поддержки одаренных детей «Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Для участников, прошедших отбор на курсы, необходима обязательная регистрация в системе Навигатор
				дополнительного образования Иркутской области по ссылке:
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21336-programma-yunyi-fotokhudozhnik.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>