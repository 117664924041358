<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			18.12.2023
		</p>
		<h2 class="NewsPage-title">
			Анатомия человека и экологическое просвещение. Итоги дистанционных курсов «Персея»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage35.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В октябре этого года в Образовательном центре «Персей» стартовали два дистанционных курса по науке: «Экологическое просвещение: от теории к практике» и «Погружение внутрь: основы анатомии человека». Участие в программах приняли более 60 школьников Приангарья.
			</p>
			<p class="NewsPage-sub-title">
				На занятиях курса по экологическому просвещению участники проводили исследования, связанные с воздухом, водой и почвой. Программа также была направлена на привлечение внимания к важным экологическим проблемам нашего времени и формирования экологического сознания школьников.
			</p>
			<p class="NewsPage-sub-title">
				Курс «Погружение внутрь: основы анатомии человека» предлагал  углубленное изучение различных систем и органов человеческого тела, а также понимание их функционирования и взаимосвязей.
			</p>
			<p class="NewsPage-sub-title">
				В рамках программы школьники познакомились с основными анатомическими терминами, структурами и системами органов человека, а также изучили макроскопическую и микроскопическую анатомию, включая кости, мышцы, суставы, органы и системы органов.
			</p>
			<p class="NewsPage-sub-title">
				Продолжат изучать физиологию человека школьники уже на профильной смене, которая стартовала 15 декабря в кампусе Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				Это первая программа в центре для школьников, которые хотят связать свою жизнь с медициной. На программе ребята смогут   получить актуальные знания и умения в области кардиологии, хирургии, травматологии и неврологии, а также научатся оказывать первую медицинскую помощь и проводить лабораторные исследования.
			</p>
			
		</div>
	</div>
</template>
