<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage27.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В четвертый сезон Всероссийской образовательной инициативы «Сириус. Лето: начни свой проект» включились 130 участников из Приангарья
			</h2>
			<p class="NewsCard-date">
				21.11.2023
			</p>
		</div>
	</div>
</template>