<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Беседы о хореографическом искусстве»
		</h2>
		<p class="EventsPage-date">
			26 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24584-programma-besedy-o-khoreograficheskom-iskusstve" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/mod/feedback/view.php?id=908" target="_blank">
			Moodle
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребенка» проводит курсы с использованием дистанционных образовательных технологий <b>«Беседы о хореографическом искусстве»</b>
		</p>

		<p class="EventsPage-text">
			Программа «Беседы о хореографическом искусстве» направлена на развитие творческих способностей детей в области хореографических дисциплин, формирование знаний, практических умений и навыков по истории развития танца в целом, истории развития народного танца и истории развития классического танца, формирование устойчивого интереса к самостоятельной деятельности в сфере хореографического искусства.
		</p>

		<p class="EventsPage-text">
			В образовательной программе могут принять участие обучающиеся в возрасте 10-17 лет образовательных организаций Иркутской области и участники творческих хореографических коллективов. Освоение программы требует от обучающихся знакомства с системой начальной ступени российского хореографического образования.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться в системе Навигатор дополнительного образования Иркутской области по ссылке: <a style="color:blue" href="https://р38.навигатор.дети/program/24584-programma-besedy-o-khoreograficheskom-iskusstve">https://р38.навигатор.дети/program/24584-programma-besedy-o-khoreograficheskom-iskusstve</a>
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться на сайте ОЦ «Персей» и <b>обязательно заполнить АНКЕТУ</b> по ссылке: <a style="color:blue" href="https://moodle.perseusirk.ru/mod/feedback/view.php?id=908">https://moodle.perseusirk.ru/mod/feedback/view.php?id=908</a>
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>