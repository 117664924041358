<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Оркестровое струнно-смычковое исполнительство»
		</h2>
		<p class="EventsPage-date">
			с 03 по 16 июля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=164" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24937-programma-orkestrovoe-strunno-smychkovoe-ispolnitelstvo"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену
			«Оркестровое струнно-смычковое исполнительство» для обучающихся организаций дополнительного образования по
			направлению инструментальное струнно-смычковое исполнительство от 10 до 17 лет, заявившие в добровольном порядке
			свое намерение участвовать в мероприятиях смены и проявивших себя в творческой деятельности в области
			инструментального исполнительства, а также, обладающими основными музыкальными способностями: слух, ритм, память,
			исполнительские навыки игры на струнных инструментах (скрипка, альт, виолончель, контрабас), а также начальными
			навыками игры в ансамбле.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает наличие определенных знаний:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				знание основных исторических сведений о струнном оркестре;
			</li>
			<li class="JuniorProfi-items">
				знание состава струнного оркестра, виды инструментов, группы оркестра;
			</li>
			<li class="JuniorProfi-items">
				правила по уходу, хранению и эксплуатации инструмента:
			</li>
			<li class="JuniorProfi-items">
				основных обозначений и музыкальных терминов;
			</li>
			<li class="JuniorProfi-items">
				состава партитуры, роли и типа своей партии;
			</li>
			<li class="JuniorProfi-items">
				основных средств музыкальной выразительности.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Наличие определенных умений:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				исполнять музыкальные произведения на достаточном художественном уровне в соответствии с их стилевыми
				особенностями;
			</li>
			<li class="JuniorProfi-items">
				слушать и слышать всю партитуру оркестра;
			</li>
			<li class="JuniorProfi-items">
				быстро переходить из одного эмоционального состояния в другое при смене жанра произведений;
			</li>
			<li class="JuniorProfi-items">
				тонко понимать интонацию: мотив, фразу в строении музыкальной формы;
			</li>
			<li class="JuniorProfi-items">
				контролировать синхронность совместной игры;
			</li>
			<li class="JuniorProfi-items">
				умение понимать жест дирижёра.
			</li>
		</ul>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Акимова Татьяна Юрьевна, Заслуженный работник культуры и искусства Иркутской области, заведующая
				предметно-цикловой комиссии «Оркестровые струнные инструменты» Иркутского областного музыкального колледжа
				им.Ф.Шопена, художественный руководитель и дирижер струнного и симфонического оркестров колледжа, преподаватель
				высшей квалификационной категории
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
			Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе
				электронной информационно-образовательной среды сайта ОЦ «Персей» до 21 июня 2024 года по ссылке: <a
					style="color:blue" href="https://moodle.perseusirk.ru/course/view.php?id=164">
					Moodle</a>
			</li>
			<li class="JuniorProfi-items">
				видео с исполнением 2-х разнохарактерных произведений;
			</li>
			<li class="JuniorProfi-items">
				портфолио с Дипломами за участие в конкурсах регионального и всероссийского уровня за 2023-2024 год.
			</li>
			<li class="JuniorProfi-items">
				Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке: <a style="color:blue"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24937-programma-orkestrovoe-strunno-smychkovoe-ispolnitelstvo">Навигатор</a>
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 25 июня 2024г. в 19.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1716015146">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Антипов Данила Владимирович</li>
			<li class="EventsPage-number">Артюх Таисия Родионовна</li>
			<li class="EventsPage-number">Баженова Серафина Павловна</li>
			<li class="EventsPage-number">Белова Софья Денисовна</li>
			<li class="EventsPage-number">Бушмакин Кирилл Александрович</li>
			<li class="EventsPage-number">Гранина Мария Юрьевна</li>
			<li class="EventsPage-number">Дорофеева Ксения Александровна</li>
			<li class="EventsPage-number">Ерёмина Адэль Сергеевна</li>
			<li class="EventsPage-number">Клещова Леся Алексеевна</li>
			<li class="EventsPage-number">Коваль Сафинез Файсаловна</li>
			<li class="EventsPage-number">Круль Лев Янович</li>
			<li class="EventsPage-number">Лекечинскайте Ангелина Антоновна</li>
			<li class="EventsPage-number">Луневская Катерина Сергеевна</li>
			<li class="EventsPage-number">Медвецкий Всеволод Алексеевич</li>
			<li class="EventsPage-number">Муратов Богдан Евгеньевич</li>
			<li class="EventsPage-number">Назырова Ника Анваровна</li>
			<li class="EventsPage-number">Нарицина Екатерина Владимировна</li>
			<li class="EventsPage-number">Нижник Арина Евгеньевна</li>
			<li class="EventsPage-number">Плотникова София Алексеевна</li>
			<li class="EventsPage-number">Попова Алёна Денисовна</li>
			<li class="EventsPage-number">Рыбакова Олеся Сергеевна</li>
			<li class="EventsPage-number">Шаркаева Валерия Александровна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>