<template>
	<div class="Training _cover">
		<h2 class="Training-title _cover-title">
			Повышение квалификации
		</h2>

		<a class="JuniorProfi-link" href="https://sochisirius.ru/edu/pedagogam">
			Образовательные программы для педагогов Сириус
		</a>

		<p class="Sirius_Artificial_Intelligence-text">
			ГАНУ ДО ИО Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» предоставляет
			широкий спектр образовательных услуг педагогическим и руководящим работникам образовательных организаций
			дошкольного, общего и дополнительного, образования. Мы предлагаем широкий выбор образовательных программ, в
			которых задействованы преподаватели-практики с высоким уровнем теоретической подготовки и многолетним опытом
			работы в системе ДПО.
		</p>
		<p class="Sirius_Artificial_Intelligence-text">
			При реализации дополнительных профессиональных программ осуществляется постоянный мониторинг удовлетворенности
			слушателей качеством образования и информирование общественности о полученных результатах. На основании полученных
			результатов сотрудники образовательного центра выявляют проблемы и определяют пути повышения эффективности
			курсовой подготовки.
		</p>

		<!-- Дополнительная профессиональная программа повышения квалификации -->
		<div class="Period">
			<h2 class="Period-title _cover-title">
				Дополнительная профессиональная программа повышения квалификации
			</h2>

			<ul class="grid-container">
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage161' })">
						<EventsCard161></EventsCard161>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage162' })">
						<EventsCard162></EventsCard162>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage163' })">
						<EventsCard163></EventsCard163>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage164' })">
						<EventsCard164></EventsCard164>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage165' })">
						<EventsCard165></EventsCard165>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage166' })">
						<EventsCard166></EventsCard166>
					</a>
				</li>
			</ul>
		</div>

	</div>
</template>

<script>
import EventsCard161 from '../EventsCard/EventsCard161';
import EventsCard162 from '../EventsCard/EventsCard162';
import EventsCard163 from '../EventsCard/EventsCard163';
import EventsCard164 from '../EventsCard/EventsCard164';
import EventsCard165 from '../EventsCard/EventsCard165';
import EventsCard166 from '../EventsCard/EventsCard166';



	export default {
		components: {
			EventsCard161,
			EventsCard162,
			EventsCard163,
			EventsCard164,
			EventsCard165,
			EventsCard166,

		}
	}
</script>