<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			Региональный конкурс «Лучшие практики работы с одаренными детьми»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			1 марта – 30 апреля 2022 г.
		</h4>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr />

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Учредитель Конкурса
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор конкурса
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<h3 class="EventsPage-prof">
			О конкурсе
		</h3>
		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/11/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B8-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%B4%D0%B5%D1%82%D1%8C%D0%BC%D0%B8.pdf" target="_blank">
			Положение — Лучшие практики работы с одаренными детьми
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/11/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-1-2-%D0%BA-%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8E.docx" target="_blank">
			Приложение 1, 2 к Положению
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%B8%D1%82%D0%BE%D0%B3%D0%B8-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B8.pdf" target="_blank">
			Приказ итоги Лучшие практики
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%9F%D1%80%D0%B8%D0%BB.-%D0%BA-%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7%D1%83-%D0%9B%D1%83%D1%87.%D0%BF%D1%80%D0%B0%D0%BA%D1%82..pdf" target="_blank">
			Прил. к приказу Луч.практ.
		</a>
		<p class="EventsPage-text">
			Цель конкурса — выявление и поддержка лучших практик работы с одаренными, талантливыми и высокомотивированными детьми.
		</p>
		<hr />

		<p class="JuniorProfi-sub-title">
			Для участников:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Принять участие в конкурсе могут педагогические и руководящие работники образовательных организаций, реализующих образовательные программы начального общего образования, основного общего образования, среднего общего образования, среднего профессионального образования, дополнительные общеразвивающие программы и дополнительные предпрофессиональные программы.
			</li>
			<li class="JuniorProfi-items">
				Допускается подача заявки индивидуально и от коллектива, не превышающего 3 (трех) человек.
			</li>
			<li class="JuniorProfi-items">
				В качестве лучших практик работы с одаренными, талантливыми и высокомотивированными детьми в рамках данного положения следует понимать следующие учебно-методические материалы:
			</li>
			<li class="JuniorProfi-items">
				методические рекомендации;
			</li>
			<li class="JuniorProfi-items">
				дидактические материалы.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Номинации конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными детьми общеобразовательной организации»;
			</li>
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными обучающимися организации дополнительного образования детей».
			</li>
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными обучающимися профессиональной образовательной организации».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Этапы конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Отборочный (заочный) этап – оценка документов на основании перечня критериев и показателей, применяемых при проведении конкурса. Срок проведения – с 1 марта по 10 апреля 2022 года.
			</li>
			<li class="JuniorProfi-items">
				заключительный этап – очное (онлайн) представление лучших практик по работе с одаренными детьми через платформу Zoom. Срок проведения – с 11 апреля по 30 апреля 2022 года.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Лучшие практики по итогам конкурса
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%9E%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D0%BC%D0%B8%D1%81%D1%8F-%D0%B2-%D1%88%D0%B0%D1%85%D0%BC%D0%B0%D1%82%D0%BD%D0%BE%D0%BC-%D0%BE%D0%B1%D1%8A%D0%B5%D0%B4%D0%BD%D0%B8%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8-%D0%AE%D0%B4%D0%B8%D0%BD-%D0%93.%D0%9C-%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B5%D0%B2-%D0%A1.%D0%92.-%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B5%D0%B2-%D0%90.%D0%92.pdf" target="_blank">
			Образовательная практика работы с одаренными обучающимися в шахматном объеднинении, Юдин Г.М, Ковалев С.В., Ковалев А.В
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%CC%88%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%B4%D0%B5%D1%82%D1%8C%D0%BC%D0%B8-%D0%BF%D1%80%D0%B8-%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B5-%D0%BA-%D0%BF%D1%80%D0%B5%D0%B4%D0%BC%D0%B5%D1%82%D0%BD%D0%BE%D0%B8%CC%86-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B5-%D0%BF%D0%BE-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%BE%D0%BC%D1%83-%D1%8F%D0%B7%D1%8B%D0%BA%D1%83-%D0%94%D1%80%D0%B0%D1%87%D0%B5%D0%BD%D0%B8%D0%BD%D0%B0-%D0%A1.%D0%92.-%D0%9A%D0%BE%D0%BD%D1%8B%D1%88%D0%B5%D0%B2%D0%B0-%D0%95.%D0%91..pdf" target="_blank">
			Система работы с одарёнными детьми при подготовке к предметной олимпиаде по русскому языку, Драченина С.В., Конышева Е.Б.
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%AD%D0%B2%D1%80%D0%B8%D1%81%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BF%D1%80%D0%B8-%D0%B8%D0%B7%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D0%B8-%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8-XVII-%D0%B2%D0%B5%D0%BA%D0%B0-%D0%A1%D0%B5%D0%BD%D0%B8%D0%BD%D0%B0.%D0%9E.%D0%98.pdf" target="_blank">
			Эвристические задания при изучении истории России XVII века, Сенина.О.И
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%98%D0%BD%D0%B4%D0%B8%D0%B2%D0%B8%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B8%CC%86-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B8%CC%86-%D0%BC%D0%B0%D1%80%D1%88%D1%80%D1%83%D1%82-%D0%BA%D0%B0%D0%BA-%D1%84%D0%BE%D1%80%D0%BC%D0%B0-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%B4%D0%B5%D1%82%D1%8C%D0%BC%D0%B8-%D0%B2-%D0%B4%D0%BE%D0%BF.-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8-%D0%9F%D0%B0%D0%BD%D1%86%D0%B5%D0%B2%D0%B8%D1%87-%D0%95.%D0%9F.-%D0%A7%D1%83%D1%80%D1%83%D0%BA%D1%81%D0%B0%D0%B5%D0%B2%D0%B0-%D0%A2.%D0%AF.pdf" target="_blank">
			Индивидуальный образовательный маршрут как форма работы с одаренными детьми в доп. образовании, Панцевич Е.П., Чуруксаева Т.Я
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%9B%D0%B8%D0%BD%D0%B3%D0%B2%D0%B8%D1%81%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B8%CC%86-%D1%84%D0%B5%D1%81%D1%82%D0%B8%D0%B2%D0%B0%D0%BB%D1%8C-%D1%82%D0%B2%D0%BE%D1%80%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0-%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%BE%D0%B3%D0%BE-%D0%92%D0%B0%D0%B2%D0%B8%D0%BB%D0%BE%D0%BD-%D0%A8%D0%B5%D1%81%D1%82%D0%B0%D0%BA%D0%BE%D0%B2%D0%B0-%D0%A1.%D0%92.pdf" target="_blank">
			Лингвистический фестиваль творчества детского Вавилон, Шестакова С.В
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%9B%D1%83%D1%87%D1%88%D0%B0%D1%8F-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0-%D1%81-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%B4%D0%B5%D1%82%D1%8C%D0%BC%D0%B8-%D0%B2-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D1%86%D0%BE%D0%B2%D0%BE%D0%B8%CC%86-%D1%82%D0%B5%D0%B0%D1%82%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B8%CC%86-%D1%81%D1%82%D1%83%D0%B4%D0%B8%D0%B8-%D0%97%D0%B5%D1%80%D0%BA%D0%B0%D0%BB%D0%BE-%D0%9F%D0%BE%D0%BB%D0%B5%D1%81%D1%8C%D0%B5-%D0%9A.%D0%92.-%D0%A1%D0%B0%D0%B2%D0%B0%D1%82%D0%B5%D0%B5%D0%B2%D0%B0-%D0%90.%D0%A1.-%D0%A4%D0%B5%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%B0-%D0%9B.%D0%92..pdf" target="_blank">
			Лучшая практика с одаренными детьми в образцовой театральной студии Зеркало, Полесье К.В., Саватеева А.С., Федорова Л.В.
		</a>

	</div>
</template>
