<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			16.10.2023
		</p>
		<h2 class="NewsPage-title">
			КООРДИНАТОРЫ И НАСТАВНИКИ СИРИУС.ЛЕТО О ТОМ, КАК РЕАЛИЗОВАТЬ БОЛЬШОЙ ПРОЕКТ И РАЗВИВАТЬ ПРЕЕМСТВЕННОСТЬ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage14.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				До 30 сентября продолжается прием заявок от студентов – наставников проекта «Сириус.Лето». Студенты старших курсов бакалавриата и специалитета, магистранты и аспиранты будут помогать ребятам в поиске лучших решений актуальных задач в сферах технологий, науки и бизнеса. Доцент кафедры менеджмента и сервиса БГУ Оксана Сараева является координатором программы «Сириус.Лето» в Байкальском государственном университете. Она занимается отбором кандидатов в наставники и в дальнейшем сопровождает студентов. При этом в университете складывается своего рода система преемственности: когда школьники, в прошлые годы участвовавшие в программе, становятся наставниками, а бывшие наставники – экспертами, предлагающими новые задачи.
			</p>
			<p class="NewsPage-sub-title">
				Оксана Сараева включилась в программу «Сириус.Лето» в 2020 году. Первым проектом, над которым она работала вместе с командой в программе «Сириус.Лето», стал «Магазин будущего». Такую задачу участникам предложили специалисты иркутской компании «Слата» – одного из лидеров розничной торговли региона. Школьникам нужно было разработать современное мобильное приложение для мерчендайзеров.
			</p>
			<p class="NewsPage-sub-title">
				«Ребята работали над приложением, используя нейросети, продумали концепцию и схему функционирования “умных полок”, разрабатывали сити-фермы, предложили технологию геймификации для адаптации новых сотрудников и многое другое. Вся работа проходила в тесной коммуникации с сотрудниками компании. Мы решали реальные задачи, взаимодействовали со специалистами, выступали с отчетами перед топ-менеджерами о проделанной работе. Важно, что к ребятам относились как к состоявшимся специалистам, задавали серьезные вопросы без скидки на возраст», – рассказывает Оксана Сараева.
			</p>
			<p class="NewsPage-sub-title">
				За три сезона программы «СириусЛето:начни свой проект» разные команды, которые курировала Оксана, представили 17 проектных решений.
			</p>
			<p class="NewsPage-sub-title">
				«Очень важно, чтобы работа над проектом не останавливалась с завершением каждого сезона Сириус.Лето. Ребята по собственной инициативе продолжали развивать идею, тестировать и совершенствовать свой продукт и дальше. Я, как куратор, учу этому наставников – поддерживать интерес команды в течение длительного времени. Нередко для качественной проработки проекта требуется больше времени, чем стандартный учебный год. Моя задача – сделать так, чтобы и студенты, и школьники относились к своей работе с энтузиазмом и неподдельным интересом. Только так можно добиться результата», – говорит наставник.
			</p>
			<p class="NewsPage-sub-title">
				В прошлом сезоне программы Оксана вместе с проектной командой начала работать над одним из таких масштабных проектов. Команда взялась за разработку профориентационной нейросети ProfSmart. Примечательно, что на всех этапах работы команду консультирует генеральный директор компании по автоматизации HR-систем ООО «Hurma» Максим Демин.
			</p>
			<p class="NewsPage-sub-title">
				«У современных школьников не всегда есть возможность получить достаточно информации о будущей профессии и карьерной траектории. Большинство существующих методик профессионального самоопределения субъективны и не адаптированы к современным условиям. Суть нашего проекта в создании профориентационной нейросети, целью которой является помощь школьникам, студентам и взрослым в осуществлении профессионального выбора быстро и качественно. В настоящий момент активно идет процесс обучения нейросети, которая интегрирована в чат-бот в Telegram», – делится Оксана.
			</p>
			<p class="NewsPage-sub-title">
				По задумке, система предложит пользователю пройти уникальный комплекс тестов, связанный с профессиональными предпочтениями, темпераментом, типом мышления.  Пройти тестирования на профориентацию можно будет по направлениям «Управление и экономика», «Точные и естественные науки», «Медицина и здравоохранение», «Медиа, дизайн и архитектура», «Безопасность», «Гуманитарные и социальные науки», «Искусство и творчество», «Информационные технологии», «Техника и технологии».
			</p>
			<p class="NewsPage-sub-title">
				Чтобы успешно доработать все масштабные проекты, многие наставники участвуют в Сириус.Лето не один год. Это позволяет не только реализовать все задачи, но и развивать управленческие навыки.
			</p>
			<p class="NewsPage-sub-title">
				«Благодаря проекту я нашла действительно близкое дело – наставничество. Мне очень интересно помогать ребятам включаться и реализовать полезные идеи. Моя первая и вторая специальность связана с менеджментом: маркетингом и стратегическим управлением организацией. Все это перекликается с проектной деятельностью, развитием и продвижением проектов. Я думаю, что именно Сириус.Лето помог раскрыть весь мой потенциал управленческих навыков. К тому же эта программа невероятно расширяет кругозор. Раньше я считала, что, скорее всего, буду заниматься чем-то более обыденным, но жизнь сложилась так, что я включилась в работу над действительно яркими, инновационными  задачами. Это очень здорово осознавать», – говорит одна из наставников проекта, аспирант Байкальского государственного университета Анастасия Колесова.
			</p>
			<p class="NewsPage-sub-title">
				И координаторы, и наставники благодаря работе над проектом получают возможность погрузиться в новые для себя области научных знаний и даже реализовать свои задумки. Работа над проектами позволяет получить не только практический опыт, но и может открыть дорогу в науку. Так, по результатам работы участниками был опубликован ряд научных статей, посвященных темам проектов.
			</p>
			<p class="NewsPage-sub-title">
				«Координатор от вуза, помимо исполнения формальных обязанностей, должен быть идейным вдохновителем для команды, уметь создать такую атмосферу, в которой хочется работать, развиваться, творить и просто общаться. А еще просто необходимо вовлечь в работу экспертов, которые очень часто своим энтузиазмом и профессионализмом задают тон в работе и предопределяют результат», – говорит Оксана Сараева.
			</p>
			<p class="NewsPage-sub-title">
				Для реализации проектов в Байкальском государственном университете создана научно-исследовательская лаборатория YoungBusinessLab. Как поясняет координатор, за несколько лет участия вуза в программе «Сириус.Лето» здесь даже сложилась определенная система наставничества.
			</p>
			<p class="NewsPage-sub-title">
				«Мы не просто поддерживаем отношения после проекта – мы сотрудничаем и дружим. И еще у нас уже есть своего рода преемственность поколений. Некоторые из школьников, которые ранее участвовали в Сириус.Лето, уже стали студентами нашего вуза и намерены попробовать себя в качестве наставников. Они, в свою очередь, после окончания вуза становятся частью команд региональных фирм и предприятий. Наставники возвращаются в проект уже в качестве экспертов», – поясняет координатор.
			</p>
			<p class="NewsPage-sub-title">
				В 2023/2024 году Байкальский государственный университет вновь присоединится к программе «Сириус.Лето». Участники будут работать над несколькими научно-технологическими проектами и доработают задачи прошлого сезона.
			</p>
			<p class="NewsPage-sub-title">
				Источник - Образовательынй центр "Сириус" <a class="NewsPage-link" href="https://sochisirius.ru/news/6463" target="_blank">ссылка на новость</a>
			</p>

		</div>
	</div>
</template>
