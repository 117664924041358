<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			24.08.2023
		</p>
		<h2 class="NewsPage-title">
			СОТРУДНИКИ «ПЕРСЕЯ» ОБСУДИЛИ ВОЗМОЖНОСТИ СЕТЕВОГО ВЗАИМОДЕЙСТВИЯ С ЦЕНТРАМИ «ТОЧКА РОСТА»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage3.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Сегодня, 24 августа, на онлайн-площадке Регионального центра выявления и поддержки одаренных детей «Персей» прошел вебинар, посвященный возможностям сетевого взаимодействия «Персея» с региональными центрами «Точка роста».
			</p>
			<p class="NewsPage-sub-title">
				Как пояснили организаторы вебинара, на сегодняшний день современный подход к формированию содержания образования требует создания новых организационных форм учебной деятельности, которые будут нацелены на актуальные результаты.
			</p>
			<p class="NewsPage-sub-title">
				– В связи с большой загруженностью педагогов в школах достаточно сложно уделить внимание каждому одаренному ребенку Приангарья. Поэтому сотрудничество «Точек роста» с Образовательным центром «Персей» может оказать значительную поддержку в выявлении, развитии и сопровождении талантливых школьников, а также в создании современной образовательной среды, в которой каждый ребенок может найти для себя вектор в развитии, – отметил руководитель Образовательного центра «Персей» Алексей Шестаков, Сотрудники «Персея» познакомили участников вебинара с деятельностью образовательного центра. На данный момент «Персей» успешно реализует четыре направления: «Наука», «Искусство», «Спорт», а также «Олимпиады и конкурсы».
			</p>
			<p class="NewsPage-sub-title">
				В рамках направлений руководителям центров «Точка роста» поступили предложения по организации совместной деятельности для учащихся и родителей это: открытые «Летние» и «Зимние» школ, выезд Мобильной лаборатории «Персея» и проведение занятий по тематическим и профориентационным программам, а также по участие в открытых уроках с применением интерактивных форм обучения и высокотехнологичного оборудования, которое недоступно в школах.
			</p>
			<p class="NewsPage-sub-title">
				Помимо программ для школьников и их родителей, «Персей» также предлагает проводить комплексную работу и с педагогами в ходе выездных коучинг – сессии на базе кампуса.
			</p>
			<p class="NewsPage-sub-title">
				– Благодаря сотрудничеству мы сможем повысить не только качество образования, но и замотивировать детей на углубленное изучение общеобразовательных дисциплин и участие в конкурсах и олимпиадах, которые помогут школьникам при поступлении, – подчеркнул Алексей Шестков.
			</p>
			<p class="NewsPage-sub-title">
				Мероприятие проходило в рамках проведения методической недели Августовского педагогического совещания. К вебинару подключились 128 участников.
			</p>
			<a class="NewsPage-link" href="../assets/Document/Презентация-24.08.2023-общая.pdf" target="_blank" download>
				Презентация 24.08.2023 общая
			</a>
		</div>
	</div>
</template>
