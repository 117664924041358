<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			07.11.2023
		</p>
		<h2 class="NewsPage-title">
			«Персей» принял участие в Областном молодёжном карьерном форуме
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage23.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Специалисты Образовательного центра «Персей» провели профориентационный мастер-класс «Ключ к успешной профессии» для 20 учащихся школ и студентов г. Иркутска. Мероприятие состоялось в рамках II Областного молодежного карьерного форума, который проходил в Иркутске 2-3 ноября в «Сибэкспоцентре».
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы мастер-класса, главная цель – популяризация педагогической деятельности среди школьников и студентов средне профессиональных организаций.
			</p>
			<p class="NewsPage-sub-title">
				В ходе встречи участники смогли познакомиться с профессией педагога и попробовать себя в роли учителей и наставников. Для них также прошла презентация образовательных программ «Персея», на которых ребята смогут более углубленно погрузиться в профессиональную деятельность и выбрать подходящее направление для себя в педагогике.
			</p>
			<p class="NewsPage-sub-title">
				– Одна из сложностей работы педагога – это найти подход к участникам образовательного процесса, будь то школьник, родитель или коллега. Через интерактивы ребятам было предложено решить ситуационные коммуникативные задачи, с которыми учитель сталкивается каждый день. Нам было важно, чтобы ребята поняли, что работа педагога – это не только проведение учебных и досуговых мероприятий, но и умение находить оптимальные решения, а также выстраивать правильную коммуникацию и решать спорные вопросы. Более углубленно изучить эти темы школьники могут на нашей профильной смене «Искусство вдохновлять», которая ежегодно проходит в Образовательном центре, – рассказала Вера Самодурова, руководитель Центра развития конкурсного движения и детских инициатив.
			</p>
			<p class="NewsPage-sub-title">
				Одними из участников мастер-класса стали ученицы 8 класса 65 школы г. Иркутска Елизавета Бодягина и Щепайкина Анастасия.
			</p>
			<p class="NewsPage-sub-title">
				Девушки отметили, что участие в мероприятии помогло им понять, как правильно налаживать отношения и что в педагогической деятельности есть большой карьерный потенциал.
			</p>
			<p class="NewsPage-sub-title">
				«Персей» активно сотрудничает с образовательными учреждениями и организациями, делая упор на раннюю профориентацию школьников региона. Планируется, что серия мастер-классов «Ключ к успешной профессии» станет одним из постоянных проектов Образовательного центра.
			</p>
			<p class="NewsPage-sub-title">
				Для справки: Областной молодежный карьерный форум проводится в целях создания условий для свободного общения между выпускниками образовательных организаций Иркутской области, молодыми специалистами и работодателями, мотивации молодежи в профессиональном самоопределении и развитии, популяризации осознанного подхода к выбору профессии и построению карьеры, помощи в трудоустройстве.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(5).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(6).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(7).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(8).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(9).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(10).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(11).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(12).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(13).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(14).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(15).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(16).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(17).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(18).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(19).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(20).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(21).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(22).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(23).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(24).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(25).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(26).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(27).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(28).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage23(29).webp" alt="Картинка">
					</li>
				</ul>
			</div>

		</div>
	</div>
</template>
