<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			01.09.2023
		</p>
		<h2 class="NewsPage-title">
			«ПЕРСЕЙ» ЗАПУСКАЕТ ЧЕТВЕРТЫЙ СЕЗОН ОБРАЗОВАТЕЛЬНЫХ ПРОГРАММ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage4.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Региональный центр выявления и поддержки одаренных детей «Персей» начал свою работу с 2020 года благодаря Федеральному проекту «Успех каждого ребенка» Национального проекта «Образование». На сегодняшний день «Персей» успешно завершил более 200 образовательных программ научной, творческой и спортивной направленности.
			</p>
			<p class="NewsPage-sub-title">
				Как сообщает руководство Образовательного центра, в новом учебном году школьников Приангарья ожидает более 50 профильных смен, еженедельных и дистанционных курсов, а также десять конкурсных и олимпиадных мероприятий. Планируется, что участие в программах с сентября 2023 г. по май 2024 г. смогут принять более двух тысяч талантливых и одаренных детей со всего региона.
			</p>
			<p class="NewsPage-sub-title">
				– Сейчас наша главная задача – сделать Образовательный центр «Персей» местом сосредоточения всех талантливых детей Иркутской области. Местом, где каждый сможет реализовать свой потенциал. Для этого мы запускаем новые программы, привлекаем лучших преподавателей региона, а также сотрудничаем с ведущими организациями области. Заинтересованность детей к нашей образовательной деятельности растет с каждым днем, тем самым помогая расти и нам, – отметил руководитель Образовательного центра «Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Также директор Образовательного центра поделился ближайшими планами. До конца года в «Персее» будут реализованы смены агропромышленной, инженерно-технической, научной направленности. Стартуют традиционные смены и курсы по подготовке ребят ко Всероссийской олимпиаде школьников. Смены по углубленному изучению таких дисциплин, как: химия, физика, геология, астрономия, русский язык, обществознание, история, ОБЖ и информатика.
			</p>
			<p class="NewsPage-sub-title">
				В рамках направления «Искусство» пройдут профильные смены для волонтеров культуры, танцоров и музыкантов. А в направлении «Спорт» смены для ребят, которые увлекаются спортивным туризмом и планируют сдавать нормы ГТО. Также на базе кампуса состоится традиционный чемпионат компетенции «ЮниорПрофи» и конкурс «Студент года».
			</p>
			<p class="NewsPage-sub-title">
				С 6 сентября «Персей» откроет свои двери для более 250 школьников, которые приедут на профильные смены: «Рачительный хозяин земли», «Страна железных дорог», «Школа казачьей культуры», Спортивный туризм и Олимпиадная математика.
			</p>
			<p class="NewsPage-sub-title">
				В ближайшее время откроется набор на шесть профильных смен. Узнать, как подать заявку, можно будет на сайте: <a class="NewsPage-link" href="https://perseusirk.ru">https://perseusirk.ru</a>
			</p>
		</div>
	</div>
</template>

