<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			«Всероссийская олимпиада школьников» 2020-2021г.
		</h2>
		<a class="EventsPage-btn temp-btn" href="https://vseros.perseusirk.ru/" target="_blank">
			Регистрация
		</a>
		<hr>

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Организатор заключительного этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Министерство образования и науки Российской Федерации
			</li>
			<p class="EventsPage-text">
				Организатор регионального этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор регионального этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Порядок проведения всероссийской олимпиады школьников
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96678-%D0%BE%D1%82-27.11.20-%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D1%81%D0%9E%D0%A8.pdf" target="_blank">
			Приказ №678 от 27.11.20 Об утверждении Порядка проведения ВсОШ
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%961435-%D0%BE%D1%82-%D0%BE%D1%82-17.11.16-%D0%9E-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BA-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D1%83-%E2%84%961252.pdf" target="_blank">
			Приказ №1435 от от 17.11.16 О внесении изменений к Порядку №1252
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96-1488-%D0%BE%D1%82-17.12.15-%D0%9E-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BA-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D1%83-%E2%84%961252.pdf" target="_blank">
			Приказ № 1488 от 17.12.15 О внесении изменений к Порядку №1252
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96249-%D0%BE%D1%82-17.03.15-%D0%9E-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BA-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D1%83-%E2%84%961252.pdf" target="_blank">
			Приказ №249 от 17.03.15 О внесении изменений к Порядку №1252
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%961252-%D0%BE%D1%82-18.11.13-%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D1%81%D0%9E%D0%A8.pdf" target="_blank">
			Приказ №1252 от 18.11.13 Об утверждении Порядка проведения ВсОШ
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Заключительный этап всероссийской олимпиады школьников 2020-2021
		</p>
		<p class="EventsPage-text">
			Требования к проведению заключительного этапа:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Английский язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F.pdf" target="_blank">
			Астрономия
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.pdf" target="_blank">
			Биология
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F.pdf" target="_blank">
			География
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf" target="_blank">
			Информатика
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE.pdf" target="_blank">
			Искусство
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Испанский язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F.pdf" target="_blank">
			История
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Итальянский язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Китайский язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0.pdf" target="_blank">
			Литература
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf" target="_blank">
			Математика
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Немецкий язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5.pdf" target="_blank">
			Обществознание
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D1%8B-%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B5%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf" target="_blank">
			Основы безопасности жизнедеятельности
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE.pdf" target="_blank">
			Право
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Русский язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.pdf" target="_blank">
			Технология
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0.pdf" target="_blank">
			Физика
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%BA%D1%83%D0%BB%D1%8C%D1%83%D1%82%D1%80%D0%B0.pdf" target="_blank">
			Физическая культура
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf" target="_blank">
			Французский язык
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F.pdf" target="_blank">
			Химия
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.pdf" target="_blank">
			Экология
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0.pdf" target="_blank">
			Экономика
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Приказы о количестве баллов для участия в заключительном этапе:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-342-%D0%BE%D1%82-22-03-2021-1.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-342 от 22-03-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-331-%D0%BE%D1%82-19-03-2021-1.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-331 от 19-03-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-313-%D0%BE%D1%82-17-03-2021-1.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-313 от 17-03-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-279-%D0%BE%D1%8212-03-2021-1.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-279 от 12-03-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-260-%D0%BE%D1%82-11-03-2021-1.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-260 от 11-03-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-236-%D0%BE%D1%82-04-03-2021.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-236 от 04-03-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%BA%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2-%D0%B4%D0%BB%D1%8F-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8F-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-03-217-%D0%BE%D1%82-03-03-2021-1.pdf" target="_blank">
			О количестве баллов для участия в закл. этапе ВСОШ 03-217 от 03-03-2021
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Приказы заключительного этапа:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D1%81%D1%80%D0%BE%D0%BA%D0%B8-%D0%B8-%D0%BC%D0%B5%D1%81%D1%82%D0%B0-%D0%B7%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D1%81%D0%9E%D0%A8-2020-2021.pdf" target="_blank">
			Приказ о внесении изменений в сроки и места заключительного этапа ВсОШ 2020-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D0%B8-%D0%B2-%D0%B7%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-2020-2021.pdf" target="_blank">
			Приказ об участии в заключительном этапе ВсОШ 2020-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8-%D1%81%D1%80%D0%BE%D0%BA%D0%BE%D0%B2-%D0%B8-%D0%BC%D0%B5%D1%81%D1%82-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B7%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D1%81%D0%9E%D0%A8-2020-2021.pdf" target="_blank">
			Приказ об установлении сроков и мест проведения заключительного этапа ВсОШ 2020-2021
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Региональный этап всероссийской олимпиады школьников в Иркутской области 2020-2021
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%E2%84%96-996-%D0%BC%D1%80-%D0%BE%D1%82-28.12.20-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D1%81%D0%9E%D0%A8-2020-2021.pdf" target="_blank">
			Распоряжение № 996-мр от 28.12.20 О проведении регионального этапа ВсОШ 2020-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%E2%84%96294-%D0%BC%D1%80-%D0%BE%D1%82-16.03.21-%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D1%81%D0%9E%D0%A8-2020-2021.pdf" target="_blank">
			Распоряжение №294-мр от 16.03.21 Об итогах регионального этапа ВсОШ 2020-2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9E-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D1%80%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%85-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D1%80%D0%B5%D0%B3.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D1%81%D0%9E%D0%A8-2020-2021.pdf" target="_blank">
			О временных регламентах проведения рег. этапа ВсОШ 2020-2021
		</a>
		<hr>

	</div>
</template>