<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Еженедельный курс
		</h2>
		<h3 class="EventsCard-title">
			«Основы фотосъемки: практика»
		</h3>
		<p class="EventsCard-sub-title">
			с 4 марта по 8 мая 2024 года
		</p>
	</div>
</template>