<template>
	<div class="DistanceCourses _cover">
		<h2 class="DistanceCourses-title _cover-title">Дистанционный курс "Подготовка обучающихся к участию во ВСОШ"</h2>
		<hr />
		<h3 class="Preparation-sub-title">О курсах</h3>
		<p class="Preparation-dist">Формат проведения курсов – дистанционный, объем – 32 часа, ориентировочная дата начала – 18 октября 2023 года.</p>
		<p class="Preparation-dist">Занятия будут проводить члены жюри региональных экспертных комиссий всероссийской олимпиады школьников 2023 года, ведущие преподаватели высших образовательных организаций Иркутской области и лучшие учителя общеобразовательных организаций, имеющие опыт работы в сфере олимпиадного движения школьников.</p>
		<h3 class="Preparation-sub-title">Участие</h3>
		<p class="Preparation-dist">К участию приглашаются обучающиеся Иркутской области.</p>
		<p class="Preparation-dist">Участникам необходимо зарегистрироваться и подать заявку в системе Навигатор (ссылка будет опубликована позднее) и в системе Moodle Образовательного Центра «Персей» (<a style="color: blue" href="https://moodle.perseusirk.ru/">https://moodle.perseusirk.ru/</a>). Дистанционные курсы будут проходить на данной платформе.</p>
		<h3 class="Preparation-sub-title">Регистрация</h3>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tbody>
					<tr>
						<td width="283"><p>Название курса</p></td>
						<td width="236"><p>Ссылка на регистрацию Навигатор</p></td>
						<td width="396"><p>Ссылка на регистрацию Moodle</p></td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по английскому языку»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22396-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-angliiskomu-yazyku">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=91">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по истории»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22390-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-istorii">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=93">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по химии»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22375-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-khimii">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=92">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по математике»</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22370-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-matematike">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=91">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по ОБЖ»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22461-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-obzh">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=96">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по географии»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22459-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-geografii">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=95">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по русскому языку»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22456-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-russkomu-yazyku">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=98">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
					<tr>
						<td width="283">
							<p><strong>«Подготовка обучающихся к участию во ВСОШ по физике»</strong></p>
							<p><strong>&nbsp;</strong></p>
						</td>
						<td width="236">
							<p style="text-align: center"><a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22455-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-fizike">https://xn--38-kmc.xn</a></p>
						</td>
						<td width="396">
							<p style="text-align: center"><a href="https://moodle.perseusirk.ru/course/view.php?id=97">https://moodle.perseusirk.ru</a></p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<h3 class="Preparation-sub-title">Контакты</h3>
		<p class="Preparation-dist">
			<a style="color: blue" href="mailto:perseus@perseusirk.ru"> perseus@perseusirk.ru </a>
		</p>
		<p class="Preparation-dist">
			<a style="color: blue" href="tel:+73952546044"> 8 (3952) 54-60-44  </a>
		</p>
	</div>
</template>
