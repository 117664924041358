<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Лаборатория творчества: техники современного танца»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			Обучение проводится с 17 января по 30 января 2024 года
		</h4>

		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=120" target="_blank">
			Регистрации Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24376-programma-laboratoriya-tvorchestva-tekhniki-sovremennogo-tantsa" target="_blank">
			Регистрации Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену «Лаборатория творчества: техники современного танца» для обучающихся образовательных организаций и учреждений дополнительного образования детей Иркутской области в возрасте от 10 до 15 лет, занимающихся в хореографических коллективах, и владеющих навыками в области современной хореографии.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает:
		</p>
		<p class="EventsPage-text">
			готовность к занятиям суставно-связочного аппарата обучающегося (растяжка, постановка корпуса, развитая координация);
		</p>
		<p class="EventsPage-text">
			наличие навыков и знаний в области ОФП и классического танца;
		</p>
		<p class="EventsPage-text">
			наличие практического сценического опыта выступлений, а именно, умение ориентироваться в классе и на сцене, знания основ построения рисунка, базовых понятий современной хореографии, терминологии основных движений современной хореографии и правильное их исполнение (танцевальный опыт не менее 6 лет), полученные в хореографических коллективах, проявившие себя в творческой деятельности.
		</p>
		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<p class="EventsPage-text">
			Бородихина Наталья Валерьевна, преподаватель хореографических дисциплин ГБПОУ ИО ИРКПО, педагог дополнительного образования;
		</p>
		<p class="EventsPage-text">
			Бородихина Арина Сергеевна, педагог дополнительного образования АНО ДО «Студия танца «Акварель».
		</p>
		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<p class="EventsPage-text">
			Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе электронной информационно-образовательной среды сайта ОЦ «Персей» до 10 января 2024 года, где необходимо прикрепить:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Портфолио с дипломами, грамотами за участие в конкурсах регионального и всероссийского уровня в номинации «Современный танец» за 2022 и 2023 год;
			</li>
			<li class="JuniorProfi-items">
				Мотивационное письмо на тему «Современный танец в моей жизни», в котором написать о себе, о наиболее значимых мероприятиях, в которых вы принимали участие как исполнитель современной хореографии (не более 1000 символов).
			</li>
		</ul>
		<p class="EventsPage-text">
			Записаться в ресурсе «Навигатор дополнительного образования Иркутской области»
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D1%81%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%BD%D0%B0-%D1%81%D0%B0%D0%B9%D1%82_%D0%9B%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D1%82%D0%B2%D0%BE%D1%80%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0.pdf" target="_blank">
			Список учащихся, прошедших отбор
		</a>


	</div>
</template>