<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			27.06.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/sites/minobr/news/3687587/">
				В «Персее» прошли региональные сборы учащихся по подготовке к программе «Сириус.ИИ»
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage68.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одаренных детей «Персей» состоялись первые региональные сборы по
				подготовке школьников к участию во Всероссийской научно-технологической программе по решению проектных задач в
				области искусственного интеллекта и смежных дисциплин «Сириус.ИИ». Участие в сборах приняли 76 школьников
				информационно-технологических классов из Иркутска, Ангарска, Нижнеудинска, Саянска, Шелехова, Усть-Илимска,
				Братска и Слюдянки.
			</p>
			<p class="NewsPage-sub-title">
				В министерстве образования Иркутской области рассказали, что это первый этап программы обучения в центре
				«Персей» по работе с искусственным интеллектом. Уже осенью ребята в составе региональной сборной встретятся на
				профильной смене, где будут в командах работать над решениями проектных задач, заказчиками которых выступит
				региональный бизнес-сектор.
			</p>
			<p class="NewsPage-sub-title">
				После школьники смогут подать заявки на участие в осеннем сезоне Всероссийской научно-технологической программы
				фонда «Талант и успех» «Сириус.ИИ». Участники этой программы будут решать проектные задачи в области
				искусственного интеллекта, заказчиками которой выступят ведущие компании и организации страны.
			</p>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов отметил важность изучения искусственного интеллекта
				школьниками.
			</p>
			<p class="NewsPage-sub-title">
				– Использование искусственного интеллекта в обучении открывает новые возможности перед ребятами, помогает
				подбирать оптимальные решения на основе анализа данных и развивать креативность мышления. Мы видим в этом
				огромный потенциал для подготовки будущих специалистов, которые будут востребованы на рынке труда, – отметил
				Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Ребята занимались разработкой алгоритмов на языке Python, анализировали данные и изучали методы машинного
				обучения применительно к практическим задачам под руководством преподавателей Иркутского политеха, Иркутского
				государственного университета, Томского национального исследовательского государственного университета,
				Сколковского института науки и технологий, педагогов Образовательного центра «Сириус», а также специалистов
				группы компании «Форус» и Межрегионального научно-образовательного центра «Байкал».
			</p>
			<p class="NewsPage-sub-title">
				Перед участниками стояла задача научиться использовать искусственный интеллект для создания и усовершенствования
				своих собственных продуктовых систем. Так, на одном из занятий ребята создавали чат-бот, который на основе
				профориентационного теста предлагает варианты для поступления в Иркутские вузы.
			</p>
			<p class="NewsPage-sub-title">
				– На сегодняшний день технологии искусственного интеллекта помогают создавать модель проекта за пару часов. Даже
				ребята, которые никогда этого не делали могут справиться с этими задачами. Они могут использовать ИИ для
				автоматизации рутинных задач, получения аналитических данных и создания моделей, что значительно упрощает
				процесс обучения и разработки. Благодаря этим технологиям, каждый школьник может почувствовать себя настоящим
				новатором и получить уникальный опыт в мире современных технологий, – поделился Андрей Кантер, директор по
				инновациям Группы компании «Форус».
			</p>
			<p class="NewsPage-sub-title">
				Те ребята, которые только планируют попасть в состав региональной сборной, смогут улучшить свои ИТ-компетенции
				на еженедельных и дистанционных курсах Образовательного центра «Персей».
			</p>

		</div>
	</div>
</template>
