<template>
	<div class="ProfileBlock _cover">
		<h2 class="ProfileBlock-title _cover-title">
			Профильные смены
		</h2>
		<h3 class="ProfileBlock-sub-title _cover-title">
			Реализация профильных смен осуществляется на&nbsp;базе кампуса по&nbsp;адресу: Иркутская область, Ангарский
			район, 8.351&nbsp;км автодороги Ангарск-Тальяны.
		</h3>
		<div class="ProfileBlock-btn-box">
			<button class="ProfileBlock-bth temp-btn" @click="$router.push({ name: 'HowToGet' })">
				Документы для заезда в кампус ОЦ Персей
			</button>
			<a class="ProfileBlock-bth temp-btn" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D1%8F%D0%BD%D0%B2%D0%B0%D1%80%D1%8C-%D1%84%D0%B5%D0%B2%D1%80%D0%B0%D0%BB%D1%8C.pdf" target="_blank" download>
				Профильные программы 2023
			</a>
		</div>

		<ul class="grid-container">
			<li class="grid-item">
				<img loading="lazy" class="ProfileBlock-img" src="../assets/img/jpg/ProfileBlock1.webp" alt="#">
			</li>
			<li class="grid-item">
				<img loading="lazy" class="ProfileBlock-img" src="../assets/img/jpg/ProfileBlock2.webp" alt="#">
			</li>
			<li class="grid-item">
				<img loading="lazy" class="ProfileBlock-img" src="../assets/img/jpg/ProfileBlock3.webp" alt="#">
			</li>
			<li class="grid-item">
				<img loading="lazy" class="ProfileBlock-img" src="../assets/img/jpg/ProfileBlock4.webp" alt="#">
			</li>
		</ul>
		
		<h3 class="ProfileBlock-actual-title _cover-title">
			Актуальные
		</h3>
		
		<ul class="grid-container">

			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage133' })">
					<EventsCard133></EventsCard133>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage132' })">
					<EventsCard132></EventsCard132>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage129' })">
					<EventsCard129></EventsCard129>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage128' })">
					<EventsCard128></EventsCard128>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage127' })">
					<EventsCard127></EventsCard127>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage126' })">
					<EventsCard126></EventsCard126>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage124' })">
					<EventsCard124></EventsCard124>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage123' })">
					<EventsCard123></EventsCard123>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage118' })">
					<EventsCard118></EventsCard118>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage112' })">
					<EventsCard112></EventsCard112>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage111' })">
					<EventsCard111></EventsCard111>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage108' })">
					<EventsCard108></EventsCard108>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage107' })">
					<EventsCard107></EventsCard107>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage105' })">
					<EventsCard105></EventsCard105>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage104' })">
					<EventsCard104></EventsCard104>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage103' })">
					<EventsCard103></EventsCard103>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage102' })">
					<EventsCard102></EventsCard102>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage100' })">
					<EventsCard100></EventsCard100>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage99' })">
					<EventsCard99></EventsCard99>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage98' })">
					<EventsCard98></EventsCard98>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage97' })">
					<EventsCard97></EventsCard97>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage96' })">
					<EventsCard96></EventsCard96>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage95' })">
					<EventsCard95></EventsCard95>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage94' })">
					<EventsCard94></EventsCard94>
				</a>
			</li>
			<li class="ProfileBlock-actual-items grid-item">
				<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage93' })">
					<EventsCard93></EventsCard93>
				</a>
			</li>
			
		</ul>

		<!-- Архив -->
		<div class="Period">
			<h2 class="Period-title _cover-title">Архив</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">Подробнее</summary>
				<ul class="grid-container">
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage59' })">
							<EventsCard59></EventsCard59>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage58' })">
							<EventsCard58></EventsCard58>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage42' })">
							<EventsCard42></EventsCard42>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage41' })">
							<EventsCard41></EventsCard41>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage28' })">
							<EventsCard28></EventsCard28>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage26' })">
							<EventsCard26></EventsCard26>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage22' })">
							<EventsCard22></EventsCard22>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage21' })">
							<EventsCard21></EventsCard21>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage18' })">
							<EventsCard18></EventsCard18>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage23' })">
							<EventsCard23></EventsCard23>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage19' })">
							<EventsCard19></EventsCard19>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage13' })">
							<EventsCard13></EventsCard13>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage12' })">
							<EventsCard12></EventsCard12>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage11' })">
							<EventsCard11></EventsCard11>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage10' })">
							<EventsCard10></EventsCard10>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage9' })">
							<EventsCard9></EventsCard9>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage8' })">
							<EventsCard8></EventsCard8>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage7' })">
							<EventsCard7></EventsCard7>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage6' })">
							<EventsCard6></EventsCard6>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage5' })">
							<EventsCard5></EventsCard5>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage4' })">
							<EventsCard4></EventsCard4>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage3' })">
							<EventsCard3></EventsCard3>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage2' })">
							<EventsCard2></EventsCard2>
						</a>
					</li>
					<li class="ProfileBlock-actual-items grid-item">
						<a class="ProfileBlock-actual-link" @click="$router.push({ name: 'EventsPage20' })">
							<EventsCard20></EventsCard20>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-mvo-120723-250823/" target="_blank">
							<EventsCard82></EventsCard82>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-uiu-210523-300523/" target="_blank">
							<EventsCard81></EventsCard81>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-aibt-160423-290423/" target="_blank">
							<EventsCard80></EventsCard80>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-ecol-160423-250423/" target="_blank">
							<EventsCard79></EventsCard79>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-kp-160423-290423/" target="_blank">
							<EventsCard78></EventsCard78>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-fb-100323-160323/" target="_blank">
							<EventsCard77></EventsCard77>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-pdsl-100323-230323/" target="_blank">
							<EventsCard76></EventsCard76>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-bpl-100323-230323/" target="_blank">
							<EventsCard75></EventsCard75>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-ap080223-210223/" target="_blank">
							<EventsCard74></EventsCard74>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-iv080223-210223/" target="_blank">
							<EventsCard72></EventsCard72>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-se-080223-210223/" target="_blank">
							<EventsCard71></EventsCard71>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-od-180123-310123/" target="_blank">
							<EventsCard70></EventsCard70>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-dzudo-160523-1603/" target="_blank">
							<EventsCard69></EventsCard69>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-sambo-160523-1546/" target="_blank">
							<EventsCard68></EventsCard68>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-onm-160423-290423/" target="_blank">
							<EventsCard67></EventsCard67>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-shyts-040423-130423/" target="_blank">
							<EventsCard66></EventsCard66>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-ofk-250123-310123/" target="_blank">
							<EventsCard65></EventsCard65>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/eksteryernaya_rospis/" target="_blank">
							<EventsCard64></EventsCard64>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-shcol-t-160423-300423/" target="_blank">
							<EventsCard63></EventsCard63>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-mif-10023-230323/" target="_blank">
							<EventsCard62></EventsCard62>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-tbnk-080223-210223/" target="_blank">
							<EventsCard61></EventsCard61>
						</a>
					</li>
						<li class="grid-item grid-itemss">
							<a class="Period-link" href="https://starsite.perseusirk.ru/ps-lths-180123-310123/" target="_blank">
								<EventsCard60></EventsCard60>
							</a>
						</li>
				</ul>
			</details>
		</div>

	</div>
</template>

<script>
import EventsCard2 from '../EventsCard/EventsCard2';
import EventsCard3 from '../EventsCard/EventsCard3';
import EventsCard4 from '../EventsCard/EventsCard4';
import EventsCard5 from '../EventsCard/EventsCard5';
import EventsCard6 from '../EventsCard/EventsCard6';
import EventsCard7 from '../EventsCard/EventsCard7';
import EventsCard8 from '../EventsCard/EventsCard8';
import EventsCard9 from '../EventsCard/EventsCard9';
import EventsCard10 from '../EventsCard/EventsCard10';
import EventsCard11 from '../EventsCard/EventsCard11';
import EventsCard12 from '../EventsCard/EventsCard12';
import EventsCard13 from '../EventsCard/EventsCard13';
import EventsCard18 from '../EventsCard/EventsCard18';
import EventsCard19 from '../EventsCard/EventsCard19';
import EventsCard20 from '../EventsCard/EventsCard20';
import EventsCard21 from '../EventsCard/EventsCard21';
import EventsCard22 from '../EventsCard/EventsCard22';
import EventsCard23 from '../EventsCard/EventsCard23';
import EventsCard26 from '../EventsCard/EventsCard26';
import EventsCard28 from '../EventsCard/EventsCard28';
import EventsCard41 from '../EventsCard/EventsCard41';
import EventsCard42 from '../EventsCard/EventsCard42';
import EventsCard58 from '../EventsCard/EventsCard58';
import EventsCard59 from '../EventsCard/EventsCard59';
import EventsCard60 from '../EventsCard/EventsCard60';
import EventsCard61 from '../EventsCard/EventsCard61';
import EventsCard62 from '../EventsCard/EventsCard62';
import EventsCard63 from '../EventsCard/EventsCard63';
import EventsCard64 from '../EventsCard/EventsCard64';
import EventsCard65 from '../EventsCard/EventsCard65';
import EventsCard66 from '../EventsCard/EventsCard66';
import EventsCard67 from '../EventsCard/EventsCard67';
import EventsCard68 from '../EventsCard/EventsCard68';
import EventsCard69 from '../EventsCard/EventsCard69';
import EventsCard70 from '../EventsCard/EventsCard70';
import EventsCard71 from '../EventsCard/EventsCard71';
import EventsCard72 from '../EventsCard/EventsCard72';
import EventsCard74 from '../EventsCard/EventsCard74';
import EventsCard75 from '../EventsCard/EventsCard75';
import EventsCard76 from '../EventsCard/EventsCard76';
import EventsCard77 from '../EventsCard/EventsCard77';
import EventsCard78 from '../EventsCard/EventsCard78';
import EventsCard79 from '../EventsCard/EventsCard79';
import EventsCard80 from '../EventsCard/EventsCard80';
import EventsCard81 from '../EventsCard/EventsCard81';
import EventsCard82 from '../EventsCard/EventsCard82';
import EventsCard93 from '../EventsCard/EventsCard93';
import EventsCard94 from '../EventsCard/EventsCard94';
import EventsCard95 from '../EventsCard/EventsCard95';
import EventsCard96 from '../EventsCard/EventsCard96';
import EventsCard97 from '../EventsCard/EventsCard97';
import EventsCard98 from '../EventsCard/EventsCard98';
import EventsCard99 from '../EventsCard/EventsCard99';
import EventsCard100 from '../EventsCard/EventsCard100';
import EventsCard102 from '../EventsCard/EventsCard102';
import EventsCard103 from '../EventsCard/EventsCard103';
import EventsCard104 from '../EventsCard/EventsCard104';
import EventsCard105 from '../EventsCard/EventsCard105';
import EventsCard107 from '../EventsCard/EventsCard107';
import EventsCard108 from '../EventsCard/EventsCard108';
import EventsCard111 from '../EventsCard/EventsCard111';
import EventsCard112 from '../EventsCard/EventsCard112';
import EventsCard118 from '../EventsCard/EventsCard118';
import EventsCard123 from '../EventsCard/EventsCard123';
import EventsCard124 from '../EventsCard/EventsCard124';
import EventsCard126 from '../EventsCard/EventsCard126';
import EventsCard127 from '../EventsCard/EventsCard127';
import EventsCard128 from '../EventsCard/EventsCard128';
import EventsCard129 from '../EventsCard/EventsCard129';
import EventsCard132 from '../EventsCard/EventsCard132';
import EventsCard133 from '../EventsCard/EventsCard133';


	export default {
		components: {
			EventsCard2,
			EventsCard3,
			EventsCard4,
			EventsCard5,
			EventsCard6,
			EventsCard7,
			EventsCard8,
			EventsCard9,
			EventsCard10,
			EventsCard11,
			EventsCard12,
			EventsCard13,
			EventsCard18,
			EventsCard19,
			EventsCard20,
			EventsCard21,
			EventsCard22,
			EventsCard23,
			EventsCard26,
			EventsCard28,
			EventsCard41,
			EventsCard42,
			EventsCard58,
			EventsCard59,
			EventsCard60,
			EventsCard61,
			EventsCard62,
			EventsCard63,
			EventsCard64,
			EventsCard65,
			EventsCard66,
			EventsCard67,
			EventsCard68,
			EventsCard69,
			EventsCard70,
			EventsCard71,
			EventsCard72,
			EventsCard74,
			EventsCard75,
			EventsCard76,
			EventsCard77,
			EventsCard78,
			EventsCard79,
			EventsCard80,
			EventsCard81,
			EventsCard82,
			EventsCard93,
			EventsCard94,
			EventsCard95,
			EventsCard96,
			EventsCard97,
			EventsCard98,
			EventsCard99,
			EventsCard100,
			EventsCard102,
			EventsCard103,
			EventsCard104,
			EventsCard105,
			EventsCard107,
			EventsCard108,
			EventsCard111,
			EventsCard112,
			EventsCard118,
			EventsCard123,
			EventsCard124,
			EventsCard126,
			EventsCard127,
			EventsCard128,
			EventsCard129,
			EventsCard132,
			EventsCard133,
		}
	}
</script>
