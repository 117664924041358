<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Анатомия без границ: изучение тела человека»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 15 по 25 декабря 2023 года.
		</h4>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24105-programma-anatomiya-bez-granits-izuchenie-tela-cheloveka" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Анатомия без границ: изучение тела человека» для учащихся 8-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа позволит учащимся изучить взаимосвязи между различными системами организма человека, научится распознавать и управлять процессами, связанные с основными гомеостатическими константами, а также сконцентрировать свое внимание на нарушения в деятельности различных систем.
		</p>
		<p class="EventsPage-text">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Список обучающихся
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%BD%D0%B0-%D1%81%D0%B0%D0%B9%D1%82-%D0%90%D0%BD%D0%B0%D1%82%D0%BE%D0%BC%D0%B8%D1%8F.docx" target="_blank">
			Скачать
		</a>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/9K19/319KDMWXD" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/IhQsAqHZYgJMXQ" target="_blank">
			Сертификаты
		</a>
		
		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>