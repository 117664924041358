<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«От этюда к спектаклю»
		</h2>
		<p class="EventsPage-date">
			с 31 июля по 13 августа 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=177" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25305-programma-ot-etyuda-k-spektaklyu"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену
			«От этюда к спектаклю» для обучающихся организаций дополнительного образования по направлению изобразительное
			искусство от 10 до 17 лет, заявившие в добровольном порядке свое намерение участвовать в мероприятиях смены и
			проявивших себя в творческой деятельности в области театрального искусства, а также, владеющих знаниями и навыками
			в области театрально-сценической деятельности.
		</p>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				готовность к практическим и теоретическим занятиям (беседа, дискуссия, лекция, видео-просмотр и обсуждение);
			</li>
			<li class="JuniorProfi-items">
				знание базовых понятий актёрского мастерства и сценической речи, основ построения сценического действия,
				театральной терминологии;
			</li>
			<li class="JuniorProfi-items">
				наличие практического сценического опыта выступлений, а именно, умение ориентироваться на сцене;
			</li>
			<li class="JuniorProfi-items">
				актёрский опыт не менее 3 лет.
			</li>
		</ul>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Эристави Ольга Александровна, педагог дополнительного образования МКУ ДО ИРМО «ЦРТДЮ»; член союза театральных
				деятелей РФ, директор Автономной культурно-просветительской некоммерческой организации Открытый драматический
				камерный театр «Альтер Хомо», (г. Иркутск).
			</li>
		</ul>

		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
			Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе
				электронной информационно-образовательной среды сайта ОЦ «Персей» до 19 июля 2024 года
				https://moodle.perseusirk.ru/course/view.php?id=177 где необходимо прикрепить портфолио:
			</li>
			<li class="JuniorProfi-items">
				фотографии творческих достижений (грамоты, дипломы, сертификаты, благодарственные письма за 2023-2024 год);
			</li>
			<li class="JuniorProfi-items">
				фотографии актёрских работ (участие в спектаклях, с указанием названий постановок, ролей, именами режиссёров,
				дат и мест показов, во время которых сделаны фотографии)
			</li>
			<li class="JuniorProfi-items">
				видео визитка (продолжительностью 1 минута, в котором учащийся говорит о том, зачем ему нужно обучение по
				дополнительной общеразвивающей программе «От этюда к спектаклю»).
			</li>
			<li class="JuniorProfi-items">
				Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке:
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25305-programma-ot-etyuda-k-spektaklyu.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Бабаева Лейла Дильшодовна</li>
			<li class="EventsPage-number">Баев Александр Геннадьевич</li>
			<li class="EventsPage-number">Варламов Богдан Денисович</li>
			<li class="EventsPage-number">Васильева Виктория Александровна</li>
			<li class="EventsPage-number">Вишан Валерия Андреевна</li>
			<li class="EventsPage-number">Вишан Полина Андреевна</li>
			<li class="EventsPage-number">Говорин Дмитрий Евгеньевич</li>
			<li class="EventsPage-number">Григорьева Ульяна Андреевна</li>
			<li class="EventsPage-number">Губанова Виктория Александровна</li>
			<li class="EventsPage-number">Демьянчикова Маргарита Вячеславовна</li>
			<li class="EventsPage-number">Зайцева Анастасия Павловна</li>
			<li class="EventsPage-number">Иванова Ольга Вячеславовна</li>
			<li class="EventsPage-number">Кадошникова Валерия Алексеевна</li>
			<li class="EventsPage-number">Клепча Алиса Евгеньевна</li>
			<li class="EventsPage-number">Ковальчук Арина Константиновна</li>
			<li class="EventsPage-number">Козулина Алина Владиславовна</li>
			<li class="EventsPage-number">Коновалюк Екатерина Сергеевна</li>
			<li class="EventsPage-number">Коновалюк Татьяна Сергеевна</li>
			<li class="EventsPage-number">Корнев Андрей Алексеевич</li>
			<li class="EventsPage-number">Коршунов Константин Александрович</li>
			<li class="EventsPage-number">Курбатова Алиса Васильевна</li>
			<li class="EventsPage-number">Курдубакина Анастасия Александровна</li>
			<li class="EventsPage-number">Левченко Мария Григорьевна</li>
			<li class="EventsPage-number">Лыкова Наталья Вячеславовна</li>
			<li class="EventsPage-number">Масленникова Полина Сергеевна</li>
			<li class="EventsPage-number">Ощепкова Нелли Дмитриевна</li>
			<li class="EventsPage-number">Подлужная Милена Владимировна</li>
			<li class="EventsPage-number">Потапова Алиса Алексеевна</li>
			<li class="EventsPage-number">Савченко Татьяна Александровна</li>
			<li class="EventsPage-number">Спицын Иван Артёмович</li>
			<li class="EventsPage-number">Теплякова Анастасия Сергеевна</li>
			<li class="EventsPage-number">Токарева Алена Александровна</li>
			<li class="EventsPage-number">Уваров Александр Алексевич</li>
			<li class="EventsPage-number">Хвисков Иван Андреевич</li>
			<li class="EventsPage-number">Хитрова Александра Викторовна</li>
			<li class="EventsPage-number">Хитрова Ксения Викторовна</li>
			<li class="EventsPage-number">Хитрова Ульяна Денисовна</li>
			<li class="EventsPage-number">Хроменкова Лидия Алексеевна</li>
			<li class="EventsPage-number">Чечуенко Максим Демьянович</li>
			<li class="EventsPage-number">Чигиринский Денис Ильич</li>
			<li class="EventsPage-number">Шевченко Валерия Александровна</li>
			<li class="EventsPage-number">Щербакова Алиса Сергеевна</li>
			<li class="EventsPage-number">Ястребова Ангелина Ивановна</li>
			<li class="EventsPage-number">Ященко Иван Игоревич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>