<template>
	<div class="WeeklyCourses _cover">
		<h1 class="WeeklyCourses-title _cover-title">
			Еженедельный курс
		</h1>

		<div class="WeeklyCourses-img-box">
			<img loading="lazy" class="WeeklyCourses-img-content" src="../assets/img/Logo/НАУКА.webp" alt="Наука">
		</div>

		<hr>

		<div class="Period">
			<h2 class="Period-title _cover-title">Актуальные программы</h2>

			<ul class="grid-container">
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage156' })">
						<EventsCard156></EventsCard156>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage147' })">
						<EventsCard147></EventsCard147>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage146' })">
						<EventsCard146></EventsCard146>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage120' })">
						<EventsCard120></EventsCard120>
					</a>
				</li>
			</ul>

		</div>
	</div>
</template>

<script>
import EventsCard120 from '../EventsCard/EventsCard120';
import EventsCard146 from '../EventsCard/EventsCard146';
import EventsCard147 from '../EventsCard/EventsCard147';
import EventsCard156 from '../EventsCard/EventsCard156';


	export default {
		components: {
			EventsCard120,
			EventsCard146,
			EventsCard147,
			EventsCard156,
		}
	}
</script>