<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">Чемпионат</h3>
		<h2 class="EventsPage-title">«VI Региональный чемпионат компетенций ЮниорПрофи Иркутской области »</h2>
		<h4 class="JuniorProfi-title _cover-title">23 по 26 декабря 2021 года</h4>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank"> Регистрация </a>
		<hr />

		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/12/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-VI-%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%87%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B5%D1%82%D0%B5%D0%BD%D1%86%D0%B8%D0%B9.pdf" target="_blank"> Приказ Об итогах VI Регионального чемпионата компетенций </a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/11/%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-VI-%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%87%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B5%D1%82%D0%B5%D0%BD%D1%86%D0%B8%D0%B9-%D0%AE%D0%BD%D0%B8%D0%BE%D1%80%D0%9F%D1%80%D0%BE%D1%84%D0%B8-%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B9-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B82.pdf" target="_blank"> О проведении VI Регионального чемпионата компетенций ЮниорПрофи Иркутской области </a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/12/%D0%A0%D0%B0%D1%81%D0%BF.-%D0%BE-%D0%A7%D0%9A-23-26-%D0%B4%D0%B5%D0%BA.-2021-%D0%B3%D0%BE%D0%B4%D0%B0.pdf" target="_blank"> Расп. о ЧК 23-26 дек. 2021 года </a>
		<p class="EventsPage-text">VI Региональный чемпионат компетенций ЮниорПрофи Иркутской области состоится 23 по 26 декабря 2021 года.</p>
		<p class="EventsPage-text">Чемпионат будет проходить очно в кампусе Образовательного центра «Персей» (Иркутская область, Ангарский р-н, 8.351 км автодороги Ангарск-Тальяны).</p>
		<p class="EventsPage-text">Обучение и проживание для обучающихся и экспертов бесплатное.</p>
		<hr />

		<p class="JuniorProfi-sub-title">С целью подготовки к участию в Чемпионате состоится он-лайн обучение наставников в соответствии со следующим графиком:</p>

		<table width="0">
			<tbody>
				<tr>
					<td width="260">
						<p><strong>Компетенция</strong></p>
					</td>
					<td width="154">
						<p><strong>Дата регистрации</strong></p>
					</td>
					<td width="152">
						<p><strong>Дата обучения</strong></p>
					</td>
					<td width="155">
						<p><strong>Формат проведения</strong></p>
					</td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Прототипирование</p></td>
					<td width="154"><p>До 5 ноября</p></td>
					<td width="152"><p>6 ноября</p></td>
					<td width="155"><p>Дистанционно</p></td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Инженерный дизайн</p></td>
					<td width="154"><p>До 5 ноября</p></td>
					<td width="152"><p>6 ноября</p></td>
					<td width="155"><p>Дистанционно</p></td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Агрономия</p></td>
					<td width="154"><p>До 10 ноября</p></td>
					<td width="152"><p>12 ноября с 10 часов</p></td>
					<td width="155"><p>Дистанционно</p></td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Сити-фермерство</p></td>
					<td width="154"><p>До 10 ноября</p></td>
					<td width="152"><p>12 ноября с 10 часов</p></td>
					<td width="155"><p>Дистанционно</p></td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Мобильная робототехника</p></td>
					<td width="154"><p>До 5 ноября</p></td>
					<td width="152"><p>6 ноября</p></td>
					<td width="155"><p>Дистанционно</p></td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Мультимедиакоммуникации</p></td>
					<td width="154"><p>До 5 ноября</p></td>
					<td width="152"><p>6 ноября</p></td>
					<td width="155"><p>Дистанционно</p></td>
				</tr>
				<tr>
					<td width="260"><p style="text-align: left">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ветеринария</p></td>
					<td width="154"><p>До 16 ноября</p></td>
					<td width="152"><p>18 ноября 2021 года</p></td>
					<td width="155"><p>очно в ГБПОУ ИО «Иркутский аграрный техникум» (г. Иркутск, ул. Ярославского, 211).</p></td>
				</tr>
			</tbody>
		</table>

		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">Регистрация на профильную смену проходит по ссылке https://konkurs.ric38.ru/.</li>
			<li class="JuniorProfi-items">После регистрации на сайте необходимо выбрать в списке конкурсов «VI Региональный чемпионат компетенций ЮниорПрофи Иркутской области. Обучение наставников»</li>
			<li class="JuniorProfi-items">Заполнить заявку.</li>
		</ul>
	</div>
</template>
