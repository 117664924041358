<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Конкурс
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			Конкурс постов «Мой 2023 год»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 11 декабря 2023 года по 25 декабря 2023 года.
		</h4>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/activity/1444/?date=2023-12-11" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Цель Конкурса:
		</p>
		<p class="EventsPage-text">
			Выявление и поддержка талантливых и одаренных ребят, имеющих особые достижения в области науки, спорта, искусства, также общественной, олимпиадной и конкурсной деятельности.
		</p>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			О конкурсе:
		</p>
		<p class="EventsPage-text">
			Приглашаем школьников в возрасте 10-16 лет принять участие в конкурсе постов в социальной сети Вконтакте и рассказать о своих успехах и достижениях за 2023 год.
		</p>
		<p class="EventsPage-text">
			Не забудьте подать заявку на сайте «Навигатор дополнительного образования детей Иркутской области» <a style="display: inline-block;" class="EventsPage-link" href="https://р38.навигатор.дети/activity/1444/?date=2023-12-11" target="_blank">по ссылке </a>
		</p>
		<p class="EventsPage-text">
			Победителям Конкурса вручается сертификат на участие в профильной смене ОЦ «Персей» (из числа предложенных Организатором). Срок действия сертификата — 1 (один) год. Формат и особые условия участия в профильной смене обсуждаются с победителем индивидуально.
		</p>
		<p class="EventsPage-text">
			Сроки проведения: с 11 декабря 2023 года по 25 декабря 2023 года. Подведение итогов с 26 декабря по 27 декабря 2023 года.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Конкурсное задание:
		</p>
		<p class="EventsPage-text">
			Разместить на личной странице в социальной сети ВКонтакте пост в формате текста с информацией о своих достижениях в сфере науки, искусства(творчества), спорта или конкурсной олимпиадной деятельности в период с 1 января 2023 года по настоящий момент. <b>С хештегами:</b> #ХочувПерсей #ИтогигодасПерсеем #Персей #Мой2023.
		</p>
		<p class="EventsPage-text">
			Подробнее о ходе и итогах конкурса смотрите в официальном сообществе Образовательного центра «Персей» Вконтакте: <a class="EventsPage-link" href="https://vk.com/perseusirk" target="_blank">https://vk.com/perseusirk</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для прохождения обучения:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5.pdf" target="_blank">
			Положение о конкурсе
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Самодурова Вера Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: v.samodurova@perseusirk.ru
			</li>
		</ul>

	</div>
</template>