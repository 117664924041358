<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			VII Региональный чемпионат компетенций ЮниорПрофи Иркутской области
		</h2>
		<p class="EventsPage-date">
			Планируемые мероприятия 2022-2023 года:
		</p>
		<p class="EventsPage-date">
			VII Региональный чемпионат компетенций
		</p>
		<p class="EventsPage-date">
			VII Региональный чемпионат корпораций
		</p>
		<hr>

		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Фонд «Вольное Дело» - Учредитель Чемпионата ЮниорПрофи
			</li>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области - Организатор регионального Чемпионата
			</li>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей" - Региональный координационный центр
			</li>
		</ul>
		<p class="JuniorProfi-sub-title">
			Даты проведения: с 19 по 22 декабря 2022 года.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Задачи Чемпионата:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				привлечение внимания обучающихся и их родителей к выбору инженерно-технических профессий, к ранней профессиональной подготовке как перспективному направлению профессионального самоопределения и карьерного развития
			</li>
			<li class="JuniorProfi-items">
				привлечение внимания высокотехнологичных предприятий, образовательных организаций высшего и среднего профессионального образования, других организаций к деятельности образовательных организаций общего и дополнительного образования как субъектов системы подготовки кадрового резерва для промышленности
			</li>
			<li class="JuniorProfi-items">
				развитие у обучающихся навыков практического решения задач в конкретных производственных ситуациях и навыков работы с техническими устройствами
			</li>
			<li class="JuniorProfi-items">
				совершенствование навыков самостоятельной работы, развитие профессионального мышления и повышение ответственности обучающихся за выполняемую работу
			</li>
			<li class="JuniorProfi-items">
				формирование готовности школьников к целенаправленной деятельности по созданию общественно полезного продукта
			</li>
			<li class="JuniorProfi-items">
				создание системы наращивания профессионализма педагогов по реализации программ технической и технологической направленности в организациях основного и дополнительного образования детей
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Участники:
		</p>
		<p class="EventsPage-text">
			К участию приглашаются обучающиеся школ и организаций ДПО возрастов 10+ (10-13 лет) и 14+ (14-17 лет) в составе команды из 2-х человек по выбранной компетенции, под руководством педагога-наставника.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Компетенции чемпионата:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Прототипирование
			</li>
			<li class="JuniorProfi-items">
				Инженерный дизайн
			</li>
			<li class="JuniorProfi-items">
				Агрономия
			</li>
			<li class="JuniorProfi-items">
				Сити-фермерство
			</li>
			<li class="JuniorProfi-items">
				Ветеринария
			</li>
			<li class="JuniorProfi-items">
				Мобильная робототехника
			</li>
			<li class="JuniorProfi-items">
				Мультимедиакоммуникации
			</li>
			<li class="JuniorProfi-items">
				Электроника
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Как принять участие:
		</p>
		<p class="EventsPage-text">
			Чемпионат будет проходить очно в кампусе Образовательного центра «Персей» Адрес: Иркутская область, Ангарский р-н, 8.351 км автодороги Ангарск-Тальяны. До места проведения Чемпионата участники добираются самостоятельно (транспорт не предусмотрен).
		</p>
		<p class="EventsPage-text">
			С целью подготовки к участию в Чемпионате состоится стажировка для педагогов, желающих стать наставниками-экспертами Чемпионата. Заявки для обучения наставников-экспертов необходимо отправить в срок с 1 ноября по 6 ноября 2022 года включительно через АИС «Проведение конкурсов» konkurs.ric38.ru.
		</p>
		<p class="EventsPage-text">
			После прохождения стажировки наставники-эксперты подают заявки на участие с 17 по 23 ноября 2022 года. Участники команды регистрируются для участия в Чемпионате через Навигатор дополнительного образования Иркутской области р.38.навигатор.дети/activity/745/?date=2022-10-27.
		</p>
		<p class="EventsPage-text">
			В случае, если количество команд, желающих принять участие в Чемпионате, будет больше установленной квоты, по каждой компетенции будет проведен предварительный отбор.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/11/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B5-%D0%BF%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-VII-%D1%87%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82%D0%B0-%D0%9A%D0%BE%D0%BC%D0%BF%D0%B5%D1%82%D0%B5%D0%BD%D1%86%D0%B8%D0%B9-%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B9-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8.pdf" target="_blank">
			Информационное письмо — О проведении VII чемпионата Компетенций Иркутской области
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Как проходил VI Региональный чемпионат компетенций ЮниорПрофи
		</p>
		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe width="100%" height="500" src="https://www.youtube.com/embed/p86xCrWuiII?si=bxVgmCkq3SKRRKAC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
		</div>

	</div>
</template>