<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			24.07.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/sites/minobr/news/3881000/">
				Школьники из Приангарья принимают участие в научно-технологической программе «Большие вызовы»
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage74.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Сириус» Фонда «Талант и успех» проходит ежегодная научно-технологическая программа
				«Большие вызовы». Ее участниками стали 420 школьников со всей страны. Иркутскую область на площадке представляют
				учащийся лицея РЖД № 14 Иркутска Владимир Федосеев, учащийся лицея № 2 Братска Никита Заворотний, ученик
				иркутской школы № 19 Артем Алексеенко.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, «Большие вызовы» – это один из главных научно-технологических проектов России. Его цель – включить
				школьников в работу над актуальными вызовами, стоящими перед страной, и обучать детей на примере реальных
				проектов, которые предлагают крупнейшие компании и вузы России. В этом году программа проходит по 14
				направлениям, каждое из которых заложено в Стратегии научно-технологического развития страны.
			</p>
			<p class="NewsPage-sub-title">
				В рамках работы по направлению «Передовые производственные технологии» Владимир Федосеев вместе с командой
				решает проблему Трубной металлургической компании. В их задачу входит разработать интеллектуальную тестирующую
				систему для оценивания качества обучения сотрудников с помощью VR-технологий. Никита Заворотний в направлении
				«Большие данные, искусственный интеллект, финансовые технологии и машинное обучение» работает над кейсом от
				Центрального банка России, разрабатывая аналитический сервис для противодействия финансовому мошенничеству.
			</p>
			<p class="NewsPage-sub-title">
				В этом году добавилось новое направление «Экология и изучение изменений климата». В рамках направления школьники
				изучают современные экологические проблемы, анализируют данные о климатических изменениях и разрабатывают
				проекты, направленные на их решение. Одну из таких задач решает Артем Алексеенко. Школьник работает над
				прототипом автономной метеостанции с возможностью дистанционного мониторинга.
			</p>
			<p class="NewsPage-sub-title">
				– Участие наших школьников в научно-технологической программе «Большие вызовы» – это огромная возможность для их
				личностного и профессионального роста. Они не только приобретают новые знания и навыки, но и учатся применять их
				на практике. Мы гордимся успехами наших учеников и уверены, что их участие в этой программе будет способствовать
				развитию науки и технологий в России, – отметил министр образования Иркутской области Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Помимо работы над проектами, школьников ждут многочисленные тренинги, мастер-классы и лекции от руководителей
				направлений программы, известных ученых, популяризаторов науки, экспертов ведущих компаний и предприятий.
			</p>
			<p class="NewsPage-sub-title">
				Закончится программа большим фестивалем проектов, где ребята презентуют свои работы. Уже после программы
				участники продолжают сотрудничать с компаниями. Команды поддерживают связь и продолжают работу над проектами,
				что приводит к созданию прототипов устройств, научных статей и готовых продуктов. Ребята также получают
				приглашения посетить предприятия или пройти практику в компаниях-партнерах «Сириуса».
			</p>
			<p class="NewsPage-sub-title">
				Защита итоговых проектов пройдет 24 июля в 16:00. Подключиться к прямой трансляции можно будет по ссылке:
				<a class="NewsPage-link" href="https://vk.com/wall-71991592_50177">https://vk.com/wall-71991592_50177</a>
			</p>

		</div>
	</div>
</template>
