<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			30.07.2024
		</p>
		<h2 class="NewsPage-title">
			Школьники Приангарья приняли участие в ежегодной программе по спортивному туризму
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage75.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» прошла ежегодная профильная смена по спортивному туризму. Участниками
				программы стали 37 школьников из Иркутска, Ангарска, Братска, Усолье-Сибирского и Иркутского района. Смена
				проходила с 19 по 28 июля и была направлена на подготовку юных спортсменов к соревновательному сезону.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали тренеры, программа включала отработку технических приемов со специальным снаряжением,
				технико-тактическую подготовку и тренировки на дистанциях. Особое внимание уделялось взаимодействию в связке и
				разработке тактических взаимодействий. Важным элементом обучения стало спортивное ориентирование на местности,
				что позволяло ребятам развивать навыки работы с картами и компасом.
			</p>
			<p class="NewsPage-sub-title">
				Одним из ярких событий программы стала встреча с иркутским альпинистом Владиславом Лачкаревым. Владислав –
				второй человек в мире, который прошел экспедицию «Семь вершин, семь вулканов и два полюса». На встрече альпинист
				поделился с участниками своим опытом в преодолении сложнейших маршрутов и покорении высочайших вершин и полюсов
				Земли.
			</p>
			<p class="NewsPage-sub-title">
				– Встреча с юными спортсменами была невероятно вдохновляющей. Видеть их горящие глаза и стремление к новым
				вершинам – напомнило мне о начале моего пути. Я уверен, что эти ребята смогут достичь больших высот не только в
				спорте, но и в жизни. Главное — не бояться мечтать и идти к своим целям, – поделился Владислав.
			</p>
			<p class="NewsPage-sub-title">
				Сами участники программы отмечают, что обучение помогло им развить важные навыки, такие как уверенность в своих
				силах, командная работа и способность быстро адаптироваться к новым условиям. Они подчеркнули, что практические
				занятия и взаимодействие с опытными наставниками стали для них ценным опытом, который они смогут применять не
				только в спорте, но и в повседневной жизни.
			</p>
			<p class="NewsPage-sub-title">
				В завершение обучения ребята приняли участие в соревнованиях по туризму и спортивному ориентированию. Участники
				продемонстрировали приобретенные навыки в навигации по сложной местности, работе в команде и применении
				тактических приемов.
			</p>
			<p class="NewsPage-sub-title">
				– Можем с полной уверенностью сказать, что такие сборы помогают ребятам достигать высоких спортивных
				результатов. Многие спортсмены приезжают из года в год, и сейчас они уже имеют разряды и успешно представляют
				наш регион на соревнованиях регионального и всероссийского уровня, – отметил Леонид Куксенко, тренер программы
				«Спортивный туризм».
			</p>
			<p class="NewsPage-sub-title">
				Уже скоро спортсмены поедут на Первенство Сибирского федерального округа в г. Зеленогорск. Напомним, что
				профильные смены по спортивному туризму в Образовательном центре «Персей» способствуют формированию здорового
				образа жизни школьников и популяризации туризма среди молодежи Иркутской области. В октябре в «Персее» пройдет
				программа по спортивному ориентированию. Участие в ней примут более 100 школьников региона.
			</p>
		</div>
	</div>
</template>
