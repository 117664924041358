<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage72.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» впервые прошла программа повышения квалификации для педагогов в области музыкального образования
			</h2>
			<p class="NewsCard-date">
				12.07.2024
			</p>
		</div>
	</div>
</template>