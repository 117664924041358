<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			02.08.2023
		</p>
		<h2 class="NewsPage-title">
			«ЭКСТЕРЬЕРНАЯ РОСПИСЬ»: КАК В «ПЕРСЕЕ» ПРОШЛА УНИКАЛЬНАЯ ПРОГРАММА
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage1.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В «Персее» подошла к концу июльская образовательная программа по направлению «Искусство». Профильная смена «Экстерьерная роспись» проходила впервые. Участие в ней приняли 16 школьников из Иркутска, Ангарска, Шелехова, Слюдянки, а также из Иркутского и Усольского района.
			</p>
			<p class="NewsPage-sub-title">
				За десять дней юным художникам удалось разработать дизайн росписи стен, а также воплотить его на фасаде спортивного домика. Как отмечает преподаватель смены Наталия Лобыгина, темой росписи стал «Спортивный праздник».
			</p>
			<p class="NewsPage-sub-title">
				– В начале смены каждый участник программы представил эскиз, который разработал по определённым требованиям. Нашем главным критерием стало то, чтобы расписанный фасад был виден издалека, а структура росписи четко читалась. Детям было предложено нарисовать яркий эскиз, который отображает динамику спорта и радость от побед, – пояснил преподаватель.
			</p>
			<p class="NewsPage-sub-title">
				Из 16 эскизов была выбрана работа Виктории Власенко, ученицы Слюдянской школы № 4. По мотивам ее дизайна и был расписан фасад. Рисунок выполнен на объединение плоскостей, что позволяет обходить домик и видеть перетекание фигур из одной плоскости в другую, тем самым формируя цельный образ объекта.
			</p>
			<p class="NewsPage-sub-title">
				– Когда нам сказали, что нужно придумать эскиз, прежде всего я задумалась о его удобстве для переноса на фасад. На улице было жарко, и работать долго на солнце было бы тяжело. Также на смене были дети разных возрастов, и поэтому эскиз должен быть легким и не вызывать трудностей, – поделилась Виктория. – Одна из главных тем, которые мы проходили – «Динамика в интерьере», поэтому я решила, что лучше всего для фасада подойдут различные геометрические фигуры в движении. Чтобы домик выделялся в разные времена года, я выбрала цветовую палитру ярких оттенков: жёлтых, зелёных, оранжевых и красных. В результате получился эскиз, напоминающий «Конфетти».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, в направление «Искусство» в Образовательном центре «Персей» реализуются программы подготовки творческой направленности для юных художников, музыкантов, танцоров и актеров. Узнать подробнее о профильных сменах и подать заявку на отбор можно на сайте образовательного учреждения: <a class="NewsPage-link" href="https://perseusirk.ru/art/">https://perseusirk.ru/art/</a>
			</p>
		</div>
	</div>
</template>

