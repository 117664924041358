<template>
	<div class="GoldenFundPage _cover">
		<h2 class="GoldenFundPage-title _cover-title">
			Золотой фонд Сибири
		</h2>

		<h2 class="GoldenFundOfSiberia-title _cover-title">
			Открытая региональная межвузовская олимпиада обучающихся Иркутской области
		</h2>
		<a class="GoldenFundOfSiberia-btn temp-btn" href="https://irk-olimp.ru/">
			Подробнее
		</a>
		<hr>

		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Цели и задачи Олимпиады
		</h3>
		<ul class="GoldenFundOfSiberia-list">
			<li class="GoldenFundOfSiberia-items">
				выявление и создание условий для поддержки одаренных обучающихся;
			</li>
			<li class="GoldenFundOfSiberia-items">
				развития творческой инициативы и интереса к образовательному процессу;
			</li>
			<li class="GoldenFundOfSiberia-items">
				развитие общеобразовательного кругозора учащихся;
			</li>
			<li class="GoldenFundOfSiberia-items">
				пропаганда научных знаний;
			</li>
			<li class="GoldenFundOfSiberia-items">
				привлечение внимания обучающихся к научной (научно-исследовательской) деятельности, к изучению технических,
				естественно-научных, социально-экономических, политических, правовых и культурных аспектов развития
				современного мира;
			</li>
			<li class="GoldenFundOfSiberia-items">
				содействие воспитанию ценностных ориентаций у обучающихся;
			</li>
			<li class="GoldenFundOfSiberia-items">
				создание комфортного мотивационного пространства для будущего профессионального выбора.
			</li>
		</ul>
		<hr>

		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Для кого предназначена ЗФС
		</h3>
		<p class="GoldenFundOfSiberia-sub-title">
			К участию в олимпиаде приглашаются школьники 8-11 классов, а также студенты колледжей (среднее профессиональное
			образование).
		</p>
		<p style="font-weight: 800; color:red; font-size:18px" class="GoldenFundOfSiberia-sub-title">
			Участие в олимпиадах свободное и бесплатное!!!
		</p>
		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Что дает ЗФС
		</h3>
		<p class="GoldenFundOfSiberia-sub-title">
			Победители получают возможность государственной поддержки в виде денежных стипендий.
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Победители и призеры получают уникальную возможность получить образовательные гранты.
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Победители и призеры зарабатывают дополнительные одинаковые баллы к сумме ЕГЭ при поступлении в любой
			вуз-организатор (баллы за индивидуальные достижения).
		</p>
		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Об участии в Олимпиаде
		</h3>
		<ul class="GoldenFundOfSiberia-list">
			<li class="GoldenFundOfSiberia-items">
				Отборочный (дистанционный) этап. Отборочный этап проходит на сайте irk-olimp.ru. Состязания отборочного этапа
				олимпиад проходят в дистанционной форме согласно срокам, указанным в каждом Порядке проведения олимпиады.
			</li>
			<li class="GoldenFundOfSiberia-items">
				Заключительный (очный) этап. К участию в заключительном этапе приглашаются победители и призеры отборочного
				этапа. Заключительный этап проходит очно на площадке проведения вуза-организатора Олимпиады.
			</li>
		</ul>

		<hr>
		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Список олимпиад, планируемых к проведению в 2023-2024 учебном году в рамках Открытой региональной межвузовской
			олимпиады обучающихся «Золотой фонд Сибири»
		</h3>

		<h4 class="GoldenFundOfSiberia-title _cover-title">
			Федеральное государственное бюджетное образовательное учреждение высшего образования «Байкальский
			государственный университет»
		</h4>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>№</td>
					<td>Наименование олимпиады</td>
					<td>Организатор</td>
					<td>Предметный профиль олимпиады</td>
					<td>Срок регистрации для участия в олимпиаде</td>
					<td>Период проведения отборочного этапа олимпиады</td>
					<td>Дата проведения заключительного этапа олимпиады</td>
					<td>ФИО ответственного координатора</td>
					<td>Почта ответственного координатора</td>
				</tr>
				<tr>
					<td>1</td>
					<td>Олимпиада по зарубежному страноведению и иностранным языкам</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>География, история, иностранные языки</td>
					<td>04.12.2023-11.02.2024</td>
					<td>04.12.2023-11.02.2024</td>
					<td>16.02.2024</td>
					<td>Чаликова-Уханова Мария Васильевна</td>
					<td>maha-katastrofa@mail.ru</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Финансовая грамотность для школьников</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, обществознание</td>
					<td>15.01.2024-10.03.2024 г.</td>
					<td>15.01.2024-10.03.2024 г.</td>
					<td>15.03.2024 г.</td>
					<td>Кулижская Жанна Сергеевна</td>
					<td>fef2410@mail.ru</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Финансовая грамотность для студентов СПО</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, обществознание</td>
					<td>15.01.2024-25.02.2024</td>
					<td>15.01.2024-25.02.2024</td>
					<td>01.03.2024 г.</td>
					<td>Кулижская Жанна Сергеевна</td>
					<td>fef2410@mail.ru</td>
				</tr>
				<tr>
					<td>4</td>
					<td>Молодой бухгалтер</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, обществознание</td>
					<td>01.11.2023-10.03.2024</td>
					<td>01.11.2023-10.03.2024</td>
					<td>22.03.2024 г.</td>
					<td>Копылова Екатерина Константиновна, Ткач Юлия Борисовна</td>
					<td>KopylovaEK@bgu.ru TkachUB@bgu.ru</td>
				</tr>
				<tr>
					<td>5</td>
					<td>Олимпиада по экономике и основам предпринимательства</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, обществознание</td>
					<td>16.10.2023-11.02.2024</td>
					<td>16.10.2023-11.02.2024</td>
					<td>16.02.2024</td>
					<td>Скоробогатова Юлия Александровна</td>
					<td>skorobogatova.ya@gmail.com</td>
				</tr>
				<tr>
					<td>6</td>
					<td>Олимпиада по экономике и основам предпринимательства для студентов СПО</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, обществознание</td>
					<td>16.10.2023-11.02.2024</td>
					<td>16.10.2023-11.02.2024</td>
					<td>16.02.2024</td>
					<td>Скоробогатова Юлия Александровна</td>
					<td>skorobogatova.ya@gmail.com</td>
				</tr>
				<tr>
					<td>7</td>
					<td>Лингвострановедческая олимпиада по иностранным языкам</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Иностранные языки</td>
					<td>04.12.2023-28.02.2024</td>
					<td>04.12.2023-28.02.2024</td>
					<td>23.03.2024</td>
					<td>Баклашкина Олеся Николаевна</td>
					<td>baklashkinaon@bgu.ru</td>
				</tr>
				<tr>
					<td>8</td>
					<td>Олимпиада по экономической безопасности: безопасность бизнеса и личности</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, право</td>
					<td>04.01.2024-24.03.2024</td>
					<td>04.01.2024-24.03.2024</td>
					<td>30.03.2024</td>
					<td>Гущина Ирина Владимировна</td>
					<td>GuschinaIV@bgu.ru</td>
				</tr>
				<tr>
					<td>9</td>
					<td>Конституция – основной закон Российской Федерации</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Право, обществознание</td>
					<td>05.01.2024-10.03.2024</td>
					<td>05.01.2024-10.03.2024</td>
					<td>16.03.2024</td>
					<td>Рыбинская Елена Тимофеевна</td>
					<td>RybinskayaET@bgu.ru</td>
				</tr>
				<tr>
					<td>10</td>
					<td>Олимпиада по таможенному делу</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>Экономика, обществознание</td>
					<td>05.01.2024-31.03.2024</td>
					<td>05.01.2024-31.03.2024</td>
					<td>03.04.2024</td>
					<td>Завьялова Екатерина Олеговна</td>
					<td>nalog.audit@mail.ru</td>
				</tr>
				<tr>
					<td>11</td>
					<td>География Иркутской области</td>
					<td>ФГБОУ ВО БГУ</td>
					<td>География, история</td>
					<td>05.01.2024-10.03.2024</td>
					<td>05.01.2024-10.03.2024</td>
					<td>14.03.2024</td>
					<td>Головченко Татьяна Петровна</td>
					<td>ta-gol78@mail.ru</td>
				</tr>
			</table>
		</div>

		<h4 class="GoldenFundOfSiberia-title _cover-title">
			Федеральное государственное бюджетное образовательное учреждение высшего образования «Иркутский государственный
			медицинский университет» Министерства здравоохранения Российской Федерации
		</h4>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>№</td>
					<td>Наименование олимпиады</td>
					<td>Организатор</td>
					<td>Предметный профиль олимпиады</td>
					<td>Срок регистрации для участия в олимпиаде</td>
					<td>Период проведения отборочного этапа олимпиады</td>
					<td>Дата проведения заключительного этапа олимпиады</td>
					<td>ФИО ответственного координатора </td>
					<td>Почта ответственного координатора</td>
				</tr>
				<tr>
					<td>1</td>
					<td>Химия для медицины</td>
					<td>ФГБОУ ВО ИГМУ</td>
					<td>Химия</td>
					<td>20.12.2023 - 15.01.2024</td>
					<td>20.01.2024 - 10.02.2024</td>
					<td>25.02.2024</td>
					<td>Шевченко Елена Викторовна</td>
					<td>dekanat-ped@yandex.ru</td>
				</tr>
			</table>
		</div>

		<h4 class="GoldenFundOfSiberia-title _cover-title">
			Федеральное государственное бюджетное образовательное учреждение высшего образования «Иркутский национальный
			исследовательский технический университет»
		</h4>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>№</td>
					<td>Наименование олимпиады</td>
					<td>Организатор</td>
					<td>Предметный профиль олимпиады</td>
					<td>Срок регистрации для участия в олимпиаде</td>
					<td>Период проведения отборочного этапа олимпиады</td>
					<td>Дата проведения заключительного этапа олимпиады</td>
					<td>ФИО ответственного координатора </td>
					<td>Почта ответственного координатора</td>
				</tr>
				<tr>
					<td>1</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>Математика</td>
					<td>01.12.2023-09.01.2024</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>14.04.2024</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>Физика</td>
					<td>01.12.2023-09.01.2024</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>13.04.2023</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>Химия</td>
					<td>01.12.2023-09.01.2024</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>06.04.2024</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
				<tr>
					<td>4</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>Информатика</td>
					<td>01.12.2023-09.01.2024</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>07.04.2024</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
				<tr>
					<td>5</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>Техническое черчение</td>
					<td>01.12.2023-09.01.2024</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>09.04.2024</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
				<tr>
					<td>6</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>3d моделирование инженерных объектов</td>
					<td>01.12.2023-09.01.2024</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>06.04.2024</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
				<tr>
					<td>7</td>
					<td>Межпредметная олимпиада школьников «Политехник»</td>
					<td>ФГБОУ ВО ИРНИТУ</td>
					<td>Современная энергетика</td>
					<td>01.12.2023-09.01.2034</td>
					<td>очно 09.01.2024-21.01.2024 заочно 22.01.2024-31.01.2024</td>
					<td>11.04.2024</td>
					<td>Хадкевич Наталия Алексеевна</td>
					<td>hadkevichna@ex.istu.edu</td>
				</tr>
			</table>
		</div>

		<h4 class="GoldenFundOfSiberia-title _cover-title">
			Федеральное государственное бюджетное образовательное учреждение высшего образования «Иркутский государственный
			аграрный университет им. А.А. Ежевского»
		</h4>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>№</td>
					<td>Наименование олимпиады</td>
					<td>Организатор</td>
					<td>Предметный профиль олимпиады</td>
					<td>Срок регистрации для участия в олимпиаде</td>
					<td>Период проведения отборочного этапа олимпиады</td>
					<td>Дата проведения заключительного этапа олимпиады</td>
					<td>ФИО ответственного координатора </td>
					<td>Почта ответственного координатора</td>
				</tr>
				<tr>
					<td>1</td>
					<td>Математический лидер</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>Математика</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>01.02.2023-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Голышева Светлана Павловна</td>
					<td>golyshevasp@yandex.ru</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Я люблю русский язык!</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>Русский язык</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>01.02.2023-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Факторович Татьяна Владимировна</td>
					<td>rtv-ped.irk@bk.ru</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Сибирское казачество: история и современность</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>История</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>01.02.2023-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Бондаренко Ольга Валентиновна</td>
					<td>philosophy@igsha.ru</td>
				</tr>
				<tr>
					<td>4</td>
					<td>Информатика и информационные технологии</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>Информатика</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>1.02-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Белякова Анна Юрьевна</td>
					<td>belyakova_irk@mail.ru</td>
				</tr>
				<tr>
					<td>5</td>
					<td>Экономика</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>Обществознание</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>1.02-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Власенко Ольга Владимировна</td>
					<td>vlas-olga@yandex.ru</td>
				</tr>
				<tr>
					<td>6</td>
					<td>Юный биолог</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>Биология</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>1.02-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Зацепина Ольга Станиславовна</td>
					<td>zippa-os@yandex.ru</td>
				</tr>
				<tr>
					<td>7</td>
					<td>Я - будущий энергетик</td>
					<td>ФГБОУ ВО ИрГАУ</td>
					<td>Физика</td>
					<td>09.01.2024 - 31.01.2024 г.</td>
					<td>1.02-12.03.2024</td>
					<td>22-23.03.2024</td>
					<td>Сукъясов Сергей Владимирович</td>
					<td>energo@igsha.ru</td>
				</tr>
			</table>
		</div>

		<h4 class="GoldenFundOfSiberia-title _cover-title">
			Федеральное государственное бюджетное образовательное учреждение высшего образования «Иркутский государственный
			университет путей сообщения»
		</h4>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>№</td>
					<td>Наименование олимпиады</td>
					<td>Организатор</td>
					<td>Предметный профиль олимпиады</td>
					<td>Срок регистрации для участия в олимпиаде</td>
					<td>Период проведения отборочного этапа олимпиады</td>
					<td>Дата проведения заключительного этапа олимпиады</td>
					<td>ФИО ответственного координатора </td>
					<td>Почта ответственного координатора</td>
				</tr>
				<tr>
					<td>1</td>
					<td>«Ты – в бизнесе!»</td>
					<td>ФГБОУ ВО ИРГУПС</td>
					<td>Экономика</td>
					<td>01.12.2023-31.01.2024</td>
					<td>01.12.2023-31.01.2024</td>
					<td>15.03.2024</td>
					<td>Безматерных Алина Олеговна</td>
					<td>bezmaternkh_ao@irgups.ru</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Олимпиада по информационным технологиям</td>
					<td>ФГБОУ ВО ИРГУПС</td>
					<td>Информатика</td>
					<td>06.02.2024 – 05.03.2024 </td>
					<td>06.02.2024 – 05.03.2024</td>
					<td>13.03.2024</td>
					<td>Мозолевская Анна Николаевна</td>
					<td>mozolevskiie@mail.ru</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Конкурс Компьютерной графики и дизайна</td>
					<td>ФГБОУ ВО ИРГУПС</td>
					<td>Информатика</td>
					<td>06.02.2024 – 05.03.2025</td>
					<td>06.02.2024 – 05.03.2024 </td>
					<td>15.03.2024</td>
					<td>Мозолевская Анна Николаевна</td>
					<td>mozolevskiie@mail.ru</td>
				</tr>
				<tr>
					<td>4</td>
					<td>Олимпиада</td>
					<td>ФГБОУ ВО ИРГУПС</td>
					<td>Регионоведение, экономика, экономическая география</td>
					<td>15.01.2024- 5.02.2024 </td>
					<td>06.02.2024-09.02.2024</td>
					<td>01.03.2024</td>
					<td>Шелехова Татьяна Николаевна </td>
					<td>tshel@mail.ru</td>
				</tr>
			</table>
		</div>

		<h4 class="GoldenFundOfSiberia-title _cover-title">
			Федеральное государственное бюджетное образовательное учреждение высшего образования «Иркутский государственный
			университет»
		</h4>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>№</td>
					<td>Наименование олимпиады</td>
					<td>Организатор</td>
					<td>Предметный профиль олимпиады</td>
					<td>Срок регистрации для участия в олимпиаде</td>
					<td>Период проведения отборочного этапа олимпиады</td>
					<td>Дата проведения заключительного этапа олимпиады</td>
					<td>ФИО ответственного координатора </td>
					<td>Почта ответственного координатора</td>
				</tr>
				<tr>
					<td>1</td>
					<td>Юная Психея</td>
					<td>ФГБОУ ВО ИГУ</td>
					<td>Психология</td>
					<td>20.11.2023 - 21.01.2024</td>
					<td>22.01.2024 - 25.02.2024</td>
					<td>20.03.2024</td>
					<td>Синёва Ольга Валентиновна</td>
					<td>igel-1986@yandex.ru</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Роль почв в современном мире</td>
					<td>ФГБОУ ВО ИГУ</td>
					<td>Почвоведение</td>
					<td>20.11.2023 - 11.02.2024</td>
					<td>12.02.24 - 19.02.2024 </td>
					<td>29.02.2024</td>
					<td>Куклина Светлана Леонидовна</td>
					<td>kukl_swet@mail.ru</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Межвузовская олимпиада по русскому языку как иностранному</td>
					<td>ФГБОУ ВО ИГУ</td>
					<td>Русский язык как иностранный</td>
					<td>20.11.2023 - 28.03.2024</td>
					<td>1.04.2023 - 10.04.2024 </td>
					<td>11 апреля</td>
					<td>Валиулина Светлана Владимировна</td>
					<td>svetlana-valiuli@mail.ru</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Бизнес-коммуникации</td>
					<td>ФГБОУ ВО ИГУ</td>
					<td>Менеджмент, маркетинг, продвижение, коммуникация, методы поиска творческих идей, реклама, связи с
						общественностью, туризм, информационные технологии</td>
					<td>10.11.2023- 10.12.2023 </td>
					<td>11.12.2023- 10.01.2024</td>
					<td>19.01.2023</td>
					<td>Леоненко Лариса Валентиновна</td>
					<td>lmiks@yandex.ru</td>
				</tr>
				<tr>
					<td>4</td>
					<td>Навстречу миру</td>
					<td>ФГБОУ ВО ИГУ</td>
					<td>Англиский язык</td>
					<td>25.01.2024 -02.02.2024</td>
					<td>09.02.2024 - 16.02.2024 (дистант)</td>
					<td>21.02.2023</td>
					<td>Батицкая Виктория Владимировна</td>
					<td>mbv17@yandex.ru</td>
				</tr>
			</table>
		</div>

		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Документы
		</h3>
		<a class="GoldenFundOfSiberia-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%97%D0%BE%D0%BB%D0%BE%D1%82%D0%BE%D0%B9-%D0%A4%D0%BE%D0%BD%D0%B4-%D0%A1%D0%B8%D0%B1%D0%B8%D1%80%D0%B8.pdf">
			Положение Золотой Фонд Сибири
		</a>
		<a class="GoldenFundOfSiberia-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BA-%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8E-%D0%97%D0%A4%D0%A1.pdf">
			Приказ о внесении изменений в приложения к положению ЗФС
		</a>
		<hr>

		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Учредитель олимпиады
		</h3>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://irkobl.ru/sites/minobr/olimpiada/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/лого-министерство.webp"
							alt="Логотип Министерство образования Иркутской области">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Министерство образования Иркутской области
					</p>
				</div>
			</div>
		</div>

		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Вузы организаторы
		</h3>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://angtu.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/1-1.webp"
							alt="Логотип ФГБОУ ВО «Ангарский государственный технический университет»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Ангарский государственный технический университет»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="http://bgu.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/2-1.webp"
							alt="Логотип ФГБОУ ВО «Байкальский государственный университет»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Байкальский государственный университет»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://irsau.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/3-1.webp"
							alt="Логотип ФГБОУ ВО «Иркутский государственный аграрный университет им. А.А. Ежевского»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный аграрный университет им. А.А. Ежевского»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://www.ismu.baikal.ru/ismu/news.php">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/4.webp"
							alt="Логотип ФГБОУ ВО «Иркутский государственный медицинский университет» Минздрава России">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный медицинский университет» Минздрава России
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://www.irgups.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/logo.webp"
							alt="Логотип ФГБОУ ВО «Иркутский государственный университет путей сообщения»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный университет путей сообщения»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://www.istu.edu/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/GoldenFundOfSiberia/WhatsApp-Image-2022-08-30-at-13.28.15.webp"
							alt="Логотип ФГБОУ ВО «Иркутский национальный исследовательский технический университет»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский национальный исследовательский технический университет»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://isu.ru/ru/index.html">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/GoldenFundOfSiberia/WhatsApp-Image-2022-08-30-at-13.29.16.webp"
							alt="Логотип ФГБОУ ВО «Иркутский государственный университет»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный университет»
					</p>
				</div>
			</div>
		</div>
		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Оператор олимпиады
		</h3>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://angtu.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/logo3-1.webp"
							alt="Логотип Региональный центр выявления и поддержки одаренных детей в Иркутской области «Образовательный центр Персей»">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр
						Персей"
					</p>
				</div>
			</div>

		</div>
	</div>

</template>