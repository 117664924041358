<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.10.2023
		</p>
		<h2 class="NewsPage-title">
			ОБРАЗОВАТЕЛЬНЫЕ ОРГАНИЗАЦИИ ИРКУТСКОЙ ОБЛАСТИ ПРИМУТ УЧАСТИЕ ВО ВСЕРОССИЙСКОМ КОНКУРСЕ ЛУЧШИХ ПРОГРАММ ДЕТСКОГО ОТДЫХА
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage11.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Иркутской области в сентябре этого года прошел региональный этап Всероссийского конкурса программ «Лучшая программа организации отдыха детей и их оздоровления». Как сообщили организаторы конкурса, в этом году участие приняли 25 учреждений из 12 муниципальных образований региона.
			</p>
			<p class="NewsPage-sub-title">
				– Региональный этап проходил в шести номинациях. Среди них есть и номинации для палаточных, профильных, стационарных лагерей. Лагерей с дневным пребыванием, а также труда и отдыха. Такой конкурс значительно повышает качество программно-методического обеспечения организаций отдыха детей и их оздоровления. Лучшие практики тиражируются и становятся примерами для других образовательных учреждений региона, — отметила Вера Самодурова, руководитель Центра развития конкурсного движения и детских инициатив Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				В номинации «Программа лагерей с дневным пребыванием» в следующий этап прошли разработки «Назад в будущее» лагеря «Радуга» Дома детского творчества № 2 г. Иркутск и «Чемодан профессий» Школы №7 им. Пичуева Леонида Павловича» г. Усть-Илимска.
			</p>
			<p class="NewsPage-sub-title">
				Лучшими программами в регионе для палаточных лагерей стали разработки «Кванто Camp» и «Жизнь в стиле ЭКО» многопрофильного палаточного лагеря «Страна Байкал и я» Центра развития дополнительного образования детей г. Иркутск. А программа «Исторический переполох» Иркутского городского комитета Общероссийской общественной организации «Российский Союз молодежи» стала победителем в номинации «Программа стационарных лагерей»
			</p>
			<p class="NewsPage-sub-title">
				Всероссийский этап стартует 5 октября. Итоги конкурса станут известны в декабре этого года.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, конкурс «Лучшая программа организации отдыха детей и их оздоровления» проводится при поддержке министерства образования Иркутской области.  С 2023 года оператором конкурса является Региональный центр выявления и поддержки одаренных детей «Персей».
			</p>
		</div>
	</div>
</template>
