<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			20.12.2023
		</p>
		<h2 class="NewsPage-title">
			В «Персее» проходит профильная смена для детей с инвалидностью и ограниченными возможностями здоровья
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage36.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Профильная смена «Байкальские звезды «Абилимпикс» для детей с ограниченными возможностями здоровья во второй раз проходит в Региональном центре выявления и поддержки одаренных детей «Персей», созданном в Иркутской области благодаря реализации национального проекта «Образование», инициированного Президентом Российской Федерации Владимиром Путиным. Участие в смене принимает 31 школьник из Саянска, Усть-Илимска, Зимы, Иркутска и Ангарска.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, движение «Абилимпикс» является частью президентской платформы «Россия – страна возможностей» и создает условия для людей с инвалидностью проявить свои профессиональные компетенции и получить возможности для успешного трудоустройства. Иркутская область принимает активное участие в этом движении с 2018 года. В апреле 2023 года в региональном чемпионате «Абилимпикс» по 32-м компетенциям приняли участие 197 человек, в том числе школьники.
			</p>
			<p class="NewsPage-sub-title">
				– Мы верим, что каждый ребенок обладает уникальными способностями и потенциалом, и наша цель – помочь им раскрыть их. Смена «Байкальские звезды «Абилимпикс» создана, чтобы содействовать развитию, образованию и интеграции школьников с ограниченными возможностями в общество, – отметил министр образования Иркутской области Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				По информации организаторов, образовательная программа смены нацелена на повышение престижа рабочих профессий и развитие профессионального образования путем гармонизации лучших практик и профессиональных стандартов и поможет ребятам подготовиться к конкурсам профмастерства.
			</p>
			<p class="NewsPage-sub-title">
				В региональном минобре пояснили, что участники получат возможность освоить новые навыки и умения через образовательные курсы и тренинги. Для этого запланирована серия мастер-классов от колледжей и техникумов Приангарья, студенты которых являются активными участниками чемпионата «Абилимпикс».  Ребята смогут попробовать себя в разработке Web- и мультимедийных приложений, декоративно-прикладных работах, поварской деятельности и электромонтажных работах.
			</p>
			<p class="NewsPage-sub-title">
				Также для школьников подготовлена насыщенная программа занятий по экологии, математике, робототехнике и биологии с использованием того уникального оборудования, которое приобретено за счет средств нацпроекта «Образование». Проводятся профориентационные занятия и работа с психологами по умению адаптироваться в разных ситуациях.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage36(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage36(2).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
