<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			26.12.2023
		</p>
		<h2 class="NewsPage-title">
			Более 80 школьников Приангарья подготовились к участию в региональном этапе конкурса научно-технологических проектов «Большие вызовы»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage38.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» подвели итоги третьей профильной смены «Проектная деятельность». На ежегодной программе школьники подготовились к региональному этапу отбора во Всероссийском конкурсе научно-технологических проектов «Большие вызовы», который проводит Образовательный центр «Сириус» фонда «Талант и успех».
			</p>
			<p class="NewsPage-sub-title">
				В этом году школьники Приангарья работали над проектами в шести направлениях: Агропромышленные и биотехнологии; Большие данные, искусственный интеллект, финансовые технологии и машинное обучение; Космические технологии; Современная энергетика; Умный город и безопасность; Беспилотный транспорт и логистические системы.
			</p>
			<p class="NewsPage-sub-title">
				Партнерами программы в этом году выступили сотрудники Проектного офиса Байкальского государственного университета:  Сергей Астафьев, доктор экономических наук, профессор, заведующий кафедрой экономики, строительства и управления недвижимостью, директор проектного офиса,  Людмила Троицкая, кандидат экономических наук, доцент кафедры экономики, строительства и управления недвижимостью БГУ. А также преподаватели Иркутского государственного университета путей сообщения и Иркутского аграрного университета.
			</p>
			<p class="NewsPage-sub-title">
				По словам научных руководителей смены, конкурс «Большие вызовы» – это масштабное мероприятие для старшеклассников и студентов, занимающихся научной или исследовательской деятельностью, с целью выявление и развития творческих способностей и интереса к проектной деятельности.
			</p>
			<p class="NewsPage-sub-title">
				– Ежегодно в кампусе Образовательного центра проходит профильная смена «Проектная деятельность», на которой школьники могут подготовиться к участию в региональном треке конкурса, а также включиться в работу над проектами под руководством наставников. Такая программа помогает ребятам влиться в конкурсное движение, получить актуальные знания и умения в области проектной деятельности и наметить для себя дальнейший план работы, – рассказал Сергей Астафьев.
			</p>
			<p class="NewsPage-sub-title">
				Также Людмила Троицкая отметила, что в этом году процесс обучения был построен по методике форсайта – метода группового взаимодействия, который помогает выбрать оптимальную стратегию развития проектов с учетом достоинств и недостатков идей.
			</p>
			<p class="NewsPage-sub-title">
				Одним из нововведений программы стало и то, что за каждым направлением закреплен свой наставник, который помогает ребятам в работе над проектами. Ими были выбраны преподаватели образовательных учреждений, которые курируют проектную деятельность.
			</p>
			<p class="NewsPage-sub-title">
				В рамках смены также прошла с Игорем Рыморенко, министром цифрового развития и связи Иркутской области и Владимиром Краппом, советником Председателя Байкальского банка ПАО Сбербанк. Спикеры рассказали ребятам о приоритетных направлениях научно-технологического развития региона и работе банковских систем с искусственным интеллектом.
			</p>
			<p class="NewsPage-sub-title">
				Итогом смены стала защита проектов. Оценивали работы участников программы: Роман Михайлов – директор по развитию благотворительного фонда «Сначала», экс-директор по развитию проекта «Большая байкальская тропа», Никита Россов – глава крестьянско-фермерского хозяйства «Милаша», Евгений Попов – директор организации ООО «Байкал-оптика», Михаил Халгаев – генеральный директор ООО «Цифротех».
			</p>
			<p class="NewsPage-sub-title">
				Эксперты высоко отметили научный и творческий подход участников программы. Работа над проектами не заканчивается. Школьники получили рекомендации от экспертного сообщества и преподавателей. Сейчас ребятам предстоит продолжить научно-исследовательскую деятельность над проектом, чтобы подготовиться к защите работ на региональном треке конкурса научно-технологических проектов «Большие вызовы», который пройдет в феврале 2024 года в «Персее». Лучшие работы будут рекомендованы к участию в следующем этапе организованным уже Образовательным центром «Сириус».
			</p>

		</div>
	</div>
</template>
