<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			29.01.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» продолжается подготовка к региональному этапу ВсОШ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage44.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В кампусе Образовательного центра «Персей» проходит второй блок программ по подготовке к ВсОШ. Он включает недельные смены по экологии, литературе, английскому языку и основам безопасности жизнедеятельности.
			</p>
			<p class="NewsPage-sub-title">
				В программе принимают участие 111 школьников Приангарья. Все они – победители и призеры муниципального этапа олимпиады, который завершился в конце прошлого года.
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы смены, участники программы изучат типовые ошибки при решении олимпиадных задач, а также разберут задания прошлых лет.
			</p>
			<p class="NewsPage-sub-title">
				– Всероссийская олимпиада школьников – это значимое событие для всех учащихся. Из-за усердной подготовки, ребятам иногда эмоционально тяжело. Поэтому нам особенно важно было создать все условия не только для обучения ребят, но и для их отдыха перед олимпиадой. Поэтому помимо занятий будет организована досуговая деятельность, в процессе которой школьники смогут снять напряжение и стресс, восстановить эмоциональное равновесие и получить заряд энергии для дальнейших достижений, – отметил директор Образовательного центра «Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, 10 января стартовал региональный этап ВсОШ, он продлится до 29 февраля. Дипломы победителей и призеров заключительного этапа ВсОШ в течение четырех лет дают право ребятам поступить в любой вуз страны без экзаменов по соответствующему профилю. Кроме того, победители регионального этапа, а также победители и призеры заключительного этапа олимпиады при поступлении в вузы Иркутской области имеют право на ежемесячную денежную выплату в размере 5 тысяч рублей.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage44(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage44(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage44(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage44(4).webp" alt="Картинка">
					</li>
				</ul>
			</div>
			
		</div>
	</div>
</template>
