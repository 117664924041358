<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			02.05.2024
		</p>
		<h2 class="NewsPage-title">
			В России стартовал пригласительный этап ВсОШ от «Сириуса»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage57.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>Образовательный центр «Сириус» запустил новый сезон пригласительного этапа всероссийской олимпиады школьников. С 18 апреля по 24 мая на платформе «Сириус.Курсы» будут проходить туры пригласительного этапа всероссийской олимпиады школьников по астрономии, химии, физике, биологии, математике и информатике.</b> <a class="NewsPage-link" href="https://siriusolymp.ru/rules">Расписание доступно на сайте</a>
			</p>
			<p class="NewsPage-sub-title">
				Пригласительный этап – это важный шаг в олимпиадах, который позволяет участникам познакомиться с интересными задачами, узнать что-то новое и включиться в олимпиадное движение. В пригласительный этап входят шесть предметов, а уровень заданий по сложности такой же, как на школьном этапе ВсОШ.
			</p>
			<p class="NewsPage-sub-title">
				Комплекты заданий подготовлены для 3–10-х классов, но принять участие в олимпиаде может каждый – для этого нужно зарегистрироваться на сайте и выбрать направление. Для иностранных участников предусмотрена возможность прохождения олимпиады на английском языке. Выполнять задания можно с любого устройства. Главное убедиться, что у вас есть доступ к стабильному интернету.
			</p>
			<p class="NewsPage-sub-title">
				Над задачами работали эксперты Образовательного центра «Сириус» – тренеры национальных сборных, учителя и педагоги ведущих школ страны, авторы заданий всех этапов всероссийской олимпиады школьников. Каждая задача, представленная на олимпиаде, прошла трехуровневую проверку от команды пригласительного этапа – авторов, экспертов, тестировщиков и предметных координаторов. Общий уровень сложности заданий соответствует школьному этапу всероссийской олимпиады школьников, поэтому не требует подготовки или предметных знаний, выходящих за пределы школьной программы.
			</p>
			<p class="NewsPage-sub-title">
				После отправки заданий олимпиады на проверку только все начинается: через три дня в общем доступе появятся видео- и текстовые разборы, через семь дней – окончательные результаты, а именные дипломы и сертификаты будут доступны в июне 2024 года. Те, кто захочет больше, смогут пройти бесплатные <a class="NewsPage-link" href="https://edu.sirius.online/#/">онлайн-курсы от «Сириуса»</a>, где разбираются подходы к решению задач вне школьной программы.
			</p>
			<p class="NewsPage-sub-title">
				этап входят шесть предметов, а уровень заданий по сложности такой же, как на школьном этапе ВсОШ.
			</p>
			<p class="NewsPage-sub-title">
				Комплекты заданий подготовлены для 3–10-х классов, но принять участие в олимпиаде может каждый – для этого нужно зарегистрироваться на сайте и выбрать направление. Для иностранных участников предусмотрена возможность прохождения олимпиады на английском языке. Выполнять задания можно с любого устройства. Главное убедиться, что у вас есть доступ к стабильному интернету.
			</p>
			<p class="NewsPage-sub-title">
				Над задачами работали эксперты Образовательного центра «Сириус» – тренеры национальных сборных, учителя и педагоги ведущих школ страны, авторы заданий всех этапов всероссийской олимпиады школьников. Каждая задача, представленная на олимпиаде, прошла трехуровневую проверку от команды пригласительного этапа – авторов, экспертов, тестировщиков и предметных координаторов. Общий уровень сложности заданий соответствует школьному этапу всероссийской олимпиады школьников, поэтому не требует подготовки или предметных знаний, выходящих за пределы школьной программы.
			</p>
			<p class="NewsPage-sub-title">
				Зарегистрироваться на пригласительный этап можно <a class="NewsPage-link" href="https://siriusolymp.ru/">на сайте олимпиады</a>. Принять участие можно во всех предметах, но надо зарегистрироваться на каждый отдельно.
			</p>
			<p class="NewsPage-sub-title">
				Следить за обновлениями можно в сообществе <a class="NewsPage-link" href="https://vk.com/siriusolymp">«ВКонтакте»</a> и Telegram-канале <a class="NewsPage-link" href="https://t.me/siriusolymp">«Сириус Олимп: все об олимпиадах»</a>.
			</p>

		</div>
	</div>
</template>
