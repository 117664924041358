<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			20.11.2023
		</p>
		<h2 class="NewsPage-title">
			Специалисты «Персея» и Лимнологического института СО РАН провели интерактивную лекцию о Байкале для посетителей Международной выставки-форума «Россия»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage24.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Интерактивная лекция «Байкал – природная лаборатория» состоялась для посетителей Международной выставки-форума «Россия» в День Иркутской области, 17 ноября. Мероприятие провели Региональный центр выявления и поддержки одаренных детей «Персей» совместно с Лимнологическим институтом СО РАН. Об этом рассказали в министерстве образования Иркутской области.
			</p>
			<p class="NewsPage-sub-title">
				В ходе мероприятия слушателям показали видеофильм об озере Байкал и экспедициях, совершаемых школьниками Иркутской области – участниками профильных смен Образовательного центра «Персей» с учеными-лимнологами.
			</p>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов рассказал, что Образовательный центр «Персей» создан в регионе благодаря федеральному проекту «Успех каждого ребенка» президентского национального проекта «Образование» по модели Образовательного центра «Сириус» (г. Сочи). Его высоко технологичное оборудование позволяет педагогам в рамках профильных смен организовывать настоящую научную работу и эксперименты со школьниками.
			</p>
			<p class="NewsPage-sub-title">
				– Участие в профильных сменах «Байкал – природная лаборатория» дает школьникам, прошедшим отбор, уникальную возможность участия в научных экспедициях Лимнологического института Сибирского отделения Российской академии наук. Вместе с учеными института ребята участвуют в научных исследованиях, изучают экосистему озера Байкал, делают свои первые маленькие открытия, – подчеркнул Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Он отметил, что Байкал – это всемирное наследие, поэтому всё, что с ним связано, всегда интересно и познавательно для самых разных категорий слушателей, в том числе среди посетителей выставки-форума «Россия».
			</p>
			<p class="NewsPage-sub-title">
				Спикер мероприятия научный сотрудник Лимнологического института, кандидат биологических наук Татьяна Майор рассказала об уникальности флоры и фауны озера Байкал. Посредством микроскопа с видеоадаптером зрителям наглядно продемонстрировали обитателей подводного мира (рачков и моллюсков). Слушатели также познакомились с историей изучения Байкала, экспедиционными исследованиями озера, экологическими проблемами озера и путями их решения.
			</p>
			<p class="NewsPage-sub-title">
				По материалам пресс-службы Правительства Иркутской области
			</p>

		</div>
	</div>
</template>
