<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			Региональная олимпиада для обучающихся начальных классов
		</h2>
		<h2 class="EventsPage-title">
			«Олимпик»
		</h2>
		<p class="EventsPage-date">
			с 1 ноября по 15 декабря 2021 года
		</p>

		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr>

		<h3 class="EventsPage-prof">
			Уважаемые участники! Дипломы победителям и сертификаты участникам будут отправлены на почты участников в 2022 году.
		</h3>

		<p class="JuniorProfi-sub-title">
			Учредитель Олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Оператор Олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/12/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA.pdf" target="_blank">
			Приказ Об итогах региональной олимпиады Олимпик
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/10/%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA-1.pdf" target="_blank">
			Положение Олимпик
		</a>
		<hr>

		<p class="EventsPage-text">
			Срок проведения Олимпиады – с 1 ноября по 15 декабря 2021 года.
		</p>
		<p class="EventsPage-text">
			<b>Формат проведения – заочный.</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Основными целями и задачами Олимпиады являются:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				развитие познавательной активности младших школьников;
			</li>
			<li class="JuniorProfi-items">
				выявление и поддержка одаренных и высокомотивированных обучающихся начальных классов;
			</li>
			<li class="JuniorProfi-items">
				подготовка обучающихся начальных классов к участию в региональных, всероссийских и международных олимпиадах и конкурсах средней и старшей ступени обучения.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			В Олимпиаде могут принять участие на добровольной основе обучающиеся <b>третьих и четвертых классов</b>
		</p>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			<b>Олимпиада проводится по следующим общеобразовательным предметам:</b>
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				математика;
			</li>
			<li class="JuniorProfi-items">
				русский язык;
			</li>
			<li class="JuniorProfi-items">
				окружающий мир.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Список победителей и призеров утверждается правовым актом Регионального института кадровой политики в срок <b>до 10 декабря 2021 года.</b>
		</p>

	</div>
</template>