<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			16.10.2023
		</p>
		<h2 class="NewsPage-title">
			В «ПЕРСЕЕ» СТАРТОВАЛИ ОКТЯБРЬСКИЕ НАУЧНЫЕ СМЕНЫ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage16.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В кампусе Образовательного центра «Персей» сейчас проходят обучение более 80 школьников, которые приехали на научные октябрьские программы: «Шаг в мир науки» и «Беспилотные авиационные системы». Ребята углубленно знакомятся с Web – индустрией и изучают основные темы физики.
			</p>
			<p class="NewsPage-sub-title">
				<b>«Шаг в мир науки»</b>
			</p>
			<p class="NewsPage-sub-title">
				Сейчас у участников научных профильных смен «Персея» идёт интенсивная подготовка по предметам: физика и информатика. В рамках направлений школьники изучают основы Web – индустрии, двоичного поиска, комбинаторики, программирования, а также разделы физики: строение веществ, взаимодействие тел, движение жидкостей и газов, работа и мощность, энергия.
			</p>
			<p class="NewsPage-sub-title">
				Как пояснили организаторы смены, это вторая программа для школьников 5-10 классов, на которой они проходят обучение по двум модулям. Первая профильная смена «Шаг в мир науки» завершилась 13 октября этого года.
			</p>
			<p class="NewsPage-sub-title">
				– На прошлой смене школьники показали высокий результат освоения программы. Показателем этого стали их проекты, которые они представили в завершении обучения. Информатики смогли сверстать свой сайт на платформе «Figma», а физики провели серию экспериментальных работ. Уверены, что итоговые работы этого набора будут таким же сильными, – рассказала Татьяна Толстихина, руководитель Отдела развития образовательных программ по направлению «Наука»
			</p>
			<p class="NewsPage-sub-title">
				<b>«Беспилотные авиационные системы»</b>
			</p>
			<p class="NewsPage-sub-title">
				В ходе смены для участников программы пройдут занятия по аэродинамике, динамике полета самолета и основам гидроавиации. Школьники познакомятся с принципом работы беспилотных летательных аппаратов, научатся их собирать и ремонтировать. Освоят 3д-моделирование и аэрофотосъемку, а также смогут отработать спортивные навыки по авиамодельному спорту в классе моделей F9U.
			</p>
			<p class="NewsPage-sub-title">
				В преподавательский состав вошли сотрудники Авиационного техникума факультета «Авиационных систем и комплексов» Иркутского филиала Московского Государственного технического университета гражданской авиации.
			</p>
			<p class="NewsPage-sub-title">
				– Авиация всегда была востребованным направлением подготовки, поэтому нам важно, чтобы на этой смене была проведена ранняя профориентация по профессиям, связанными с инженерным авиамоделированием и с управлением беспилотными аппаратами. Мы подготовили насыщенную программу, где каждый школьник, увлеченный авиацией, сможет найти свою стезю, – отметил Михаил Портнов, начальник отделения среднего профессионального образования факультета «Авиационных систем и комплексов» Иркутского филиала Московского Государственного технического университета гражданской авиации.
			</p>
			<p class="NewsPage-sub-title">
				Завершится смена командными соревнованиями по запуску моделей самолета, которые ребята спроектируют во время обучения на профильной смене в «Персее».
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage16(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage16(2).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
