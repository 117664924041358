<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage25' })">
						<events-card25></events-card25>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage46' })">
						<events-card46></events-card46>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage27' })">
						<events-card27></events-card27>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage51' })">
						<events-card51></events-card51>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard27 from '../EventsCard/EventsCard27';
import EventsCard51 from '../EventsCard/EventsCard51';
import EventsCard46 from '../EventsCard/EventsCard46';
import EventsCard25 from '../EventsCard/EventsCard25';

	export default {
		components: {
			EventsCard27,
			EventsCard51,
			EventsCard46,
			EventsCard25,
		}
	}
</script>
