<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Конкурс
		</h3>
		<h2 class="EventsPage-title">
			Конкурс к «Международному женскому дню» для школьников с 10 до 17 лет
		</h2>
		<p class="EventsPage-date">
			с 4 по 22 марта
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=142" target="_blank">
			Moodle
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Персей» проводит конкурс к «Международному женскому дню» для школьников с 10 до 17 лет.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Цель мероприятия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Побудить участников задуматься о значимости и важности Международного женского дня, а также о роли женщин в современном обществе;
			</li>
			<li class="JuniorProfi-items">
				Способствовать развитию навыков письменной коммуникации и аналитического мышления участников;
			</li>
			<li class="JuniorProfi-items">
				Формировать чувства материнства, любви к семье, семейным традициям, представления о празднике 8 Марта, о его истории и традициях;
			</li>
			<li class="JuniorProfi-items">
				Стимулировать учащихся, педагогов к участию в сетевых проектах.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Конкурсные номинации (направления):
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Прекрасный день 8 Марта»;
			</li>
			<li class="JuniorProfi-items">
				«Мамы они такие ….»;
			</li>
			<li class="JuniorProfi-items">
				«Супербабушка»;
			</li>
			<li class="JuniorProfi-items">
				«Славим женщину России».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сроки:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				с 4.03.2024 года по 15.03.2024 года включительно осуществляется сбор конкурсных работ. Конкурсные работы, поступившие после окончания срока приема конкурсных работ, к Конкурсу не допускаются;
			</li>
			<li class="JuniorProfi-items">
				с 15.03.2024 года по 22.03.2024 года включительно осуществляется подведение итогов.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5-08.03..pdf" target="_blank">
			Положение о конкурсе 08.03.2024
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/1ytzSnR5POftwg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сорока Сергей Сергеевич
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

	</div>
</template>