<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">Олимпиада</h3>
		<h2 class="EventsPage-title">
			"Высшая проба"
		</h2>
		<h2 class="EventsPage-title">
			<img class="EventsPage-title-img" src="../assets/img/svg/VysshayaProba.webp" alt="Высшая проба" loading="lazy" />
		</h2>
		<hr />
		<div class="EventsPage-disc">
			<h3 class="EventsPage-nte">Об олимпиаде «Высшая проба»:</h3>
			<p class="EventsPage-nte-dis">Дорогие друзья! Приветствуем вас в числе участников втортого (заключительного) этапа
				Всероссийской олимпиады школьников "Высшая проба" в г. Иркутске</p>
			<p class="EventsPage-nte-dis">Олимпиадные состязания будут проходить с 08 по 18 февраля по адресу: г. Иркутск, ул.
				Рабочего штаба 19а (рядом с КДЦ "Марат") остановка общественного транспорта "Ремесленное училище" троллейбус №3,
				автобус №67, №8, №32, №27</p>
			<p class="EventsPage-nte-dis">Время начала олимпиадных состязаний с 15:00. Прибыть на пункт проведения олимпиады
				рекомендуем за 20-30 минут.</p>
			<p class="EventsPage-nte-dis">При себе необходимо иметь документ, удостоверяющий личность: паспорт или
				свидетельство о рождении, распечатанный из личного кабинета титульный лист, ручку черного или синего цвета.</p>
			<p class="EventsPage-nte-dis">Допускается наличие воды, шоколада, бананы, очки.</p>
			<hr />

			<h3 class="EventsPage-nte">Не разрешается использовать и даже иметь при себе и на рабочем месте:</h3>
			<ul class="JuniorProfi-list">
				<li class="JuniorProfi-items">справочные материалы (за исключением участников профиля «химия» - материалы будут
					выданы);</li>
				<li class="JuniorProfi-items">калькуляторы (за исключением участников профилей «биология», «география»,
					«инженерные науки», «основы бизнеса», «физика», «финансовая грамотность», «химия», «экономика»);</li>
				<li class="JuniorProfi-items">свою бумагу: бумагу для черновиков дадут организаторы;</li>
				<li class="JuniorProfi-items">карманные компьютеры и любые иные электронно-вычислительные устройства;
					смарт-часы; мобильные телефоны и иные средства связи; плееры.</li>
			</ul>

			<p class="EventsPage-nte-dis">
				<b>Обращаем внимание: участник имеет право опоздать не более чем на 30 минут. Участники, опоздавшие к началу
					состязаний не более чем на 30 минут, имеют право принять в них участие, но время выполнения заданий для них не
					продлевается.</b>
			</p>
			<hr />

			<p class="JuniorProfi-sub-title">Контакты:</p>
			<ul class="JuniorProfi-list">
				<li class="JuniorProfi-items">Контактное лицо: Самодурова Вера Геннадьевна</li>
				<li class="JuniorProfi-items">Телефон: 8 (3952) 546-044 </li>
				<li class="JuniorProfi-items">Телефон: 8 924 711 78 35</li>
				<li class="JuniorProfi-items">Эл. почта: v.samodurova@perseusirk.ru</li>
			</ul>
			<hr>

			<p class="JuniorProfi-sub-title">
				Документы:
			</p>
			<a class="EventsPage-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/2023_%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D1%80%D0%B0%D0%B7%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D0%BA%D0%B0%D0%BB%D1%8C%D0%BA%D1%83%D0%BB%D1%8F%D1%82%D0%BE%D1%80%D0%BE%D0%B2.pdf"
				target="_blank">
				СПИСОК РАЗРЕШЕННЫХ КАЛЬКУЛЯТОРОВ
			</a>

			<div class="StructureAndOrgans-table">
				<table>
					<tr>
						<td colspan="5" style="text-align: center">
							<b>Расписание проведения второго (заключительного) этапа олимпиады «Высшая проба» 2023/2024 учебного года
								г. Иркутск, ул. Рабочего штаба 19а (рядом с КДЦ «Марат»)</b>
						</td>
					</tr>
					<tr>
						<td>День проведения</td>
						<td>Время состязания</td>
						<td>Профиль олимпиады</td>
						<td>Продолжительность состязания</td>
						<td>Аудитория</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="3">08.02.2024 четверг</td>
						<td>15:00 -19:00</td>
						<td>Филология</td>
						<td>240 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>15:00-18:00</td>
						<td>Математика</td>
						<td>7,8 классы - 180 мин</td>
						<td>10 ауд.</td>
					</tr>
					<tr>
						<td>15:00 -19:00</td>
						<td>Математика</td>
						<td>9, 10, 11 классы - 240 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="4">09.02.2024 пятница</td>
						<td>15:00-17:30</td>
						<td>Социология</td>
						<td>150 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>15:00-19:00</td>
						<td>Информатика</td>
						<td>240 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>18:00-21:00</td>
						<td>Восточные языки</td>
						<td>180 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>18:00-21:00</td>
						<td>Дизайн</td>
						<td>180 мин</td>
						<td>10 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="2">10.02.2024 суббота</td>
						<td>15:00-18:00</td>
						<td>Обществознание</td>
						<td>180 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>19:00-21:00</td>
						<td>Востоковедение</td>
						<td>120 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="3">11.02.2024 воскресенье</td>
						<td>15:00-18:00</td>
						<td>Культурология</td>
						<td>180 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>15:00-19:00</td>
						<td>Биология</td>
						<td>240 мин</td>
						<td>9,10 ауд.</td>
					</tr>
					<tr>
						<td>19:00-21:00</td>
						<td>Право</td>
						<td>120 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="2">12.02.24 понедельник</td>
						<td>15:00-18:20</td>
						<td>Экономика</td>
						<td>200 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>18:30- 21:00</td>
						<td>Философия</td>
						<td>150 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="4">13.02.2024 вторник</td>
						<td>15:00-18:00</td>
						<td>Международные отношения</td>
						<td>180 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>15:00-19:00</td>
						<td>Инженерные науки</td>
						<td>240 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>15:00-19:00</td>
						<td>Химия</td>
						<td>240 мин</td>
						<td>10 ауд.</td>
					</tr>
					<tr>
						<td>19:00-21:00</td>
						<td>Финансовая грамотность</td>
						<td>120 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="4">14.02.2024 среда</td>
						<td>15:00-19:00</td>
						<td>География</td>
						<td>240 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>15:30-17:30</td>
						<td>Психология</td>
						<td>120 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>19:00-20:30</td>
						<td>ИМЦ</td>
						<td>9 класс-90 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>19:00-21:00</td>
						<td>ИМЦ</td>
						<td>10,11 класс-120 мин</td>
						<td>10 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="3">15.02.2023 четверг</td>
						<td>15:00-17:00</td>
						<td>История</td>
						<td>120 мин</td>
						<td>9,10 ауд.</td>
					</tr>
					<tr>
						<td>15:00-19:00</td>
						<td>Физика</td>
						<td>240 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>18:00-21:00</td>
						<td>Основы бизнеса</td>
						<td>180 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="2">16.02.2024</td>
						<td>15:00-18:00</td>
						<td>Русский язык</td>
						<td>7,8,9,10 классы - 180 мин</td>
						<td>9 ауд.</td>
					</tr>
					<tr>
						<td>17:00-20:00</td>
						<td>Русский язык</td>
						<td>11 классы - 180 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowspan="3">17.02.2024</td>
						<td>15:00-17:00</td>
						<td>Иностранные языки</td>
						<td>7,8 классы-120 мин</td>
						<td>4 ауд.</td>
					</tr>
					<tr>
						<td>15:00-18:00</td>
						<td>Политология</td>
						<td>180 мин</td>
						<td>10 ауд.</td>
					</tr>
					<tr>
						<td>19:00-21:00</td>
						<td>Иностранные языки</td>
						<td>9,10,11 классы- 120 мин</td>
						<td>9,10,4 ауд.</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>18.02.2024</td>
						<td>15:00-19:00</td>
						<td>Журналистика</td>
						<td>240 мин</td>
						<td>9 ауд.</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="Contacts-map">
			<h2 class="Contacts-title _cover-title">
				Место проведения на карте
			</h2>
			<iframe class="Contacts-map-items"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d725.3371043468136!2d104.29716120303671!3d52.30269164296132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5da83b2bdd2382e3%3A0xfc7147023a698cb1!2z0YPQuy4g0KDQsNCx0L7Rh9C10LPQviDQqNGC0LDQsdCwLCAxNSwg0JjRgNC60YPRgtGB0LosINCY0YDQutGD0YLRgdC60LDRjyDQvtCx0LsuLCA2NjQwMDE!5e0!3m2!1sru!2sru!4v1705989778577!5m2!1sru!2sru"
				allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
				Местоположение на карте
			</iframe>
		</div>


	</div>
</template>
