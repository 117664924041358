<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			12.10.2023
		</p>
		<h2 class="NewsPage-title">
			ПРЕПОДАВАТЕЛИ И УЧАСТНИКИ ПРОФИЛЬНОЙ СМЕНЫ «ВОЛОНТЁРЫ КУЛЬТУРЫ» РАССКАЗАЛИ, КАК ПРОХОДИЛА ПРОГРАММА
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage13.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Осенью этого года в кампусе Образовательного центра «Персей» во второй раз прошла профильная смена «Волонтеры культуры». Обучение было направлено на популяризацию добровольчества среди школьников в сфере культуры. Как проходила программа для юных волонтеров, рассказали преподаватели и участники смены.
			</p>
			<p class="NewsPage-sub-title">
				Марк Волков, ученик 8 класса школы №19 г. Иркутска, приехал в «Персей» впервые. Школьник прошел отбор на программу и стал участником профильной смены «Волонтеры культуры». Вместе с однокурсниками он разработал проект по созданию волонтерских отрядов в каждом населённом пункте области.
			</p>
			<p class="NewsPage-sub-title">
				– Каждое занятие было направлено на знакомство и получение актуальных навыков в сфере культурного добровольчества. Мы ежедневно придумывали разные проекты: от разработки библиотеки до составления туристического маршрута. Знакомились с движением «Волонтеров культуры» и посещали встречи со спикерами, которые делились с нами своим опытом. В середине смены нам дали кейсы. Перед нами стояла задача решить их и оформить идеи в социокультурный проект. Нашей команде достался не простой кейс: нужно было продумать, как создать волонтерские отряды в каждом уголке нашей области. Решение, которое мы представили, способствует развитию сферы добровольчества на территории всей Иркутской области, а также сохранению культурного и исторического кода региона. Наш проект стал одним из лучших, и мы смогли представить его Светлане Жановне, заместителю министра культуры Иркутской области. Она высоко оценила наши труды. Это заслуга и преподавателей, которые работали с нами на смене, – поделился Марк.
			</p>
			<p class="NewsPage-sub-title">
				Как отмечают преподаватели, школьники получили актуальные практические кейсы по саморазвитию в сфере культурного добровольчества. Примечательно и то, что данные кейсы были распределены таким образом, чтобы они могли быть реализованы самими ребятами на той территории, на которой они живут и обучаются. 
			</p>
			<p class="NewsPage-sub-title">
				– В целом за смену выявлено около 100 проектных идей, которые могут быть реализованы по всему региону, – подчеркнул преподаватель и  руководитель регионального отделения Всероссийского общественного движения «Волонтеры культуры» Родион Шантанов. – Основной же целью для нас являлось создание групповых проектов для муниципалитетов в рамках проектных сессий. Дети смогли представить качественные работы, в которых предусмотрены четко поставленные цели, задачи и этапы, сформулированы конкретные целевые группы и ожидаемые результаты, а также описаны возможности создания партнерских сетей для более успешной реализации инициатив. Однако, при всей сложности описания проектов, каждая группа смогла достаточно качественно прописать социальную эффективность своих проектов, что очень важно для понимания того, что они реализуют и чего хотят достичь.
			</p>
			<p class="NewsPage-sub-title">
				Помимо работы над социокультурными проектами, волонтеры разрабатывали квизы и викторины, придумывали и оформляли бренд волонтеров культуры, выстраивали контент-план для учреждений культуры, снимали видеоролики о смене и добровольчестве.
			</p>
			<p class="NewsPage-sub-title">
				– Главным образом, вся программа профильной смены рассчитана на освоение компетенций в направлениях сохранения материального и нематериального культурного наследия, работы с учреждениями культуры разных форм, организацию социокультурной проектной деятельности, ведение добровольческих отрядов на базе образовательных и культурных учреждений в муниципалитетах и др., – рассказал Родион Шантанов.
			</p>
			<p class="NewsPage-sub-title">
				Так Марка назначили на должность руководителя Иркутского муниципального округа Волонтеров Культуры, а также посвятили в активисты регионального движения волонтеров.
			</p>
			<p class="NewsPage-sub-title">
				– Должности распределялись в зависимости от способностей ребенка и его активности на уроках. Я был усердным и активным учеником, а также разработал свой личный проект, который получил огромную поддержку от преподавателей. За мою работу на смене мне и предложили занять должность руководителя и войти в состав активистов, – подчеркнул школьник.
			</p>
			<p class="NewsPage-sub-title">
				На программе волонтеры, также познакомились с модельными библиотеками Иркутской области, которые созданы по нацпроекту «Культура». А также попробовали себя в качестве участников экспертного жюри конкурса «БиблиоЛайк», который проводится Иркутской областной юношеской библиотеки им. И.П. Уткина.
			</p>
			<p class="NewsPage-sub-title">
				Преподавателями и наставниками стали опытные добровольцы, авторы проектов, руководители некоммерческих организаций, специалисты Иркутской областной юношеской библиотеки им. И.П. Уткина, архитекторы и художники.
			</p>
			<p class="NewsPage-sub-title">
				– Данная смена продолжила этап становления детского актива волонтеров культуры и определила векторы развития направления. Мы вовлекли новых волонтеров, которые будут реализовывать социокультурные проекты на своих территориях и на региональном уровне. Среди таких проектов есть инициативы по созданию волонтерских центров, проведению добровольческих форумов, концертов, экскурсий, популяризации объектов культурного наследия, – рассказала Александра Горчакова, преподаватель, координатор по информационно-просветительской работе и работе волонтеров культуры на территории муниципальных образований Иркутской области.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, профильная смена проходила под эгидой Всероссийского общественного движения «Волонтеры культуры» совместно с министерством культуры Иркутской области. Участие в ней приняли 75 школьников из Иркутского, Заларинского, Тайшетского, Балаганского, Усольского, Бодайбинского, Нукутского, Зиминского, Черемховского, Баяндаевского, а также из г. Иркутск, г. Ангарск, г. Черемхово, г. Братска, г. Байкальск, г. Киренск и п. Усть-Уда.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage13(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage13(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage13(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage13(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage13(5).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
