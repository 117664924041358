<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Зимняя интеллектуальная общеразвивающая школа»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 12 декабря по 25 декабря 2023 года.
		</h4>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24099-programma-zimnyaya-intellektualnaya-obshcherazvivayushchaya-shkola" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Зимняя интеллектуальная общеразвивающая школа» для обучающихся 10-17-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа направлена на развитие научно-технических знаний, профессионально-прикладных навыков и создание условий для социального, культурного и профессионального самоопределения, творческой самореализации личности.
		</p>
		<p class="EventsPage-text">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/uaLzlxW8EVa6LQ" target="_blank">
			Сертификаты
		</a>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>