<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.10.2023
		</p>
		<h2 class="NewsPage-title">
			В «ПЕРСЕЕ» ПРОХОДИТ ПРОГРАММА ДЛЯ ВОЛОНТЕРОВ КУЛЬТУРЫ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage8.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» стартовала программа «Волонтеры культуры». Курс занятий направлен популяризацию добровольчества среди школьников в сфере культуры. В ходе обучения юные волонтеры узнают о направлениях движения, значимых культурных региональных проектах и даже разработают свои.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена в Образовательном центре проходит во второй раз. В этом году участие в ней принимают 75 школьников из Иркутского, Заларинского, Тайшетского, Балаганского, Усольского, Бодайбинского, Нукутского, Зиминского, Черемховского, Баяндаевского, а также из г. Иркутск, г. Ангарск, г. Черемхово, г. Братска, г. Байкальск, г. Киренск и п. Усть-Уда.
			</p>
			<p class="NewsPage-sub-title">
				На программе с участниками работают ведущие специалисты в сфере добровольчества, а также сотрудники Иркутских библиотек, центров культуры, школ и учреждений дополнительного образования.
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы смены, в этом году основной уклон будет сделан на развитие добровольческих компетенции и обмен опыта между волонтерами.
			</p>
			<p class="NewsPage-sub-title">
				– Смена уникальна тем, что благодаря знакомству участников из разных районов, мы создаем объединение неравнодушных и активных школьников в деле сохранения и популяризации историко-культурного наследия не только региона, но и страны. Участники прошлого года до сих пор поддерживают активную связь между собой и реализуют совместные проекты. Мы хотим сделать сильное комьюнити из заинтересованных детей, в котором каждый найдет свое предназначение, – подчеркнула Александра Горчакова, координатор по информационно-просветительской работе и работе волонтеров культуры на территории муниципальных образований Иркутской области.
			</p>
			<p class="NewsPage-sub-title">
				Примечательно, что добровольцы получают не только теоретические знания и практический опыт на смене, но и активно развивают новые для себя компетенции в сфере медиа, реставрации, организации культурных мероприятий и краеведения.
			</p>
			<p class="NewsPage-sub-title">
				Для школьников также пройдут мастер-классы, встречи с интересными людьми и тренинги. В завершении смены участники представят свои проекты. Лучшие инициативы будут реализованы.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, профильная смена проходит под эгидой Всероссийского общественного движения «Волонтеры культуры», совместно с министерством культуры Иркутской области.
			</p>
		</div>
	</div>
</template>
