<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дополнительная профессиональная программа повышения квалификации
		</h3>
		<h2 class="EventsPage-title">
			«Особенности работы с одаренными детьми в области инструментального исполнительства» (струнно-смычковые
			инструменты)
		</h2>
		<p class="EventsPage-date">
			с 5 июля по 12 июля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=179" target="_blank">
			Moodle
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит набор слушателей
			по дополнительной профессиональной программе повышения квалификации «Особенности работы с одаренными детьми в
			области инструментального исполнительства» (струнно-смычковые инструменты).
		</p>

		<p class="EventsPage-text">
			Объем Программы: 54 часа.
		</p>

		<p class="EventsPage-text">
			К освоению Программы допускаются лица, имеющие среднее профессиональное и (или) высшее образование.
		</p>

		<p class="EventsPage-text">
			По итогам обучения при условии успешного прохождения курсовой подготовки слушателям выдается удостоверение
			установленного образца о повышении квалификации.
		</p>

		<p class="EventsPage-text">
			Курсовая подготовка будет проходить в срок с 5 июля по 12 июля 2024 года. Форма обучения: очно-заочная с
			применением дистанционных образовательных технологий.
		</p>

		<p class="EventsPage-text">
			Заочный этап - с 5 по 9 июля 2024 года осуществляется на образовательной платформе Moodle ОЦ «Персей» (ссылка на
			электронно-информационную образовательную среду ОЦ «Персей» будет отправлена после заполнения заявки).
		</p>

		<p class="EventsPage-text">
			Очный этап (стажировка) - с 10 по 12 июля 2024 года, будет проходить на базе кампуса Образовательного центра
			«Персей», по адресу: Иркутская область, Ангарский район, 8.351 км автодороги Ангарск-Тальяны.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Для записи на курсы необходимо перейти по ссылке: https://forms.yandex.ru/cloud/66723f64d046881ec81d5270/.
			</li>
			<li class="JuniorProfi-items">
				Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе
				электронной информационно-образовательной среды сайта ОЦ «Персей» до 5 июля 2024 года по ссылке:
				https://moodle.perseusirk.ru/course/view.php?id=179
			</li>
			<li class="JuniorProfi-items">

			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Акимова Татьяна Юрьевна - заслуженный работник культуры и искусства Иркутской области, заведующая
				предметно-цикловой комиссии «Оркестровые струнные инструменты» Иркутского областного музыкального колледжа им.
				Ф. Шопена, художественный руководитель и дирижер струнного и симфонического оркестров колледжа, преподаватель
				высшей квалификационной категории.
			</li>
		</ul>
		<hr>


		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна, заведующий отделом развития образовательных программ по
				направлению «Искусство», тел 8(3952) 54-60-44 (доб. 5).
			</li>
		</ul>

	</div>
</template>