<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Олимпиады и конкурсы
		</h2>
		<h3 class="EventsCard-title">
			Конкурс постов «Мой 2023 год»
		</h3>
		<p class="EventsCard-sub-title">
			с 11 по 25 декабря 2023 года.
		</p>
	</div>
</template>