<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			17.06.2024
		</p>
		<h2 class="NewsPage-title">
			Новые Чемпионы: В «Персее» подвели итоги первых летних тренировочных сборов по борьбе
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage66.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одаренных детей «Персей» прошли первые спортивные смены по самбо,
				дзюдо и греко-римской борьбе. Участниками образовательных программ стали 160 воспитанников спортивных школ из
				Иркутска, Ангарска, Усолье-Сибирского, п. Усть-Ордынский, Иркутского и Эхирит-Булагатского районов.
			</p>
			<p class="NewsPage-sub-title">
				На протяжении двух недель юные борцы региона принимали участие в насыщенной образовательной программе,
				включающей в себя как практические тренировки в партере, так и теоретические занятия, где ребята узнавали о
				правилах и стратегиях борьбы, истории и развитии этих видов спорта.
			</p>
			<p class="NewsPage-sub-title">
				Опытные тренеры и мастера спорта делились своими знаниями и навыками, помогая детям улучшить технику, физическую
				подготовку и тактическое мышление.
			</p>
			<p class="NewsPage-sub-title">
				По словам преподавателей программ, тренировочные сборы – один из эффективных способов не только подготовиться к
				предстоящему тренировочному сезону, но и развить важные личностные качества спортсмена, а а также укрепить
				здоровье и отдохнуть от соревновательного сезона.
			</p>
			<p class="NewsPage-sub-title">
				– Одна из главных задач учебно-тренировочных сборов – это подготовка к началу спортивного старта, а это:
				Первенство области и Сибирского федерального округа. За это время мы выявили и учли все ошибки, над которыми
				нужно работать, и сконцентрировались на их исправлении. Ребята продемонстрировали высокий уровень самоотдачи и
				целеустремленности, что позволило нам существенно повысить их спортивные показатели. Мы уделили внимание каждому
				аспекту подготовки: от технической и тактической выучки до физической и психологической готовности, – пояснил
				Илья Ольховой, тренер-преподаватель, мастер спорта России по универсальному бою.
			</p>
			<p class="NewsPage-sub-title">
				Помимо подготовки к предстоящим соревнованиям для ребят прошла спартакиада приуроченная к Олимпийскому дню,
				которая включала игры ГТО, эстафеты и командные соревнования по футболу. Также для школьники были организован
				чемпионат по шашкам и шахматам, теннису и мастер-классы от заслуженных тренеров России.
			</p>
			<p class="NewsPage-sub-title">
				Так к участникам программы «Греко-римская борьба» с мастер-классом приехал Александр Бриль – мастер спорта
				СССР, заслуженный тренер России по греко-римской борьбе. Александр поделился своим практическим опытом, который
				он сам оттачивал годами.
			</p>
			<p class="NewsPage-sub-title">
				На мастер-классе юные спортсмены узнали о ключевых моментах подготовки к поединкам, тактических приемах и
				стратегиях, необходимых для достижения успеха на борцовском ковре. Тренер также уделил внимание вопросам
				психологической подготовки, которая играет важную роль в соревнованиях.
			</p>
			<p class="NewsPage-sub-title">
				Ко Дню России для участников смен «Дзюдо» и «Самбо» прошло ежегодное первенство «Персея», где ребята
				смогли продемонстрировать свои навыки и умения, приобретенные во время тренировочных сборов. Ребята показали не
				только высокий уровень подготовки, но и умение держать себя в руках, соблюдать правила честной борьбы и
				проявлять уважение к соперникам.
			</p>
			<p class="NewsPage-sub-title">
				– Итоговые соревнования показали, насколько высок уровень их подготовки и насколько они выросли за это время
				подготовки в рамках смены. Мы очень гордимся каждым из них и уверены, что впереди их ждут большие победы, –
				отметил директор Образовательного центра «Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Смена проходила при поддержке министерства спорта Иркутской области и Иркутской региональной общественной
				организации «Федерация самбо» и областной федерации дзюдо. 17 июня в кампусе «Персея» пройдут вторые
				учебно-тренировочные сборы по дзюдо для ребят из Иркутска и Иркутского района.
			</p>

		</div>
	</div>
</template>
