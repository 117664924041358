<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage1.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				«ЭКСТЕРЬЕРНАЯ РОСПИСЬ»: КАК В «ПЕРСЕЕ» ПРОШЛА УНИКАЛЬНАЯ ПРОГРАММА
			</h2>
			<p class="NewsCard-date">
				02.08.2023
			</p>
		</div>
	</div>
</template>