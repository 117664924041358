<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Рачительный хозяин земли»
		</h2>
		<p class="EventsPage-date">
			Обучение проводится с 6 по 19 сентября 2023
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21394-programma-rachitelnyi-khozyain-zemli%20" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				Егорова Юлия Сергеевна
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				89246361877
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				yulcha_23@mail.ru
			</p>
		</div>
		<hr>
		<p class="EventsPage-sub-title">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребенка» и реализации Концепции развития непрерывного агробизнес-образования на сельских территориях на 2021-2024г. проводит профильную смену «Рачительный хозяин земли» для обучающихся 8-10 классов агрошкол Иркутской области.<br>
			Профильная смена состоится с 6 по 19 сентября 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).<br>
			Программа смены будет реализована по трем образовательным модулям: растениеводство, животноводство, экологическая культура. В рамках образовательной программы обучающиеся получат знания в области предпринимательской деятельности, бизнес-планирования в сельскохозяйственной сфере, разработки проектов в области растениеводства, животноводства, экологической деятельности и др.<br>
		</p>
		<h2 class="EventsPage-title">
			Документы:
		</h2>
		<ul class="EventsPage-list">
			<li class="EventsPage-items">
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/h5OxfSmkmfiQPQ/%C2%AB%D0%A0%D0%B0%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%85%D0%BE%D0%B7%D1%8F%D0%B8%D0%BD%20%D0%B7%D0%B5%D0%BC%D0%BB%D0%B8%C2%BB">
					Ссылки на сертификаты
				</a>
			</li>
		</ul>

	</div>
</template>