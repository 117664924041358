<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельные курсы
		</h3>
		<h2 class="EventsPage-title">
			«Театральная мастерская»
		</h2>
		<p class="EventsPage-date">
			с 03 июня по 12 июля 2024 года
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24939-programma-teatralnaya-masterskaya."
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			К освоению программы допускаются учащиеся общеобразовательных организаций и организаций дополнительного
			образования в возрасте 10-17 лет. Предварительная подготовка учащихся должна заключаться в сформированных
			двигательных и координационных навыках, сценического опыта выступления в театральных коллективах.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Цель программы:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Создание условий для воспитания творчески активной личности, раскрытие новых способностей и талантов учащихся
				средствами театрального искусства.
			</li>
			<li class="JuniorProfi-items">
				Учебные занятия проводятся очно с периодичностью 3 раза в неделю по 2 академических часа.
			</li>
			<li class="JuniorProfi-items">
				По итогам обучения выдаются сертификаты об освоении дополнительной общеразвивающей программы.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Крапивина Наталья Валентиновна, педагог дополнительного образования высшей квалификационной категории «МОУ ИРМО
				Кудинская СОШ».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться в системе Навигатор дополнительного образования Иркутской области до 27 мая 2024 года по
				ссылке: https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24939-programma-teatralnaya-masterskaya.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>