<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			24.06.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/news/3687375/">
				Призеры Всероссийской олимпиады школьников из Приангарья будут учиться в ведущих вузах страны
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage67.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов встретился с победителями и призерами Всероссийской
				олимпиады школьников 2023-2024 учебного года и их наставниками.
			</p>
			<p class="NewsPage-sub-title">
				Максим Парфенов поздравил школьников с высокими достижениями, вручил призерам и их педагогам благодарственные
				письма министерства образования, отметив, что успехи ребят – это результат упорной работы, стремления к знаниям
				и поддержки педагогов и родителей.
			</p>
			<p class="NewsPage-sub-title">
				– Вы доказали, что Иркутская область богата талантливыми и мотивированными молодыми людьми. Ваши достижения –
				это гордость для всех нас. Я уверен, что вы сможете реализовать свой потенциал, построить свою дальнейшую
				образовательную траекторию, будущую карьеру и принести пользу нашей стране, – подчеркнул министр.
			</p>
			<p class="NewsPage-sub-title">
				Среди участников заключительного этапа всероссийской олимпиады школьников нынешнего года у Иркутской области
				один победитель и семь призеров.
			</p>
			<p class="NewsPage-sub-title">
				Одиннадцатиклассница иркутской гимназии № 2 Елена Жарникова стала победителем всероссийской олимпиады по
				физической культуре. Елена показала свои знания в теоретическом туре, а затем в практическом туре
				продемонстрировать физические возможности. В общем зачете она стала второй из трехсот участников и получила
				диплом победителя. Елена будет учиться в Российском университете спорта «ГЦОЛИФК» на тренера по художественной
				гимнастике.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, что дипломы победителей и призеров финала олимпиады в течение четырех лет дают ребятам право поступить
				на профильное отделение в любой российский вуз без экзаменов, и те из ребят, кто в этом году заканчивает 11
				класс, уже определились с вузами, где будут продолжать обучение.
			</p>
			<p class="NewsPage-sub-title">
				О своих планах участники рассказали на встрече с министром. Так призер олимпиады по химии Захар Зайцев из лицея
				№ 1 города Иркутска будет учиться на химическом факультете МГУ, а выпускник иркутского лицея № 2 Артур Копылов –
				на юридическом факультете МГУ. Призер по обществознанию Даниил Самоходкин (гимназия № 1, Иркутск) выбрал
				обучение в Высшей школе экономики.
			</p>
			<p class="NewsPage-sub-title">
				Остальные призеры 2024 года смогут воспользоваться своим правом выбора вуза, когда закончат школу. Это призер по
				мировой художественной культуре Анастасия Миль (10 класс, гимназия №1 имени А.А. Иноземцева, г. Братск), призер
				по обществознанию София Прокопченко (10 класс, Шелеховский лицей, г. Шелехов), призер по китайскому языку Чжоу
				Мэн Ци (9 класс, центр образования № 47 г. Иркутск), призер по английскому языку Илья Карандин (9 класс, лицей №
				1 г. Усолье-Сибирское).
			</p>
			<p class="NewsPage-sub-title">
				По информации регионального минобра, в Иркутской области олимпиада традиционно проходила в четыре этапа:
				школьный, муниципальный, региональный и заключительный.
			</p>
			<p class="NewsPage-sub-title">
				Школьный этап самый массовый. В этом учебном году его участниками стали более 200 тысяч школьников Приангарья. В
				муниципальный тур прошли более 20 тысяч ребят, а в региональном соревновались около 1500 участников. К
				заключительному этапу были допущены 35 школьников из Приангарья. Они представляли Иркутскую область в 22
				предметных олимпиадах.
			</p>
			<p class="NewsPage-sub-title">
				Оператором олимпиады в Иркутской области определен Региональный центр выявления и поддержки одаренных детей
				«Персей», созданный в рамках реализации федерального проекта «Успех каждого ребенка» президентского
				национального проекта «Образование».
			</p>

		</div>
	</div>
</template>
