<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Дистанционная викторина
		</h2>
		<h3 class="EventsCard-title">
			«Праздник науки: викторина открытий»
		</h3>
		<p class="EventsCard-sub-title">
			Регистрация с 22.01.2024  по 31.01.2024
		</p>
		<p class="EventsCard-sub-title">
			Участие с 01.02.2024 по 15.02.2024
		</p>
	</div>
</template>