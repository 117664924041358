<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Конкурс
		</h2>
		<h3 class="EventsCard-title">
			«Большие вызовы»
		</h3>
		<p class="EventsCard-sub-title">
			2022-2023г.
		</p>
	</div>
</template>