<template>
	<div class="PsychologicalSupport _cover">
		<h2 class="PsychologicalSupport _cover-title">
			Психологическое сопровождение одаренных детей
		</h2>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%B5%D1%80%D0%B5%D1%87%D0%B5%D0%BD%D1%8C-%D0%BC%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA-%D0%B2-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D1%8C-%D1%88%D0%BA%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC%D1%83-%D0%BF%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D1%83.pdf"
					target="_blank">
					Перечень методик в помощь школьному психологу
				</a>
			</li>
		</ul>
	</div>
</template>