<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage16.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «ПЕРСЕЕ» СТАРТОВАЛИ ОКТЯБРЬСКИЕ НАУЧНЫЕ СМЕНЫ
			</h2>
			<p class="NewsCard-date">
				16.10.2023
			</p>
		</div>
	</div>
</template>