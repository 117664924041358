<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Умницы и умники»
		</h2>
		<p class="EventsPage-date">
			с 20 по 31 мая 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=163" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24909-programma-umniki-i-umnitsy" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит дистанционный курс «Умницы и умники» для учащихся 10-х классов.
		</p>

		<p class="EventsPage-text">
			Участникам дистанционного курса могут стать победители и призеры регионального этапа Всероссийской олимпиады
			школьников по следующим предметам: русский язык, литература, обществознание, история, мировая художественная
			культура.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (портфолио) до 20 мая 2024 года на платформе Moodle,
				Образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, нужно будет записаться на программу «Умницы и умники» на
				ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Результаты отбора будут размещены 20 мая на сайте Образовательного центра «Персей».
		</p>
		<p class="EventsPage-text">
			31 мая состоится Региональный этап Всероссийской телевизионной гуманитарной олимпиады школьников «Умницы и
			умники».
		</p>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Список участников регионального (отборочного) этапа Всероссийской открытой телевизионной гуманитарной олимпиады
			школьников «Умницы и умники»:
		</p>
		<p class="JuniorProfi-sub-title">
			Агонисты:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Ксения Подрубилина</li>
			<li class="EventsPage-number">Нижегородова Олеся</li>
			<li class="EventsPage-number">Анастасия Огородникова</li>
			<li class="EventsPage-number">Ксения Яшина</li>
			<li class="EventsPage-number">Ксения Соколова</li>
			<li class="EventsPage-number">Прокопченко София</li>
			<li class="EventsPage-number">Арина Селезнева</li>
			<li class="EventsPage-number">Ильина Вероника</li>
			<li class="EventsPage-number">Попов Михаил</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Теоретики:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Оверин Максим</li>
			<li class="EventsPage-number">Писарев Иван</li>
			<li class="EventsPage-number">Базилевская Софья</li>
			<li class="EventsPage-number">Зайцева Ксения</li>
			<li class="EventsPage-number">Кокорина Василиса</li>
		</ol>
		<br>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на дистанционный курс:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Алдаранова Ольга Денисовна</li>
			<li class="EventsPage-number">Базилевская Софья Романовна</li>
			<li class="EventsPage-number">Белоус Антон Антонович</li>
			<li class="EventsPage-number">Бритов Павел Евгеньевич</li>
			<li class="EventsPage-number">Брыкина Екатерина Максимовна</li>
			<li class="EventsPage-number">Бужинаев Данил</li>
			<li class="EventsPage-number">Волкова Екатерина Максимовна</li>
			<li class="EventsPage-number">Зайцева Ксения Олеговна</li>
			<li class="EventsPage-number">Ильина Вероника Антоновна</li>
			<li class="EventsPage-number">Камельских Виталий Викторович</li>
			<li class="EventsPage-number">Кириллова Дарья Леонидовна</li>
			<li class="EventsPage-number">Кокорина Василиса Владимировна</li>
			<li class="EventsPage-number">Константинова Мария Александровна</li>
			<li class="EventsPage-number">Коплик Маргарита Алексеевна</li>
			<li class="EventsPage-number">Круглова Наталья Ивановна</li>
			<li class="EventsPage-number">Крылов Глеб Артёмович</li>
			<li class="EventsPage-number">Кузнецова Алина Александровна</li>
			<li class="EventsPage-number">Лизин Вячеслав Вячеславович</li>
			<li class="EventsPage-number">Макеева Елизавета Олеговна</li>
			<li class="EventsPage-number">Митлярова Софья Валерьевна</li>
			<li class="EventsPage-number">Москвитина Виктория Вячеславовна</li>
			<li class="EventsPage-number">Наумова Вероника Вячеславовна</li>
			<li class="EventsPage-number">Непомнящий Антон</li>
			<li class="EventsPage-number">Нижегородова Олеся Сереевна</li>
			<li class="EventsPage-number">Огородникова Анастасия Максимовна</li>
			<li class="EventsPage-number">Оверин Максим Александрович</li>
			<li class="EventsPage-number">Писарев Иван Владимирович</li>
			<li class="EventsPage-number">Платонова Варвара Олеговна</li>
			<li class="EventsPage-number">Подрубилина Ксения Александровна</li>
			<li class="EventsPage-number">Попов Михаил Денисович</li>
			<li class="EventsPage-number">Прокопченко София Александровна</li>
			<li class="EventsPage-number">Саляхова Рената Ренатовна</li>
			<li class="EventsPage-number">Селезнева Арина Сергеевна</li>
			<li class="EventsPage-number">Сергеев Савелий Валерьевич</li>
			<li class="EventsPage-number">Соколова Ксения Александровна</li>
			<li class="EventsPage-number">Струк Ксения Дмитриевна</li>
			<li class="EventsPage-number">Филатов Данил Алексеевич</li>
			<li class="EventsPage-number">Шахеров Михаил Антонович</li>
			<li class="EventsPage-number">Шведина Варвара Андреевна</li>
			<li class="EventsPage-number">Шестакова Ангелина Андреевна</li>
			<li class="EventsPage-number">Шестакова Дарья Дмитриевна</li>
			<li class="EventsPage-number">Яшина Ксения Игоревна</li>
		</ol>
		<br>
		<hr>


		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>