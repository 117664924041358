<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			27.03.2024
		</p>
		<h2 class="NewsPage-title">
			Итоги мартовских научных интенсивов
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage51.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» завершились мартовские профильные смены: «Регионоведение. География», «Астрономия: Наука о звездах и не только», «Спортивное пилотирование» и «Страна железных дорог».
			</p>
			<p class="NewsPage-sub-title">
				Участниками образовательных программ стали 156 школьников из Иркутска, Ангарска, Шелехово, Тайшета, Усолье-Сибирского, Братска, Саянска, Тулуна, Иркутского, Шелеховского, Тайшетского, Заларинского, Усольского, Братского, Аларского, Тулунского, Нукутского, Заларинского, Качугского, Нижнеудинского, Балаганского, Жигаловского, Слюдянского, Усть-Илимского районов.
			</p>
			<p class="NewsPage-sub-title">
				Каждая программа, по направлению «Наука», была особенна по-своему.
			</p>
			<p class="NewsPage-sub-title">
				Школьники приехавшие на профильную смену «Регионоведение. География» познакомились с экономическими и географическими факторами  взаимодействия  России со странами  Азиатско-Тихоокеанского региона.
			</p>
			<p class="NewsPage-sub-title">
				Как отметили преподаватели, программа стала отличным стартом для ребят, которые задумывались о профессии регионоведа.
			</p>
			<p class="NewsPage-sub-title">
				– Мы увидели высокую заинтересованность школьников в изучении языков и культурных особенностей на этой смене. Мы постарались построить обучение так, чтобы участники программы по завершению смогли выбрать интересующее направление, и пришли учиться к нам, уже понимая, что ожидает их и кем они смогут быть после выпуска, – рассказала Маргарита Новоселова, доцент каферды «Регионоведение» Иркутского государственого университета.
			</p>
			<p class="NewsPage-sub-title">
				Каждый день был посвящен знакомству с национальными и языковыми особенностями одной страны. А также изучению актуальных вопросов безопасности в международных отношениях и современных глобальных проблем.
			</p>
			<p class="NewsPage-sub-title">
				На программе «Астрономия: Наука о звездах и не только» юные ученые изучали методы астрономических исследований, строение нашей Солнечной системы, а также конструировали и запускали ракеты.
			</p>
			<p class="NewsPage-sub-title">
				– На смене мы наблюдали за звездами, туманностями, планетами, метеорам, а также Луной и искусственными спутниками Земли. Помимо этого, мы смогли собрать и запустить свои ракеты. Я был в восторге, когда моя ракета поднялась выше пятиэтажного дома. Непередаваемые эмоции, – поделился участник профильной смены  Светослав Побойкин.
			</p>
			<p class="NewsPage-sub-title">
				Программа проходила совместно с Иркутским Планетарием под руководством генерального директора Павла Никифорова.
			</p>
			<p class="NewsPage-sub-title">
				Как отметил директор Иркутского планетария, программы по астрономии помогают находить детей, которые заинтересованы в изучении этой науки. Сейчас в Иркутской области астрономия набирает все большую популярность среди молодежи, и «Персей» – это отличное место, где юные умы могут начать реализовывать себя в этом направлении.
			</p>
			<p class="NewsPage-sub-title">
				Участники профильной смены «Спортивное пилотирование» освоили навыки управления FPV-дронами и их эксплуатации в различных условиях. Под руководством опытных наставников прошли обучение на симуляторах, состязались в дрон-рестлинге и разбирали строение квадрокоптеров.
			</p>
			<p class="NewsPage-sub-title">
				Артур Эльхудов, преподаватель смены, подчеркнул, что Иркутская область только начинает реализовываться в этом направлении. Поэтому программы по спортивному пилотированию для школьников сейчас набирают все большую популярность среди них.
			</p>
			<p class="NewsPage-sub-title">
				По итогам обучения ребята смогли подать заявки на вступление в федерацию спортивного пилотирования, где уже они продолжат учиться и выступать в данной дисциплине.
			</p>
			<p class="NewsPage-sub-title">
				Свои первые карьерные решения смогли принять и участники программы «Страна железных дорог», которая прошла по трем направлениям: «Экология», «Робототехника», «Цифровые технологии вокруг нас».
			</p>
			<p class="NewsPage-sub-title">
				В рамках данных направлений ребята учились работать с навигационными системами, электрическими схемами и электронными компонентами. В процессе обучения получили практические навыки в области природоохранной деятельности, программирования и прототипирования, а также проектирования, разработки экспертных систем, криптографии и основ построения компьютерных сетей.
			</p>
			<p class="NewsPage-sub-title">
				В рамках данных направлений ребята учились работать с навигационными системами, электрическими схемами и электронными компонентами. В процессе обучения получили практические навыки в области природоохранной деятельности, программирования и прототипирования, а также проектирования, разработки экспертных систем, криптографии и основ построения компьютерных сетей.
			</p>
			<p class="NewsPage-sub-title">
				Главным событием смены стали открытые диалоги с представителями дирекции ВСЖД ОАО «Российские железные дороги», на которых школьники узнали о флагманских проектах холдинга.
			</p>
			<p class="NewsPage-sub-title">
				В завершении обучения ребята представили свои проекты в рамках направлений, им удалось представить свои идеи по переработке пластика, создании экологичных брелоков, разработке мобильного приложения для безопасного использования в поезде, систем диагностики колесных пар, а также роботов-помощников.
			</p>
			<p class="NewsPage-sub-title">
				Лучшие работы были отмечены специальными призами от дирекции  ВСЖД ОАО «Российские железные дороги».
			</p>
			<p class="NewsPage-sub-title">
				С 10 апреля в кампусе Образовательного центра «Персей» стартуют апрельские научные профильные смены. Выбрать программу и подать заявку вы можете на сайте: <a class="NewsPage-link" href="https://perseusirk.ru/ScienceBlock">https://perseusirk.ru/ScienceBlock</a>
			</p>

		</div>
	</div>
</template>
