<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Танцевальный фольклор Иркутской области»
		</h2>
		<p class="EventsPage-date">
			с 28 октября по 5 ноября 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену «Танцевальный фольклор Иркутской области» для обучающихся 10-17 лет общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Дополнительная общеразвивающая программа «Танцевальный фольклор Иркутской области» ориентирована на обучающихся образовательных организаций Иркутской области, в возрасте от 10 до 17 лет, имеющие двигательные, координационные и музыкальные навыки, полученные в хореографических коллективах.
		</p>
		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-text">
			Намаконова Лариса Владимировна, Главный балетмейстер ГБУ ДО ИО ЦРДТЮ «Узорочье»;
		</p>
		<p class="EventsPage-text">
			Заика Наталья Александровна, педагог дополнительного образования МБОУ г. Иркутска СОШ № 40.
		</p>
		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>

		<h2 class="EventsPage-title">
			Условия участия:
		</h2>
			<p class="EventsPage-text">
				Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе электронной информационно-образовательной среды сайта ОЦ «Персей» с 12 октября по 16 октября 2023 года по ссылке:
			</p>
			<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=108" target="_blank">
				Ссылка на платформу
			</a>
			<p class="EventsPage-text">
				где необходимо прикрепить:
			</p>
			<p class="EventsPage-text">
				- Портфолио с благодарственными письмами, сертификатами за участие в конкурсах регионального и всероссийского уровня в номинации «Народный танец» (не более 3 документов);
			</p>
			<p class="EventsPage-text">
				- Мотивационное письмо на тему «Моё увлечение фольклорными танцами», в котором написать о себе, о наиболее значимых мероприятиях, в которых вы принимали участие как исполнитель народных и фольклорных танцев. (не более 1000 символов).
			</p>
			<p class="EventsPage-text">
				Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке:
			</p>
			<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/23084-programma-tantsevalnyi-folklor-irkutskoi-oblasti" target="_blank">
				Ссылка на Навигатор
			</a>
		<hr>
		<h2 class="EventsPage-title">
			Списочный состав обучающихся, прошедших отбор и приглашённых на очную профильную смену
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Абушова София</li>
			<li class="EventsPage-number">Барахтенко Константин</li>
			<li class="EventsPage-number">Васильев Вячеслав</li>
			<li class="EventsPage-number">Герасименко Семен</li>
			<li class="EventsPage-number">Дылкина Софья</li>
			<li class="EventsPage-number">Егоров Артём</li>
			<li class="EventsPage-number">Клюев Ефим</li>
			<li class="EventsPage-number">Краснова Екатерина</li>
			<li class="EventsPage-number">Коршакевич Антон</li>
			<li class="EventsPage-number">Кузнецова Ульяна</li>
			<li class="EventsPage-number">Кякина Анастасия</li>
			<li class="EventsPage-number">Лескова Юлия</li>
			<li class="EventsPage-number">Окунева Арина</li>
			<li class="EventsPage-number">Пестова Ксения</li>
			<li class="EventsPage-number">Татарова Марина</li>
			<li class="EventsPage-number">Терещенко Кристина</li>
			<li class="EventsPage-number">Тимофеева Виктория</li>
			<li class="EventsPage-number">Тришкина Варвара</li>
			<li class="EventsPage-number">Уфилина Софья</li>
			<li class="EventsPage-number">Фереферова Софья</li>
			<li class="EventsPage-number">Чмиль Семён</li>
			<li class="EventsPage-number">Агеева Мария</li>
			<li class="EventsPage-number">Бутырина Екатерина</li>
			<li class="EventsPage-number">Жуйкова Ольга</li>
			<li class="EventsPage-number">Зайнулина Арина</li>
			<li class="EventsPage-number">Курносова Ульяна</li>
			<li class="EventsPage-number">Лепихова Алина</li>
			<li class="EventsPage-number">Малыхина Стефания</li>
			<li class="EventsPage-number">Москвитина Карина</li>
			<li class="EventsPage-number">Новик Диана</li>
			<li class="EventsPage-number">Пак Алёна</li>
			<li class="EventsPage-number">Пекарева Полина</li>
			<li class="EventsPage-number">Севостьянова Анастасия</li>
			<li class="EventsPage-number">Соколова Эвелина</li>
			<li class="EventsPage-number">Фефелова Василиса</li>
			<li class="EventsPage-number">Яковлева Дарья</li>
		</ol>
			
	</div>
</template>