<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дополнительная профессиональная программа повышения квалификации
		</h3>
		<h2 class="EventsPage-title">
			«Организационно-педагогическое обеспечение воспитательного процесса в организациях отдыха детей и их оздоровления»
		</h2>
		<hr>

		<p class="EventsPage-text">
			Целью курса является совершенствование профессиональной компетенции слушателей организационно-педагогического
			обеспечения воспитательного процесса в организациях отдыха детей и их оздоровления.
		</p>
		<p class="EventsPage-text">
			Объем программы: 48 часов
		</p>
		<p class="EventsPage-text">
			Категория слушателей, воспитатели, педагоги организаторы, педагоги дополнительного образования, вожатые
		</p>
		<p class="EventsPage-text">
			Аннотация: в рамках курсовой подготовки слушатели изучат типы и виды учреждений, основная деятельность которых
			направлена на реализацию услуг по обеспечению отдыха детей и их оздоровления, ознакомятся с требованиями,
			предъявляемые к педагогическим работникам, участвующим в реализации программы отдыха детей и их оздоровления.
			Узнают об особенностях организации методического сопровождения программы отдыха детей и их оздоровления и изучат
			интерактивные, рефлексивные и онлайн технологии для работы с детьми.
		</p>
		<p class="EventsPage-text">
			По итогам изучения курса слушатели должны будут пройти тест из 30 вопросов (время ограничено)
		</p>
		<p class="EventsPage-text">
			Слушатели, успешно завершившие курсовую подготовку, получают удостоверение установленного образца о повышении
			квалификации.
		</p>
		<p class="EventsPage-text">
			<b>Обучение проходит в очно-заочной форме с использованием дистанционных образовательных ресурсов на платформе
				Мудл (Moodle)
			</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских инициатив
				образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Сорока Сергей Сергеевич, заведующий отделом выявления и сопровождения одаренных детей, образовательного центра
				«Персей»
			</li>
			<li class="JuniorProfi-items">
				Грушевская Ольга Сергеевна, педагог-психолог образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, руководитель отдела информационно-технического обеспечения
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Соловьева Дарья Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: d.soloveva@perseusirk.ru
			</li>
		</ul>

	</div>
</template>