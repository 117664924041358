<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Агенты погоды»
		</h2>
		<p class="EventsPage-date">
			с 27 февраля по 11 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=140" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24573-programma-agenty-pogody" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Агенты погоды» для учащихся 8-9-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа направлена на формирование представлений о роли гидрометеорологической службы для обеспечения защищенности населения и окружающей среды при возникновении техногенных и природных чрезвычайных ситуаций.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить отборочное задание на платформе ОЦ «Персей», до 19 февраля 2024 года на платформе обучения Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 20 февраля (включительно) на сайте Образовательного центра «Персей».</b>
		</p>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 20 февраля 2024 г. в 20.00, ссылка на подключение <a style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1707967842">https://pruffme.com/landing/Perseus/tmp1707967842</a>
		</p>
		<hr>

		<h2 class="JuniorProfi-sub-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену:
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аджалова Джулай Витальевна</li>
			<li class="EventsPage-number">Аджалова Фидан Витальевна</li>
			<li class="EventsPage-number">Аношкин Максим Михайлович</li>
			<li class="EventsPage-number">Арбатская Елизавета Петровна</li>
			<li class="EventsPage-number">Богатырева Софья Ильинична</li>
			<li class="EventsPage-number">Бредунова Екатерина Романовна</li>
			<li class="EventsPage-number">Абрамов Василий Иванович</li> 
			<li class="EventsPage-number">Верховская Ангелина Сергеевна</li>
			<li class="EventsPage-number">Гончаровская Александра Андреевна</li>
			<li class="EventsPage-number">Гусева Александра Александровна</li>
			<li class="EventsPage-number">Дмитриева Екатерина Владимировна</li>
			<li class="EventsPage-number">Дорошенко Александр Дмитриевич</li>
			<li class="EventsPage-number">Дюка Светлана Александровна</li>
			<li class="EventsPage-number">Зуева Кристина</li>
			<li class="EventsPage-number">Иванова Дарья Сергеевна</li>
			<li class="EventsPage-number">Кирьянов Данил Михайлович</li>
			<li class="EventsPage-number">Колодин Игорь Алексеевич</li>
			<li class="EventsPage-number">Кравчук Карина Витальевна</li>
			<li class="EventsPage-number">Краснова Яна Андреевна</li>
			<li class="EventsPage-number">Кузьмина Виктория Константиновна</li>
			<li class="EventsPage-number">Латышев Руслан Андреевич</li>
			<li class="EventsPage-number">Лесина Дарина Олеговна</li>
			<li class="EventsPage-number">Макарова Арина Дмитриевна</li>
			<li class="EventsPage-number">Меркурьев Даниил Дмитриевич</li>
			<li class="EventsPage-number">Миронова Ирина Викторовна</li>
			<li class="EventsPage-number">Молоева Асема Азаматовна</li>
			<li class="EventsPage-number">Новолодский Егор Олегович</li>
			<li class="EventsPage-number">Панфилова София Витальевна</li>
			<li class="EventsPage-number">Пахомова Диана Михайловна</li>
			<li class="EventsPage-number">Ерошенко Вера Алексеевна</li> 
			<li class="EventsPage-number">Поляков Данила Александрович</li>
			<li class="EventsPage-number">Савельев Матвей Сергеевич</li>
			<li class="EventsPage-number">Сальникова Анастасия Алексеевна</li>
			<li class="EventsPage-number">Середкина Валерия Олеговна</li>
			<li class="EventsPage-number">Суббота Ангелина Александровна</li>
			<li class="EventsPage-number">Травникова Валерия Романовна</li>
			<li class="EventsPage-number">Чернорай Дарья Аркадиевна</li>
			<li class="EventsPage-number">Шалахин Матвей Денисович</li>
			<li class="EventsPage-number">Шишков Латон Алексеевич</li>
			<li class="EventsPage-number">Авсиевич Мария Анатольевна</li>
			<li class="EventsPage-number">Беганцова Мария Владимировна</li>
			<li class="EventsPage-number">Данилина Мария</li>
			<li class="EventsPage-number">Ерошенко Маргарита Сергеевна</li>
			<li class="EventsPage-number">Иванов Максим Евгеньевич</li>
			<li class="EventsPage-number">Люфа Эвелина Дмитриевна</li>
		</ol>

		<p class="EventsPage-text">
			<b>Примечание:</b> Списочный состав участников смены сформирован экспертной комиссией на основании отборочного задания.
		</p>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/9e5q/8kQYx4whv" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/dFdt-7C0Hf5ldA" target="_blank">
			Скачать
		</a>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>