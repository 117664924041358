<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage48.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Итогом февральской программы по хореографии стала победа школьников на Первенстве Иркутской области
			</h2>
			<p class="NewsCard-date">
				04.03.2024
			</p>
		</div>
	</div>
</template>