<template>
	<div class="BigChallenges _cover">
		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">Всероссийский конкурс научно-технологических проектов</h2>
		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">«Большие вызовы» 2023-2024г.</h2>
		<a class="GoldenFundOfSiberia-btn temp-btn-BC" href="https://konkurs.sochisirius.ru/"> Подробнее </a>
		<hr />

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">Учредители конкурса</h2>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://edu.gov.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/logo-1.webp" alt="Логотип Министерство науки и высшего образования Российской Федерации" />
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">Министерство науки и высшего образования Российской Федерации</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://irkobl.ru/sites/minobr/olimpiada/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Univпersity-2020.webp" alt="Логотип Научно-технологический университет «Сириус»" />
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">Научно-технологический университет «Сириус»</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://sochisirius.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/news-20200320-1584703357-mzvntl_0x480_e98.webp" alt="Логотип Образовательный Фонд «Талант и успех»" />
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">Образовательный Фонд «Талант и успех»</p>
				</div>
			</div>
		</div>
		<hr />

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">Организатор регионального трека конкурса</h2>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://angtu.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/logo3-1.webp" alt="Логотип Региональный центр выявления и поддержки одаренных детей в Иркутской области «Образовательный центр Персей»" />
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"</p>
				</div>
			</div>
		</div>

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">О конкурсе</h2>
		<ul class="GoldenFundOfSiberia-list">
			<li class="GoldenFundOfSiberia-items">Образовательный центр «Сириус» предлагает участникам оформить свои идеи, разработки и исследования в проект и представить его для оценки экспертам.</li>
			<li class="GoldenFundOfSiberia-items">Цель конкурса – выявление и развитие у молодежи творческих способностей, интереса к проектной, научной (научно-исследовательской), инженерно-технической, изобретательской и творческой деятельности, популяризация научных знаний и достижений.</li>
			<li class="GoldenFundOfSiberia-items">В конкурсе принимают участие ученики школ и учреждений среднего профессионального образования, уровня 7 — 11 классов.</li>
		</ul>

		<h2 class="CompetitionStructure" style="color: #8327ab">Структура Конкурса</h2>

		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tbody>
					<tr>
						<td><p>&nbsp;</p></td>
						<td>
							<p><strong>Региональный трек</strong></p>
						</td>
						<td>
							<p><strong>Дистанционный трек</strong></p>
						</td>
					</tr>
					<tr>
						<td>
							<p><strong>Отборочные этапы конкурса</strong></p>
						</td>
						<td>
							<p>Региональный конкурс:</p>
							<p>1. Отборочные этапы регионального конкурса</p>
							<p>2. Финальный (очный) этап регионального конкурса</p>
						</td>
						<td><p>Дистанционный конкурс</p></td>
					</tr>
					<tr>
						<td>
							<p><strong>Заключительный этап конкурса</strong></p>
						</td>
						<td colspan="2"><p>Два тура (выполнение заданий – тестирование и индивидуальное собеседование)</p></td>
					</tr>
				</tbody>
			</table>
		</div>

		<p class="CompetitionStructure-dist">
			Конкурс включает в себя два трека – Региональный и Дистанционный – которые проходят параллельно.
		</p>
		<p class="CompetitionStructure-dist">
			Региональный трек проходит очно. Дистанционный конкурс проходит тех школьников, тематика проекта которых не совпадает с направлениями очного конкурса в регионе.
		</p>
		<p class="CompetitionStructure-dist">
			Заключительный этап конкурса проводится по завершению региональных и дистанционного конкурсов. Этап проходит в два тура, включающие в себя выполнение заданий в субъектах Российской Федерации и индивидуальные собеседования с экспертной комиссией.
		</p>
		<hr>

		<h2 class="CompetitionStructure" style="color: #8327ab">
			Что является проектной работой в рамках конкурса?
		</h2>
		<p class="CompetitionStructure-dist">
			Проектная деятельность – особый способ работы, в котором участники проходят полный жизненный цикл проекта в специальной учебной форме. Школьный проект опирается на те же понятия, мерки и стандарты, которые существуют в настоящем проектировании и исследовании.
		</p>
		<p class="CompetitionStructure-dist">
			Проектная деятельность призвана решить актуальную проблему, поэтому проект начинается с анализа ситуации, фиксации проблемы и выявления передовых методов и технологий решения.
		</p>
		<div class="CompetitionStructure-img-box">
			<img loading="lazy" class="CompetitionStructure-img" src="../assets/img/BigChallenges/WhatsApp-Image-2022-09-02-at-10.05.37.webp">
		</div>
		<hr>

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">
			Направления Регионального конкурса
		</h2>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/agro">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Агропромышленные_и_биотехнологии.svg" alt="Логотип Агропромышленные и биотехнологии">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Агропромышленные и биотехнологии
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/bigdata">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Большие_данные_искусственный_интеллект_финансовые_технологии_и_машинное_обучение.svg" alt="Логотип Большие данные, искусственный интеллект, финансовые технологии и машинное обучение">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Большие данные, искусственный интеллект, финансовые технологии и машинное обучение
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/space">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Космические_технологии.svg" alt="Логотип Космические технологии">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Космические технологии
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/energo">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Современная_энергетика.svg" alt="Логотип Современная энергетика">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Современная энергетика
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/smct">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Умный_город_и_безопасность.svg" alt="Логотип Умный город и безопасность">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Умный город и безопасность
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/nano">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/Нанотехнологии.webp" alt="Логотип Нанотехнологии">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Нанотехнологии
					</p>
				</div>
			</div>
		</div>
		<hr>

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">
			Документы
		</h2>
		<ul class="BigChallenges-list">
			<li class="BigChallenges-items">
				<a class="BigChallenges-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9E%D0%B1-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%B8-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B8%D0%B5-%D0%B2%D1%8B%D0%B7%D0%BE%D0%B2%D1%8B2024.pdf" target="_blank">
					Об организации и проведении конкурса Большие вызовы 2024
				</a>
			</li>
			<li class="BigChallenges-items">
				<a class="BigChallenges-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D1%80%D0%B5%D0%B3.-%D1%82%D1%80%D0%B5%D0%BA%D0%B0-%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B8%D0%B5-%D0%B2%D1%8B%D0%B7%D0%BE%D0%B2%D1%8B.pdf" target="_blank">
					Приказ об итогах рег. трека Большие вызовы
				</a>
			</li>
		</ul>
		<hr>

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">
			Проектная работа должна отвечать следующим характеристикам:
		</h2>
		<ul class="BigChallenges-list">
			<li class="BigChallenges-items">
				Участник достигает востребованного результата, его работа важна и актуальна и разворачивается в области, связанной с передовыми исследованиями и разработками.
			</li>
			<li class="BigChallenges-items">
				Участник понимает, как и кем будет использоваться полученный им результат. Однако это не означает, что школьники всегда должны выполнять проекты на уровне взрослых профессионалов. Завышая планку, руководители проектов порой ставят школьников в зависимую позицию исполнителя, не дающую опыт целостного освоения культуры организации и проектирования своей работы.
			</li>
			<li class="BigChallenges-items">
				Речь идет о научной или технологической новизне. Лабораторная работа или конструирование по известному алгоритму не являются полноценными проектами. Инновационные решения, в свою очередь, требуют высокого уровня освоения участниками фундаментальной предметной базы.
			</li>
			<li class="BigChallenges-items">
				Участник получает опыт работы с проблемной областью, постановки целей и задач, опыт получения нового решения, опыт организации деятельности.
			</li>
		</ul>

		<h2 class="BigChallenges-title _cover-title" style="color: #8327ab">
			Методические рекомендации
		</h2>
		<div class="Preparation-iframe-box">
			<iframe class="Preparation-iframe" width="560" height="315" src="https://www.youtube.com/embed/1TnZFFhL92Y?si=mByM81gI6gJgUf5w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315" src="https://www.youtube.com/embed/aaP8MYJvv2s?si=0aUGyfitSEBuLVea" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315" src="https://www.youtube.com/embed/k970a4OhShc?si=t7BlTsPI1tfPVD0-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315" src="https://www.youtube.com/embed/PQjWsaScP0E?si=4XXR1oNDtVonTRII" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>

	</div>
</template>
