<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Подготовка обучающихся к участию в региональном этапе ВСОШ по астрономии»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 17 по 23 декабря 2023 года.
		</h4>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24233-programma-podgotovka-k-uchastiyu-vo-vsosh-po-astronomii" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Подготовка обучающихся к участию в региональном этапе ВСОШ по астрономии» для учащихся 7-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа позволит учащимся развивать свой творческий и научный потенциал, навыки адаптации к современному обществу и получить возможность усовершенствовать свои знания по предмету «Астрономия» для успешного участия в олимпиаде на региональном уровне.
		</p>
		<p class="EventsPage-text">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Список учащихся, приглашенных на профильную смену, будет опубликован на сайте ОЦ «Персей» 8 декабря 2023 года.
		</p>
		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 8 декабря 2023 г. в 20.00, <a style="display: inline;" class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1701325394" target="_blank">ссылка на подключение</a>
		</p>
		<hr>

		
		<p class="JuniorProfi-sub-title">
			Список обучающихся
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%B8.docx" target="_blank">
			Скачать
		</a>
		
		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/yHjk/NTbs4YfcQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/ratTaQd-a-6kIg" target="_blank">
			Сертификаты
		</a>
		
		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>