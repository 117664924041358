<template>
	<div class="MunicipalCoordinators _cover">
		<h2 class="MunicipalCoordinators-title _cover-title">
			Информация для муниципальных координаторов по работе с одаренными детьми
		</h2>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%BF%D0%BB%D0%B0%D0%BD-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D0%BC%D1%83%D0%BD%D0%B8%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BE%D1%80%D0%B4%D0%B8%D0%BD%D0%B0%D1%82%D0%BE%D1%80%D0%B0-%D0%BF%D0%BE-%D0%B2%D1%8B%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8E-%D0%B8-%D1%81%D0%BE%D0%BF%D1%80%D0%BE%D0%B2%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8E-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9-%D0%B2-%D0%BC%D1%83%D0%BD%D0%B8%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F%D1%85-%D0%BD%D0%B0-2024-%D0%B3%D0%BE%D0%B4.pdf"
					target="_blank">
					План работы образовательного центра «Персей» с муниципальными координаторами на 2024 год
				</a>
			</li>
		</ul>
	</div>
</template>