<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Дистанционный курс
		</h2>
		<h3 class="EventsCard-title">
			«Художественное слово. Подготовка к творческой деятельности по литературе»
		</h3>
		<p class="EventsCard-sub-title">
			с 19 февраля по 18 марта 2024 года
		</p>
	</div>
</template>