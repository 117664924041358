<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Агенты погоды»
		</h3>
		<p class="EventsCard-sub-title">
			08.02.23-21.02.23
		</p>
	</div>
</template>