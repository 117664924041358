<template>
	<div class="test _cover">
		<h2 class="test-title _cover-title">
			Тестовая страница
		</h2>
		<ul>
			<li v-for="item in data" :key="item.id">{{ item.column1 }}</li>
		</ul>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			data: []
		};
	},
	mounted() {
		axios.get('/api/data').then((response) => {
			this.data = response.data;
		}).catch((error) => {
			console.error('Ошибка получения данных: ' + error);
		});
	}
};
</script>