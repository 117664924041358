<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'BigChallenges2024' })">
						<EventsCard130></EventsCard130>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'BigChallenges2023' })">
						<EventsCard45></EventsCard45>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard45 from '../EventsCard/EventsCard45';
import EventsCard130 from '../EventsCard/EventsCard130';

	export default {
		components: {
			EventsCard45,
			EventsCard130,
		}
	}
</script>
