<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			08.12.2023
		</p>
		<h2 class="NewsPage-title">
			Команда школьников из Приангарья стала участником первой программы по ИИ в «Сириусе»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage32.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>В Образовательном центре «Сириус» стартовала первая научно-технологическая программа по искусственному интеллекту для школьных команд. Работать над проектами в Образовательный центр приехали 22 команды из 74 регионов, в их числе школьники из Братска.</b>
			</p>
			<p class="NewsPage-sub-title">
				По информации Фонда «Талант и успех», программа «Сириус.ИИ» для школьных команд проводится Образовательным центром впервые. На первом отборочном этапе ребятам нужно было сформировать команду в составе от двух до четырех человек и определить проект, над которым они будут трудиться. На выбор было представлено шесть задач от партнеров – Университета «Сириус», группы компаний «Медси», ПАО «Сбербанк», VK, «Тинькофф Банк», ОЦРВ. В течение недели школьники работали над выбранной задачей, полученное решение было направлено на экспертизу. На втором туре отборочного этапа команды представили экспертам свои решения в формате онлайн-собеседования. По его итогам был сформирован список участников, приглашенных на очный этап «Сириус.ИИ», который пройдет по 25 декабря 2023 года.
			</p>
			<p class="NewsPage-sub-title">
				Все задачи, предложенные компаниями, связаны с применением машинного обучения и искусственного интеллекта в различных областях: от социальных сетей и медицины до банковских услуг и экологии.
			</p>
			<p class="NewsPage-sub-title">
				Команда из школы № 46 г. Братска в лице Андрея Калячко, Ильи Рукавицына и Льва Журавлева, работает над задачей от «Тинькофф Банка». На отборочном этапе ребятам необходимо было придумать идеи по усовершенствованию голосового помощника Тинькофф «Джуниор». Школьники решили внедрить в искусственный интеллект полезные навыки, которые могут помочь детям в решении сложных вопросов: от помощи в выполнении домашнего задания до совершения банковских операций.
			</p>
			<p class="NewsPage-sub-title">
				Сейчас участники финального этапа дорабатывают свои решения под руководством экспертов в «Сириусе». По завершению программы, они смогут претендовать на дополнительные баллы на заключительном этапе Всероссийского конкурса научно-технологических проектов «Большие вызовы» по направлению «Большие данные, искусственный интеллект, финансовые технологии и машинное обучение».
			</p>

		</div>
	</div>
</template>
