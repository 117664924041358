<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.12.2023
		</p>
		<h2 class="NewsPage-title">
			VIII Региональный чемпионат компетенций ЮниорПрофи стартовал в Иркутской области
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage31.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Соревнования пройдут на площадке Регионального центра выявления и поддержки одаренных детей «Персей», при поддержке министерства образования Иркутской области и фонда поддержки социальных инноваций «Вольное дело». В них примут участие около 100 конкурсантов и 50 наставников по восьми компетенциям: «Прототипирование», «Инженерный дизайн», «Агрономия», «Сити-Фермерство», «Ветеринария», «Мобильная робототехника», «Мультимедиакоммуникации» и «Электроника».
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы мероприятия, чемпионат пройдет для конкурсантов двух возрастных категорий 10-13 лет и 14-17 лет. Все школьники прошли подготовку к чемпионату под руководством наставников, которые в ноябре этого года стажировались в Образовательном центре «Персей».
			</p>
			<p class="NewsPage-sub-title">
				– Чемпионаты ЮниорПрофи один из элементов подхода к ранней профориентации школьников. При выполнении конкурсных заданий ребята развивают творческие, предпринимательские и инженерные навыки. Это дает уникальный опыт, так как соревнования приближены к реальным профессиональным задачам, и школьники могут сразу оценить свои потенциал в выбранной компетенции и скорректировать вектор развития, – подчеркнул Алексей Шестаков, директор Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				Также руководитель Образовательного центра отметил техническую базу «Персея». Благодаря федеральному проекту «Успех каждого ребенка» национального проекта «Образование» есть все необходимое оборудование, которое позволяет участникам выполнять конкурсные задания в соответствии с требованиями, проявлять творческий подход и находить нестандартные решение.
			</p>
			<p class="NewsPage-sub-title">
				В этом году чемпионат проходит в восьмой раз. Участие в нем принимают школьники из Иркутска, Ангарска,  Черемхово, Братска, Усолье-Сибирского, Усть-Илимска, Аларского, Иркутского, Братского, Заларинского, Куйтунского, Нукутского, Тайшетского,  Тулунского, Усольского, Слюдянского и Шелеховского районов.
			</p>
			<p class="NewsPage-sub-title">
				Чемпионат пройдет с 5 по 8 декабря. В завершении состязаний в каждой компетенции будет определена команда победителей в составе двух человек. В следующем году они смогут принять участие в Национальном Чемпионате ЮниорПрофи.
			</p>
			
		</div>
	</div>
</template>
