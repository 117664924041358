<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Лаборатория творчества: Street dance»
		</h2>
		<p class="EventsPage-date">
			с 7 февраля по 20 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=133#section-0" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24455-programma-laboratoriya-tvorchestva-street-dance" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену «Лаборатория творчества: Street dance» для обучающихся общеобразовательных организаций и организаций дополнительного образования в возрасте от 10 до 17 лет, заявившие в добровольном порядке свое намерение участвовать в мероприятиях смены и проявивших себя в творческой деятельности.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				готовность к занятиям суставно-связочного аппарата обучающегося (растяжка, постановка корпуса, развитая координация);
			</li>
			<li class="JuniorProfi-items">
				наличие навыков и знаний в области ОФП;
			</li>
			<li class="JuniorProfi-items">
				наличие практического сценического опыта выступлений, а именно, умение ориентироваться в классе и на сцене, знания основ построения рисунка, базовых понятий современной хореографии, терминологии основных движений направления Street Dance и правильное их исполнение.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Лелюк Ольга Сергеевна, тренер-преподаватель Муниципального казенного учреждения дополнительного образования «Спортивная школа «Лидер»;
			</li>
			<li class="JuniorProfi-items">
				Лелюк Максим Владимирович, тренер-преподаватель Муниципального казенного учреждения дополнительного образования «Спортивная школа «Лидер».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<p class="EventsPage-text">
			Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе электронной информационно-образовательной среды сайта ОЦ «Персей» до 27 января 2024 года по ссылке: <a style="color:blue" href="https://moodle.perseusirk.ru/course/view.php?id=133#section-0">https://moodle.perseusirk.ru/course/view.php?id=133#section-0</a>, где необходимо прикрепить:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Портфолио с дипломами, грамотами за участие в конкурсах регионального и всероссийского уровня в номинации «Street dance (хип-хоп)» за 2022 и 2023 год;
			</li>
			<li class="JuniorProfi-items">
				Мотивационное письмо на тему «Street dance (хип-хоп) в моей жизни», в котором написать о себе, о наиболее значимых мероприятиях, в которых вы принимали участие как исполнитель современной хореографии (не более 1000 символов).
			</li>
		</ul>
		<p class="EventsPage-text">
			Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке: <a style="color:blue" href="https://р38.навигатор.дети/program/24455-programma-laboratoriya-tvorchestva-street-dance">https://р38.навигатор.дети/program/24455-programma-laboratoriya-tvorchestva-street-dance</a>.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся на профильную смену:
		</p>

		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Абатурова Варвара Викторовна</li>
			<li class="EventsPage-number">Балабанова Валерия Петровна</li>
			<li class="EventsPage-number">Балабанова Вероника Петровна</li>
			<li class="EventsPage-number">Белоусова Нелли Антоновна</li>
			<li class="EventsPage-number">Дашинимаева Арина Адреевна</li>
			<li class="EventsPage-number">Иванова Александра Олеговна</li>
			<li class="EventsPage-number">Кобелева Анна Денисовна</li>
			<li class="EventsPage-number">Ланина Арина Ильинична</li>
			<li class="EventsPage-number">Магадеева Диана Алексеевна</li>
			<li class="EventsPage-number">Овчинникова Дарья Александровна</li>
			<li class="EventsPage-number">Пахалуев Никита Максимович</li>
			<li class="EventsPage-number">Постникова Ангелина Романовна</li>
			<li class="EventsPage-number">Потапова Василиса Сергеевна</li>
			<li class="EventsPage-number">Судакова Александра Алексеевна</li>
			<li class="EventsPage-number">Файзулина Амина Алексеевна</li>
			<li class="EventsPage-number">Черникова Анастасия Алексеевна</li>
			<li class="EventsPage-number">Баженова Ксения Викторовна</li>
			<li class="EventsPage-number">Брынцева Валерия Михайловна</li>
			<li class="EventsPage-number">Готалюк Милана Вячеславовна</li>
			<li class="EventsPage-number">Дьяконова Алина</li>
			<li class="EventsPage-number">Зотеева Эвелина Максимовна</li>
			<li class="EventsPage-number">Крец Дарья Ивановна</li>
			<li class="EventsPage-number">Кузьминская Валерия Евгеньевна</li>
			<li class="EventsPage-number">Панфилов Тихон Андреевич</li>
			<li class="EventsPage-number">Патрина Екатерина Игоревна</li>
			<li class="EventsPage-number">Полянская Анастасия Кирилловна</li>
			<li class="EventsPage-number">Федорова Валерия Игоревна</li>
			<li class="EventsPage-number">Чикичёва Валерия Сергеевна</li>
			<li class="EventsPage-number">Чебан Ульяна Захаровна</li>
			<li class="EventsPage-number">Шипицин Александр Александрович</li>
			<li class="EventsPage-number">Шипицина Анна Александровна</li>
			<li class="EventsPage-number">Янкевич Кира Евгеньевна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>