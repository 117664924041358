<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage38.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Более 80 школьников Приангарья подготовились к участию в региональном этапе конкурса научно-технологических проектов «Большие вызовы»
			</h2>
			<p class="NewsCard-date">
				26.12.2023
			</p>
		</div>
	</div>
</template>