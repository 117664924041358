<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			«Научный прорыв»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 17 октября по 30 ноября 2022 года.
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			Регистрация участников: с 17 по 31 октября 2022 года.
		</h4>

		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr />

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Учредитель Конкурса
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор конкурса
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Участники конкурса: к участию приглашаются обучающиеся школ, СПО и дополнительного образования в возрасте от 14 до 18 лет. Участие может быть индивидуальным или в составе коллектива до 5 участников. Участника или коллектив возглавляет руководитель — педагогический работник.
		</p>
		<p class="EventsPage-text">
			Конкурсные материалы: для участия в конкурсе необходимо предоставить авторский видеоролик, продолжительностью 3-5 минут по соответствующей номинации. Требования к видеоролику указаны в положении конкурса.
		</p>
		<p class="EventsPage-text">
			Регистрация участников: с 17 по 31 октября 2022 года.
		</p>
		<p class="EventsPage-text">
			Формат проведения: дистанционный.
		</p>
		<hr>

		<h3 class="EventsPage-prof">
			Об олимпиаде
		</h3>
		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/12/%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2-%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D1%80%D1%8B%D0%B2.pdf" target="_blank">
			Об утверждении итогов Научный прорыв
		</a>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/11/%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D1%80%D1%8B%D0%B2.pdf" target="_blank">
			Научный прорыв
		</a>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/07/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-1.pdf" target="_blank">
			Приказ об утверждении Положения
		</a>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D1%80%D1%8B%D0%B2.pdf" target="_blank">
			Положение регионального конкурса Научный прорыв
		</a>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/08/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-2.-%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BD%D0%B0-%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5-%D0%BD%D0%B0-%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83-%D0%BF%D0%B5%D1%80%D1%81.-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85-1.docx" target="_blank">
			Приложение 2. Заявление на согласие на обработку перс. данных
		</a>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/08/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-3.-%D0%9F%D0%BE%D1%8F%D1%81%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%BA%D0%B0.xlsx" target="_blank">
			Приложение 3. Пояснительная записка
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Цель конкурса
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Развитие научного и творческого потенциала детей и молодежи посредством вовлечения в научно-познавательную и социально-значимую деятельность.
			</li>
			<li class="JuniorProfi-items">
				Конкурс приурочен к празднованию 85-летия со дня образования Иркутской области.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Номинации:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Регион Иркутск: глобальные проблемы современности и пути их решения;
			</li>
			<li class="JuniorProfi-items">
				Известные люди Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				Профессиональная династия Иркутской области.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Регистрация и участие в конкурсе
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сайт проведения конкурса: https://konkurs.ric38.ru/.
			</li>
			<li class="JuniorProfi-items">
				После регистрации пользователя необходимо выбрать желаемые номинации в списке конкурсов и подать заявку, прикрепив заявление, пояснительную записку и конкурсную работу.
			</li>
			<li class="JuniorProfi-items">
				Всем участникам также необходимо пройти регистрацию в Навигаторе дополнительного образования Иркутской области (ссылка будет доступна позднее).
			</li>
		</ul>
	</div>
</template>
