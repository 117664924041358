<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Оркестровое духовое исполнительство»
		</h2>
		<p class="EventsPage-date">
			с 16 по 29 августа 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=178" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25354-programma-orkestrovoe-dukhovoe-ispolnitelstvo " target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит профильную смену «Оркестровое духовое исполнительство» для учащихся от 10
			до 17 лет, обладающих основными музыкальными способностями (слух, ритм, память), исполнительскими навыками игры на
			духовых или ударных инструментах, а также начальными навыками игры в ансамбле.
		</p>

		<p class="EventsPage-text">
			Программа «Оркестровое духовое исполнительство» ориентирована на развитие мотивации учащихся к познанию через
			создание условий для раскрытия творческого потенциала в составе оркестра, выявление одаренных детей в области
			музыкального искусства, развитие умений и навыков игры на духовых инструментах в ансамбле и в оркестре,
			позволяющих исполнять музыкальные произведения в соответствии с необходимым уровнем музыкальной грамотности и
			стилевыми традициями, а также, приобщение детей к сценическим формам духового оркестрового исполнительства.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сорвин Александр Валерьевич, преподаватель по классу трубы государственного нетипового общеобразовательного
				бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска», дирижер
				Образцового детского духового оркестра «Серебряные трубы»;
			</li>
			<li class="JuniorProfi-items">
				Портяная Анастасия Александровна, преподаватель по классу саксофона государственного нетипового
				общеобразовательного бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г.
				Иркутска»;
			</li>
			<li class="JuniorProfi-items">
				Мархаев Кирилл Андреевич, преподаватель ГОБУДО Иркутская областная ДШИ;
			</li>
			<li class="JuniorProfi-items">
				Сергеев Олег Дмитриевич, преподаватель государственного нетипового общеобразовательного бюджетного учреждения
				Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска»;
			</li>
			<li class="JuniorProfi-items">
				Мешин Антон Андреевич, преподаватель государственного нетипового общеобразовательного бюджетного учреждения
				Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска»;
			</li>
			<li class="JuniorProfi-items">
				Федоренко Владислав Валерьевич, преподаватель ГБПОУ Иркутский областной музыкальный колледж им. Ф.Шопена;
			</li>
			<li class="JuniorProfi-items">
				Фролов Семён Александрович, преподаватель преподаватель государственного нетипового общеобразовательного
				бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Учащимся, обладающим исполнительскими навыками игры на духовых инструментах, а также начальными навыками игры
				в ансамбле, необходимо обязательно зарегистрироваться до 6 августа 2024 года в системе Навигатор дополнительного
				образования Иркутской области по ссылке:
				https://р38.навигатор.дети/program/25354-programma-orkestrovoe-dukhovoe-ispolnitelstvo
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету участника на платформе электронной информационно-образовательной среды
				Образовательного центра «Персей» по ссылке: https://moodle.perseusirk.ru/course/view.php?id=178
			</li>
			<li class="JuniorProfi-items">
				где необходимо прикрепить:
			</li>
			<li class="JuniorProfi-items">
				портфолио с Дипломами за участие в конкурсах регионального и всероссийского уровня (2023-2024 год);
			</li>
			<li class="JuniorProfi-items">
				рекомендательное письмо от образовательного учреждения об участии в профильной смене.
			</li>
			<li class="JuniorProfi-items">
				Каждый участник смены должен иметь личный музыкальный инструмент для занятий.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>