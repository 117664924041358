<template>
	<div class="SmartiesAndSmartiesPage _cover">
		<h2 class="BigChallengesPage-title _cover-title">
			Умницы и Умники
		</h2>

		<div class="DirectionBlock-img-box" style="width: 600px;">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/BigChallenges/u&u1kanal.webp" alt="Логотип" />
		</div>

		<a class="GoldenFundOfSiberia-btn temp-btn"
			href="https://abiturient.mgimo.ru/olimpiady/umniki?utm_source=google.com&utm_medium=organic&utm_campaign=google.com&utm_referrer=google.com">
			Сайт олимпиады
		</a>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Цель Олимпиады:</b> выявление у школьников творческих способностей и интереса к научной деятельности, создание
			необходимых условий для поддержки талантливой молодежи, пропаганда научных знаний.
		</p>

		<p class="JuniorProfi-sub-title">
			Этапы олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Отборочный этап (г. Иркутск).
			</li>
			<li class="JuniorProfi-items">
				Заключительный этап, включающий 3 тура: четвертьфиналы, полуфиналы и финал (г. Москва).
			</li>
		</ul>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Участники:</b> к участию в отборочном этапе приглашаются обучающиеся 10-х классов Иркутской области, которые
			успешно прошли обучение в Образовательном центре «Персей» в дистанционном курсе: «Умницы и умники».
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Преимущества участия в олимпиаде:</b> победителям и призерам олимпиады предоставляется возможность поступления
			в
			МГИМО МИД России на льготных условиях.
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Как принять участие:</b> Информация по участию в Олимпиаде 2025 г. побудет опубликована в первой половине 2025
			г.
		</p>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Гавриленко Татьяна Георгиевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

	</div>

	<!-- Архив -->
	<ArchiveSmartiesAndSmarties />

</template>

<script>
import ArchiveSmartiesAndSmarties from '@/components/ArchiveSmartiesAndSmarties.vue';

export default {
	components: {
		ArchiveSmartiesAndSmarties,
	}
}

</script>