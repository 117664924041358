<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			15.01.2024
		</p>
		<h2 class="NewsPage-title">
			Более 30 тысяч школьников Приангарья в 2024 году смогут принять участие в мероприятиях «Персея»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage42.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Региональный центр выявления и поддержки одаренных детей «Персей» в 2024 году планирует провести около 200 мероприятий, направленных на выявление, поддержку и сопровождение талантливых и одаренных детей Иркутской области. Участие в них примут более 30 тысяч школьников Приангарья. Об этом сообщили в региональном министерстве образования.
			</p>
			<p class="NewsPage-sub-title">
				Также в ведомстве отметили, что в наступившем году в «Персее» будут реализованы новые программы. В перечне профильных смен на 2024 год – научные программы по искусственному интеллекту, информационным технологиям, геологии, минералогии, инженерной и изобретательской деятельности. По направлению «Искусство» проведут творческие программы, объединяющие музыку, театр, изобразительное и декоративно-прикладное искусство. Для школьников, занимающихся спортом, пройдет профильная смена «Многоборье ГТО». Всего запланировано более 60 профильных смен.
			</p>
			<p class="NewsPage-sub-title">
				– В 2023 году Образовательный центр «Персей» в рамках реализации федерального проекта «Успех каждого ребенка» президентского национального проекта «Образование» пополнил материально-техническую базу учебными наборами FPV квадрокоптеров, конструкторами, студийным фотооборудованием, приобретено профессиональное оборудование в хореографический и спортивный залы. В 2024 году «Персей» откроет новую площадку для проведения еженедельных программ в Иркутске. Благодаря этому у учащихся появятся новые возможности, в том числе и для проведения научных исследований, – подчеркнул министр образования Иркутской области Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Глава регионального министерства отметил, что в августе в «Персее» вновь проведут выездную программу «Байкал - природная лаборатория» совместно с Лимнологическим институтом Сибирского отделения Российской Академии наук. Участниками экспедиции по Байкалу станут 12 школьников Иркутской области, которые предварительно пройдут конкурсный отбор.
			</p>
			<p class="NewsPage-sub-title">
				Ключевыми конкурсными и олимпиадными мероприятиями 2024 года станут региональный этап телевизионной гуманитарной олимпиады школьников «Умницы и умники», чемпионаты «Юниор Профи», региональный этап всероссийской олимпиады школьников, открытая региональная межвузовская олимпиада обучающихся Иркутской области «Золотой Фонд Сибири», региональный конкурс «Лучший ученик года», а также олимпиада «Олимпик» для ребят 3-4 классов. 
			</p>
			<p class="NewsPage-sub-title">
				Планируется, что участниками еженедельных курсов станут более 1,5 тысячи школьников, а занятия с преподавателями, наставниками позволят ребятам реализовать свой потенциал и продолжить изучение точных наук. Также школьники смогут получить актуальные знания и умения на дистанционных курсах и через проект «Мобильная лаборатория», разработанный специально для отдаленных школ Приангарья.
			</p>
			<p class="NewsPage-sub-title">
				Открывают 2024 год в Образовательном центре «Персей» программы по олимпиадной подготовке для победителей и призеров муниципального этапа Всероссийской олимпиады школьников.
			</p>
			<p class="NewsPage-sub-title">
				По материалам пресс-службы Правительства Иркутской области <a class="NewsPage-link" href="https://irkobl.ru/news/3566269/">ссылка</a>
			</p>
			
		</div>
	</div>
</template>
