<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Олимпиадная математика»
		</h2>
		<p class="EventsPage-date">
			1 этап – с 5 по 10 июля 2023 года
		</p>
		<p class="EventsPage-date">
			2 этап – с 6 по 11 сентября 2023 года
		</p>
		<p class="EventsPage-date">
			3 этап – с 15 по 20 декабря 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21341-programma-olimpiadnaya-matematika" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				Егорова Юлия Сергеевна
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				89246361877
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				perseus.irk@rikp38.ru
			</p>
		</div>
		<hr>
		<p class="EventsPage-sub-title">
			Профильная смена состоится на базе кампуса «ОЦ «Персей», расположенного по адресу Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны.<br>
			Профильная смена проводится для обучающихся общеобразовательных организаций Иркутской области, вошедших, по результатам прохождения отбора (май 2023 года), в состав Сборной Иркутской области по углубленному изучению математики.<br>
			Участие в профильной смене – бесплатно.<br>
			Обязательна регистрация в Навигаторе дополнительного образования детей Иркутской области: <a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21341-programma-olimpiadnaya-matematika">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21341-programma-olimpiadnaya-matematika</a><br>
		</p>
		<p class="EventsPage-sub-title">
			В общей системе естественнонаучного образования современного человека математика играет основополагающую роль. Под влиянием математики развиваются новые направления научных исследований, возникающие на стыке с другими науками, создаются техника и технологическая база инновационного развития общества.<br>
			Цель программы — предоставление дополнительных возможностей для обучающихся, имеющим особые достижения в изучении математики, для обеспечения высокого качества их подготовки к результативному участию в мероприятиях межрегионального, всероссийского и международного уровней.<br>
			Преподаватель:<br>
			Штыков Николай Николаевич, учитель математики высшей квалификационной категории, педагог дополнительного образования МБОУ г. Иркутска гимназии № 1, доцент кафедры социально-экономических дисциплин ФГБОУ ВО «ИГУ», член жюри и оргкомитета муниципальных и региональных олимпиад по математике, тренер, подготовивший 14 победителей и призёров заключительного этапа всероссийской олимпиады школьников по математике, призёра Международной математической олимпиады (Romanian Master of Mathematics), победителя Всекитайской математической олимпиады, победителя Международной математической олимпиады (IMO).<br>
		</p>
	</div>
</template>