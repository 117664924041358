<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			02.05.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="">
				Более 180 школьников региона приняли участие в апрельских научных сменах «Персея»
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage58.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В региональном центре выявления и поддержки одаренных детей «Персей» в апреле прошли пять профильных научных смен: «Ракетостроение», «Байкал – природная лаборатория», «Экология», «Авиамоделирование и беспилотный транспорт» и «Школа безопасности».
			</p>
			<p class="NewsPage-sub-title">
				Участниками образовательных интенсивов стали 182 школьника из Саянска, Ангарска, Усть-Илимска, Иркутска, Усолье-Сибирского, Братска, Нижнеудинска, Шелехово, Киренска, Тайшета, Алазмая, Нижнеудинского, Нукутского, Боханского, Эхирит-Булагатского, Балаганского, Усть-Илимского, Усольского, Ольхонского, Куйтунского, Усть-Удинского, Нижнеилимского, Тулунского, Шелеховского, Жигаловского, Слюдянского, Братского, Нукутского, Качугского, Заларинского, Казачинско-Ленского района.
			</p>
			<p class="NewsPage-sub-title">
				Программа <b>«Байкал – природная лаборатория»</b> проходила совместно с Лимнологическим институтом Сибирского отделения РАН, знакомила ребят экосистемой озера Байкал. Школьники в ходе обучения проводили качественные и количественные анализы проб воды Байкала, используя молекулярно-биологические методы: выделение ДНК, ПЦР, гель-электорофорез, клонирование.
			</p>
			<p class="NewsPage-sub-title">
				Отдельный раздел программы был посвящен изучению  гидробиологии, альгологии и фитотоксикологии, где ребята проводили анализы цианобактерии и зоопланктона озера Байкал.
			</p>
			<p class="NewsPage-sub-title">
				– На смене мы открыли для себя биологию с практической стороны. Мы проводили химико-биологические  анализы, работали на современном оборудовании, которого точно нет в школах. Большое спасибо нашим  преподавателям и научным сотрудникам Сибирского отделения РАН, которые погрузили нас в этом увлекательный мир микробиологии и помогли понять, чем могут заниматься биологи. Все это скажется на нашем будущем профессиональном самоопределении, – рассказали Никита Бабашко и Алена Соболева, участники программы «Байкал – природная лаборатория».
			</p>
			<p class="NewsPage-sub-title">
				На профильной смене <b>«Ракетостроение»</b> ребята собирали малые космические аппараты, изучали основы программирования и электротехники, а также проводили запуски своих первых ракет. Программа проходила совместно с Иркутским планетарием.
			</p>
			<p class="NewsPage-sub-title">
				– Самым удивительным было то, что мы смогли собирать наши ракеты и успешно их запустить. Мне удалось спроектировать и собрать свою ракету с системой спасения. Когда проходили первые запуски, я чувствовал себя инженером, чья ракета вот-вот полетит с Байконура прямо в космос. Это непередаваемые эмоции, – поделился Григорий Пятков, участник профильной смены <b>«Ракетостроение»</b>.
			</p>
			<p class="NewsPage-sub-title">
				Программа помогла школьником в профессиональном самоопределении. Участники отмечают, что в дальнейшем они продолжат изучать ракетостроение и хотели бы связать свою жизнь с инженерной деятельностью.
			</p>
			<p class="NewsPage-sub-title">
				Свои первые запуски смогли и провести участники программы <b>«Авиамоделирование и беспилотный транспорт»</b>. На смене ребята изучали особенности построения дронов, работали с прототипами изделий авиастроительной отрасли, получали навык работы с производственным и испытательным оборудованием, а также собирали свои дроны, проводили их запуски и участвовали в соревнованиях по дрон-рейсингу (гонках на дронах).
			</p>
			<p class="NewsPage-sub-title">
				Как рассказывают участники программы, обучение было сложным, но интересным. За четырнадцать дней школьники смогли не только собрать свои дроны, но и отточить навыки полета, которые в дальнейшем им пригодятся и в соревновательной деятельности.
			</p>
			<p class="NewsPage-sub-title">
				В завершении программы школьники приняли участие в областных соревнованиях «Пилоты будущего» от Движения первых и прошли во всероссийский этап, который пройдет в Казани летом этого года.
			</p>
			<p class="NewsPage-sub-title">
				Образовательная программа <b>«Экология»</b> объединила участников школьных лесничеств, чтобы ребята узнали больше о лесной экосистеме, познакомились с животным и растительным миром сибирской тайги, узнали, как защищать лес от пожаров и противозаконных действий. Смена проходила при поддержке ФГБУ «Заповедное Прибайкалье».
			</p>
			<p class="NewsPage-sub-title">
				Во время обучения ребята проводили исследования свойств лесных семян, осуществляли лесопатологический мониторинг, знакомились с экотропами «Персея» и посетили интерактивную экспозицию ФГБУ «Заповедное Прибайкалье» в Иркутске.
			</p>
			<p class="NewsPage-sub-title">
				Алина Иванова, Дарья Голодникова, Арина Чернова – участницы профильной смены «Экология» отметили, что получили бесценный опыт, который поможет им в дальнейшей экологической деятельности, а также при сдаче экзаменов и в участии в олимпиадах и конкурсах.
			</p>
			<p class="NewsPage-sub-title">
				Программа также была насыщенна на научную и проектную работу.  Школьники готовили и защищали проекты, снимали эко-ролики, проводили научные исследования, которые помогут в дальнейшем сохранить уникальную природу сибирской тайги.
			</p>
			<p class="NewsPage-sub-title">
				На закрытие смены к ребятам приехал заместитель министра лесного комплекса Иркутской области Валентин Широков. Замминистра поделился историей, с которой начался его профессиональный путь. Примечательно, что Валентин Анатольевич определился с будущей профессией со слета школьных лесничеств, которое проходило на базе бывшего ДОЛ «Галактика» ныне Образовательный центр «Персей».
			</p>
			<p class="NewsPage-sub-title">
				– Это живописное место вдали города несет большую историю, – отметил Валентин Широков.
			</p>
			<p class="NewsPage-sub-title">
				Участники профильной смены <b>«Школа безопасности»</b> познакомились с работой аварийно-спасательных служб, правилами организации спасения пострадавших в лесу, спортивному ориентированию, а также готовились к стартам региональных и межрегиональных соревнований.
			</p>
			<p class="NewsPage-sub-title">
				В завершении обучения юные спасатели региона приняли участие Областных соревнованиях по спортивному ориентированию. Также по итогам сборов определились команды, которые примут участие в межрегиональном этапе Сибирского федерального округа Всероссийских соревнований «Школа безопасности» и «Юный спасатель», они пройдут в июне в г. Новосибирск.
			</p>
			<p class="NewsPage-sub-title">
				– На протяжении всей смены мы упорно тренировались и готовились к чемпионатам. За две недели сборов мы получили бесценный опыт, который смогли сразу же применить в соревнованиях. Основной уклон в подготовке был сделан на получение умений в проведении поисково-спасательных работ в природной и техногенной среде, а также в акватории. Сборы помогли нам подготовиться к предстоящим соревнованиям и занять призовые места, – рассказали Константин Виноградов и Семён Свинин, участники профильной смены.
			</p>
			<p class="NewsPage-sub-title">
				Следующий блок научных программ в Образовательном центре «Персей»   пройдет в конце августа. Школьники смогут принять участие в сменах по физике и математике, а также проектно-исследовательской деятельности.
			</p>

		</div>
	</div>
</template>
