<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Астрономия: наука о звёздах и не только»
		</h2>
		<p class="EventsPage-date">
			с 14 по 23 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=147" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24647-programma-astronomiya-nauka-o-zvezdakh-i-ne-tolko" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Астрономия: наука о звёздах и не только» для учащихся 6-11-х классов общеобразовательных организаций.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (мотивационное письмо) на платформе ОЦ «Персей», до 4 марта 2024 года на платформе обучения Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 14 марта 2024 года по 23 марта 2024 года на бесплатной основе в Образовательном центре «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<p class="EventsPage-text">
				Для участников смены будет проведено организационное собрание 6 марта 2024 г. в 20.00, <a style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1708921820">ссылка на подключение</a>
		</p>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 5 марта на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>
		
		<h2 class="JuniorProfi-sub-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену:
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Алексеева Варвара Михайловна</li>
			<li class="EventsPage-number">Аюшеев Артём Александрович</li>
			<li class="EventsPage-number">Бейдик Мария Андреевна</li>
			<li class="EventsPage-number">Бирюков Сергей Денисович</li>
			<li class="EventsPage-number">Буентаев Дамир Петрович</li>
			<li class="EventsPage-number">Геруцкая Елизавета Юревна</li>
			<li class="EventsPage-number">Говорин Андрей Артурович</li>
			<li class="EventsPage-number">Грициндишина Виктория Ярославовна</li>
			<li class="EventsPage-number">Заико Глеб Константинович</li>
			<li class="EventsPage-number">Китаева Диана</li>
			<li class="EventsPage-number">Крупанова Елизавета Витальевна</li>
			<li class="EventsPage-number">Кудеев Александр Сергеевич</li>
			<li class="EventsPage-number">Леликов Матвей Александрович</li>
			<li class="EventsPage-number">Лысцов Егор Леонидович</li>
			<li class="EventsPage-number">Макарова Ксения Викторовна</li>
			<li class="EventsPage-number">Михалев Роман Константинович</li>
			<li class="EventsPage-number">Парыгин Владислав Петрович</li>
			<li class="EventsPage-number">Пивнёва Екатерина Павловна</li>
			<li class="EventsPage-number">Потапова Полина Алексеевна</li>
			<li class="EventsPage-number">Пятков Георгий Анатольевич</li>
			<li class="EventsPage-number">Пятков Григорий Анатольевич</li>
			<li class="EventsPage-number">Скрябикова Елена Александровна</li>
			<li class="EventsPage-number">Соколова Евгения Николаевна</li>
			<li class="EventsPage-number">Устинова Елизавета Константиновна</li>
			<li class="EventsPage-number">Шелест Михаил Иванович</li>
		</ol>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/EZsd/hqcHfzyYE" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/uUzJvrJLflxObQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>