<template>
	<div class="DirectionBlock _cover">

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/НАУКА.webp" alt="Наука" />
		</div>

		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe class="Intelligence-block-video-items"
					src="https://www.youtube.com/embed/xtXYF_Qs0Mo?si=-hTdLT86-G2fQ9Sd" title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
					Направление Наука
				</iframe>
			</div>
		</div>

		<!-- Профильные смены -->
		<div class="Period">
			<h2 class="Period-title _cover-title">Профильные смены</h2>

			<ul class="grid-container">
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage186' })">
						<EventsCard186></EventsCard186>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage185' })">
						<EventsCard185></EventsCard185>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage184' })">
						<EventsCard184></EventsCard184>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage180' })">
						<EventsCard180></EventsCard180>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage157' })">
						<EventsCard157></EventsCard157>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage139' })">
						<EventsCard139></EventsCard139>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage133' })">
						<EventsCard133></EventsCard133>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage132' })">
						<EventsCard132></EventsCard132>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage129' })">
						<EventsCard129></EventsCard129>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage128' })">
						<EventsCard128></EventsCard128>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage127' })">
						<EventsCard127></EventsCard127>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage126' })">
						<EventsCard126></EventsCard126>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage111' })">
						<EventsCard111></EventsCard111>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage123' })">
						<EventsCard123></EventsCard123>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage124' })">
						<EventsCard124></EventsCard124>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage118' })">
						<EventsCard118></EventsCard118>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage112' })">
						<EventsCard112></EventsCard112>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage108' })">
						<EventsCard108></EventsCard108>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage104' })">
						<EventsCard104></EventsCard104>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage100' })">
						<EventsCard100></EventsCard100>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage103' })">
						<EventsCard103></EventsCard103>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage102' })">
						<EventsCard102></EventsCard102>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage99' })">
						<EventsCard99></EventsCard99>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage98' })">
						<EventsCard98></EventsCard98>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage97' })">
						<EventsCard97></EventsCard97>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage96' })">
						<EventsCard96></EventsCard96>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage95' })">
						<EventsCard95></EventsCard95>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage94' })">
						<EventsCard94></EventsCard94>
					</a>
				</li>

			</ul>
		</div>


		<!-- Завершенные программы -->
		<div class="Period">
			<h2 class="Period-title _cover-title">Завершенные программы</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">Подробнее</summary>
				<ul class="grid-container">
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage59' })">
							<EventsCard59></EventsCard59>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage58' })">
							<EventsCard58></EventsCard58>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage42' })">
							<EventsCard42></EventsCard42>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage41' })">
							<EventsCard41></EventsCard41>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage28' })">
							<EventsCard28></EventsCard28>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage26' })">
							<EventsCard26></EventsCard26>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage22' })">
							<EventsCard22></EventsCard22>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage21' })">
							<EventsCard21></EventsCard21>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage23' })">
							<EventsCard23></EventsCard23>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage12' })">
							<EventsCard12></EventsCard12>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage11' })">
							<EventsCard11></EventsCard11>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage20' })">
							<EventsCard20></EventsCard20>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage19' })">
							<EventsCard19></EventsCard19>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage6' })">
							<EventsCard6></EventsCard6>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage7' })">
							<EventsCard7></EventsCard7>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage8' })">
							<EventsCard8></EventsCard8>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage9' })">
							<EventsCard9></EventsCard9>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage10' })">
							<EventsCard10></EventsCard10>
						</a>
					</li>
				</ul>
			</details>

		</div>
	</div>
</template>

<script>
import EventsCard6 from '../EventsCard/EventsCard6'
import EventsCard7 from '../EventsCard/EventsCard7'
import EventsCard8 from '../EventsCard/EventsCard8'
import EventsCard9 from '../EventsCard/EventsCard9'
import EventsCard10 from '../EventsCard/EventsCard10'
import EventsCard11 from '../EventsCard/EventsCard11'
import EventsCard12 from '../EventsCard/EventsCard12'
import EventsCard19 from '../EventsCard/EventsCard19'
import EventsCard20 from '../EventsCard/EventsCard20'
import EventsCard21 from '../EventsCard/EventsCard21'
import EventsCard22 from '../EventsCard/EventsCard22'
import EventsCard23 from '../EventsCard/EventsCard23'
import EventsCard26 from '../EventsCard/EventsCard26'
import EventsCard28 from '../EventsCard/EventsCard28'
import EventsCard41 from '../EventsCard/EventsCard41'
import EventsCard42 from '../EventsCard/EventsCard42'
import EventsCard58 from '../EventsCard/EventsCard58'
import EventsCard59 from '../EventsCard/EventsCard59'
import EventsCard94 from '../EventsCard/EventsCard94'
import EventsCard95 from '../EventsCard/EventsCard95'
import EventsCard96 from '../EventsCard/EventsCard96'
import EventsCard97 from '../EventsCard/EventsCard97'
import EventsCard98 from '../EventsCard/EventsCard98'
import EventsCard99 from '../EventsCard/EventsCard99'
import EventsCard100 from '../EventsCard/EventsCard100'
import EventsCard102 from '../EventsCard/EventsCard102'
import EventsCard103 from '../EventsCard/EventsCard103'
import EventsCard104 from '../EventsCard/EventsCard104'
import EventsCard108 from '../EventsCard/EventsCard108'
import EventsCard111 from '../EventsCard/EventsCard111'
import EventsCard112 from '../EventsCard/EventsCard112'
import EventsCard118 from '../EventsCard/EventsCard118'
import EventsCard123 from '../EventsCard/EventsCard123'
import EventsCard124 from '../EventsCard/EventsCard124'
import EventsCard126 from '../EventsCard/EventsCard126'
import EventsCard127 from '../EventsCard/EventsCard127'
import EventsCard128 from '../EventsCard/EventsCard128'
import EventsCard129 from '../EventsCard/EventsCard129'
import EventsCard132 from '../EventsCard/EventsCard132'
import EventsCard133 from '../EventsCard/EventsCard133'
import EventsCard139 from '../EventsCard/EventsCard139'
import EventsCard157 from '../EventsCard/EventsCard157'
import EventsCard180 from '../EventsCard/EventsCard180'
import EventsCard184 from '../EventsCard/EventsCard184'
import EventsCard185 from '../EventsCard/EventsCard185'
import EventsCard186 from '../EventsCard/EventsCard186'

export default {
	components: {
		EventsCard6,
		EventsCard7,
		EventsCard8,
		EventsCard9,
		EventsCard10,
		EventsCard11,
		EventsCard12,
		EventsCard19,
		EventsCard20,
		EventsCard21,
		EventsCard22,
		EventsCard23,
		EventsCard26,
		EventsCard28,
		EventsCard41,
		EventsCard42,
		EventsCard58,
		EventsCard59,
		EventsCard94,
		EventsCard95,
		EventsCard96,
		EventsCard97,
		EventsCard98,
		EventsCard99,
		EventsCard100,
		EventsCard102,
		EventsCard103,
		EventsCard104,
		EventsCard108,
		EventsCard111,
		EventsCard112,
		EventsCard118,
		EventsCard123,
		EventsCard124,
		EventsCard126,
		EventsCard127,
		EventsCard128,
		EventsCard129,
		EventsCard132,
		EventsCard133,
		EventsCard139,
		EventsCard157,
		EventsCard180,
		EventsCard184,
		EventsCard185,
		EventsCard186,
	},
}
</script>
