<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Программирование и анализ данных»
		</h2>
		<p class="EventsPage-date">
			с 19 апреля по 11 мая 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=159" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24754-programma-programmirovanie-i-analiz-dannykh"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Программирование и анализ данных» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Участники курса познакомятся с современными технологиями вычисления, которые используются в научных исследованиях.
			У участников будет возможность освоить и углубить свои знания языка программирования Python. В том числе, научатся
			применять его в задачах анализа временных рядов, изучить и использовать библиотеки Python для обработки временных
			рядов, отображения временных рядов. Преимуществом будет, если участник уже знает базовые конструкции языка
			программирования Python: переменные, числа, строки. А также приветствуются знания в основных свойствах функций и
			умения строить графики функций.
		</p>

		<p class="EventsPage-text">
			По итогам изучения курса пройдёт конкурс в ИДСТУ СО РАН на лучшие работы по программированию. Для участников
			конкурса будет проведен разбор заданий, победители будут награждены дипломами и премиями
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Башарина Ольга Юрьевна, научный сотрудник ФГБОУ ИДСТУ СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Воскобойников Михаил Леонтьевич, младший научный сотрудник ФГБОУ ИДСТУ СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Костромин Роман Олегович, старший, научный сотрудник ФГБОУ ИДСТУ СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Михеев Михаил Алексеевич, младший научный сотрудник ФГБОУ ИДСТУ СО РАН.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 19 апреля 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 19 апреля 2024 года на платформе Moodle, Образовательного центра
				«Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/xVAWC9oX_RSC3Q" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>