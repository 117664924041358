<template>
	<div class="VacanciesForAdmission _cover">
		<h2 class="VacanciesForAdmission-title _cover-title">
			Вакантные места для приема (перевода) обучающихся
		</h2>
		<div class="VacanciesForAdmission-sub-title">
			Вакантные места для приема и перевода:
		</div>
		<p class="VacanciesForAdmission-dist">
			Не предусмотрены.
		</p>
	</div>
</template>