<template>
	<div class="ConferenceSeminars _cover">
		<h2 class="ConferenceSeminars-title _cover-title">
			Мероприятия ГАНУ ДО ИО «Региональный центр выявления и поддержки одаренных детей «Персей» в рамках региональной
			программы методической недели «августовского» педагогического совещания – 2024
		</h2>

		<h3 class="Documents-sub-title">
			«Сетевая образовательная программа с Образовательным центром Персей - новые возможности для школ
			региона»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%A1%D0%B5%D1%82%D0%B5%D0%B2%D1%8B%D0%B5-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B.pdf"
					target="_blank">
					Презентация
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://rutube.ru/video/private/3f11d52ea14ce15db99f63eae99d2d85/?p=BwBsV-Dba_LnhGVU2juGxg"
					target="_blank">
					Видеозапись мероприятия
				</a>
			</li>
		</ul>

		<h3 class="Documents-sub-title">
			«Формирование единых подходов к подготовке проектных и проектно-исследовательских работ школьников»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%95%D0%B4%D0%B8%D0%BD%D1%8B%D0%B5-%D0%BF%D0%BE%D0%B4%D1%85%D0%BE%D0%B4%D1%8B-%D0%BA-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B5-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BE%D0%B2.pdf"
					target="_blank">
					Презентация
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://rutube.ru/video/private/42994b29565bc48831736c41af57dc46/?p=7f0-We296RhoIZhPYchIxw"
					target="_blank">
					Видеозапись мероприятия
				</a>
			</li>
		</ul>
	</div>
</template>