<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			16.07.2024
		</p>
		<h2 class="NewsPage-title">
			Итоги июльских профильных смен по направлению «Искусство»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage73.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В региональном центре выявления и поддержки одаренных детей «Персей» подвели итоги июльских профильных смен
				«Оркестровое струнно-смычковое исполнительство», «Классический танец» и «Арт перформанс». Участие в программах
				приняли 67 школьников из Иркутска, Ангарска, Тайшета, Тулуна, Братска, Саянска, Усолье-Сибирского,
				Усть-Ордынска, Вихоревки, Шелехова, с. Пивоварихи, с. Максимовщины, с. Мамонов и Большереченского муниципального
				образования.
			</p>
			<p class="NewsPage-sub-title">
				Смены проходили с 3 по 16 июля и завершились отчетным концертом, на котором школьники продемонстрировали все,
				чему они научились за эти две недели. Концерт стал ярким финалом насыщенной учебной и творческой работы, показав
				высокие достижения участников в области искусства.
			</p>
			<p class="NewsPage-sub-title">
				Участники программы «Арт перформанс» подготовили пластическую фантазию «Четыре цивилизации – четыре стихии», в
				которой они с помощью движений, света и звука изобразили взаимодействие и противостояние Земли, Воды, Огня и
				Воздуха.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали преподаватели смены – это коллаборация театрального и изобразительного творчества. На программе
				дети занимались не только постановкой этюда, но и изготавливали реквизит и декорации. Школьники работали в
				технике витража, создавая уникальные элементы оформления сцены, которые добавили красок и выразительности их
				представлению. Это позволило участникам развить навыки работы с различными материалами, а также углубить свое
				понимание искусства через практическую деятельность.
			</p>
			<p class="NewsPage-sub-title">
				– Ребята приехали разные, кто-то ни разу не пробовал себя в театральной деятельности, а другие никогда не
				рисовали. Наша задача была объединить всех детей, дать им возможность попробовать себя в новых видах творчества
				и научить работать в команде. Мы стремились создать условия, в которых каждый ребёнок смог бы раскрыть свой
				потенциал и почувствовать себя частью единого творческого коллектива, – отметила Виктория Алексеюк,
				преподаватель смены.
			</p>
			<p class="NewsPage-sub-title">
				Итогом работы на смене «Классический танец» стали хореографические этюды. По словам Натальи Дегтеревой,
				преподавателя программы, школьницы смогли улучшить свою технику и выразительность движений, овладеть новыми
				элементами классического танца и почувствовать себя настоящими артистками балета. Участницы программы также
				научились работать в ансамбле, что способствовало развитию их навыков взаимодействия и коллективной работы на
				сцене.
			</p>
			<p class="NewsPage-sub-title">
				– Школьницы продемонстрировали невероятное усердие и талант. За короткое время они смогли значительно повысить
				свой уровень, освоив сложные элементы и доведя свои выступления до высокой степени профессионализма. Я горжусь
				их достижениями и уверена, что многие из них продолжат развивать свои навыки и достигнут больших успехов в
				будущем, – отметила Наталья Дегтерева.
			</p>
			<p class="NewsPage-sub-title">
				За две недели юные музыканты, участники программы «Оркестровое струнно-смычковое исполнительство», поготовили
				насыщенную концертную программу. В их репертуар вошли известные произведения таких композиторов, как:
				Петр Ильич Чайковский, Астор Пьяццолла, Поль Мориа, Франц Шуберт, Владимир Шанский, Хаяо Миядзаки, Эдуардо ди
				Капуа и Дарио Марианелли.
			</p>
			<p class="NewsPage-sub-title">
				Это разнообразие музыкальных стилей и направлений позволило участникам проявить своё мастерство и музыкальные
				способности, что нашло отклик у восхищенной публики. Программа проходила под руководством Татьяны Акимовой,
				заслуженного работника культуры и искусства Иркутской области, заведующей предметно-цикловой комиссии
				«Оркестровые струнные инструменты» Иркутского областного музыкального колледжа им.Ф.Шопена, художественного
				руководителя и дирижера струнного и симфонического оркестров колледжа.
			</p>
			<p class="NewsPage-sub-title">
				На смене участники осваивали исполнение музыкальных произведений в соответствии с их уникальными стилистическими
				особенностями, совершенствовали синхронность в коллективной игре и развивали чувство восприятия жестов дирижера.
			</p>
			<p class="NewsPage-sub-title">
				– Мероприятия такого рода не только способствуют развитию творческих способностей детей, но и помогают им
				обрести уверенность в себе, научиться работать в коллективе и совершенствовать своё мастерство под руководством
				опытных педагогов. «Персей» продолжает свою работу, предоставляя одарённым детям региона возможность развивать
				свои таланты и достигать новых вершин в искусстве, – поделилась Ангелина Сыроватская, руководитель отдела
				развития образовательных программ по направлению «Искусство».
			</p>
			<p class="NewsPage-sub-title">
				Помимо учебных занятий, для ребят были организованы мероприятия от специалистов Медиацентра «Телешко» и
				Мобильного планетария Точки будущего «Чудища морей: доисторическое приключение». В рамках этих мероприятий дети
				получили возможность погрузиться в увлекательные научные исследования и открытия, узнать о тайнах древних
				цивилизаций через интерактивные и образовательные программы, а также повысить свою медиаграмотность.
			</p>
			<p class="NewsPage-sub-title">
				В августе этого года в «Персее» пройдут профильные смены «От этюда к спектаклю» и «Оркестровое духовое
				исполнительство». Участие в них примут около 200 талантливых и одаренных школьников региона.
			</p>
			<p class="NewsPage-sub-title">
				Трансляция концерта в нашем сообществе Вконтакте: <a class="NewsPage-link"
					href="https://vk.com/wall-195976317_2298">https://vk.com/wall-195976317_2298</a>
			</p>

		</div>
	</div>
</template>
