<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage65.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» завершился еженедельный курс по основам фотосъемки
			</h2>
			<p class="NewsCard-date">
				08.06.2024
			</p>
		</div>
	</div>
</template>