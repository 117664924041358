<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			06.05.2024
		</p>
		<h2 class="NewsPage-title">
			Большое путешествие Мобильной лаборатории «Персея»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage59.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Проект «Мобильный Персей» Регионального центра выявления и поддержки одаренных детей Образовательный центр «Персей» продолжает знакомить школьников отдаленных муниципалитетов Иркутской области с миром науки и техники.
			</p>
			<p class="NewsPage-sub-title">
				Весной этого года мобильная лаборатория «Персея» посетила 35 образовательный учреждений в Нижнеудинском и Братском районе. Участниками проекта стали более 5499 школьников 4-8-х классов.
			</p>
			<p class="NewsPage-sub-title">
				Уникальность проекта в том, что в регионе нет подобных мероприятий для ребят, которые проживают вдали от областного центра. На занятиях под руководством наставников Образовательного центра «Персей»  школьники приобретают опыт научного исследования, а также получают возможность работы с современным и высокотехнологичным оборудованием.
			</p>
			<p class="NewsPage-sub-title">
				Программы «Мобильного Персея» включают занятия по аэродинамики, пневматики, механики, прикладной математике, искусственного интеллекта, геометрии, астрономии, а также химико-экологического анализа.
			</p>
			<p class="NewsPage-sub-title">
				– На занятиях по искусственному интеллекту ребята знакомятся с работой нейросетей и учатся использовать их в образовательных целях. Модули по предметным дисциплинами дают возможность закрепить теоретические знания на практике. К примеру, когда ребята знакомятся с основами аэродинамики, у них не всегда есть четкое представление, как это работает в жизни. И уже в  ходе наглядной демонстрации, экспериментов и работы с оборудованием ребята закрепляют знания в естественнонаучных и технических дисциплинах, что сказывается не только на усвоении материала, но и на общем интересе ребенка к миру науки и техники, – пояснил директор Образовательного центра «Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Также директор регионального центра отметил, что одним немаловажным проектом  «Мобильный Персей» являются встречи с педагогами, родителями, руководителями школ и «Точек роста», на которых обсуждаются приоритетные направления сотрудничества Образовательного центра «Персей» и образовательных учреждений.
			</p>
			<p class="NewsPage-sub-title">
				В рамках путешествия «Мобильного Персея» такое мероприятие прошло и для директоров и заместителей директоров школ города Братска и Нижнеудинска.
			</p>
			<p class="NewsPage-sub-title">
				В ходе встречи участники узнали о перспективах сотрудничества и поддержки школьников при участии в конкурсах проектах на региональном, федеральном и международном уровнях, которые курирует «Персей», а также о повышении квалификации учителей на базе Образовательных центров «Сириус» и «Персей» и о методическом сопровождении педагогов в работе с одаренными детьми.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, проект «Мобильный Персей» реализуется в регионе с 2021 года. За это время специалисты Образовательного центра посетили более 150 школ Приангарья. В ближайшее время мобильная лаборатория посетит город Байкальск.
			</p>
			<p class="NewsPage-sub-title">
				<b>Сми о работе мобильной лаборатории «Персея»:</b>
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://www.youtube.com/watch?v=uasj-Kfm4Ds">Телерадиокомпания Братска</a>
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://vk.com/perseusirk?z=video-195976317_456239201%2F402d1ced861f07fbbe%2Fpl_wall_-195976317">Братская студия телевидения</a>
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://vk.com/video-206853457_456240524">Телеканал ТВ-12 Нижнеудинск</a>
			</p>

		</div>
	</div>
</template>
