<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage54.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Победителем XXIV регионального конкурса «Лучший ученик года - 2024» стала десятиклассница из Иркутска
			</h2>
			<p class="NewsCard-date">
				08.04.2024
			</p>
		</div>
	</div>
</template>