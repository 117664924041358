<template>
	<div class="OlympicPage _cover">
		<h2 class="OlympicPage-title _cover-title">
			Олимпик
		</h2>

		<h2 class="Olimpik-title _cover-title">
			Региональная олимпиада для обучающихся начальных классов
		</h2>
		<h2 class="Olimpik-title _cover-title">
			Олимпик
		</h2>
		<div class="Olimpik-img-box">
			<img loading="lazy" class="Olimpik-img" src="../assets/img/svg/Олимпик.webp" alt="Всероссийская олимпиада школьников">
		</div>
		<h2 class="Olimpik-title _cover-title">
			Регистрация участников: с 15 октября по 16 ноября.
		</h2>
		<h2 class="Olimpik-title _cover-title">
			График олимпиады:
		</h2>
		<h3 class="Olimpik-title _cover-title">
			21 ноября 2023 г. – «Математика»
		</h3>
		<h3 class="Olimpik-title _cover-title">
			23 ноября 2023 г. – «Русский язык»
		</h3>
		<h3 class="Olimpik-title _cover-title">
			28 ноября 2023 г. – «Окружающий мир»
		</h3>
		<a class="Olimpik-link temp-btn" href="https://eor.vserosperseusirk.ru/">
			Регистрация на сайте проведения олимпиады "Олимпик"
		</a>
		<h2 class="Olimpik-title _cover-title">
			Уважаемые родители и участники! Пожалуйста, внимательно ознакомьтесь с разделом "Регистрация и участие в
			олимпиаде" внизу страницы.
		</h2>
		<hr>

		<ul class="AllRussian-list">
			<li class="AllRussian-items">
				<a class="AllRussian-items-link" href="https://irkobl.ru/sites/minobr/">
					<p class="AllRussian-items-dist">
						Учредитель Олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/svg/logo.webp" alt="Учредитель Олимпиады">
					<p class="AllRussian-items-dist">
						Министерство образования Иркутской области
					</p>
				</a>
				<a class="AllRussian-items-link" @click="$router.push({ name: 'Main' })">
					<p class="AllRussian-items-dist">
						Оператор Олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/Logo/logo.webp" alt="Оператор Олимпиады">
					<p class="AllRussian-items-dist">
						Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр
						Персей"
					</p>
				</a>
			</li>
		</ul>
		<hr>

		<p class="Olimpik-dist">
			<b>Возраст обучающихся:</b> к участию приглашаются школьники 3 и 4 классов начальной школы.<br>
			<b>Номинации:</b> русский язык, математика, окружающий мир.<br>
			<b>Формат проведения:</b> дистанционный.<br>
		</p>
		<div class="Olimpik-dist-img-box">
			<img loading="lazy" class="Olimpik-dist-img" src="../assets/img/jpg/777-min.webp" alt="Листовка">
		</div>
		<h2 class="Olimpik-title _cover-title">
			Документы
		</h2>
		<a class="Olimpik-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA-1.pdf"
			target="_blank">
			Письмо О проведении олимпиады Олимпик
		</a><br>
		<a class="Olimpik-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA.pdf"
			target="_blank">
			Положение Олимпик
		</a><br>
		<h2 class="Olimpik-title _cover-title">
			Цели олимпиады
		</h2>
		<ul class="Olimpik-list">
			<li class="Olimpik-items">
				Развитие познавательной активности младших школьников, их мотивация к глубокому изучению общеобразовательных
				предметов («Математика», «Русский язык», «Окружающий мир») и истории Иркутской области.
			</li>
			<li class="Olimpik-items">
				Выявление и поддержка одаренных и высокомотивированных обучающихся начальных классов.
			</li>
			<li class="Olimpik-items">
				Подготовка обучающихся начальных классов к участию в региональных, всероссийских и международных олимпиадах и
				конкурсах средней и старшей ступени обучения.
			</li>
		</ul>
		<h2 class="Olimpik-title _cover-title">
			Регистрация и участие в олимпиаде
		</h2>
		<ul class="Olimpik-list">
			<li class="Olimpik-items">
				На сайте Олимпиады: <a class="Olimpik-doc" href="https://eor.vserosperseusirk.ru/">eor.vserosperseusirk.ru.</a>
				После регистрации заполните раздел
				«Личные данные».
			</li>
			<li class="Olimpik-items">
				В системе «Навигатор»: <a class="Olimpik-doc"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/">р38.навигатор.дети.</a>
			</li>
			<li class="Olimpik-items">
				<a class="Olimpik-doc"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1290/?date=2023-10-13">Русский язык</a>
			</li>
			<li class="Olimpik-items">
				<a class="Olimpik-doc"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1291/?date=2023-10-13">Окружающий мир</a>
			</li>
			<li class="Olimpik-items">
				<a class="Olimpik-doc"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1292/?date=2023-10-13">Математика</a>
			</li>
		</ul>
		<div class="Olimpik-dist-img-box2">
			<img loading="lazy" class="Olimpik-dist-img2" src="../assets/img/svg/памятка-навигатор-ОЦ-ПЕРСЕЙ.webp" alt="Брошюра">
		</div>
		<h2 class="Olimpik-title _cover-title">
			Как будет проходить олимпиада?
		</h2>
		<p class="AllRussian-items-dist">
			Олимпиадные задания будут доступны в течение суток.<br>
			На выполнение олимпиадного задания дается 60 минут.<br>
			Счет времени начинается с момента открытия тестов.<br>
			По истечении 60 минут независимо от результата, тест будет закрыт.<br>
			Желаем успехов!<br>
		</p>

		<h2 class="Olimpik-title _cover-title">
			Итоги
		</h2>
		<a class="Olimpik-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA.pdf"
			target="_blank">
			Об утверждении итогов Олимпик
		</a><br>
		<a class="Olimpik-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-1-.-%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf"
			target="_blank">
			приложение 1 . Математика
		</a><br>
		<a class="Olimpik-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-2.-%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
			target="_blank">
			приложение 2. Русский язык
		</a><br>
		<a class="Olimpik-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-3.-%D0%9E%D0%BA%D1%80%D1%83%D0%B6%D0%B0%D1%8E%D1%89%D0%B8%D0%B9-%D0%BC%D0%B8%D1%80.pdf"
			target="_blank">
			приложение 3. Окружающий мир
		</a><br>
	</div>
</template>