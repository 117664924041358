<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			112.07.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» впервые прошла программа повышения квалификации для педагогов в области музыкального образования
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage72.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» состоялись курсы повышения квалификации для педагогов, посвященные
				особенностям работы с одаренными детьми в области инструментального исполнительства для струнно-смычковой группы
				оркестра. Курсы проходили с 5 по 12 июля. Участие в них приняли преподаватели музыкальных школ, школ искусств и
				специализированных учебных заведений из Иркутска, Ангарска, Усолье-Сибирское, Тайшет и с. Хомутова.
			</p>
			<p class="NewsPage-sub-title">
				Цель курсов заключалась в ознакомлении педагогов с новейшими методиками и практиками работы с талантливыми
				детьми, обучающимися игре на струнно-смычковых инструментах. Обучала преподавателей Татьяна Акимова, заслуженный
				работник культуры и искусства Иркутской области, заведующая предметно-цикловой комиссии «Оркестровые струнные
				инструменты» Иркутского областного музыкального колледжа им.Ф.Шопена, художественный руководитель и дирижер
				струнного и симфонического оркестров колледжа.
			</p>
			<p class="NewsPage-sub-title">
				Само мероприятие было поделено на два модуля: В ходе теоретической части участники обсуждали вопросы
				индивидуализации обучения, создания благоприятных условий для творческого развития детей, а также внедрению
				современных технологий в учебный процесс. Практическая часть программы продолжилась в кампусе Образовательного
				центра, где преподаватели уже наблюдали за работой Татьяны Акимовой на занятиях с участниками профильной смены
				«Оркестровое струнно-смычковое исполнительство».
			</p>
			<p class="NewsPage-sub-title">
				Итогом программы стал круглый стол, на котором участники обсудили вопросы по работе с одаренными детьми и
				обменялись мнениями по внедрению новых методик в образовательный процесс.
			</p>
			<p class="NewsPage-sub-title">
				— Эти курсы стали платформой для обмена опытом и знаниями, что несомненно, способствует профессиональному росту
				каждого участника. Обучение одаренных детей требует особого подхода и понимания их уникальных потребностей, и мы
				стремимся предоставлять педагогам все необходимые инструменты для успешной реализации этой задачи, – отметил
				Алексей Шестаков, директор Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				В завершение курсов педагоги получили удостоверения о повышении квалификации, а также ценные методические
				материалы, которые помогут им в дальнейшей работе с одаренными детьми. Образовательный центр «Персей» планирует
				продолжить проведение подобных мероприятий, направленных на развитие профессиональных компетенций педагогов и
				поддержку талантливых детей региона.
			</p>

		</div>
	</div>
</template>
