<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			«Всероссийская олимпиада школьников» 2021-2022г.
		</h2>
		<hr>

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Организатор заключительного этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Министерство образования и науки Российской Федерации
			</li>
			<p class="EventsPage-text">
				Организатор регионального этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор регионального этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Нормативно-правовые документы Министерства просвещения Российской Федерации
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%9C%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%B0-%D0%9F%D1%80%D0%BE%D1%81%D0%B2%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F-%E2%84%96608-%D0%BE%D1%82-26.08.21-%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D1%81%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B0-%D0%A6%D0%B5%D0%BD%D1%82%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D1%80%D0%B3%D0%BA%D0%BE%D0%BC%D0%B8%D1%82%D0%B5%D1%82%D0%B0.pdf" target="_blank">
			Приказ №608 от 26.08.21 Об утверждении состава Центрального оргкомитета Министерства Просвещения
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96678-%D0%BE%D1%82-27.11.20-%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D1%81%D0%9E%D0%A8.pdf" target="_blank">
			Приказ №678 от 27.11.20 Об утверждении Порядка проведения ВсОШ
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Всероссийская олимпиада школьников — крупнейшее интеллектуальное соревнование, в котором ежегодно принимают участие более 6 млн школьников.
		</p>
		<p class="EventsPage-text">
			Олимпиада проводится по общеобразовательным предметам: математика, русский язык, иностранный язык (английский, немецкий, французский, испанский, китайский), информатика, физика, химия, биология, экология, география, астрономия, литература, история, обществознание, экономика, право, искусство, физическая культура, технология, основы безопасности жизнедеятельности.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			ШКОЛЬНЫЙ ЭТАП (сентябрь-октябрь)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 5-11 классов (по всем предметам), для 4 класса (по предметам математика и русский язык);
			</li>
			<li class="JuniorProfi-items">
				к участию приглашаются все желающие обучающиеся;
			</li>
			<li class="JuniorProfi-items">
				формат проведения: по предметам физика, биология, химия, астрономия, математика и информатика – дистанционно, на платформе Сириус.Курсы.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			МУНИЦИПАЛЬНЫЙ ЭТАП (ноябрь-декабрь)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 7-11 классов;
			</li>
			<li class="JuniorProfi-items">
				приглашаются:
			</li>
			<li class="JuniorProfi-items">
				— участники школьного этапа текущего учебного года, набравшие необходимое для участия количество баллов, установленное министерством образования Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				— победители и призеры муниципального этапа олимпиады предыдущего учебного года, продолжающие освоение основных образовательных программ основного общего и среднего общего образования.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			РЕГИОНАЛЬНЫЙ ЭТАП (январь-февраль)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 9-11 классов;
			</li>
			<li class="JuniorProfi-items">
				приглашаются:
			</li>
			<li class="JuniorProfi-items">
				— участники муниципального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество баллов, установленное министерством образования Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				— победители и призеры регионального этапа олимпиады предыдущего учебного года, продолжающие освоение основных образовательных программ основного общего и среднего общего образования.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			ЗАКЛЮЧИТЕЛЬНЫЙ ЭТАП (март-апрель)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 9-11 классов;
			</li>
			<li class="JuniorProfi-items">
				приглашаются:
			</li>
			<li class="JuniorProfi-items">
				— участники регионального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество баллов, установленное Министерством Просвещения Российской Федерации;
			</li>
			<li class="JuniorProfi-items">
				— победители и призеры заключительного олимпиады предыдущего учебного года, продолжающие освоение основных образовательных программ начального общего, основного общего и среднего общего образования.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Муниципальный этап всероссийской олимпиады школьников Иркутской области 2021-2022
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/11/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BC%D0%B8%D0%BD.-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B9-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%E2%84%961886-%D0%BC%D1%80-%D0%BE%D1%82-9.11.21-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0-%D0%BC%D1%83%D0%BD.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0.pdf" target="_blank">
			Распоряжение мин. образования Иркутской области №1886-мр от 9.11.21 (изменение графика мун. этапа)
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/10/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8-%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B5-%D0%9C%D0%AD-%D0%BC%D0%B8%D0%BD.%D0%BE%D0%B1%D1%80-%D0%98%D1%80%D0%BA.%D0%BE%D0%B1%D0%BB.pdf" target="_blank">
			Распоряжение о проведении и подготовке МЭ мин.обр Ирк.обл
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Школьный этап всероссийской олимпиады школьников Иркутской области 2021-2022
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/11/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%81%D0%BE%D1%82%D1%80%D1%83%D0%B4%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B5-%D0%B2-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D0%A1%D0%9E%D0%A8-2021.pdf" target="_blank">
			Соглашение о сотрудничестве в области проведения ВСОШ 2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%BC%D0%B8%D0%BD.-%D0%BE%D0%B1%D1%80.-%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B9-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D1%88%D0%BA.-%D0%B8-%D0%BC%D1%83%D0%BD.-%D1%8D%D1%82%D0%B0%D0%BF%D0%BE%D0%B2.pdf" target="_blank">
			Письмо мин. обр. Иркутской области О проведении шк. и мун. этапов
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D1%8B-%D0%BE%D1%82%D0%B2%D0%B5%D1%82%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D0%BB%D0%B8%D1%86-%D0%BE%D1%82-%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B9-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8.pdf" target="_blank">
			Контакты ответственных лиц от Иркутской области
		</a>
		<hr>

		<p class="EventsPage-text">
			Школьный этап Всероссийской олимпиады школьников по физике, биологии, химии, астрономии, математике и информатике на платформе Сириус.Курсы. (подробнее: https://siriusolymp.ru/)
		</p>
		<p class="EventsPage-text">
			Задания школьного этапа олимпиады 2020 года на платформе Сириус. Курсы.: sirius.online
		</p>
		<p class="EventsPage-text">
			Конференция «Организация школьного этапа ВсОШ на онлайн-платформе «Сириус.Курсы»: YOUTUBE
		</p>
		<p class="EventsPage-text">
			Пресс-конференция, посвященная старту школьного этапа Всероссийской олимпиады школьников: www.youtube.com Спикеры: Елена Шмелева, сопредседатель Центрального штаба ОНФ; Евгений Семченко, директор департамента государственной политики и управления в сфере общего образования.
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4-%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%A8%D0%B8%D0%9C%D0%AD-%D0%92%D1%81%D0%9E%D0%A8-2021-22.pdf" target="_blank">
			Метод рекомендации ШиМЭ ВсОШ 2021-22
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Консультационные вебинары для членов предметно-методических комиссий школьного и муниципального этапов:
		</p>
		<p class="EventsPage-text">
			https://cloud.mail.ru/public/A1Y2/MDTqss5Yk (русский язык, литература, экология, информатика)
		</p>
		<p class="EventsPage-text">
			https://cloud.mail.ru/public/Guh5/dy1ec894R (немецкий язык, испанский язык, французский язык) 
		</p>
		<p class="EventsPage-text">
			https://cloud.mail.ru/public/ifcF/2PpzgvZjH (английский язык ,китайский язык, обществознание, история, право)
		</p>
		<p class="EventsPage-text">
			https://cloud.mail.ru/public/Xb94/znui8jqyA (география, химия, биология, математика, физика)
		</p>
		<p class="EventsPage-text">
			https://cloud.mail.ru/public/YEVA/TAaV3jrzV (астрономия, итальянский язык, ОБЖ, технология, физическая культура)
		</p>
		<p class="EventsPage-text">
			https://siriusolymp.ru/school2021/4/physics (Разбор заданий школьного этапа по Физике)
		</p>
	</div>
</template>