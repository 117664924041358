<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage45.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Современная хореография. Итоги первой программы в 2024 году по направлению «Искусство»
			</h2>
			<p class="NewsCard-date">
				31.01.2024
			</p>
		</div>
	</div>
</template>