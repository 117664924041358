<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			24.10.2023
		</p>
		<h2 class="NewsPage-title">
			В «Персее» прошел Гала-концерт участников профильной смены «Оркестровое духовое исполнительство»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage19.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В кампусе Образовательного центра прошел отчетный концерт участников октябрьского творческого интенсива по духовому оркестровому исполнительству. Школьники представили насыщенную концертную программу, которая включала произведения Михаила Глинки, Константина Москаленко, Евгения Никитина, Жоржа Бен Джоя, Леонарда Коэна, Луи Армстронга, Андрея Петрова, Аркадия Островского, Геннадия Гладкова, Имы Сумак, Берта Кемпферта и других.
			</p>
			<p class="NewsPage-sub-title">
				Как отмечают преподаватели смены, в течение 10 дней 62 школьника готовились к Гала-концерту во время ежедневных индивидуальных и групповых занятий и репетиций. Интенсивный курс требовал от юных музыкантов полной отдачи. Именно такая нагрузка помогла оркестру довести произведения до совершенства и поразить слушателей своей игрой.
			</p>
			<p class="NewsPage-sub-title">
				– Очень интересное общение с юными, одаренными и талантливыми музыкантами. Я приятно удивлен количеством детей и качеством той работы, которая проводится здесь на интенсивах. Эта работа очень важна, так как большинство детей, которые приезжают на такие смены,  связывают свою жизнь с духовым исполнительством, становятся студентами наших музыкальных учебных заведений и в будущем реализуют себя как в нашем регионе, так и по всей стране. Поэтому такие программы помогают подготовить школьников к профессиональной музыкальной деятельности и дать им хорошую базу для будущей реализации, – Владислав Федоренко, преподаватель Иркутского областного музыкального колледжа им. Фредерика Шопена.
			</p>
			<p class="NewsPage-sub-title">
				С молодыми музыкантами работали Алексей Завьялов, дирижер эстрадно-джазового оркестра Иркутской областной филармонии; Алексей Плотников, военный дирижер; Александр Сорвин, артист оркестра ГУ МЧС; Преподаватели Иркутского областного музыкального колледжа им. Фредерика Шопена Андрей Пухтий и Владислав Федоренко. А также преподаватель духовых и ударных инструментов Иркутской областной Детской школы искусств Александр Петров. Преподаватель Детской школы искусств г. Байкальска Игорь Игнатенко и педагоги Школы-интерната музыкантских воспитанников г. Иркутска Анастасия Портяная и Семён Фролов.
			</p>
			<p class="NewsPage-sub-title">
				В образовательную программу были также включены мастер-классы по группам инструментов оркестра: «медные», «духовые» и «ударные». Особенностью Гала-концерта стало оркестровое дефиле, которое продемонстрировали участники смены.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, программа проходила с 13 по 23 октября 2023 года. Участие в ней приняли юные музыканты из г. Иркутска, г. Байкальска, г. Ангарска и г. Усолье-Сибирское.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage19(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage19(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage19(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage19(5).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
