<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			24.04.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/news/3641758/">
				В Иркутской области объявили победителей региональной межвузовской олимпиады «Золотой фонд Сибири»
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage56.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Приангарье подвели итоги региональной межвузовской олимпиады обучающихся «Золотой фонд Сибири». Победителями и призерами олимпиады стали более 140 старшеклассников и студентов профессиональных образовательных организаций региона.
			</p>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов сообщил, что мероприятие проводится в регионе ежегодно с 2017 года. Оператором олимпиады «Золотой фонд Сибири» выступает Региональный центр выявления и поддержки одаренных детей «Персей». Он создан для координации работы с талантливой молодежью в Приангарье в рамках федерального проекта «Успех каждого ребенка» президентского национального проекта «Образование».
			</p>
			<p class="NewsPage-sub-title">
				– Завершается седьмой сезон этого уникального проекта сетевого взаимодействия ведущих вузов Иркутской области. С каждым годом олимпиада привлекает все больше заинтересованных и мотивированных участников и выявляет наиболее одаренных ребят, которые нацелены на получение высшего образования в нашем регионе, – подчеркнул Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				В 2023-2024 учебном году олимпиада состоялась по более чем 30 различным направлениям, в том числе естественно-научному, техническому, физико-математическому, гуманитарному и другим. Всего в интеллектуальных соревнованиях приняли участие около 2 тыс. человек.
			</p>
			<p class="NewsPage-sub-title">
				Максим Парфенов напомнил, что учредителем олимпиады «Золотой фонд Сибири» является министерство образования Иркутской области, а соучредителями и организаторами – шесть вузов региона: Иркутский государственный университет, Иркутский национальный исследовательский технический университет, Байкальский государственный университет, Иркутский государственный университет путей сообщения, Иркутский государственный аграрный университет им. А.А. Ежевского, Иркутский государственный медицинский университет.
			</p>
			<p class="NewsPage-sub-title">
				По условиям олимпиады победителям, призерам и участникам заключительного этапа при приеме на обучение в вуз-организатор к результатам ЕГЭ начисляются баллы за индивидуальные достижения: победителям – 10 баллов, призерам за второе место – восемь баллов, за третье место – шесть баллов. За наличие сертификата участника заключительного этапа олимпиады можно получить три балла.
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://vk.com/album-195976317_301319343">
					Больше фото:
				</a>
			</p>

		</div>
	</div>
</template>
