<template>
	<div class="ContactsofOlympiad _cover">
		<h2 class="ContactsofOlympiad-title _cover-title">Контакты координаторов олимпиады в Иркутской области</h2>
		<a class="temp-btn" @click="$router.push({ name: 'AllRussian' })"> ВСОШ </a>
		<h3 class="ContactsofOlympiad-sub-title">Куратор Всероссийской олимпиады школьников в Иркутской области:</h3>
		<p class="ContactsofOlympiad-dist">Большакова Инга Михайловна, ведущий советник отдела общего и дошкольного образования управления общего и дополнительного образования министерства образования Иркутской области, тел. <a style="color: blue" href="tel:+73952201638">8 (3952) 20-16-38</a></p>
		<h3 class="ContactsofOlympiad-sub-title">Ответственные лица оператора Всероссийской олимпиады школьников в Иркутской области:</h3>
		<p class="ContactsofOlympiad-dist">
			Гавриленко Татьяна Георгиевна<br />
			Непомнящая Екатерина Сергеевна<br />
			тел. <a style="color: blue" href="tel:+73952546044">8 (3952) 54-60-44 </a>
		</p>
		<h2 class="ContactsofOlympiad-title _cover-title">Координаторы муниципальных образований Иркутской области</h2>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tbody>
					<tr>
						<td width="189">
							<p><strong>Район</strong></p>
						</td>
						<td width="312">
							<p><strong>Координатор</strong></p>
						</td>
						<td width="208">
							<p><strong>Контакты</strong></p>
						</td>
						<td width="236">
							<p><strong>Ссылка на страницу ВСОШ</strong></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Аларский район</p></td>
						<td width="312"><p>Исхакова Жанна Владимировна</p></td>
						<td width="208"><p>8 (39564) 37-128</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Балаганский район</p></td>
						<td width="312"><p>Иванова Татьяна Александровна</p></td>
						<td width="208"><p>8 (3954) 85-04-95</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Боханский район</p></td>
						<td width="312"><p>Афанасьева Наталья Леонидовна</p></td>
						<td width="208"><p>8 (3953) 82-54-72</p></td>
						<td width="236">
							<p><a href="http://xn----7sbcc2dedr3b.xn--p1ai/%d0%b2%d1%81%d0%b5%d1%80%d0%be%d1%81%d1%81%d0%b8%d0%b9%d1%81%d0%ba%d0%b0%d1%8f-%d0%be%d0%bb%d0%b8%d0%bc%d0%bf%d0%b8%d0%b0%d0%b4%d0%b0-%d1%88%d0%ba%d0%be%d0%bb%d1%8c%d0%bd%d0%b8%d0%ba%d0%be%d0%b2/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Братский район</p></td>
						<td width="312"><p>Большешапова Светлана Сергеевна</p></td>
						<td width="208"><p>8 (3953) 21-06-50</p></td>
						<td width="236">
							<p><a href="https://brroo.ru/%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D0%BC%D1%81%D1%8F">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Ангарск</p></td>
						<td width="312"><p>Куликова Наталья Викторовна</p></td>
						<td width="208"><p>8 (3955) 59-19-17</p></td>
						<td width="236">
							<p><a href="http://educoroang.ru/index/vserossijskaja_olimpiada_shkolnikov/0-589">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Бодайбо и район</p></td>
						<td width="312"><p>Малышевская Ирина Николаевна</p></td>
						<td width="208"><p>8 (39561) 52-310</p></td>
						<td width="236">
							<p><a href="http://rcobdb.uobodaibo.ru/index/vserossijskaja_olimpiada_shkolnikov/0-128">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Братск</p></td>
						<td width="312"><p>Фролова Анастасия Александровна</p></td>
						<td width="208"><p>8 (3953) 34-96-06</p></td>
						<td width="236">
							<p><a href="https://www.obrbratsk.ru/do/ooo/olymp/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Зима</p></td>
						<td width="312"><p>Антонюк Александра Олеговна</p></td>
						<td width="208"><p>8 (39554) 72-124</p></td>
						<td width="236">
							<p><a href="http://www.uozima.ru/index.php/2018-05-08-07-20-05/olimpiady">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td rowspan="2" width="189"><p>Город Иркутск</p></td>
						<td width="312"><p>Исмагилова Евгения Викторовна</p></td>
						<td width="208"><p>8 (3952) 34-37-05</p></td>
						<td width="236">
							<p><a href="https://mc.eduirk.ru/index.php?option=com_k2&amp;view=itemlist&amp;task=category&amp;id=1248:vserossijskaya-olimpiada-shkolnikov-vsosh&amp;Itemid=144">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="312"><p>Гусева Алена Владимировна</p></td>
						<td width="208"><p>8 (3952) 34-37-05</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Город Саянск</p></td>
						<td width="312"><p>Михалева Наталья Викторовна</p></td>
						<td width="208"><p>8 (39553) 56-692</p></td>
						<td width="236">
							<p><a href="http://uo-sayansk.ru/napravleniya-deyatelnosti/rabota-s-odarennymi-detmi/vserossijskaya-olimpiada-shkolnikov.html">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Свирск</p></td>
						<td width="312"><p>Черниговская Татьяна Анатольевна</p></td>
						<td width="208"><p>8 (39573) 22-136</p></td>
						<td width="236">
							<p><a href="https://www.svirskobraz.ru/vserossijskaya-olimpiada-shkolnikov/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Тулун</p></td>
						<td width="312"><p>Лыткина Анастасия Геннадьевна</p></td>
						<td width="208"><p>8 (39530) 40-502</p></td>
						<td width="236">
							<p><a href="http://mbumc.3dn.ru/index/vserossijskaja_olimpiada_shkolnikov/0-63">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Усолье-Сибирское</p></td>
						<td width="312"><p>Кисеева Юлия Александровна</p></td>
						<td width="208"><p>8 (39543) 63-478</p></td>
						<td width="236">
							<p><a href="http://mu-imc.ru/index.php/rabota-s-odarennymi-detmi/predmetnye-olimpiady-shkolnikov">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Усть-Илимск</p></td>
						<td width="312"><p>Козловская Анастасия Владимировна</p></td>
						<td width="208"><p>8 (39535) 65-404</p></td>
						<td width="236">
							<p><a href="http://uiedu.ru/%d1%80%d0%b0%d0%b7%d0%b2%d0%b8%d1%82%d0%b8%d0%b5-%d1%81%d0%bf%d0%be%d1%81%d0%be%d0%b1%d0%bd%d0%be%d1%81%d1%82%d0%b5%d0%b9-%d0%b8-%d1%82%d0%b0%d0%bb%d0%b0%d0%bd%d1%82%d0%be%d0%b2/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Город Черемхово</p></td>
						<td width="312"><p>Черных Людмила Александровна</p></td>
						<td width="208"><p>8 (39546) 51-464</p></td>
						<td width="236">
							<p><a href="https://cherobr.ru/index.php/otdel-informatsionno-metodicheskogo-soprovozhdeniya/615-vserossijskaya-olimpiada-shkolnikov">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Жигаловский район</p></td>
						<td width="312"><p>Зелинская Тамара Петровна</p></td>
						<td width="208"><p>8 (3955) 13-16-74</p></td>
						<td width="236">
							<p><a href="http://zhiguo.ucoz.ru/index/vserossijskaja_olimpiada_shkolnikov/0-106">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Заларинский район</p></td>
						<td width="312"><p>Архипов Александр Иннокентьевич.</p></td>
						<td width="208"><p>8 (39552) 22-141</p></td>
						<td width="236">
							<p><a href="http://komobrzal.ucoz.ru/index/osnovnoe_srednee_obshhee_obrazovanie/0-4">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Зиминский район</p></td>
						<td width="312"><p>Курбалова Инна Адамовна</p></td>
						<td width="208"><p>8 (39554) 3-17-58</p></td>
						<td width="236">
							<p><a href="http://komitetzrmo.ru/index20cro.html">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Иркутский район</p></td>
						<td width="312"><p>Полонина Елена Юрьевна</p></td>
						<td width="208"><p>8 (3952) 71-80-34</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Казачинско-Ленский район</p></td>
						<td width="312"><p>Лисичникова Евгения Валерьевна</p></td>
						<td width="208"><p>8 (39562) 32-985</p></td>
						<td width="236">
							<p><a href="http://rc-kazachinsk.ru/index/2022_2023/0-232">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Катангский район</p></td>
						<td width="312"><p>Щеглова Татьяна Геннадьевна</p></td>
						<td width="208"><p>8 (39560) 21-770</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Качугский район</p></td>
						<td width="312"><p>Гусева Ксения Николаевна</p></td>
						<td width="208"><p>8 (39540) 31-409</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Киренский район</p></td>
						<td width="312"><p>Полоскова Татьяна Анатольевна</p></td>
						<td width="208"><p>8 (39568) 43-594</p></td>
						<td width="236">
							<p><a href="http://киренск-обр.рф/odarennye-deti/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Куйтунский район</p></td>
						<td width="312"><p>Остроухова Татьяна Викторовна</p></td>
						<td width="208"><p>8 (3953652482</p></td>
						<td width="236">
							<p><a href="http://uo.edukuitun.ru/activity/education/child/olimpiada2022-2023/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Мамско-Чуйский район</p></td>
						<td width="312"><p>Черняева Ольга Сергеевна</p></td>
						<td width="208"><p>8 (39569) 21-651</p></td>
						<td width="236">
							<p><a href="https://edu-mchr.irkmo.ru/vserossiyskaya-olimpiada-shkolnikov-/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Нижнеилимский район</p></td>
						<td width="312"><p>Попович Марина Геннадьевна</p></td>
						<td width="208"><p>8 (39566) 32-537</p></td>
						<td width="236">
							<p><a href="http://do.nilimsk.ru/index.php?option=com_content&amp;view=article&amp;id=87&amp;Itemid=934">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Нижнеудинский район</p></td>
						<td width="312"><p>Старовойтова Оксана Викторовна</p></td>
						<td width="208"><p>8 (39557) 7-11-65</p></td>
						<td width="236">
							<p><a href="https://nruo.ru/vosh.html">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Нукутский район</p></td>
						<td width="312"><p>Булсунаева Любовь Андреевна</p></td>
						<td width="208"><p>8 (39549) 21-804</p></td>
						<td width="236">
							<p><a href="https://xn--m1abhv.xn--p1ai/sveden/vserossiyskaya-olimpiada/">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Ольхонский район</p></td>
						<td width="312"><p>Загулова Валентина Викторовна</p></td>
						<td width="208"><p>8 (39558) 52-544</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td rowspan="2" width="189"><p>Осинский район</p></td>
						<td width="312"><p>Хамаева Екатерина Владимировна</p></td>
						<td width="208"><p>8(39539) 31-192</p></td>
						<td width="236">
							<p><a href="https://uo.eduosa.ru/index.php/en/olimpiady">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="312"><p>Шоболова Александра Юрьевна</p></td>
						<td width="208"><p>8 (39539) 95-104</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Слюдянский район</p></td>
						<td width="312"><p>Мироновская Ирина Константиновна</p></td>
						<td width="208"><p>8 (39544) 51-438</p></td>
						<td width="236">
							<p><a href="https://ksp.edusluda.ru/index.php?option=com_content&amp;view=article&amp;id=273&amp;Itemid=507">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Тайшетский район</p></td>
						<td width="312"><p>Быргина Елена Олеговна</p></td>
						<td width="208"><p>8 (39563) 2-43-26</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Тулунский район</p></td>
						<td width="312"><p>Шипачева Аурика Николаевна</p></td>
						<td width="208"><p>8 (39530) 40-120</p></td>
						<td width="236">
							<p><a href="http://www.tulunrono.tulunr.ru/index.php/olimpiada">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Усольский район</p></td>
						<td width="312"><p>Андреева Наталья Борисовна</p></td>
						<td width="208"><p>8 (39543) 36-074</p></td>
						<td width="236">
							<p><a href="http://uoura.ru/index.php/obshchee-obrazovanie2019/2018-09-28-03-09-57">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Усть-Илимский район</p></td>
						<td width="312"><p>Янченко Галина Максимовна</p></td>
						<td width="208"><p>8 (395835) 77-152</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td rowspan="2" width="189"><p>Усть-Кутский район</p></td>
						<td width="312"><p>Усачева Валентина Гельдыевна</p></td>
						<td width="208"><p>8 (39565) 52-126</p></td>
						<td width="236">
							<p><a href="https://ustkut.uoedu.ru/site/section?id=29">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="312"><p>Парилов Сергей Викторович</p></td>
						<td width="208"><p>8 (39565) 52- 133</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Усть-Удинский район</p></td>
						<td width="312"><p>Развозжаева Елена Александровна</p></td>
						<td width="208"><p>8 (39545) 31-243</p></td>
						<td width="236">
							<p><a href="http://uo-u-uda.my1.ru/index/rabota_s_odarennymi_detmi/0-90">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Черемховский район</p></td>
						<td width="312"><p>Андреева Ирина Александровна</p></td>
						<td width="208"><p>8 (39546) 5-53-03</p></td>
						<td width="236">
							<p><a href="http://oo.cheredu.ru/index.php/tsentr-razvitiya-obrazovaniya/rabota-s-odarennymi-detmi/olimpiady">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="189"><p>Чунский район</p></td>
						<td width="312"><p>Шалак Анна Николаевна</p></td>
						<td width="208"><p>8 (39567) 2-17-87</p></td>
						<td width="236">
							<p><a href="http://chuna-rono.ru/otdel-obrazovaniya/deyatelnost/65-vserossijskaya-olimpiada-shkolnikov">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td rowspan="2" width="189"><p>Шелеховский район</p></td>
						<td width="312"><p>Калашник Татьяна Анатольевна</p></td>
						<td width="208"><p>8 (39550) 5-37-94</p></td>
						<td width="236">
							<p><a href="https://imoc.sheledu.ru/dlya-obuchayushchikhsya-i-vospitannikov/vserossijskaya-olimpiada">Ссылка</a></p>
						</td>
					</tr>
					<tr>
						<td width="312"><p>Пальвинская Елена Алексеевна</p></td>
						<td width="208"><p>8 (3952) 57-428</p></td>
						<td width="236"><p>Ссылка</p></td>
					</tr>
					<tr>
						<td width="189"><p>Эхирит-Булагатский район</p></td>
						<td width="312"><p>Абашеева Лира Николаевна</p></td>
						<td width="208"><p>8 (39541) 3-11-05</p></td>
						<td width="236">
							<p><a href="https://uo.ehirit38.ru/index.php/upravlenie-obrazovaniya/olimpiada/744-vserossijskaya-olimpiada-shkolnikov-2022-2023">Ссылка</a></p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
