<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Байкальские звезды «Абилимпикса»
		</h2>
		<p class="EventsPage-date">
			с 10 по 16 сентября 2024
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/25475-programma-baikalskie-zvezdy-abilimpiksa" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Байкальские звезды «Абилимпикса» для учащихся 5-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа ориентирована на создание мотивирующей образовательной среды, умение адаптироваться в различных
			ситуациях, эффективное взаимодействие со сверстниками и педагогами. Отличительной особенностью программы является
			то, что педагоги уделяют внимание не только интеллектуального потенциала обучающихся, но и формированию ключевых
			личностных навыков, к которым относятся – коммуникативные навыки, умение работать в команде.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо записаться на программу «Байкальские звезды «Абилимпикса» на ресурсе «Навигатор дополнительного
				образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>