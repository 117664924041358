<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			12.08.2024
		</p>
		<h2 class="NewsPage-title">
			Школьники Приангарья стали участниками межрегиональной образовательной программы по информационной безопасности
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage77.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Нижнем Новгороде стартовала ежегодная межрегиональная образовательная программа «НЕЙМАРК.ИТ» – Академия по
				информационной безопасности. В программе принимают участие 100 школьников из 26 регионов России, включая
				региональную сборную по программированию и искусственному интеллекту Иркутской области. В министерстве
				образования региона рассказали, что в ее состав вошли восемь школьников из Иркутска, Саянска и Нижнеудинска.
			</p>
			<p class="NewsPage-sub-title">
				Сформировал и подготовил сборную – Региональный центр выявления и поддержки одаренных детей «Персей». Также в
				новом учебном году центром будут сформированы региональные сборные по другим предметным областям.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали организаторы программы, участники ИТ-Академии по олимпиадному программированию улучшат свои
				знания в области программирования, получат опыт в прохождении контестов и повысят свои навыки. Академия включает
				практические и лекционные занятия по теории чисел, теории графов и структурам данных. Полученные знания
				школьники смогут также применить в новом олимпиадном сезоне.
			</p>
			<p class="NewsPage-sub-title">
				– Образовательные программы, такие как ИТ-Академия по олимпиадному программированию, являются важным шагом к
				развитию навыков и знаний у молодых талантов региона. Мы уверены, что ребята не только углубят свои знания в
				области программирования, но и разовьют важные личные качества, такие как командная работа и критическое
				мышление. Именно такие инициативы помогают формировать новое поколение IT-специалистов, способных решать сложные
				задачи и добиваться высоких результатов в олимпиадном движении, – поделился директор Образовательного центра
				«Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Обучение будут проводить эксперты индустрии и опытные педагоги. В программе предусмотрены мастер-классы и лекции
				от приглашенных лекторов ведущих ИТ-компании страны. Также участников Академии ожидает насыщенная внеучебная
				жизнь – спортивные мероприятия, квизы, выездные активности на свежем воздухе, командные игры и многое другое.
			</p>
			<p class="NewsPage-sub-title">
				Программа пройдет с 9 по 19 августа и завершится соревнованиями, на которых участники смогут применить
				полученные знания и навыки на практике. Они будут соревноваться в различных категориях, демонстрируя свои
				достижения в области информационной безопасности и программирования.
			</p>

		</div>
	</div>
</template>
