<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дополнительная профессиональная программа повышения квалификации
		</h3>
		<h2 class="EventsPage-title">
			«Управление деятельностью организаций отдыха детей и их оздоровления»
		</h2>
		<hr>

		<p class="EventsPage-text">
			Целью курса является совершенствование профессиональных компетенций в области управления деятельностью организаций
			отдыха детей и их оздоровления.
		</p>
		<p class="EventsPage-text">
			Объем программы: 72 часа
		</p>
		<p class="EventsPage-text">
			Категория слушателей: специалисты управлений образования, курирующие вопросы организации отдыха детей и их
			оздоровления, руководители организаций отдыха детей и их оздоровления
		</p>
		<p class="EventsPage-text">
			Аннотация: в рамках изучения программы повышения квалификации, слушатели изучат модели и формы организации отдыха
			детей и их оздоровления, особенности работы с детьми различных возрастных категорий во временных детских
			коллективах, а также с психолого-педагогическими особенностями организации воспитательного процесса в организации
			отдыха детей и их оздоровления Ознакомятся с педагогическим стилем и педагогической этикой специалистов, занятых в
			работе организации отдыха детей и из оздоровления
		</p>
		<p class="EventsPage-text">
			По итогам изучения курса слушатели должны представить разработку локального нормативного акта на выбор
		</p>
		<p class="EventsPage-text">
			Слушатели, успешно завершившие курсовую подготовку, получают удостоверение установленного образца о повышении
			квалификации.
		</p>
		<p class="EventsPage-text">
			<b>Обучение проходит в очно-заочной форме с использованием дистанционных образовательных ресурсов на платформе
				Мудл (Moodle)
			</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских инициатив
				образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Соболева Елена Владимировна, заведующий учебно-методическим отделом образовательного центра "Персей".
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Соловьева Дарья Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: d.soloveva@perseusirk.ru
			</li>
		</ul>

	</div>
</template>