<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Подготовка к конкурсу научно-технологических проектов «Большие вызовы»
		</h2>
		<p class="EventsPage-date">
			с 29 по 12 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=135" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24481-programma-podgotovka-k-konkursu-bolshie-vyzovy" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.sochisirius.ru/?ysclid=lrrj4z60gy130755196" target="_blank">
			Сириус
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Подготовка к конкурсу научно-технологических проектов «Большие вызовы» с 29 января 2024 года по 12 февраля 2024 года для учащихся 8-11-х классов общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Целью изучения программы является подготовка учащихся образовательных организаций Иркутской области к участию во Всероссийском конкурсе научных проектов «Большие вызовы».
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для прохождения обучения необходимо:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться до 26 января 2024 года на сайте Всероссийского конкурса научно-технологических проектов «Большие вызовы» и загрузить проектные работы;
			</li>
			<li class="JuniorProfi-items">
				Подать заявку до 29 января 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 29 января 2024 года на платформе дистанционного обучения Образовательного центра «Персей» системе Moodle.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Астафьев Сергей Александрович, д.э.н., заведующий кафедрой «Экономики строительства и управления недвижимостью» ФГБОУ ВО «БГУ», директор Проектного офиса БГУ, эксперт региональной проектной группы АСИ по направлению «Городское развитие» (Иркутская область).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты:
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/F_67eoXAAcJfcw" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>