<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционная викторина
		</h3>
		<h2 class="EventsPage-title">
			«По страницам Великой Победы»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			С 5 мая по 15 мая 2023 года
		</h4>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/activity/1214" target="_blank">
			Регистрация
		</a>
		<hr />

		<h3 class="EventsPage-prof">
			О конкурсе
		</h3>
		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2023/05/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%92%D0%B8%D0%BA%D1%82%D0%BE%D1%80%D0%B8%D0%BD%D0%B0-%D0%9F%D0%BE-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0%D0%BC-%D0%92%D0%B5%D0%BB%D0%B8%D0%BA%D0%BE%D0%B9-%D0%9F%D0%BE%D0%B1%D0%B5%D0%B4%D1%8B.pdf" target="_blank">
			Положение_Викторина «По страницам Великой Победы»
		</a>
		<p class="EventsPage-text">
			Контактное лицо: Егорова Юлия Сергеевна (исполняющий обязанности заведующего отделом выявления и сопровождения одаренных детей).
		</p>
		<p class="EventsPage-text">
			Телефон: 89246361877
		</p>
		<p class="EventsPage-text">
			Эл. почта: yulcha_23@mail.ru
		</p>
		<hr />

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Дистанционная викторина «По страницам Великой Победы» (далее – Викторина) приурочена к празднованию 78-ой годовщины Победы нашей страны в Великой Отечественной войне.
			</li>
			<li class="JuniorProfi-items">
				Вопросы викторины включают в себя знания основных дат, событий, имен Героев, памятников, а также истории региона в годы войны.
			</li>
			<li class="JuniorProfi-items">
				Целью Викторины является привлечение внимания обучающихся общеобразовательных организаций Иркутской области к Великой Отечественной войне.
			</li>
			<li class="JuniorProfi-items">
				Викторина провидится в дистанционной форме в Электронной информационно-образовательной среде (далее – ЭИОС) Образовательного центра «Персей».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для участия в Викторине необходимо с 5 мая по 14 мая 2023 года:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				подать заявку на участие в мероприятии «Дистанционная викторина «По страницам Великой Победы» в АИС «Навигатор дополнительного образования детей Иркутской области» по ссылке https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1214
			</li>
			<li class="JuniorProfi-items">
				зарегистрироваться, заполнить анкету участника и ответить на вопросы Викторины в ЭИОС Образовательного центра «Персей» по ссылке http://moodle.center-prof38.ru/course/view.php?id=79
			</li>
			<li class="JuniorProfi-items">
				Подведение итогов и награждение участников будет осуществляться 15 мая 2023 года.
			</li>
		</ul>

	</div>
</template>
