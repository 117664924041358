<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Дзюдо»
		</h2>
		<p class="EventsPage-date">
			с 1 по 14 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24906-programma-dzyudo" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит профильную смену «Дзюдо» для обучающихся 5-11-х классов
			общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Дзюдо – японское национальное единоборство, основой которого являются броски, удержания, болевые приемы (только
			руками и только на руки) и удушающие (задерживающие дыхание) приемы.
		</p>

		<p class="EventsPage-text">
			Этот вид спорта интересен тем, что позволяет занимающимся наиболее эффективно использовать свою умственную и
			физическую энергию. Занятия этим видом единоборства направлены на физическое и духовное совершенствование личности
			на основе изучения техники, тактики и философии дзюдо. Абсолютная цель занятий дзюдо – улучшить себя, чтобы
			принести больше пользы окружающему миру.
		</p>

		<p class="EventsPage-text">
			Программа направлена на усовершенствование технико-тактических навыков спортсменов, подготовку к соревнованиям по
			дзюдо.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели профильной смены:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Романова Елена Юрьевна, тренер-преподаватель ОФСО «Иркутская областная федерации дзюдо»;
			</li>
			<li class="JuniorProfi-items">
				Парилов Иван Андреевич, тренер-преподаватель МКУ ДО «Спортивная школа «ЦРС».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Участникам, прошедших на профильную смену необходимо подать заявку на платформе Навигатор дополнительного
				образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/6w5uyUisxbbgkg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Капустина Екатерина Станиславна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: e.kapustina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>