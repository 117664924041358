<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			Региональная олимпиада для обучающихся начальных классов «Олимпик»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			1 ноября – Окружающий мир
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			3 ноября – Русский язык
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			7 ноября – Математика
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			9 ноября – История Иркутской области
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			Регистрация участников: с 10 по 28 октября 2022 года.
		</h4>

		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация на сайте проведения олимпиады "Олимпик"
		</a>
		<a class="EventsPage-btn temp-btn" href="http://р38.навигатор.дети/activity/656/?date=2022-10-18" target="_blank">
			Навигатор - Окружающий мир
		</a>
		<a class="EventsPage-btn temp-btn" href="http://р38.навигатор.дети/activity/658/?date=2022-10-18" target="_blank">
			Навигатор - Русский язык
		</a>
		<a class="EventsPage-btn temp-btn" href="http://р38.навигатор.дети/activity/660/?date=2022-10-18" target="_blank">
			Навигатор - История Иркутской области
		</a>
		<a class="EventsPage-btn temp-btn" href="http://р38.навигатор.дети/activity/659/?date=2022-10-18" target="_blank">
			Навигатор - Математика
		</a>
		<p class="JuniorProfi-sub-title">
			Уважаемые родители и участники! Пожалуйста, внимательно ознакомьтесь с разделом "Регистрация и участие в олимпиаде" внизу страницы.
		</p>
		<hr />

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Учредитель Конкурса
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор конкурса
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Возраст обучающихся: к участию приглашаются школьники 3 и 4 классов начальной школы.
		</p>
		<p class="EventsPage-text">
			Регистрация участников: с 10 по 28 октября 2022 года.
		</p>
		<p class="EventsPage-text">
			Формат проведения: дистанционный.
		</p>
		<hr>

		<h3 class="EventsPage-prof">
			Об олимпиаде
		</h3>
		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/07/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F.pdf" target="_blank">
			Приказ об утверждении Положения
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA-2022-2023.pdf" target="_blank">
			Положение олимпиады Олимпик 2022-2023
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Цели олимпиады
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Развитие познавательной активности младших школьников, их мотивация к глубокому изучению общеобразовательных предметов («Математика», «Русский язык», «Окружающий мир») и истории Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Выявление и поддержка одаренных и высокомотивированных обучающихся начальных классов.
			</li>
			<li class="JuniorProfi-items">
				Подготовка обучающихся начальных классов к участию в региональных, всероссийских и международных олимпиадах и конкурсах средней и старшей ступени обучения. 
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Регистрация и участие в олимпиаде
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сайт проведения олимпиады: https://konkurs.ric38.ru/.
			</li>
			<li class="JuniorProfi-items">
				После регистрации пользователя необходимо выбрать желаемую номинацию (или номинации) в списке конкурсов, и подать заявку.
			</li>
			<li class="JuniorProfi-items">
				Всем участникам также необходимо пройти регистрацию и подать заявку В Навигаторе дополнительного образования Иркутской области
			</li>
			<li class="JuniorProfi-items">
				<a class="EventsPage-link" href="http://р38.навигатор.дети/activity/656/?date=2022-10-18" target="_blank">
					Окружающий мир
				</a>
			</li>
			<li class="JuniorProfi-items">
				<a class="EventsPage-link" href="http://р38.навигатор.дети/activity/658/?date=2022-10-18" target="_blank">
					Русский язык
				</a>
			</li>
			<li class="JuniorProfi-items">
				<a class="EventsPage-link" href="http://р38.навигатор.дети/activity/659/?date=2022-10-18" target="_blank">
					Математика
				</a>
			</li>
			<li class="JuniorProfi-items">
				<a class="EventsPage-link" href="http://р38.навигатор.дети/activity/660/?date=2022-10-18" target="_blank">
					История Иркутской области
				</a>
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Как будет проходить олимпиада?
		</p>
		<p class="EventsPage-text">
			Олимпиадные задания будут доступны в течение суток.
		</p>
		<p class="EventsPage-text">
			На выполнение олимпиадного задания дается 60 минут.
		</p>
		<p class="EventsPage-text">
			Счет времени начинается с момента открытия тестов.
		</p>
		<p class="EventsPage-text">
			По истечении 60 минут независимо от результата, тест будет закрыт.
		</p>
		<p class="EventsPage-text">
			Желаем успехов!
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="http://perseusirk.ru/wp-content/uploads/2022/11/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA.pdf" target="_blank">
			Приказ Об итогах региональной олимпиады Олимпик
		</a>
	</div>
</template>
