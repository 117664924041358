<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			23.04.2024
		</p>
		<h2 class="NewsPage-title">
			Юные художники «Персея» стали лауреатами Международного конкурса
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage55.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Участники профильной смены «Академический рисунок», которая проходила в феврале текущего года, стали победителями IV Международного конкурса «Академический рисунок». Обучение и подготовка к конкурсу подходила под руководством Ирины Ширшовой, члена Иркутского регионального представительства Международного союза педагогов-художников.
			</p>
			<p class="NewsPage-sub-title">
				Конкурсные работы были выполнены в стиле графика.
			</p>
			<p class="NewsPage-sub-title">
				В число победителей и призеров вошли: Артур Зурначян, Алина Лалакина, Екатерина Васильева, Мария Хамова, Снежана Шевкунова, Илья Воложин и Виктория Гусева.
			</p>
			<p class="NewsPage-sub-title">
				Директор Образовательного центра «Персей» Алексей Шестаков отметил, что смены для юных художников – одни из наиболее востребованных в рамках направления «Искусство» в «Персее». Ребята после обучения поступают в престижные художественные училища и творческие вузы страны, становятся победителями и призерами конкурсов всероссийского и международного уровня.
			</p>
			<p class="NewsPage-sub-title">
				– Обучение под руководством сильных педагогов помогает школьникам региона достичь высоких результатов не только на наших образовательных программах, но и после выпуска. Победы на международных конкурсах тому подтверждение. Мы очень рады, что можем помочь таким талантливым и одаренным ребятам раскрыть свои потенциал еще больше, получить ценный опыт и знания, которые помогают им в дальнейшем творческом пути, – поделился Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				В июле этого года в кампусе Образовательного центра «Персей» пройдет профильная смена «Art-перформанс», на которой будут представлены работы юных художников в театрально-сценическом воплощении. Принять участие в программе смогут ребята, которые пройдут конкурсный отбор.
			</p>

		</div>
	</div>
</template>
