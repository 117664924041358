<template>
	<div class="OlympicPage _cover">
		<h2 class="OlympicPage-title _cover-title">
			Региональная олимпиада для обучающихся начальных классов «Олимпик»
		</h2>

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/svg/Олимпик.webp" alt="Логотип" />
		</div>

		<p class="JuniorProfi-sub-title">
			Цели олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				развитие познавательной активности младших школьников, их мотивация к глубокому изучению общеобразовательных
				предметов,
			</li>
			<li class="JuniorProfi-items">
				выявление и поддержка одаренных и высокомотивированных обучающихся начальных классов.
			</li>
			<li class="JuniorProfi-items">
				подготовка обучающихся начальных классов к участию в региональных, всероссийских и международных олимпиадах и
				конкурсах средней и старшей ступени обучения.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Возраст обучающихся:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				к участию приглашаются школьники 3 и 4 классов начальной школы.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Номинации:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				русский язык,
			</li>
			<li class="JuniorProfi-items">
				математика,
			</li>
			<li class="JuniorProfi-items">
				окружающий мир.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			По вопросам участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

		<p class="GoldenFundOfSiberia-sub-title">
			Уважаемые участники! Информация о проведении олимпиады в 2024 г. будет опубликована в сентябре 2024 г.
		</p>

	</div>

	<!-- Архив -->
	<ArchiveOlympic />

</template>

<script>
import ArchiveOlympic from '@/components/ArchiveOlympic.vue';

export default {
	components: {
		ArchiveOlympic,
	}
}

</script>