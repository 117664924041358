<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Игры звука»
		</h2>
		<p class="EventsPage-date">
			с 12 июля по 01 августа 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/21303-programma-igry-zvuka"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит еженедельные курсы по программе «Игры звука» для учащихся детских
			музыкальных школ и детских школ искусств Иркутской области в возрасте с 10 до 17 лет.
		</p>

		<p class="EventsPage-text">
			Программа имеет художественно-эстетическую направленность и предназначена создать у учащихся более глубокие
			представления и знания в области коллективного духового исполнительства.
		</p>

		<p class="EventsPage-text">
			Обучаясь по данной программе, учащиеся знакомятся с миром искусства на основе собственной творческой деятельности,
			посредством умения играть на инструменте, осознавая себя участником увлекательного процесса музыкального
			исполнительства.
		</p>

		<p class="EventsPage-text">
			Учебный курс построен на практических занятиях, теоретические знания формируются в процессе освоения
			исполнительских навыков.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Петров Александр Юрьевич, преподаватель ГОБУДО Иркутская областная ДШИ, руководитель Образцового духового
				оркестра
			</li>

		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>