<template>
	<div class="wrapper" id="app">
		<div v-if="admin">
			<Header />
			<router-view></router-view>
			<Footer />
		</div>
		<div v-else>
			<Header />
			<router-view></router-view>
			<Footer />
		</div>
	</div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
// import { mapActions } from 'vuex';
import { mapState } from 'vuex'
import axios from 'axios';

export default {
	name: 'App',
	components: {
		Header,
		Footer,
		// AddNewsForm
	},
	

	computed: {
		...mapState(['admin']),
	},

mounted() {
		axios.get('/api/data').then((response) => {
			this.data = response.data;
		}).catch((error) => {
			console.error('Ошибка получения данных: ' + error);
		});
	}
};
	

</script>