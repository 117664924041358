<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дополнительная профессиональная программа повышения квалификации
		</h3>
		<h2 class="EventsPage-title">
			«Современные концепции организации отдыха детей и их оздоровления»
		</h2>
		<hr>

		<p class="EventsPage-text">
			Целью курса является совершенствование профессиональных компетенций слушателей в сфере развития воспитательного
			процесса в организации отдыха детей и их оздоровления
		</p>
		<p class="EventsPage-text">
			Объем программы: 36 часов
		</p>
		<p class="EventsPage-text">
			Категория слушателей: руководители организаций отдыха детей и их оздоровления, воспитатели, педагоги организаторы,
			педагоги дополнительного образования
		</p>
		<p class="EventsPage-text">
			Аннотация: участники курсовой подготовки познакомятся с государственной политикой в области воспитания, изучат
			современные методы и средства воспитания и ознакомятся со спецификой организации воспитательного процесса в
			организации отдыха детей и их оздоровления по типам лагеря, а также с планирование и проведение комплексных
			тематических мероприятий в зависимости от типа лагеря
		</p>
		<p class="EventsPage-text">
			По итогам изучения курса слушатели должны будут пройти тест из 30 вопросов (время ограничено)
		</p>
		<p class="EventsPage-text">
			Слушатели, успешно завершившие курсовую подготовку, получают удостоверение установленного образца о повышении
			квалификации.
		</p>
		<p class="EventsPage-text">
			<b>Обучение проходит в очно-заочной форме с использованием дистанционных образовательных ресурсов на платформе
				Мудл (Moodle)
			</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских инициатив
				образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Сорока Сергей Сергеевич, заведующий отделом выявления и сопровождения одаренных детей, образовательного центра
				«Персей»
			</li>
			<li class="JuniorProfi-items">
				Грушевская Ольга Сергеевна, педагог-психолог образовательного центра «Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Соловьева Дарья Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: d.soloveva@perseusirk.ru
			</li>
		</ul>

	</div>
</template>