<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Весенняя интеллектуальная школа»
		</h3>
		<p class="EventsCard-sub-title">
			с 27 февраля по 11 марта 2024 года
		</p>
	</div>
</template>