<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">Олимпиада</h3>
		<h2 class="EventsPage-title">«Высшая проба»</h2>
		<p class="EventsPage-date">10 – 19 февраля 2023 года</p>
		<p class="EventsPage-date">ВАЖНО!!!</p>
		<p class="EventsPage-date">При себе иметь: паспорт или свидетельство о рождении, ручку синюю или черную, распечатанный титульный лист</p>
		<hr />

		<p class="JuniorProfi-sub-title">ЧТО ТАКОЕ «ВЫСШАЯ ПРОБА?»</p>
		<p class="EventsPage-text">История олимпиады школьников «Высшая проба» чуть меньше, чем история самой Вышки. Свои двери для проведения олимпиадных состязаний школьников университет впервые открыл в 1998 году. С тех пор олимпиада постоянно развивалась, меняла названия, увеличивала число профилей, прирастала партнерами и числом участников, сохраняя неизменно принципы доступности, открытости, честности, поддержки и внимательного отношения к участникам состязаний.</p>
		<p class="EventsPage-text">С 2010 года олимпиада стала проводиться в тесном взаимодействии с ведущими российскими университетами. В 2012 году олимпиада получила свое настоящее имя — «Высшая проба». К 2019 году олимпиада переросла межрегиональный масштаб и стала всероссийской.</p>
		<p class="EventsPage-text">Сегодня олимпиада «Высшая проба» — крупнейшее интеллектуальное состязание школьников, которое ежегодно собирает десятки тысяч школьников проверить свои силы и знания.</p>
		<p class="EventsPage-text">Среди ее особенностей — большой перечень профилей. Это не только дисциплины из школьной программы, но и финансовая грамотность, восточные языки, дизайн и другие профили для школьников, интересы которых уже сформировались и выходят за рамки школьной программы.</p>
		<p class="EventsPage-text">Другая особенность — это широкий географический охват олимпиады. Принять участие в олимпиаде могут как школьники из любого региона России, так и школьники из ближнего и дальнего зарубежья.</p>
		<p class="EventsPage-text">Олимпиада входит в Перечень олимпиад школьников. Призовые места в олимпиаде дают право на льготы при поступлении в вузы, в том числе поступление без вступительных испытаний.</p>
		<p class="EventsPage-text">Олимпиада «Высшая проба» – это способ поддержать талантливых ребят, помочь им определиться с будущей профессией и продолжить обучение в ведущем вузе страны.</p>
		<hr />

		<p class="JuniorProfi-sub-title">Преференции для дипломантов «Высшая проба» включена в Перечень олимпиад школьников и дает следующие льготы:</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">Дипломантам 11 класса поступление в вузы РФ без вступительных испытаний;</li>
			<li class="JuniorProfi-items">100 баллов за ЕГЭ по предмету, соответствующему профилю олимпиады.</li>
			<li class="JuniorProfi-items">Дипломантам 7-10 классов право принять участие сразу в заключительном этапе олимпиады в следующем году.</li>
		</ul>
		<hr />

		<p class="JuniorProfi-sub-title">Расписание:</p>
		<p class="EventsPage-text">Расписание проведения второго (заключительного) этапа Всероссийской олимпиады школьников «Высшая проба» 2022/2023 учебного года (по часовым поясам Иркутска, Якутска, Хабаровска и Владивостока)</p>
		<table>
			<tbody>
				<tr>
					<td width="144">
						<p>&nbsp;</p>
						<p>День проведения</p>
					</td>
					<td width="161"><p>Профиль олимпиады</p></td>
					<td width="189">
						<p>&nbsp;</p>
						<p>Продолжительность состязания</p>
					</td>
					<td width="132">
						<p>&nbsp;</p>
						<p>MSK +5</p>
					</td>
					<td width="160">
						<p>&nbsp;</p>
						<p>Адрес</p>
					</td>
					<td width="132">
						<p>&nbsp;</p>
						<p>Аудитория</p>
					</td>
				</tr>
				<tr>
					<td rowspan="3" width="144">
						<p>&nbsp;</p>
						<p>10.02.2023</p>
						<p>пятница</p>
					</td>
					<td width="161"><p>География</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td width="161"><p>Физика</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td width="161"><p>Социология</p></td>
					<td width="189"><p>150 мин</p></td>
					<td width="132"><p>19:00 – 21:30</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td rowspan="6" width="144">
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>11.02.2023</p>
						<p>суббота</p>
					</td>
					<td width="161"><p>Иностранные языки</p></td>
					<td width="189"><p>9, 10, 11 классы –120 мин</p></td>
					<td width="132"><p>15:00 – 17:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 130</p></td>
				</tr>
				<tr>
					<td width="161"><p>Иностранные языки</p></td>
					<td width="189"><p>&nbsp;7,8 классы –120 мин</p></td>
					<td width="132"><p>18:30 – 20:30</p></td>
					<td width="160">
						<p><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp;</p>
					</td>
					<td width="132">
						<p><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>&nbsp;</p>
					</td>
				</tr>
				<tr>
					<td width="161"><p>Инженерные науки</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160">
						<p>Угольный проезд</p>
						<p>68/1</p>
						<p>&nbsp;</p>
					</td>
					<td width="132"><p>&nbsp;№ 4</p></td>
				</tr>
				<tr>
					<td width="161"><p>Биология</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>18:00 – 21:00</p></td>
					<td width="160">
						<p>Ленина 8</p>
						<p>&nbsp;</p>
					</td>
					<td width="132"><p>&nbsp; &nbsp;&nbsp;Д 153</p></td>
				</tr>
				<tr>
					<td width="161"><p>ИМЦ</p></td>
					<td width="189"><p>&nbsp;9-10 классы – 90 мин</p></td>
					<td width="132"><p>18:30 – 20:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>&nbsp; &nbsp;&nbsp;Д 151</p></td>
				</tr>
				<tr>
					<td width="161"><p>ИМЦ</p></td>
					<td width="189"><p>&nbsp;11 класс – 120 мин</p></td>
					<td width="132"><p>18:30 – 20:30</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>&nbsp; &nbsp;&nbsp;Д 151</p></td>
				</tr>
				<tr>
					<td rowspan="2" width="144">
						<p>12.02.2023</p>
						<p>воскресенье</p>
					</td>
					<td width="161"><p>Русский язык</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>15:00 – 18:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 130</p></td>
				</tr>
				<tr>
					<td width="161"><p>Философия</p></td>
					<td width="189"><p>150 мин</p></td>
					<td width="132"><p>18:30 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td rowspan="3" width="144">
						<p>&nbsp;</p>
						<p>13.02.2023</p>
						<p>понедельник</p>
					</td>
					<td width="161"><p>Журналистика</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td width="161"><p>Химия</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td width="161"><p>Востоковедение</p></td>
					<td width="189"><p>120 мин</p></td>
					<td width="132"><p>19:00 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 151</p></td>
				</tr>
				<tr>
					<td rowspan="2" width="144">
						<p>14.02.2023</p>
						<p>вторник</p>
					</td>
					<td width="161"><p>Филология</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 151</p></td>
				</tr>
				<tr>
					<td width="161"><p>Право</p></td>
					<td width="189"><p>120 мин</p></td>
					<td width="132"><p>19:00 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td rowspan="3" width="144">
						<p>&nbsp;</p>
						<p>15.02.2023</p>
						<p>среда</p>
					</td>
					<td width="161">
						<p>Финансовая</p>
						<p>грамотность</p>
					</td>
					<td width="189"><p>120 мин</p></td>
					<td width="132"><p>15:00 – 17:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td width="161"><p>Восточные языки</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>15:00 – 18:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 151</p></td>
				</tr>
				<tr>
					<td width="161"><p>Политология</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>18:00 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td rowspan="2" width="144">
						<p>16.02.2023</p>
						<p>четверг</p>
					</td>
					<td width="161"><p>Экономика</p></td>
					<td width="189"><p>200 мин</p></td>
					<td width="132"><p>15:00 – 18:20</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 224 г</p></td>
				</tr>
				<tr>
					<td width="161"><p>Культурология</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>18:00 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 151</p></td>
				</tr>
				<tr>
					<td rowspan="3" width="144">
						<p>&nbsp;</p>
						<p>17.02.2023</p>
						<p>пятница</p>
					</td>
					<td width="161"><p>Информатика</p></td>
					<td width="189"><p>240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160">
						<p>Угольный проезд</p>
						<p>68/1</p>
					</td>
					<td width="132"><p>4</p></td>
				</tr>
				<tr>
					<td width="161"><p>История</p></td>
					<td width="189"><p>120 мин</p></td>
					<td width="132"><p>15:00 – 17:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>А 49</p></td>
				</tr>
				<tr>
					<td width="161"><p>Основы бизнеса</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>18:00 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 153</p></td>
				</tr>
				<tr>
					<td rowspan="5" width="144">
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>18.02.2023</p>
						<p>суббота</p>
					</td>
					<td width="161"><p>Дизайн</p></td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>15:00 – 18:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 130</p></td>
				</tr>
				<tr>
					<td width="161"><p>Математика</p></td>
					<td width="189"><p>7, 8 классы –180 мин</p></td>
					<td width="132"><p>15:00 – 18:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 130</p></td>
				</tr>
				<tr>
					<td width="161"><p>Математика</p></td>
					<td width="189"><p>9, 10, 11 классы –240 мин</p></td>
					<td width="132"><p>15:00 – 19:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>А 33</p></td>
				</tr>
				<tr>
					<td width="161">
						<p>Международные</p>
						<p>отношения</p>
					</td>
					<td width="189"><p>180 мин</p></td>
					<td width="132"><p>15:00 – 18:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 130</p></td>
				</tr>
				<tr>
					<td width="161"><p>Психология</p></td>
					<td width="189"><p>120 мин</p></td>
					<td width="132"><p>19:00 – 21:00</p></td>
					<td width="160"><p>Ленина 8</p></td>
					<td width="132"><p>Д 151</p></td>
				</tr>
				<tr>
					<td width="144">
						<p>19.02.2023</p>
						<p>воскресенье</p>
					</td>
					<td width="161">
						<p>&nbsp;</p>
						<p>Обществознание</p>
					</td>
					<td width="189">
						<p>&nbsp;</p>
						<p>180 мин</p>
					</td>
					<td width="132">
						<p>&nbsp;</p>
						<p>15:00 – 18:00</p>
					</td>
					<td width="160">
						<p>&nbsp;</p>
						<p>Ленина 8</p>
					</td>
					<td width="132">
						<p>&nbsp;</p>
						<p>Д 130</p>
					</td>
				</tr>
			</tbody>
		</table>

	</div>
</template>
