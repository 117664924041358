<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-2">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Дзюдо»
		</h3>
		<p class="EventsCard-sub-title">
			с 1 по 14 июня 2024 года
		</p>
	</div>
</template>