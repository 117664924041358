<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			18.12.2023
		</p>
		<h2 class="NewsPage-title">
			Более 160 школьников региона принимают участие в научных образовательных программах «Персея»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage34.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» стартовали декабрьские научные интенсивы: «Проектная деятельность: Большие вызовы», «Зимняя интеллектуальная общеразвивающая школа», «Анатомия без границ». Участниками образовательных программ стали 164 школьника из Иркутска, Братска, Нижнеудинска, Усолье-Сибирского, Алзамая, Шелехово, Тулуна, Ангарска, Бохана, Иркутского, Усольского районов.
			</p>
			<p class="NewsPage-sub-title">
				Подробнее о программах читайте в нашем материале.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена «Проектная деятельность» проходит в Образовательном центре третий раз. На программе школьники готовятся к региональному этапу отбора во Всероссийском конкурсе научно-технологических проектов «Большие вызовы», который проводит Образовательный центр «Сириус» фонда «Талант и успех».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, цель конкурса – включить талантливых детей в работу с технологиями под руководством наставников и научить их применять свой талант на практике в реальных проектах.
			</p>
			<p class="NewsPage-sub-title">
				В этом году школьники Приангарья будут работать над проектами в шести направлениях: Агропромышленные и биотехнологии; Большие данные, искусственный интеллект, финансовые технологии и машинное обучение; Космические технологии; Современная энергетика; Умный город и безопасность; Беспилотный транспорт и логистические системы.
			</p>
			<p class="NewsPage-sub-title">
				Особенностью программы этого года стало то, что за каждым направлением закреплен свой наставник, который помогает ребятам в работе над проектами.
			</p>
			<p class="NewsPage-sub-title">
				По словам организаторов, смена поможет участникам получить актуальные знания и умения в области проектной деятельности, а также включиться в работу над задачами, связанными с научно-технологическим развитием региона и России.
			</p>
			<p class="NewsPage-sub-title">
				Участниками научного интенсива стали 80 школьников. Завершится программа защитой проектов. Лучшие работы будут рекомендованы к участию в следующем этапе отбора, который проводит Образовательный центр «Сириус».
			</p>
			<p class="NewsPage-sub-title">
				На Зимнюю общеразвивающую интеллектуальную школу приехали 56 школьников. Все они – обучающиеся школ г. Усолье-Сибирского. Как поясняет руководитель отдела развития образовательных программ по направлению «Наука» Татьяна Толстихина: Цикл сезонных программ интеллектуальной школы направлен на погружение школьников в мир науки и техники.
			</p>
			<p class="NewsPage-sub-title">
				– Каждый сезон мы приглашаем школьников из одного муниципалитета, чтобы они познакомились с научной деятельностью и Образовательным центром. Это помогает ребятам включиться в исследовательскую деятельность и получить хорошую базу для того, чтобы в дальнейшем принимать участие в научных интенсивах «Персея». На этой зимней смене ребята знакомятся с инженерной деятельностью. Для них проходят занятия по программированию и 3-D моделированию, – отметила Татьяна Толстихина.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена «Анатомия без границ» – это заключительная программа блока по изучению физиологии человека. Цикл программ стартовал с дистанционного курса, на котором школьники знакомились с теоретическими основами строения тела человека. Сейчас ребята приехали на десятидневный интенсив, чтобы продолжить изучение в формате практических занятий.
			</p>
			<p class="NewsPage-sub-title">
				– Это первая программа в центре для школьников, которые хотят связать свою жизнь с медициной. Медицинская деятельность – одна из самых сложных сфер. Иногда выпускники, выбирая ее, не всегда понимают, с чем им придется столкнуться. Поэтому на программе ребята смогут познакомиться с профессией, получить актуальные знания и умения в области кардиологии, хирургии, травматологии и неврологии, а также научатся оказывать первую медицинскую помощь и проводить лабораторные исследования. Все это поможет им сделать осознанный профессиональный выбор, – поделилась Альбина Томилова, руководитель программы, старший лаборант Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				Это последние очные программы «Персея» в 2023 году. В следующем году школьники смогут принять участие в научных образовательных интенсивах с февраля 2024 года. Информация о сменах появится в разделе «Образовательные программы»: <a class="NewsPage-link" href="https://perseusirk.ru/ProfileBlock">https://perseusirk.ru/ProfileBlock</a>
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(5).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(6).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(7).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(8).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(9).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(10).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(11).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(12).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(13).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(14).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage34(15).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
