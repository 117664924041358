<template>
	<header class="header">

		<div class="header_top">
			<div class="header_inform">
				<router-link to="/" class="header_logo">
					<img class="header_logo-img" src="../assets/img/Logo/logo.webp" alt="Логотип Персей" loading="auto">
					<img class="header_logo-img-mini" src="../assets/img/Logo/logo_mini.webp" alt="Логотип Персей" loading="auto">
				</router-link>

				<div class="header_info-contacts">
					<ul class="header_info-contacts-list">
						<li class="header_info-contacts-items">
							<a href="tel:+73952546044" class="header_info-contacts-link">
								8 (3952) 54-60-44
							</a>
						</li>
						<li class="header_info-contacts-items">
							<a href="mailto:perseus@perseusirk.ru" class="header_info-contacts-link">
								perseus@perseusirk.ru
							</a>
						</li>
					</ul>
				</div>
			</div>

		</div>

		<div class="header-bottom">

			<input type="checkbox" name="menu" id="menu">
			<label for="menu" class="menu">
				<span class="menu_line"></span>
				<span class="menu_line"></span>
				<span class="menu_line"></span>
			</label>

			<nav class="header_nav">
				<ul class="header_nav-list">
					<li class="header_nav-items">
						<a class="header_nav-link header_icon">
							Сведения об образовательной организации
						</a>
						<ul class="header_nav-list header-list-colum">
							<li class="header_nav-items header-items-colum">
								<router-link to="/BasicInformation" class="header_nav-link header-link-colum">
									Основные сведения
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/StructureAndOrgans" class="header_nav-link header-link-colum">
									Структура и органы управления образовательной организацией
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Documentation" class="header_nav-link header-link-colum">
									Документы
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Education" class="header_nav-link header-link-colum">
									Образование
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Management" class="header_nav-link header-link-colum">
									Руководство. Педагогический (научно-педагогический) состав
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Logistics" class="header_nav-link header-link-colum">
									Материально-техническое обеспечение и оснащенность образовательного процесса
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/PaidServices" class="header_nav-link header-link-colum">
									Платные образовательные услуги
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Financial" class="header_nav-link header-link-colum">
									Финансово-хозяйственная деятельность
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/VacantPlaces" class="header_nav-link header-link-colum">
									Вакантные места для приема (перевода) обучающихся
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Scholarships" class="header_nav-link header-link-colum">
									Стипендии и меры поддержки обучающихся
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/InternatiCooperation" class="header_nav-link header-link-colum">
									Международное сотрудничество
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/AccessibleEnvironment" class="header_nav-link header-link-colum">
									Доступная среда
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Trustees" class="header_nav-link header-link-colum">
									Попечительский совет
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/ExpertCouncil" class="header_nav-link header-link-colum">
									Экспертный совет
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/Contacts" class="header_nav-link header-link-colum">
									Контакты
								</router-link>
							</li>
						</ul>
					</li>
					<li class="header_nav-items">
						<a class="header_nav-link header_icon">
							О нас
						</a>
						<ul class="header_nav-list header-list-colum">
							<li class="header_nav-items header-items-colum">
								<router-link to="/Intelligence" class="header_nav-link header-link-colum">
									О нас
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/News" class="header_nav-link header-link-colum">
									Новости
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link header-link-colum header_icon">
									Как попасть
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/GetIn" class="header_nav-link header-link-colum">
											Как попасть
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/SelectionCriteria" class="header_nav-link header-link-colum">
											Критерии отбора
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/HowToGet" class="header_nav-link header-link-colum">
											Условия заезда, размещения и выезда учащихся
										</router-link>
									</li>
								</ul>
							</li>
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link header-link-colum header_icon">
									Летний отдых и оздоровление детей
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/BestPrograms" class="header_nav-link header-link-colum">
											Реестр лучших практик
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/SummerHolidayRecommendations" class="header_nav-link header-link-colum">
											Методические рекомендации
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum">
											Нормативно правовые документы
										</router-link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li class="header_nav-items">
						<a class="header_nav-link header_icon">
							Образовательные программы
						</a>
						<ul class="header_nav-list header-list-colum ">
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link header-link-colum header_icon">
									Профильные смены
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/ScienceBlock" class="header_nav-link header-link-colum">
											Наука
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/ArtBlock" class="header_nav-link header-link-colum">
											Искусство
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/SportBlock" class="header_nav-link header-link-colum">
											Спорт
										</router-link>
									</li>
								</ul>
							</li>
							<li class="header_nav-items header-items-colum ">
								<a class="header_nav-link header-link-colum header_icon">
									Еженедельные курсы
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/WeeklyCoursesScience" class="header_nav-link header-link-colum">
											Наука
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/WeeklyCoursesArt" class="header_nav-link header-link-colum">
											Искусство
										</router-link>
									</li>
								</ul>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/RemoteBlock" class="header_nav-link header-link-colum">
									Дистанционные курсы
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum ">
								<a class="header_nav-link header-link-colum header_icon">
									Проектные программы
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/Sirius_Summer" class="header_nav-link header-link-colum">
											Сириус.Лето
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/Sirius_Artificial_Intelligence" class="header_nav-link header-link-colum">
											Сириус.ИИ
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/Sirius_Lessons" class="header_nav-link header-link-colum">
											Уроки настоящего
										</router-link>
									</li>
								</ul>
							</li>
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link header-link-colum header_icon">
									Педагогам
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/Training" class="header_nav-link header-link-colum">
											Повышение квалификации
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/ProfessionalEducation" class="header_nav-link header-link-colum">
											Профессиональное обучение
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/ConferenceSeminars" class="header_nav-link header-link-colum">
											Семинары, НПК
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/MunicipalCoordinators" class="header_nav-link header-link-colum">
											Информация для муниципальных координаторов по работе с одаренными детьми
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/CompetitionsForTeachers" class="header_nav-link header-link-colum">
											Конкурсы для педагогов
										</router-link>
									</li>
								</ul>
							</li>
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link header-link-colum header_icon">
									Страница психолога
								</a>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="/RecommendationsForTeachers" class="header_nav-link header-link-colum">
											Рекомендации для педагогов
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="/PsychologicalSupport" class="header_nav-link header-link-colum">
											Психологическое сопровождение одаренных детей
										</router-link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li class="header_nav-items">
						<a class="header_nav-link header_icon">
							Олимпиады и конкурсы
						</a>
						<ul class="header_nav-list header-list-colum">
							<li class="header_nav-items header-items-colum">
								<router-link to="" class="header_nav-link header-link-colum"
									@click="$router.push({ name: 'OlyandCom' })">
									Список мероприятий
								</router-link>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="" class="header_nav-link header-link-colum header_icon">
									Олимпиады
								</router-link>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'VsoshPage' })">
											ВсОШ
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'GoldenFundPage' })">
											Золотой фонд Сибири
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'OlympicPage' })">
											Олимпик
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'HighestQualityPage' })">
											Высшая проба
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'TalentAndSuccessFoundationPage' })">
											Отборочные туры на программы образовательного центра «Сириус» (Фонд «Талант и успех»)
										</router-link>
									</li>
								</ul>
							</li>
							<li class="header_nav-items header-items-colum">
								<router-link to="/OlyandCom" class="header_nav-link header-link-colum header_icon">
									Конкурсы
								</router-link>
								<ul class="header_nav-list header-list-colum header-list-colum-drop">
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'BigChallengesPage' })">
											Большие вызовы
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'JuniorProfiChampionshipPage' })">
											Чемпионат ЮниорПрофи
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'SmartiesAndSmartiesPage' })">
											Умницы и Умники
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'BestStudentPage' })">
											Лучший ученик года
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'BestCounselorPage' })">
											Лучший вожатый
										</router-link>
									</li>
									<li class="header_nav-items header-items-colum">
										<router-link to="" class="header_nav-link header-link-colum"
											@click="$router.push({ name: 'BestPracticsPage' })">
											Лучшие практики работы с одаренными детьми
										</router-link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li class="header_nav-items">
						<router-link to="/News" class="header_nav-link">
							Новости
						</router-link>
					</li>
					<li class="header_nav-items">
						<a class="header_nav-link header_icon">
							О Сириусе
						</a>
						<ul class="header_nav-list header-list-colum">
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link" href="https://sochisirius.ru/" target="_blank">
									Сириус
								</a>
							</li>
							<li class="header_nav-items header-items-colum">
								<a class="header_nav-link" href="https://edu.sirius.online/#/" target="_blank">
									Сириус.Курсы
								</a>
							</li>
						</ul>
					</li>
					<li class="header_nav-items">
						<div class="header-nav-bottom-img-box">
							<img id="specialButton" style="cursor:pointer;" src="https://lidrekon.ru/images/special.png"
								alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" />
						</div>
					</li>
				</ul>
			</nav>

		</div>

	</header>
</template>