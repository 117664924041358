<template>
	<div class="BestPracticsPage _cover">
		<h2 class="BestPracticsPage-title _cover-title">
			Региональный конкурс<br> «Лучшие практики работы с одаренными детьми»
		</h2>

		<!-- <div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/svg/Олимпик.webp" alt="Логотип" />
		</div> -->

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Цель конкурса:</b> выявление и поддержка лучших практик работы с одаренными, талантливыми и
			высокомотивированными детьми.
		</p>

		<p class="JuniorProfi-sub-title">
			Для участников:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Принять участие в конкурсе могут педагогические и руководящие работники образовательных организаций,
				реализующих образовательные программы начального общего образования, основного общего образования, среднего
				общего образования, среднего профессионального образования, дополнительные общеразвивающие программы и
				дополнительные предпрофессиональные программы.
			</li>
			<li class="JuniorProfi-items">
				Допускается подача заявки индивидуально и от коллектива, не превышающего 3 (трех) человек.
			</li>
			<li class="JuniorProfi-items">
				В качестве лучших практик работы с одаренными, талантливыми и высокомотивированными детьми следует понимать
				следующие учебно-методические материалы: методические рекомендации и дидактические материалы.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Номинации конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными детьми общеобразовательной
				организации»,
			</li>
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными обучающимися организации
				дополнительного образования детей»,
			</li>
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными обучающимися дошкольной
				образовательной организации».
			</li>
		</ul>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Как участвовать:</b> Информация о старте конкурса в 2025 г. появится в начале 2025 г.
		</p>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

	</div>

	<!-- Архив -->
	<ArchiveBestPractics />

</template>

<script>
import ArchiveBestPractics from '@/components/ArchiveBestPractics.vue';

export default {
	components: {
		ArchiveBestPractics,
	}
}

</script>