<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Экология»
		</h2>
		<p class="EventsPage-date">
			с 10 по 23 апреля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=151" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24691-programma-ekologiya" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Экология» для учащихся 6-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа направлена на приобретение учащимися теоретических и практических знаний в области системного анализа применительно к лесному комплексу, изучение основных принципов представления разнообразных объектов лесного хозяйства в качестве сложных систем.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (эссе и портфолио) до 1 апреля 2024 года на платформе Moodle, Образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, нужно будет записаться на программу «Экология» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 2 апреля на сайте Образовательного центра «Персей».</b>
		</p>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 2 апреля 2024 г. в 20.00, <a style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1709296297">ссылка на подключение</a>
		</p>

		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Бабушкина Варвара Андреевна</li>
			<li class="EventsPage-number">Бадаева Надежда Андреевна</li>
			<li class="EventsPage-number">Базилевская Софья Романовна</li>
			<li class="EventsPage-number">Беломестных Светлана Юрьевна</li>
			<li class="EventsPage-number">Белорусова Светлана Андреевна</li>
			<li class="EventsPage-number">Бердникова Карина Андреевна</li>
			<li class="EventsPage-number">Бумбошкина Ева Владимировна</li>
			<li class="EventsPage-number">Бурдуковская Кристина Олеговна</li>
			<li class="EventsPage-number">Владимир Романович Сизен</li>
			<li class="EventsPage-number">Галдасова Полина Игоревна</li>
			<li class="EventsPage-number">Гаптрахманова Милена Александровна</li>
			<li class="EventsPage-number">Голодникова Дарья Михайловна</li>
			<li class="EventsPage-number">Голык Софья Святославовна</li>
			<li class="EventsPage-number">Горбунова Вероника Валерьевна</li>
			<li class="EventsPage-number">Елфимова Виолетта Алексеевны</li>
			<li class="EventsPage-number">Ершов Никита Сергеевич</li>
			<li class="EventsPage-number">Ершова Валерия Сергеевна</li>
			<li class="EventsPage-number">Журавлёва Мария Михайловна</li>
			<li class="EventsPage-number">Иванова Алина Андреевна</li>
			<li class="EventsPage-number">Кальянова Анастасия Михайловна</li>
			<li class="EventsPage-number">Клименков Ефим Алексеевич</li>
			<li class="EventsPage-number">Котлов Владимир Алексеевич</li>
			<li class="EventsPage-number">Купрякова Ксения Андреевна</li>
			<li class="EventsPage-number">Курмель Софья Андреевна</li>
			<li class="EventsPage-number">Лесина София Александровна</li>
			<li class="EventsPage-number">Лимарев Михаил Павлович</li>
			<li class="EventsPage-number">Лутик Мария Николаевна</li>
			<li class="EventsPage-number">Мальцева Анастасия Андреевна</li>
			<li class="EventsPage-number">Мальцева Ульяна Владимировна</li>
			<li class="EventsPage-number">Масленникова Александра Григорьевна</li>
			<li class="EventsPage-number">Масленникова Надежда Григорьевна</li>
			<li class="EventsPage-number">Мороховец Николай Алексеевич</li>
			<li class="EventsPage-number">Никифорова Анастасия Сергеевна</li>
			<li class="EventsPage-number">Новикова Дарина Ивановна </li>
			<li class="EventsPage-number">Огородникова Софья Владимировна</li>
			<li class="EventsPage-number">Оскирко Александер Иванович</li>
			<li class="EventsPage-number">Путилина Елизавета Валентиновна</li>
			<li class="EventsPage-number">Садовник Сабина Евгеньевна</li>
			<li class="EventsPage-number">Силаев Андрей Дмитриевич</li>
			<li class="EventsPage-number">Ставерская Яна Максимовна</li>
			<li class="EventsPage-number">Старицына Кира Витальевна</li>
			<li class="EventsPage-number">Степан Соболев Михайлович</li>
			<li class="EventsPage-number">Субач Анастасия Михайловна</li>
			<li class="EventsPage-number">Суфиева Елизавета Михайловна</li>
			<li class="EventsPage-number">Травкина Алиса Андреевна</li>
			<li class="EventsPage-number">Федотов Глеб Алексеевич</li>
			<li class="EventsPage-number">Хабадаева Карина Ильинична</li>
			<li class="EventsPage-number">Хабадаева Мария Викторовна</li>
			<li class="EventsPage-number">Халиулин Марат Рашидович</li>
			<li class="EventsPage-number">Черникевич Вероника Андреевна</li>
			<li class="EventsPage-number">Чернова Арина Александровна</li>
			<li class="EventsPage-number">Ширяева Арина Павловна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/xBct/NReZ1p6nZ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/m87Et0Zog0zChw" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>