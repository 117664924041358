<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Проектная деятельность в русском языке и литературе»
		</h2>
		<p class="EventsPage-date">
			с 5 по 10 февраля 2024 года
		</p>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Проектная деятельность в русском языке и литературе»
			для учащихся 4-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Проектная деятельность является важнейшим направлением образовательного процесса учащихся. Способствует
			обеспечению таких условий в образовательном процессе, когда полученные знания становятся инструментом решения
			творческих теоретических и практических задач и инструментом формирования потребности и способности к
			саморазвитию. В рамках обучения по программе учащиеся получают навыки эффективного взаимодействия в команде,
			построения отношений с другими на основе взаимного уважения и сотрудничества, проявляют себя в решении групповых
			задач. Также программа ориентирована на реализацию творческого потенциала обучающихся, через социально-значимую
			деятельность.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Юрьева Ольга Алексеевна, педагог дополнительного образования, заместитель директора МАОУ г. Иркутска ОК
				"Лесной".
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 5 февраля 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>