<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage20.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» подвели итоги октябрьских научных профильных смен
			</h2>
			<p class="NewsCard-date">
				26.10.2023
			</p>
		</div>
	</div>
</template>