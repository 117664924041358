<template>
	<div class="Sirius_Lessons _cover">
		<h2 class="Sirius_Lessons-title _cover-title">
			Уроки настоящего
		</h2>

		<div class="Sirius_Lessons-logo">
			<img loading="lazy" class="Sirius_Lessons-logo-img" src="../assets/img/svg/Sirius_Lessons-logo-img.webp"
				alt="Логотип Уроки настоящего">
		</div>

		<h2 class="Sirius_Lessons-title _cover-title">
			<a class="Sirius_Lessons-link" href="https://scienceclass.sirius.ru/">
				О программе
			</a>
		</h2>

		<p class="Sirius_Lessons-text">
			Ученики 7-11 классов создают студии на базе школ и других образовательных организаций. В течение учебного года
			школьники встречаются онлайн c ведущими учеными и инженерами, выполняют научно-технологические проектные задачи и
			получают комментарии от экспертов по своим решениям.
		</p>

		<p class="Sirius_Lessons-text">
			Здесь школьники могут найти задачу для проектной работы, связанную с актуальными, современными проблемами науки,
			технологии, бизнеса, а также наставника, который поможет разобраться в задаче и будет сопровождать школьника в
			течение всего проекта.
		</p>

		<h3 class="Sirius_Lessons-title _cover-title">
			Как проходит программа
		</h3>
		<p class="Sirius_Lessons-text">
			Ежегодно каждый месяц с сентября по май школьники получают от компаний–партнеров несколько задач на выбор и решают
			их в своих студиях.
		</p>
		<p class="Sirius_Lessons-text">
			Задания размещены в личных кабинетах студий и на сайте программы
		</p>

		<ul class="Sirius_Lessons-list">
			<p class="Sirius_Lessons-list-text">
				Первая неделя
			</p>
			<li class="Sirius_Lessons-items">Изучение материалов цикла, научно-технологической или исследовательской задачи,
				требований к оформлению</li>
			<li class="Sirius_Lessons-items">Составление плана работы над задачей</li>
			<li class="Sirius_Lessons-items">Определение числа и продолжительности встреч в студии, в случае необходимости
				планирование выездов в компанию региона для консультации с экспертом</li>
			<li class="Sirius_Lessons-items">Расписание ролей и обязанностей между участниками</li>
			<li class="Sirius_Lessons-items">Участие в онлайн-лекции от лидера компании</li>
		</ul>

		<ul class="Sirius_Lessons-list">
			<p class="Sirius_Lessons-list-text">
				Вторая неделя
			</p>
			<li class="Sirius_Lessons-items">Обсуждение возникших вопросов с экспертами компании во время онлайн-консультации
			</li>
			<li class="Sirius_Lessons-items">Работа над задачей в соответствии с планом</li>
		</ul>

		<ul class="Sirius_Lessons-list">
			<p class="Sirius_Lessons-list-text">
				Третья неделя
			</p>
			<li class="Sirius_Lessons-items">Продолжение работы над задачей</li>
			<li class="Sirius_Lessons-items">Финальная корректировка решения</li>
		</ul>

		<ul class="Sirius_Lessons-list">
			<p class="Sirius_Lessons-list-text">
				Четвертая неделя
			</p>
			<li class="Sirius_Lessons-items">Оформление решения</li>
			<li class="Sirius_Lessons-items">Оформление итогового поста о результатах работы в группе студии во «ВКонтакте»
			</li>
			<li class="Sirius_Lessons-items">Загрузка отчета о выполнении в курс</li>
		</ul>

		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe class="Intelligence-block-video-items"
					src="https://vk.com/video_ext.php?oid=-150963328&id=456239215&hd=2" title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
					Проект «Уроки настоящего»
				</iframe>
			</div>
		</div>

		<h3 class="Sirius_Lessons-title _cover-title">
			Как принять участие
		</h3>

		<div class="Sirius_Summer-contact-box">
			<a class="Sirius_Lessons-link" href="https://scienceclass.sirius.ru/participants">
				Инструкция для участников
			</a>
			<a class="Sirius_Lessons-link" href="https://scienceclass.sirius.ru/docs">
				Положение
			</a>
		</div>


		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Календарь событий
			</summary>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Примеры задач прошлых лет
			</summary>
			<a class="Sirius_Lessons-link"
				href="https://scienceclass.sirius.ru/#program">https://scienceclass.sirius.ru/#program</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Действующие студии
			</summary>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Советы студийцам
			</summary>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Ответы на вопросы
			</summary>
			<a class="Sirius_Lessons-link" href="https://scienceclass.sirius.ru/faq">https://scienceclass.sirius.ru/faq</a>
		</details>

		<h3 class="Sirius_Summer-title _cover-title">
			Контакты
		</h3>

		<div class="Sirius_Summer-contact-box">
			<ul class="Sirius_Summer-contact-list">
				<li class="Sirius_Summer-contact-items">
					Самодурова Вера Геннадьевна
				</li>
				<li class="Sirius_Summer-contact-items">
					Телефон: 8 (3952) 546-044
				</li>
				<li class="Sirius_Summer-contact-items">
					Эл. почта: v.samodurova@perseusirk.ru
				</li>
			</ul>

			<ul class="Sirius_Summer-contact-list">
				<li class="Sirius_Summer-contact-items">
					Шведина Светлана Александровна
				</li>
				<li class="Sirius_Summer-contact-items">
					Телефон: 8 (3952) 546-044
				</li>
				<li class="Sirius_Summer-contact-items">
					Эл. почта: s.shvedina@perseusirk.ru
				</li>
			</ul>
		</div>

	</div>
</template>