<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Песенные традиции казачества»
		</h2>
		<p class="EventsPage-date">
			с 20 сентября по 16 октября 2023 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/21967-programma-pesennye-traditsii-kazachestva" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит дистанционный курс «Песенные традиции казачества» для учащихся
			общеобразовательных организаций Иркутской области в возрасте с 10 до 15 лет, увлекающихся песенными фольклорными
			традициями казачества, уникальной самобытностью казачьей культуры.
		</p>

		<p class="EventsPage-text">
			Программа «Песенные традиции казачества» ориентирована на развитие духовно-нравственных, этических и эстетических
			чувств, интереса к самостоятельной работе по поиску интересного материала в области казачьей культуры, духовного
			наследия и традиций казачества.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Агафонова Е.Г. – педагог дополнительного образования по народному вокалу ГБУ ДО ИО ЦРТДЮ «Узорочье»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Учащимся, необходимо обязательно зарегистрироваться в системе Навигатор дополнительного образования Иркутской
				области по ссылке:
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21967-programma-pesennye-traditsii-kazachestva
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>