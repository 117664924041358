<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			03.06.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/news/3671551/">
				Три школьницы представят Приангарье на Всероссийской телевизионной олимпиаде «Умницы и умники»
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage63.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Иркутской области завершился региональный (отборочный) этап Всероссийской открытой телевизионной гуманитарной
				олимпиады школьников «Умницы и умники». Всего 20 субъектов России, в том числе Иркутская область, проводят
				региональный этап телевикторины. В этом году в Приангарье он прошел в третий раз. Мероприятие состоялось 31 мая,
				на площадке иркутской школы № 19. Тема игры этого года – «Декабристы в Сибири».
			</p>
			<p class="NewsPage-sub-title">
				Оператором выступил Региональный центр выявления и поддержки одарённых детей «Персей», созданный для координации
				работы с талантливой молодежью в Приангарье в рамках федерального проекта «Успех каждого ребенка» президентского
				национального проекта «Образование».
			</p>
			<p class="NewsPage-sub-title">
				В отборочном этапе приняли участие 14 школьников Иркутской области – девять агонистов и пять теоретиков. Это
				учащиеся 10-х классов – победители или призеры регионального этапа Всероссийской олимпиады школьников этого
				года, которые представили Ангарск, Братск, Иркутск, Усолье-Сибирское, Братский и Шелеховский районы.
			</p>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов рассказал, что в этом году у потенциальных участников
				регионального этапа телевизионной олимпиады была возможность подготовиться к конкурсным испытаниям.
			</p>
			<p class="NewsPage-sub-title">
				– Для этого региональный оператор олимпиады Образовательный центр «Персей» в мае организовал обучение по
				дистанционной программе «Умницы и умники», его участниками стали более 100 десятиклассников Приангарья из числа
				участников регионального этапа всероссийской олимпиады школьников по русскому языку, литературе, обществознанию,
				праву, экономике, истории и иностранным языкам. Завершая обучение, участники написали эссе и прошли
				тестирование, по результатам которых и были определены участники отборочного этапа, – сообщил министр
				образования Иркутской области Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Участников отборочного финала приветствовала координатор Всероссийской открытой гуманитарной телевизионной
				олимпиады «Умницы и умники» Карина Князева, которая приняла участие в работе жюри.
			</p>
			<p class="NewsPage-sub-title">
				– Ребята, сегодня вам предстоит принять вызов самим себе на преодоление своих страхов, неуверенности в своих
				знаниях, и я надеюсь, что вы этот вызов достойно примете и справитесь с ним. А еще хочу добавить, что мы сейчас
				живем в непростое время, и у нас впереди будущее – тоже непростое, но очень интересное, и творить это будущее –
				вам. Удачи и вперед, – обратилась к участникам Карина Князева.
			</p>
			<p class="NewsPage-sub-title">
				Право принять участие в съемках 33-го сезона в Москве получили иркутянки Вероника Ильина (средняя школа № 5) и
				Василиса Кокорина (средняя школа № 55), а также ученица Шелеховского лицея Ксения Яшина.
			</p>
			<p class="NewsPage-sub-title">
				Победители интеллектуальных состязаний осенью отправятся в Москву на съемки программы «Умницы и умники» и будут
				бороться за главный приз – поступление без экзаменов в Московский государственный институт международных
				отношений.
			</p>

		</div>
	</div>
</template>
