<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			«Научный прорыв»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			c 18 октября по 7 ноября 2021 года.
		</h4>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/concurs/view/139" target="_blank">
			Регистрация
		</a>
		<hr>

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Учредитель Конкурса
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор конкурса
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			О конкурсе
		</p>
		<p class="EventsPage-text">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/11/%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D1%80%D1%8B%D0%B2-2021.pdf" target="_blank">
			Научный прорыв 2021
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%80%D1%83%D1%81%D0%B0-%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D1%80%D1%8B%D0%B2.pdf" target="_blank">
			О проведении регионального конкурса Научный прорыв
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/12/%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D1%8B%D0%B9-%D0%BF%D1%80%D0%BE%D1%80%D1%8B%D0%B2.pdf" target="_blank">
			Об итогах регионального конкурса Научный прорыв
		</a>
		<hr>

		<p class="EventsPage-text">
			Номинации конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Эволюция соцсетей»
			</li>
			<li class="JuniorProfi-items">
				«Безопасный транспорт»
			</li>
			<li class="JuniorProfi-items">
				«Роботохника в жизни современного человека»
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Условия участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Для участия в конкурсе необходимо предоставить авторский видеофайл (анимационный фильм), созданный при помощи stop motion анимации.
			</li>
			<li class="JuniorProfi-items">
				Конкурс проводится в один этап, в формате заочного участия. Сроки проведения c 18 октября по 7 ноября 2021 года.
			</li>
			<li class="JuniorProfi-items">
				Требования, предъявляемые к участникам Конкурса: индивидуальное участие или коллективное участие. Коллектив, общим количеством от двух до пяти человек, возглавляемый руководителем (педагогическим работником).
			</li>
			<li class="JuniorProfi-items">
				В состав коллектива участников, кроме руководителя, могут входить обучающиеся от 14 до 18 лет (включительно) общеобразовательных организаций, профессиональных образовательных организаций и организаций дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Руководителем может быть педагогический работник образовательной организации, курирующий конкурсную работу по указанным направлениям.
			</li>
			<li class="JuniorProfi-items">
				Члены коллектива участников могут быть представителями одной образовательной организации или разных образовательных организаций Иркутской области. Каждый участник или команда может подать на Конкурс только один видеоролик в каждой номинации.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Документы для участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				заявление на участие в Конкурсе с отметкой о согласии принять участие в Конкурсе (приложение 1 к Положению);
			</li>
			<li class="JuniorProfi-items">
				анкета руководителя проекта (приложение 2 к Положению);
			</li>
			<li class="JuniorProfi-items">
				пояснительная записка (приложение 3 к Положению);
			</li>
			<li class="JuniorProfi-items">
				видеофайл (анимационный фильм), созданный при помощи stop motion анимации.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Технические требования конкурсной работы:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				формат видео с возможностью воспроизведения на большом количестве современных цифровых устройств: AVI, MPEG, MKV, WMV, FLV, FullHD и др.;
			</li>
			<li class="JuniorProfi-items">
				продолжительность видеоролика – до 5 минут.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Информационные требования конкурсной работы:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				наличие титульного кадра с указанием названия видеоролика, автора(ов), наименования муниципального образования, образовательной организации;
			</li>
			<li class="JuniorProfi-items">
				отсутствие рекламы и ссылки на продукцию известных брендов и логотипы компаний, за исключением случаев, когда на это существуют веские редакционные основания (указать в пояснительной записке);
			</li>
			<li class="JuniorProfi-items">
				отсутствие нецензурных, оскорбительных или любых других неподобающих материалов.
			</li>
			<li class="JuniorProfi-items">
				Содержательные аспекты видеоролика должны быть представлены в соответствии с критериями конкурсного отбора.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Регистрация на конкурс
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Регистрация проходит по ссылке: https://konkurs.ric38.ru/
			</li>
			<li class="JuniorProfi-items">
				После регистрации на сайте необходимо выбрать в списке конкурсов «Региональный конкурс инфобаннеров научно-технической направленности «MODERN TECH» согласно номинации.
			</li>
			<li class="JuniorProfi-items">
				После выбора конкурса необходимо заполнить заявку, прикрепив конкурсные документы.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Результаты
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Экспертная комиссия в срок до 11 декабря 2021 года осуществляет оценку предоставленных на Конкурс документов на основании перечня критериев, применяемых при проведении Конкурса.
			</li>
			<li class="JuniorProfi-items">
				По результатам оценки предоставленных документов формируются 3 (три) рейтинга участников Конкурса.
			</li>
			<li class="JuniorProfi-items">
				Первый участник (коллектив) в каждой номинации, набравший наибольшее количество баллов по рейтингу, становится победителем Конкурса, следующие за ним по рейтингу 2 (два) участника (коллектива) – лауреатами Конкурса. Итого будет определено 9 (девять) финалистов.
			</li>
			<li class="JuniorProfi-items">
				3.10. Список победителей и лауреатов Конкурса утверждается распоряжением министерства образования Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				3.11. Победители и лауреаты Конкурса награждаются дипломами и ценными подарками. Все участники Конкурса получают сертификаты участия.
			</li>
			<li class="JuniorProfi-items">
				3.12 Лучшие работы будут опубликованы на сайте Регионального института кадровой политики (http://center-prof38.ru/).
			</li>
		</ul>

	</div>
</template>