<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			«Лучший вожатый-2023»
		</h2>
		<p class="EventsPage-date">
			Регистрация: с 10 октября по 16 ноября 2023 г.
		</p>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Документы:
			</h3>
			<a class="EventsPage-link" href="https://starsite.perseusirk.ru//wp-content/uploads/2023/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B9-%D0%B2%D0%BE%D0%B6%D0%B0%D1%82%D1%8B%D0%B9-2023.pdf" target="_blank">
				Положение Лучший вожатый 2023
			</a>
			<a class="EventsPage-link" href="https://starsite.perseusirk.ru//wp-content/uploads/2023/09/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-1.-%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5.docx" target="_blank">
				Приложение 1. Заявление
			</a>
			<a class="EventsPage-link" href="https://starsite.perseusirk.ru//wp-content/uploads/2023/09/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-2.-%D0%90%D0%BD%D0%BA%D0%B5%D1%82%D0%B0.docx" target="_blank">
				Приложение 2. Анкета
			</a>
			<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B9-%D0%B2%D0%BE%D0%B6%D0%B0%D1%82%D1%8B%D0%B9.pdf" target="_blank">
				Приказ об итогах конкурса Лучший вожатый.
			</a>
			<hr>
			<h3 class="EventsPage-nte">
				Цель конкурса:
			</h3>
			<p class="EventsPage-nte-dis">
				Конкурс направлен на выявление, анализ и трансляцию лучших вожатских практик, повышение педагогического мастерства и методической грамотности вожатых, самореализации способностей для повышения качества организации отдыха и оздоровления детей.
			</p>
			<hr>
			<h3 class="EventsPage-nte">
				О конкурсе:
			</h3>
			<p class="EventsPage-nte-dis">
				Приглашаем принять участие вожатых образовательных организаций, в возрасте от 16 лет, имеющие опыт работы воспитательной деятельности не менее трех месяцев.<br>
				Формат Конкурса -заочный, на сайте konkurs.ric38.ru.<br>
				Участникам нужно пройти регистрацию и подать заявку с 10 октября по 16 ноября в раздел «Лучший вожатый-2023».<br>
				К заявке нужно прикрепить:<br>
				1. Заявление на участие в конкурсе, Приложение 1 Положения.<br>
				2. Портфолио участника конкурса, Приложение 2 Положения.<br>
				3. Видеоролик вожатский-мастер-класс, требования указаны в Положении на стр.2.<br>
				4. Эссе. Тема эссе будет размешена на сайте ОЦ «Персей» 13 ноября, требования к оформлению указаны в Положении на стр.3.<br>
				5. Решение кейсов. Задания кейсов будут размешены на сайте ОЦ 14 ноября, требования к оформлению указаны в Положении на стр.3.<br>
			</p>
			<hr>
			<h3 class="EventsPage-nte">
				Конкурсные задания:
			</h3>
			<p class="EventsPage-nte-dis">
				Тема для эссе: "Почему я стал вожатым"
			</p>
			<hr>
			<h3 class="EventsPage-nte">
				Решение кейсов:
			</h3>
			<p class="EventsPage-nte-dis">
				Кейс №1.
			</p>
			<p class="EventsPage-nte-dis">
				В лагере ЧП – из третьего отряда сбежал ребёнок. По одному педагогу с каждого отряда задействованы в поисках за территорией лагеря. Мальчик Ваня из первого отряда узнал об это и решил помочь вожатым в поиске. Он перелез через забор, ограничивающий территорию лагеря, и ушёл прочёсывать лес. Через некоторое время ему удалось найти сбежавшего ребёнка и уговорить его вернуться в лагерь.
			</p>
			<p class="EventsPage-nte-dis">
				Вопрос. Что следует сделать воспитателю третьего отряда? Как должен поступить воспитатель первого отряда?
			</p>
			<p class="EventsPage-nte-dis">
				Кейс №2.
			</p>
			<p class="EventsPage-nte-dis">
				На первой неделе смены в отряд детей от 9 до 11 лет добавился мальчик 9 лет. Мальчик впервые оказался в лагере. С первых дней своего пребывания в отряде, мальчик стал испытывать сложности в общении со сверстниками, дети неохотно принимали его в свою игровую и творческую деятельность. Мальчик становился замкнутым и необщительным. В разговоре с родителями по телефону просился домой, плакал.
			</p>
			<p class="EventsPage-nte-dis">
				Вопрос: Какие меры необходимо предпринять для предупреждения ситуации дезадаптации ребенка.
			</p>
		</div>
	</div>
</template>