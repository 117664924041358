<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			07.05.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» завершились еженедельные курсы по хореографии
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage60.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре  «Персей» подвели итоги еженедельных курсов  «Наследие народного танца» и  «Танцевальная импровизация». Участие в программах приняли 62 школьника из Иркутска, Иркутского и Шелеховского района.
			</p>
			<p class="NewsPage-sub-title">
				Участники курсов получили уникальную возможность углубить свои знания в области танцевального искусства, расширить свой хореографический опыт и развить свои творческие способности.
			</p>
			<p class="NewsPage-sub-title">
				– Танец позволяет выразить свои эмоции, развить координацию, гибкость и силу, а также расширить кругозор и погрузиться в культуру разных народов. Кроме того, через танец дети учатся работать в коллективе, развивают взаимопонимание и терпение. Мы стараемся передать им не только технические навыки, но и любовь к искусству, и желание самовыражаться через движение, – отметила Лариса Намаконова,  преподаватель хореографических дисциплин Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				На программе «Наследие народного танца» школьники познакомились с многообразием культуры народного танца, изучая основные движения, стили и характерные черты. Итогом программы стали хореографические постановки кадриль «На привале», приуроченный ко Дню Победы, и хоровод к спектаклю «Сказка о Царе Гвидоне».
			</p>
			<p class="NewsPage-sub-title">
				Как отмечают участники курса, во время обучения они получили не только новые знания и навыки, но и сценический опыт, который смогут продемонстрировать на конкурсах и фестивалях различного уровня.
			</p>
			<p class="NewsPage-sub-title">
				На занятиях курса «Танцевальная импровизация» учащиеся освоили сольную, парную и ансамблевую танцевальную лексику, импровизируя и создавая собственные танцевальные композиции, основанные на свободном движении и интуитивном восприятии музыки.
			</p>
			<p class="NewsPage-sub-title">
				Уже в июле этого года в кампусе Образовательного центра «Персей» пройдет профильная смена по хореографии «Классический танец». Подать заявку можно будет на сайте Образовательного центра в разделе «Искусство»: <a class="NewsPage-link" href="https://perseusirk.ru/ArtBlock">https://perseusirk.ru/ArtBlock</a>
			</p>

		</div>
	</div>
</template>
