<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage41.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Школьница из Приангарья прошла в полуфинал всероссийской телеолимпиады «Умницы и умники»
			</h2>
			<p class="NewsCard-date">
				11.01.2024
			</p>
		</div>
	</div>
</template>