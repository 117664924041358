<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			16.10.2023
		</p>
		<h2 class="NewsPage-title">
			ЮНЫЕ МУЗЫКАНТЫ ПРИАНГАРЬЯ ОСВОЯТ ПРОГРАММУ ПО ДУХОВОМУ ОРКЕСТРОВОМУ ИСПОЛНИТЕЛЬСТВУ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage15.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Профильная смена «Оркестровое духовое исполнительство» проходит в «Персее» впервые. На программу приехали молодые музыканты из г. Иркутска, г. Байкальска, г. Ангарска и г. Усолье-Сибирское. Обучение пройдут 62 школьника в возрасте от 10 до 17 лет.
			</p>
			<p class="NewsPage-sub-title">
				В Образовательном центре ребят ждут мастер-классы по нескольким направлениям: «медные», «духовые» и «ударные». Также школьников ждут ежедневные индивидуальные и групповые занятия. Заниматься с ними будут ведущие концертмейстеры, артисты оркестра и преподаватели Приангарья.
			</p>
			<p class="NewsPage-sub-title">
				Среди них дирижер эстрадно-джазового оркестра Иркутской областной филармонии Алексей Завьялов, военный дирижер Алексей Плотников, артист оркестра ГУ МЧС Александр Сорвин, Преподаватель Иркутского областного музыкального колледжа им. Фредерика Шопена Андрей Пухтий и Владислав Федоренко. А также преподаватель духовых и ударных инструментов Иркутской областной Детской школы искусств Александр Петров, Преподаватель Детской школы искусств г. Байкальска Игорь Игнатенко и педагоги Школы-интерната музыкантских воспитанников г. Иркутска Анастасия Портяная и Семён Фролов.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказывают преподаватели программы, что школьники в кратчайшие сроки смогут освоить новые приемы оркестровой игры и совместного музицирования, а также расширить музыкальный кругозор и исполнять музыкальные произведения в соответствии с необходимым уровнем музыкальной грамотности и стилевыми традициями.
			</p>
			<p class="NewsPage-sub-title">
				Благодаря наставничеству преподавателей за десять дней дети освоят ключевые навыки инструментального исполнительства на сцене и подготовят оркестровое дефиле.
			</p>
			<p class="NewsPage-sub-title">
				Грандиозным завершением интенсива станет участие юных музыкантов в Гала-концерте, который пройдет в кампусе Образовательного центра 22 октября.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage15(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage15(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage15(3).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
