<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Региональный конкурс
		</h2>
		<h3 class="EventsCard-title">
			«Лучшие практики работы с одаренными детьми»
		</h3>
		<p class="EventsCard-sub-title">
			c 25 февраля - 21 июня 2024 г.
		</p>
	</div>
</template>