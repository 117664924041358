<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			Открытая региональная межвузовская олимпиада обучающихся Иркутской области «Золотой фонд Сибири» 2021-2022
		</h2>
		<a class="EventsPage-btn temp-btn" href="https://irk-olimp.ru/" target="_blank">
			Подробнее
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Об олимпиаде
		</p>
		<p class="EventsPage-text">
			Открытая региональная межвузовская олимпиада обучающихся Иркутской области «Золотой фонд Сибири» (ЗФС) – уникальный проект сетевого взаимодействия вузов, основанный на использовании единой цифровой платформы и направленный на выявление, поддержку и развитие одаренных детей и перспективной молодежи Иркутской области и Забайкальского края.
		</p>
		<p class="EventsPage-text">
			Учредителем ЗФС является министерство образования Иркутской области.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Цели и задачи
		</p>
		<p class="EventsPage-text">
			Основными целями и задачами Открытой региональной межвузовской олимпиады обучающихся Иркутской области «Золотой фонд Сибири» являются:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				выявление и создание условий для поддержки одаренных обучающихся;
			</li>
			<li class="JuniorProfi-items">
				развития творческой инициативы и интереса к образовательному процессу;
			</li>
			<li class="JuniorProfi-items">
				развитие общеобразовательного кругозора учащихся;
			</li>
			<li class="JuniorProfi-items">
				пропаганда научных знаний;
			</li>
			<li class="JuniorProfi-items">
				привлечение внимания обучающихся к научной (научно-исследовательской) деятельности, к изучению технических, естественно-научных, социально-экономических, политических, правовых и культурных аспектов развития современного мира;
			</li>
			<li class="JuniorProfi-items">
				содействие воспитанию ценностных ориентаций у обучающихся;
			</li>
			<li class="JuniorProfi-items">
				создание комфортного мотивационного пространства для будущего профессионального выбора.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Открытая региональная межвузовская олимпиада обучающихся Иркутской области «Золотой фонд Сибири» входит в перечень олимпиад, направленных на развитие интеллектуальных и творческих способностей 
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7_%E2%84%96_715_%D0%BE%D1%82_11_%D0%B4%D0%B5%D0%BA%D0%B0%D0%B1%D1%80%D1%8F_2020_%D0%B3..pdf" target="_blank">
			Приказ Министерства просвещения Российской Федерации № 715 от 11.12.2020 Об утверждении перечня олимпиад, направленных на развитие интеллектуальных и творческих способностей на 2020/2021 уч. год
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/06/%D0%97%D0%A4%D0%A1-%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5.pdf" target="_blank">
			Положение об олимпиаде «Золотой фонд Сибири»
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/06/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-%D0%97%D0%A4%D0%A1.pdf" target="_blank">
			Распоряжение об утверждении Положения об олимпиаде
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/06/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7_%E2%84%96_715_%D0%BE%D1%82_11_%D0%B4%D0%B5%D0%BA%D0%B0%D0%B1%D1%80%D1%8F_2020_%D0%B3..pdf" target="_blank">
			Приказ Министерства просвещения Российской Федерации № 715 от 11.12.2020 Об утверждении перечня олимпиад, направленных на развитие интеллектуальных и творческих способностей на 2020/2021 уч. год
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/06/%D0%9F%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB-%D0%A6%D0%9E%D0%9A_3-%D0%BE%D1%82-12-%D0%BD%D0%BE%D1%8F%D0%B1%D1%80%D1%8F-2020-%D0%B3%D0%BE%D0%B4%D0%B0.pdf" target="_blank">
			Протокол ЦОК_3 от 12 ноября 2020 года
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			История олимпиады
		</p>
		<p class="EventsPage-text">
			Золотой фонд Сибири успешно реализуется с 2017 года. В первый год реализации проекта было зарегистрировано 2 тыс. участников, число вузов-организаторов – 4. Во второй год количество вузов-организаторов увеличилось до 8, а количество участников увеличилось до 4 тыс. человек.
		</p>
		<p class="EventsPage-text">
			В 2018-2019 учебном году количество проведенных предметных олимпиад составило 41. За 2 года в проекте приняло участие 5870 человек, более чем из 60 городов и населенных пунктов Сибири и Дальнего Востока. Общее количество победителей и призеров составило более 300 человек.
		</p>
		<p class="EventsPage-text">
			За каждой победой стоит огромная работа педагога-наставника. В 2018-2019 учебном году их количество достигло 72 человек.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для кого предназначена ЗФС
		</p>
		<p class="EventsPage-text">
			К участию в олимпиаде приглашаются школьники 8-11 классов, а также студенты колледжей (среднее профессиональное образование).
		</p>
		<p class="EventsPage-text">
			<b>Участие в олимпиадах СВОБОДНОЕ и БЕСПЛАТНОЕ!!!</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Что дает ЗФС
		</p>
		<p class="EventsPage-text">
			Победители получают возможность государственной поддержки в виде денежных стипендий.
		</p>
		<p class="EventsPage-text">
			Победители и призеры получают уникальную возможность получить образовательные гранты.
		</p>
		<p class="EventsPage-text">
			Победители и призеры зарабатывают дополнительные одинаковые баллы к сумме ЕГЭ при поступлении в любой вуз-организатор (баллы за индивидуальные достижения).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Вузы организаторы
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				ФГБОУ ВО «Ангарский государственный технический университет»
			</li>
			<li class="JuniorProfi-items">
				ФГБОУ ВО «Байкальский государственный университет»
			</li>
			<li class="JuniorProfi-items">
				ФГБОУ ВО «Иркутский государственный аграрный университет им. А.А. Ежевского»
			</li>
			<li class="JuniorProfi-items">
				ФГБОУ ВО «Иркутский государственный медицинский университет» Минздрава России
			</li>
		</ul>

	</div>
</template>