<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«История развития русского балета. 17-18 век»
		</h2>
		<p class="EventsPage-date">
			c 03 апреля по 08 мая 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/mod/feedback/view.php?id=1061"
			target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/24662-programma-istoriya-razvitiya-russkogo-baleta-17-18-vek"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит курсы с использованием дистанционных образовательных технологий
			<b>«История развития русского балета. 17-18 век»</b>
		</p>

		<p class="EventsPage-text">
			Программа <b>«История развития русского балета. 17-18 век»</b> направлена на развитие творческих способностей
			детей в области хореографических дисциплин, формирование знаний по истории русского хореографического искусства
			17-18 столетия, когда культура танца очень быстро, динамично и стремительно развивалась, насыщаясь разнообразием
			видов; формирование устойчивого интереса к самостоятельной деятельности в сфере хореографического искусства.
		</p>

		<p class="EventsPage-text">
			В образовательной программе могут принять участие обучающиеся в возрасте 10-17 лет образовательных организаций
			Иркутской области и участники творческих хореографических коллективов.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Намаконова Лариса Владимировна, главный балетмейстер ГБУ ДО ИО ЦРДТЮ «Узорочье»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 03 апреля 2024 года на платформе Навигатор дополнительного образования Иркутской области по
				ссылке: https://р38.навигатор.дети/program/24662-programma-istoriya-razvitiya-russkogo-baleta-17-18-vek
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и обязательно <b>заполнить АНКЕТУ</b> до 03 апреля 2024 на платформе дистанционного обучения
				Образовательного центра «Персей» в системе Moodle по ссылке:
				https://moodle.perseusirk.ru/mod/feedback/view.php?id=1061
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/-DxjgUgODcMyLg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>