<template>
	<div class="Management _cover">
		<h2 class="Management-title _cover-title">
			Руководство. Педагогический(научно-педагогический) состав
		</h2>

		<!-- Директор -->
		<div class="grid-container">
			<div class="grid-item ManagementCard">
				<div class="Management-img">
					<img loading="lazy" class="Management-img-items" src="@/assets/img/svg/User.svg" alt="Шестаков Алексей Александрович">
				</div>
				<div class="dis">
					<h3 class="Management-username">
						Шестаков Алексей Александрович
					</h3>
					<p class="Management-jobtitle">
						Директор Государственного автономного нетипового учреждения дополнительного образования Иркутской области
						"Региональный центр выявления и поддержки одаренных детей "Персей"
					</p>
					<a class="Management-tel-mail" href="tel:+73952546044">
						8 (3952) 54-60-44
					</a>
					<a class="Management-tel-mail" href="mailto:perseus@perseusirk.ru">
						perseus@perseusirk.ru
					</a>
				</div>
			</div>
		</div>

		<!-- Замы -->
		<div class="grid-container">
			<div class="grid-item ManagementCard">
				<div class="Management-img">
					<img loading="lazy" class="Management-img-items" src="@/assets/img/svg/User.svg" alt="Дмитриев Иван Георгиевич">
				</div>
				<div class="dis">
					<h3 class="Management-username">
						Дмитриев Иван Георгиевич
					</h3>
					<p class="Management-jobtitle">
						Заместитель директора
					</p>
					<a class="Management-tel-mail" href="tel:+73952546044">
						8 (3952) 54-60-44 (доб.4)
					</a>
				</div>
			</div>

			<div class="grid-item ManagementCard">
				<div class="Management-img">
					<img loading="lazy" class="Management-img-items" src="@/assets/img/svg/User.svg" alt="Егорова Юлия Сергеевна">
				</div>
				<div class="dis">
					<h3 class="Management-username">
						Егорова Юлия Сергеевна
					</h3>
					<p class="Management-jobtitle">
						Заместитель директора
					</p>
					<a class="Management-tel-mail" href="tel:+73952546044">
						8 (3952) 54-60-44 (доб.4)
					</a>
					<a class="Management-tel-mail" href="mailto:yu.egorova@perseusirk.ru">
						yu.egorova@perseusirk.ru
					</a>
				</div>
			</div>

			<div class="grid-item ManagementCard">
				<div class="Management-img">
					<img loading="lazy" class="Management-img-items" src="@/assets/img/svg/User.svg" alt="Караулова Светлана Андреевна">
				</div>
				<div class="dis">
					<h3 class="Management-username">
						Караулова Светлана Андреевна
						<br>
					</h3>
					<p class="Management-jobtitle">
						Заместитель директора
					</p>
					<a class="Management-tel-mail" href="tel:+73952546044">
						8 (3952) 54-60-44
					</a>
					<a class="Management-tel-mail" href="mailto:s.karaulova@perseusirk.ru">
						s.karaulova@perseusirk.ru
					</a>
				</div>
			</div>

		</div>

		<h2 class="Management-title _cover-title">
			Педагогический (научно-педагогический состав)
		</h2>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%A1%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BE-%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BD%D0%B8%D0%BA%D0%B0%D1%85-%D0%BD%D0%B0-06.12.2023-1.pdf"
					target="_blank">
					Сведения о педагогических работниках на 05.12.2023
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {

	}
</script>