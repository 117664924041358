<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Олимпиадное право»
		</h2>
		<p class="EventsPage-date">
			с 16 августа по 22 августа 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21385-programma-olimpiadnoe-pravo" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				Егорова Юлия Сергеевна
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				89246361877
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				perseus.irk@rikp38.ru
			</p>
		</div>
		<hr>
		<p class="EventsPage-sub-title">
			Образовательный центр «Персей» проводит профильную смену «Олимпиадное право» для учащихся общеобразовательных организаций Иркутской области с 10 до 17 лет.<br>
			Программа ориентирована на углубленное изучение права, подготовку учащихся к выполнению заданий Всероссийской олимпиады школьников и их рассмотрение с юридической точки зрения. Учащиеся, в рамках данной программы, смогут ознакомиться с заданиями олимпиады по праву прошлых лет, изучить их решение и типичные ошибки, допущенные участниками.<br>
		</p>
		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-sub-title">
			<b>Трофимова Елена Александровна</b> — педагог дополнительного образования, старший преподаватель кафедры конституционного права и теории права Юридического института ФГБОУ ВО «Иркутский государственный университет»<br>
			В 2020 по 2022 год стала наставником победителей и призеров олимпиад, входящих в Перечень Минобрнауки России.<br>
			<b>Условия участия:</b><br>
			Для участия в программе необходимо записаться на дополнительную общеразвивающую программу «Олимпиадное право», размещенную на портале «Навигатор дополнительного образования Иркутской области» <a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21385-programma-olimpiadnoe-pravo">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21385-programma-olimpiadnoe-pravo</a><br>
		</p>
	</div>
</template>