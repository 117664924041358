<template>
	<div class="MainNavigator _cover">
		<h4 class="MainNavigator-title">При выборе общеразвивающей программы или мероприятия Образовательного центра
			"Персей" необходимо пройти регистрацию на сайте: Навигатор дополнительного образования детей Иркутской области
			<a class="MainOlympics-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/"
				target="_blank">38-навигатор.дети</a>
		</h4>
		<h4 class="MainNavigator-title">
			<a class="MainOlympics-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8-%D0%BD%D0%B0%D0%B2%D0%B8%D0%B3%D0%B0%D1%82%D0%BE%D1%80.pdf"
				target="_blank">
				Инструкция по регистрации
			</a>
		</h4>
	</div>
</template>
