<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			20.08.2024
		</p>
		<h2 class="NewsPage-title">
			Театральными постановками завершилась профильная смена по «Искусству»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage78.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В августе в Образовательном центре «Персей» прошла профильная смена «От этюда к спектаклю». Участниками
				творческого образовательного интенсива стали 44 школьника из Иркутска и Иркутского района. Программа была
				рассчитана на погружение в театральную деятельность, где каждый участник получил возможность проявить свои
				таланты в разных жанрах сценического искусства. Итогом работы стали две постановки: пластический спектакль
				«Выбор» и драматический спектакль «Нежное чувство».
			</p>
			<p class="NewsPage-sub-title">
				По словам преподавателей программы, смена была насыщенной и требовала от участников не только творческой
				активности, но и огромных усилий. За две недели ребята прошли путь от идеи до полноценного спектакля. Они
				самостоятельно придумывали драматургическую основу, разрабатывали сценарии и воплощали свои задумки в этюдах,
				которые в конечном итоге стали основой для финальных постановок. Подробнее о том, как проходила подготовка и
				какие творческие задачи стояли перед участниками, читайте в нашем материале.
			</p>
			<p class="NewsPage-sub-title">
				Пластический спектакль под названием «Выбор» стал ярким примером того, как можно выразить сложные философские
				идеи через движения и пластику. Сюжет спектакля был полностью придуман детьми и касался важной темы выбора, с
				которой сталкивается каждый человек в своей жизни.
			</p>
			<p class="NewsPage-sub-title">
				– Стоит отметить, что ребята, которые приехали на смену, не занимаются хореографией. Это юные актеры, которые за
				две недели овладели основами пластического искусства, научились выражать эмоции и передавать сюжет без слов.
				Этот опыт стал для них настоящим вызовом, так как многие впервые столкнулись с необходимостью работать над
				движением, пластикой и телесным выражением чувств. Однако, благодаря упорству и творческому энтузиазму, они
				смогли создать не просто танцевальные номера, а полноценное сценическое действие, где каждый жест и каждое
				движение наполнялись смыслом и глубоким эмоциональным содержанием, – поделились ведущая актриса Иркутского
				Открытого драматического камерного театра «ALTER HOMO», балетмейстер Ярослава Рассохина.
			</p>
			<p class="NewsPage-sub-title">
				Занятия на смене были построены по типу творческой лаборатории, где каждый ребенок мог предложить идею и внести
				свой вклад в создание финальных постановок. Этот формат позволил участникам не только развить свои актерские и
				режиссерские навыки, но и почувствовать себя полноценными соавторами спектаклей. Вместе с преподавателями они
				искали уникальные решения, экспериментировали с драматургией, мизансценами и выразительными средствами, что
				сделало каждый спектакль по-настоящему уникальным и отражающим коллективное творчество всей группы.
			</p>
			<p class="NewsPage-sub-title">
				Второй спектакль, «Нежное чувство» стал не менее значимым событием смены. 30 участников активно участвовали в
				создании своих персонажей, импровизировали и искали новые подходы к своим ролям. Итогом их работы стал
				полноценный спектакль, в котором поднималась волнующая тема школьной любви.
			</p>
			<p class="NewsPage-sub-title">
				– Каждый ребенок самостоятельно продумывал своего персонажа: что он будет говорить, как вести себя на сцене, и
				каким будет его путь в рамках сюжета. Детали характера, мимика, жесты — всё это стало результатом личных
				размышлений и творческих поисков участников. Этот процесс не только развивал их актерское мастерство, но и
				позволял глубже понять внутренний мир своего героя, создать на сцене живой, достоверный образ, с которым зритель
				мог бы легко идентифицироваться, – отметила Ольга Эристави, педагог дополнительного образования, руководитель,
				театра «ALTER HOMO», член союза театральных деятелей РФ.
			</p>
			<p class="NewsPage-sub-title">
				В рамках смены для участников прошли мастер-классы и мероприятия от специалистов Медиацентра «Телешко». Также
				юные актеры прошли кастинг, и уже трое из них станут ведущими программы «38 вещей, которые нужно сделать в
				Иркутской области» – это всероссийский проект, направленный на популяризацию достопримечательностей Иркутской
				области.
			</p>
			<p class="NewsPage-sub-title">
			</p>
			<p class="NewsPage-sub-title">
				Посмотреть спектакли вы можете <a class="NewsPage-link" href="https://cloud.mail.ru/public/WJPp/msTYGjoFY">по
					ссылке</a>
			</p>

		</div>
	</div>
</template>
