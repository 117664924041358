<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			22.11.2023
		</p>
		<h2 class="NewsPage-title">
			Итоги ноябрьских научных смен подвели в «Персее»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage28.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>В Региональном центре выявления и поддержке одаренных детей «Персей» подвели итоги ноябрьских научных интенсивов «Инженеры будущего» и «Физика полетов». Участие в программах приняли 57 школьников из г. Ангарск, г. Шелехова, г. Тулун, г. Нижнеудинск, г. Усолье-Сибирское, г. Братск, г. Иркутск, г. Усть-Илимск, г. Киренск, а также ребята из Нижнеилимского, Нижнеудинского, Иркутского и Тулунского района.</b>
			</p>
			<p class="NewsPage-sub-title">
				Во время образовательной программы «Инженеры будущего» школьники работали над прототипами своих изобретений и изучали теоретические основы решения изобретательских задач.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали преподаватели смены, особое внимание в обучении уделялось на понимание механики создания стартапов, формирование необходимых инженерных, предпринимательских и универсальных навыков: коммуникация, креативное мышление, командная работа и лидерство.
			</p>
			<p class="NewsPage-sub-title">
				– Программа была насыщенна на теоретические и прикладные основы создания изобретений. Перед преподавателями стояла непростая задача: за две недели им нужно было рассказать не только теоретические основы физики, инженерии и научно-проектной деятельности, но и научить школьников применять полученные знания в работе над своими идеями. Инженер – это творческая профессия, поэтому на смене с ребятами работали и над развитием креативного мышления, а также обучали продвижению и презентации созданных изобретений, – поделилась Татьяна Толстихина, руководитель отдела развития образовательных программ по направлению «Наука».
			</p>
			<p class="NewsPage-sub-title">
				Чтобы погрузить школьников в инженерно-техническое творчество, для них были организованы встречи с выдающимися преподавателями и изобретателями региона.
			</p>
			<p class="NewsPage-sub-title">
				Так, участие в смене приняли: Наталья Лазарева – доцент, кандидат физико-математических наук, научный сотрудник института лазерной физики, которая выступила с лекциями по обработке экспериментальных данных и основам научно-исследовательской деятельности.
			</p>
			<p class="NewsPage-sub-title">
				Павел Деранжулин, исполнительный директор ассоциации Иркутского регионального объединения работодателей и Дмитрий Палютин, советник генерального директора ООО «Премьер-Энерго» рассказали об истории и современности изобретательской деятельности. А Александр Быстрицкий советник при ректорате Иркутского политеха, кандидат технологических наук поделился с ребятами, как правильно анализировать изобретательскую проблему и определять конечный результат продукта.
			</p>
			<p class="NewsPage-sub-title">
				Василий Хабардин, профессор Иркутского аграрного университета, доктор технических наук, заслуженный изобретатель РФ. Василий Николаевич рассказал участникам о практике создания изобретений и дал профессиональную оценку, советы и идеи по улучшению их прототипов.
			</p>
			<p class="NewsPage-sub-title">
				Также школьникам удалось познакомиться с изобретением, направленным на модернизацию стрелкового оружия и повышения безопасности при проведении саперных работ, и посетить экскурсию в Иркутский государственный аграрный университет имени А. А. Ежевского. Там юные изобретатели узнали больше о деятельности инженерного факультета и экспериментальной научно-исследовательской лаборатории возобновляемой энергетики.
			</p>
			<p class="NewsPage-sub-title">
				– Экскурсия в Иркутский аграрный университет – это возможность для школьников познакомиться с лабораторией, посмотреть на эксперименты с использованием имеющегося оборудования, узнать о рационализаторских и конструкторских решений от ведущих преподавателей университета, а также понять, что в регионе есть место для таких вовлеченных в науку и технологии ребят, где они могут получить достойное образование и воплотить свои самые смелые инженерные решения в жизнь, – отметил Максим Герьятович, инженер сектора проектирования первичного оборудования ООО «ИЦ «ЕвроСибЭнерго».
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена «Физика полета» проходила в Образовательном центре во второй раз. На программе ребята изучали авиамоделирование и беспилотный транспорт. Изготавливали модели летательных аппаратов, собирали квадрокоптеры и состязались в дрон-рейсинге – гонке на мини дронах и на компьютерных симуляторах.
			</p>
			<p class="NewsPage-sub-title">
				– Беспилотник может с воздуха увидеть больше, чем человек с земли. Например, оперативно зафиксировать ЧС или найти в лесном массиве потерявшегося человека. Это намного эффективнее, чем поднимать вертолет в небо. Поэтому беспилотная авиация – одно из самых перспективных направлений в нашей стране и за рубежом. Дроны набирают все большую популярность среди подростков. На этой смене мы учились не только запускать и управлять дронам, но собирать и программировать их. Я учил школьников собирать уникальные дроны, не похожие на все остальные. Итогом смены были соревнования по сборке и дрон-рейсингу. Многие ребята показали высокий результат по сборке, гонкам и пилотированию. Очень было приятно работать с такими вовлеченными детьми и вместе с ними делать первые шаги в беспилотной авиации, – отметил Алексей Изосимов, преподаватель-тренер Иркутского центра технического творчества «СИГМА».
			</p>
			<p class="NewsPage-sub-title">
				За время смены ребята тренировались в пилотировании дронов от первого лица (в специальных очках) и визуально (наблюдая за коптером). Учились преодолевать препятствия, а также разучивали трюки.
			</p>
			<p class="NewsPage-sub-title">
				Следующая смена по авиамоделированию и беспилотному транспорту запланирована на весну 2024 года. Участие в ней также смогут принять школьники Иркутской области в возрасте 14-17 лет.
			</p>
			
			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage28(1).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
