<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-3">
			Еженедельные курсы
		</h2>
		<h3 class="EventsCard-title">
			«Театральная мастерская»
		</h3>
		<p class="EventsCard-sub-title">
			с 03 июня по 12 июля 2024 года
		</p>
	</div>
</template>