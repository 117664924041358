<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Дистанционный курс
		</h2>
		<h3 class="EventsCard-title">
			«Биология. Интенсивный учебный модуль»
		</h3>
		<p class="EventsCard-sub-title">
			с 16 сентября по 14 октября 2024 года
		</p>
	</div>
</template>