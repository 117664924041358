<template>
	<div class="JuniorProfi _cover">
		<h2 class="JuniorProfi-title _cover-title">
			«Конкурсные мероприятия в рамках VII регионального чемпионата компетенций ЮниорПрофи»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 5 декабря по 8 декабря 2023 года
		</h4>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Конкурсные мероприятия в рамках VII регионального чемпионата компетенций ЮниорПрофи» для обучающихся 10-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа направлена на создание новых возможностей для демонстрации школьниками уровня освоения профессиональных компетенций, формирования экспертного сообщества и развития общероссийского движения ЮниорПрофи.
		</p>

		<p class="JuniorProfi-sub-title">
			Чемпионат проводится по следующим компетенциям:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Прототипирование (возрастная группа 14+);
			</li>
			<li class="JuniorProfi-items">
				Агрономия (возрастная группа 14+);
			</li>
			<li class="JuniorProfi-items">
				Ветеринария (возрастная группа 14+);
			</li>
			<li class="JuniorProfi-items">
				Сити-фермерство (возрастная группа 14+);
			</li>
			<li class="JuniorProfi-items">
				Мобильная робототехника (возрастная группа 10+);
			</li>
			<li class="JuniorProfi-items">
				Мультимедиа коммуникации (возрастная группа 14+);
			</li>
			<li class="JuniorProfi-items">
				Электроника (возрастная группа 14+);
			</li>
			<li class="JuniorProfi-items">
				Инженерный дизайн (возрастная группа 14+).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Для участия в профильной смене необходимо записаться на платформе «Навигатор дополнительного образования Иркутской области». <a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/23924-programma-konkursnye-meropriyatiya-viii-chempionata-yuniorprofi">Навигатор</a>
		</p>
		<p class="EventsPage-text">
			Профильная смена состоится с 5 по 8 декабря 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна 
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru 
			</li>
		</ul>

	</div>
</template>