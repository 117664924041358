<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Весенняя интеллектуальная школа»
		</h2>
		<p class="EventsPage-date">
			с 27 февраля по 11 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24610-programma-vesennyaya-intellektualnaya-obshcherazvivayushchaya-shkola" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Весенняя интеллектуальная школа» для учащихся 5-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа позволяет учащимся ознакомиться со многими интересными вопросами биологии, физики и химии, которые способствуют расширению и углублению знаний. Важным фактором реализации данной программы является стремление развивать у учащихся умения самостоятельно работать, думать, решать творческие задачи, а также совершенствовать навыки аргументации собственной позиции по определенному вопросу. Работа в команде даёт хороший опыт сотрудничества. Содержание программы соответствует познавательным возможностям школьников и предоставляет им возможность работать на уровне повышенных требований, развивая учебную мотивацию при использовании игровые формы, потому что они помогают решать серьёзные учебные проблемы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/lCPXqDHFCyx8kg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>