<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Художественное слово»
		</h2>
		<p class="EventsPage-date">
			с 13 мая по 5 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24570-programma-khudozhestvennoe-slovo"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр
			выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого
			ребёнка» проводит дистанционный курс «Художественное слово с 13 мая по 5 июня 2024 года для учащихся 4-11-х
			классов общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Программа направлена на ознакомление со спецификой литературного слова, узнают, чем отличается художественный
			текст от других видов текста, попрактикуются в написании текстов на разные темы, попробуют себя в литературной
			правке.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Дмитриева Людмила Владимировна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Егорова Вероника Вадимовна, учитель начальных классов МБОУ г. Иркутска СОШ № 77
			</li>
			<li class="JuniorProfi-items">
				Карасёва Алёна Юрьевна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Кожевникова Екатерина Александровна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Кондратьева Валентина Александровна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Некрасова Светлана Геннадьевна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Непомнещая Анна Валерьевна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Романчук Марина Владимировна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Федорова Эльвира Константиновна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Филиппова Алена Александровна, учитель начальных классов МБОУ г. Иркутска СОШ № 77;
			</li>
			<li class="JuniorProfi-items">
				Шарова Надежда Алексеевна, учитель начальных классов МБОУ г. Иркутска СОШ № 77.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 13 апреля 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/k9K4/BKmJgCEfr" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>