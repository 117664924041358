<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			25.09.2023
		</p>
		<h2 class="NewsPage-title">
			В «ПЕРСЕЕ» ПОДВЕЛИ ИТОГИ ТРЕТЬЕЙ ПРОГРАММЫ ДЛЯ ОБУЧАЮЩИХСЯ АГРОШКОЛ ПРИАНГАРЬЯ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage6.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одаренных детей «Персей» прошла третья профильная смена для обучающихся агрошкол Иркутской области. Участие в программе приняли более 70 школьников из г. Ангарска, г. Байкальска, Балаганского, Боханского, Братского, Заларинского, Иркутского, Казачинско-Ленского, Качугского, Нукутского, Тайшетского, Тулунского, Усольского района, Черемховского районов.
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы смены, в этом году отбор проходил в формате учебно-отборочного курса, на котором учащиеся изучали основы экономики, финансовой грамотности и предпринимательства. Ученики, набравшие наибольшее количество баллов, стали участниками профильной смены «Рачительный хозяин земли».
			</p>
			<p class="NewsPage-sub-title">
				На смене ребята познакомились с современными технологиями в области сельского хозяйства, включая обработку почвы, предпосадочную подготовку семян, уход за растениями, ветеринарию, селекцию и генетику, а также управление финансами и бизнес-процессами.
			</p>
			<p class="NewsPage-sub-title">
				– В идею обучения заложено то, чтобы школьники получили актуальные знания и умения в сфере агробизнес-технологий, которые впоследствии они смогут внедрить в своей школе, что поможет им оптимизировать работу на пришкольных участках. Помимо этого, программа способствует профессиональному самоопределению и вовлекает молодых людей в предпринимательскую деятельность в агропромышленном комплексе региона, – рассказал руководитель Образовательного центра «Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				На программе со школьниками работали ведущие преподаватели Иркутского государственного аграрного университета имени А.А. Ежевского, Байкальского государственного университета, Иркутского гидрометеорологического техникума, Иркутского аграрного техникума. Сотрудники благотворительного фонда «Подари планете жизнь», филиала «Рослесозащита» Центра защиты леса Иркутской области, а также преподаватели Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				В рамках профильной смены для ребят также прошли встречи с представителями сельхозпроизводителей Иркутской области. По итогам обучения школьники представили свои бизнес-проекты, которые они разработали во время профильной смены.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, обучение в Образовательном центре «Персей» по программе «Рачительный хозяин земли» проходило в рамках Концепции развития непрерывного агробизнес-образования на сельских территориях Иркутской области на 2021-2025 годы. Концепция была утверждена министром образования Иркутской области Максимом Парфеновым и министром сельского хозяйства Иркутской области Ильей Сумароковым.
			</p>
		</div>
	</div>
</template>
