<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			27.10.2023
		</p>
		<h2 class="NewsPage-title">
			Работы юных художниц Приангарья представлены на выставке в Сочинском художественном музее
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage21.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Сочинском художественном музее имени Д.Д. Жилинского открылась выставка «Созвездие молодых талантов». В экспозиции представлены работы участников, которые приезжали на программы Образовательного центра «Сириус» по изобразительному искусству и дизайну.
			</p>
			<p class="NewsPage-sub-title">
				Ежемесячно в Образовательном центре «Сириус» проходят программы по живописи, скульптуре, анималистике, гончарному искусству и дизайну. Лучшие работы школьников отбираются в фонд «Сириуса» и выставляются на тематических экспозициях.
			</p>
			<p class="NewsPage-sub-title">
				В этом году участницами программы стали Лидия Бородина, выпускница Детской школы искусств г.Усть-кут, и Марина Гамзулин, выпускница Детской школы искусств № 5 г.Иркутска. Жители и гости музея оценили натюрморты и портреты, созданные школьницами на программах по масляной живописи, которая курируется Санкт-Петербургской академией художеств имени Ильи Репина.
			</p>
			<p class="NewsPage-sub-title">
				«Выставка демонстрирует результат уникальной отечественной академической системы обучения, основанной на глубоких традициях. Представленные на экспозиции произведения юных художников в разных жанрах позволяют увидеть становление будущих мастеров изобразительного искусства страны», – рассказали эксперты «Сириуса».
			</p>
			<p class="NewsPage-sub-title">
				«Состав экспозиции из года в год становится все более интересным и разнообразным – это и уже традиционная школа многослойной акварели, и академический рисунок, и скульптура, станковая живопись, керамика. Работы юных художников со всей России всегда вызывают заслуженный положительный отклик наших посетителей. В этом году мы предоставили под выставку самые большие залы музея, чтобы у организаторов появилась возможность максимально полно представить весь срез программ изобразительного искусства, а у гостей и жителей Краснодарского края – возможность познакомиться с результатами уникальной системой творческого образования в “Сириусе”», – пояснил заведующий выставочным отделом Сочинского художественного музея Борис Салахов.
			</p>
			<p class="NewsPage-sub-title">
				Выставки работы юных художников «Сириуса» проходят в Сочинском художественном музее с 2016 года. Такое сотрудничество помогает ребятам пополнить профессиональное портфолио и подготовиться к работе художником.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage21(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage21(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage21(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage21(5).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
