<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			«Всероссийская олимпиада школьников» 2022-2023г.
		</h2>

		<a class="EventsPage-btn temp-btn" href="https://starsite.perseusirk.ru/%d0%bf%d0%be%d0%b4%d1%80%d0%be%d0%b1%d0%bd%d0%b5%d0%b5-%d0%be-%d1%88%d0%ba%d0%be%d0%bb%d1%8c%d0%bd%d0%be%d0%bc-%d1%8d%d1%82%d0%b0%d0%bf%d0%b5-%d0%b2%d1%81%d0%b5%d1%80%d0%be%d1%81%d1%81%d0%b8/" target="_blank">
			Подробнее о школьном этапе
		</a>
		<a class="EventsPage-btn temp-btn" href="https://starsite.perseusirk.ru/kontakty-kord-irk-obl-vsoh/" target="_blank">
			Контакты
		</a>
		<a class="EventsPage-btn temp-btn" href="https://starsite.perseusirk.ru/podgotovka-vsoh-2022-2023%d0%b3/" target="_blank">
			Подготовка к олимпиаде
		</a>
		<a class="EventsPage-btn temp-btn" href="http://р38.навигатор.дети/activity/660/?date=2022-10-18" target="_blank">
			Региональный этап
		</a>
		<hr />

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Организатор заключительного этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Министерство образования и науки Российской Федерации
			</li>
			<p class="EventsPage-text">
				Организатор регионального этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор регионального этапа олимпиады:
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Нормативно-правовые документы Министерства просвещения Российской Федерации
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			
		</a>
		<a class="EventsPage-link" href="" target="_blank">
			
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Региональный этап
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Приказ №678 от 27.11.20 Об утверждении Порядка проведения ВсОШ
		</a>
		<a class="EventsPage-link" href="" target="_blank">
			Приказ Министерства просвещения №740 от 16.08.22 О внесении изменений в состав ЦПМК ВСОШ
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Муниципальный этап
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96678-%D0%BE%D1%82-27.11.20-%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D1%81%D0%9E%D0%A8.pdf" target="_blank">
			Изм. сроков 13-12-2022
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/11/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%9C%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%B0-%D0%BF%D1%80%D0%BE%D1%81%D0%B2%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F-%E2%84%96740-%D0%BE%D1%82-16.08.22-%D0%9E-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D1%81%D0%BE%D1%81%D1%82%D0%B0%D0%B2-%D0%A6%D0%9F%D0%9C%D0%9A-%D0%92%D0%A1%D0%9E%D0%A8.pdf" target="_blank">
			Распоряжение о РЭ ВсОШ 2022-23
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/12/%D0%98%D0%B7%D0%BC.-%D1%81%D1%80%D0%BE%D0%BA%D0%BE%D0%B2-13-12-2022.pdf" target="_blank">
			Приказ Министерства просвещения №898 от 10.10.22 График РЭ ВсОШ 2022-23 г.
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/12/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%A0%D0%AD-%D0%92%D1%81%D0%9E%D0%A8-2022-23.pdf" target="_blank">
			Требования к РЭ ВсОШ 2022-23
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/12/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BC%D0%B8%D0%BD.%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9E-%D0%BF%D1%80%D0%BE%D1%85%D0%BE%D0%B4%D0%BD%D1%8B%D1%85-%D0%B1%D0%B0%D0%BB%D0%BB%D0%B0%D1%85.pdf" target="_blank">
			Распоряжение мин.образования О проходных баллах
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/03/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BC%D0%B8%D0%BD.-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%98%D1%80%D0%BA.-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D0%A0%D0%AD-%D0%92%D0%A1%D0%9E%D0%A8-2022-2023-%D0%B3%D0%BE%D0%B4%D0%B0.pdf" target="_blank">
			Приказ мин. образования Ирк. области Об итогах РЭ ВСОШ 2022-2023 года
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Муниципальный этап
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/10/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BC%D0%B8%D0%BD.%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%98%D1%80%D0%BA.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%E2%84%9655-1575-%D0%BE%D1%82-24.10.22-%D0%9E-%D0%9C%D0%AD-%D0%92%D0%A1%D0%9E%D0%A8.pdf" target="_blank">
			Распоряжение мин.образования Ирк.области №55-1575 от 24.10.22 О МЭ ВСОШ
		</a>
		<a class="EventsPage-link" href="https://vserosolimp.edsoo.ru/municipal_way" target="_blank">
			Консультационные вебинары по организации и проведению ШЭ и МЭ ВСОШ в 2022-2023 учебного года 
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Школьный этап
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/08/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5-%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%BA-%D0%A8%D0%B8%D0%9C%D0%AD-%D0%92%D1%81%D0%9E%D0%A8-2022-23.pdf" target="_blank">
			Методические рекомендации к ШиМЭ ВсОШ 2022-23
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/08/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%BC%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%B0-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%E2%84%96-02-55-8903-22-%D0%BE%D1%82-22.08.22-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%92%D0%A1%D0%9E%D0%A8.pdf" target="_blank">
			Письмо министерства образования № 02-55-8903-22 от 22.08.22 О проведении ВСОШ
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-02-55-9551-22-%D0%BE%D1%82-02.09.22-%D0%9E-%D0%B2%D0%B5%D0%B1%D0%B8%D0%BD%D0%B0%D1%80%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-%D0%B4%D0%BB%D1%8F-%D0%BC%D1%83%D0%BD%D0%B8%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BA%D0%BE%D0%BE%D1%80%D0%B4%D0%B8%D0%BD%D0%B0%D1%82%D0%BE%D1%80%D0%BE%D0%B2.pdf" target="_blank">
			Письмо 02-55-9551-22 от 02.09.22 О вебинаре ВСОШ для муниципальных координаторов
		</a>
		<a class="EventsPage-link" href="https://youtu.be/gk2Zs0EZz5g" target="_blank">
			Запись вебинара с муниципальными координаторами от 08.09.22
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/09/%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F-%D0%BA-%D0%B2%D0%B5%D0%B1%D0%B8%D0%BD%D0%B0%D1%80%D1%83-%D1%81-%D0%BC%D1%83%D0%BD.-%D0%BA%D0%BE%D0%BE%D1%80%D0%B4%D0%B8%D0%BD%D0%B0%D1%82%D0%BE%D1%80%D0%B0%D0%BC%D0%B8-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BE%D1%82-08.09.22.pdf" target="_blank">
			Презентация к вебинару с мун. координаторами ВСОШ от 08.09.22
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Отчеты
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/11/%D0%90%D0%BD%D0%B0%D0%BB%D0%B8%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B8%CC%86-%D0%BE%D1%82%D1%87%D0%B5%D1%82-%D0%BE-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BD%D0%B0-%D1%82%D0%B5%D1%80%D1%80%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8-%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B8%CC%86-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8-%D0%92%D0%A1%D0%9E%D0%A8-2021-2022.pdf" target="_blank">
			Аналитический отчет о проведении на территории Иркутской области ВСОШ 2021-2022
		</a>
		<hr />

				<p class="JuniorProfi-sub-title">
			Всероссийская олимпиада школьников — крупнейшее интеллектуальное соревнование, в котором ежегодно принимают участие более 6 млн школьников.
		</p>
		<p class="EventsPage-text">
			Олимпиада проводится по общеобразовательным предметам: математика, русский язык, иностранный язык (английский, немецкий, французский, испанский, китайский), информатика, физика, химия, биология, экология, география, астрономия, литература, история, обществознание, экономика, право, искусство, физическая культура, технология, основы безопасности жизнедеятельности.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			ШКОЛЬНЫЙ ЭТАП (сентябрь-октябрь)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 5-11 классов (по всем предметам), для 4 класса (по предметам математика и русский язык);
			</li>
			<li class="JuniorProfi-items">
				к участию приглашаются все желающие обучающиеся;
			</li>
			<li class="JuniorProfi-items">
				формат проведения: по предметам физика, биология, химия, астрономия, математика и информатика – дистанционно, на платформе Сириус.Курсы.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			МУНИЦИПАЛЬНЫЙ ЭТАП (ноябрь-декабрь)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 7-11 классов;
			</li>
			<li class="JuniorProfi-items">
				приглашаются:
			</li>
			<li class="JuniorProfi-items">
				— участники школьного этапа текущего учебного года, набравшие необходимое для участия количество баллов, установленное министерством образования Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				— победители и призеры муниципального этапа олимпиады предыдущего учебного года, продолжающие освоение основных образовательных программ основного общего и среднего общего образования.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			РЕГИОНАЛЬНЫЙ ЭТАП (январь-февраль)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 9-11 классов;
			</li>
			<li class="JuniorProfi-items">
				приглашаются:
			</li>
			<li class="JuniorProfi-items">
				— участники муниципального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество баллов, установленное министерством образования Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				— победители и призеры регионального этапа олимпиады предыдущего учебного года, продолжающие освоение основных образовательных программ основного общего и среднего общего образования.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			ЗАКЛЮЧИТЕЛЬНЫЙ ЭТАП (март-апрель)
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				для 9-11 классов;
			</li>
			<li class="JuniorProfi-items">
				приглашаются:
			</li>
			<li class="JuniorProfi-items">
				— участники регионального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество баллов, установленное Министерством Просвещения Российской Федерации;
			</li>
			<li class="JuniorProfi-items">
				— победители и призеры заключительного олимпиады предыдущего учебного года, продолжающие освоение основных образовательных программ начального общего, основного общего и среднего общего образования.
			</li>
		</ul>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			ЛЬГОТЫ ПОБЕДИТЕЛЯМ И ПРИЗЕРАМ ВСОШ
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Главная льгота, которую предоставляет победа или призерство на заключительном этапе всероссийской олимпиады школьников, – это поступление в профильные вузы без экзаменов. Принимаются результаты с 9 по 11 класс. Если победитель поступает на непрофильную специальность, по решению вуза ему могут зачесть 100 баллов за ЕГЭ по предмету, соответствующему профилю олимпиады (соответствие направления и профиля олимпиады определяет сам вуз).
			</li>
			<li class="JuniorProfi-items">
				Для победителей и призеров регионального этапа ВСОШ есть возможность получения баллов индивидуальных достижений, дополнительно к сумме баллов ЕГЭ. Порядок учета и перечень индивидуальных достижений устанавливается каждым вузом самостоятельно.
			</li>
		</ul>
		<hr>

	</div>
</template>
