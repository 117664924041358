<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Региональные сборы по подготовке проектов по направлению «Агропромышленные и биотехнологии»
		</h2>
		<p class="EventsPage-date">
			с 13 июня по 14 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href=" https://moodle.perseusirk.ru/course/view.php?id=170" target="_blank">
			Moodle
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит еженедельный курс «Региональные сборы по подготовке проектов по
			направлению «Агропромышленные и биотехнологии» для учащихся 8-10-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Задачи курса установка на проведение исследования учащихся - выбор тем, планирование задач, пошаговое описание
			проведения лабораторных экспериментов и сами эксперименты, консультации экспертов, отбор на профильную смену.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету на платформе ОЦ «Персей», до 12 июня 2024 года на платформе обучения
				Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Еженедельный курс состоится 13 июня и 14 июня 2024 года на бесплатной основе в Образовательном центре «Персей»
				(г. Иркутск, ул. Рабочая штаба 19 а).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>