<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Современная энергетика»
		</h2>
		<p class="EventsPage-date">
			С 7 февраля по 20 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn----7sbih0agcjgfbqey0h8clv.xn--p1ai/accounts/login/" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24511-programma-sovremennaya-energetika" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Современная энергетика» для учащихся 8-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Энергетика – стратегически важная составляющая для обеспечения жизни каждого человека и государства в целом. Программа дает возможность сформировать у учащихся теоретические и практические знания о физических процессах и явлениях, положенных в основу функционирования энергетических объектов, об особенностях образовательных организаций, занимающихся подготовкой специалистов для энергетической отрасли, о работе энергетических предприятий Иркутской области.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Пройти регистрацию в ЭнергоШколу Эн+: энергошкола-энплюс.рф.
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Заполнить анкету на участие в конкурсе в энергосмене. 
			</li>
		</ul>

		<a class="EventsPage-link" href="https://docs.google.com/forms/d/e/1FAIpQLSd0ATsnMf5uknGeHrWTCXX7q7dk0lc9CbRY-Mb7QyyrJ6DI9A/viewform" target="_blank">
			Анкета
		</a>

		<p class="EventsPage-text">
			<b>Заявки принимаются до 24 января. Результаты отбора опубликуем до 29 января (включительно).</b>
		</p>
		<p class="EventsPage-text">
			По всем вопросам: в сообщения группы Вконтакте или в телеграмме @en_sch.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Списочный состав учащихся, приглашённых на очную профильную смену:
		</p>

		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Абатурова Виктория Юрьевна</li>
			<li class="EventsPage-number">Андрианова Ольга Сергеевна</li>
			<li class="EventsPage-number">Антонова Арина Вячеславовна</li>
			<li class="EventsPage-number">Боленко Арина Павловна</li>
			<li class="EventsPage-number">Бугдаева Александра Сергеевна</li>
			<li class="EventsPage-number">Власов Иван Денисович</li>
			<li class="EventsPage-number">Гаврилова Софья Андреевна</li>
			<li class="EventsPage-number">Голяк Ирина Викторовна</li>
			<li class="EventsPage-number">Городецкий Алексей Павлович</li>
			<li class="EventsPage-number">Горошко Анфиса Сергеевна</li>
			<li class="EventsPage-number">Донцов Данил Иванович</li>
			<li class="EventsPage-number">Дружинин Дмитрий Максимович</li>
			<li class="EventsPage-number">Иванов Антон Дмитриевич</li>
			<li class="EventsPage-number">Иванов Роман Алексеевич</li>
			<li class="EventsPage-number">Казак Илона Алексеевна</li>
			<li class="EventsPage-number">Клименко Анна Александровна</li>
			<li class="EventsPage-number">Климентьев Андрей Иванович</li>
			<li class="EventsPage-number">Кобелев Илья Алексеевич</li>
			<li class="EventsPage-number">Куручова Тасним Женишбеквона</li>
			<li class="EventsPage-number">Лесникова Анастасия Денисовна</li>
			<li class="EventsPage-number">Малофеев Вячеслав Артемович</li>
			<li class="EventsPage-number">Марченко Мария Александровна</li>
			<li class="EventsPage-number">Мельникова Ирина Максимовна</li>
			<li class="EventsPage-number">Микрюкова Зоя Сергеевна</li>
			<li class="EventsPage-number">Михеева Полина Артемовна</li>
			<li class="EventsPage-number">Непомнящая Арина Сергеевна</li>
			<li class="EventsPage-number">Никитенко Дмитрий Викторович</li>
			<li class="EventsPage-number">Осодоева Динара Сергеевна</li>
			<li class="EventsPage-number">Павленко Елизавета Андреевна</li>
			<li class="EventsPage-number">Пальцева Елена Николаевна</li>
			<li class="EventsPage-number">Пантаева Вероника Игоревна</li>
			<li class="EventsPage-number">Пашковская Мария Александровна</li>
			<li class="EventsPage-number">Подрез Виктория Михайловна</li>
			<li class="EventsPage-number">Подтаева Маргарита Александровна</li>
			<li class="EventsPage-number">Ратовский Олег Александрович</li>
			<li class="EventsPage-number">Семейкина Анна Алексеевна</li>
			<li class="EventsPage-number">Сизых Алина Николаевна</li>
			<li class="EventsPage-number">Струмеляк Софья Романовна</li>
			<li class="EventsPage-number">Таипов Михаил Валерьевич</li>
			<li class="EventsPage-number">Ташлыков Михаил Владимирович</li>
			<li class="EventsPage-number">Устинова Елизавета Константиновна</li>
			<li class="EventsPage-number">Устюгова Доминика Аркадьевна</li>
			<li class="EventsPage-number">Чебыкин Максим Денисович</li>
			<li class="EventsPage-number">Черемисин Даниил Александрович</li>
			<li class="EventsPage-number">Янова Яна Игоревна</li>
		</ol>
		<br>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 1 февраля 2024 г. в 20.00, ссылка на подключение https://pruffme.com/landing/Perseus/tmp1706081331 <a class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1706081331" target="_blank">https://pruffme.com/landing/Perseus/tmp1706081331</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/RmrR/mfiMnYPi5" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/t6Jr4EbQJkvNcA" target="_blank">
			Сертификаты
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>