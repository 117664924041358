<template>
	<div class="JuniorProfiChampionshipPage _cover">
		<h2 class="JuniorProfiChampionshipPage-title _cover-title">
			Чемпионат ЮниорПрофи
		</h2>
	</div>

		<!-- Архив -->
		<ArchiveJuniorProfiChampionship />
		
</template>

<script>
import ArchiveJuniorProfiChampionship from '@/components/ArchiveJuniorProfiChampionship.vue';

export default {
	components: {
		ArchiveJuniorProfiChampionship,
	}
}

</script>