<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Школа безопасности. Основы безопасности жизнедеятельности»
		</h2>
		<p class="EventsPage-date">
			Обучение проводится с 27 сентября по 8 октября 2023
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22491-shkola-bezopasnosti-osnovy-bezopasnosti-zhiznedeyatelnosti" target="_blank">
			Регистрация
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<p class="EventsPage-nte">
				Контактное лицо: Егорова Юлия Сергеевна<br>
				Телефон: 89246361877<br>
				Эл. почта: yulcha_23@mail.ru<br>
			</p>
			
			<hr>
			<h3 class="EventsPage-nte-dis">
				Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Школа безопасности.<br>
				Основы безопасности жизнедеятельности» для обучающихся 8-10-х классов общеобразовательных организаций.<br>
				Программа смены направлена на формирование у учащихся сознательного и ответственного отношения к вопросам личной и общественной безопасности, получение ими практических навыков и умений поведения в экстремальных ситуациях, стремления к здоровому образу жизни, совершенствование морально-психологического состояния и физического развития подрастающего поколения.<br>
				Профильная смена состоится <b>с 27 сентября по 10 октября 2023 года</b>, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).<br>
			</h3>
			<hr>

				<h2 class="EventsPage-prof">
					Документы
				</h2>
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/GebMvXZFF4_rQQ" target="_blank">
					Сертификаты
				</a>
		</div>
	</div>
</template>