<template>
	<div class="SchoolStage _cover">
		<h2 class="SchoolStage-title _cover-title">«Школьный этап» 2023-2024г.</h2>
		<a class="temp-btn" @click="$router.push({ name: 'AllRussian' })"> ВСОШ </a>
		<hr />
		<h3 class="ContactsofOlympiad-sub-title">Информация для муниципальных координаторов:</h3>
		<p class="ContactsofOlympiad-dist">Запись вебинара по организации ВСОШ, 6 сент.</p>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://youtu.be/Gb-QKs79D7E"> https://youtu.be/Gb-QKs79D7E </a>
		</p>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://starsite.perseusirk.ru//wp-content/uploads/2023/09/6-%D1%81%D0%B5%D0%BD%D1%82.-2023-%D0%BF%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F.pdf"> 6 сент. 2023 презентация </a>
		</p>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://vserosolimp.edsoo.ru/school_way"> Школьный этап Методические рекомендации к школьному и муниципальному этапам ВсОШ 2023-24 (по предметам) </a>
		</p>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://siriusolymp.ru/school2023/4"> График школьного этапа </a>
		</p>
		<h3 class="ContactsofOlympiad-sub-title">Информация для участников:</h3>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<thead>
					<tr>
						<th>Предмет</th>
						<th>Класс</th>
						<th>Дата</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Физика</td>
						<td>7–11 класс</td>
						<td>29 сентября</td>
					</tr>
					<tr>
						<td>Астрономия</td>
						<td>5–11 класс</td>
						<td>3 октября</td>
					</tr>
					<tr>
						<td>Химия</td>
						<td>7–11 класс</td>
						<td>6 октября</td>
					</tr>
					<tr>
						<td>Биология</td>
						<td>5–11 класс</td>
						<td>13 октября</td>
					</tr>
					<tr>
						<td>Математика</td>
						<td>4–6 класс</td>
						<td>20 октября</td>
					</tr>
					<tr>
						<td>Математика</td>
						<td>7–11 класс</td>
						<td>19 октября</td>
					</tr>
					<tr>
						<td>Информатика</td>
						<td>5–11 класс</td>
						<td>27 октября</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D0%B0%D0%BC%D1%8F%D1%82%D0%BA%D0%B0-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA%D0%B0.pdf"> Памятка участника </a>
		</p>
		<h3 class="ContactsofOlympiad-sub-title">Плакаты:</h3>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%A8%D0%AD-2023_%D0%9F%D0%BB%D0%B0%D0%BA%D0%B0%D1%82-%D0%904_%D0%93%D1%804_%D1%86%D0%B2-1.pdf"> ШЭ 2023_Плакат А4_Гр4_цв </a>
		</p>
		<p class="ContactsofOlympiad-dist">
			<a style="color: blue" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%A8%D0%AD-2023_%D0%9F%D0%BB%D0%B0%D0%BA%D0%B0%D1%82-%D0%904_%D0%93%D1%804_%D1%87%D0%B1-1.pdf"> ШЭ 2023_Плакат А4_Гр4_чб </a>
		</p>
	</div>
</template>
