<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			21.11.2023
		</p>
		<h2 class="NewsPage-title">
			«Сириус» объявляет о старте девятого сезона конкурса «Большие вызовы»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage2.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>Стартовал девятый сезон Всероссийского научно-технологического конкурса «Большие вызовы» Образовательного центра «Сириус». Принять участие в конкурсе могут школьники 7–11-х классов и студенты 1–2-х курсов колледжей и техникумов из субъектов Российской Федерации, а также из стран СНГ.</b>
			</p>
			<p class="NewsPage-sub-title">
				<b>Конкурс «Большие вызовы»</b> — это главный научно-технологический проект России. Он позволяет школьникам разработать собственные проекты под руководством наставников — педагогов, представителей региональных центров и вузов. Конкурс проводится по <a class="NewsPage-link" href="https://konkurs.sochisirius.ru/#tracks">направлениям</a> научной деятельности, отвечающей стратегии научно-технологического развития России (космические технологии, генетика и биомедицина, агротехнологии, искусственный интеллект, умный город, беспилотный транспорт и другие). Участникам нужно оформить свои разработки и исследования в проект по одному из направлений и презентовать его экспертам.
			</p>
			<p class="NewsPage-sub-title">
				Благодаря конкурсу школьники получают возможность попробовать себя в проектной деятельности и провести полный цикл исследований.
			</p>
			<p class="NewsPage-sub-title">
				В прошлом году в финал конкурса прошли Тимофей Никонов и Андрей Запорожских из Братского лицея № 2. Они подготовили проекты в направлениях «Агропромышленные и биотехнологии» и «Современная энергетика». Тимофей представил проект по перспективам применения криопротекторов в районах рискованного земледелия России, а Андрей разработал радиоизотопный термоэлектрический генератор нового поколения. Елизавета Пономарева из Шелеховского лицея в направлении «Генетика и биомедицина» представила проект «Влияние табачных изделий на микрофлору полости рта». Школьники стали призерами конкурса и приехали на очную программу «Большие вызовы».
			</p>
			<p class="NewsPage-sub-title">
				Победители конкурса становятся участниками <b>очной программы «Большие вызовы»</b>, которая ежегодно проходит в июле в «Сириусе». В течение 24 дней в лабораториях и научных классах «Сириуса» школьники <a class="NewsPage-link" href="https://bigchallenges.ru/news">реализуют проекты</a> под руководством инженеров, ученых, представителей крупнейших российских компаний. Партнеры «Сириуса» предлагают учащимся включиться в решение реальных задач, стоящих перед компаниями, и поработать наравне с профессиональными инженерами и учеными.
			</p>
			<p class="NewsPage-sub-title">
				Например, Андрей Запорожских на программе в «Сириусе» реализовывал проект в области геотермальной энергетики, а именно работал над созданием макета второй очереди Мутновской геотермальной электростанций (ГеоЭС) на Камчатке. Тимофей Никитин решал задачу, связанную с проблемами агропромышленности и биотехнологий. Проект, в котором он принимал участие, был направлен на маркер-ориентированную селекцию садовых роз и поиска генетических маркеров устойчивости их к чёрной пятнистости (грибное заболевание), что в перспективе позволило получить сорта, устойчивые к заболеванию в результате селекции. А Елизавета Пономарева вместе с командой стремилась к повышению чувствительности ПЦР с помощью праймеров с фосфорилгуанидиновыми модификациями, которые отвечают за для выявления мутаций в генах и различных тяжёлые заболеваний, например, онкологию.
			</p>
			<p class="NewsPage-sub-title">
				В прошлом сезоне конкурса «Большие вызовы» заявки на участие подали более 12,5 тыс. человек из 83 регионов Российской Федерации, а также из стран ближнего зарубежья.
			</p>
			<p class="NewsPage-sub-title">
				Очный этап отбора пройдет в «Персее» по шести направлениям: «Агропромышленные и биотехнологии», «Большие данные, искусственный интеллект, финансовые технологии и машинное обучение», «Космические технологии», «Современная энергетика». «Умный город и безопасность», и «Беспилотный транспорт и логистические системы». Отбор будет проходить на профильной смене в кампусе Образовательного центра. Подать заявку на участие в программе можно до 1 декабря 2023 года <a class="NewsPage-link" href="https://perseusirk.ru/ProfileBlock/EventsPage26">по ссылке</a>
			</p>
			<p class="NewsPage-sub-title">
				По направлениям: «Беспилотный транспорт и логистические системы», «Освоение Арктики и Мирового океана», «Генетика и биомедицина», «Новые материалы», «Когнитивные исследования», «Передовые производственные технологии», «Природоподобные и нейротехнологии» отбор будет проходить в дистанционном формате. Заявки на участие принимаются до 15 февраля. <a class="NewsPage-link" href="https://konkurs.sochisirius.ru/">Подробнее на сайте</a>
			</p>
			
		</div>
	</div>
</template>
