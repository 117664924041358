<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			Осенняя интеллектуальная общеразвивающая школа»
		</h3>
		<p class="EventsCard-sub-title">
			с 27 сентября по 10 октября 2023
		</p>
	</div>
</template>