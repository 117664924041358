<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			20.11.2023
		</p>
		<h2 class="NewsPage-title">
			Программа по&nbsp;хореографии в&nbsp;&laquo;Персее&raquo; завершилась отчетным концертом
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage25.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» прошла профильная смена «Танцевальный фольклор Иркутской области». Участие в программе приняли 36 воспитанников танцевальных коллективов Приангарья. После недели занятий с ведущими преподавателями региона юные хореографы выступили с отчетным концертом, на котором был представлен мастер-класс по классическому танцу, а также фольклорные танцы Иркутской области.
			</p>
			<p class="NewsPage-sub-title">
				В Образовательном центре танцовщики занимались под руководством Натальи Заики, педагога дополнительного образования школы №40 г. Иркутска, художественного руководителя хореографической школы «Аллегро» и Ларисы Намаконовой, главного балетмейстера Центра развития детей и юношества «Узорочье». Аккомпанировала ребятам концертмейстер Иркутского областного колледжа культуры Любовь Бухарова.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказала Лариса Намаконова, тема этой смены малоизучена. Так как особенности танцевального фольклора нашей области знают не все, и материала сохранилось очень мало. Танцы, которые были представлены на отчетном концерте, ставили на основе трудов известного иркутского этнографа Эраста Константиновича Филиппова.
			</p>
			<p class="NewsPage-sub-title">
				&mdash;&nbsp;Вместе с&nbsp;ребятами мы&nbsp;реконструировали танцы по&nbsp;записям этнографа, прошли около 17&nbsp;танцев Иркутской области, изучили квадратные, линейные и&nbsp;круговые кадрили. За&nbsp;семь дней мы&nbsp;сделали&nbsp;то, что не&nbsp;удавалось пройти со&nbsp;студентами профессиональных училищ и&nbsp;колледжей культуры. Большая работа была проделана над танцем &laquo;Сибирская подгорная&raquo;. Именно этот танец содержит сложные элементы и&nbsp;передает всю особенность и&nbsp;самобытность танцевального фольклора Иркутской области,&nbsp;&mdash; отметила Лариса Намаконова.
			</p>
			<p class="NewsPage-sub-title">
				В программу занятий также вошли мастер-классы по классическому танцу. На которых участники изучили партерную часть, экзерсис на середине и у станка, а также большие и маленькие прыжки.
			</p>
			<p class="NewsPage-sub-title">
				– Классический танец – это основа хореографии, и многие привыкли, что классический танец достаточно статичный и спокойный. Но мы подготовили программу нашего концерта легкой, веселой и динамичной. Несмотря на то, что ребята, которые приехали на смену были разного уровня подготовки и возраста, нам удалось проделать большой объем работы и разучить новые комбинаций и движения. Более взрослые смогли показать элементы из классического балетного танца, а также передать свои опыт ребятам помладше. Это смена – замечательная возможность обмена опытом, а также качественной и усиленной подготовки юных талантов, – поделилась Наталья Заика.
			</p>
			<p class="NewsPage-sub-title">
				Также преподаватели отметили, что номер «Сибирская подгорная», который вошел в программу отчетного концерта они покажут на конкурсе танцевального мастерства в городе Красноярск.
			</p>
			<p class="NewsPage-sub-title">
				Следующая программа по хореографии в Образовательном центре пройдет в январе 2024 года. Курс занятий также завершится отчетным концертом.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(5).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(6).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(7).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(8).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(9).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(10).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(11).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage25(12).webp" alt="Картинка">
					</li>
				</ul>
			</div>

		</div>
	</div>
</template>
