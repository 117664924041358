<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Академический рисунок»
		</h2>
		<p class="EventsPage-date">
			с 07 по 20 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=132" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24429-programma-akademicheskii-risunok" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену «Академический рисунок» для обучающихся или выпускников организаций дополнительного образования по направлению изобразительное искусство от 15 до 17 лет, заявившие в добровольном порядке свое намерение участвовать в мероприятиях смены и проявивших себя в творческой деятельности в области изобразительного искусства, а также, обладающих определенными знаниями законов композиции, линейной и воздушной перспективы, принципов размещения изображения на листе и конструктивного построения формы, владеющих линией, штрихом, имеющих навыки линейного рисунка.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает наличие определенных знаний:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				понятия «пропорции», «симметрия»;
			</li>
			<li class="JuniorProfi-items">
				законы композиции, принципы размещения изображения на листе;
			</li>
			<li class="JuniorProfi-items">
				принципы конструктивного построения формы;
			</li>
			<li class="JuniorProfi-items">
				законы линейной и воздушной перспективы;
			</li>
			<li class="JuniorProfi-items">
				способы передачи пространства и моделирование формы тоном;
			</li>
			<li class="JuniorProfi-items">
				основные этапы работы над длительной постановкой;
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Наличие определенных умений:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				найти грамотное композиционное решение;
			</li>
			<li class="JuniorProfi-items">
				сознательно пользоваться приемами линейной и воздушной перспективы;
			</li>
			<li class="JuniorProfi-items">
				выполнить конструктивное построение простой формы;
			</li>
			<li class="JuniorProfi-items">
				моделировать форму предметов тоном;
			</li>
			<li class="JuniorProfi-items">
				передавать пространство средствами штриха и светотени;
			</li>
			<li class="JuniorProfi-items">
				«ставить» предметы на плоскость;
			</li>
			<li class="JuniorProfi-items">
				владеть линией, штрихом, иметь навыки линейного рисунка;
			</li>
			<li class="JuniorProfi-items">
				выполнить наброски фигуры и головы живой модели;
			</li>
			<li class="JuniorProfi-items">
				нарисовать по памяти несложные предметы в разных положениях.
			</li>
		</ul>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Ширшова Ирина Петровна, преподаватель художественной студии МЦ «Лифт» г.Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<p class="EventsPage-text">
			Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе электронной информационно-образовательной среды сайта ОЦ «Персей» до 27 января 2024 года по ссылке: <a style="color:blue" href="https://moodle.perseusirk.ru/course/view.php?id=132">https://moodle.perseusirk.ru/course/view.php?id=132</a>, где необходимо прикрепить:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				эссе на тему «Моя будущая профессия в искусстве»;
			</li>
			<li class="JuniorProfi-items">
				фотографии следующих творческих работ:
			</li>
			<li class="JuniorProfi-items">
				конструктивный рисунок натюрморта из предметов геометрической формы или бытовых предметов (графитный карандаш),
			</li>
			<li class="JuniorProfi-items">
				зарисовку фигуры человека,
			</li>
			<li class="JuniorProfi-items">
				портрет (материал и техника любые).
			</li>
		</ul>

		<p class="EventsPage-text">
			Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке: <a style="color:blue" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24429-programma-akademicheskii-risunok">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24429-programma-akademicheskii-risunok</a>.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Списочный состав учащихся, приглашённых на очную профильную смену:
		</p>

		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Богослова Алиса Романовна</li>
			<li class="EventsPage-number">Бондарь Иван Антонович</li>
			<li class="EventsPage-number">Васильева Екатерина Дмитриевна</li>
			<li class="EventsPage-number">Воложин Илья Иванович</li>
			<li class="EventsPage-number">Голубь Евгения Игоревна</li>
			<li class="EventsPage-number">Гусева Виктория Игоревна</li>
			<li class="EventsPage-number">Зурначян Артур Гагикович</li>
			<li class="EventsPage-number">Лалакина Алина Викторовна</li>
			<li class="EventsPage-number">Лосова Виктория Бэликтовна</li>
			<li class="EventsPage-number">Наливайко Милена Дмитриевна</li>
			<li class="EventsPage-number">Невечерина Юлия Васильевна</li>
			<li class="EventsPage-number">Хамова Мария Викторовна</li>
			<li class="EventsPage-number">Шевкунова Снежана Сергеевна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>