<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			08.04.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/sites/minobr/news/3639299/">Победителем XXIV регионального конкурса «Лучший ученик года - 2024» стала десятиклассница из Иркутска</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage54.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Приангарье подвели итоги ХХIV областного конкурса «Лучший ученик года». Победителем конкурса стала Шишминцева Алина из средней школы №4 Иркутска.
			</p>
			<p class="NewsPage-sub-title">
				Конкурс проходил с 5 по 7 апреля 2024 года на базе Регионального центра выявления и поддержки одаренных детей «Персей», созданного в рамках реализации национального проекта «Образование». Участие в нем приняли 43 школьника из 37 муниципальных образований региона.
			</p>
			<p class="NewsPage-sub-title">
				Алина - старший ребенок из многодетной семьи. Отец Алины принимает участие в специальной военной операции.
			</p>
			<p class="NewsPage-sub-title">
				У девочки разносторонние увлечения и активная жизненная позиция: она играла в КВН, интересуется военной патриоткой, является комиссаром школьного караула и отличником Поста №1, участвует в движении «Волонтеры Победы», а в школе возглавляет первичное отделение «Движения Первых».
			</p>
			<p class="NewsPage-sub-title">
				— Мне уже доводилось участвовать в больших конкурсах, но все равно волновалась. Впечатления от этих дней только положительные: хорошая организация мероприятия, интересные задания, а еще здесь свежий воздух и мы с ребятами все вместе, — поделилась лучшая  ученица Иркутской области 2024 года.
			</p>
			<p class="NewsPage-sub-title">
				Лауреатами конкурса стали ученик девятого класса Первомайской школы Нукутского района Степан Безотечество и десятиклассник из школы №1 Бодайбо Виктор Чистяков. Специальный приз «За волю к победе» получила Доминика Цвентарных из школы №13 им. М.К. Янгеля Усть-Илимска.
			</p>
			<p class="NewsPage-sub-title">
				– Это очень необычный конкурс, потому что главное в нем – не конкуренция или соперничество, а уникальная дружеская атмосфера и общение с единомышленниками. За более чем 20-летнюю историю «ЛУГа» через него прошли более тысячи девушек и юношей, и для каждого это мероприятие стало источником веры в себя, обретения хороших друзей и новых ориентиров в жизни, – прокомментировал министр образования региона Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Глава регионального ведомства добавил, что в этом году тема конкурса была приурочена к Году семьи, объявленному Президентом Российской Федерации Владимиром Путиным.
			</p>
			<p class="NewsPage-sub-title">
				В первом этапе конкурсанты писали эссе по теме «Проблемы современной семьи и пути их решения» и представляли свою визитную карточку.
			</p>
			<p class="NewsPage-sub-title">
				Во второй тур прошли 32 школьника, они демонстрировали мастер-классы и состязались в умении вести дебаты. В финальных испытаниях третьего тура 16 конкурсантов решали ситуационные задачи и вели диалог министром образования Иркутской области Максимом Парфеновым.
			</p>
			<p class="NewsPage-sub-title">
				Оценивали ребят представители профессионального педагогического сообщества, научных и спортивных организаций, творческих союзов, общественных объединений, имеющих опыт организации работы с молодежью и общественное признание в профессиональной сфере деятельности.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, конкурс «Лучший ученик года» проводится для выявления и поддержки обучающихся, имеющих особые достижения в области науки, творчества, спорта, молодежной политики, общественной деятельности и добровольчества. Победитель представит Иркутскую область на всероссийском этапе осенью этого года.
			</p>

		</div>
	</div>
</template>
