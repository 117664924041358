<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Секреты фотосъемки»
		</h2>
		<p class="EventsPage-date">
			с 24 октября по 14 декабря 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Секреты фотосъемки» для обучающихся 8-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа курса позволит учащимся изучить теоретические аспекты фотографии, умении правильно применять полученные знания на практике и обладать необходимыми представлениями о работе фототехники, постобработки, композиционных и жанровых особенностей, а также в понимании, как правильно продвигать свои работы и профессионально развиваться в выбранной области деятельности, что, в свою очередь, оказывает благоприятное воздействие на формирование нравственных качеств, развитие эстетических чувств, творческого самовыражения; помогает адаптироваться в сложной обстановке современного мира, а также в последующем самоопределении.
		</p>
		<p class="EventsPage-text">
			Дистанционный курс состоится на бесплатной основе.
		</p>
		<hr>
		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
			<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22865-programma-sekrety-fotosemki">
				Подать заявку в Навигаторе.
			</a>
			<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=107">
				Зарегистрироваться и заполнить анкету в системе Moodle Образовательного Центра «Персей»
			</a>
			Обучение будет проводиться в заочной форме. Расписание занятий, материалы курса, ссылки на онлайн-подключения будут размещены на странице курса в системе Moodle Образовательного Центра «Персей»
	</div>
</template>