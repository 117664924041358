<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage135' })">
						<EventsCard135></EventsCard135>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage52' })">
						<EventsCard52></EventsCard52>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard52 from '../EventsCard/EventsCard52';
import EventsCard135 from '../EventsCard/EventsCard135';

	export default {
		components: {
			EventsCard52,
			EventsCard135,
		}
	}
</script>
