<template>
	<div class="Scholarships _cover">
		<h2 class="Scholarships-title _cover-title">
			Платные образовательные услуги
		</h2>
		<h3 class="Scholarships-title-box">
			Платные образовательные услуги:
		</h3>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D1%82-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D1%85-%D1%83%D1%81%D0%BB%D1%83%D0%B3.pdf"
					target="_blank">
					Приказ от утверждении платных услуг
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA-%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D1%85-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D1%83%D1%81%D0%BB%D1%83%D0%B3-.pdf"
					target="_blank">
					Порядок оказания платных образовательных услуг
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D1%8E%D1%80.-%D0%BB%D0%B8%D1%86%D0%B0-%D0%9F%D0%9A.docx"
					target="_blank">
					Договор оказания платных образовательных услуг повышения квалификации для юридических лиц
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D1%84%D0%B8%D0%B7.%D0%BB%D0%B8%D1%86%D0%BE-%D0%9F%D0%9A.docx"
					target="_blank">
					Договор оказания платных образовательных услуг повышения квалификации для физических лиц
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D1%81-%D1%8E%D1%80.%D0%BB%D0%B8%D1%86%D0%BE%D0%BC-%D0%9F%D0%9E.docx"
					target="_blank">
					Договор оказания платных образовательных услуг профессионального обучения для юридических лиц
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D1%81-%D1%84%D0%B8%D0%B7.-%D0%BB%D0%B8%D1%86%D0%BE%D0%BC-%D0%9F%D0%9E.docx"
					target="_blank">
					Договор оказания платных образовательных услуг профессионального обучения для физических лиц
				</a>
			</li>
		</ul>

	</div>
</template>