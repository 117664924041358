<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			21.11.2023
		</p>
		<h2 class="NewsPage-title">
			В четвертый сезон Всероссийской образовательной инициативы «Сириус. Лето: начни свой проект» включились 130 участников из Приангарья
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage27.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В начале ноября в Образовательном центре «Персей» прошла  установочная конференция по старту четвертого сезона Всероссийской образовательной инициативы по поиску и реализации научно-технологических проектов «Сириус. Лето: начни свой проект».
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали организаторы встречи, целью программы «Сириус. Лето: начни свой проект» является создание механизма вовлечения талантливой молодежи в работу над актуальными задачами российской науки и бизнеса, а также построение взаимодействие «школа – вуз – предприятие».
			</p>
			<p class="NewsPage-sub-title">
				В этом году в программу включились 29 наставников и 101 школьник Иркутской области. В лице наставников выступают студенты ведущих вузов региона.
			</p>
			<p class="NewsPage-sub-title">
				Миниханов Константин, студент Института высоких технологий ИРНИТУ, принимает участие в роли наставника уже второй раз. В этом году вместе ребятами он будет работать над проектом в области энергетики. Перед ними стоит задача разработать экотропу, по маршруту которой будут находиться станции для подзарядки гаджетов, а также точки доступа Wi-Fi. Тропа будет полностью автономной, а электричество будет поступать от возобновляемых источников энергии.
			</p>
			<p class="NewsPage-sub-title">
				Проектные задачи в этом сезоне для студентов и школьников выдвинули более десяти предприятий и организаций региона. Среди них Ангарская нефтехимическая компания, Иркутская нефтяная химическая компания, Центр «Мой бизнес», Газпром добыча Иркутск, компания по продаже комплектующих для капельного автополива «Квантум» и ООО «Энолог», а также школы и вузы Иркутска. Помимо этого, участники включились в решение задач от других регионов страны.
			</p>
			<p class="NewsPage-sub-title">
				Так, при поддержке иркутских школьников и студентов будет реализован проект «Создание дрона-тележки для транспортировки тяжелых грузов». Одним из наставников в проекте стала Маргарита Павлюковская, студентка четвертого курса Иркутского государственного университета.
			</p>
			<p class="NewsPage-sub-title">
				Девушка участвует в инициативе впервые, но в прошлом году она наблюдала за работой команды ИГУ, и в этом сезоне она приняла решение стать наставником.
			</p>
			<p class="NewsPage-sub-title">
				– Это интересный опыт не только для школьников, но и для студентов, и самого вуза. Сейчас есть спад на некоторые специальности, и мне бы хотелось привлечь внимание абитуриентов к нашему факультету, а также передать свой опыт подрастающему поколению и получить новые знания, – поделилась Маргарита.
			</p>
			<p class="NewsPage-sub-title">
				На основе предложенных региональными партнерами задач участники смогут создать чат-бот, нейросети, портативные системы трекинга персонала, комиксы-историй для решения социально-психологических проблем подростков, системы мониторинга микроклимата и включиться в разработку проектов в области нефтехимии, энергетики, информационных систем, геологии и промышленности. Всего будет реализовано 18 проектных задач.
			</p>
			<p class="NewsPage-sub-title">
				27 ноября стартует дистанционный курс для созданных проектных команд, где они начнут работать над решением задач. А в начале февраля 2024 года в кампусе Образовательного центра пройдет очная профильная смена для участников «Сириус.Лето».
			</p>
			
		</div>
	</div>
</template>
