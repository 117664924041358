<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Финансовая грамотность: продвинутый уровень»
		</h2>
		<p class="EventsPage-date">
			с 22 ноября по 22 декабря 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Секреты фотосъемки» для обучающихся 7-10-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа курса позволит учащимся изучить финансовую культуру, рациональное финансовое поведение и ответственное потребление финансовых услуг, с учётом их возрастных особенностей и способностей.
		</p>
		<hr>
		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
		<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/23750-programma-finansovaya-gramotnost-prodvinutyi-uroven">
			Подать заявку в Навигаторе.
		</a>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=115">
			Зарегистрироваться и заполнить анкету в системе Moodle Образовательного Центра «Персей»
		</a>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=114#section-0">
			Moodle Образовательного Центра «Персей»
		</a>
		<p class="EventsPage-text">
			Дистанционный курс состоится на бесплатной основе. Обучение будет проводиться в очно-заочной форме. Расписание занятий, материалы курса, ссылки на онлайн-подключения будут размещены на странице курса в системе Moodle Образовательного Центра «Персей».
		</p>
	</div>
</template>