<template>
	<div class="Partners _cover">
				<h2 class="Partners-title _cover-title">
					Партнеры
				</h2>
					<ul class="grid-container">
						<li class="grid-item">
							<a class="Partners-link" href="https://www.ddut-irk.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/LOGOTIP17.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="http://iokm.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/Логотп-1.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="https://irkpo.ru/www">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/logo.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="https://bratskmuz.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/logo-2.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="https://muzcollege-irk.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/noroot.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="https://irkteatruch.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/logo.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="https://iokk38.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/iokk-logo-250.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="https://iohk.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/cropped-лого-2.webp" alt="#">
							</a>
						</li>
						<li class="grid-item">
							<a class="Partners-link" href="http://perspektiva.irk.muzkult.ru/">
								<img loading="lazy" class="Partners-img" src="../assets/img/Ticker/Logotip_Perspektiva_czvetok_bez_fona.webp" alt="#">
							</a>
						</li>
					</ul>
				</div>
</template>