<template>
	<div class="JuniorProfi _cover">
		<h2 class="JuniorProfi-title _cover-title">
			VIII Региональный чемпионат компетенций ЮниорПрофи
		</h2>
		<div class="DirectionBlock-img-box">
			<img class="DirectionBlock-img" src="../assets/img/Logo/юниор.webp" alt="Спорт" loading="lazy">
		</div>
		<h4 class="JuniorProfi-title _cover-title">
			с 5 ноября по 8 декабря 2023 года
		</h4>
		<hr>

		<p class="JuniorProfi-sub-title">
			О чемпионате:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Чемпионат состоится в очном формате с 5 по 8 декабря 2023 года на базе кампуса
			</li>
			<li class="JuniorProfi-items">
				Образовательного центра «Персей» (Иркутская область, Ангарский район, 8.351 км автодороги Ангарск-Тальяны).
			</li>
			<li class="JuniorProfi-items">
				В соответствии с конкурсным заданием, в Чемпионате примут участие обучающиеся по 8 компетенциям.
			</li>
			<br>
			<a class="JuniorProfi-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%B8-%D0%A7%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B5%D1%82%D0%B5%D0%BD%D1%86%D0%B8%D0%B9.pdf"
				target="_blank">
				Итоги Чемпионат компетенций
			</a>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Компетенции чемпионата:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Прототипирование 14+
			</li>
			<li class="JuniorProfi-items">
				Инженерный дизайн 14+
			</li>
			<li class="JuniorProfi-items">
				Агрономия 14+
			</li>
			<li class="JuniorProfi-items">
				Сити-фермерство 14+
			</li>
			<li class="JuniorProfi-items">
				Ветеринария 14+
			</li>
			<li class="JuniorProfi-items">
				Мобильная робототехника 10+
			</li>
			<li class="JuniorProfi-items">
				Мультимедиакоммуникации 14+
			</li>
			<li class="JuniorProfi-items">
				Электроника 14+
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Как принять участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				В Чемпионате соревнуются команды по выбранной компетенции. Каждая команда состоит из двух обучающихся.
			</li>
			<li class="JuniorProfi-items">
				Команду предварительно готовит к участию наставник, наставники перед Чемпионатом проходят обучение у старшего
				регионального эксперта по компетенции.
			</li>
			<li class="JuniorProfi-items">
				Регистрация наставников проходит до 9 ноября 2023 года.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Ссылки на регистрацию педагогов-наставников:
		</p>
		<a class="JuniorProfi-link" href="https://forms.gle/PotEtLA29RhJXppv5">
			Ветеринария
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/wQp6eY88hnqJLyMe6">
			Сити-фермерство
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/PJ4WhH99kz2YNRyC7">
			Агрономия
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/9q7kFi45ieuuwXoA7">
			Мультимедиакоммуникации
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/uyycdVrvNcunEUrn7">
			Инженерный дизайн
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/eHZi1P8zYfSW2T5B8">
			Прототипирование
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/VHYf7d6QDU9LB6Nm9">
			Мобильная робототехника
		</a>
		<a class="JuniorProfi-link" href="https://forms.gle/oLAd2AbsZkU4PBWC8">
			Электроника
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="JuniorProfi-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%9B%D0%B8%D1%81%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%A7%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B5%D1%82%D0%B5%D0%BD%D1%86%D0%B8%D0%B9-23.pdf">
			Листовка Чемпионат компетенций 23
		</a>
		<a class="JuniorProfi-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-1.-%D0%A1%D1%81%D1%8B%D0%BB%D0%BA%D0%B8-%D0%BD%D0%B0-%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8E.pdf">
			Приложение 1. Ссылки на регистрацию
		</a>
		<a class="JuniorProfi-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-VIII-%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%87%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82%D0%B0-%D0%AE%D0%BD%D0%B8%D0%BE%D1%80%D0%9F%D1%80%D0%BE%D1%84%D0%B8.pdf">
			О проведении VIII Регионального чемпионата ЮниорПрофи
		</a>
		<a class="JuniorProfi-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%AE%D0%BD%D0%B8%D0%BE%D1%80%D0%9F%D1%80%D0%BE%D1%84%D0%B8-%D0%A7%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82.pdf">
			Распоряжение ЮниорПрофи Чемпионат
		</a>
		<a class="JuniorProfi-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82-%D0%AE%D0%BD%D0%B8%D0%BE%D1%80%D0%9F%D1%80%D0%BE%D0%BE%D1%84%D0%B8-%D0%A7%D0%B5%D0%BC%D0%BF%D0%B8%D0%BE%D0%BD%D0%B0%D1%82.pdf">
			Регламент ЮниорПроофи Чемпионат
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				8 (3952) 54-60-44, доб. 4
			</li>
			<li class="JuniorProfi-items">
				perseus@perseusirk.ru
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Архив:
		</p>
		<a class="JuniorProfi-link" href="#top" @click="$router.push({ name: 'EventsPage27' })">
			VI Региональный чемпионат корпораций ЮниорПрофи Иркутской области
		</a>
		<a class="JuniorProfi-link" href="#top" @click="$router.push({ name: 'EventsPage51' })">
			Итоги VI Регионального чемпионата корпораций ЮниорПрофи Иркутской области
		</a>
		<a class="JuniorProfi-link" href="#top" @click="$router.push({ name: 'EventsPage46' })">
			VII Региональный чемпионат компетенций ЮниорПрофи Иркутской области
		</a>
		<a class="JuniorProfi-link" href="#top" @click="$router.push({ name: 'EventsPage28' })">
			Конкурсные мероприятия в рамках VII регионального чемпионата компетенций ЮниорПрофи
		</a>

	</div>
</template>