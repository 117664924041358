<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Авиамоделирование и беспилотный транспорт»
		</h2>
		<p class="EventsPage-date">
			с 10 по 23 апреля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=155" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24712-programma-aviamodelirovanie-i-bespilotnyi-transport" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Авиамоделирование и беспилотный транспорт» для учащихся 5-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа профильной смены направлена на изучение профессий, связанные с инженерным авиамоделированием и с управлением беспилотными аппаратами, развитие интеллектуально-творческих способностей учащихся, их интереса к научно-исследовательской деятельности и техническому творчеству в данной области.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание до 1 апреля 2024 года на платформе Moodle, Образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, нужно будет записаться на программу «Авиамоделирование и беспилотный транспорт» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 2 апреля на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аносова Екатерина Андреевна</li>
			<li class="EventsPage-number">Антипина Яна Семеновна</li>
			<li class="EventsPage-number">Базилевская Софья Романовна</li>
			<li class="EventsPage-number">Банникова Эвелина Романовна</li>
			<li class="EventsPage-number">Бедарев Макар Петрович</li>
			<li class="EventsPage-number">Белов Юрий Александрович</li>
			<li class="EventsPage-number">Вакулко Влада Валерьевна</li>
			<li class="EventsPage-number">Воронин Макар Антонович</li>
			<li class="EventsPage-number">Гайко Дарья Александровна</li>
			<li class="EventsPage-number">Гвон Дмитрий Викторович</li>
			<li class="EventsPage-number">Глушкова Анастасия Александровна</li>
			<li class="EventsPage-number">Елизарьев Михаил Константинович</li>
			<li class="EventsPage-number">Ефремов Семён Антонович</li>
			<li class="EventsPage-number">Жирова Маргарита Алексеевна</li>
			<li class="EventsPage-number">Караченцев Арсений Витальевич</li>
			<li class="EventsPage-number">Климова Марина Юрьевна</li>
			<li class="EventsPage-number">Коновалов Лев Александрович</li>
			<li class="EventsPage-number">Кравчук Анна Александровна</li>
			<li class="EventsPage-number">Кушнерёв Александр Даниилович</li>
			<li class="EventsPage-number">Лукина Елена Васильевна</li>
			<li class="EventsPage-number">Макарова Анастасия Евгеньевна</li>
			<li class="EventsPage-number">Петкевич Степан Андреевич</li>
			<li class="EventsPage-number">Пилявин Илья Александрович</li>
			<li class="EventsPage-number">Приходько Семён Андреевич</li>
			<li class="EventsPage-number">Пятидесятникова Злата Антоновна</li>
			<li class="EventsPage-number">Романов Владимир Александрович</li>
			<li class="EventsPage-number">Рыхальский Роман Глебович</li>
			<li class="EventsPage-number">Рябцева Ксения Вячеславовна</li>
			<li class="EventsPage-number">Смолина Дарья Аркадьевна</li>
			<li class="EventsPage-number">Степанов Степан Степанович</li>
			<li class="EventsPage-number">Чугунова Анна Андреевна</li>
			<li class="EventsPage-number">Шумаков Константин Олегович</li>
		</ol>
		<br>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 2 апреля 2024 г. в 20.00 <a style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1709296297">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/ZnGY/wBhUyX4F7" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/WnTBWYXZ9s4iFg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>