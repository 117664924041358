<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«ФИНАНСОВАЯ ГРАМОТНОСТЬ»
		</h2>
		<p class="EventsPage-date">
			с 11 октября по 10 ноября 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Финансовая грамотность» для обучающихся 7-10-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			«Финансовая грамотность»
		</p>
		<p class="EventsPage-text">
			В программе курса уделяется особое внимание не только содержательным — предметным аспектам финансовой грамотности, но и развитию коммуникативных и регулятивных универсальных учебных действий, решению творческих задач и формированию критического мышления у обучающихся 7-10 классов.
		</p>
		<p class="EventsPage-text">
			Дистанционный курс состоится на бесплатной основе.
		</p>
		<hr>
		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
			<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21965-programma-finansovaya-gramotnost">
				Подать заявку в системе Навигаторе.
			</a>
			<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=103">
				Зарегистрироваться и заполнить анкету в системе Moodle Образовательного Центра «Персей»
			</a>
			Обучение будет проводиться в очно-заочной форме. Расписание занятий, материалы курса, ссылки на онлайн-подключения будут размещены на странице курса в системе Moodle Образовательного Центра «Персей»
	</div>
</template>