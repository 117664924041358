<template>
	<div class="ExpertCouncil _cover">
		<div class="ExpertCouncil-container container">
			<div class="ExpertCouncil-content">
				<h2 class="ExpertCouncil-title _cover-title">
					Экспертный совет
				</h2>
				<p class="ExpertCouncil-sub-title">
					Коллегиальными органами управления Образовательного центра &laquo;Персей&raquo; в&nbsp;соответствии
					с&nbsp;регламентирующими нормативно-правовыми актами и&nbsp;Уставом являются:
				</p>
				<ul class="ExpertCouncil-list-one">
					<li class="ExpertCouncil-items-one">
						Попечительский совет;
					</li>
					<li class="ExpertCouncil-items-one">
						Экспертный совет.
					</li>
				</ul>
				<p class="ExpertCouncil-sub-title">
					<b>Основными задачами Экспертного совета являются:</b> научно- методическое, экспертное и&nbsp;аналитическое
					обеспечение его деятельности по&nbsp;направлениям &laquo;Наука&raquo;, &laquo;Искусство&raquo;,
					&laquo;Спорт&raquo;, в&nbsp;том числе содержательное и&nbsp;методическое обеспечение разработки
					и&nbsp;реализации образовательных программ и&nbsp;иных мероприятий.
				</p>
				<p class="ExpertCouncil-sub-title">
					<b>Основными функциями Экспертного совета являются:</b>
				</p>
				<ul class="ExpertCouncil-list-two">
					<li class="ExpertCouncil-items-two">
						экспертиза образовательных программ Образовательного центра &laquo;Персей&raquo;;
					</li>
					<li class="ExpertCouncil-items-two">
						формирование критериев отбора обучающихся и&nbsp;педагогических работников по&nbsp;направлениям
						образовательной деятельности Образовательного центра &laquo;Персей&raquo;
					</li>
					<li class="ExpertCouncil-items-two">
						определение потребностей в&nbsp;ресурсном обеспечении образовательных программ Образовательного центра
						&laquo;Персей&raquo;, развитие образовательной инфраструктуры Образовательного центра &laquo;Персей&raquo;;
					</li>
					<li class="ExpertCouncil-items-two">
						определение форм и&nbsp;механизмов дальнейшего взаимодействия выпускников образовательных программ
						Образовательного центра &laquo;Персей&raquo; и&nbsp;выпускников Образовательного центра &laquo;Сириус&raquo;
						с&nbsp;ведущими педагогами, учеными, представителями организаций-партнеров с&nbsp;целью их&nbsp;дальнейшего
						профессионального развития.
					</li>
				</ul>
				<div class="ExpertCouncil-video-box">
					<div class="ExpertCouncil-video">
						<iframe class="ExpertCouncil-video-items" src="https://www.youtube.com/embed/28X8WxOf-UU"
							title="YouTube video player" frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen>
							Об&nbsp;Экспертном совете Образовательного центра &laquo;Персей&raquo;
						</iframe>
					</div>
				</div>

				<div class="StructureAndOrgans-table">
					<table class="ExpertCouncil-table">
					<thead class="ExpertCouncil-table-header">
						<tr>
							<th>Наука</th>
							<th>Искусство</th>
							<th>Спорт</th>
						</tr>
					</thead>
					<tbody class="ExpertCouncil-table-body">
						<tr>
							<td>Сибирское отделение Российской Академии Наук</td>
							<td>ГОБУ ДО ИО Детская школа искусств</td>
							<td>Федеральное государственное бюджетное учреждение профессиональная образовательная организация "Государственное училище (колледж) олимпийского резерва г. Иркутска"</td>
						</tr>
						<tr>
							<td>Сибирский институт физиологии и биохимии растений</td>
							<td>ГБПОУ Иркутский областной музыкальный колледж им. Ф. Шопена</td>
							<td>ОГБУ «Ресурсно-методический центр развития физической культуры и спорта Иркутской области»</td>
						</tr>
						<tr>
							<td>Восточно-Сибирский институт медико-экологических исследований</td>
							<td>ГБПОУ Иркутский областной колледж культуры</td>
							<td>Директор ОГБУ «Центр спортивной подготовки сборных команд Иркутской области»</td>
						</tr>
						<tr>
							<td>Иркутский филиал Института лазерной физики</td>
							<td>ГБПОУ СПО Иркутский областной художественный колледж им. И.Л. Копылова.</td>
							<td></td>
						</tr>
						<tr>
							<td>Лимнологический институт СО РАН</td>
							<td>ОГПОБУ Иркутское театральное училище</td>
							<td></td>
						</tr>
						<tr>
							<td>Институт систем энергетики им. Мелентьева СО РАН</td>
							<td>Государственное бюджетное профессиональное образовательное учреждение Иркутской области «Иркутский региональный колледж педагогического образования»</td>
							<td></td>
						</tr>
						<tr>
							<td>Институт геохимии им. А.П. Виноградова СО РАН</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>Институт земной коры СО РАН</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>ФГБОУ ВО ИГУ</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>ФГБОУ ВО ИрГУПС</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>ФГБОУ ВО ИрНИТУ</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>ФГБОУ ВО ИГМУ</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>АО «ВостСиб АГП»</td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
				</div>
			</div>
		</div>
	</div>
</template>