<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиады
		</h3>
		<h2 class="EventsPage-title _cover-title">
			Региональная олимпиада «История государства и права России», посвященная 300-летию образования российской прокуратуры
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 18 октября по 8 ноября
		</h4>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Об олимпиаде
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/12/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D0%B3%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B0-%D0%B8-%D0%BF%D1%80%D0%B0%D0%B2%D0%B0-%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8.pdf" target="_blank">
			Приказ об утверждении итогов Олимпиады История государства и права России
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/10/%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B5.pdf" target="_blank">
			Положение об олимпиаде
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Учредители Олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Прокуратура Иркутской области,
			</li>
			<li class="JuniorProfi-items">
				Иркутский юридический институт (филиал) Университета прокуратуры Российской Федерации,
			</li>
			<li class="JuniorProfi-items">
				министерство образования Иркутской области.
			</li>
		</ul>
		<p class="EventsPage-text">
			Региональный оператор Олимпиады – Государственное автономное учреждение дополнительного профессионального образования Иркутской области «Региональный институт кадровой политики и непрерывного профессионального образования».
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Основные цели и задачи Олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				выявление и развитие у обучающихся творческих способностей и интереса к научно-исследовательской деятельности, истории нашей страны;
			</li>
			<li class="JuniorProfi-items">
				поддержка одаренных и высокомотивированных обучающихся Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				содействие в профессиональной ориентации обучающихся.
			</li>
		</ul>
		<p class="EventsPage-text">
			К участию в Олимпиаде допускаются обучающиеся 10–11-х классов.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Темы олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Создание прокуратуры в Российской империи и ее деятельность в XVIII в.
			</li>
			<li class="JuniorProfi-items">
				Прокуратура Российской империи в дореформенный период (1801–1864 гг.).
			</li>
			<li class="JuniorProfi-items">
				Прокуратура после проведение судебной реформы (1864–1917 гг.).
			</li>
			<li class="JuniorProfi-items">
				Создание советской прокуратуры и ее деятельность (1922–1991 гг.).
			</li>
		</ul>
		<p class="JuniorProfi-sub-title">
			Прокуратура Российской Федерации как орган государственной власти. Прокуратура в системе органов государственной власти. Организация и деятельность прокуратуры Российской Федерации.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Регистрация на олимпиаду
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Регистрация на Олимпиаду проходит с 18 октября по 8 ноября по ссылке: https://konkurs.ric38.ru/. После регистрации на сайте необходимо выбрать в списке конкурсов «Региональная олимпиада «История государства и права России» и заполнить заявку.
			</li>
			<li class="JuniorProfi-items">
				Заочный отборочный этап (тестирование) пройдет с 18 октября по 18 ноября 2021 года.
			</li>
			<li class="JuniorProfi-items">
				Заочный заключительный этап (выполнение полученных олимпиадных заданий) пройдет с 19 ноября по 15 декабря 2021 года.
			</li>
		</ul>
	</div>
</template>