<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Школа безопасности»
		</h3>
		<p class="EventsCard-sub-title">
			с 10 по 23 апреля 2024 года
		</p>
	</div>
</template>