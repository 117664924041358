<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-2">
			Спорт
		</h2>
		<h3 class="EventsCard-title">
			«Ориентирование на местности»
		</h3>
		<p class="EventsCard-sub-title">
			16.04.23-29.04.23
		</p>
	</div>
</template>