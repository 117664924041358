<template>
	<div class="WeeklyCourses _cover">
		<h1 class="WeeklyCourses-title _cover-title">
			Еженедельный курс
		</h1>

		<div class="WeeklyCourses-img-box">
			<img loading="lazy" class="WeeklyCourses-img-content" src="../assets/img/Logo/ИСКУССТВО.webp" alt="Наука">
		</div>

		<hr>

		<div class="Period">
			<h2 class="Period-title _cover-title">Программы</h2>

			<ul class="grid-container">

				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage149' })">
						<EventsCard149></EventsCard149>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage125' })">
						<EventsCard125></EventsCard125>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage122' })">
						<EventsCard122></EventsCard122>
					</a>
				</li>
			</ul>

		</div>

		<!-- Завершенные программы -->
		<div class="Period">
			<h2 class="Period-title _cover-title">
				Архив
			</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">
					Подробнее
				</summary>
				<ul class="grid-container">
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage173' })">
							<EventsCard173></EventsCard173>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage172' })">
							<EventsCard172></EventsCard172>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage171' })">
							<EventsCard171></EventsCard171>
						</a>
					</li>
				</ul>
			</details>

		</div>
	</div>
</template>

<script>
import EventsCard122 from '../EventsCard/EventsCard122'
import EventsCard125 from '../EventsCard/EventsCard125'
import EventsCard149 from '../EventsCard/EventsCard149'
import EventsCard171 from '../EventsCard/EventsCard171'
import EventsCard172 from '../EventsCard/EventsCard172'
import EventsCard173 from '../EventsCard/EventsCard173'

export default {
	components: {
		EventsCard122,
		EventsCard125,
		EventsCard149,
		EventsCard171,
		EventsCard172,
		EventsCard173,

	},
}
</script>