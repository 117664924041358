<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			11.03.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» подвели итоги профильных смен «Агенты погоды» и «Весенняя интеллектуальная общеразвивающая школа»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage49.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» подвели итоги профильных смен «Агенты погоды» и «Весенняя интеллектуальная  общеразвивающая школа». Участниками образовательных программ стали 68 школьников Киренска, Иркутска, Усть-Кута, Тулуна, Усолье-Сибирского, Саянска, Братска, Заларинского, Братского, Боханского и Тулунского районов.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена «Агенты погоды» проходила в Образовательном центре в третий раз совместно с Иркутским гидрометеорологическим колледжем.  К программе присоединились специалисты Иркутского управления по гидрометеорологии и мониторингу окружающей среды и Иркутской нефтяной компании.
			</p>
			<p class="NewsPage-sub-title">
				На интенсивной профильной смене школьники изучали работу гидрометеорологической службы. По словам организаторов, уникальность программы в том, что у ребят есть возможность решать реальные производственные задачи, с которыми сталкиваются работники гидрометеорологической службы для прогноза погодно-климатических условий и устранения последствий чрезвычайных ситуаций.
			</p>
			<p class="NewsPage-sub-title">
				Во время обучения ребята проводили лабораторные и территориальные эксперименты:  химические анализы проб воды, воздуха, почвы и снежного покрова, геодезические, метеорологические и радиотехнические работы. А также осуществляли гидрологические и метеорологические наблюдения и экологический мониторинг.
			</p>
			<p class="NewsPage-sub-title">
				Итогом программы стали научно-исследовательские проекты, созданные ребятами во время обучения.  Школьники провели исследования по оценки качества атмосферного воздуха, изучили последствия от наводнения в 2019 года в Тулуне, утечки соляной кислоты в реку Китой и метеорологические явления.
			</p>
			<p class="NewsPage-sub-title">
				Авторы лучших проектов смогут принять участия во всероссийском фестивале «Дом, в котором мы живем – 2024», который ежегодно проходит на базе гидрометеорологического техникума.
			</p>
			<p class="NewsPage-sub-title">
				На Весеннюю интеллектуальную общеразвивающую школу приехали школьники из Иркутска. Как поясняет директор Образовательного центра «Персей» Алексей Шестаков: Цикл сезонных программ интеллектуальной школы направлен на погружение школьников в мир науки и техники.
			</p>
			<p class="NewsPage-sub-title">
				– Каждый сезон мы приглашаем школьников из одного муниципалитета, чтобы они познакомились с научной деятельностью в Образовательном центре. Это помогает ребятам включиться в исследовательскую деятельность и получить хорошую базу для того, чтобы в дальнейшем принимать участие в научных проектах и мероприятиях «Персея», – отметил директор Образовательного центра.
			</p>
			<p class="NewsPage-sub-title">
				Научный интенсив был посвящен изучению биологии, физики и химии. Также иркутские школьники смогли принять участие в профориентационных мероприятиях и тренингах на командообразование.
			</p>
			<p class="NewsPage-sub-title">
				Следующая программа интеллектуальной школы пройдет в Образовательном центре – летом. Для участников будет подготовлена насыщенная программа, на которой они смогут познакомиться с научной и  технической деятельностью, которая востребована среди школьников Приангарья.
			</p>

		</div>
	</div>
</template>
