<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			25.09.2023
		</p>
		<h2 class="NewsPage-title">
			В «ПЕРСЕЕ» ПРОШЛА ПРОГРАММА ДЛЯ ЮНЫХ ЖЕЛЕЗНОДОРОЖНИКОВ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage7.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персее» завершилось обучение по программе «Страна железных дорог». В профильной смене приняли участие 50 школьников из г. Иркутска, г. Слюдянки и г. Усть-Кута.
			</p>
			<p class="NewsPage-sub-title">
				Как сообщают организаторы смены, основной целью обучения стало знакомство школьников со спецификой работы железных дорог и специальностями железнодорожного вуза региона.
			</p>
			<p class="NewsPage-sub-title">
				В этом году обучение по программе проходило по трем направлениям: робототехника, экология и цифровые технологии.
			</p>
			<p class="NewsPage-sub-title">
				В рамках направлений ребята научились работать с навигационными системами, электрическими схемами и электронными компонентами. Получили актуальные навыки в области природоохранной деятельности, программирования и прототипирования, а также проектирования, разработки экспертных систем, криптографии и основ построения компьютерных сетей.
			</p>
			<p class="NewsPage-sub-title">
				С юными железнодорожниками работали специалисты и преподаватели главного транспортного вуза Восточной Сибири – Иркутского государственного университета путей сообщения.
			</p>
			<p class="NewsPage-sub-title">
				Главными событиями смены стали открытые диалоги с представителями дирекций ОАО «Российские железные дороги», проректорами и деканами факультетов университета путей сообщения. На встречах школьники узнали о флагманских проектах холдинга, а также познакомились с направлениями подготовки вуза и прошли профориентационные тренинги.
			</p>
			<p class="NewsPage-sub-title">
				В завершении смены участники программы представили свои проекты, которые они разработали во время обучения. Экспертная комиссия высоко оценила современный и творческий подход школьников при решении проектных задач. Лучшие идеи будут доработаны и пойдут в основу будущих проектов регионального холдинга ОАО «РЖД».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, профильная смена «Страна железных дорог» в Образовательном центре проходила во второй раз при поддержке Иркутского государственного университета путей сообщения.
			</p>
		</div>
	</div>
</template>
