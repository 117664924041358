<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage106' })">
						<EventsCard106></EventsCard106>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage47' })">
						<EventsCard47></EventsCard47>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard47 from '../EventsCard/EventsCard47';
import EventsCard106 from '../EventsCard/EventsCard106';

	export default {
		components: {
			EventsCard47,
			EventsCard106,
		}
	}
</script>
