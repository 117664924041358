<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			28.05.2024
		</p>
		<h2 class="NewsPage-title">
			Юная художница из Иркутска работает над выпуском второго тома атласа XIX века в «Сириусе»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage62.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Сириус» школьники готовят к изданию второй том атласа редких растений Крыма и Кавказа
				Федора Биберштейна, созданного в начале позапрошлого века. В программе участвуют ребята из 21 региона страны, в
				их числе и ученица Лицея №3 г. Иркутск – Юлия Гусак.
			</p>
			<p class="NewsPage-sub-title">
				Первый сборник воспитанники «Сириуса» выпустили в марте этого года. Его подарили президенту России Владимиру
				Путину на Всемирном фестивале молодежи, проходившем на федеральной территории.
			</p>
			<p class="NewsPage-sub-title">
				Сейчас дети работают над второй книгой. В нее войдет информация о 50 редких видах южной флоры с описаниями и
				иллюстрациями. Данные о 20 из них публикуются впервые. Над описаниями трудятся учащиеся образовательных программ
				направления «Наука».
			</p>
			<p class="NewsPage-sub-title">
				Важнейшая часть этой работы - иллюстрирование образцов. Юные художники, фиксируя мельчайшие детали, создают для
				книги акварельные иллюстрации в технике ботанического рисунка под руководством педагогов Академии акварели и
				изящных искусств Сергея Андрияки. В проект включены 90 художников «Сириуса». Каждый из них подготовит 1-2 работы
				для второго тома Атласа редких растений под руководством педагогов Академии акварели Андрияки. Коллегиально
				будут выбраны лучшие, которые войдут в книгу. Партнёрами проекта выступают Российская национальная библиотека,
				издательство «Наука», Университет “Сириус”, Никитский ботанический сад, Санкт-Петербургский филиал архива
				Российской академии наук, Академия акварели и изящных искусств Сергея Андрияки.
			</p>
			<p class="NewsPage-sub-title">
				«Сейчас практически завершена работа над иллюстрациями. Следующий этап – ботанические описания, эту работу будут
				делать дети-биологи. На редактирование описания отправим в Ботанический институт имени Комарова Российской
				Академии Наук, где корректировки внесет специалист самого высокого уровня — редактор Красной Книги Российской
				Федерации, доктор биологических наук Ольга Баранова. По неизданной части мы совещались с коллегами в Петербурге.
				Я знакомился с оригинальными рисунками первого иллюстратора атласа Якова Матеса, зарисовками других художников,
				и рисунки детей из «Сириуса» не уступают оригиналам». — рассказал кандидат биологических наук, один из
				координаторов проекта Олег Коротков.
			</p>
			<p class="NewsPage-sub-title">
				Уникальный атлас южных растений создан в 1810 году ученым, путешественником, ботаником Федором Биберштейном.
				Труд должен был содержать описания 100 редких растений юга России, но при жизни ученый смог издать только один
				том, включавший описание 50 растений. Он вышел тиражом 70 экземпляров. В мире сохранилось не более 10 оригиналов
				книги. В частности, два хранятся в Российской национальной библиотеке в Санкт-Петербурге и Никитском
				ботаническом саду в Крыму. В электронном виде издание недоступно.
			</p>
			<p class="NewsPage-sub-title">
				С 2020 года учащиеся Образовательного центра «Сириус» работают над завершением труда Биберштейна, дополняя
				работу ученого актуализированными описаниями растений и акварельными рисунками. Первый том обновленного атласа
				увидел свет в начале 2024 года. Поддержку в выпуске второго тома книги «Сириусу» оказывает также архив Академии
				наук в Санкт-Петербурге.
			</p>
			<p class="NewsPage-sub-title">
				«Ботаническая живопись исторически всегда была раскрашена акварелью. Для работы в жанре необходимо уверенное
				владение рукой, навык прорисовки тонких линий. Дети, придерживаясь стилистики раритета Биберштейна, делают свои
				композиции. Сперва мы утверждаем похожесть растения, его правильное масштабирование с экспертом по ботанике,
				потом переходим к работе акварелью. Изначально ботаника была наукой, схожей с анатомией, от иллюстратора
				требовалось точно фиксировать мельчайшие детали растений. Мы придерживаемся более живописной подачи, делаем
				растяжку цветом», — говорит преподаватель факультета дополнительного образования Академии акварели и изящных
				искусств Сергея Андрияки Марина Зимина.
			</p>
			<p class="NewsPage-sub-title">
				Координаторы проекта убеждены: судя по реакции профессионального сообщества и широкого круга читателей на
				переиздание первого тома атласа Биберштейна, желающих получить второй будет много.
			</p>
			<p class="NewsPage-sub-title">
				Книга, которую «Сириус» вместе со своими партнерами готовит к изданию, будет интересна как художникам, так и
				ботаникам-профессионалам: многие виды растений описаны Биберштейном впервые, а «Сириус» представит современный
				взгляд на них. А благодаря работе детей на программах «Сириуса» под руководством педагогов Академии акварели и
				изящных искусств Сергея Андрияки, научное издание станет еще и произведением искусства.
			</p>

		</div>
	</div>
</template>
