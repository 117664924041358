<template>
	<div class="Financial _cover">
		<h2 class="Financial-title _cover-title">
			Финансово-хозяйственная деятельность
		</h2>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9E%D1%82%D1%87%D0%B5%D1%82-%D0%BE-%D1%80%D0%B5%D0%B7%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%82%D0%B0%D1%85-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%B3%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-%D1%83%D1%87%D1%80%D0%B5%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B7%D0%B0-2023%D0%B3.pdf"
					target="_blank">
					Отчет о результатах деятельности государственного учреждения за 2023 год
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9E%D1%82%D1%87%D0%B5%D1%82-%D0%BE-%D1%84%D0%B8%D0%BD%D0%B0%D0%BD%D1%81%D0%BE%D0%B2%D1%8B%D1%85-%D1%80%D0%B5%D0%B7%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%82%D0%B0%D1%85-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D1%83%D1%87%D1%80%D0%B5%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B7%D0%B0-2023%D0%B3.pdf"
					target="_blank">
					Отчет о финансовых результатах деятельности учреждения за 2023 год
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BB%D0%B0%D0%BD-%D0%A4%D0%A5%D0%94-2023%D0%B3.pdf"
					target="_blank">
					План ФХД 2023 год
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BB%D0%B0%D0%BD-%D0%A4%D0%A5%D0%94-2024%D0%B3.pdf"
					target="_blank">
					План ФХД 2024 год
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9E%D0%A6-%D0%9F%D0%B5%D1%80%D1%81%D0%B5%D0%B9-%D1%81-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F%D0%BC%D0%B8-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D1%8B-%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%BE%D0%BC-%D0%9D%D0%A1-%E2%84%964-%D0%AD%D0%91-%D0%BE%D1%82-14.03.2024.pdf"
					target="_blank">
					Положение о закупке товаров, работ, услуг для нужд Государственного автономного нетипового учреждения дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Персей»
				</a>
			</li>
		</ul>

	</div>
</template>