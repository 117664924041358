<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage181' })">
						<events-card181></events-card181>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage30' })">
						<events-card30></events-card30>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage48' })">
						<events-card48></events-card48>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage32' })">
						<events-card32></events-card32>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard32 from '../EventsCard/EventsCard32';
import EventsCard30 from '../EventsCard/EventsCard30';
import EventsCard48 from '../EventsCard/EventsCard48';
import EventsCard181 from '../EventsCard/EventsCard181';

	export default {
		components: {
			EventsCard32,
			EventsCard30,
			EventsCard48,
			EventsCard181,
		}
	}
</script>
