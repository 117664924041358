<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			Региональный конкурс инфобаннеров научно-технической направленности «MODERN TECH»
		</h2>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/concurs/view/228" target="_blank">
			Подробнее
		</a>
		<hr>

		<p class="EventsPage-text">
			Целью Конкурса является повышение интереса обучающихся в сфере инноваций и высоких технологий с учетом экологизации современного цифрового производства.
		</p>
		<p class="JuniorProfi-sub-title">
			Номинации конкурса
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Интернет вещей
			</li>
			<p class="EventsPage-text">
				Возобновляемая энергетика
			</p>
			<li class="JuniorProfi-items">
				Транспорт будущего
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			О конкурсе
		</p>
		<p class="EventsPage-text">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-1444-%D0%BC%D1%80-%D0%BE%D1%82-25.08.21-MODERN-TECH.pdf" target="_blank">
			Распоряжение 1444-мр от 25.08.21 MODERN TECH
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5-%D0%9C%D0%BE%D0%B4%D0%B5%D1%80%D0%BD.pdf" target="_blank">
			Положение о конкурсе MODERN TECH
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/09/%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-%D0%B8%D0%BD%D1%84%D0%BE%D0%B1%D0%B0%D0%BD%D0%BD%D0%B5%D1%80%D0%BE%D0%B2-Modern-Tech.pdf" target="_blank">
			О проведении регионального конкурса инфобаннеров Modern Tech
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2021/12/%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-Modern-Tech.pdf" target="_blank">
			Об итогах регионального конкурса Modern Tech
		</a>
		<hr>

		<p class="EventsPage-text">
			Условия участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Для участия в Конкурсе необходимо предоставить авторский инфобаннер.
			</li>
			<li class="JuniorProfi-items">
				Конкурс проводится в один этап, в формате заочного участия. Прием заявок с 18 октября до 7 ноября 2021 г.
			</li>
			<li class="JuniorProfi-items">
				Требования, предъявляемые к участникам Конкурса: индивидуальное участие или коллективное участие. Коллектив, общим количеством от двух до пяти человек, возглавляемый руководителем (педагогическим работником).
			</li>
			<li class="JuniorProfi-items">
				В состав коллектива участников, кроме руководителя, могут входить обучающиеся от 14 до 18 лет (включительно) общеобразовательных организаций, профессиональных образовательных организаций и организаций дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Руководителем может быть педагогический работник образовательной организации Иркутской области, курирующий конкурсную работу по указанным направлениям.
			</li>
			<li class="JuniorProfi-items">
				Члены коллектива могут быть представителями одной образовательной организации или разных образовательных организаций Иркутской области. Каждый участник или команда может подать на Конкурс только один проект в каждой номинации.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Документы для участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Выдвижение участников Конкурса осуществляется путем предоставления следующих документов:
			</li>
			<li class="JuniorProfi-items">
				заявление на участие в Конкурсе с отметкой о согласии принять участие в Конкурсе (приложение 1 к данному Положению);
			</li>
			<li class="JuniorProfi-items">
				анкета руководителя проекта (приложение 2 к Положению);
			</li>
			<li class="JuniorProfi-items">
				пояснительная записка (приложение 3 к Положению);
			</li>
			<li class="JuniorProfi-items">
				инфобаннер в формате JPEG.
			</li>
		</ul>
		
	</div>
</template>