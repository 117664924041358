<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage60.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» завершились еженедельные курсы по хореографии
			</h2>
			<p class="NewsCard-date">
				07.05.2024
			</p>
		</div>
	</div>
</template>