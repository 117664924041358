<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Областной конкурс
		</h3>
		<h2 class="EventsPage-title">
			XXII ОБЛАСТНОЙ КОНКУРС «ЛУЧШИЙ УЧЕНИК ГОДА»
		</h2>
		<p class="EventsPage-date">
			Муниципальный этап. Срок проведения - с 14 по 18 ноября 2022 года.
		</p>
		<p class="EventsPage-date">
			Региональный этап. Срок проведения – с 29 ноября по 02 декабря 2022 года.
		</p>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация на региональный этап
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/744/?date=2022-11-02" target="_blank">
			Регистрация на региональный этап в системе "Навигатор"
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Задачи конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				выявление и поощрение наиболее активных и творческих обучающихся
			</li>
			<li class="JuniorProfi-items">
				создание условий для самореализации и раскрытия творческого потенциала обучающихся
			</li>
			<li class="JuniorProfi-items">
				стимулирование общественной, творческой и познавательной активности обучающихся
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Положение XXII областного конкурса Лучший ученик года
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-123-%D0%BA-%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8E.docx" target="_blank">
			Приложения 1,2,3 к положению
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Участники:
		</p>
		<p class="EventsPage-text">
			В Конкурсе на добровольной основе принимают участие обучающиеся 8-10-х классов образовательных организаций Иркутской области.
		</p>
		<p class="EventsPage-text">
			В региональном этапе конкурса принимают участие победители муниципального этапа конкурса.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Формат проведения регионального этапа конкурса:
		</p>
		<p class="EventsPage-text">
			Региональный этап пройдет очно, на базе кампуса Образовательного центра «Персей» по адресу: Иркутская область, Ангарский район, 8,321 км автодороги Ангарск-Тальяны.
		</p>
		<hr>

		<table>
			<caption>
				Структура регионального этапа конкурса:
			</caption>
			<thead>
				<tr>
					<th>Конкурсное задание</th>
					<th>Формат конкурсного задания</th>
					<th>Регламент выступления и требования</th>
				</tr>
			</thead>
			<tbody align="center">
				<tr>
					<td colspan="3">1 тур</td>
				</tr>
				<tr>
					<td>Визитка</td>
					<td>Творческая публичная индивидуальная презентация своих личных качеств и достижений.</td>
					<td>На презентацию дается более 3 минут. Формат подготовки: домашнее задание.</td>
				</tr>
				<tr>
					<td>Эссе</td>
					<td>Конкурсантам необходимо написать эссе, тема которого сообщается непосредственно перед выполнением конкурсного задания. Не допускается использование интернет-ресурсов, а также материалов, приготовленных заранее.</td>
					<td>На выполнение задания у участников 60 минут. Требования к оформлению эссе: текст не более 1 страницы.</td>
				</tr>
				<tr>
					<td colspan="3">2 тур</td>
				</tr>
				<tr>
					<td>Виртуальная экскурсия «Мой край – место, где я живу»</td>
					<td>Конкурсантам необходимо разработать и презентовать виртуальную экскурсию по теме «Мой край – место, где я живу». Конкурсантам разрешается использовать информационные материалы по истории Иркутской области, истории муниципального образования, фото- и видеоматериалы. Виртуальная экскурсия разрабатывается в виде видео-ролика в видеоредакторах Movavi или ВидеоМонтаж и сопровождается устным сопровождением конкурсанта.</td>
					<td>На выполнение задания у участников 2,5 часа. Время на презентацию конкурсного задания не менее 2 и не более 3 минут.</td>
				</tr>
				<tr>
					<td>Дебаты</td>
					<td>Конкурсное задание проводится в форме публичного диалога на заданную тему в парах участников, которые определяются по жребию. Тема дебатов объявляется перед началом конкурсного задания.</td>
					<td>Каждой паре участников дается 20 минут для проведения дебатов.</td>
				</tr>
				<tr>
					<td colspan="3">3 тур</td>
				</tr>
				<tr>
					<td>Решение кейсов</td>
					<td>Решение проблемного кейса.</td>
					<td>На выполнение задания у участников 60 минут.</td>
				</tr>
				<tr>
					<td>Круглый стол</td>
					<td>Участники за столом обсуждают актуальные темы.</td>
					<td>На конкурсное задание дается 60 минут.</td>
				</tr>
			</tbody>
		</table>
		<hr>

		<p class="JuniorProfi-sub-title">
			Как участвовать в региональном этапе:
		</p>
		<p class="EventsPage-text">
			С 21 по 23 ноября 2022 года подать заявки:
		</p>
		<p class="EventsPage-text">
			— на сайте konkurs.ric38.ru в разделе «Ученик года» (к заявке нужно загрузить приложения 1, 2, 3 Положения).
		</p>
		<p class="EventsPage-text">
			— в системе Навигатор: р38.навигатор.дети/activity/744/?date=2022-11-02
		</p>
	</div>
</template>