<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Танцевальная импровизация»
		</h2>
		<p class="EventsPage-date">
			с 15 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24546-programma-tantsevalnaya-improvizatsiya" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			К освоению программы допускаются учащиеся общеобразовательных организаций и организаций дополнительного образования (ДШИ) в возрасте 10-17 лет. Предварительная подготовка учащихся должна заключаться в сформированных двигательных, координационных и музыкальных навыках, полученных в хореографических коллективах.
		</p>

		<p class="EventsPage-text">
			Учебные занятия проводятся очно с периодичностью 2 раза в неделю, с использованием специального оборудования: хореографический зал, зеркала, станки и специальная форма для занятий хореографией.
		</p>
		<p class="EventsPage-text">
			По итогам обучения выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Намаконова Лариса Владимировна, главный балетмейстер ГБУ ДО ИО «Центр развития творчества детей и юношества «Узорочье».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться в системе Навигатор дополнительного образования Иркутской области до 20 февраля 2024 года по ссылке: <a style="color:blue" href="https://р38.навигатор.дети/program/24546-programma-tantsevalnaya-improvizatsiya">https://р38.навигатор.дети/program/24546-programma-tantsevalnaya-improvizatsiya</a>
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>