<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			11.09.2023
		</p>
		<h2 class="NewsPage-title">
			УЧАЩИЕСЯ КАДЕТСКИХ КАЗАЧЬИХ КЛАССОВ ИЗУЧАТ НАЦИОНАЛЬНЫЕ ТРАДИЦИИ СИБИРСКИХ КАЗАКОВ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage5.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» стартовала профильная смена «Традиции казачьей культуры». В региональном минобразования рассказали, что участие в мероприятии принимают 77 школьников, которые обучаются в классах казачьей направленности школ Ангарского городского округа, Куйтунского и Качугского районов.
			</p>
			<p class="NewsPage-sub-title">
				По информации министра образования Иркутской области Максима Парфенова, на профильную смену собрались победители отборочного этапа областного фестиваля «Школа казачьей культуры», стартовавшего весной 2023 года.
			</p>
			<p class="NewsPage-sub-title">
				Региональным оператором мероприятия выступил Центр развития творчества детей и юношества «Узорочье», при участии Образовательного центра «Персей» и Иркутского войскового казачьего общества.
			</p>
			<p class="NewsPage-sub-title">
				– Победители отборочного этапа по условиям фестиваля получили уникальную возможность приехать на профильную смену всем классом со своим педагогом и вместе с преподавателями образовательной программы на две недели погрузиться в мир традиций иркутского казачества, – отметил Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Глава регионального ведомства подчеркнул, что фестиваль организован в рамках мероприятий по исполнению указа Президента Российской Федерации «О стратегии государственной политики Российской Федерации в отношении российского казачества».
			</p>
			<p class="NewsPage-sub-title">
				Министерством образования Иркутской области с 2020 года реализуется План мероприятий (дорожная карта) по организации деятельности казачьих кадетских классов в общеобразовательных организациях Приангарья, подписано соглашение о сотрудничестве и взаимодействии между министерством и Иркутским войсковым казачьим обществом. В 2022-2023 учебном году в 17 казачьих классах обучалось триста детей.
			</p>
			<p class="NewsPage-sub-title">
				Такие классы созданы в восьми муниципальных образованиях региона. Организаторы образовательной программы в центре «Персей» рассказали, что участники смены изучают традиции и историю казачьей культуры, осваивают строевую и огневую подготовку, для ребят проходят уроки по самообороне и основам выживания. Отдельный блок программы посвящен изучению основ православной культуры и семейных ценностей, казачьим песням, танцам, играм и обрядам.
			</p>
			<p class="NewsPage-sub-title">
				Помимо обучения пройдут соревнования по стрельбе, оказанию первой доврачебной помощи и фланкировке шашкой. В завершении смены определят лучших кадетов-казачат.
			</p>
			<p class="NewsPage-sub-title">
				В министерстве образования пояснили, что в сентябре 2023 года в Региональном центре выявления и поддержки одаренных детей «Персей» стартовал уже четвертый сезон профильных образовательных программ.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, центр был создан в 2020 году благодаря федеральному проекту «Успех каждого ребенка» национального проекта «Образование». За это время здесь провели для школьников Иркутской области более 200 образовательных программ научной, творческой и спортивной направленности.
			</p>
		</div>
	</div>
</template>
