<template>
	<div class="Scholarships _cover">
		<h2 class="Scholarships-title _cover-title">
			Вакантные места для приема (перевода) обучающихся
		</h2>
		<h3 class="Scholarships-title-box">
			Вакантные места для приема (перевода) обучающихся:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляются
		</p>
	</div>
</template>