<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage137' })">
						<EventsCard137></EventsCard137>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage29' })">
						<EventsCard29></EventsCard29>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard29 from '../EventsCard/EventsCard29';
import EventsCard137 from '../EventsCard/EventsCard137';

	export default {
		components: {
			EventsCard29,
			EventsCard137,
		}
	}
</script>
