<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			28.06.2024
		</p>
		<h2 class="NewsPage-title">
			Вторые летние тренировочные сборы по борьбе завершились в «Персее»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage71.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» завершились вторые летние тренировочные сборы «Дзюдо. Путь к успеху». Их
				участниками стали 38 воспитанников спортивных школ из Иркутска, Иркутского и Черемховского районов.
			</p>
			<p class="NewsPage-sub-title">
				Программа сборов была направлена на подготовку к предстоящему соревновательному сезону. Под руководством опытных
				тренеров ребята совершенствовали общие и специальные физические качества.
			</p>
			<p class="NewsPage-sub-title">
				Тренеры рассказали, что учебно-тренировочные сборы – важная часть тренировочного процесса. Они не только
				способствуют улучшению физической формы и технических навыков, но и играют ключевую роль в подготовке
				спортсменов. В ходе сборов ребята имеют возможность работать над своими слабыми сторонами и укреплять
				выносливость. Также сборы способствуют укреплению командного духа и сплочению коллектива, что не менее важно для
				достижения высоких результатов на соревнованиях.
			</p>
			<p class="NewsPage-sub-title">
				– Вот уже как три года мы готовимся к соревновательному сезону в Образовательном центре и видим хорошие
				результаты на соревнованиях, – отмечает Сергей Иванов, мастер спорта России по дзюдо, тренер Иркутской областной
				федерации дзюдо. На сборах мы стараемся делать уклон не только на на физическую подготовку ребят, но и на
				психологическую, которая не менее важна для спортсмена. Для ребят мы проводим специальные тренинги и занятия,
				которые помогают справляться спортсменам со стрессом, повышают концентрацию и делают их более уверенными в своих
				спортивных навыках. «Персей» – замечательное место для подготовки. Здесь есть все необходимые условия, чтобы
				увеличить спортивные показатели перед стартами. С уверенностью могу сказать, что и эти сборы – влияют на
				спортивный результат детей.
			</p>
			<p class="NewsPage-sub-title">
				Сами ребята также отмечают, что их уровень физической подготовки значительно вырос. Так, Юлия Батанова, ученица
				школы №3 г. Иркутск, в «Персей» приехала в третий раз. В дзюдо девушка уже четыре года. За это время она
				получила разряд кандидата в мастера спорта и заняла первое место в первенстве Сибирского Федерального округа по
				дзюдо среди девушек до 18 лет в весовой категории до 44 кг.
			</p>
			<p class="NewsPage-sub-title">
				– Все, чему нас здесь обучают, дает отличные результаты. Четко выстроенный тренировочный процесс, правильно
				подобранные упражнения, а также общение с другими спортсменами и тренерами значительно помогают в подготовке. К
				старту сезона я чувствую себя намного увереннее в своих силах. Уже в августе я поеду на спартакиаду по дзюдо в
				Армавир и уверена в своей подготовке на все сто процентов, – поделилась Юлия.
			</p>
			<p class="NewsPage-sub-title">
				Дзюдо входит в перечень базовых видов спорта Иркутской области, а подготовка молодых спортсменов является одним
				из главных направлений работы Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				– Ежегодно в «Персее» проходят смены по дзюдо, на протяжении которых мы наблюдаем за профессиональным ростом
				наших воспитанников. Школьники достигают высоких результатов, становясь призерами и победителями соревнований
				как на региональном, так и на всероссийском уровнях. Мы гордимся их достижениями и уверены в важности нашей роли
				в их спортивной карьере. Каждый год мы приглашаем высококвалифицированных тренеров и создаем все необходимые
				условия для развития талантов наших спортсменов, – поделился Алексей Шестаков, директор Образовательного центра
				«Персей».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, с июня этого года в Образовательном центре прошли четыре профильные спортивные смены в формате
				учебно-тренировочных сборов для школьников, которые увлечены боевыми искусствами: самбо, дзюдо и греко-римская
				борьба. Участие в них приняли более 200 детей со всего региона.
			</p>

		</div>
	</div>
</template>
