<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage61.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» завершилась подготовка к отборочному этапу Всероссийской телевизионной олимпиады «Умницы и умники»
			</h2>
			<p class="NewsCard-date">
				27.05.2024
			</p>
		</div>
	</div>
</template>