<template>
	<div class="Scholarships _cover">
		<h2 class="Scholarships-title _cover-title">
			Стипендии и меры поддержки обучающихся
		</h2>
		<h3 class="Scholarships-title-box">
			Наличие и условия предоставления стипендий:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляется
		</p>
		<h3 class="Scholarships-title-box">
			Меры социальной поддержки:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляется
		</p>
		<h3 class="Scholarships-title-box">
			Наличие общежития, интерната в образовательной организации:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляется
		</p>
		<h3 class="Scholarships-title-box">
			Количество жилых помещений в общежитии:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляется
		</p>
		<h3 class="Scholarships-title-box">
			Формирование платы за проживание в общежитии:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляется
		</p>
		<h3 class="Scholarships-title-box">
			Трудоустройство выпускников:
		</h3>
		<p class="Scholarships-sub-title">
			Не предоставляется
		</p>
	</div>
</template>