<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			22.03.2024
		</p>
		<h2 class="NewsPage-title">
			В Приангарье завершился региональный конкурс девятого сезона научно-технологических проектов «Большие вызовы»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage50.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Всероссийский конкурс научно-технологических проектов «Большие вызовы» – один из главных проектов России. Его цель – включить школьников в работу над актуальными вызовами, стоящими перед страной, и обучить детей на примере реальных проектов, которые предлагают крупнейшие компании и вузы России.
			</p>
			<p class="NewsPage-sub-title">
				В Иркутской области  он проходит по семи направлениям: «Агропромышленные и биотехнологии», «Беспилотный транспорт и логистические системы», «Большие данные, искусственный интеллект, финансовые технологии и машинное обучение», «Космические технологии», «Современная энергетика», «Умный город и безопасность» и «Экология и изучение изменений климата».
			</p>
			<p class="NewsPage-sub-title">
				В марте 2024 года были подведены итоги регионального трека, участниками которого стали 23 учащихся из Иркутска, Братска и Нижнеудинска.
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы конкурса, региональный трек проходил в два этапа: отборочный и финальный. Отборочному этапу предшествовала образовательная программа в «Персее», где школьники под руководством наставников осваивали основы проектной деятельности и работали над своими идеями.
			</p>
			<p class="NewsPage-sub-title">
				В заключительном этапе ребята представляли свои проекты по семи направлениям: «Агропромышленные и биотехнологии», «Беспилотный транспорт и логистические системы», «Большие данные, искусственный интеллект, финансовые технологии и машинное обучение», «Космические технологии», «Современная энергетика», «Умный город и безопасность» и «Экология и изучение изменений климата».
			</p>
			<p class="NewsPage-sub-title">
				В рамках направлений ребята презентовали свои идеи по изучению фиторемедиационного потенциала овса посевного «Аvena sativa l» и кресс-салата «Lepidium sativum l» при загрязнении почв тяжелыми металлами методом биотестирования, созданию наземного многоцелевого беспилотного средства и внедрение его в военную отрасль, улучшению электровибрационного голосообразующего аппарата с помощью модуля Искусственного интеллекта, определению жизненного состояния деревьев тополя бальзамического в озеленении поселка. А также представили разработки космических аппаратов, трансформаторов и переносных персональных газоанализаторов.
			</p>
			<p class="NewsPage-sub-title">
				– Наши школьники демонстрируют высокий интерес к научной-исследовательской деятельности. Мы всеми силами готовы поддерживать молодые умы и их идеи, которые в дальнейшем могут оказать значительное влияние на развитие не только нашего региона, и страны в целом. И те проекты, которые представили финалисты регионального этапа, смогут стать отличным началом для их профессиональной самореализации. Ведь большое количество организаций и компаний не только в регионе, но и по всей стране готовы принять к себе на стажировки молодых изобретателей и исследователей, – отметил директор Образовательного центра “Персей” Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Победителями и призерами финального этапа стали учащиеся из  школы №19 с углубленным изучением отдельных предметов г. Иркутска, школ №1 и № 48 г. Нижнеудинска, Лицея №2 г. Братска, Лицея №1 г. Иркутска, Лицея ИГУ г. Иркутска.
			</p>
			<p class="NewsPage-sub-title">
				Полный список призеров и победителей размещен на: <a class="NewsPage-link" href="https://perseusirk.ru/BigChallenges2024">https://perseusirk.ru/BigChallenges2024</a>
			</p>
			<p class="NewsPage-sub-title">
				Сейчас ребята начнут активно готовиться к заключительному этапу конкурса, который проводит Образовательный центр «Сириус» Фонда «Талант и успех». Он будет состоять из двух туров: тестирование и индивидуальное собеседование. По итогам будут определены призеры и победители, которые в дальнейшем смогут стать участниками одноименной программы в «Сириусе».
			</p>

		</div>
	</div>
</template>
