<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-2">
			Спорт
		</h2>
		<h3 class="EventsCard-title">
			«Школа юного туриста-спасателя»
		</h3>
		<p class="EventsCard-sub-title">
			04.04.23-13.04.23
		</p>
	</div>
</template>