<template>
	<div class="HighestQualityPage _cover">
		<h2 class="HighestQualityPage-title _cover-title">
			Высшая проба
		</h2>
	</div>

		<!-- Архив -->
		<ArchiveHighestQuality />
		
</template>

<script>
import ArchiveHighestQuality from '@/components/ArchiveHighestQuality.vue';

export default {
	components: {
		ArchiveHighestQuality,
	}
}

</script>