<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Байкальские звезды «Абилимпикса».
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 15 по 21 декабря 2023 года.
		</h4>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24164-programma-baikalskie-zvezdy-abilimpiksa" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Байкальские звезды «Абилимпикса» для учащихся 5-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа ориентирована на создание мотивирующей образовательной среды, умение адаптироваться в различных ситуациях, эффективное взаимодействие со сверстниками и педагогами.
		</p>
		<p class="EventsPage-text">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/tbSJxDgX-OoHxw" target="_blank">
			Сертификаты
		</a>
		
		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>