<template>
	<div class="BigChallengesPage _cover">
		<h2 class="BigChallengesPage-title _cover-title">
			Всероссийский конкурс научно-технологических проектов «Большие вызовы»
		</h2>

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/BW.webp" alt="Логотип" />
		</div>

		<a class="GoldenFundOfSiberia-btn temp-btn" href="https://konkurs.sochisirius.ru/">
			Сайт конкурса
		</a>

		<p class="JuniorProfi-sub-title">
			О конкурсе:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Образовательный центр «Сириус» предлагает участникам оформить свои идеи, разработки и исследования в проект и
				представить его для оценки экспертам.
			</li>
			<li class="JuniorProfi-items">
				Цель конкурса – выявление и развитие у молодежи творческих способностей, интереса к проектной, научной
				(научно-исследовательской), инженерно-технической, изобретательской и творческой деятельности, популяризация
				научных знаний и достижений.
			</li>
			<li class="JuniorProfi-items">
				В конкурсе принимают участие ученики школ и учреждений среднего профессионального образования, уровня 7 — 11
				классов.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Как участвовать в конкурсе:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Образовательный Центр «Персей» проводит очную профильную смену по подготовке к региональному треку Конкурса. В
				профильной смене школьники под руководством наставников и экспертов разрабатывают научно-технологический проект.
			</li>
			<li class="JuniorProfi-items">
				Готовый проект участники размещают на сайте Образовательного Центра «Сириус», где проходит отборочный тур
				регионального трека Конкурса.
			</li>
			<li class="JuniorProfi-items">
				По итогам отборочного трека участники приглашаются к очной защите проектов на заключительный тур регионального
				трека Конкурса.
			</li>
			<li class="JuniorProfi-items">
				Информация о старте Конкурса в 2024 г. будет опубликована после сентября 2024 г.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Структура Конкурса:
		</p>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td><b>Региональный трек</b></td>
					<td><b>Дистанционный трек</b></td>
				</tr>
				<tr>
					<td>1. Отборочный тур (дистанционный).<br>
						2. Заключительный тур (очный).
					</td>
					<td>Дистанционный конкурс проходит тех школьников, тематика проекта<br> которых не совпадает с направлениями
						очного конкурса в регионе.</td>
				</tr>
				<tr>
					<td colspan="2"><b>Заключительный этап конкурса (Федеральный уровень)</b></td>
				</tr>
				<tr>
					<td colspan="2">Два тура (выполнение заданий – тестирование и индивидуальное собеседование)</td>
				</tr>
			</table>
		</div>

		<p class="JuniorProfi-sub-title">
			Что является проектной работой в рамках конкурса?
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Проектная деятельность – особый способ работы, в котором участники проходят полный жизненный цикл проекта в
				специальной учебной форме. Школьный проект опирается на те же понятия, мерки и стандарты, которые существуют в
				настоящем проектировании и исследовании.
			</li>
			<li class="JuniorProfi-items">
				Проектная деятельность призвана решить актуальную проблему, поэтому проект начинается с анализа ситуации,
				фиксации проблемы и выявления передовых методов и технологий решения.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Проектная работа должна отвечать следующим характеристикам:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Участник достигает востребованного результата, его работа важна и актуальна и разворачивается в области,
				связанной с передовыми исследованиями и разработками.
			</li>
			<li class="JuniorProfi-items">
				Участник понимает, как и кем будет использоваться полученный им результат. Однако это не означает, что
				школьники всегда должны выполнять проекты на уровне взрослых профессионалов. Завышая планку, руководители
				проектов порой ставят школьников в зависимую позицию исполнителя, не дающую опыт целостного освоения культуры
				организации и проектирования своей работы.
			</li>
			<li class="JuniorProfi-items">
				Речь идет о научной или технологической новизне. Лабораторная работа или конструирование по известному
				алгоритму не являются полноценными проектами. Инновационные решения, в свою очередь, требуют высокого уровня
				освоения участниками фундаментальной предметной базы.
			</li>
			<li class="JuniorProfi-items">
				Участник получает опыт работы с проблемной областью, постановки целей и задач, опыт получения нового решения,
				опыт организации деятельности.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Направления конкурса в Иркутской области:
		</p>

		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/agro">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/Агропромышленные_и_биотехнологии.svg"
							alt="Логотип Агропромышленные и биотехнологии">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Агропромышленные и биотехнологии
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/bigdata">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/Большие_данные_искусственный_интеллект_финансовые_технологии_и_машинное_обучение.svg"
							alt="Логотип Большие данные, искусственный интеллект, финансовые технологии и машинное обучение">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Большие данные, искусственный интеллект, финансовые технологии и машинное обучение
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/space">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/Космические_технологии.svg" alt="Логотип Космические технологии">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Космические технологии
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/energo">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/Современная_энергетика.svg" alt="Логотип Современная энергетика">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Современная энергетика
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/smct">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/Умный_город_и_безопасность.svg"
							alt="Логотип Умный город и безопасность">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Умный город и безопасность
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/nano">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/Нанотехнологии.webp" alt="Логотип Нанотехнологии">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Нанотехнологии
					</p>
				</div>
			</div>
		</div>

		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://konkurs.sochisirius.ru/nano">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/BigChallenges/ЭкологияИизменениеКлимата-01.webp" alt="Логотип Экология">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Экология и изучение изменений климата
					</p>
				</div>
			</div>
		</div>

		<h2 class="BigChallenges-title _cover-title">
			Методические рекомендации
		</h2>
		<div class="Preparation-iframe-box">
			<iframe class="Preparation-iframe" width="560" height="315"
				src="https://www.youtube.com/embed/1TnZFFhL92Y?si=mByM81gI6gJgUf5w" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315"
				src="https://www.youtube.com/embed/aaP8MYJvv2s?si=0aUGyfitSEBuLVea" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315"
				src="https://www.youtube.com/embed/k970a4OhShc?si=t7BlTsPI1tfPVD0-" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315"
				src="https://www.youtube.com/embed/PQjWsaScP0E?si=4XXR1oNDtVonTRII" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen></iframe>
		</div>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/BigChallenges/robbot.webp"
						alt="Логотип Министерство образования Иркутской области">
				</div>
			</div>
		</div>

	</div>


	<!-- Архив -->
	<ArchiveBigChallenges />

</template>

<script>
import ArchiveBigChallenges from '@/components/ArchiveBigChallenges.vue';

export default {
	components: {
		ArchiveBigChallenges,
	}
}

</script>