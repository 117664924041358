<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.12.2023
		</p>
		<h2 class="NewsPage-title">
			Более 100 педагогов стали участниками первой программы курсов повышения квалификации для хореографов
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage30.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одаренных детей «Персей» впервые прошла программа курсов повышения квалификации «Особенности работы с одаренными детьми в системе хореографического образования». Первыми ее слушателями стали 105 педагогов-хореографов из Саянска, Иркутска, Свирска, Усолье-Сибирского, Зимы, Черемхово, Тулун, Ангарск, Братска, Усольского, Иркутского, Шелеховского, Братского, Алзамайского, Уриковского, Атагайского, Осинского, Ангарского, Тайшетского и Аларского районов.
			</p>
			<p class="NewsPage-sub-title">
				Программа проходила в дистанционном и очном формате. Очное обучение включало серию мастер-классов на базе кампуса Образовательного центра. На него приехали 54 педагога. Участники изучили детские танцы, балетную гимнастику, детскую нейробику, фольклорные танцы, а также методику создания хореографического произведения.
			</p>
			<p class="NewsPage-sub-title">
				По словам организаторов курса, помимо практических занятий, программа включала модули по психолого-педагогическому сопровождению одаренных детей.
			</p>
			<p class="NewsPage-sub-title">
				– Когда мы говорим про работу с одаренными и талантливыми детьми, очень важно понимать, что за ребенок перед нами и как раскрыть и реализовать его потенциал. Поэтому в первую очередь нужно было научить педагогов выявлять этих детей и грамотно выстраивать индивидуальный процесс обучения. Важно также понимать и то, что талант ребенка – это лишь половина успеха, остальное зависит от грамотных наставников, – отметила Ангелина Сыроватская, руководитель отдела развития образовательных программ по направлению «Искусство»
			</p>
			<p class="NewsPage-sub-title">
				На программе со слушателями работали ведущие преподаватели и балетмейстеры: Лариса Намаконова, главный балетмейстер центра развития творчества и детей юношества «Узорочье». Наталья Заика, педагог дополнительного образования школы №40 г. Иркутска, художественный руководитель хореографической школы «Аллегро». Педагоги Иркутского регионального колледжа педагогического образования Татьяна Мамаева и Наталья Бородихина, а также концертмейстер Иркутского областного колледжа культуры Любовь Бухарова.
			</p>
			<p class="NewsPage-sub-title">
				Курс занятий был разработан для преподавателей учреждений дополнительного образования, учреждений дошкольного и общего профессионального образования в области хореографического искусства.
			</p>
			<p class="NewsPage-sub-title">
				– Сейчас есть понимание того, с чего нужно начинать, чтобы добиться хорошего результата. Педагоги структурировали все наши знания, накопленные за долгие годы работы, поделились свои опытом из практики, дали теорию и методику преподавания. Но самое главное – они рассказали и показали, как нужно выстраивать работу одаренными детьми, как развивать их талант, к чему это может привести. Я с большим удовольствием прошла обучение, нашла единомышленников и теперь с новой волной вдохновения уже возвращаюсь к детям, – поделилась Ольга Иванова, участница программы из Ангарска.
			</p>
			<p class="NewsPage-sub-title">
				Следующие программы повышения квалификации для педагогов запланированы на 2024 год. Анонсы программ будут опубликованы на сайте Образовательного центра «Персей».
			</p>
			
		</div>
	</div>
</template>
