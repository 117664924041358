<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Дистанционный курс
		</h2>
		<h3 class="EventsCard-title">
			"Подготовка обучающихся к участию во ВСОШ"
		</h3>
		<p class="EventsCard-sub-title">
			
		</p>
	</div>
</template>