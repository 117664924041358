<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.10.2023
		</p>
		<h2 class="NewsPage-title">
			Иркутская область: прошел школьный этап ВсОШ по физике на платформе «Сириус.Курсы»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage10.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Всероссийская олимпиада школьников — крупнейшее интеллектуальное соревнование, в котором ежегодно принимают участие более 6 млн школьников. Олимпиада проводится в четыре этапа: школьный, муниципальный, региональный и заключительный. Самым массовым является школьный этап, который дает возможность миллионам учащихся познакомиться с интересными и нестандартными заданиями, составленными ведущими экспертами страны.
			</p>
			<p class="NewsPage-sub-title">
				В 2023 году в туре приняло участие 7819 школьников, из которых 1705 — за 7 класс, 1893 — за 8 класс, 2032 — за 9 класс, 1238  — за 10 класс и 951 участник за 11 класс.
			</p>
			<p class="NewsPage-sub-title">
				Процент школ-участниц в регионе составил 59.16 %.
			</p>
			<p class="NewsPage-sub-title">
				На сайте <a class="NewsPage-link" href="https://siriusolymp.ru/">siriusolymp.ru</a> уже доступны текстовые и видеоразборы заданий, которые помогут учителям провести показ и разбор выполненных работ и ответить на вопросы участников.
			</p>
			<p class="NewsPage-sub-title">
				Предварительные результаты станут доступны не позднее чем через 7 дней после проведения каждого тура. В это время школьники могут задать вопросы о несогласии с предварительными результатами школьного этапа ВсОШ, проведенного на платформе «Сириус.Курсы». Через 14 дней после тура в системе и в школах появляются окончательные результаты. После этого организаторы смогут определить победителей и призеров тура.
			</p>
			<p class="NewsPage-sub-title">
				Путь всех победителей и призеров заключительного этапа начинается со школьного этапа, прохождение которого <b>доступно каждому школьнику</b>. Ребятам, желающим написать олимпиаду, необходимо лишь ознакомиться с актуальным для их группы регионов <a class="NewsPage-link" href="https://siriusolymp.ru/about#schedule">графиком проведения</a>, а также запросить код участника в школе у учителя или организатора. Выполнять олимпиаду можно по нескольким предметам. <b>Участие является бесплатным и добровольным</b>.
			</p>
			<p class="NewsPage-sub-title">
				После олимпиад школьники могут изучить трудные темы, решить сложные задачи и попробовать новые для себя предметы на <b>Сириус.Курсах</b>.
			</p>
			<p class="NewsPage-sub-title">
				Следить за информацией о турах школьного этапа можно на сайте <a class="NewsPage-link" href="https://siriusolymp.ru/">siriusolymp.ru</a> и в <a class="NewsPage-link" href="https://vk.com/siriusolymp">социальных сетях</a>.
			</p>
		</div>
	</div>
</template>
