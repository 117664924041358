<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title" style="color: #14b4ac;">
			"Подготовка обучающихся к участию во ВСОШ"
		</h2>
		<h2 class="EventsPage-title">
			<img class="EventsPage-title-img" src="../assets/img/svg/ВСОШ.svg" alt="Всероссийская олимпиада школьников"
				loading="lazy">
		</h2>
		<hr>
		<div class="EventsPage-disc">
			<h3 class="EventsPage-nte" style="color: #14b4ac;">
				О курсах
			</h3>
			<p class="EventsPage-nte-dis">
				Формат проведения курсов – дистанционный, объем – 32 часа, ориентировочная дата начала – 18 октября 2023
				года.<br>
				Занятия будут проводить члены жюри региональных экспертных комиссий всероссийской олимпиады школьников 2023
				года, ведущие преподаватели высших образовательных организаций Иркутской области и лучшие учителя
				общеобразовательных организаций, имеющие опыт работы в сфере олимпиадного движения школьников.
			</p>
			<h3 class="EventsPage-nte" style="color: #14b4ac;">
				Участие
			</h3>
			<p class="EventsPage-nte-dis">
				К участию приглашаются обучающиеся Иркутской области.
			</p>
			<p class="EventsPage-nte-dis">
				Обучение проходит на бюджетной основе.
			</p>
			<p class="EventsPage-nte-dis">
				Участникам необходимо зарегистрироваться и подать заявку в системе Навигатор и в системе Moodle Образовательного
				Центра «Персей».
			</p>
			<p class="EventsPage-nte-dis">
				Дистанционный курс будут проходить на платформе Moodle.
			</p>
			<h3 class="EventsPage-nte" style="color: #14b4ac;">
				Расписание
			</h3>
			<p class="EventsPage-nte-dis">
				Расписание занятий для каждого курса будет опубликовано в системе Moodle.
			</p>
			<p class="EventsPage-nte-dis">
				Все вебинары будут доступны в записи.
			</p>

			<h3 class="EventsPage-nte" style="color: #14b4ac;">
				Регистрация
			</h3>
			<div class="StructureAndOrgans-table">
				<table width="100%">
					<tr>
						<th>Наименование дополнительной общеразвивающей программы</th>
						<th>Дата проведения</th>
						<th>Ссылки для подачи заявок на сайте «Навигатор дополнительного образования детей Иркутской области»</th>
						<th>Ссылки на регистрацию в электронной информационно-образовательной среде Moodle</th>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по английскому языку»</td>
						<td>16 октября - 2 ноября 2023г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22396-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-angliiskomu-yazyku"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=94" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по истории»</td>
						<td>19 октября -1 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22390-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-istorii"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=93" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по химии»</td>
						<td>23 октября – 18 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22375-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-khimii"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=92" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по математике»</td>
						<td>2 ноября – 22 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22370-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-matematike"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=91" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по ОБЖ»</td>
						<td>25 октября – 16 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22461-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-obzh"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=96" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по географии»</td>
						<td>16 октября – 27 октября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22459-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-geografii"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=95" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по русскому языку»</td>
						<td>18 октября – 7 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22456-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-russkomu-yazyku "
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=98" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по физике»</td>
						<td>1 ноября - 10 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22455-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-fizike"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=97" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
					<tr>
						<td>«Подготовка обучающихся к участию во ВСОШ по праву»</td>
						<td>4 октября - 11 ноября 2023 г.</td>
						<td><a
								href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22576-programma-podgotovka-obuchayushchikhsya-k-uchastiyu-vo-vsosh-po-pravu"
								target="_blank" style="color: #322a80">Ссылка на Навигатор</a></td>
						<td><a href="https://moodle.perseusirk.ru/course/view.php?id=102" target="_blank"
								style="color: #322a80">Ссылка на Moodle</a></td>
					</tr>
				</table>
			</div>

			<h3 class="EventsPage-nte" style="color: #14b4ac;">
				Документы
			</h3>
			<a class="EventsPage-nte-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%BE-%D0%B4%D0%B8%D1%81%D1%82%D0%B0%D0%BD%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85-%D0%BA%D1%83%D1%80%D1%81%D0%B0%D1%85-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
				style="color: #322a80">
				Письмо о дистанционных курсах ВСОШ
			</a>
			<h3 class="Preparation-sub-title" style="color: #14b4ac;">
				Контакты
			</h3>
			<p class="Preparation-dist">
				<a class="EventsPage-link" style="color: #322a80" href="mailto:perseus@perseusirk.ru"> perseus@perseusirk.ru
				</a>
			</p>
			<p class="Preparation-dist">
				<a class="EventsPage-link" style="color: #322a80" href="tel:+73952546044"> 8 (3952) 54-60-44 </a>
			</p>
		</div>
	</div>
</template>