<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Олимпиадная математика»
		</h3>
		<p class="EventsCard-sub-title">
			1 этап – с 5 по 10 июля 2023 года
		</p>
		<p class="EventsCard-sub-title">
			2 этап – с 6 по 11 сентября 2023 года
		</p>
		<p class="EventsCard-sub-title">
			3 этап – с 15 по 20 декабря 2023 года
		</p>
	</div>
</template>