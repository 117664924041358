<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Art box»
		</h2>
		<p class="EventsPage-date">
			с 11 августа по 16 августа 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/21354-programma-art-box"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит дистанционный курс «Art box» для учащихся общеобразовательных организаций
			Иркутской области в возрасте с 10 до 17 лет, увлекающихся конструированием из бумаги, историей создания техники
			оригами.
		</p>

		<p class="EventsPage-text">
			Программа «Art box» ориентирована на развитие духовно-нравственных, этических и эстетических чувств, интереса к
			самостоятельной работе по поиску интересных техник оригами в области конструировании из бумаги.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Гриценко Татьяна Михайловна, педагог дополнительного образования МКУ ДО ИРМО «ЦРТДЮ»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Учащимся, необходимо обязательно зарегистрироваться в системе Навигатор дополнительного образования Иркутской
				области по ссылке: https://р38.навигатор.дети/program/21354-programma-art-box
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>