<template>
	<div class="GetIn _cover">
		<div class="GetIn-container">
			<div class="GetIn-content">
				<h2 class="GetIn-title _cover-title">Об&nbsp;обучении в&nbsp;Образовательном центре &laquo;Персей&raquo;</h2>
				<p class="GetIn-sub-title">Для участия в&nbsp;образовательных программах и&nbsp;мероприятиях образовательного центра &laquo;Персей&raquo; приглашаются школьники Иркутской области, имеющие таланты в&nbsp;областях науки, спорта и&nbsp;искусства. Обучение осуществляется за&nbsp;счет средств регионального бюджета. Ознакомиться с&nbsp;мероприятиями и&nbsp;программами:</p>

				<ul class="GetIn-list">
					<li class="GetIn-items">
						<button class="GetIn-link temp-btn" @click="$router.push({ name: 'Profile' })">Профильные смены</button>
					</li>
					<li class="GetIn-items">
						<button class="GetIn-link temp-btn" @click="$router.push({ name: 'Weekly' })">Еженедельные курсы</button>
					</li>
					<li class="GetIn-items">
						<button class="GetIn-link temp-btn" @click="$router.push({ name: 'Remote' })">Дистанционные курсы</button>
					</li>
					<li class="GetIn-items">
						<button class="GetIn-link temp-btn" @click="$router.push({ name: 'OlyandCom' })">Олимпиады и&nbsp;конкурсы</button>
					</li>
				</ul>
				<div class="Contacts-map">
					<h2 class="Contacts-title _cover-title">Кампус образовательного центр «Персей»</h2>
					<iframe class="Contacts-map-items" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9731.175817609526!2d103.64268649336971!3d52.42853099924172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d07971f7a878d1f%3A0xa84c8c0320cabf2e!2z0J_QtdGA0YHQtdC5!5e0!3m2!1sru!2sru!4v1695783906624!5m2!1sru!2sru" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"> Местоположение на карте </iframe>
				</div>
				<p class="GetIn-sub-title">На&nbsp;базе площадок реализуется обучение по&nbsp;дополнительным общеобразовательным программам</p>
				<p class="GetIn-sub-title">Адреса площадок:</p>
				<p class="GetIn-sub-title">Г. Иркутск, Угольный проезд, д. 68/1</p>
				<p class="GetIn-sub-title">Реализация профильных смен осуществляется на&nbsp;базе кампуса.</p>
				<p class="GetIn-sub-title">Адрес: Иркутская область, Ангарский район, 8.351&nbsp;км автодороги Ангарск-Тальяны.</p>
			</div>
		</div>
	</div>
</template>
