<template>
	<div class="registration">
		<h2 class="registration-title">
			Регистрация
		</h2>
		<form class="registration-form" id="registration-form">
			<label class="registration-parameter" for="last-name">
				Фамилия:
			</label>
			<input class="registration-data" type="text" id="last-name" minlength="2" maxlength="30" placeholder="Иванов" required>
	
			<label class="registration-parameter" for="first-name">
				Имя:
			</label>
			<input class="registration-data" type="text" id="first-name" minlength="2" maxlength="30" placeholder="Иван" required>

			<label class="registration-parameter" type="text" id="Surname" for="patronymic">
				Отчество:
			</label>
			<input class="registration-data" type="text" id="patronymic" minlength="2" maxlength="30" placeholder="Иванович" required>

			<label class="registration-parameter" type="username" for="email">
				Почта:
			</label>
			<input class="registration-data" type="email" id="email" placeholder="@mail.ru" required>

			<label class="registration-parameter" for="password">
				Пароль:
			</label>
			<input class="registration-data" type="password" id="password" minlength="8" maxlength="16" placeholder="8-16 символов" required>

			<label class="registration-parameter" for="confirm-password">
				Подтверждение пароля:
			</label>
			<input class="registration-data" type="password" id="confirm-password" minlength="8" maxlength="16" placeholder="8-16 символов" required>

			<label class="registration-parameter" for="gender">
				Пол:
			</label>
			<select class="gender" id="gender">
				<option value="male">
					Мужской
				</option>
				<option value="female">
					Женский
				</option>
			</select>

			<label class="registration-parameter" for="birth-date">
				Дата рождения:
			</label>
			<input class="registration-data" type="date" id="birth-date" required>

			<div class="checkbox-block">
				<input class="checkbox" type="checkbox" id="consent" required>
				<label class="checkbox-title" for="consent">
					Я даю согласие на обработку данных
				</label>
			</div>

			<button class="registration-btn temp-btn" type="submit">
				Зарегистрироваться
			</button>
		</form>
	</div>
</template>

<!-- <style>
/* Example styles, you can customize as needed */
form {
	max-width: 400px;
	margin: 0 auto;
}

label {
	display: block;
	margin-bottom: 5px;
}

input class="registration-data",
select {
	width: 100%;
	padding: 8px;
	margin-bottom: 10px;
}

button {
	background-color: #4CAF50;
	color: white;
	padding: 10px 20px;
	border: none;
	cursor: pointer;
}

button:hover {
	background-color: #45a049;
}
</style> -->