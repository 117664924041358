<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			«Modern Tech»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 17 октября по 30 декабря 2022 года
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			Регистрация участников: с 17 октября по 8 ноября 2022 года.
		</h4>

		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr>

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Учредитель Конкурса
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор конкурса
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>

			<p class="EventsPage-text">
				Участники конкурса: к участию приглашаются обучающиеся школ, СПО и дополнительного образования в возрасте от 14 до 18 лет. Участие может быть индивидуальным или в составе коллектива до 5 участников. Участника или коллектив возглавляет руководитель — педагогический работник.
			</p>
			<p class="EventsPage-text">
				Конкурсные материалы: для участия в Конкурсе необходимо представить авторский информационный баннер (далее – инфобанер), созданный по тематике номинации.
			</p>
			<p class="EventsPage-text">
				Регистрация участников: с 17 октября по 8 ноября 2022 года.
			</p>
			<p class="EventsPage-text">
				Формат проведения: дистанционный.
			</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			О конкурсе
		</p>
		<p class="EventsPage-text">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/12/%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2-ModernTech.pdf" target="_blank">
			Об утверждении итогов ModernTech
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/11/%D0%9C%D0%BE%D0%B4%D0%B5%D1%80%D0%BD-%D1%82%D1%8D%D1%87.pdf" target="_blank">
			Модерн тэч положение
		</a>
		<hr>

		<p class="EventsPage-text">
			Цель конкурса
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Целью Конкурса является повышение интереса обучающихся в сфере инноваций и высоких технологий с учетом экологизации современного цифрового производства.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Номинации:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Глобальные проблемы современности»;
			</li>
			<li class="JuniorProfi-items">
				«Великие люди XXI века»;
			</li>
			<li class="JuniorProfi-items">
				«Здоровый образ жизни как модная тенденция»;
			</li>
			<li class="JuniorProfi-items">
				«Техника будущего»;
			</li>
			<li class="JuniorProfi-items">
				«Жизнь в XXII веке».
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Регистрация и участие в конкурсе
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сайт проведения конкурса: https://konkurs.ric38.ru/.
			</li>
			<li class="JuniorProfi-items">
				После регистрации пользователя необходимо выбрать желаемые номинации в списке конкурсов и подать заявку, прикрепив заявление, пояснительную записку и конкурсную работу.
			</li>
			<li class="JuniorProfi-items">
				Всем участникам также необходимо пройти регистрацию в Навигаторе дополнительного образования Иркутской области (ссылка будет доступна позднее).
			</li>
		</ul>
		<hr>
		
	</div>
</template>