<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Региональный конкурс
		</h2>
		<h3 class="EventsCard-title">
			«Лучший вожатый-2023»
		</h3>
		<p class="EventsCard-sub-title">
			с 10 октября по 16 ноября 2023 г.
		</p>
	</div>
</template>