<template>
	<div class="BestCounselor _cover">
		<h2 class="BestCounselor-title _cover-title">
			Региональный конкурс<br> «Лучший вожатый-2024»
		</h2>

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/LVLogo.webp" alt="Логотип" />
		</div>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Цель конкурса:</b> конкурс направлен на выявление, анализ и трансляцию лучших вожатских практик, повышение
			педагогического мастерства и методической грамотности вожатых, самореализации способностей для повышения качества
			организации отдыха и оздоровления детей.
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Формат проведения конкурса:</b> дистанционный, в один этап.
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Участники:</b> к участию приглашаются вожатые образовательных организаций, в возрасте от 16 лет, имеющие опыт
			работы воспитательной деятельности не менее трех месяцев.
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Как участвовать:</b> информация о Конкурсе будет опубликована в сентябре 2024 г.
		</p>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>
	</div>

	<!-- Архив -->
	<ArchiveBestCounselor />

</template>
		
<script>
import ArchiveBestCounselor from '@/components/ArchiveBestCounselor.vue';

export default {
	components: {
		ArchiveBestCounselor,
	}
}

</script>