<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			26.10.2023
		</p>
		<h2 class="NewsPage-title">
			В «Персее» подвели итоги октябрьских научных профильных смен
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage20.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В кампусе Образовательного центра «Персей» подошли к концу октябрьские научные интенсивы по программам «Шаг в мир науки» и «Беспилотные авиационные системы». Участие в сменах приняли более 80 школьников Приангарья.
			</p>
			<p class="NewsPage-sub-title">
				Обучение по программе «Шаг в мир науки» проходило в двух направлениях: информатика и физика. В рамках модулей школьники знакомились с основами Web – индустрии, двоичного поиска, комбинаторики и программирования, а также изучали такие разделы физики, как: строение веществ, взаимодействие тел, движение жидкостей и газов, работа и мощность, энергия.
			</p>
			<p class="NewsPage-sub-title">
				Это вторая профильная осенняя профильная смена для школьников 5-10 классов, на которой дети изучают разные науки.
			</p>
			<p class="NewsPage-sub-title">
				– Было интересно узнавать у ребят, что они сегодня проходили. После занятий мы собирались и обсуждали новые темы, те кто изучал физику, делились с нами фактами о солнечном затмении, объясняли, как рассчитать скорость полета, а мы делились лайфхаками по работе с программным обеспечением, – рассказали участники профильной смены «Шаг в мир науки».
			</p>
			<p class="NewsPage-sub-title">
				Своими успехами и достижениями делились и участники профильной смены «Беспилотные авиационные системы». Программа проходила при поддержке Авиационного техникума факультета «Авиационных систем и комплексов» Иркутского филиала Московского Государственного технического университета гражданской авиации.
			</p>
			<p class="NewsPage-sub-title">
				Участники программы «Беспилотные авиационные системы» отметили, что смена была насыщенной и за две недели они изучили такие темы, как: БВС самолетного и беспилотного типа, технология производств и ремонта беспилотных авиационных систем, а также устройства и управление беспилотного типа. Также у них проходили практические занятия по пайке и основам программирования, и вместе с преподавателями им удалось совершить тренировочные полеты и выполнить аэрофотосъёмку.
			</p>
			<p class="NewsPage-sub-title">
				– Помимо изучения беспилотных авиационных систем, нам было важно объяснить детям, что авиация – это востребованная и большая сфера деятельности, в которой каждый сможет найти свое предназначение. И самое главное, что сейчас мы видим ребят, у которых горят глаза.  Ребят, которые искренне заинтересовались авиацией и хотят связать свою профессиональную жизнь с ней, – отметил Михаил Портнов, начальник отделения среднего профессионального образования факультета «Авиационных систем и комплексов» Иркутского филиала Московского Государственного технического университета гражданской авиации.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказал руководитель Образовательного центра «Персей» Алексей Шестаков, сотрудничество с учреждениями среднего профессионального и высшего образования помогает реализовывать программы предпрофессиональной подготовки, на которых школьники знакомятся со специальностями, а также вузами и колледжами  региона. Это один из вариантов ранней профориентации, который помогает выбрать ребенку ту сферу деятельности, где ему будет интересно, и узнать о возможностях, которые предоставляют образовательные организации региона.
			</p>
			<p class="NewsPage-sub-title">
				8 ноября в «Персее» стартует вторая осенняя интенсивная программа для ребят, увлеченных инженерным авиамоделированием и управлением беспилотными аппаратами. Смена будет проходить при поддержке педагогов центра Дворца культуры им. Ю. Гагарина г. Иркутска
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(5).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(6).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(7).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(8).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(9).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(10).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(11).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img class="grid-item-img" src="../assets/Newsimg/NewsPage20(12).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
