<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			09.02.2024
		</p>
		<h2 class="NewsPage-title">
			В Образовательном центре «Персей» ко Дню российской науки стартовали три профильные смены
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage46.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				День российской науки отмечают сегодня, 8 февраля, в региональном центре выявления и поддержки одаренных детей «Персей», где накануне стартовали три профильные смены по направлению «Наука»: «Современная энергетика», «Анатомия человека» и «Проектная деятельность».
			</p>
			<p class="NewsPage-sub-title">
				В региональном министерстве образования сообщили, что участие в сменах принимают около 100 школьников из Иркутска, Братска, Ангарска, Баяндая, Киренска, Слюдянки, Зимы, Черемхово, Тулунского, Иркутского, Нукутского, Баяндаевского, Жигаловского районов.
			</p>
			<p class="NewsPage-sub-title">
				По словам организаторов, на программах школьники познакомятся с устройством работы региональной энергетической системы, смогут подготовиться к региональному этапу всероссийской образовательной инициативы по поиску и реализации научно-технологических проектов «Сириус.Лето: начни свой проект», а также изучат основные системы человеческого организма.
			</p>
			<p class="NewsPage-sub-title">
				Министр образования региона Максим Парфенов отметил, что «Персей» – это одна из площадок для популяризации науки среди школьников Приангарья. Ежегодно в образовательном центре проходит более 100 мероприятий, на которых ребята могут включиться в научно-исследовательскую деятельность.
			</p>
			<p class="NewsPage-sub-title">
				– Благодаря реализации федерального проекта «Успех каждого ребенка» президентского национального проекта «Образование» у участников программ «Персея» есть доступ к высокотехнологичному оборудованию, которое помогает им совершать свои первые научные прорывы и открывать для себя мир науки и техники. Уверен, что в будущем многие из тех, что побывал на научных программах «Персея», свяжут свою жизнь с наукой, и мы будем гордиться их достижениями, – подчеркнул Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Также глава регионального ведомства отметил, что с 2022 года в России объявлено Десятилетие науки и технологий. Образовательный центр «Персей» активно сотрудничает с крупными научными организациями региона. В процессе обучения уделяется большое внимание знакомству школьников с наукой и новыми технологиями, которые можно освоить под руководством профессионалов. 
			</p>
			<p class="NewsPage-sub-title">
				Завершатся профильные смены по направлению «Наука» 20 февраля защитой учебных проектов, которые школьники разработают в рамках направлений.
			</p>
			<p class="NewsPage-sub-title">
				Напомним также, что для юных любителей науки проводится всероссийский конкурс научно-популярного видео «Знаешь? Научи!». Конкурс дает возможность школьникам попробовать себя в роли научно-популярных блогеров и выиграть увлекательные поездки, технику и сертификаты на образовательные курсы.
			</p>
			<p class="NewsPage-sub-title">
				Мероприятие проводится при поддержке нацпроекта «Наука и университеты», который реализуется по решению Президента Российской Федерации Владимира Путина. Конкурс также входит в инициативу «Инфраструктура для популяризации науки, создание контента» Десятилетия науки и технологий. Прием конкурсных материалов продлится по 5 марта. Победители будут определены в апреле 2024 года.
			</p>
			<p class="NewsPage-sub-title">
				По материалам пресс-службы Правительства Иркутской области <a class="NewsPage-link" href="https://irkobl.ru/news/3571733/">ссылка</a>
			</p>

		</div>
	</div>
</template>
