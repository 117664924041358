<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Подготовка обучающихся к участию в региональном этапе ВсОШ по экологии»
		</h2>
		<p class="EventsPage-date">
			С 25 января по 31 января 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=127" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24456-programma-podgotovka-k-uchastiyu-vo-vsosh-po-ekologii" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Подготовка обучающихся к участию в региональном этапе ВсОШ по экологии» для учащихся 9-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Отличительной особенностью программы считается совершенствование знаний в области подготовки к заданиям регионального этапа Всероссийской олимпиады школьников по экологии. Учащиеся, в рамках данной программы, смогут ознакомиться с заданиями олимпиады прошлых лет, изучить их решение и типичные ошибки участников, возникающие при их выполнении.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<p class="EventsPage-text">
				Для участников смены будет проведено организационное собрание 17 января 2024 г. в 20.00, <a class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1705037682" target="_blank">https://pruffme.com/landing/Perseus/tmp1705037682</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/kg2H/NcgT8Ly49" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты:
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/DBkXPaSck5WzNg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>