<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			01.11.2023
		</p>
		<h2 class="NewsPage-title">
			В ПРИАНГАРЬЕ ЗАВЕРШИЛСЯ ПЕРВЫЙ ЭТАП ВСЕРОССИЙСКОЙ ОЛИМПИАДЫ ШКОЛЬНИКОВ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage22.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Иркутской завершился школьный этап всероссийской олимпиады школьников (ВсОШ). Он стартовал в сентябре и проходил по 23 общеобразовательным предметам. Как сообщили организаторы, в рамках школьного этапа традиционно свои знания демонстрировали обучающиеся 5-11 классов, а в интеллектуальных состязаниях по русскому языку и математике приняли участие и четвероклассники. По предварительным данным, в этом году самый массовый – школьный этап объединил порядка 100 тысяч школьников Иркутской области.
			</p>
			<p class="NewsPage-sub-title">
				Стоит отметить, что школьники смогли написать олимпиаду по шести общеобразовательным предметам через платформу Сириус.Курсы. В их числе математика, информатика, физика, астрономия, химия и биология. Остальные предметы прошли в традиционном формате.
			</p>
			<p class="NewsPage-sub-title">
				Муниципальный этап стартует уже 13 ноября. Он начнется с интеллектуальных состязаний по английскому языку и закончится 13 декабря олимпиадой по технологии. Подробное расписание опубликовано на сайте министерства образования Иркутской области:
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://irkobl.ru/sites/minobr/olimpiada/">https://irkobl.ru/sites/minobr/olimpiada/</a>
			</p>
			<p class="NewsPage-sub-title">
				Для желающих дополнительно подготовиться к муниципальному этапу ВсОШ, в октябре этого года Образовательный центр «Персей» запустил дистанционный курс по девяти предметам: истории, химии, математике, основам безопасности жизнедеятельности, географии, физике, праву, русскому и английскому языку. Подать заявку можно на сайте Образовательного центра:
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://perseusirk.ru/ProfileBlock/EventsPage1">https://perseusirk.ru/ProfileBlock/EventsPage1</a>
			</p>
			<p class="NewsPage-sub-title">
				Напомним, Всероссийская олимпиада школьников проводится ежегодно с 1 сентября по 30 июня. Она включает школьный, муниципальный, региональный и заключительный этапы. Дипломы победителей и призеров олимпиады дают право поступить в ведущие вузы страны без экзаменов по соответствующему профилю.
			</p>

		</div>
	</div>
</template>
