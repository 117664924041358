<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			29.12.2023
		</p>
		<h2 class="NewsPage-title">
			<b>Наш 2023 год: итоги</b>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage39.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Дорогие друзья! 2023 год стал для Образовательного центра «Персей» ярким, запоминающимся, неповторимым, и все благодаря вам: участникам смен и мероприятий, педагогам и наставникам, партнерам.
			</p>
			<p class="NewsPage-sub-title">
				Хотим поделиться нашими совместными достижениями!
			</p>
			<p class="JuniorProfi-sub-title">
				Наука.
			</p>
			<p class="NewsPage-sub-title">
				80% смен «Персея» реализуются в направлении «Наука» и мы постарались наполнить их качественным, интересным и вдохновляющим на открытия контентом. На профильных сменах по авиамоделированию и беспилотному транспорту школьники занимались сборкой, программированием и управлением малыми летательными аппаратами. Другим настоящим открытием для юных изобретателей стала смена «Инженеры будущего», на которой школьники работали над прототипами своих изобретений и изучали основы продвижения стартапов.
			</p>
			<p class="NewsPage-sub-title">
				Летом 2023 года был запущен блок программ по основам фотосъемки. В него вошли профильная смена «Мир в объективе» и дистанционный курс «Секреты фотосъемки». Дальнейшее изучение мастерства фотографии школьники продолжат в фотостудии «Персея», которая начнет свою работу уже в следующем году.
			</p>
			<p class="NewsPage-sub-title">
				В рамках реализации национального проекта «Образование», инициированного Президентом Российской Федерации, было приобретено высокотехнологичное оборудование. Благодаря чему у учащихся появились новые возможности для проведения научных исследований. Так, например, у участников программы «Космический патруль» появились в распоряжении спутниковый метеокомплекс «Лентикулярис» и учебный комплекс «Орбикрафт». Первый предназначен для приема, демодуляции, декодирования, регистрации и обработки цифровой информации, передаваемой с метеорологических искусственных спутников Земли, а второй – для первичного знакомства с основными подсистемами космического аппарата и сборки функциональной модели спутника формата TabletSat из готовых программируемых модулей. Это позволило учащимся проводить собственные исследования по мониторингу вырубок лесов, пожаров, эрозии почв и распространения стихийных свалок в Иркутской области, а также проектировать малые космические аппараты и проводить их запуски.
			</p>
			<p class="NewsPage-sub-title">
				В этом году ряды друзей ОЦ «Персей» пополнили научные институты региона, культурные организации и ведущие предприятия. Так, при их поддержке и сотрудничестве были реализованы следующие программы: вышеупомянутый «Космический патруль», «Современная энергетика», «Агенты погоды», «Рачительный хозяин земли», «Страна железных дорог», «Байкал – природная лаборатория». Опыт проведения последней из них, реализованной в партнерстве с Лимнологическим институтом СО РАН, был представлен на Международной выставке-форуме «Россия».
			</p>
			<p class="NewsPage-sub-title">
				Помимо прочего, участники проектных смен успешно реализуют себя после обучения в «Персее». Обучающиеся принимают активное участие в форумах и конференциях, становятся победителями и призерами региональных и всероссийских конкурсов. Так, в этом году участники профильной смены «Экология» прошли в финал Всероссийского юниорского лесного конкурса «Подрост», заняли первое место во Всероссийском детском экологическом форуме в номинации «Водный мир» и второе место в командном проекте на Всероссийском слете школьных лесничеств.
			</p>
			<p class="NewsPage-sub-title">
				Для тех, кто только хочет погрузиться в научную деятельность, «Персей» предлагает программы интеллектуальной общеразвивающей школы, в виде сезонных смен, где школьники знакомятся с миром науки и техники и выбирают для себя интересующее направление. Такую же возможность предоставляет и мобильная лаборатория «Персея». Благодаря передвижному комплексу даже самые отдаленные школы Приангарья получили доступ к современному и высокотехнологичному оборудованию, а их обучающиеся приобрели опыт научного исследования. В этом году мобильная лаборатория посетила 28 образовательных учреждений Иркутской области, а участие в программе приняли более пяти тысяч школьников Приангарья.
			</p>
			<p class="JuniorProfi-sub-title">
				Искусство.
			</p>
			<p class="NewsPage-sub-title">
				Музыкально-игровой фольклор, традиции бурятского этнографического искусства, танцевальный фольклор Иркутской области и экстерьерная роспись – далеко не весь перечень программ, представленных «Персеем» для школьников региона в 2023 году.
			</p>
			<p class="NewsPage-sub-title">
				Одним из новых направлений стали программы для юных артистов. Профильная смена «Школьный театр» прошла в Образовательном центре впервые в рамках проекта «Театр – школа жизни», который реализуется национальными проектами «Культура» и «Образование».  Итогом программы стал показ серии спектаклей под эгидой конкурса «Лучший школьный театр».
			</p>
			<p class="NewsPage-sub-title">
				Отдельно следует отметить профильную смену «Волонтёры культуры», организованную совместно с министерством культуры Иркутской области. В отличие от остальных программ, направленных на развитие у школьников художественного, хореографического и актерского мастерства, ее главной идеей является обмен опытом между данными направлениями.
			</p>
			<p class="NewsPage-sub-title">
				Участники образовательных программ по направлению «Искусство» стали победителями областных и всероссийских конкурсов, а также слушателями программ «Сириуса». Так, работы юных художниц Приангарья были представлены на выставке в Сочинском художественном музее. Одно из призовых мест в приуроченном к данной выставке конкурсе ботанического рисунка заняла выпускница «Персея» с работой «Редкие цветы Байкала». Лауреатами I степени фестиваля детского и юношеского творчества «Весенняя капель» стали участники хореографического коллектива «Аллегро», которые занимались на профильной смене «Обрядовый танцевальный фольклор». Также первое место на Всероссийском фестивале-конкурсе «Триумф талантов» было присвоено студии танца «Акварель», юные танцоры которой готовились к конкурсу на программе «Лаборатория творчества: хореографический спектакль».
			</p>
			<p class="JuniorProfi-sub-title">
				Спорт.
			</p>
			<p class="NewsPage-sub-title">
				По сравнению с предыдущим годом было достигнуто двукратное увеличение реализованных программ спортивной направленности. В текущем году более 100 школьников смогли приехать на тренировочные сборы по борьбе: самбо и дзюдо, а также еще около 250 одаренных спортсменов Приангарья стали участниками спортивных смен по туризму, ГТО и олимпиадной физической культуре.
			</p>
			<p class="NewsPage-sub-title">
				Кроме того, «Персей» стал региональным оператором викторины в рамках «Дня зимних видов спорта», проводимой при поддержке Олимпийского комитета России и посвящённой девятой годовщине проведения XXII Олимпийских зимних игр и XI Паралимпийских зимних игр 2014 года в г. Сочи.
			</p>
			<p class="NewsPage-sub-title">
				Благодаря учебно-тренировочным сборам в Образовательном центре наши юные спортсмены показали высокие результаты на соревнованиях регионального и федерального уровня.
			</p>
			<p class="JuniorProfi-sub-title">
				Олимпиады и конкурсы.
			</p>
			<p class="NewsPage-sub-title">
				Помимо побед на творческих и спортивных мероприятиях, школьники, проходившие обучение в «Персее», продолжают показывать достойные результаты и на предметных олимпиадах. Например, на Всероссийской олимпиаде школьников Иркутскую область представляло 29 обучающихся, трое стали победителями заключительного этапа, а пять – призерами. В финале Всероссийской Гуманитарной телевизионной олимпиаде «Умницы и умники» было три участника от нашего региона, один из которых стал победителем, получив студенческий билет МГИМО.
			</p>
			<p class="NewsPage-sub-title">
				В уходящем году участники профильной смены «Проектная деятельность» стали призерами конкурса научно-технологических проектов «Большие вызовы» и были приглашены на одноименную программу в Образовательный центр «Сириус».
			</p>
			<p class="NewsPage-sub-title">
				При активной поддержке Образовательного центра возрос интерес и к открытой региональной межвузовской олимпиаде обучающихся Иркутской области «Золотой фонд Сибири». В этом году участие в олимпиаде приняло около 2 тысяч школьников и студентов профессиональных образовательных организаций региона. В текущем году участниками чемпионатов компетенций и корпораций стали более 300 школьников, а в финале регионального этапа областного конкурса «Лучший ученик года» из 40 обучающихся выбирали победителя, который представил наш регион на всероссийском конкурсе. «Персей» проводит работу и с младшими школьниками, так региональная олимпиада обучающихся начальных классов «Олимпик» объединила более 8 тысяч детей.
			</p>
			<p class="NewsPage-sub-title">
				В уходящем году специалисты «Персея» стали участниками Межрегиональной конференции «К.Л.И.К.: Коммуникации, Лидерство, Идея, Команда» в Екатеринбурге и Межрегиональной конференции «Система выявления, развития и поддержки талантов: актуальные вызовы и перспективы», проходившей в ОЦ «Сириус».
			</p>
			<p class="NewsPage-sub-title">
				В Год педагога и наставника для 484 работников образовательных организаций региона «Персеем» были организованы курсы повышения квалификации, а также выездные семинары, тренинги, практикумы и вебинары. Прошел ежегодный межрегиональный конкурс методических разработок «Лучшие практики по работе с талантливыми и одаренными детьми», участие в котором приняли 182 педагога из Иркутской области и Красноярского края.
			</p>
			<p class="NewsPage-sub-title">
				Мы выражаем благодарность каждому преподавателю, который работал в этом году с учащимися на образовательных программах. И отдельная благодарность нашим партнерам, которые помогают выявлять и поддерживать одарённых и талантливых детей Иркутской области!
			</p>

		</div>
	</div>
</template>
