<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			02.08.2024
		</p>
		<h2 class="NewsPage-title">
			Школьники региона обучились основам фотосъемки в Образовательном центре «Персей»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage76.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одаренных детей «Персей» завершилась вторая профильная смена для
				юных фотографов Приангарья. Она проходила с 19 июля по 1 августа. Ее участниками стали 12 школьников из
				Иркутска, Шелехова, Киренска, Черемхово, Иркутского, Черемховского, Тайшетского, Осинского и Тулунского районов.
			</p>
			<p class="NewsPage-sub-title">
				На профильной смене школьники знакомились с профессиональной деятельностью фотографа. По словам преподавателей,
				ребята за две недели освоили основы экспозиции, композиции, разобрали жанровые особенности студийной,
				портретной, репортажной и пейзажной съемки, а также постобработки и продвижения фотографов в социальных сетях.
			</p>
			<p class="NewsPage-sub-title">
				– На программе ребята проделали большую работу, – отмечает преподаватель профильной смены Анастасия Говорова. –
				Наша главная задача была научить ребят фотографировать профессионально, а именно выставлять все базовые
				настройки фотоаппарата самостоятельно. Фотоаппарат – это сложная техника, требующая глубокого понимания
				различных параметров, таких как выдержка, диафрагма, ISO, баланс белого, экспозамер и другие. Мы стремились к
				тому, чтобы участники смогли не только освоить технические аспекты, но и развить свое творческое видение, умение
				передавать настроение и историю через фотографии, а также правильно композиционно видеть карды.
			</p>
			<p class="NewsPage-sub-title">
				Помимо освоения технических особенностей фотографии, ребята получили теоретические и практические знания
				разножанровых съемок. Школьники научились снимать пейзажи разных видов, событийный и повседневный репортаж,
				портретную и студийную съемку.
			</p>
			<p class="NewsPage-sub-title">
				– Студийная съемка всегда интересна ребятам. Благодаря реализации федерального проекта «Успех каждого ребенка»
				президентского национального проекта «Образование» мы оборудовали профессиональную фотостудию, где ребята могут
				осваивать жанр студийной фотографии. В рамках таких занятий школьники учатся работать со студийным
				оборудованием: постоянным и импульсным светом, световыми модификаторами, фотофонами и фотобоксами. Все это
				помогает им погрузиться в мир профессиональной деятельности фотографа, развить чувство света, композиции и
				понимание работы с моделью. Это уникальная возможность для ребят получить ценные навыки, которые пригодятся им в
				дальнейшем обучении и карьере, – рассказал Сергей Хранивский, преподаватель программы.
			</p>
			<p class="NewsPage-sub-title">
				Также на программе школьники изучили основы постобработки фотографии в фоторедакторах, а именно узнали о
				принципах ретуши и цветокоррекции. Они освоили базовые техники улучшения качества изображений, научились
				работать с инструментами для удаления дефектов, коррекции цветов и освещения, а также для создания
				художественных эффектов. Эти навыки позволят им не только улучшать свои снимки, но и более глубоко понимать
				процесс создания визуального контента, что является важным аспектом современной фотографии.
			</p>
			<p class="NewsPage-sub-title">
				– Многие думают, что работа фотографа заключается в том, чтобы просто нажимать на кнопку, но это не так.
				Современная фотография – это сложный процесс. В нынешних реалиях фотограф должен уметь не только работать с
				техникой, но и обладать навыками в постобработке, маркетинге, и взаимодействии с участниками съемочного
				процесса. Он должны уметь правильно настроить оборудование, выбрать подходящий свет, а также создавать
				композиции, которые передают эмоции и смысл. В дополнение к этому, фотографам важно быть в курсе последних
				тенденций в области цифровых технологий, социальных медиа и визуального сторителлинга, чтобы эффективно
				представлять свои работы и привлекать аудиторию. Этому мы учим детей на наших программах, – дополнили
				преподаватели.
			</p>
			<p class="NewsPage-sub-title">
				Итогом обучения станет онлайн фотовыставка, которая будет доступна для просмотра в социальных сетях
				Образовательного центра «Персей»: <a class="NewsPage-link" href="https://vk.com/perseusirk">https://vk.com/perseusirk</a>
			</p>

		</div>
	</div>
</template>
