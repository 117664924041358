<template>
	<div class="ProfessionalEducation _cover">
		<h2 class="ProfessionalEducation-title _cover-title">
			Профессиональное обучение
		</h2>

		<h2 class="ProfessionalEducation-sub-title _cover-title" style="color: red">
			Страница пуста
		</h2>
	</div>
</template>