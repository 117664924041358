<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Шаг в мир науки»
		</h2>
		<p class="EventsPage-date">
			с 27 сентября по 10 октября 2023
		</p>
		<p class="EventsPage-date">
			с 13 октября по 26 октября 2023
		</p>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
				<h3 class="EventsPage-nte">
					Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Шаг в мир науки» для обучающихся 5-10-х классов общеобразовательных организаций.
				</h3>
				<h3 class="EventsPage-nte">
					Программа направлена на формирование у учащихся целостное представление о глобальном межпредметном пространстве и принципах получения информации, конструкторские и исследовательские навыки активного творчества с использованием современных технологий.
				</h3>
				<h3 class="EventsPage-nte">
					Профильная смена состоится с 27 сентября по 10 октября 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
				</h3>

				<hr>
				<p class="EventsPage-nte-dis">
					<b>Контактное лицо:</b> Толстихина Татьяна Павловн<br>
					<b>Телефон:</b> 8 (3952) 546-044 <br>
					<b>Эл. почта:</b> n.tolstihina@perseusirk.ru<br>
				</p>
				<hr>

				<h2 class="EventsPage-prof">
					Документы
				</h2>
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/pZtPF1QPaLOQNA" target="_blank">
					Сертификаты с 27 сентября по 10 октября 2023
				</a>
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/Yg2xXqwltf_akg" target="_blank">
					Сертификаты с 13 октября по 26 октября 2023
				</a>
		</div>
	</div>
</template>