<template>
	<!-- Олимпиады -->
	<MainOlympics />

	<div class="DirectionBlock _cover">
		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/ОЛИМПИАДЫ.webp"
				alt="Олимпиады и конкурсы" />
		</div>

		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe class="Intelligence-block-video-items"
					src="https://www.youtube.com/embed/JbLKa01XN0k?si=mzFtJa81POHklcVQ" title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
					Направление Олимпиады и конкурсы
				</iframe>
			</div>
		</div>

		<!-- Профильные смены -->
		<div class="Period">
			<h2 class="Period-title _cover-title">Список мероприятий 2024 года</h2>

			<ul class="grid-container">
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage158' })">
						<EventsCard158></EventsCard158>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage137' })">
						<EventsCard137></EventsCard137>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage135' })">
						<EventsCard135></EventsCard135>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage117' })">
						<EventsCard117></EventsCard117>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage116' })">
						<EventsCard116></EventsCard116>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage106' })">
						<EventsCard106></EventsCard106>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'AllRussian' })">
						<EventsCard43></EventsCard43>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'GoldenFundPage' })">
						<EventsCard44></EventsCard44>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'BigChallenges2024' })">
						<EventsCard130></EventsCard130>
					</a>
				</li>
			</ul>
		</div>

		<!-- Архив -->
		<div class="Period">
			<h2 class="Period-title _cover-title">Архив</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">Подробнее</summary>
				<ul class="grid-container">
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage90' })">
							<EventsCard90></EventsCard90>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'BigChallenges2023' })">
							<EventsCard45></EventsCard45>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage25' })">
							<EventsCard25></EventsCard25>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage2' })">
							<EventsCard2></EventsCard2>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage47' })">
							<EventsCard47></EventsCard47>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage46' })">
							<EventsCard46></EventsCard46>
						</a>
					</li>
					<!-- <li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage59' })">
							<EventsCard59></EventsCard59>
						</a>
					</li> -->
					<!-- <li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage58' })">
							<EventsCard58></EventsCard58>
						</a>
					</li> -->
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage57' })">
							<EventsCard57></EventsCard57>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage56' })">
							<EventsCard56></EventsCard56>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage55' })">
							<EventsCard55></EventsCard55>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage54' })">
							<EventsCard54></EventsCard54>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage53' })">
							<EventsCard53></EventsCard53>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage52' })">
							<EventsCard52></EventsCard52>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage51' })">
							<EventsCard51></EventsCard51>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage50' })">
							<EventsCard50></EventsCard50>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage49' })">
							<EventsCard49></EventsCard49>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage48' })">
							<EventsCard48></EventsCard48>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage37' })">
							<EventsCard37></EventsCard37>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage36' })">
							<EventsCard36></EventsCard36>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage33' })">
							<EventsCard33></EventsCard33>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage32' })">
							<EventsCard32></EventsCard32>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage31' })">
							<EventsCard31></EventsCard31>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage30' })">
							<EventsCard30></EventsCard30>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage29' })">
							<EventsCard29></EventsCard29>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage27' })">
							<EventsCard27></EventsCard27>
						</a>
					</li>
				</ul>
			</details>
		</div>
	</div>
</template>

<script>
import MainOlympics from '@/components/MainOlympics.vue'
import EventsCard2 from '../EventsCard/EventsCard2'
import EventsCard25 from '../EventsCard/EventsCard25'
import EventsCard27 from '../EventsCard/EventsCard27'
import EventsCard29 from '../EventsCard/EventsCard29'
import EventsCard30 from '../EventsCard/EventsCard30'
import EventsCard31 from '../EventsCard/EventsCard31'
import EventsCard32 from '../EventsCard/EventsCard32'
import EventsCard33 from '../EventsCard/EventsCard33'
import EventsCard36 from '../EventsCard/EventsCard36'
import EventsCard37 from '../EventsCard/EventsCard37'
import EventsCard43 from '../EventsCard/EventsCard43'
import EventsCard44 from '../EventsCard/EventsCard44'
import EventsCard45 from '../EventsCard/EventsCard45'
import EventsCard46 from '../EventsCard/EventsCard46'
import EventsCard47 from '../EventsCard/EventsCard47'
import EventsCard48 from '../EventsCard/EventsCard48'
import EventsCard49 from '../EventsCard/EventsCard49'
import EventsCard50 from '../EventsCard/EventsCard50'
import EventsCard51 from '../EventsCard/EventsCard51'
import EventsCard52 from '../EventsCard/EventsCard52'
import EventsCard53 from '../EventsCard/EventsCard53'
import EventsCard54 from '../EventsCard/EventsCard54'
import EventsCard55 from '../EventsCard/EventsCard55'
import EventsCard56 from '../EventsCard/EventsCard56'
import EventsCard57 from '../EventsCard/EventsCard57'
import EventsCard90 from '../EventsCard/EventsCard90'
import EventsCard106 from '../EventsCard/EventsCard106'
import EventsCard116 from '../EventsCard/EventsCard116'
import EventsCard117 from '../EventsCard/EventsCard117'
import EventsCard130 from '../EventsCard/EventsCard130'
import EventsCard135 from '../EventsCard/EventsCard135'
import EventsCard137 from '../EventsCard/EventsCard137'
import EventsCard158 from '../EventsCard/EventsCard158'

export default {
	components: {
		EventsCard2,
		EventsCard25,
		EventsCard27,
		EventsCard29,
		EventsCard30,
		EventsCard31,
		EventsCard32,
		EventsCard33,
		EventsCard36,
		EventsCard37,
		EventsCard43,
		EventsCard44,
		EventsCard45,
		EventsCard46,
		EventsCard47,
		EventsCard48,
		EventsCard49,
		EventsCard50,
		EventsCard51,
		EventsCard52,
		EventsCard53,
		EventsCard54,
		EventsCard55,
		EventsCard56,
		EventsCard57,
		EventsCard90,
		EventsCard106,
		EventsCard116,
		EventsCard117,
		EventsCard130,
		EventsCard135,
		EventsCard137,
		EventsCard158,

		MainOlympics,
	},
}
</script>
