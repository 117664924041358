<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Областной конкурс
		</h3>
		<h2 class="EventsPage-title">
			«Лучший ученик года»
		</h2>
		<p class="EventsPage-date">
			Муниципальный этап - с 22 по 27 марта 2024 года.
		</p>
		<p class="EventsPage-date">
			Региональный этап - с 5 по 8 апреля 2024 года.
		</p>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация на региональный этап
		</a>
		<a class="EventsPage-btn temp-btn" href="р38.навигатор.дети/activity/1597/?date=2024-03-08" target="_blank">
			Регистрация на региональный этап в системе "Навигатор"
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Задачи конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				выявление и поощрение наиболее активных и творческих обучающихся
			</li>
			<li class="JuniorProfi-items">
				создание условий для самореализации и раскрытия творческого потенциала обучающихся
			</li>
			<li class="JuniorProfi-items">
				стимулирование общественной, творческой и познавательной активности обучающихся
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%A3%D1%87%D0%B5%D0%BD%D0%B8%D0%BA-%D0%B3%D0%BE%D0%B4%D0%B0-2024.pdf"
			target="_blank">
			Положение Ученик года 2024
		</a>
		<a class="EventsPage-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%98%D1%82%D0%BE%D0%B3%D0%B8-%D0%A3%D0%93.pdf"
			target="_blank">
			Итоги Ученик года 2024
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Участники:
		</p>
		<p class="EventsPage-text">
			В Конкурсе на добровольной основе принимают участие обучающиеся 8-10-х классов образовательных организаций
			Иркутской области.
		</p>
		<p class="EventsPage-text">
			В региональном этапе конкурса принимают участие победители муниципального этапа конкурса.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Формат проведения регионального этапа конкурса:
		</p>
		<p class="EventsPage-text">
			Региональный этап пройдет очно, на базе кампуса Образовательного центра «Персей» по адресу: Иркутская область,
			Ангарский район, 8,321 км автодороги Ангарск-Тальяны.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Как участвовать в региональном этапе:
		</p>
		<p class="EventsPage-text">
			С 27 по 29 марта 2024 года подать заявки:
		</p>
		<p class="EventsPage-text">
			— на сайте konkurs.ric38.ru в разделе «Ученик года» (к заявке нужно загрузить документы пункта 3.2. Положения).
		</p>
		<p class="EventsPage-text">
			— в системе Навигатор: р38.навигатор.дети/activity/1597/?date=2024-03-08
		</p>
	</div>
</template>