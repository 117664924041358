<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage30.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Более 100 педагогов стали участниками первой программы курсов повышения квалификации для хореографов
			</h2>
			<p class="NewsCard-date">
				05.12.2023
			</p>
		</div>
	</div>
</template>