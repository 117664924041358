<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Биология. Интенсивный учебный модуль»
		</h2>
		<p class="EventsPage-date">
			с 16 сентября по 14 октября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=174" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25355-programma-biologiya-intensivnyi-uchebnyi-modul" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Биология. Интенсивный учебный модуль» для учащихся
			5-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Изучение биологии важно для людей всех возрастов, чтобы расширить свои знания и лучше понимать процессы в своем
			организме и окружающей среде.
		</p>

		<p class="EventsPage-text">
			Начиная изучение курса с основ биологии, вы сможете узнать о свойствах живого, систематике и принципах ее
			построения, а также о прокариотах, вирусах и эукариотических клетках.
		</p>

		<p class="EventsPage-text">
			Далее вы погрузитесь в изучение альгологии, микологии, протистологии и ботаники, ознакомитесь со строением клеток,
			жизненными циклами и разнообразием водорослей, грибов, лишайников и простейших.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 16 сентября 2024 года на платформе обучения Образовательного центра
				«Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>