<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.10.2023
		</p>
		<h2 class="NewsPage-title">
			В «ПЕРСЕЕ» ПО СЕНТЯБРЬСКИМ НАУЧНЫМ ПРОГРАММАМ ПРОХОДЯТ ОБУЧЕНИЕ 100 ШКОЛЬНИКОВ ПРИАНГАРЬЯ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage9.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В конце сентября в Образовательном центре «Персей» стартовали четыре профильные научные смены. Они продлятся до 10 октября. Какие занятия жду участников программ, рассказываем в нашем материале.
			</p>
			<p class="NewsPage-sub-title">
				На смене «Школа безопасности. Основы безопасности жизнедеятельности» школьники будут изучать правила поведения в экстремальных чрезвычайных ситуациях, основы медицинских знаний, туристической подготовки, военной службы и правила дорожного движения.
			</p>
			<p class="NewsPage-sub-title">
				Обучение по программе подразумевает насыщенную практическую подготовку. Школьники научатся оказывать первую медицинскую помощь, проходить спортивные туристические маршруты, освоят метание гранаты, а также смогут разбираться в дорожных знаках, разметках и сигналах.
			</p>
			<p class="NewsPage-sub-title">
				Программа «Актуальные вопросы обществознания» построена на изучении тем по предмету Обществознание, которые для школьников вызывают затруднения.
			</p>
			<p class="NewsPage-sub-title">
				Как отмечают преподаватели смены, освоение курса позволит обучающимся более углубленно познакомиться с полномочиями правоохранительных органов. Изучить системное строение общества. Разобраться в вопросах социального взаимодействия и общественных отношений. Также обучение по программе предполагает изучение юридической ответственности, экономики и политики.
			</p>
			<p class="NewsPage-sub-title">
				Занятия на профильной смене «Осенняя интеллектуальная общеразвивающая школа» будут проходить по двум модулям. В Первом модуле школьники будут изучать историю от первобытного мира и древних цивилизаций до современности. Второй модуль посвящен праву, на котором ребята смогут познакомиться со своими правами и основными правовыми документами нашей страны.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена «Шаг в мир науки» рассчитана на школьников 5-10 классов, которые увлечены информатикой и физикой. Обучение по программе также пройдет по двум модулям, на которых участники смены изучат основы Web – индустрии, двоичного поиска, комбинаторики, программирования, а также основные темы физики: строение веществ, взаимодействие тел, движение жидкостей и газов, работа и мощность, энергия.
			</p>
			<p class="NewsPage-sub-title">
				Итоговыми работами по программам «Актуальные вопросы обществознания», «Осенняя интеллектуальная общеразвивающая школа», «Шаг в мир науки» будет разработка и защита проектов. Участники профильной смены «Школа безопасности. Основы безопасности жизнедеятельности» пройдут итоговое тестирование.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, в Образовательном центре «Персей» в сентябре-октябре этого года проходят по направлению «Спорт», «Наука» и «Искусство» обучение более 200 школьников из 20 муниципальных образований Иркутской области.
			</p>
		</div>
	</div>
</template>
