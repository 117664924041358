<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Наследие народного танца»
		</h2>
		<p class="EventsPage-date">
			с 1 марта 2024 года
		</p>
		<hr>

		<p class="EventsPage-text">
			Программа составлена для детей 10-17 лет, занимающихся в организациях дополнительного образования (ДШИ, самодеятельные танцевальные коллективы). Предварительная подготовка обучающихся заключается в базовом владении основами народного танца:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				постановка корпуса, рук, ног;
			</li>
			<li class="JuniorProfi-items">
				знание основных элементов народного танца.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Учебные занятия проводятся очно с периодичностью 2 раза в неделю.
		</p>
		<p class="EventsPage-text">
			По итогам обучения выдаются сертификаты об освоении дополнительной общеразвивающей программы.

		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Намаконова Лариса Владимировна, главный балетмейстер ГБУ ДО ИО «Центр развития творчества детей и юношества «Узорочье».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<p class="JuniorProfi-sub-title">
			Для участия необходимо:
		</p>
		<p class="EventsPage-text">
			Зарегистрироваться в системе Навигатор дополнительного образования Иркутской области по ссылке: <a style="color:blue" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24588-programma-nasledie-narodnogo-tantsa">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24588-programma-nasledie-narodnogo-tantsa</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>