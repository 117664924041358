<template>
	<div class="Cura-container _cover">
		<div class="Cura-content">

			<ul class="Cura-list grid-container">
				<li class="Cura-items grid-element">
					<img class="Cura-img" loading="lazy" src="../assets/img/Cura/DiplomaWebsite.webp" alt="#">
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link"
						href="https://pos.gosuslugi.ru/og/org-activities?mun_code=25701000&utm_source=vk2&utm_medium=25&utm_campaign=1083808001353"
						alt="Высказать мнение" target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/Opinion.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/8.-%D0%94%D1%80%D0%BE%D0%BF%D0%BF%D0%B5%D1%80%D1%8B-%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B5-%D1%81%D1%82%D0%B0%D1%82%D1%8C-%D1%81%D0%BE%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA%D0%BE%D0%BC-%D0%BF%D1%80%D0%B5%D1%81%D1%82%D1%83%D0%BF%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8%D1%82%D0%BE%D0%B3-%D0%B4%D0%BB%D1%8F-%D0%BF%D0%B5%D0%BD%D1%81%D0%B8%D0%BE%D0%BD%D0%B5%D1%80%D0%BE%D0%B2.pdf"
						alt="Дропперы - как не стать соучастником преступления-итог для пенсионеров" target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/Dropper1.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link" href="http://www.napf.ru/PDS" alt="ПРОГРАММА ДОЛГОСРОЧНЫХ СБЕРЕЖЕНИЙ"
						target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/PDS.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/besplatnaya_yuridicheskaya_pomoshch.pdf"
						alt="Юридическая помощь" target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/yuridicheskaya.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link" href="https://uso.coko38.ru"
						alt="Социологический опрос «Удовлетворенность системой образования Иркутской области»" target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/SOCTS.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/8-2.%D0%94%D1%80%D0%BE%D0%BF%D0%BF%D0%B5%D1%80%D1%8B-%D0%BF%D1%80%D0%B5%D1%81%D1%82%D1%83%D0%BF%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B8-%D0%BD%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8%D1%82%D0%BE%D0%B3-%D0%B4%D0%BB%D1%8F-%D0%B2%D0%B7%D1%80%D0%BE%D1%81%D0%BB%D1%8B%D1%85.pdf"
						alt="Дропперы - как не стать соучастником преступления-итог для пенсионеров" target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/Dropper2.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link"
						href="https://pos.gosuslugi.ru/form/?opaId=374583&utm_source=vk&utm_medium=25&utm_campaign=1073808005534"
						alt="Сообщить о проблеме" target="_blank">
						<img class="Cura-img" loading="lazy" src="../assets/img/Cura/Problem.webp" alt="#">
					</a>
				</li>
				<li class="Cura-items grid-element">
					<a class="Cura-items-link" href="https://bus.gov.ru/qrcode/rate/753703" alt="Оценка организации"
						target="_blank">
						<figure class="Cura-items grid-element">
							<img class="Cura-img" loading="lazy" src="../assets/img/Cura/qrcode.webp" alt="#">
							<figcaption style="display: flex;	justify-content: center;">Оценка организации</figcaption>
						</figure>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<style>
.Cura-items {
	min-width: 300px;
	width: 100%;
	height: 180px;

	
}

.Cura-img {
	width: 100%;
	height: 100%;
	object-position: center;
	border-radius: 10px ;
	transition: all 0.2s linear;
	outline: 1px solid #c3c3c3;
	object-fit: contain;
}
</style>

<script>
	export default {

}
</script>