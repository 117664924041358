<template>
	<div class="AdditionalGneral _cover">
		<h2 class="AdditionalGneral-title _cover-title">Перечень реализуемых дополнительных общеразвивающих программ 2023
			года</h2>

		<!-- Искусство -->

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Art box
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная, в том числе с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие учащиеся
				образовательных организаций в возрасте 10-17 лет</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Результатом освоения дополнительной общеразвивающей программы в
				области декоративно-прикладного искусства «ART BOX» является приобретение учащимися следующих знаний:</p>

			<p class="AdditionalGneral-summary-dict">Предметные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основ техники оригами, как одного из видов
					конструирования из бумаги.</li>
				<li class="AdditionalGneral-summary-dict-items">знакомство с терминологией и базовыми формами оригами
					Метапредметные.</li>
				<li class="AdditionalGneral-summary-dict-items">развитие художественно – эстетического вкуса в процессе освоения
					техник декоративно – прикладного творчества.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие художественно-творческого мышления, наблюдательности и
					фантазии;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование эстетических потребностей (потребностей в общении с
					искусством, природой, потребностей в творческом отношении к окружающему миру, потребностей в самостоятельной
					практической творческой деятельности), ценностей и чувств;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения обсуждать и анализировать собственную
					художественную деятельность и работу одногруппников, с позиций творческих задач данной темы, с точки зрения
					содержания и средств его выражения.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-ART-BOX.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Волонтеры культуры
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 14 до 17 лет. Программа ориентирована преимущественно на обучающихся, проявивших себя в
				проектной, творческой деятельности.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать особенности ключевых направлений добровольчества в сфере
					культуры;</li>
				<li class="AdditionalGneral-summary-dict-items">знать основные компетенции волонтеров культуры;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь владеть волонтерскими навыками при участии в
					социокультурных проектах;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь применять технические приемы и навыки в пленэрной
					изобразительной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать и применять знания на практике;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь самостоятельно искать и выделять нужную информацию, в
					том числе и при помощи компьютерных средств.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать и применять знания на практике;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь выражать свою индивидуальную и оригинальную точку зрения.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%92%D0%BE%D0%BB%D0%BE%D0%BD%D1%82%D0%B5%D1%80%D1%8B-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Лаборатория творчества хоровая асамблея
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Заочная, в том числе с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие обучающиеся в
				возрасте 10-17 лет, являющиеся учащимися учреждений дополнительного образования (ДМШ и ДШИ) Иркутской области.
				Освоение программы требует от учащихся предварительной вокально-хоровой подготовки, а именно опыта работы в
				хоровом коллективе не менее 2-х лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Результатом освоения дополнительной общеразвивающей программы в
				области музыкального искусства «Лаборатория творчества: хоровая ассамблея» является приобретение обучающимися в
				области хорового музыкального исполнительства следующих</p>

			<p class="AdditionalGneral-summary-dict">знаний:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание характерных особенностей хорового пения, вокально-хоровых
					жанров и основных стилистических направлений хорового исполнительства;</li>
				<li class="AdditionalGneral-summary-dict-items">музыкальной терминологии;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">умений:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">грамотно исполнять музыкальные произведения, как сольно, так и в
					составах хорового и вокального коллективов;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать художественный образ при исполнении музыкального
					произведения;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно разучивать вокально-хоровые партии.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">навыков:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исполнять партии в составе хорового коллектива;</li>
				<li class="AdditionalGneral-summary-dict-items">чтения с листа вокально-хоровых произведений (хоровых партий);
				</li>
				<li class="AdditionalGneral-summary-dict-items">первичных навыков в области теоретического и музыкального
					анализа исполняемых произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">публичных выступлений.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9B%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D1%82%D0%B2%D0%BE%D1%80%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0-%D0%A5%D0%BE%D1%80%D0%BE%D0%B2%D0%B0%D1%8F-%D0%B0%D1%81%D1%81%D0%B0%D0%BC%D0%B1%D0%BB%D0%B5%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Оркестровое духовое исполнительство
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">66 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Программа ориентирована на школьников от 10 до 17 лет. Обучение по
				программе требует наличие основных музыкальных способностей: слуха, ритма, памяти, исполнительских навыков игры
				на духовых или ударных инструментах, а также начальных навыков игры в ансамбле.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">игры в ансамбле и оркестре; </li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельного разбора новых произведений и чтения с листа;
				</li>
				<li class="AdditionalGneral-summary-dict-items">исполнения элементов «дефиле» и фигурной маршировки;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">общих принципов игры в ансамбле и оркестре;</li>
				<li class="AdditionalGneral-summary-dict-items">выразительных и технических возможностей духовых и ударных
					инструментов;</li>
				<li class="AdditionalGneral-summary-dict-items">особенностей оркестрового репертуара;</li>
				<li class="AdditionalGneral-summary-dict-items">двигательных и танцевальных элементов оркестрового дефиле;
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исполнять музыкальные произведения на достаточном
					художественном уровне в соответствии с их стилевыми особенностями; </li>
				<li class="AdditionalGneral-summary-dict-items">слушать и слышать всю партитуру оркестра;</li>
				<li class="AdditionalGneral-summary-dict-items">быстро переходить из одного эмоционального состояния в другое
					при смене жанра произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">тонко понимать интонацию: мотив, фразу в строении музыкальной
					формы;</li>
				<li class="AdditionalGneral-summary-dict-items">контролировать синхронность совместной игры;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять элементарные передвижения в соответствии с
					концертно-сценическим планом оркестрового дефиле.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D1%80%D0%BA%D0%B5%D1%81%D1%82%D1%80%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B4%D1%83%D1%85%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Песенные традиции казачества
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Заочная, в том числе с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">24 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся в возрасте от 10 до 15
				лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие духовно-нравственных, этических и эстетических чувств;
				</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание доброжелательности и эмоционально-нравственной
					отзывчивости, уважительного отношения к историко-культурным традициям казачества;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование позитивного отношения к родной культуре, повышение
					самооценки.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение наблюдать за различными явлениями жизни и искусства во
					внеурочной деятельности, понимать их специфику и эстетическое многообразие;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять инициативу в постановке новых задач, предлагать
					собственные способы решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений планировать, контролировать и оценивать
					учебные действия;</li>
				<li class="AdditionalGneral-summary-dict-items">использование речевых средств и средств
					информационно-коммуникативных технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять информационный поиск;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками смыслового чтения различных стилей и жанров.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно исполнять песню из выученного репертуара;
				</li>
				<li class="AdditionalGneral-summary-dict-items">знание малых форм казачьего фольклора;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование основ музыкальной культуры, в том числе на
					материале музыкальной казачьей культуры родного края;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие художественного вкуса, устойчивый интерес к
					музыкальному искусству и различным видам музыкально-творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%B5%D1%81%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5-%D1%82%D1%80%D0%B0%D0%B4%D0%B8%D1%86%D0%B8%D0%B8-%D0%BA%D0%B0%D0%B7%D0%B0%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Танцевальный фольклор Иркутской области
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">54 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 10 до 17 лет, проживающие на территории Иркутской области. Предварительная подготовка
				учащихся должна заключаться в сформированных двигательных, координационных и музыкальных навыках, полученных в
				хореографических коллективах.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыки работы в группе;</li>
				<li class="AdditionalGneral-summary-dict-items">демонстрация различных танцевальных региональных особенностей;
				</li>
				<li class="AdditionalGneral-summary-dict-items">координация музыкального слуха и движения во время исполнения
					танцев.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исторические, этнические особенности танцевальной культуры
					региона;</li>
				<li class="AdditionalGneral-summary-dict-items">танцевальная лексика и характерные особенности исполнения
					народного танца;</li>
				<li class="AdditionalGneral-summary-dict-items">основные календарные народные праздники и обряды им
					сопутствующие.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">демонстрировать выразительный и технически грамотный показ
					народных движений, этюдов и танцев;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности, слаженности, гармоничности и
					художественной привлекательности музыкального произведения, представляемого на сцене.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A2%D0%B0%D0%BD%D1%86%D0%B5%D0%B2%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%84%D0%BE%D0%BB%D1%8C%D0%BA%D0%BB%D0%BE%D1%80.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Теория оркестрового духового исполнительства
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно – заочная, с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">24 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Программа ориентирована на школьников от 10 до 17 лет. Обучение по
				программе требует наличие основных музыкальных способностей: слуха, ритма, памяти, исполнительских навыков игры
				на духовых инструментах</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-title">Знание:</p>

			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">история формирования духового оркестра;</li>
				<li class="AdditionalGneral-summary-dict-items">истории военных духовых коллективов;</li>
				<li class="AdditionalGneral-summary-dict-items">роли духового исполнительства;</li>
				<li class="AdditionalGneral-summary-dict-items">стилистических особенностей исполнения в разные эпохи.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%BE%D1%80%D0%BA%D0%B5%D1%81%D1%82%D1%80%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE-%D0%B4%D1%83%D1%85%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Юный фотохудожник
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">65 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам образовательных организаций, в возрасте от 10 до 17 лет. Обучение по программе не
				предполагает наличие определенных знаний.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над фотопроектами;
				</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к объектам
					фотосъёмки;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к фотосъёмке;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы проведения фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование первоначальных представлений о системе
					фотографических жанров, формирование навыка составления фотокомпозиции;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков обработки фотографий в графических
					редакторах;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков систематизации и размещения в сети Интернет
					графических файлов;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов; самостоятельно
					реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе предварительного
					планирования.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AE%D0%BD%D1%8B%D0%B9-%D1%84%D0%BE%D1%82%D0%BE%D1%85%D1%83%D0%B4%D0%BE%D0%B6%D0%BD%D0%B8%D0%BA.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Игры звука
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Программа ориентирована на школьников от 10 до 17 лет. Обучение по
				программе требует наличие основных музыкальных способностей: слуха, ритма, памяти, исполнительских навыков игры
				на духовых или ударных инструментах.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы.</p>
			<p class="AdditionalGneral-summary-sub-title">Занимаясь по данной программе, учащиеся приобретают:</p>

			<p class="AdditionalGneral-summary-dict">практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">игры в ансамбле и оркестре;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельного разбора новых произведений, навыки чтения с
					листа.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">общих принципов игры в ансамбле и оркестре;</li>
				<li class="AdditionalGneral-summary-dict-items">выразительных и технических возможностей духовых и ударных
					инструментов;</li>
				<li class="AdditionalGneral-summary-dict-items">особенностей ансамблевого репертуара;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исполнять музыкальные произведения на достаточно художественном
					уровне в соответствии со стилевыми особенностями</li>
				<li class="AdditionalGneral-summary-dict-items">воспринимать целостность музыкального образа (сопереживание,
					умение находиться в одном эмоциональном состоянии с партнером и т.д.);</li>
				<li class="AdditionalGneral-summary-dict-items">ощущать разнообразие нюансов и тембров;</li>
				<li class="AdditionalGneral-summary-dict-items">слушать и слышать всю партитуру ансамбля;</li>
				<li class="AdditionalGneral-summary-dict-items">быстро переходить из одного эмоционального состояния в другое;
				</li>
				<li class="AdditionalGneral-summary-dict-items">более тонко понимать интонацию, мотив, фразу в строении
					музыкальной формы;</li>
				<li class="AdditionalGneral-summary-dict-items">контролировать синхронность совместной игры;</li>
				<li class="AdditionalGneral-summary-dict-items">чувствовать солиста или второго участника ансамбля, добиваясь
					единства поставленных художественных задач и эмоционального состояния произведения.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D0%B3%D1%80%D1%8B-%D0%B7%D0%B2%D1%83%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Основы пленэра
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная – курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций, обучающиеся по дополнительным общеразвивающим программам в возрасте от 10 до 15 лет. Обучение по
				программе предполагает наличие определенных знаний:</p>

			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">представление о линейной и воздушной перспективе;</li>
				<li class="AdditionalGneral-summary-dict-items">первоначальные навыки передачи солнечного освещения, изменения
					локального цвета;</li>
				<li class="AdditionalGneral-summary-dict-items">последовательность ведения зарисовки, этюда.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над этюдом,
					зарисовкой;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление индивидуальности в использовании технических приемов
					и выразительных средств в изображении зарисовки;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание трудолюбия и самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать правильность изображения</li>
				<li class="AdditionalGneral-summary-dict-items">умение планировать работу и находить ошибки в работе;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах изобразительной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к пленэру;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы ведения работы на пленэре;</li>
				<li class="AdditionalGneral-summary-dict-items">научиться делать быстрые и длительные зарисовки пейзажа и
					передавать характерность в быстрых набросочных изображениях растений, животных, насекомых.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D1%8B-%D0%BF%D0%BB%D0%B5%D0%BD%D1%8D%D1%80%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Сибирские наигрыши
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие учащиеся в
				возрасте 10-17 лет, являющиеся учащимися учреждений дополнительного образования (ДМШ и ДШИ) Иркутской области.
				Освоение программы требует от учащихся предварительной музыкальной подготовки, а именно опыта игры на русских
				музыкальных инструментах не менее 2-х лет.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Результатом освоения дополнительной общеразвивающей программы в
				области музыкального искусства «Сибирские наигрыши» является приобретение обучающимися в области коллективного
				музыкального исполнительства следующих знаний:</p>

			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие интереса у учащихся к музыкальному искусству в целом;
				</li>
				<li class="AdditionalGneral-summary-dict-items">реализация в музыкальном коллективе индивидуальных практических
					навыков игры на инструменте;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение особых навыков игры в музыкальном коллективе;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыка чтения нот с листа;</li>
				<li class="AdditionalGneral-summary-dict-items">знание репертуара для ансамблей, оркестров русских народных
					инструментов;</li>
				<li class="AdditionalGneral-summary-dict-items">наличие навыков репетиционно-концертной работы в качестве члена
					музыкального коллектива.</li>
				<li class="AdditionalGneral-summary-dict-items">первичных навыков в области теоретического и музыкального
					анализа исполняемых произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">публичных выступлений. </li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%B8%D0%B1%D0%B8%D1%80%D1%81%D0%BA%D0%B8%D0%B5-%D0%BD%D0%B0%D0%B8%D0%B3%D1%80%D1%8B%D1%88%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Экстерьерная роспись
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 11 до 16 лет. Обучение по программе требует предварительной подготовки, а именно,
				знания основ построения рисунка и композиции, навыков живописи.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать особенности стилей в истории интерьера и архитектуры;
				</li>
				<li class="AdditionalGneral-summary-dict-items">знать основные приемы изменения пространства с помощью росписи
					стен в интерьере и экстерьере;</li>
				<li class="AdditionalGneral-summary-dict-items">знать способы и приемы построения композиции;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь наглядно представлять собственные идеи;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать объем, пропорции, форму;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь стилизовать объемную форму;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь применять технические приемы и навыки в работе с
					бумагой.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать и применять знания по мировой
					художественной культуре;</li>
				<li class="AdditionalGneral-summary-dict-items">получить навыки работы в группах;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь самостоятельно искать и выделять нужную информацию, в
					том числе и при помощи компьютерных средств.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности, аккуратности и художественной
					привлекательности работы;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь выражать свою индивидуальную и оригинальную точку зрения.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AD%D0%BA%D1%81%D1%82%D0%B5%D1%80%D1%8C%D0%B5%D1%80%D0%BD%D0%B0%D1%8F-%D1%80%D0%BE%D1%81%D0%BF%D0%B8%D1%81%D1%8C.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<!-- Наука -->

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Время первых
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам образовательных организаций, в возрасте от 10 до 17 лет. Обучение по программе не
				предполагает наличие определенных знаний.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">воспитание Российской гражданской идентичности: патриотизм,
					уважение к Отечеству;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общение и
					сотрудничестве со сверстниками и взрослыми;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы коммуникативных навыков;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков креативности;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков альтруизма;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					самостоятельно реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе
					предварительного планирования.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%92%D1%80%D0%B5%D0%BC%D1%8F-%D0%BF%D0%B5%D1%80%D0%B2%D1%8B%D1%85.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Литературная карта Восточной Сибири
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются общеобразовательных школ в возрасте
				от 10 до 17 лет. Обучение по программе не предполагает наличие определенных знаний. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование литературного вкуса, чувства гармонии;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над проектами;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к литературе
					Восточной Сибири;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к литературе Восточной Сибири;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы написания эссе;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование представлений о филологических исследованиях, эссе
					и публицистике о ярких деятелях литературы и журналистики (исторических фигурах и наших современниках)
					региона.;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно реализовывать, контролировать и осуществлять
					коррекцию своей деятельности на основе предварительного планирования.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%BC%D0%B0-%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%BD%D0%B0%D1%8F-%D0%BA%D0%B0%D1%80%D1%82%D0%B0-%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир профпроектов
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, Угольный проезд, 68/1, передвижная лаборатория
				«Мобильный Персей»</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам и программам среднего профессионального образования в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">иметь представление о многообразии мира профессий;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть начальными навыками самоанализа, формулировать свои
					интересы, потребности, выделять свои сильные и слабые стороны;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков объёмного, пространственного, логического
					мышления и конструкторские способности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять коммуникативные навыки;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть начальными навыками проектной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">иметь представление о ценности труда;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительно относиться к представителям различных профессий.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B8%D1%80-%D0%BF%D1%80%D0%BE%D1%84%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BE%D0%B2.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир экспериментов
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, Угольный проезд, 68/1, передвижная лаборатория
				«Мобильный Персей»</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам и программам среднего профессионального образования 5-7 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">правила техники безопасности при проведении опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">названия и правила пользования приборов - помощников при
					проведении опытов;</li>
				<li class="AdditionalGneral-summary-dict-items">способы познания окружающего мира (наблюдения, эксперименты);
				</li>
				<li class="AdditionalGneral-summary-dict-items">основные физические, химические, технические, экологические
					понятия;</li>
				<li class="AdditionalGneral-summary-dict-items">свойства и явления природы.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять на практике изученный теоретический материал и
					применять его при проведении опытов и экспериментов с объектами живой и неживой природы;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться оборудованием для проведения опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">вести наблюдения за окружающей природой;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать исследовательскую деятельность;
				</li>
				<li class="AdditionalGneral-summary-dict-items">выделять объект исследования, разделять учебно-исследовательскую
					деятельность на этапы;</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование познавательных интересов, интеллектуальных и
					творческих способностей обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование целостного мировоззрения, соответствующего
					современному уровню развития науки и технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в приобретении новых знаний и практических
					умений; готовность к выбору жизненного пути в соответствии с собственными интересами и возможностями;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление технико-технологического мышления при организации
					своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">мотивация образовательной деятельности обучающихся на основе
					личностно ориентированного подхода;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ценностных отношений друг к другу, преподавателю,
					авторам открытий и изобретений, результатам обучения; формирование коммуникативной компетентности в процессе
					проектной, учебно-исследовательской, игровой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи; умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач; развитие монологической и диалогической речи, умения
					выражать свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого
					человека на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать термины области «Робототехника»;</li>
				<li class="AdditionalGneral-summary-dict-items">умение конструировать механизмы для преобразования движения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формально выполнять алгоритмы, описанные с использованием
					конструкций ветвления (условные операторы) и повторения (циклы), вспомогательных алгоритмов, простых и
					табличных величин; умение создавать и выполнять программы для решения несложных алгоритмических задач в
					выбранной среде программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планирование технологического процесса в процессе создания
					роботов и робототехнических сформированность понимания общих экологических законов, особенностей влияния
					человеческой деятельности на состояние природной и социальной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта эколого-направленной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность экологического мышления и способности учитывать
					и оценивать экологические последствия в разных сферах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение базовыми экологическими понятиями, владение
					способностями применять экологические знания в жизненных ситуациях, связанных с выполнением типичных
					социальных ролей;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность личностного отношения к экологическим
					ценностям, моральной ответственности за экологические последствия своих действий в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у обучающихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять соединения неизвестного состава на основании
					их свойств и условий протекания реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать физические свойства систем для решения
					химических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B8%D1%80-%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D1%82%D0%BE%D0%B2.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Олимпиадное право
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам 7-10 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Повышение общего культурного уровня учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие правовой грамотности учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">Формирование общего представления об основах юридического знания
					у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Совершенствование навыков познавательной и
					учебно-исследовательской деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Владение и понимание основных понятий и концепций юридической
					теории;</li>
				<li class="AdditionalGneral-summary-dict-items">Углубление знаний в сфере права и способность к их применению на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие основ логического мышления для формирования навыка
					юридического мышления.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%BD%D0%BE%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Олимпиадное право (продвинутый уровень)
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам 7-10 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Повышение общего культурного уровня учащихся</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие правовой грамотности учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">Формирование общего представления об основах юридического знания
					у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Совершенствование навыков познавательной и
					учебно-исследовательской деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Владение и понимание основных понятий и концепций юридической
					теории;</li>
				<li class="AdditionalGneral-summary-dict-items">Углубление знаний в сфере права и способность к их применению на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие основ логического мышления для формирования навыка
					юридического мышления.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%BD%D0%BE%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%BE-%D0%BF%D1%80%D0%BE%D0%B4%D0%B2%D0%B8%D0%BD%D1%83%D1%82%D1%8B%D0%B9-%D1%83%D1%80%D0%BE%D0%B2%D0%B5%D0%BD%D1%8C.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Опыты и эксперименты
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, Угольный проезд, 68/1, передвижная лаборатория
				«Мобильный Персей»</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам и программам среднего профессионального образования 5-7 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">правила техники безопасности при проведении опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">названия и правила пользования приборов - помощников при
					проведении опытов;</li>
				<li class="AdditionalGneral-summary-dict-items">способы познания окружающего мира (наблюдения, эксперименты);
				</li>
				<li class="AdditionalGneral-summary-dict-items">основные физические, химические, технические, экологические
					понятия;</li>
				<li class="AdditionalGneral-summary-dict-items">свойства и явления природы.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять на практике изученный теоретический материал и
					применять его при проведении опытов и экспериментов с объектами живой и неживой природы;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться оборудованием для проведения опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">вести наблюдения за окружающей природой;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать исследовательскую деятельность;
				</li>
				<li class="AdditionalGneral-summary-dict-items">выделять объект исследования, разделять учебно-исследовательскую
					деятельность на этапы;</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование познавательных интересов, интеллектуальных и
					творческих способностей обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование целостного мировоззрения, соответствующего
					современному уровню развития науки и технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в приобретении новых знаний и практических
					умений; готовность к выбору жизненного пути в соответствии с собственными интересами и возможностями;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление технико-технологического мышления при организации
					своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">мотивация образовательной деятельности обучающихся на основе
					личностно ориентированного подхода;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ценностных отношений друг к другу, преподавателю,
					авторам открытий и изобретений, результатам обучения; формирование коммуникативной компетентности в процессе
					проектной, учебно-исследовательской, игровой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи; умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач; развитие монологической и диалогической речи, умения
					выражать свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого
					человека на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать термины области «Робототехника»;</li>
				<li class="AdditionalGneral-summary-dict-items">умение конструировать механизмы для преобразования движения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формально выполнять алгоритмы, описанные с использованием
					конструкций ветвления (условные операторы) и повторения (циклы), вспомогательных алгоритмов, простых и
					табличных величин; умение создавать и выполнять программы для решения несложных алгоритмических задач в
					выбранной среде программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планирование технологического процесса в процессе создания
					роботов и робототехнических сформированность понимания общих экологических законов, особенностей влияния
					человеческой деятельности на состояние природной и социальной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта эколого-направленной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность экологического мышления и способности учитывать
					и оценивать экологические последствия в разных сферах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение базовыми экологическими понятиями, владение
					способностями применять экологические знания в жизненных ситуациях, связанных с выполнением типичных
					социальных ролей;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность личностного отношения к экологическим
					ценностям, моральной ответственности за экологические последствия своих действий в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у обучающихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять соединения неизвестного состава на основании
					их свойств и условий протекания реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать физические свойства систем для решения
					химических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%BF%D1%8B%D1%82%D1%8B-%D0%B8-%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D1%82%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Погружение внутрь основы анатомии человека
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности обучающихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию,
					осознанному выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире
					профессий и профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе
					формирования уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, детьми старшего и младшего возраста, взрослыми в процессе образовательной,
					общественно полезной, учебно-исследовательской, творческой и других видов деятельности</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учебе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей, в том
					числе альтернативные, осознанно выбирать наиболее эффективные способы решения учебных и познавательных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать правильность выполнения учебной задачи,
					собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области анатомии;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность с учителем и сверстниками; работать индивидуально и в группе: находить общее решение и разрешать
					конфликты на основе согласования позиций и учета интересов; формулировать, аргументировать и отстаивать свое
					мнение;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">узнают особенности организма человека, его строение,
					жизнедеятельности, высшей нервной деятельности и поведения; </li>
				<li class="AdditionalGneral-summary-dict-items">узнают основные термины (обмен веществ, превращение энергии,
					питание, дыхание, выделение, транспорт веществ, рост, развитие, размножение, наследственность и изменчивость,
					регуляция жизнедеятельности организма, раздражимость)</li>
				<li class="AdditionalGneral-summary-dict-items">научится проводить наблюдения за состоянием собственного
					организма;</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B3%D1%80%D1%83%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D1%8C-%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D1%8B-%D0%B0%D0%BD%D0%B0%D1%82%D0%BE%D0%BC%D0%B8%D0%B8-%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по английскому языку
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1. </p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет. Приступая к освоению данной программы, школьник должен иметь базовые
				знания первого иностранного языка не ниже уровня В1 в соответствии с общеевропейскими компетенциями владения
				иностранными языками.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">этические нормы и ценности иноязычной культуры;</li>
				<li class="AdditionalGneral-summary-dict-items">основные фонетические, лексические, грамматические,
					словообразовательные явления и закономерности функционирования английского языка;</li>
				<li class="AdditionalGneral-summary-dict-items">композиционные элементы текста;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности официального, нейтрального и неофициального
					регистров общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">свободно выражать свои мысли, адекватно используя разнообразные
					языковые средства с целью выделения релевантной информации;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать этикетные формулы в устной и письменной
					коммуникации.</li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыками социокультурной и межкультурной коммуникации;</li>
				<li class="AdditionalGneral-summary-dict-items">культурой мышления, способностью к анализу, обобщению
					информации, постановке целей и выбору путей их достижения;</li>
				<li class="AdditionalGneral-summary-dict-items">системой лингвистических знаний;</li>
				<li class="AdditionalGneral-summary-dict-items">основными дискурсивными способами реализации коммуникативных
					целей высказывания.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%BE%D0%BC%D1%83-%D1%8F%D0%B7%D1%8B%D0%BA%D1%83.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по географии
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение на начальном уровне географическими знаниями и
					умениями;</li>
				<li class="AdditionalGneral-summary-dict-items">осознание ценности географического знания как важнейшего
					компонента научной картины мира;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение основами картографической грамотности и использования
					географической карты;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основными навыками нахождения и использования
					географической информации;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование представлений о географической науке и ее основных
					разделах.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%B3%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по истории
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет. Учащиеся по данной программе должны демонстрировать следующий уровень
				подготовки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">иметь первоначальные навыки работы с историческим источником;
				</li>
				<li class="AdditionalGneral-summary-dict-items">знать основные этапы и специфику исторического развития России;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владеть навыками написания сочинения.</li>
			</ul>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Повышение общего культурного уровня учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие интереса к изучению исторического прошлого России;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие интеллектуальных и практических умений по
					самостоятельному приобретению и применению на практике полученные знания по истории России.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Формирование культуры работы с историческими источниками и
					литературой, выступлений на семинарах, ведения дискуссий;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие интерес к изучению исторического прошлого России;</li>
				<li class="AdditionalGneral-summary-dict-items">Формирование общего представления об историческом прошлом
					России.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по математике
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие самостоятельности суждений, независимости и
					нестандартности мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие любознательности, сообразительности при выполнении
					разнообразных заданий проблемного и эвристического характера;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование способности самостоятельного приобретения
					новых знаний и практических умений.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осуществление развернутых действий контроля и самоконтроля:
					сравнивание построенной конструкции с образцом;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование основы для дальнейшего математического развития;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развитие механизмов мышления, характерных для математической
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование умений формализации и структурирования
					информации, умения выбирать способ представления данных в соответствии с поставленной задачей.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по ОБЖ
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам и программам среднего профессионального образования в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование активной жизненной позиции, умений и навыков
					личного участия в обеспечении мер безопасности личности, общества и государства;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков работы с информацией, поиск, отбор источников,
					с учётом предложенной учебной задачи и заданных критериев.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основных понятий в области безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение основными навыками решения олимпиадных заданий по ОБЖ;
				</li>
				<li class="AdditionalGneral-summary-dict-items">формирование культуры безопасности жизнедеятельности на основе
					освоенных знаний и умений, комплексного понимания значимости безопасного поведения в условиях опасных и
					чрезвычайных ситуаций для личности, общества и государства.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%9E%D0%91%D0%96.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по праву
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие правовой грамотности учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование общего представления об основах юридического знания
					у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование навыков познавательной и
					учебно-исследовательской деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение и понимание основных понятий и концепций юридической
					теории;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление знаний в сфере права и способность к их применению на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление знаний в сфере права и способность к их применению на
					практике;</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%BF%D1%80%D0%B0%D0%B2%D1%83.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по русскому языку
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание этической ценности русского языка;</li>
				<li class="AdditionalGneral-summary-dict-items">увеличение словарного запаса и уровня понимания русского языка,
					его места в современном мире;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осуществлять речевой самоконтроль, оценивать устные и письменные
					высказывания с точки зрения языкового оформления;</li>
				<li class="AdditionalGneral-summary-dict-items">понимать связь языка и истории, культуры русского народа;</li>
				<li class="AdditionalGneral-summary-dict-items">знать основные единицы языка, их признаки;</li>
				<li class="AdditionalGneral-summary-dict-items">проводить лингвистический анализ текстов различных стилей;</li>
				<li class="AdditionalGneral-summary-dict-items">применять в практике речевого общения основные орфоэпические,
					лексические, грамматические нормы современного русского языка;</li>
				<li class="AdditionalGneral-summary-dict-items">соблюдать в практике письма орфографические и пунктуационные
					нормы современного русского литературного языка;</li>
				<li class="AdditionalGneral-summary-dict-items">соблюдать нормы речевого поведения;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать основные, а также приобретенные приемы
					информационной переработки устного и письменного текста.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%BE%D0%BC%D1%83-%D1%8F%D0%B7%D1%8B%D0%BA%D1%83.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по физике
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие познавательного интереса, интеллектуальных и творческих
					способностей учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование способности самостоятельного приобретения
					новых знаний и практических умений;</li>
				<li class="AdditionalGneral-summary-dict-items">способствование приобретению положительного эмоционального
					отношения к окружающей природе и самому себе как части природы у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление убеждения учащихся в закономерной связи и
					познаваемости явлений природы, в объективности научного знания, в высокой ценности науки в развитии
					материальной и духовной культуры общества.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию во ВСОШ по химии
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие познавательного интереса, интеллектуальных и творческих
					способностей учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование способности самостоятельного приобретения
					новых знаний и практических умений;</li>
				<li class="AdditionalGneral-summary-dict-items">способствование приобретению положительного эмоционального
					отношения к окружающей природе и самому себе как части природы у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся общего представления о
					феноменологических знаниях о природе важнейших химических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление убеждения учащихся в закономерной связи и
					познаваемости явлений природы, в объективности научного знания, в высокой ценности науки в развитии
					материальной и духовной культуры общества.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2%D0%BE-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D1%85%D0%B8%D0%BC%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Проектная деятельность в русском языке и литературе
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">заочная, в том числе с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие учащиеся
				образовательных организаций Иркутской области в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">освоить наиболее актуальные для работы над проектами способы
					деятельности и подготовить учащихся к разработке и реализации собственных проектов;</li>
				<li class="AdditionalGneral-summary-dict-items">приобрести знания о структуре учебно-исследовательской
					деятельности, способах поиска информации, обработки результатов и его презентации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать и применять знания на практике;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать способности, позволяющие эффективно действовать в
					реальной жизненной ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать представление о русском языке и литературе как
					духовной, нравственной и культурной ценности народа, осознание национального своеобразия русского языка;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать творческие и интеллектуальные способности и логическое
					мышление учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">создавать мотивацию к самообразованию;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование навыков конструктивного сотрудничества и
					публичного выступления;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь выражать свою индивидуальную и оригинальную точку зрения.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D0%B0%D1%8F-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C-%D0%B2-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%BE%D0%BC-%D1%8F%D0%B7%D1%8B%D0%BA%D0%B5-%D0%B8-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Секреты фотосъемки
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">к освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам и программам ДШИ и ДХШ, в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над фотопроектами;
				</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к объектам
					фотосъёмки;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к фотосъёмке;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы проведения фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование первоначальных представлений о системе
					фотографических жанров, формирование навыка составления фотокомпозиции;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков обработки фотографий в графических
					редакторах;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков систематизации и размещения в сети Интернет
					графических файлов;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов; самостоятельно
					реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе предварительного
					планирования.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%B5%D0%BA%D1%80%D0%B5%D1%82%D1%8B-%D1%84%D0%BE%D1%82%D0%BE%D1%81%D1%8A%D0%B5%D0%BC%D0%BA%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Сириус. Лето начни свой проект
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам и программам среднего профессионального образования в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности обучающихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию,
					осознанному выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире
					профессий и профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе
					формирования уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, детьми старшего и младшего возраста, взрослыми в процессе образовательной,
					общественно полезной, учебно-исследовательской, творческой и других видов деятельности;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учебе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей, в том
					числе альтернативные, осознанно выбирать наиболее эффективные способы решения учебных и познавательных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать правильность выполнения учебной задачи,
					собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области проектной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность с учителем и сверстниками;</li>
				<li class="AdditionalGneral-summary-dict-items">работать индивидуально и в группе: находить общее решение и
					разрешать конфликты на основе согласования позиций и учета интересов;</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать, аргументировать и отстаивать свое мнение;</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%B8%D1%80%D0%B8%D1%83%D1%81.-%D0%9B%D0%B5%D1%82%D0%BE-%D0%BD%D0%B0%D1%87%D0%BD%D0%B8-%D1%81%D0%B2%D0%BE%D0%B9-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Социальное проектирование
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие обучающиеся
				образовательных организаций Иркутской области в возрасте от 14 до 17 лет. Программа ориентирована
				преимущественно на обучающихся, проявивших себя в проектной, творческой деятельности.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать основы социального проектирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знать этапы реализации социальных проектов;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь работать в команде над общим проектом;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь применять технические приемы и навыки в реализации
					проекта.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать и применять знания на практике;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь самостоятельно искать и выделять нужную информацию, в том
					числе и при помощи компьютерных средств.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">добиваться добросовестного отношения к выполнению своих
					обязанностей;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь выражать свою индивидуальную и оригинальную точку зрения.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%BE%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Теоретическая информатика
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 10 до17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к учению, готовность и способность
					учащихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию;</li>
				<li class="AdditionalGneral-summary-dict-items">осознанный выбор и построение дальнейшей индивидуальной
					траектории образования на базе ориентировки в мире профессий и профессиональных предпочтений с учётом
					устойчивых познавательных интересов, а также на основе формирования уважительного отношения к труду, развитие
					опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">критичность мышления, инициатива, находчивость, активность при
					решении математических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">критичность мышления, умение распознавать логически некорректные
					высказывания, отличать гипотезу от факта;</li>
				<li class="AdditionalGneral-summary-dict-items">креативность мышления, инициатива, находчивость, активность при
					решении математических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к эмоциональному (эстетическому)восприятию
					геометрических объектов, задач, решений, рассуждений;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict"></p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items"></li>
				<li class="AdditionalGneral-summary-dict-items"></li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A2%D0%B5%D0%BE%D1%80%D0%B5%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Традиции народной культуры
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие обучающиеся в
				возрасте 10-17 лет образовательных организаций Иркутской области.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь проводить сравнение и классификацию объектов;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь понимать и применять полученную информацию при выполнении
					заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование внутренней позиции обучающегося, адекватной
					мотивации учебной деятельности, включая познавательные мотивы, ориентацию на моральные нормы и их выполнение;
				</li>
				<li class="AdditionalGneral-summary-dict-items">грамотно выполнять предложенные преподавателем задания.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">организовывать собственную деятельность, выбирать и использовать
					средства для достижения её целей;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять контроль, коррекцию и оценку результатов своей
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">проявлять индивидуальные творческие способности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение выделять в потоке информации необходимый материал по
					заданной теме;</li>
				<li class="AdditionalGneral-summary-dict-items">умение активно включаться в общение и взаимодействие со
					сверстниками на принципах уважения и доброжелательности, взаимопомощи и сопереживания;</li>
				<li class="AdditionalGneral-summary-dict-items">осмысление мотивов своих действий при выполнении заданий с
					жизненными ситуациями в соответствии с традициями российского народа;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять дисциплинированность, трудолюбие и упорство в
					достижении поставленных целей.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A2%D1%80%D0%B0%D0%B4%D0%B8%D1%86%D0%B8%D0%B8-%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B9-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Финансовая грамотность
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам и программам среднего профессионального образования 7-10 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">базовых понятий (финансовая грамотность, финансовое поведение,
					статистические данные, простая финансовая информация в области экономики семьи, учебное исследование
					экономических отношений в семье и обществе;</li>
				<li class="AdditionalGneral-summary-dict-items">названия и правила пользования финансовых инструментов;</li>
				<li class="AdditionalGneral-summary-dict-items">виды операций с деньгами, планирование семейного бюджета;</li>
				<li class="AdditionalGneral-summary-dict-items">виды финансового мошенничества.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять на практике изученный теоретический материал и
					применять его при проведении финансовых операций;</li>
				<li class="AdditionalGneral-summary-dict-items">грамотно пользоваться финансовыми инструментами;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать семейный бюджет;</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность у учащегося гражданской позиции как активного и
					ответственного члена российского общества, осознающего свои конституционные права и обязанности, уважающего
					закон и правопорядок, обладающего чувством собственного достоинства;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к саморазвитию и личностному
					самоопределению;</li>
				<li class="AdditionalGneral-summary-dict-items">выявление и мотивация к раскрытию лидерских и
					предпринимательских качеств;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание мотивации к труду, умение оценивать и аргументировать
					собственную точку зрения по финансовым проблемам, стремление строить свое будущее на основе целеполагания и
					планирования;</li>
				<li class="AdditionalGneral-summary-dict-items">осознание ответственности за настоящее и будущее собственное
					финансовое благополучие, благополучие своей семьи и государства.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи; умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач; развитие монологической и диалогической речи, умения
					выражать свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого
					человека на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">способность планирования и прогнозирования будущих доходов и
					расходов личного бюджета</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">освоение и применение системы финансовых знаний, и их
					необходимости для решения современных практических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">умение классифицировать по разным признакам (в том числе
					устанавливать существенный признак классификации) финансовые объекты, явления, процессы, выявляя их
					существенные признаки, элементы и основные функции;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать полученные знания для объяснения (устного и
					письменного) сущности, взаимосвязей финансовых явлений и процессов; необходимости правомерного налогового
					поведения; осмысления личного опыта принятия финансовых решений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение решать в рамках изученного материала познавательные и
					практические задачи, отражающие процессы постановки финансовых целей, управления доходами и расходами,
					формирования резервов и сбережений, принятия инвестиционных решений;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение смысловым чтением текстов финансовой тематики,
					позволяющим воспринимать, понимать и интерпретировать смысл текстов разных типов, жанров, назначений в целях
					решения различных учебных задач, в том числе извлечений из нормативных правовых актов; умение преобразовывать
					текстовую информацию в модели (таблицу, диаграмму, схему) и преобразовывать предложенные модели в текст;</li>
				<li class="AdditionalGneral-summary-dict-items">умение анализировать, обобщать, систематизировать,
					конкретизировать и критически оценивать статистическую финансовую информацию, соотносить ее с собственными
					знаниями и опытом; формулировать выводы, подкрепляя их аргументами;</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать собственные поступки и поведение других людей с
					точки зрения финансово-экономических и правовых норм, включая вопросы, связанные с личными финансами,
					финансовыми рисками и угрозами финансового мошенничества; осознание неприемлемости всех форм антиобщественного
					поведения;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта использования полученных знаний по финансовой
					грамотности в практической (индивидуальной и/или групповой) деятельности: для составления личного финансового
					плана; для принятия решений о приобретении финансовых услуг и имущества; для приобретения опыта публичного
					представления результатов своей деятельности в соответствии с темой и ситуацией общения, особенностями
					аудитории и регламентом.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D0%B8%D0%BD%D0%B0%D0%BD%D1%81%D0%BE%D0%B2%D0%B0%D1%8F-%D0%B3%D1%80%D0%B0%D0%BC%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Финансовая грамотность (продвинутый уровень)
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам 7-10 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">способность к самостоятельным решениям в области управления
					личными финансами;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность сознательного, активного и ответственного
					поведения на финансовом рынке: поведения личности, уважающей закон, осознающей свою ответственность за
					решения, принимаемые в процессе взаимодействия с финансовыми институтами;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание прав и обязанностей в сфере управления личными
					финансами; </li>
				<li class="AdditionalGneral-summary-dict-items">готовность вести диалог с членами семьи, представителями
					финансовых институтов по вопросам управления личными финансами, достигать в нём взаимопонимания; </li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к финансовому образованию и
					самообразованию во взрослой жизни; </li>
				<li class="AdditionalGneral-summary-dict-items">сознательное отношение к непрерывному финансовому
					самообразованию как условию достижения финансового благополучия; </li>
				<li class="AdditionalGneral-summary-dict-items">способность учащегося осуществлять коммуникативную деятельность
					со сверстниками и педагогом в рамках занятий по финансовой грамотности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять финансовые цели и составлять
					планы по их достижению, осознавая приоритетные и второстепенные задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение выявлять альтернативные пути достижения поставленных
					финансовых целей;</li>
				<li class="AdditionalGneral-summary-dict-items">способность и готовность к самостоятельному поиску методов
					решения финансовых проблем; </li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в различных источниках информации
					финансового характера, критически оценивать и интерпретировать информацию, получаемую из различных источников;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять назначение и функции различных финансовых
					институтов, ориентироваться в предлагаемых финансовых продуктах, оценивать последствия их использования;</li>
				<li class="AdditionalGneral-summary-dict-items">умение общаться и взаимодействовать с учащимися и педагогом в
					рамках занятий по финансовой грамотности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<p class="AdditionalGneral-summary-dict">владение понятиями:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">личные финансы;</li>
				<li class="AdditionalGneral-summary-dict-items">сбережения;</li>
				<li class="AdditionalGneral-summary-dict-items">банк;</li>
				<li class="AdditionalGneral-summary-dict-items">депозит;</li>
				<li class="AdditionalGneral-summary-dict-items">кредит;</li>
				<li class="AdditionalGneral-summary-dict-items">ипотека;</li>
				<li class="AdditionalGneral-summary-dict-items">процент;</li>
				<li class="AdditionalGneral-summary-dict-items">инвестирование;</li>
				<li class="AdditionalGneral-summary-dict-items">финансовый риск;</li>
				<li class="AdditionalGneral-summary-dict-items">портфель инвестиций;</li>
				<li class="AdditionalGneral-summary-dict-items">бизнес;</li>
				<li class="AdditionalGneral-summary-dict-items">стартап;</li>
				<li class="AdditionalGneral-summary-dict-items">бизнес-план;</li>
				<li class="AdditionalGneral-summary-dict-items">бизнес-ангел;</li>
				<li class="AdditionalGneral-summary-dict-items">венчурный предприниматель;</li>
				<li class="AdditionalGneral-summary-dict-items">финансовое мошенничество;</li>
				<li class="AdditionalGneral-summary-dict-items">финансовые пирамиды;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">владение знанием:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">об основных целях управления личными финансами, мотивах
					сбережений, возможностях и ограничениях использования заёмных средств;</li>
				<li class="AdditionalGneral-summary-dict-items">об устройстве банковской системы, особенностях банковских
					продуктов для физических лиц, правилах инвестирования денежных средств в банковские продукты и привлечения
					кредитов;</li>
				<li class="AdditionalGneral-summary-dict-items">о видах финансовых рисков и способах минимизации их последствий
					для семейного бюджета;</li>
				<li class="AdditionalGneral-summary-dict-items">об основах функционирования и организации бизнеса, структуре
					бизнес-плана, налогообложении малого бизнеса и источниках его финансирования;</li>
				<li class="AdditionalGneral-summary-dict-items">о видах финансовых мошенничеств и особенностях их
					функционирования, способах идентификации финансовых мошенничеств среди предлагаемых финансовых продуктов.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D0%B8%D0%BD%D0%B0%D0%BD%D1%81%D0%BE%D0%B2%D0%B0%D1%8F-%D0%B3%D1%80%D0%B0%D0%BC%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%8C-%D0%BF%D1%80%D0%BE%D0%B4%D0%B2%D0%B8%D0%BD%D1%83%D1%82%D1%8B%D0%B9-%D1%83%D1%80%D0%BE%D0%B2%D0%B5%D0%BD%D1%8C.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Экологическое просвещение от теории к практике
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 10 до 14 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие познавательного интереса к окружающему миру;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание бережного отношения к окружающей природной среде;
				</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание активной жизненной позиции;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание положительных качеств личности учащегося (трудолюбия,
					настойчивости, умения работать в коллективе, коммуникации).</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование владение приёмами работы с информацией;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать элементарные приёмы исследовательской деятельности и
					навыки проектной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интеллектуальных, коммуникативных, творческих
					способностей обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование умений и навыков вести наблюдения объектами,
					явлениями природы;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение учащимися умений и навыков организации своей
					исследовательской деятельности, осуществления самоконтроля в ходе ее реализации;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение учащимися опыта успешной самореализации в процессе
					осуществления естественно-научного исследования;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать владение элементами самостоятельной организации
					деятельности при выполнении наблюдений, проектов, презентаций;</li>
				<li class="AdditionalGneral-summary-dict-items">умений ставить цель, планировать деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать собственный вклад в деятельность группы;</li>
				<li class="AdditionalGneral-summary-dict-items">давать самооценку личных достижений.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">узнают основные понятия (экология, воздух. почва, вода и т.д.);
				</li>
				<li class="AdditionalGneral-summary-dict-items">узнают основные термины (среда обитания, экологическое факторы,
					толерантность, заповедники, заказники, аутэкология и т.д.);</li>
				<li class="AdditionalGneral-summary-dict-items">познакомятся с новыми методами исследования воды, воздуха и
					почвы;</li>
				<li class="AdditionalGneral-summary-dict-items">познакомятся с механизмами заражения возбудителями инфекционных
					заболеваний и влияние мутагенных факторов на генетический аппарат клетки;</li>
				<li class="AdditionalGneral-summary-dict-items">изучат правила поведения в природе, соответствующим принципам
					экологической этики.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5-%D0%BF%D1%80%D0%BE%D1%81%D0%B2%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D1%82-%D1%82%D0%B5%D0%BE%D1%80%D0%B8%D0%B8-%D0%BA-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<!-- Дистант -->

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир вокруг нас
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций Иркутской области, в возрасте от 10 до 11 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки самоорганизации;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие мотивации к познанию окружающего мира и творчеству;
				</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной, проектной и социальной
					компетентности;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над проектом;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение применять полученные знания;</li>
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основ декоративно-прикладного искусства;</li>
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к проекту;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать методы и приёмы художественно-творческой
					деятельности в учебном процессе и повседневной жизни;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B8%D1%80-%D0%B2%D0%BE%D0%BA%D1%80%D1%83%D0%B3-%D0%BD%D0%B0%D1%81.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир науки и техники
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 10 до 18 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование познавательных интересов, интеллектуальных и
					творческих способностей учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование целостного мировоззрения, соответствующего
					современному уровню развития науки и технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в приобретении новых знаний и практических
					умений; готовность к выбору жизненного пути в соответствии с собственными интересами и возможностями;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление технико-технологического мышления при организации
					своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">мотивация образовательной деятельности учащихся на основе
					личностно ориентированного подхода;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ценностных отношений друг к другу, преподавателю,
					авторам открытий и изобретений, результатам обучения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в процессе
					проектной, учебно-исследовательской, игровой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие монологической и диалогической речи, умения выражать
					свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого человека
					на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать термины области «Робототехника»;</li>
				<li class="AdditionalGneral-summary-dict-items">умение конструировать механизмы для преобразования движения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формально выполнять алгоритмы, описанные с использованием
					конструкций ветвления (условные операторы) и повторения (циклы), вспомогательных алгоритмов, простых и
					табличных величин;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать и выполнять программы для решения несложных
					алгоритмических задач в выбранной среде программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планирование технологического процесса в процессе создания
					роботов и робототехнических сформированность понимания общих экологических законов, особенностей влияния
					человеческой деятельности на состояние природной и социальной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта эколого-направленной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность экологического мышления и способности учитывать
					и оценивать экологические последствия в разных сферах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение базовыми экологическими понятиями, владение
					способностями применять экологические знания в жизненных ситуациях, связанных с выполнением типичных
					социальных ролей;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность личностного отношения к экологическим
					ценностям, моральной ответственности за экологические последствия своих действий в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять соединения неизвестного состава на основании
					их свойств и условий протекания реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать физические свойства систем для решения
					химических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B8%D1%80-%D0%BD%D0%B0%D1%83%D0%BA%D0%B8-%D0%B8-%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Эксперименты в науке
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам и программам среднего профессионального образования 5-7 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование познавательных интересов, интеллектуальных и
					творческих способностей учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование целостного мировоззрения, соответствующего
					современному уровню развития науки и технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в приобретении новых знаний и практических
					умений; готовность к выбору жизненного пути в соответствии с собственными интересами и возможностями;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление технико-технологического мышления при организации
					своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">мотивация образовательной деятельности учащихся на основе
					личностно ориентированного подхода;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ценностных отношений друг к другу, преподавателю,
					авторам открытий и изобретений, результатам обучения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в процессе
					проектной, учебно-исследовательской, игровой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие монологической и диалогической речи, умения выражать
					свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого человека
					на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать термины области «Робототехника»;</li>
				<li class="AdditionalGneral-summary-dict-items">умение конструировать механизмы для преобразования движения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формально выполнять алгоритмы, описанные с использованием
					конструкций ветвления (условные операторы) и повторения (циклы), вспомогательных алгоритмов, простых и
					табличных величин;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать и выполнять программы для решения несложных
					алгоритмических задач в выбранной среде программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планирование технологического процесса в процессе создания
					роботов и робототехнических сформированность понимания общих экологических законов, особенностей влияния
					человеческой деятельности на состояние природной и социальной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта эколого-направленной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность экологического мышления и способности учитывать
					и оценивать экологические последствия в разных сферах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение базовыми экологическими понятиями, владение
					способностями применять экологические знания в жизненных ситуациях, связанных с выполнением типичных
					социальных ролей;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность личностного отношения к экологическим
					ценностям, моральной ответственности за экологические последствия своих действий в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять соединения неизвестного состава на основании
					их свойств и условий протекания реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать физические свойства систем для решения
					химических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AD%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D1%82%D1%8B-%D0%B2-%D0%BD%D0%B0%D1%83%D0%BA%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<!-- Профильные смены -->

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Актуальные вопросы обществознания
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">гармонизация интеллектуального и эмоционального развития
					личности;</li>
				<li class="AdditionalGneral-summary-dict-items">подготовка к осознанному выбору индивидуальной образовательной
					или профессиональной траектории;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование осознанного, уважительного и доброжелательного
					отношения к другому человеку, его мнению, мировоззрению, культуре;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность вести диалог с другими людьми и
					достигать в нем взаимопонимания;</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать с позиций социальных норм собственные поступки
					и поступки других людей</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками и взрослыми в процессе образовательной, учебно-исследовательской, творческой и
					других видов деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">выявление причинно-следственных связей;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие критического мышления, способности аргументировать свою
					точку зрения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование исследовательских, коммуникативных и информационных
					умений;</li>
				<li class="AdditionalGneral-summary-dict-items">использование анализа, синтеза, сравнения, обобщения,
					систематизации;</li>
				<li class="AdditionalGneral-summary-dict-items">определение целей и задач учебной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">выбор средств реализации целей и задач в их применении на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельная оценка достигнутых результатов.</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формировать для себя новые задачи в познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей;</li>
				<li class="AdditionalGneral-summary-dict-items">умения определять понятия, создавать обобщения, устанавливать
					аналогии причинно-следственные связи, строить логическое рассуждение, делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать сотрудничество и совместную деятельность с
					педагогом и сверстниками;</li>
				<li class="AdditionalGneral-summary-dict-items">работать индивидуально и в группе, формулировать аргументировать
					и отстаивать свое мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понятия о Родине, гражданине, семье, своей родословной, об
					отношениях в семье, человеке как личности;</li>
				<li class="AdditionalGneral-summary-dict-items">представления о взаимодействии личности и власти, устройстве
					государства, политических партиях, отношения между человеком и законом, особая роль подростка в обществе;</li>
				<li class="AdditionalGneral-summary-dict-items">представление о культуре, о духовных и нравственных ценностях,
					мировом и российском культурном достоянии;</li>
				<li class="AdditionalGneral-summary-dict-items">понятие глобализации, экологической проблемы, экологического
					кризиса, представление о экологической ситуации в стране и мире.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B-%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Анатомия без границ изучение тела человека
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">48 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">узнают особенности организма человека, его строение,
					жизнедеятельности, высшей нервной деятельности и поведения;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают основные термины (обмен веществ, превращение энергии,
					питание, дыхание, выделение, транспорт веществ, рост, развитие, размножение, наследственность и изменчивость,
					регуляция жизнедеятельности организма, раздражимость);</li>
				<li class="AdditionalGneral-summary-dict-items">научится проводить наблюдения за состоянием собственного
					организма.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учебе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей, в том
					числе альтернативные, осознанно выбирать наиболее эффективные способы решения учебных и познавательных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать правильность выполнения учебной задачи,
					собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области анатомии;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность с учителем и сверстниками; работать индивидуально и в группе: находить общее решение и разрешать
					конфликты на основе согласования позиций и учета интересов; формулировать, аргументировать и отстаивать свое
					мнение.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности обучающихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию,
					осознанному выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире
					профессий и профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе
					формирования уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, детьми старшего и младшего возраста, взрослыми в процессе</li>
				<li class="AdditionalGneral-summary-dict-items">образовательной, общественно полезной, учебно-исследовательской,
					творческой и других видов деятельности;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Воспитательные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде.</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение, ценностное отношение к своему здоровью, ответственное отношение к обучению, готовность к
					саморазвитию и самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%BD%D0%B0%D1%82%D0%BE%D0%BC%D0%B8%D1%8F-%D0%B1%D0%B5%D0%B7-%D0%B3%D1%80%D0%B0%D0%BD%D0%B8%D1%86-%D0%B8%D0%B7%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D1%82%D0%B5%D0%BB%D0%B0-%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Байкальские звезды. Абилимпикс
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся c инвалидностью и
				ограниченными возможностями здоровья в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">расширение знаний по естественно-научным дисциплинам;</li>
				<li class="AdditionalGneral-summary-dict-items">получение навыков работы с робототехникой.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">способность прогнозировать своё поведение в разных ситуациях;
					формирование коммуникативной компетенции;</li>
				<li class="AdditionalGneral-summary-dict-items">умение адекватно устанавливает границы уже известного и
					неизвестного;</li>
				<li class="AdditionalGneral-summary-dict-items">способность ставить и формулировать для себя цели действий,
					прогнозировать результаты, анализировать их (причём как положительные, так и отрицательные), делать выводы в
					процессе работы и по её окончании, корректировать намеченный план, ставить новые цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение извлекать нужную информацию из разных видов источников,
					систематизировать её, анализировать и представлять в разных формах;</li>
				<li class="AdditionalGneral-summary-dict-items">рефлексия (то есть самооценка, самонаблюдение, самоконтроль) в
					процессе коммуникации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">адаптация учащегося к различным условиям;</li>
				<li class="AdditionalGneral-summary-dict-items">удовлетворенность учащимся своей деятельностью;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование мотивов к конструктивному взаимодействию и
					сотрудничеству со сверстниками и педагогами;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие жизненных, социальных компетенций, таких как:
					автономность (способность делать выбор и контролировать личную и общественную жизнь);</li>
				<li class="AdditionalGneral-summary-dict-items">ответственность (способность принимать ответственность за свои
					действия и их последствия; мировоззрение (следование социально значимым ценностям);</li>
				<li class="AdditionalGneral-summary-dict-items">социальный интерес (способность интересоваться другими и
					принимать участие в их жизни;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность к сотрудничеству и помощи даже при неблагоприятных и
					затруднительных обстоятельствах;</li>
				<li class="AdditionalGneral-summary-dict-items">склонность человека давать другим больше, чем требовать);
					патриотизм и гражданская позиция (проявление гражданско-патриотических чувств);</li>
				<li class="AdditionalGneral-summary-dict-items">культура целеполагания (умение ставить цели и их достигать, не
					ущемляя прав и свобод окружающих людей);</li>
				<li class="AdditionalGneral-summary-dict-items">умение «презентовать» себя.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%91%D0%B0%D0%B9%D0%BA%D0%B0%D0%BB%D1%8C%D1%81%D0%BA%D0%B8%D0%B5-%D0%B7%D0%B2%D0%B5%D0%B7%D0%B4%D1%8B-%D0%90%D0%B1%D0%B8%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA%D1%81%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Беспилотные авиационные системы
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основ авиамоделирования, основными понятиями и базовыми
					элементами моделирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знание формообразование материаловедения, образное,
					пространственное мышление и умение выразить свою мысль с помощью чертежа, рисунка, авиамодели;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки работы нужными инструментами и приспособлениями при
					обработке различных материалов;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки учебно-исследовательской работы, создавать чертежи и
					шаблоны моделей.</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил техники безопасности при управлении БЛА,</li>
				<li class="AdditionalGneral-summary-dict-items">знание истории, сфер применения БЛА</li>
				<li class="AdditionalGneral-summary-dict-items">знание устройства БЛА, основных элементов, материалов, из
					которых они изготавливаются,</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками настройки БЛА,</li>
				<li class="AdditionalGneral-summary-dict-items">владение техниками управления БЛА от «третьего лица», в режиме
					FPV,</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил авиамодельного спорта в классе F3U, их
					практическое применение,</li>
				<li class="AdditionalGneral-summary-dict-items">спортивные навыки, спортивная техника в авиамодельном спорте в
					классе F3U.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение сотрудничать, выполняя различные роли в группе, в
					совместном решении проблемы (задачи);</li>
				<li class="AdditionalGneral-summary-dict-items">умения применять знания об окружающем мире из таких учебных
					предметов (окружающий мир, технология, литература и другие) для мотивации в работе по авиамоделированию;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать мелкую моторику, пластичность, гибкость рук и точность
					глазомера;</li>
				<li class="AdditionalGneral-summary-dict-items">развить мотивацию к овладению техническими процессами
					изготовления моделей любой сложности;</li>
				<li class="AdditionalGneral-summary-dict-items">реализовать технические и творческие способности воспитанников;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развить самостоятельность, ответственность, активность,
					аккуратность, трудолюбие, умение работать в группе в процессе выполнения технической работы;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать потребность в саморазвитии.</li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в своей системе знаний: отличать новое
					знание от известного;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать по предложенным инструкциям и самостоятельно;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение излагать мысли в четкой логической последовательности,
					отстаивать свою точку зрения, анализировать ситуацию и самостоятельно находить ответы на вопросы путем
					логических рассуждений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать в команде, эффективно распределять обязанности
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимание особой роли технического развития в жизни общества и
					каждого отдельного человека;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетические чувства, техническо-творческого мышления,
					наблюдательности и фантазии, потребностей в творческом отношении к окружающему миру, в самостоятельной
					практической технической деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки коллективной деятельности в процессе совместной
					технической работы в команде одноклассников под руководством педагога;</li>
				<li class="AdditionalGneral-summary-dict-items">умение обсуждать и анализировать собственную техническую
					деятельность и работу других учащихся с позиций задач данной темы, с точки зрения содержания и средств его
					выражения.</li>
				<li class="AdditionalGneral-summary-dict-items">потребность в технической самореализации в социально- полезной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">патриотические чувства и качества;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки здорового образа жизни.</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к технике и технологиям, более развитые
					технические способности,</li>
				<li class="AdditionalGneral-summary-dict-items">познавательная активность и способность к самообразованию,</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к профессиональной сфере применения БЛА,
					профессии оператора БЛА, профориентация на специальности, связанные с применением БЛА.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Воспитательные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде,
					спортивная этика,</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение,</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью,</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию и
					самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%91%D0%B5%D1%81%D0%BF%D0%B8%D0%BB%D0%BE%D1%82%D0%BD%D1%8B%D0%B5-%D0%B0%D0%B2%D0%B8%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Зимняя интеллектуальная общеразвивающая школа
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ориентация в деятельности на современную систему научных
					представлений об основных закономерностях развития человека, природы и общества, взаимосвязях человека с
					природной и социальной средой;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основными навыками исследовательской деятельности,
					установка на осмысление опыта, наблюдений, поступков и стремление совершенствовать пути достижения
					индивидуального и коллективного благополучия,</li>
				<li class="AdditionalGneral-summary-dict-items">освоение обучающимися социального опыта, основных социальных
					ролей, соответствующих ведущей деятельности возраста, норм и правил общественного поведения, форм социальной
					жизни в группах и сообществах, включая семью, группы, сформированные по профессиональной деятельности, а также
					в рамках социального взаимодействия с людьми из другой культурной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">способность обучающихся во взаимодействии в условиях
					неопределенности, открытость опыту и знаниям других;</li>
				<li class="AdditionalGneral-summary-dict-items">способность действовать в условиях неопределенности, повышать
					уровень своей компетентности через практическую деятельность, в том числе умение учиться у других людей,
					осознавать в совместной деятельности новые знания, навыки и компетенции из опыта других;</li>
				<li class="AdditionalGneral-summary-dict-items">навык выявления и связывания образов, способность формирования
					новых знаний, в том числе способность формулировать идеи, понятия, гипотезы об объектах и явлениях, в том
					числе ранее не известных, осознавать дефициты собственных знаний и компетентностей, планировать свое развитие;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение распознавать конкретные примеры понятия по характерным
					признакам, выполнять операции в соответствии с определением и простейшими свойствами понятия, конкретизировать
					понятие примерами, использовать понятие и его свойства при решении задач (далее - оперировать понятиями), а
					также оперировать терминами и представлениями в области концепции устойчивого развития;</li>
				<li class="AdditionalGneral-summary-dict-items">умение анализировать и выявлять взаимосвязи природы, общества и
					экономики;</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать свои действия с учетом влияния на окружающую
					среду, достижений целей и преодоления вызовов, возможных глобальных последствий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение познавательными, регулятивными и коммуникативными
					универсальными действиями.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Ожидаемые результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта применения физических, химических,
					биологических методов исследования объектов и явлений природы;</li>
				<li class="AdditionalGneral-summary-dict-items">знание технологии решения творческих задач с помощью
					моделирования, прототипирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знакомство с основами 3D-моделирования, робототехники;</li>
				<li class="AdditionalGneral-summary-dict-items">достижение высокого уровня учебной мотивации в изучении
					предметов физико-математического и естественно-научного циклов, информационных технологий, конструирования и
					проектирования с выходом на научно-исследовательскую и научно-практическую составляющую;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ключевых компетенций (робототехника,
					3D-моделирование, прототипирование), необходимых для дальнейшего образования.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%97%D0%B8%D0%BC%D0%BD%D1%8F%D1%8F-%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B0%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Инженеры будущего
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание формообразование материаловедения, образное,
					пространственное мышление и умение выразить свою мысль с помощью чертежа, рисунка;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки учебно-исследовательской работы, создавать чертежи и
					шаблоны моделей;</li>
				<li class="AdditionalGneral-summary-dict-items">знание истории в области инженерного изобретательства.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение сотрудничать, выполняя различные роли в группе, в
					совместном решении проблемы (задачи);</li>
				<li class="AdditionalGneral-summary-dict-items">умения применять знания об окружающем мире из таких учебных
					предметов (окружающий мир, технология, литература и другие);</li>
				<li class="AdditionalGneral-summary-dict-items">развивать мелкую моторику, пластичность, гибкость рук и точность
					глазомера;</li>
				<li class="AdditionalGneral-summary-dict-items">развить мотивацию к овладению техническими процессами
					изготовления моделей любой сложности;</li>
				<li class="AdditionalGneral-summary-dict-items">реализовать технические и творческие способности воспитанников;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развить самостоятельность, ответственность, активность,
					аккуратность, трудолюбие, умение работать в группе в процессе выполнения технической работы;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать потребность в саморазвитии.</li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в своей системе знаний: отличать новое
					знание от известного;</li>
				<li class="AdditionalGneral-summary-dict-items">умение излагать мысли в четкой логической последовательности,
					отстаивать свою точку зрения, анализировать ситуацию и самостоятельно находить ответы на вопросы путем
					логических рассуждений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать в команде, эффективно распределять обязанности
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">мотивация к изобретательству, развитие научной, познавательной и
					творческой активности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интереса к инновационным проектам и изобретательству;
				</li>
				<li class="AdditionalGneral-summary-dict-items">выявление рационализаторских и конструкторских решений;</li>
				<li class="AdditionalGneral-summary-dict-items">выявление и поддержка талантливой молодёжи;</li>
				<li class="AdditionalGneral-summary-dict-items">расширение коммуникативного пространства на основе активизации
					интереса к технической и интеллектуально-творческой деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение статуса, общественной значимости и привлекательности
					деятельности в сфере производства, техники и технологий, социально значимой творческой деятельности
					обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие социально-профессиональной и предметно-профессиональной
					компетентности педагогов и расширение сферы профессионального общения;</li>
				<li class="AdditionalGneral-summary-dict-items">создание условий для публичного представления учащимися
					результатов интеллектуального и технического творчества, изобретательства; </li>
				<li class="AdditionalGneral-summary-dict-items">поиск новых идей, фиксация новых тенденций в развитии
					интеллектуального, технического творчества и изобретательства;</li>
				<li class="AdditionalGneral-summary-dict-items">поддержка в оформлении прав на результаты интеллектуальной
					деятельности авторов перспективных проектов.</li>
				<li class="AdditionalGneral-summary-dict-items">Воспитательные результаты:</li>
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде;</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение,</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью,</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию и
					самообразованию;</li>
				<li class="AdditionalGneral-summary-dict-items">содействие учащимся в построении индивидуальной образовательной
					траектории «школа — вуз — профессия».</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D0%BD%D0%B6%D0%B5%D0%BD%D0%B5%D1%80%D1%8B-%D0%B1%D1%83%D0%B4%D1%83%D1%89%D0%B5%D0%B3%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир в объективе
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">к освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам и программам ДШИ и ДХШ, в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над фотопроектами;
				</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к объектам
					фотосъёмки;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к фотосъёмке;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы проведения фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование первоначальных представлений о системе
					фотографических жанров, формирование навыка составления фотокомпозиции;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков обработки фотографий в графических
					редакторах;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков систематизации и размещения в сети Интернет
					графических файлов;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов; самостоятельно
					реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе предварительного
					планирования.</li>
			</ul>


			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B8%D1%80-%D0%B2-%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D0%B8%D0%B2%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Олимпиадная математика
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильные смены, проводимые по двум модулям, каждый из
				которых объемом по 35 часов</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">70 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 14 до17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к учению, готовность и способность
					обучающихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию;</li>
				<li class="AdditionalGneral-summary-dict-items">осознанный выбор и построение дальнейшей индивидуальной
					траектории образования на базе ориентировки в мире профессий и профессиональных предпочтений с учётом
					устойчивых познавательных интересов, а также на основе формирования уважительного отношения к труду, развитие
					опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">критичность мышления, инициатива, находчивость, активность при
					решении математических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе, развивать мотивы и интересы своей познавательной деятельности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять контроль своей деятельности в процессе достижения
					результата, определять способы действий в рамках предложенных условий и требований, корректировать свои
					действия в соответствии с изменяющейся ситуацией;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие компетентности в области использования информационно -
					коммуникационных технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">первоначальные представления об идеях и о методах математики как
					об универсальном языке науки и техники, о средстве моделирования явлений и процессов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение видеть математическую задачу в контексте проблемной
					ситуации в других дисциплинах, в окружающей жизни;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">представление о математической науке как сфере математической
					деятельности, об этапах её развития, о её значимости для развития цивилизации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять понятия, создавать обобщения, устанавливать
					связи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение устанавливать причинно-следственные связи, строить
					логическое рассуждение, умозаключение (индуктивное, дедуктивное и по аналогии) и делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение находить в различных источниках информацию, необходимую
					для решения математических проблем, и представлять её в понятной форме, принимать решение в условиях неполной
					или избыточной, точной или вероятностной информации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение выдвигать гипотезы при решении задачи, понимать
					необходимость их проверки.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F-%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Олимпиадное право
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">76 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам 7-10 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие правовой грамотности учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование общего представления об основах юридического знания
					у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование навыков познавательной и
					учебно-исследовательской деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение и понимание основных понятий и концепций юридической
					теории;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление знаний в сфере права и способность к их применению на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие основ логического мышления для формирования навыка
					юридического мышления.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%BD%D0%BE%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%BE-1.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Осенняя интеллектуальная общеразвивающая школа
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Повышение общего культурного уровня обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие интереса к изучению исторического прошлого;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие интеллектуальных и практических умений по
					самостоятельному приобретению и применению на практике полученные знания по истории и праву.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Формирование культуры работы с историческими источниками и
					литературой, выступлений на семинарах, ведения дискуссий;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие интерес к изучению исторического прошлого;</li>
				<li class="AdditionalGneral-summary-dict-items">Формирование общего представления об историческом прошлом;</li>
				<li class="AdditionalGneral-summary-dict-items">Владение и понимание основных понятий и концепций юридической
					теории;</li>
				<li class="AdditionalGneral-summary-dict-items">Углубление знаний в сфере права и способность к их применению на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие основ логического мышления для формирования навыка
					юридического мышления.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">Развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D1%81%D0%B5%D0%BD%D0%BD%D1%8F%D1%8F-%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B0%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВСОШ по астрономии
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных
				организаций в возрасте от 12 до17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к учению, готовность и способность
					обучающихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию;</li>
				<li class="AdditionalGneral-summary-dict-items">осознанный выбор и построение дальнейшей индивидуальной
					траектории образования на базе ориентировки в мире профессий и профессиональных предпочтений с учётом
					устойчивых познавательных интересов, а также на основе формирования уважительного отношения к труду, развитие
					опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">критичность мышления, инициатива, находчивость, активность при
					решении математических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе, развивать мотивы и интересы своей познавательной деятельности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять контроль своей деятельности в процессе достижения
					результата, определять способы действий в рамках предложенных условий и требований, корректировать свои
					действия в соответствии с изменяющейся ситуацией;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие компетентности в области использования информационно -
					коммуникационных технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">первоначальные представления об идеях и о методах математики как
					об универсальном языке науки и техники, о средстве моделирования явлений и процессов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение видеть математическую задачу в контексте проблемной
					ситуации в других дисциплинах, в окружающей жизни;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">представление о астрономии как науке, об этапах её развития, о
					её значимости для развития цивилизации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять понятия, создавать обобщения, устанавливать
					связи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение устанавливать причинно-следственные связи, строить
					логическое рассуждение, умозаключение (индуктивное, дедуктивное и по аналогии) и делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение находить в различных источниках информацию, необходимую
					для решения математических проблем, и представлять её в понятной форме, принимать решение в условиях неполной
					или избыточной, точной или вероятностной информации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение выдвигать гипотезы при решении задачи, понимать
					необходимость их проверки.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D0%A1%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Проектная деятельность
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Учащиеся общеобразовательных организаций 7-11 классов, проявляющие
				интерес к научно-исследовательской деятельности.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные;</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков коммуникативной,
					учебно-исследовательской деятельности, критического мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к инновационной, аналитической, творческой,
					интеллектуальной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков проектной деятельности, а также
					самостоятельного применения приобретенных знаний и способов действий при решении различных задач, используя
					знания нескольких учебных предметов и/или предметных областей;</li>
				<li class="AdditionalGneral-summary-dict-items">способность постановки цели и формулирования гипотезы
					исследования, планирования работы, отбора и интерпретации необходимой информации, структурирования и
					аргументации результатов исследования на основе собранных данных, презентация результатов;</li>
				<li class="AdditionalGneral-summary-dict-items">владение умением излагать результаты проектной работы на
					вебинарах, семинарах, конференциях и т.п.;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность понятий проект, проектирование;</li>
				<li class="AdditionalGneral-summary-dict-items">владение знанием этапов проектной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение методами поиска и анализа научной информации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели деятельности и составлять
					планы деятельности; самостоятельно осуществлять, контролировать и корректировать деятельность; использовать
					все возможные ресурсы для достижения поставленных целей и реализации планов деятельности; выбирать успешные
					стратегии в различных ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">умение продуктивно общаться и взаимодействовать в процессе
					совместной деятельности, учитывать позиции других участников деятельности, эффективно разрешать конфликты;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной, учебно-исследовательской и
					проектной деятельности, навыками разрешения проблем; способность и готовность к самостоятельному поиску
					методов решения практических задач, применению различных методов познания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной
					информационно-познавательной деятельности, включая умение ориентироваться в различных источниках информации,
					критически оценивать и интерпретировать информацию, получаемую из различных источников;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать средства информационных и коммуникационных
					технологий (далее - ИКТ) в решении когнитивных, коммуникативных и организационных задач с соблюдением
					требований эргономики, техники безопасности, гигиены, ресурсосбережения, правовых и этических норм, норм
					информационной безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять назначение и функции различных социальных
					институтов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать и принимать решения,
					определяющие стратегию поведения, с учётом гражданских и нравственных ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами - умение ясно, логично и точно
					излагать свою точку зрения, использовать адекватные языковые средства;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной рефлексии как осознания
					совершаемых действий и мыслительных процессов, их результатов и оснований, границ своего знания и незнания,
					новых познавательных задач и средств их достижения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уважение к своему народу, гордости за свой край, свою Родину,
					уважение государственных символов (герб, флаг, гимн);</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность мировоззрения, соответствующего современному
					уровню развития науки и общественной практики, основанного на диалоге культур, а также различных форм
					общественного сознания, осознание своего места в поликультурном мире;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность основ саморазвития и самовоспитания в
					соответствии с общечеловеческими ценностями и идеалами гражданского общества; готовность и способность к
					самостоятельной, творческой и ответственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">толерантное сознание и поведение в поликультурном мире,
					готовность и способность вести диалог с другими людьми, достигать в нём взаимопонимания, находить общие цели и
					сотрудничать для их достижения;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки сотрудничества со сверстниками, детьми младшего возраста,
					взрослыми в образовательной, общественно полезной, учебно-исследовательской, проектной и других видах
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к образованию, в том числе
					самообразованию, на протяжении всей жизни; сознательное отношение к непрерывному образованию как условию
					успешной профессиональной и общественной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетическое отношение к миру, включая эстетику быта, научного и
					технического творчества, спорта, общественных отношений;</li>
				<li class="AdditionalGneral-summary-dict-items">принятие и реализация ценностей здорового и безопасного образа
					жизни, потребности в физическом самосовершенствовании, занятиях спортивно-оздоровительной деятельностью,
					неприятие вредных привычек: курения, употребления алкоголя, наркотиков;</li>
				<li class="AdditionalGneral-summary-dict-items">бережное, ответственное и компетентное отношение к физическому и
					психологическому здоровью, как собственному, так и других людей, умение оказывать первую помощь;</li>
				<li class="AdditionalGneral-summary-dict-items">1осознанный выбор будущей профессии и возможностей реализации
					собственных жизненных планов; отношение к профессиональной деятельности как возможности участия в решении
					личных, общественных проблем;</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D0%B0%D1%8F-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Рачительный хозяин земли
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание о месте сельского хозяйства в экономике страны и его
					связях с другими отраслями;</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к законам рыночного производства, основным элементы
					рыночного механизма,</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к экономическим знаниям;</li>
				<li class="AdditionalGneral-summary-dict-items">осознание социальной значимости начисления уплаты налогов и
					фиксированных страховых взносов;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к самопознанию, самооценке и самоорганизации;</li>
				<li class="AdditionalGneral-summary-dict-items">уважение к другому человеку, его мнению.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно ставить новые задачи на основе развития
					познавательных мотивов и интересов.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные.</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">усвоить первичные знания по организации и выполнению работ по
					производству, первичной обработке, хранению и транспортировке сельскохозяйственной продукции;</li>
				<li class="AdditionalGneral-summary-dict-items">знать сельскохозяйственные культуры (сорта и гибриды), - семена
					и посадочный материал;</li>
				<li class="AdditionalGneral-summary-dict-items">иметь представление о почве и ее плодородии; </li>
				<li class="AdditionalGneral-summary-dict-items">иметь представление о удобрениях, пестицидах;
					сельскохозяйственной технике и оборудовании;</li>
				<li class="AdditionalGneral-summary-dict-items">сформировать природоохранные, природосберегающие,
					природовосстановительные навыки ведения сельскохозяйственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">освоить основы исследовательской деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A0%D0%B0%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%85%D0%BE%D0%B7%D1%8F%D0%B8%D0%BD-%D0%B7%D0%B5%D0%BC%D0%BB%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Страна железных дорог
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 18 лет (учащиеся 9-11 классов), проявляющие интерес к техническим и
				естественнонаучным дисциплинам, связанным с системами связи, робототехникой, геоинформатикой и экологией.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание значимости железнодорожной отрасли;</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к деятельности железных дорог и отдельным видам
					профессиональной деятельности, реализуемым в ОАО «РЖД»;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание необходимости бережного и уважительного отношения к
					окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в выполнении учебных заданий, поручений,
					договоренностей;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение профориентационных знаний, которые по окончанию
					школы способствуют в выборе будущей профессии.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">теоретические и практические основы робототехники и мехатроники;
				</li>
				<li class="AdditionalGneral-summary-dict-items">основы создания технических систем на базе робототехнических
					конструкторов на платформе Arduino;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы описания алгоритмов управляющих программ роботов;</li>
				<li class="AdditionalGneral-summary-dict-items">физические основы функционирования элементов роботов
					(контроллеров, приводов, датчиков), их характеристики;</li>
				<li class="AdditionalGneral-summary-dict-items">основы создания 3D-моделей и их изготовление аддитивными
					методами;</li>
				<li class="AdditionalGneral-summary-dict-items">основы сборки электрических схем на монтажных платах;</li>
				<li class="AdditionalGneral-summary-dict-items">основы создания управляющих программ в среде Arduino IDE, их
					тестирование и отладка;</li>
				<li class="AdditionalGneral-summary-dict-items">процессы, проходящие в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">методы экологического мониторинга;</li>
				<li class="AdditionalGneral-summary-dict-items">разрешенные показатели выбросов промышленных предприятий,
					методику их контроля;</li>
				<li class="AdditionalGneral-summary-dict-items">основные понятия в области экономики, менеджмента,
					информационных технологий, характеризующие цифровую экономику;</li>
				<li class="AdditionalGneral-summary-dict-items">методы проектирования веб-приложений;</li>
				<li class="AdditionalGneral-summary-dict-items">основные принципы построения сетей;</li>
				<li class="AdditionalGneral-summary-dict-items">методы сбора информации для проекта;</li>
				<li class="AdditionalGneral-summary-dict-items">основы искусственного интеллекта;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы построения алгоритмов;</li>
				<li class="AdditionalGneral-summary-dict-items">основы криптографии;</li>
				<li class="AdditionalGneral-summary-dict-items">способы обработки больших данных;</li>
				<li class="AdditionalGneral-summary-dict-items">основные понятия цифровой экономики;</li>
				<li class="AdditionalGneral-summary-dict-items">основы организации и реализации стартапов;</li>
				<li class="AdditionalGneral-summary-dict-items">тренды на рынке труда с учетом цифровой трансформации;</li>
				<li class="AdditionalGneral-summary-dict-items">направления цифровизации на железнодорожном транспорте.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">учащиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">работать со статической и динамической навигационной
					информацией;</li>
				<li class="AdditionalGneral-summary-dict-items">производить сборку типовых конструкций роботов и адаптировать их
					для решения частных задач;</li>
				<li class="AdditionalGneral-summary-dict-items">разрабатывать и описывать алгоритмы функционирования роботов в
					соответствии с нормами Единой системы программной документации (ГОСТ 19.701-90);</li>
				<li class="AdditionalGneral-summary-dict-items">объяснить основы функционирования и принципы действия основных
					элементов роботов (контроллеров, приводов, датчиков);</li>
				<li class="AdditionalGneral-summary-dict-items">производит монтаж электрических схем на макетных платах;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать управляющие программы в среде программирования Arduino
					IDE;</li>
				<li class="AdditionalGneral-summary-dict-items">подготовить радиоуправляемую робототехническую платформу в
					рамках образовательной смены;</li>
				<li class="AdditionalGneral-summary-dict-items">отбирать пробы, готовить аналитические растворы, проводить их
					исследование;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться измерительными приборами для определения
					концентрации вредных веществ в различных природных средах;</li>
				<li class="AdditionalGneral-summary-dict-items">исследовать содержание загрязняющих веществ в воздухе;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать анионные поверхностно-активные вещества сульфатов
					и хлоридов при помощи тест-систем МЭТ и «Кристмас+»;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять анализ проб воды на нефтепродукты при помощи тест
					систем «Кристмас+»;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять отбор проб снеговой массы;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять анализ проб почв на содержание нефтепродуктов;</li>
				<li class="AdditionalGneral-summary-dict-items">проектировать простейшие экспертные системы;</li>
				<li class="AdditionalGneral-summary-dict-items">строить и настраивать компьютерные сети;</li>
				<li class="AdditionalGneral-summary-dict-items">программировать на языке высокого уровня;</li>
				<li class="AdditionalGneral-summary-dict-items">строить многомерные таблицы по типу OLAB-куб;</li>
				<li class="AdditionalGneral-summary-dict-items">разрабатывать чат-бот;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать структуру стартапов;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать финансово-экономическую модель проекта.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование культуры командной работы и коллективной проектной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков трудового творческого сотрудничества.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D1%82%D1%80%D0%B0%D0%BD%D0%B0-%D0%B6%D0%B5%D0%BB%D0%B5%D0%B7%D0%BD%D1%8B%D1%85-%D0%B4%D0%BE%D1%80%D0%BE%D0%B3.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Физика полета
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основ авиамоделирования, основными понятиями и базовыми
					элементами моделирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знание формообразование материаловедения, образное,
					пространственное мышление и умение выразить свою мысль с помощью чертежа, рисунка, авиамодели;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки работы нужными инструментами и приспособлениями при
					обработке различных материалов;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки учебно-исследовательской работы, создавать чертежи и
					шаблоны моделей.</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил техники безопасности при управлении БЛА,</li>
				<li class="AdditionalGneral-summary-dict-items">знание истории, сфер применения БЛА</li>
				<li class="AdditionalGneral-summary-dict-items">знание устройства БЛА, основных элементов, материалов, из
					которых они изготавливаются,</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ аэродинамики,</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками настройки БЛА,</li>
				<li class="AdditionalGneral-summary-dict-items">владение техниками управления БЛА от «третьего лица», в режиме
					FPV,</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил авиамодельного спорта в классе F3U, их
					практическое применение,</li>
				<li class="AdditionalGneral-summary-dict-items">спортивные навыки, спортивная техника в авиамодельном спорте в
					классе F3U.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение сотрудничать, выполняя различные роли в группе, в
					совместном решении проблемы (задачи);</li>
				<li class="AdditionalGneral-summary-dict-items">умения применять знания об окружающем мире из таких учебных
					предметов (окружающий мир, технология, литература и другие) для мотивации в работе по авиамоделированию;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать мелкую моторику, пластичность, гибкость рук и точность
					глазомера;</li>
				<li class="AdditionalGneral-summary-dict-items">развить мотивацию к овладению техническими процессами
					изготовления моделей любой сложности;</li>
				<li class="AdditionalGneral-summary-dict-items">реализовать технические и творческие способности воспитанников;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развить самостоятельность, ответственность, активность,
					аккуратность, трудолюбие, умение работать в группе в процессе выполнения технической работы;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать потребность в саморазвитии.</li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в своей системе знаний: отличать новое
					знание от известного;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать по предложенным инструкциям и самостоятельно;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение излагать мысли в четкой логической последовательности,
					отстаивать свою точку зрения, анализировать ситуацию и самостоятельно находить ответы на вопросы путем
					логических рассуждений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать в команде, эффективно распределять обязанности
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимание особой роли технического развития в жизни общества и
					каждого отдельного человека;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетические чувства, техническо-творческого мышления,
					наблюдательности и фантазии, потребностей в творческом отношении к окружающему миру, в самостоятельной
					практической технической деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки коллективной деятельности в процессе совместной
					технической работы в команде одноклассников под руководством педагога;</li>
				<li class="AdditionalGneral-summary-dict-items">умение обсуждать и анализировать собственную техническую
					деятельность и работу других учащихся с позиций задач данной темы, с точки зрения содержания и средств его
					выражения.</li>
				<li class="AdditionalGneral-summary-dict-items">потребность в технической самореализации в социально- полезной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">патриотические чувства и качества;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки здорового образа жизни.</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к технике и технологиям, более развитые
					технические способности,</li>
				<li class="AdditionalGneral-summary-dict-items">познавательная активность и способность к самообразованию,</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к профессиональной сфере применения БЛА,
					профессии оператора БЛА, профориентация на специальности, связанные с применением БЛА.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Воспитательные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде,
					спортивная этика,</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение,</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью,</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию и
					самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-%D0%BF%D0%BE%D0%BB%D0%B5%D1%82%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Шаг в мир науки
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сравнение разных приемов действий, выбор удобных способов для
					выполнения конкретного задания;</li>
				<li class="AdditionalGneral-summary-dict-items">моделирование алгоритма решения задачи в процессе совместного
					обсуждения;</li>
				<li class="AdditionalGneral-summary-dict-items">применение изученных способов учебной работы и приёмов
					вычислений для работы с нестандартными заданиями;</li>
				<li class="AdditionalGneral-summary-dict-items">действие в соответствии с заданными правилами;</li>
				<li class="AdditionalGneral-summary-dict-items">участие в обсуждении проблемных вопросов, высказывание
					собственного мнения и последующая аргументация;</li>
				<li class="AdditionalGneral-summary-dict-items">контролирование своей деятельности: обнаружение и исправление
					ошибок.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Приобщение учащихся к информационной культуре, раскрытие
					творческих способностей детей;</li>
				<li class="AdditionalGneral-summary-dict-items">Приобретение начальной профессиональной подготовки по данному
					направлению, что поспособствует повышению социальной адаптации учащихся после окончания школы.</li>
				<li class="AdditionalGneral-summary-dict-items">Совершенствование навыков работы с компьютером и умение
					применять изученные алгоритмы для решения актуальных задач;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование механизмов мышления, характерных для деятельности в
					области физики, астрономии и информатике.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A8%D0%B0%D0%B3-%D0%B2-%D0%BC%D0%B8%D1%80-%D0%BD%D0%B0%D1%83%D0%BA%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Школа безопасности. Основы безопасности жизнедеятельности
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">60 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<p class="AdditionalGneral-summary-dict">Регулятивные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать своё продвижение в овладении содержанием курса;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять ошибки свои и партнёра.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Познавательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде речи, текста, схем,
					таблиц, видео;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, характерные ошибки других участников,
					делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Коммуникативные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать и пользоваться командами, речевыми сочетаниями между
					участниками при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку при работе в команде.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D0%B0%D0%BC%D0%BC%D0%BC%D0%B0-%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0-%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.-%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D1%8B-%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Школа казачьей культуры
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся кадетских классов казачьей
				направленности по общеобразовательным программам в возрасте от 10 до 15 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">внутренняя позиция на уровне положительного отношения к истории
					Родины;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование основ российской идентичности, чувства гордости за
					свою страну;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие самостоятельности и личной ответственности за свои
					поступки;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие эстетических чувств;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание доброжелательности и эмоционально-нравственной
					отзывчивости;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков сотрудничества с взрослыми и сверстниками в
					разных социальных ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">наличие мотивации к труду, работе на результат, бережному
					отношению к себе, а также материальным и духовным ценностям.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование умений планировать, контролировать и оценивать
					учебные действия;</li>
				<li class="AdditionalGneral-summary-dict-items">использование речевых средств и средств
					информационно-коммуникативных технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять информационный поиск;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками смыслового чтения различных стилей и жанров.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание, понимание и принятие ценностей: Отечество, казачество,
					долг, культурное наследие, нравственность, долг, милосердие, миролюбие, вера;</li>
				<li class="AdditionalGneral-summary-dict-items">знакомство с историей и современностью Российского казачества;
				</li>
				<li class="AdditionalGneral-summary-dict-items">осознание ценности нравственности и духовности человеческой
					жизни.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0-%D0%BA%D0%B0%D0%B7%D0%B0%D1%87%D1%8C%D0%B5%D0%B9-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<!-- Спорт -->
		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Дзюдо
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 10 до 17 лет, прошедшие отбор.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>
			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знания, умения и навыки по виду спорта «Дзюдо»;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование общих и специальных физических качеств,
					технической, тактической и психологической подготовки;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками выполнения техники бросков через спину с
					разных захватов и с разных направлений;</li>
				<li class="AdditionalGneral-summary-dict-items">знание особенностей функционирования организма;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ личной гигиены;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ здорового образа жизни и закаливания организма;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать свой технический арсенал для успешного
					выполнения поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества;</li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности.</li>
			</ul>

			<p class="AdditionalGneral-summary-sub-title">Метапредметные результаты:</p>
			<p class="AdditionalGneral-summary-dict">Регулятивные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать своё продвижение в овладении содержанием курса;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять свои ошибки.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Познавательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде текста, рисунков,
					схем;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Коммуникативные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">участвовать в диалоге при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Егорова Юлия Сергеевна</p>
			<p class="AdditionalGneral-summary-sub-title">yu.egorova@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D0%B0%D0%BC%D0%BC%D0%B0-%D0%94%D0%B7%D1%8E%D0%B4%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Развитие физических способностей при подготовке к ГТО
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">64 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 10 до 17 лет, проживающие на территории Иркутской области, интересующиеся общей
				физической подготовкой. Отбор проводится среди обучающиеся общеобразовательных организаций Иркутской области, у
				которых отсутствуют результаты сдачи норм ВФСК ГТО.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать занятия физическими упражнениями в режиме
					дня, организовывать отдых и досуг с использованием средств физической культуры;</li>
				<li class="AdditionalGneral-summary-dict-items">умение измерять (познавать) индивидуальные показатели
					физического развития (длину и массу тела), развития основных физических качеств;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объяснять правила (технику) выполнения двигательных
					действий, анализировать и находить ошибки, эффективно их исправлять.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие у детей коммуникабельности в общение со сверстниками на
					принципах взаимоуважения и доброжелательности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение управлять своими эмоциями в различных (нестандартных)
					ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">проявлять дисциплинированность, трудолюбие и упорство в
					достижении поставленных целей;</li>
				<li class="AdditionalGneral-summary-dict-items">подготовленность к сдаче нормативов комплекса ГТО.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Регулятивные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать своё продвижение в овладении содержанием курса;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять ошибки.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Познавательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде речи, текста, схем,
					таблиц, видео;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, характерные ошибки других участников,
					делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Коммуникативные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать и пользоваться командами при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку при работе в команде.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Егорова Юлия Сергеевна</p>
			<p class="AdditionalGneral-summary-sub-title">yu.egorova@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A0%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D0%B5-%D1%84%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85-%D1%81%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D1%82%D0%B5%D0%B9-%D0%BF%D1%80%D0%B8-%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B5-%D0%BA-%D0%93%D0%A2%D0%9E.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Самбо
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 10 до 17 лет, проживающие на территории Иркутской области, прошедшие отбор и попавшие в
				состав сборной команды от муниципальных образований, Иркутской области по виду спорта «Самбо».</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знания, умения и навыки по виду спорта самбо;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование общих и специальных физических качеств,
					технической, тактической и психологической подготовки;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками выполнения техники бросков с разных захватов
					и с разных направлений;</li>
				<li class="AdditionalGneral-summary-dict-items">знание особенностей функционирования организма;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ личной гигиены;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ здорового образа жизни и закаливания организма;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать свой технический арсенал для успешного
					выполнения поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества;</li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Регулятивные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать своё продвижение в овладении содержанием курса;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять свои ошибки.</li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Познавательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде текста, рисунков,
					схем;</li>
				<li class="AdditionalGneral-summary-dict-items">умение анализировать свои ошибки, делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Коммуникативные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">участвовать в диалоге при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Егорова Юлия Сергеевна</p>
			<p class="AdditionalGneral-summary-sub-title">yu.egorova@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%B0%D0%BC%D0%B1%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Спортивный туризм
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет, проживающие на территории Иркутской области, прошедшие отбор и попавшие в
				расширенный состав сборной команды Иркутской области по виду спорта «Спортивный туризм» в дисциплине «Дистанции
				пешеходные».</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знания, умения и навыки по дисциплине «дистанции пешеходные»;
				</li>
				<li class="AdditionalGneral-summary-dict-items">знания, умения и навыки по спортивному ориентированию;</li>
				<li class="AdditionalGneral-summary-dict-items">умения и навыки обеспечения безопасности на занятиях;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками работы с различным специальным оборудованием;
				</li>
				<li class="AdditionalGneral-summary-dict-items">знание особенностей оборудования учебных и соревновательных
					дистанций в полевых условиях и самообслуживания;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ личной гигиены юного спортсмена;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ здорового образа жизни и закаливания организма;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать свой технический арсенал для успешного
					выполнения поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества;</li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение бережно относиться к людям и предметам их труда.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Регулятивные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать своё продвижение в овладении содержанием курса;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять ошибки свои и партнёра по связке, группе.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Познавательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде речи, текста, схем,
					таблиц, видео;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, характерные ошибки других участников,
					делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Коммуникативные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать и пользоваться командами, речевыми сочетаниями между
					участниками на дистанции связка и дистанции группа при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку при работе в команде.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Егорова Юлия Сергеевна</p>
			<p class="AdditionalGneral-summary-sub-title">yu.egorova@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%BF%D0%BE%D1%80%D1%82%D0%B8%D0%B2%D0%BD%D1%8B%D0%B9-%D1%82%D1%83%D1%80%D0%B8%D0%B7%D0%BC.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>
	</div>
</template>