<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Сириус. Лето: начни свой проект»
		</h2>
		<p class="EventsPage-date">
			с 7 по 20 февраля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24500-programma-sirius-leto-nachni-svoi-proekt" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Сириус. Лето: начни свой проект» для учащихся 7-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа направлена на получение обучающимися знаний, необходимых им для осуществления проектной деятельности в области научных технологий. Для реализации программы привлекаются ведущие преподаватели вузов и лучшие педагоги образовательных организаций области, действующие предприниматели, специалисты организаций, составляющих инфраструктуру поддержки предпринимательства, проявившими интерес к работе с обучающимися в рамках проектно-исследовательской и научной деятельности.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<p class="EventsPage-text">
				Для участников смены будет проведено организационное собрание 1 февраля 2024 г. в 20.00, ссылка на подключение <a style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1706081331">https://pruffme.com/landing/Perseus/tmp1706081331</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://drive.google.com/drive/folders/1XDHk9FVmGiLWAroj5VaNbjssXweAS2cC?usp=sharing" target="_blank">
			Скачать
		</a>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/nt-OIrTppMEEjw" target="_blank">
			Сертификаты
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>