<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Чемпионат
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			VI Региональный чемпионат корпораций ЮниорПрофи Иркутской области
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 4 по 7 мая 2022 года, очно, в кампусе ОЦ «Персей» по адресу: Иркутская область, Ангарский район, 8.351 км автодороги Ангарск-Тальяны.
		</h4>
		<hr>

		<p class="JuniorProfi-sub-title">
			Задачи Чемпионата:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				привлечение внимания обучающихся и их родителей к выбору инженерно-технических профессий, к ранней профессиональной подготовке как перспективному направлению профессионального самоопределения и карьерного развития
			</li>
			<li class="JuniorProfi-items">
				привлечение внимания высокотехнологичных предприятий, образовательных организаций высшего и среднего профессионального образования, других организаций к деятельности образовательных организаций общего и дополнительного образования как субъектов системы подготовки кадрового резерва для промышленности
			</li>
			<li class="JuniorProfi-items">
				развитие у обучающихся навыков практического решения задач в конкретных производственных ситуациях и навыков работы с техническими устройствами
			</li>
			<li class="JuniorProfi-items">
				совершенствование навыков самостоятельной работы, развитие профессионального мышления и повышение ответственности обучающихся за выполняемую работу
			</li>
			<li class="JuniorProfi-items">
				формирование готовности школьников к целенаправленной деятельности по созданию общественно полезного продукта
			</li>
			<li class="JuniorProfi-items">
				создание системы наращивания профессионализма педагогов по реализации программ технической и технологической направленности в организациях основного и дополнительного образования детей
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Участники:
		</p>
		<p class="EventsPage-text">
			К участию приглашаются обучающиеся школ и организаций ДПО возрастов 10+ (10-13 лет) и 14+ (14-17 лет) в составе команды из 2-х человек по выбранной компетенции, под руководством педагога-наставника.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%9F%D1%80%D0%B8%D0%BB.-%D0%BA-%D1%80%D0%B0%D1%81%D0%BF.-%D0%BE%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85.pdf">
			Прил. к расп. об итогах
		</a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/05/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9C%D0%9E-%D0%B8%D1%82%D0%BE%D0%B3%D0%B8-%D0%A7%D0%B5%D0%BC%D0%BF-%D0%BA%D0%BE%D1%80%D0%BF-%D1%81%D1%82%D1%80-1.pdf">
			Распоряжение МО итоги Чемп корп стр 1
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			О чемпионате:
		</p>
		<p class="EventsPage-text">
			Чемпионат корпораций проводится как конкурсное мероприятие между 8 корпорациями.В состав каждой корпорации входят 12 участников по компетенциям:
		</p>
		<p class="EventsPage-text">
			ПРОТОТИПИРОВАНИЕ 14+
		</p>
		<p class="EventsPage-text">
			ИНЖЕНЕРНЫЙ ДИЗАЙН 14+
		</p>
		<p class="EventsPage-text">
			СИТИ-ФЕРМЕРСТВО 14+
		</p>
		<p class="EventsPage-text">
			МОБИЛЬНАЯ РОБОТОТЕХНИКА 10+
		</p>
		<p class="EventsPage-text">
			ЭЛЕКТРОНИКА 14+
		</p>
		<p class="EventsPage-text">
			МУЛЬТИМЕДИАКОММУНИКАЦИИ 14+
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			О заезде:
		</p>
		<p class="EventsPage-text">
			До места проведения Чемпионата участники добираются самостоятельно (транспорт не предусмотрен).
		</p>
		<p class="EventsPage-text">
			Для обучающихся проживание и питание во время Чемпионата осуществляется за счет бюджетных средств (бесплатно), с экспертов-наставников предусмотрен организационный взнос в размере 3000 рублей.
		</p>
		<p class="EventsPage-text">
			При заезде обучающийся должен предоставить следующие документы:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Договор об организации отдыха детей и молодежи (будет отправлен на почту участников).
			</li>
			<li class="JuniorProfi-items">
				копия документа, удостоверяющего личность Ребенка (св-во о рождении/ паспорт);
			</li>
			<li class="JuniorProfi-items">
				копия СНИЛС Ребенка;
			</li>
			<li class="JuniorProfi-items">
				копия полиса обязательного медицинского страхования Ребенка (с 2-х сторон);
			</li>
			<li class="JuniorProfi-items">
				медицинская справка о состоянии здоровья Ребенка формы 079/у;
			</li>
			<li class="JuniorProfi-items">
				справка об отсутствии контакта с инфекционными больными;
			</li>
			<li class="JuniorProfi-items">
				страховой полис на случай укуса клеща или сертификат о прививке от клещевого энцефалита (на период апрель-сентябрь);
			</li>
			<li class="JuniorProfi-items">
				страховой полис от несчастного случая;
			</li>
			<li class="JuniorProfi-items">
				заявление о зачислении (форма будет отправлена на почту участников).
			</li>
			<li class="JuniorProfi-items">
				информированное добровольное согласие на медицинское вмешательство;
			</li>
			<li class="JuniorProfi-items">
				согласие на обработку персональных данных.
			</li>
		</ul>
		<hr>

		<table>
			<caption >
				Подача заявки на участие:
			</caption>
			<tbody align="center">
				<tr>
					<td>
						до 5 апреля
					</td>
					<td>
						Педагог-наставник
					</td>
					<td>
						Заявка на обязательное онлайн-обучение педагогов 9 апреля.
					</td>
					<td>
						<a class="EventsPage-link" href="http://konkurs.ric38.ru">
							http://konkurs.ric38.ru
						</a>
					</td>
				</tr>
				<tr>
					<td>
						до 14 апреля
					</td>
					<td>
						Педагог-наставник
					</td>
					<td>
						Командная заявка
					</td>
					<td>
						<a class="EventsPage-link" href="http://konkurs.ric38.ru">
							http://konkurs.ric38.ru
						</a>
					</td>
				</tr>
				<tr>
					<td>
						до 14 апреля
					</td>
					<td>
						Законный представитель участника
					</td>
					<td>
						Персональная заявка участника
					</td>
					<td>
						<a class="EventsPage-link" href="р38.навигатор.дети">
							р38.навигатор.дети
						</a>
					</td>
				</tr>
			</tbody>
		</table>
		<hr>

		<p class="JuniorProfi-sub-title">
			Старшие региональные эксперты компетенций:
		</p>
		<p class="EventsPage-text">
			Сити-фермерство. <b>Пальчик Анатолий Павлович</b>, заместитель директора по учебной работе ГБПОУ ИО «Иркутский аграрный техникум»
		</p>
		<p class="EventsPage-text">
			Электроника. <b>Зверев Дмитрий Александрович</b>, педагог дополнительного образования МБОУ «Лицей №2» города Братска
		</p>
		<p class="EventsPage-text">
			Мультимедиакоммуникации: сопровождение инженерных проектов. <b>Коршунов Александр Сергеевич</b>, педагог дополнительного образования МАОУ дополнительного образования «Центр детского творчества» г. Усть-Илимска
		</p>
		<p class="EventsPage-text">
			Прототипирование. <b>Перевалова Юлия Викторовна</b>, учитель технологии МБОУ г. Иркутска СОШ №80
		</p>
		<p class="EventsPage-text">
			Инженерный дизайн. <b>Журавлева-Борн Ольга Александровна</b>, заместитель директора МБОУ Шелеховского района «Гимназия».
		</p>
		<p class="EventsPage-text">
			Мобильная робототехника . <b>Зулина Ксения Владимировна</b>, учитель робототехники МБОУ г. Иркутска СОШ №64
		</p>
	</div>
</template>