<template>
	<div class="Pruffme _cover">
		<h2 class="Pruffme-title _cover-title">
			Календарь мероприятий
		</h2>

		<div class="Pruffme-box">
			<iframe class="Pruffme-iframe" src="https://pruffme.com/cabinet/#/calendar/2023/october" frameborder="0"></iframe>
			
		</div>
	</div>
</template>