<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			03.04.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/news/3603148/">Школьница из Приангарья примет участие в съемках финала телеолимпиады «Умницы и умники»</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage52.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Ученица 11 класса школы № 4 Железногорск-Илимского Алена Хрущева вошла в число финалистов 32 сезона всероссийской гуманитарной телевизионной олимпиады «Умницы и умники».
			</p>
			<p class="NewsPage-sub-title">
				По информации регионального минобра, одиннадцатиклассница участвовала в съемках программ полуфинала по темам «Эпоха при правлении Александра III», «1944 год во всем мире», «Российская Арктика: география, история, экономика, народы и люди».
			</p>
			<p class="NewsPage-sub-title">
				Успешно выступив в полуфинале телеолимпиады, Алена обеспечила себе выход в заключительный этап, съемки, которого пройдут с 5 по 6 апреля этого года в Москве. Там школьница сможет побороться за главный приз – стать студенткой Московского государственного института международных отношений (МГИМО). Посмотреть передачу можно будет в эфире «Первого канала».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, Алена Хрущева стала абсолютным победителем регионального тура, который состоялся в мае 2023 года на площадке регионального центра выявления и поддержки одаренных детей «Персей» и получила возможность представить Приангарье в 32-м сезоне всероссийской гуманитарной телевизионной олимпиады «Умницы и умники».
			</p>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов сообщил, что Приангарье с 2022 года получило возможность организовывать региональные туры всероссийской гуманитарной телевизионной олимпиады «Умницы и умники» при поддержке организаторов этого проекта – Студии Юрия Вяземского «Образ – ТВ».
			</p>
			<p class="NewsPage-sub-title">
				– Участие в телевизионной олимпиаде «Умницы и умники» – еще одна возможность для талантливых школьников проявить свои способности на всероссийском уровне. Образовательный центр «Персей» был создан благодаря президентскому национальному проекту «Образование» именно для того, чтобы создавать условия для развития и продвижения одаренных детей, мы рады тому, что с каждым годом все больше желающих попробовать свои силы в образовательных программах «Персея», в том числе – в профильной смене «Умницы и умники», – прокомментировал Максим Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				Министр напомнил, что в прошлом году победителем стал выпускник центра образования № 47 Иркутска Артем Селиверстов. Сейчас юноша обучается в МГИМО по направлению «Зарубежное регионоведение».
			</p>
			<p class="NewsPage-sub-title">
				Для справки: участие в региональном туре телеолимпиады «Умницы и умники» могут принять школьники 10-х классов. Для этого необходимо подать заявку и пройти конкурсный отбор. Профильная смена «Умницы и умники» пройдет в июне 2024 года в Образовательном центре «Персей». По итогам обучения школьники смогут побороться за выход в четвертьфинал 33-его сезона телеолимпиады. Информация по участию в образовательной программе будет опубликована в мае 2024 года на сайте «Персея» во вкладке «Профильные смены.
			</p>

		</div>
	</div>
</template>
