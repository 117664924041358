<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage39.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				<b>Наш 2023 год: итоги</b>
			</h2>
			<p class="NewsCard-date">
				29.12.2023
			</p>
		</div>
	</div>
</template>