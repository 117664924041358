<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Актуальные вопросы обществознания»
		</h2>
		<p class="EventsPage-date">
			Обучение проводится с 27 сентября по 10 октября 2023
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22489-programma-aktualnye-voprosy-obshchestvoznaniya" target="_blank">
			Регистрация
		</a>
		<hr>

		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
				<p class="EventsPage-text">
					Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Актуальные вопросы обществознания» для обучающихся 8-10-х классов общеобразовательных организаций.<br>
				</p>
				<p class="EventsPage-text">
					Программа смены направлена на получение обучающимися знаний в области общественных наук, таких, как политика, право, экономика, этика, эстетика, социология для воссоздания полной картины общественного устройства.
				</p>
				<p class="EventsPage-text">
					Профильная смена состоится <b>с 27 сентября по 10 октября 2023 года</b>, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
				</p>
				<hr>
				
				<h2 class="EventsPage-prof">
					<b>Собрание по профильной смене 22.09.2023, 20:00 — 21.00</b>
				</h2>
				<h3 class="EventsPage-nte">
					Ссылка на подключение:<br>
				</h3>
				<a class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1695175482" target="_blank">
					Пруффми
				</a>
				<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%BF%D1%80%D0%B8%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-1.pdf" target="_blank" download>
					Списочный состав обучающихся, приглашённых на очную профильную смену «Актуальные вопросы обществознания»
				</a>
				<h3 class="EventsPage-nte">
					Списочный состав участников профильной смены будет размещен 22.09.2023
				</h3>
				<hr>

				<p class="EventsPage-nte-dis">
					<b>Контактное лицо:</b> Егорова Юлия Сергеевна<br>
					<b>Телефон:</b> 89246361877<br>
					<b>Эл. почта:</b> yulcha_23@mail.ru<br>
				</p>
				<hr>

				<h2 class="EventsPage-prof">
					Документы
				</h2>
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/M-AUNqFlLACW8A" target="_blank">
					Сертификаты
				</a>

		</div>
	</div>
</template>