<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Региональный конкурс
		</h3>
		<h2 class="EventsPage-title">
			Региональный конкурс «Лучшие практики работы с одаренными детьми»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			25 февраля - 21 июня 2024 г.
		</h4>
		<a class="EventsPage-btn temp-btn" href="https://konkurs.ric38.ru/" target="_blank">
			Регистрация
		</a>
		<hr />

		<ul class="JuniorProfi-list">
			<p class="EventsPage-text">
				Учредитель Конкурса
			</p>
			<li class="JuniorProfi-items">
				Министерство образования Иркутской области
			</li>
			<p class="EventsPage-text">
				Оператор конкурса
			</p>
			<li class="JuniorProfi-items">
				Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
			</li>
		</ul>
		<hr>

		<h3 class="EventsPage-prof">
			О конкурсе
		</h3>
		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-4.xlsx"
			target="_blank">
			Приложение 4
		</a>
		<a class="EventsPage-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9B%D0%9F.pdf"
			target="_blank">
			Распоряжение ЛП
		</a>
		<a class="EventsPage-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B0-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B8-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%B4%D0%B5%D1%82%D1%8C%D0%BC%D0%B8.pdf"
			target="_blank">
			Об итогах конкурса Лучшие практики работы с одаренными детьми
		</a>
		<hr />

		<p class="JuniorProfi-sub-title">
			Цель конкурса
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Выявление и поддержка лучших практик работы с одаренными, талантливыми и высокомотивированными детьми.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для участников:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Принять участие в конкурсе могут педагогические и руководящие работники образовательных организаций, реализующих
				образовательные программы начального общего образования, основного общего образования, среднего общего
				образования, среднего профессионального образования, дополнительные общеразвивающие программы и дополнительные
				предпрофессиональные программы.
			</li>
			<li class="JuniorProfi-items">
				Допускается подача заявки индивидуально и от коллектива, не превышающего 3 (трех) человек.
			</li>
			<li class="JuniorProfi-items">
				В качестве лучших практик работы с одаренными, талантливыми и высокомотивированными детьми в рамках данного
				положения следует понимать следующие учебно-методические материалы:
			</li>
			<li class="JuniorProfi-items">
				методические рекомендации;
			</li>
			<li class="JuniorProfi-items">
				дидактические материалы.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Номинации конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными детьми общеобразовательной
				организации»;
			</li>
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными обучающимися организации
				дополнительного образования детей».
			</li>
			<li class="JuniorProfi-items">
				«Лучшая практика работы с одаренными, талантливыми и высокомотивированными обучающимися дошкольной
				образовательной организации».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Этапы конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Отборочный этап: с 5 февраля по 15 мая 2024 г.
			</li>
			<li class="JuniorProfi-items">
				Прием документов: с 5 февраля по 15 апреля 2024 г.
			</li>
			<li class="JuniorProfi-items">
				Заключительный этап: с 16 мая по 23 июня 2024 г.
			</li>
		</ul>

	</div>
</template>
