<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Беспилотные авиационные системы»
		</h2>
		<p class="EventsPage-date">
			с 13 октября по 26 октября 2023
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Беспилотные авиационные системы» для обучающихся 5-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа направлена на профориентацию на профессии, связанные с инженерным авиамоделированием и с управлением беспилотными аппаратами, а также направлена на расширение знаний по авиационной и авиамодельной технике, по основам аэродинамики и методике несложных технических расчетов. Основная задача теоретических занятий - расширить знания по физике полета, аэродинамике моделей и технике моделирования при постройке летающих моделей.
		</p>
		<p class="EventsPage-text">
			Отличительной особенностью программы является интеграция технической и физкультурно-спортивной направленности. Обучающиеся получают технические знания и умения, включающие изучение устройства и принципов работы беспилотных летательных аппаратов (БЛА), навыки сборки и ремонта устройств, в том числе 3д печать отдельных деталей, учатся управлять БЛА, а также изучают правила и отрабатывают спортивные навыки по авиамодельному спорту в классе моделей F9U.
		</p>
		<p class="EventsPage-text">
			Профильная смена состоится с 13 октября по 26 октября 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>
		<p class="EventsPage-nte-dis">
			<b>Контактное лицо:</b> Толстихина Татьяна Павловн<br>
			<b>Телефон:</b> 8 (3952) 546-044 <br>
			<b>Эл. почта:</b> n.tolstihina@perseusirk.ru<br>
		</p>
		<hr>
		<h2 class="EventsPage-prof">
			Документы
		</h2>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/1sbBnRZPuG6nZA" target="_blank">
			Сертификаты
		</a>
	</div>
</template>