<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Анатомия без границ: изучение тела человека».
		</h3>
		<p class="EventsCard-sub-title">
			с 15 по 25 декабря 2023 года.
		</p>
	</div>
</template>