<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Оркестровое духовое исполнительство»
		</h2>
		<p class="EventsPage-date">
			с 13 октября по 23 октября 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21764-programma-orkestrovoe-dukhovoe-ispolnitelstvo" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
			<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				Сыроватская Ангелина Геннадьевна
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				89148810258
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				perseus@perseusirk.ru
			</p>
		</div>
			<hr>
		<p class="EventsPage-sub-title">
			Образовательный центр «Персей» проводит профильную смену «Оркестровое духовое исполнительство» для учащихся от 10 до 17 лет, обладающих основными музыкальными способностями (слух, ритм, память), исполнительскими навыками игры на духовых или ударных инструментах, а также начальными навыками игры в ансамбле.
			Программа «Оркестровое духовое исполнительство» ориентирована на развитие мотивации учащихся к познанию через создание условий для раскрытия творческого потенциала в составе оркестра, выявление одаренных детей в области музыкального искусства, развитие умений и навыков игры на духовых и ударных инструментах в ансамбле и в оркестре, позволяющих исполнять музыкальные произведения в соответствии с необходимым уровнем музыкальной грамотности и стилевыми традициями, а также, приобщение детей к сценическим формам духового оркестрового исполнительства.
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-sub-title">
			Сорвин Александр Валерьевич, учитель по классу трубы государственного нетипового общеобразовательного бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска», дирижер Образцового детского духового оркестра «Серебряные трубы»;
			Портяная Анастасия Александровна, учитель по классу саксофона государственного нетипового общеобразовательного бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска».
		</p>
	</div>
</template>