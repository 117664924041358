<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Литературное редактирование»
		</h2>
		<p class="EventsPage-date">
			с 13 мая по 7 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=161" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24845-programma-literaturnoe-redaktirovanie"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Литературное редактирование» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Работа с текстом используется во всех сферах жизни, на учёбе, работе, повседневной жизни. Учащиеся, студенты часто
			пишут рефераты, проекты, курсовые, дипломные работы. От правильного, грамотного оформления текста зависит успех
			вашей работы. Литературное редактирование расширяет знания и представления о русском литературном языке, позволяет
			понимать, анализировать текст, а также прогнозировать, как будет функционировать произведение в читательской
			среде. В рамках программы осуществляется ранняя профориентация, совершенствуются навыки в общении, коммуникации.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Потемкина Софья Викторовна – магистрант Факультета теоретической и прикладной филологии ИГУ направления
				«Современная филология: практики работы с текстами»; главный библиотекарь отдела историко-культурного наследия
				ГБУК ИОГУНБ им. И. И. Молчанова-Сибирского
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 2 мая 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету 2 мая 2024 года на платформе Moodle, Образовательного центра «Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/UKfv/27rkehka5" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>