<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«SMM: Маркетинг в социальных сетях»
		</h2>
		<p class="EventsPage-date">
			с 6 по 31 мая 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=160" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24841-programma-smm-marketing-v-sotsialnykh-setyakh"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «SMM: Маркетинг в социальных сетях» для учащихся
			8-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			В современном мире важную роль играют социальные сети и онлайн платформы. В школах, учреждениях дополнительного
			образования создаются медиацентры, Данная программа поможет учащимся создать и продвигать сообщества в социальных
			сетях творческих объединений, образовательных учреждений, свои личные. Также программа поможет развить
			коммуникативные навыки, продвигать себя, свои идеи или проекты в онлайн пространстве, повысит цифровую и медиа
			грамотность.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса::
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Мироманов Дмитрий Александрович, создатель «Бабрбука» — народного путеводителя по Иркутску, сертифицированный
				крауд-продюсер, победитель премии в области развития коммуникаций «Серебряный лучник. Сибирь»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 2 мая 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 2 мая 2024 года на платформе Moodle, Образовательного центра «Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/k_mOtRRhMKaY2w" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>