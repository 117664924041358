<template>
	<div class="News _cover">
		<h2 class="News-title _cover-title">
			Новости
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage78' })">
					<news-card78></news-card78>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage77' })">
					<news-card77></news-card77>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage75' })">
					<news-card75></news-card75>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage76' })">
					<news-card76></news-card76>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage74' })">
					<news-card74></news-card74>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage73' })">
					<news-card73></news-card73>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage72' })">
					<news-card72></news-card72>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage71' })">
					<news-card71></news-card71>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage70' })">
					<news-card70></news-card70>
				</a>
			</div>

		</div>
		<router-link to="/News" class="News-btn temp-btn">
			Все новости
		</router-link>
	</div>
</template>

<style>

.News-items-link {
	display: block;
	min-width: 250px;
	min-height: auto;
	cursor: pointer;
}
</style>

<script>
import NewsCard70 from '@/NewsCard/NewsCard70';
import NewsCard71 from '@/NewsCard/NewsCard71';
import NewsCard72 from '@/NewsCard/NewsCard72';
import NewsCard73 from '@/NewsCard/NewsCard73';
import NewsCard74 from '@/NewsCard/NewsCard74';
import NewsCard75 from '@/NewsCard/NewsCard75';
import NewsCard76 from '@/NewsCard/NewsCard76';
import NewsCard77 from '@/NewsCard/NewsCard77';
import NewsCard78 from '@/NewsCard/NewsCard78';

	export default {
		components: {
			NewsCard70,
			NewsCard71,
			NewsCard72,
			NewsCard73,
			NewsCard74,
			NewsCard75,
			NewsCard76,
			NewsCard77,
			NewsCard78,
		}
	}
</script>