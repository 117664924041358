<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Дистанционная викторина
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Экспериментальная физика»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			Обучение проводится с 13 по 23 января 2024 года
		</h4>

		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=119" target="_blank">
			Регистрации Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24392-programma-eksperimentalnaya-fizika-7-8-klass" target="_blank">
			Регистрации Навигатор 7-8 класс
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24393-programma-eksperimentalnaya-fizika-9-11-klass" target="_blank">
			Регистрации Навигатор 9-11 класс
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Экспериментальная физика» для учащихся 7-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа позволит учащимся развить аналитические способности, раскрыть творческий потенциал необходимые для успешного участия в региональном этапе всероссийской олимпиады школьников по физике.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для прохождения обучения:
		</p>
		<p class="EventsPage-text">
			Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
		</p>
		<p class="EventsPage-text">
			Зарегистрироваться и заполнить анкету на платформе дистанционного обучения Образовательного центра «Персей» системе Moodle.
		</p>
		<p class="EventsPage-text">
			Дистанционный курс состоится на бесплатной основе.
		</p>
		<p class="EventsPage-text">
			Для удаленных районов Иркутской области дистанционный курс проходит на платформе moodle, видео-урок будет размещен на следующий день после занятий в очном формате.
		</p>
		<p class="EventsPage-text">
			Для близлежащих районов дистанционный курс состоится в Иркутском Государственном Университете Физический факультет (адрес: г. Иркутск, бульвар Гагарина, 20).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Расписание для очного обучения (<b>на первое занятие принести флешки</b>):
		</p>
		<ul class="JuniorProfi-list">
			<p class="JuniorProfi-sub-title">
				14.01.2024:
			</p>
			<li class="JuniorProfi-items">
				7-ые и 8-ые классы: 10:00 – 12:15;
			</li>
			<li class="JuniorProfi-items">
				9-ые и 11-ые классы: 13:00 – 15:15.
			</li>
			<p class="JuniorProfi-sub-title">
				16.01.2024:
			</p>
			<li class="JuniorProfi-items">
				7-ые и 8-ые классы: 10:00 – 12:15;
			</li>
			<li class="JuniorProfi-items">
				9-ые и 11-ые классы: 13:00 – 15:15.
			</li>
			<p class="JuniorProfi-sub-title">
				17.01.2024:
			</p>
			<li class="JuniorProfi-items">
				7-ые и 8-ые классы: 10:00 – 12:15;
			</li>
			<li class="JuniorProfi-items">
				9-ые и 11-ые классы: 13:00 – 15:15.
			</li>
			<p class="JuniorProfi-sub-title">
				23.01.2024:
			</p>
			<li class="JuniorProfi-items">
				7-ые и 8-ые классы: 10:00 – 11:30;
			</li>
			<li class="JuniorProfi-items">
				9-ые и 11-ые классы: 13:00 – 14:30.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна 
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru 
			</li>
		</ul>

	</div>
</template>