<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage158' })">
						<EventsCard158></EventsCard158>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage144' })">
						<EventsCard144></EventsCard144>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" href="https://starsite.perseusirk.ru/ps-uiu-210523-300523/" target="_blank">
						<EventsCard81></EventsCard81>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard81 from '../EventsCard/EventsCard81';
import EventsCard144 from '../EventsCard/EventsCard144';
import EventsCard158 from '../EventsCard/EventsCard158';

	export default {
		components: {
			EventsCard81,
			EventsCard144,
			EventsCard158,
		}
	}
</script>
