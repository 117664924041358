<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-3">
			Искусство
		</h2>
		<h3 class="EventsCard-title">
			«Лаборатория творчества: хореографический спектакль»
		</h3>
		<p class="EventsCard-sub-title">
			18.01.23-31.01.23
		</p>
	</div>
</template>