<template>
	<div class="Scholarships _cover">
		<h2 class="Scholarships-title _cover-title">
			Международное сотрудничество
		</h2>
		<h3 class="Scholarships-title-box">
			Международное сотрудничество:
		</h3>
		<p class="Scholarships-sub-title">
			Договоры с иностранными и (или) международными организациями по вопросам образования не заключались и их
			заключение не планируется.
		</p>
		<p class="Scholarships-sub-title">
			Международная аккредитация образовательных программ не осуществлялась.
		</p>
	</div>
</template>