<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			31.01.2024
		</p>
		<h2 class="NewsPage-title">
			Современная хореография. Итоги первой программы в 2024 году по направлению «Искусство»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage45.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В январе в Образовательном центре «Персей» прошла профильная смена по направлению «Искусству» – «Лаборатория творчества: техники современного танца». Участие приняли более 30 юных хореографов из Иркутска. О том, как проходила программа, читайте в нашем материале.
			</p>
			<p class="NewsPage-sub-title">
				Наталья и Арина Бородихины – педагоги-хореографы Иркутского регионального колледжа педагогического образования, совместно провели программу для юных танцоров.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказывают преподаватели, основной уклон во время обучения был сделан на знакомство с техниками современного танца. Среди них: релиз, партнеринг и контактная импровизация.
			</p>
			<p class="NewsPage-sub-title">
				– Особенностью данных техник является то, что от участников требуется полное погружение в процесс. На занятиях ребята учились с помощью практик фокусироваться на своем внутреннем состоянии исполнителя и передавать его через танец. Именно отражение своего «Внутреннего я» и умение выражать свои чувства и настроение в танце, является важным элементом в современной хореографии, – пояснила Арина Бородихина.
			</p>
			<p class="NewsPage-sub-title">
				При изучении техник партнеринг и импровизация наставники помогали ребятам приобрести способность работать не только в индивидуально, но и совместно с партнером. Каждому участнику необходимо было проявлять инициативу, брать на себя ответственность, а также научиться слушать и слышать друг друга во время взаимодействия на сцене.
			</p>
			<p class="NewsPage-sub-title">
				В результате обучения каждый танцор смог реализовать свои творческие идеи в танцевальных комбинациях и зарисовках, которые вошли в отчетный концерт. 
			</p>
			<p class="NewsPage-sub-title">
				– В постановочном процессе участники получили опыт не только танцевальных, но и актерских навыков, – подчеркнула Наталья Бородихина.  Каждое произведение отличается своей темой, настроением и историей, которую необходимо донести до зрителя. Номера стали полноценными произведениями, которые в дальнейшем продолжат свою жизнь не только в репертуаре ребят, но и на конкурсах и фестивалях всероссийского и международного уровня.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, 7 февраля в кампусе Образовательного центра пройдет вторая зимняя смена по современной хореографии. Школьники изучат направление Street dance (уличные танцы).
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage45(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage45(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage45(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage45(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage45(5).webp" alt="Картинка">
					</li>
				</ul>
			</div>
			
		</div>
	</div>
</template>
