<template>
	<div class="SelectionCriteria _cover">
		<h2 class="SelectionCriteria-title _cover-title">
			Критерии отбора
		</h2>

		<p class="NewsPage-sub-title">
	Участниками образовательных программ Центра могут стать школьники Иркутской области в возрасте от 10 до 18 лет, демонстрирующие выдающиеся успехи в науке, искусстве и спорте.
		</p>
		<p class="NewsPage-sub-title">
			В образовательных программах Центра могут принимать участие граждане Российской Федерации.
		</p>
		<p class="NewsPage-sub-title">
			Допускается участие школьников в течение календарного года не более чем в двух очных профильных сменах, при условии выбора образовательных программ разной направленности.
		</p>
		<p class="NewsPage-sub-title">
			Участие в образовательных программах Центра бесплатно для детей.
		</p>
		
		<p class="JuniorProfi-sub-title">
			Направление «Наука»
		</p>
		<p class="NewsPage-sub-title">
			Отбор на очное обучение проводится отборочной комиссией, созданной в соответствии с действующими локальными актами Центра. В состав комиссии входят эксперты, преподаватели, сотрудники Центра.
		</p>
		<p class="NewsPage-sub-title">
			При отборе могут быть учтены:
		</p>
		<p class="NewsPage-sub-title">
			- академические достижения учащихся: результаты выступлений на этапах всероссийской олимпиады школьников и всероссийского конкурса научно-технологических проектов, этапах олимпиады Эйлера и олимпиады Максвелла, в олимпиадах, прошедших экспертизу Российского совета олимпиад школьников;
		</p>
		<p class="NewsPage-sub-title">
			- результаты выполнения заданий, предложенных руководителем программы, в том числе дистанционных и очных туров, оценки выполнения кейсов, творческих работ учащихся, мотивационных писем.
		</p>
		<p class="NewsPage-sub-title">
			Подать заявку на участие в конкурсном отборе можно на странице образовательной программы в указанные сроки.
		</p>
		
		<p class="JuniorProfi-sub-title">
			Направление «Искусство»
		</p>
		<p class="NewsPage-sub-title">
			Участниками очных образовательных программах Центра стать школьники, проявившие способности в области музыкально-исполнительского, изобразительного, хореографического и театрально-сценического искусства по результатам творческого отбора.
		</p>
		<p class="NewsPage-sub-title">
			Индивидуальный творческий отбор проводится отборочной комиссией на основании критериев отбора в соответствии с профилем и рекомендаций Экспертных организаций. Кандидат на участие в программе предоставляет видеозаписи исполнения музыкальных, хореографических или чтецких произведений, портфолио творческих работ по изобразительному и декоративно-прикладному искусству, творческую биографию и документы, подтверждающие творческие достижения (дипломы всероссийских, международных, региональных конкурсов, иные награды).
		</p>
		
		<p class="JuniorProfi-sub-title">
			Направление «Спорт»
		</p>
		<p class="NewsPage-sub-title">
			Отбор обучающихся на спортивные программы Центра осуществляются на основании критериев отбора и рекомендаций Экспертных организаций по соответствующим видам спорта.
		</p>

		<p class="JuniorProfi-sub-title">
			Что необходимо сделать для подачи заявки на программу?
		</p>
		<p class="NewsPage-sub-title">
			1. Ознакомиться с правилами подачи и рассмотрения заявок на участие.
		</p>
		<p class="NewsPage-sub-title">
			2. Изучить Критерии отбора на образовательную программу.
		</p>
		<p class="NewsPage-sub-title">
			3. Зарегистрироваться на сайте и подать заявку (ссылка на форму подачи заявки публикуется на странице мероприятия).
		</p>
		<p class="NewsPage-sub-title">
			В связи с целостностью и содержательной логикой образовательной программы, интенсивным режимом занятий и объемом академической нагрузки, рассчитанной на весь период пребывания обучающихся в Образовательном центре «Персей», не допускается участие школьников в отдельных мероприятиях или части образовательной программы: исключены заезды и выезды школьников вне сроков, установленных Центром.
		</p>

	</div>
</template>