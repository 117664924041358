<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Оркестровое духовое исполнительство»
		</h2>
		<p class="EventsPage-date">
			с 13 октября по 23 октября 2023 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/21764-programma-orkestrovoe-dukhovoe-ispolnitelstvo" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит профильную смену «Оркестровое духовое исполнительство» для учащихся от 10
			до 17 лет, обладающих основными музыкальными способностями (слух, ритм, память), исполнительскими навыками игры на
			духовых или ударных инструментах, а также начальными навыками игры в ансамбле.
		</p>

		<p class="EventsPage-text">
			Программа «Оркестровое духовое исполнительство» ориентирована на развитие мотивации учащихся к познанию через
			создание условий для раскрытия творческого потенциала в составе оркестра, выявление одаренных детей в области
			музыкального искусства, развитие умений и навыков игры на духовых и ударных инструментах в ансамбле и в оркестре,
			позволяющих исполнять музыкальные произведения в соответствии с необходимым уровнем музыкальной грамотности и
			стилевыми традициями, а также, приобщение детей к сценическим формам духового оркестрового исполнительства.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сорвин Александр Валерьевич, учитель по классу трубы государственного нетипового общеобразовательного бюджетного
				учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска», дирижер Образцового
				детского духового оркестра «Серебряные трубы»;
			</li>
			<li class="JuniorProfi-items">
				Портяная Анастасия Александровна, учитель по классу саксофона государственного нетипового общеобразовательного
				бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Учащимся, обладающим исполнительскими навыками игры на духовых или ударных инструментах, а также начальными
				навыками игры в ансамбле, необходимо обязательно зарегистрироваться до 2 октября 2023 года в системе Навигатор
				дополнительного образования Иркутской области по ссылке:
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21764-programma-orkestrovoe-dukhovoe-ispolnitelstvo.
			</li>
			<li class="JuniorProfi-items">

				Каждый участник смены должен иметь личный музыкальный инструмент для занятий.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>