<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Искусство фотосъёмки»
		</h2>
		<p class="EventsPage-date">
			с 19 июля по 1 августа 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25346-programma-iskusstvo-fotosemki"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Искусство фотосъёмки» для учащихся 5-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Фотография в нашей жизни с каждым днем занимает все большее место. Интерес к фотографии сегодня как хобби и
			профессия продолжает набирать популярность. Актуальность программы – заключается в приобщении учащихся к
			фотографии во всех её аспектах, привитии художественного вкуса, что, в свою очередь, оказывает благоприятное
			воздействие на формирование нравственных качеств, развитие эстетических чувств, творческого самовыражения;
			помогает адаптироваться в сложной обстановке современного мира, а также в последующем самоопределении.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Записаться на программу «Искусство фотосъёмки» на ресурсе «Навигатор дополнительного образования Иркутской
				области».
			</li>
			<li class="JuniorProfi-items">
				На Смену зачисляются учащиеся, которые записались на программу в Навигаторе дополнительного образования
				Иркутской области в установленные сроки и имеющие опыт в практике художественной фотографии
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 19 июля 2024 года по 1 августа 2024 года на бесплатной основе в Образовательном
				центре «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Бабакина Ксения Александровна</li>
			<li class="EventsPage-number">Кошкарева Ева Витальевна</li>
			<li class="EventsPage-number">Лещова Инна Владимировна</li>
			<li class="EventsPage-number">Лыкова Дарья Игоревна</li>
			<li class="EventsPage-number">Новикова Екатерина Романовна</li>
			<li class="EventsPage-number">Пегова Ольга Максимовна</li>
			<li class="EventsPage-number">Руднева Елизавета Дмитриевна</li>
			<li class="EventsPage-number">Соколова Маргарита Антоновна</li>
			<li class="EventsPage-number">Хаптагаев Андрей Александрович</li>
			<li class="EventsPage-number">Шацких Екатерина Сергеевна</li>
			<li class="EventsPage-number">Шипицина Анастасия Алексеевна</li>
			<li class="EventsPage-number">Якушева Ольга Игоревна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>