<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Конкурс
		</h2>
		<h3 class="EventsCard-title">
			Конкурс к «Международному женскому дню» для школьников с 10 до 17 лет
		</h3>
		<p class="EventsCard-sub-title">
			с 4 по 22 марта
		</p>
	</div>
</template>