<template>
	<div class="AdditionalProfessionalPrograms2024 _cover">
		<h2 class="AdditionalProfessionalPrograms2024 _cover-title">
			Перечень реализуемых дополнительные профессиональные программы 2024 года
		</h2>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Проектирование программы отдыха детей и их оздоровления
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику проведения курсов повышения квалификации</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно-заочная с применением дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">96 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Руководители организаций отдыха детей и их оздоровления</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Трудовая функция:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">управление деятельностью организации отдыха детей и их
					оздоровления</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Трудовое действие:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">организация разработки, корректировки и утверждения
					дополнительных общеобразовательных программ и программ отдыха детей и их оздоровления</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">принципы, методы и технологии разработки, анализа и реализации
					образовательных программ для достижения запланированных результатов
					Образовательные теории, технологии и средства обучения и воспитания, границы и возможности их использования в
					организации отдыха детей и их оздоровления
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">планировать результаты реализации образовательных программ
					образовательной организации. Организовывать разработку, коррекцию формы организации обучения и воспитания,
					основываясь на социальных запросах участников образовательных отношений, возможностях обучающихся,
					педагогического коллектива и требованиях к содержанию, условиям и результатам реализации образовательных
					программ и программ отдыха детей и их оздоровления.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Удостоверение о повышении квалификации установленного образца</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Соловьева Дарья Сергеевна</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9F%D0%9F-%D0%9F%D0%9A-%D0%9F%D0%9F%D0%9E%D0%94%D0%98%D0%9E-2023-96-%D1%87%D0%B0%D1%81%D0%BE%D0%B2.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Организационно-педагогическое обеспечение воспитательного процесса в организациях отдыха детей и их
				оздоровления
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику проведения курсов повышения квалификации</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно-заочная с применением дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">144 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">воспитатели организаций отдыха детей и их оздоровления</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Трудовая функция:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">организационно-педагогическое обеспечение проектирования и
					реализации программ воспитания.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Трудовое действие:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осуществлять организационно-педагогическое сопровождение
					воспитательной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">источники актуальной информации в области
					социально-педагогической поддержки обучающихся в процессе социализации в организации отдыха детей и их
					оздоровления. Механизмы программно-методического обеспечения социального партнерства институтов социализации
					по вопросам социально-педагогической поддержки обучающихся в организации отдыха детей и их оздоровления.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осуществлять организационно-методическое сопровождение
					воспитательных мероприятий программы отдыха детей и их оздоровления. Разрабатывать методические материалы для
					программ социально-педагогической поддержки обучающихся, предназначенные для обеспечения деятельности
					организации отдыха детей и их оздоровления.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Удостоверение о повышении квалификации установленного образца</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Соловьева Дарья Сергеевна</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9F%D0%9F-%D0%9F%D0%9A-%D0%9E%D0%A0%D0%93.%D0%9F%D0%95%D0%94-2023-144-%D1%87%D0%B0%D1%81%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Проектирование программы отдыха детей и их оздоровления
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику проведения курсов повышения квалификации</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно-заочная с применением дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">руководители организаций отдыха детей и их оздоровления</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Трудовая функция: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">управление деятельностью организации отдыха детей и их
					оздоровления</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Трудовое действие: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">организация разработки, корректировки и утверждения
					дополнительных общеобразовательных программ и программ отдыха детей и их оздоровления</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">принципы, методы и технологии разработки, анализа и реализации
					образовательных программ для достижения запланированных результатов
					Образовательные теории, технологии и средства обучения и воспитания, границы и возможности их использования в
					организации отдыха детей и их оздоровления
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">планировать результаты реализации образовательных программ
					образовательной организации. Организовывать разработку, коррекцию формы организации обучения и воспитания,
					основываясь на социальных запросах участников образовательных отношений, возможностях обучающихся,
					педагогического коллектива и требованиях к содержанию, условиям и результатам реализации образовательных
					программ и программ отдыха детей и их оздоровления.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Удостоверение о повышении квалификации установленного образца</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Соловьева Дарья Сергеевна</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9F%D0%9F-%D0%9F%D0%9A-%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-2024.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Современные концепции организации летнего отдыха
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику проведения курсов повышения квалификации</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно-заочная с применением дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">воспитатели организаций отдыха детей и их оздоровления</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Виды деятельности/трудовые функции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">планирование воспитательной работы с группой обучающихся.
					Организационно-методическое обеспечение воспитательного процесса в группе обучающихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Профессиональные компетенции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">разработка плана воспитательной работы с группой обучающихся.
					Приобщение обучающихся к проектированию совместной социально и личностно значимой деятельности.
					Организационно-методическое обеспечение воспитательной деятельности педагогов с группой обучающихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">планировать различные виды деятельности группы и микрогрупп
					обучающихся в целях гражданско-патриотического, духовно-нравственного, трудового, экологического,
					эстетического, интеллектуального и физического воспитания. Организовывать участие группы обучающихся в
					социально и личностно значимой деятельности институтов социализации. Осуществлять отбор актуальных
					методических материалов для воспитательной работы с группой обучающихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">примерное содержание деятельности обучающихся в целях
					гражданско-патриотического, духовно-нравственного, трудового, экологического, эстетического, интеллектуального
					и физического воспитания. Подходы к разработке совместных программ воспитательной деятельности институтов
					социализации с группой обучающихся. Нормативно-методические документы, источники методической литературы и
					подходы к отбору актуальных методических материалов для воспитательной деятельности с группой обучающихся.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Удостоверение о повышении квалификации установленного образца</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Федулина Александра Владимировна</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9F%D0%9F-%D0%9F%D0%9A-%D0%A1%D0%BE%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5-%D0%BA%D0%BE%D0%BD%D1%86%D0%B5%D0%BF%D1%86%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Управление организацией отдыха детей и их оздоровления
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику проведения курсов повышения квалификации</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно-заочная с применением дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">руководители организаций отдыха детей и их оздоровления</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Трудовая функция:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Руководство деятельностью по созданию в образовательной
					организации условий социализации и индивидуализации развития обучающихся</li>
				<li class="AdditionalGneral-summary-dict-items">Руководство системой психолого-педагогического сопровождения
					обучающихся</li>
				<li class="AdditionalGneral-summary-dict-items">Руководить деятельностью по проведению мероприятии,
					стимулирующих творческие и спортивные достижения обучающихся, интерес к научной, творческой и
					физкультурно-спортивной деятельности, волонтерскому движению.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Образовательные теории, технологии и средства обучения и
					воспитания, в том числе основанные на виртуальной и дополненной реальности, границы и возможности их
					использования в общем образовании</li>
				<li class="AdditionalGneral-summary-dict-items">Основы физиологии и гигиены детей школьного возраста</li>
				<li class="AdditionalGneral-summary-dict-items">Основы педагогики и психологии</li>
				<li class="AdditionalGneral-summary-dict-items">Санитарно-эпидемиологические правила и нормативы, относящиеся к
					деятельности общеобразовательной организации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">Формировать организационную структуру, штатное расписание,
					планировать потребность в кадрах, организовывать их подбор, прием на работу, допуск к работе, определять
					должностные обязанности</li>
				<li class="AdditionalGneral-summary-dict-items">Обеспечивать соблюдение правил санитарно-гигиенического режима,
					техники безопасности и охраны труда</li>
				<li class="AdditionalGneral-summary-dict-items">Обеспечивать исполнение локальных нормативных актов
					общеобразовательной организации по организации и осуществлению образовательной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Удостоверение о повышении квалификации установленного образца</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Канавина София Сергеевна</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9F%D0%9F-%D0%9F%D0%9A-%D0%A3%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B5%D0%B9-%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9-.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Особенности работы с одаренными детьми в области инструментального исполнительства
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику проведения курсов повышения квалификации</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно-заочная с применением дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">54 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Преподаватели ДШИ и ДМШ, педагоги дополнительного образования</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Трудовая функция: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">деятельности обучающихся, направленной на освоение
					дополнительной общеобразовательной программы.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Трудовое действие: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">организация, в том числе стимулирование и мотивация,
					деятельности и общения обучающихся на учебных занятиях по инструментальному исполнительству.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">характеристики различных методов, форм, приемов и средств
					организации деятельности обучающихся при освоении дополнительных общеобразовательных программ по
					инструментальному исполнительству. Особенности детей, одаренных в избранной области деятельности, специфика
					работы с ними. Особенности реализации дополнительных общеобразовательных программ для одаренных обучающихся.
				</li>
				<li class="AdditionalGneral-summary-dict-items">Профориентационные возможности занятий избранным видом
					деятельности</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Удостоверение о повышении квалификации установленного образца</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9F%D0%9F_%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

	</div>
</template>