<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«ЭКОЛОГИЧЕСКОЕ ПРОСВЕЩЕНИЕ: ОТ ТЕОРИИ К ПРАКТИКЕ»
		</h2>
		<p class="EventsPage-date">
			с 16 ноября по 11 декабря 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Экологическое просвещение: от теории к практике» для обучающихся 5-8-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа курса позволит учащимся узнать о мире, который нас окружает. Провести исследования, связанные с воздухом, водой и почвой, а самое главное программа поможет сформировать эстетическое и экологически грамотное отношение к природе.
		</p>
		<p class="EventsPage-text">
			Дистанционный курс состоится с 16 октября по 11 декабря 2023 года, на бесплатной основе.
		</p>
		<hr>
		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
			<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21970-programma-ekologicheskoe-prosveshchenie-ot-teorii-k-praktike">
				Подать заявку в Навигаторе.
			</a>
			<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=106">
				Зарегистрироваться и заполнить анкету в системе Moodle Образовательного Центра «Персей»
			</a>
			Обучение будет проводиться в очно-заочной форме. Расписание занятий, материалы курса, ссылки на онлайн-подключения будут размещены на странице курса в системе Moodle Образовательного Центра «Персей»

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/pkLgz5EQLe0e7A" target="_blank">
			Сертификаты
		</a>
	</div>
</template>