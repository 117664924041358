<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-1">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Формула безопасности»
		</h3>
		<p class="EventsCard-sub-title">
			10.03.23-16.03.23
		</p>
	</div>
</template>