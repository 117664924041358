<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			10.10.2023
		</p>
		<h2 class="NewsPage-title">
			Завершился школьный этап ВсОШ по астрономии на платформе «Сириус.Курсы»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage12.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В 2023 году в туре приняло участие 2259 школьников, из которых 227 – за 5 класс, 517 – за 6-7 класс, 762 – за 8-9 класс, 347 – за 10 класс и 406 – за 11 класс.
			</p>
			<p class="NewsPage-sub-title">
				Процент школ-участниц в регионе составил 26.28 %.
			</p>
			<p class="NewsPage-sub-title">
				Над заданиями олимпиады работали сильнейшие предметные специалисты: педагоги, тренеры национальных сборных, а также инженеры и научные сотрудники. Подробнее с составом команды авторов можно ознакомиться на <a class="NewsPage-link" href="https://siriusolymp.ru/authors">сайте</a>.
			</p>
			<p class="NewsPage-sub-title">
				В открытом доступе уже есть <a class="NewsPage-link" href="https://siriusolymp.ru/school2023/4/astronomy">текстовые и видеоразборы</a> заданий олимпиады. Каждый может провести работу над ошибками и узнать верные пути решений нестандартных задач.
			</p>
			<p class="NewsPage-sub-title">
				Предварительные результаты по астрономии появятся через 7 дней: смотреть их надо в <a class="NewsPage-link" href="https://uts.sirius.online/">тестирующей системе</a> по коду участника для данного предмета. Через 14 дней после тура в системе и в школах появятся окончательные результаты. После этого организаторы смогут определить победителей и призеров тура.
			</p>
			<p class="NewsPage-sub-title">
				Впереди еще туры по биологии, математике и информатике. Ребятам, желающим написать олимпиаду, необходимо лишь ознакомиться с актуальным для их группы регионов графиком проведения, а также запросить код участника в школе у учителя или организатора.
			</p>
			<p class="NewsPage-sub-title">
				Следить за информацией о турах школьного этапа можно на сайте <a class="NewsPage-link" href="https://siriusolymp.ru/">siriusolymp.ru</a> и в <a class="NewsPage-link" href="https://vk.com/siriusolymp">социальных сетях</a>.
			</p>
		</div>
	</div>
</template>
