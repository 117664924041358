<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			16.10.2023
		</p>
		<h2 class="NewsPage-title">
			В ИГУ прошел педагогический квиз «Педагогика: наука, искусство, технологии»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage17.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Квиз «Педагогика: наука, искусство, технологии», как одно из мероприятий, посвященных Году педагога и наставника, организовали совместно кафедра педагогики Педагогического института ИГУ, министерство образования Иркутской области и региональный центр выявления и поддержки одаренных детей «Персей». Мероприятие провели специально для будущих педагогов — студентов 2-х и 3- курсов ИГУ: Педагогического института и Института филологии, иностранных языков и медиакоммуникации.
			</p>
			<p class="NewsPage-sub-title">
				Участники, предварительно разделившись на 12 команд, получили возможность проявить себя специалистами в области педагогики, показать свои знания и умения работать в команде. Студенты отвечали на вопросы на логику, общую эрудицию, музыкальные и кино-вопросы. Особый интерес вызвали задания, связанные с историей становления образования в Восточной Сибири и города Иркутска. В интерактивном формате участники познакомились с педагогической деятельностью великих педагогов и погрузились в историю развития педагогической науки.
			</p>
			<p class="NewsPage-sub-title">
				<b>Елена Никитина</b>, руководитель проекта, кандидат педагогических наук, доцент кафедры педагогики Педагогического института ИГУ:
			</p>
			<p class="NewsPage-sub-title">
				<i>«Основная цель квиза — способствование развитию у студентов навыков педагогического, творческого мышления в освоении истории отечественной общественной гуманистической мысли, сложившейся в определенной историко-культурной среде; стимулирование развития читательской культуры и профессиональных компетенций участников. Также важным для нас является расширение читательского кругозора студентов посредством включения в личностный опыт осмысления педагогических идей, нашедших отражение в произведениях отечественной художественной литературы».</i>
			</p>
			<p class="NewsPage-sub-title">
				<i>«Ценность этой игры заключается в том, что, являясь по своей сути развлечением, она стимулирует творческую реализацию и самовыражение будущих учителей, ведь в основе педагогической деятельности лежит любовь умноженная на интерес познания».</i>
			</p>
			<p class="NewsPage-sub-title">
				<b>Победители квиза:</b>
			</p>
			<p class="NewsPage-sub-title">
				1 место — отделение педагогического, социального и специального образования Педагогического института (профиль подготовки «Начальное образование – Дополнительное образование»).
			</p>
			<p class="NewsPage-sub-title">
				2 место — отделение физико-математического, естественнонаучного и технологического образования Педагогического института (профиль подготовки «Профессиональное обучение по отраслям (Автомобили и автомобильное хозяйство)»).
			</p>
			<p class="NewsPage-sub-title">
				3 место — Институт филологии, иностранных языков и медиакоммуникации (профиль подготовки «Теория и методика преподавания иностранных языков и культур»).
			</p>
			
		</div>
	</div>
</template>
