<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage52.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Школьница из Приангарья примет участие в съемках финала телеолимпиады «Умницы и умники»
			</h2>
			<p class="NewsCard-date">
				03.04.2024
			</p>
		</div>
	</div>
</template>