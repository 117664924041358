<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Олимпиада
		</h3>
		<h2 class="EventsPage-title">
			Всероссийская открытая телевизионная гуманитарная олимпиада школьников «Умницы и умники»
		</h2>
		<p class="EventsPage-date">
			31 мая 2024 года
		</p>
		<hr>

		<p class="EventsPage-text">
			Цель Олимпиады: выявление у школьников творческих способностей и интереса к научной деятельности, создание
			необходимых условий для поддержки талантливой молодежи, пропаганда научных знаний.
		</p>

		<p class="EventsPage-text">
			Об олимпиаде: Олимпиада проводится в форме творческого конкурса по профилю «Гуманитарные и социальные науки» в
			один этап, он является отборочным. Обучающиеся, успешно прошедшие отборочный этап Олимпиады, могут быть приглашены
			для участия в четвертьфинале заключительного этапа Телевизионной гуманитарной олимпиаде школьников «Умницы и
			умники».
		</p>

		<p class="EventsPage-text">
			Участники: обучающиеся 10-х классов Иркутской области, которые успешно прошли обучение в Образовательном центре
			«Персей» в дистанционном курсе: «Умницы и умники».
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82-%D0%A3%D0%BC%D0%BD%D0%B8%D1%86%D1%8B-%D0%B8-%D0%A3%D0%BC%D0%BD%D0%B8%D0%BA%D0%B8-%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7.pdf"
			target="_blank">
			Приказ Умницы и Умники
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Гавриленко Татьяна Георгиевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: t.gavrilenko@perseusirk.ru
			</li>
		</ul>

	</div>
</template>