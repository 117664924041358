<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Физика полёта»
		</h2>
		<p class="EventsPage-date">
			с 8 ноября по 21 ноября 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Физика полёта» для обучающихся 8-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа курса позволит учащимся развить интеллектуально-творческие способности, интерес к научно-исследовательской деятельности, связанные с инженерным авиамоделированием и с управлением беспилотными аппаратами, а также специализированная прикладная отрасль метеорологии, изучающая влияние метеорологических условий на авиационную технику и деятельность авиации, а также разрабатывающая теоретические и методические основы метеорологического обеспечения полетов.
		</p>
		<p class="EventsPage-text">
			Обучение будет проводиться в очной форме на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны).
		</p>
		<hr>
		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
		<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/23092-programma-fizika-poleta">
			Подать заявку в Навигаторе.
		</a>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/login/index.php">
			Зарегистрироваться и заполнить анкету в системе Moodle Образовательного Центра «Персей»
		</a>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=114#section-0">
			Moodle Образовательного Центра «Персей»
		</a>
		<p class="EventsPage-text">
			Формой итоговой аттестации является соревнования по запуску моделей самолета на высоту, дальность и отказоустойчивость, победа и участие в которых требуют командной работы учащихся.
		</p>
		<a class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1698125427">
			Список учащихся, приглашенных на профильную смену, будет опубликован на сайте ОЦ «Персей» 31 октября 2023 года. Для участников смены будет проведено организационное собрание (31 октября 2023 г. в 20.00, ссылка на подключение.
		</a>
		<h2 class="EventsPage-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену «Физика полета»
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аносова Екатерина Андреевна</li>
			<li class="EventsPage-number">Бедарев Макар Петрович</li>
			<li class="EventsPage-number">Бизров Сергей Николаевич</li>
			<li class="EventsPage-number">Глушкова Анастасия Александровна</li>
			<li class="EventsPage-number">Деревцов Даниил Вячеславович</li>
			<li class="EventsPage-number">Елизарьев Михаил Константинович</li>
			<li class="EventsPage-number">Жуковский Роман Яковлевич</li>
			<li class="EventsPage-number">Заварухин Игорь Андреевич</li>
			<li class="EventsPage-number">Зимина Ирина Андреевна</li>
			<li class="EventsPage-number">Злобин Данил Сергеевич</li>
			<li class="EventsPage-number">Зуева Анастасия Игоревна</li>
			<li class="EventsPage-number">Исаков Руслан</li>
			<li class="EventsPage-number">Кокобелян Артур Павлович</li>
			<li class="EventsPage-number">Корольков Артём Дмитриевич</li>
			<li class="EventsPage-number">Макарова Анастасия Евгеньевна</li>
			<li class="EventsPage-number">Маненков Сергей Владимирович</li>
			<li class="EventsPage-number">Михайлов Марк Михайллвич</li>
			<li class="EventsPage-number">Огнев Иван Викторович</li>
			<li class="EventsPage-number">Орлов Владимир</li>
			<li class="EventsPage-number">Оширов Кирилл Александрович</li>
			<li class="EventsPage-number">Петкевич Степан Андреевич</li>
			<li class="EventsPage-number">Потапов Данил Максимович</li>
			<li class="EventsPage-number">Приходько Семён Андреевич</li>
			<li class="EventsPage-number">Романов Владимир Александрович</li>
			<li class="EventsPage-number">Рябцева Ксения Вячеславовна</li>
			<li class="EventsPage-number">Святненко Даниил Андреевич</li>
			<li class="EventsPage-number">Семёнов Егор Алексеевич</li>
			<li class="EventsPage-number">Сугаченко Иван Михайлович</li>
			<li class="EventsPage-number">Темников Павел Алексеевич</li>
			<li class="EventsPage-number">Труфанов Макар</li>
			<li class="EventsPage-number">Фисенко Константин Дмитриевич</li>
			<li class="EventsPage-number">Черкашин Дмитрий Андреевич</li>
			<li class="EventsPage-number">Чугунова Анна Андреевна</li>
			<li class="EventsPage-number">Шашков Дмитрий Олегович</li>
		</ol>
		<hr>
		<p class="EventsPage-text">
			<a class="EventsPage-link" href="https://cloud.mail.ru/public/ecB4/sf3WNWCNU">
				Справки-вызовы
			</a>
		</p>
		<hr>
		<p class="EventsPage-text">
			Примечание: Списочный состав участников смены сформирован экспертной комиссией на основании представленных данных о достижениях учащихся.
		</p>
		<hr>
		<h2 class="EventsPage-prof">
			Документы
		</h2>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/9pLM849LqwNyug" target="_blank">
			Сертификаты
		</a>
	</div>
</template>