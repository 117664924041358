<template>
	<div class="AdditionalProfessionalPrograms2023 _cover">
		<h2 class="AdditionalProfessionalPrograms2023 _cover-title">
			Перечень реализуемых дополнительные профессиональные программы 2023 года
		</h2>
		
		<!-- <details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение: </p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>

			<p class="AdditionalGneral-summary-dict"></p>
				<ul class="AdditionalGneral-summary-dict-list">
					<li class="AdditionalGneral-summary-dict-items"></li>
					<li class="AdditionalGneral-summary-dict-items"></li>
					<li class="AdditionalGneral-summary-dict-items"></li>
				</ul>

			<p class="AdditionalGneral-summary-dict"></p>
				<ul class="AdditionalGneral-summary-dict-list">
					<li class="AdditionalGneral-summary-dict-items"></li>
					<li class="AdditionalGneral-summary-dict-items"></li>
					<li class="AdditionalGneral-summary-dict-items"></li>
				</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин, календарный учебный график)
			</a>
		</details> -->

	</div>
</template>