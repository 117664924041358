<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			09.08.2023
		</p>
		<h2 class="NewsPage-title">
			В «СИРИУСЕ» ПОДВЕЛИ ИТОГИ НАУЧНО-ТЕХНОЛОГИЧЕСКОЙ ПРОГРАММЫ «БОЛЬШИЕ ВЫЗОВЫ»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage2.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				На федеральной территории «Сириус» подвели итоги восьмой научно-технологической программы «Большие вызовы». Она проходила с 1 по 24 июля 2023 года. Всего в программе приняли участие почти 400 школьников из 70 регионов страны.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали в региональном министерстве образования, участниками программы стали четыре школьника из Иркутской области: ученики Братского лицея №2 Тимофей Никонов и Андрей Запорожских, учащийся Нижнеудинской школы №48 Роман Дианов и ученица Шелеховского лицея Елизавета Пономарева.
			</p>
			<p class="NewsPage-sub-title">
				Они работали над проектами в трех направлениях: «Современная энергетика», «Агропромышленные и биотехнологии», «Генетика и биомедицина».
			</p>
			<p class="NewsPage-sub-title">
				В рамках работы направления «Современная энергетика» Андрей Запорожских вместе с командой разрабатывал проект по развитию Камчатского полуострова через строительство второй очереди Мутновской геотермальной электростанций. Школьник отметил, что сложность проекта была в том, что у России небольшой практический опыт в работе с геотермальными станциями (ГеоЭС), так как эта энергетическая отрасль в нашей стране только начала развиваться. Поэтому ребятам необходимо было продумать не только макет будущей ГеоЭС, но и рассчитать все факторы воздействия на окружающею среду и рассчитать самый экономически выгодный способ обеспечения полуострова энергией, а будущем и других регионов.
			</p>
			<p class="NewsPage-sub-title">
				В этом же направлении Роман Дианов искал ответ на вопрос, как снабжать электроэнергией береговые объекты с судна, оснащённого ядерной энергетической установкой. Как говорит Роман, главная задача была в том, чтобы продумать систему передачи энергии по подводным кабелям, так как судно не может подойти к берегу ближе, чем на 50 километров.
			</p>
			<p class="NewsPage-sub-title">
				Тимофей Никонов, участник в направлении «Агропромышленные и биотехнологии» решал задачу по поиску генетических маркеров устойчивости садовых роз к чёрной пятнистости. Целью было получить новые сора, которые будут устойчивы к грибковому заболеванию. А Елизавета Пономарева вместе с командой из направления «Генетика и биомедицина» стремилась к повышению чувствительности полимеразной цепной реакции с помощью праймеров с фосфорилгуанидиновыми модификациями. Которые в последствии смогут выявлять мутаций в генах, отвечающие за различные тяжёлые заболевания, например, онкологию.
			</p>
			<p class="NewsPage-sub-title">
				Программа завершилась грандиозным фестивалем проектов, на котором дети представили итоги своей трехнедельной работы в научных классах Образовательного центра и лабораториях Университета «Сириус» под руководством наставников – представителей крупнейших компаний, научных институтов и вузов России.
			</p>
			<p class="NewsPage-sub-title">
				– Школьники Приангарья демонстрируют высокий результат в научно-исследовательской деятельности не только в рамках региона, но и за его пределами. Они могут вносить свои вклад в развитие нашей страны. Примером того является конкурс научно-технологических проектов «Большие вызовы» и одноимённая программа. Мы очень рады, что наши дети, несмотря на их юный возврат, включаются в решение таких важных задач для России и становятся частью таких масштабных проектов, — отметил Алексей Шестаков, руководитель Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				Как сообщают специалисты Фонда «Талант и успех» и Научно-технологического университета «Сириус» после завершения программы работа по проектам не заканчивается. Многие предприятия берут созданные школьниками разработки на дальнейшее сопровождение, дорабатывают их и в том или ином виде внедряют на своих площадках. Кроме того, некоторые ребята получают возможность познакомиться с работой компаний изнутри, пройти стажировку и в дальнейшем стать частью их команды.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, программа «Большие вызовы» – один из главных научно-технологических проектов России. Его цель – включить школьников в работу над актуальными вызовами, стоящими перед страной, и обучать детей на примере реальных проектов, которые предлагают крупнейшие компании и вузы России.
			</p>
		</div>
	</div>
</template>
