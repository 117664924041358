<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			05.06.2024
		</p>
		<h2 class="NewsPage-title">
			<a class="NewsPage-link-source" href="https://irkobl.ru/sites/minobr/news/3672955/">
				Иркутские школьники вошли в число победителей Всероссийского конкурса проектов «Большие вызовы»
			</a>
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage64.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Школьники из Иркутской области вошли в число победителей и призеров девятого сезона Всероссийского конкурса
				научно-технологических проектов «Большие вызовы». Итоги подвели в Образовательном центре «Сириус» Фонда «Талант
				и успех» Федеральная территория Сириус.
			</p>
			<p class="NewsPage-sub-title">
				По итогам двух туров заключительного этапа в число победителей и призеров вошли три школьника из Иркутска: Артем
				Алексеенко из средней школы №19, ученица гимназии № 44 Мария Даниленко и ученик Лицея РЖД №14 Владимир Федосеев.
			</p>
			<p class="NewsPage-sub-title">
				В региональном министерстве образования сообщили, что от Иркутской области в заключительный этап прошли 19
				школьников. А всего от региона было подано более 40 заявок от ребят, которые определились по итогам
				регионального этапа, состоявшегося на площадке Регионального центра выявления и поддержки одаренных детей
				«Персей».
			</p>
			<p class="NewsPage-sub-title">
				– Ребята принимали участие в образовательных программах «Персея», созданного для координации работы с
				талантливой молодежью региона в рамках федерального проекта «Успех каждого ребенка» президентского национального
				проекта «Образование». В своих школах они ведут научно-исследовательскую деятельность под руководством
				педагогов-наставников, и участие в «Больших вызовах» – отличный шанс заявить о себе на всероссийском уровне и
				получить независимую оценку федеральных экспертов, – прокомментировал министр образования региона Максим
				Парфенов.
			</p>
			<p class="NewsPage-sub-title">
				По информации организаторов конкурса, Мария Даниленко и Владимир Федосеев стали победителями в направлениях
				«Нанотехнологии» и «Передовые производственные технологии». Мария представила проект по изучению и созданию
				наноструктурированных супергидрофобных покрытий, а Владимир работал над системой обнаружения вагона с
				неисправными тормозами. Артем Алексеенко стал призером конкурса в направлении «Экология и изучение изменений
				климата». Школьник представил проект по теме «Особенности накопления и распределения ртути в хвое в зависимости
				от ландшафтных и климатических условий Приморского хребта».
			</p>
			<p class="NewsPage-sub-title">
				Уже в июле школьники смогут встретиться на очной научно-технологической программе «Большие вызовы» в
				Образовательном центре «Сириус», где им предстоит под руководством лучших ученых и экспертов работать над
				решением задач от ведущих компаний и академических центров страны.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, Всероссийский конкурс научно-технологических проектов «Большие вызовы» – это мероприятие для молодежи,
				заинтересованной в разработке инновационных проектов и решении самых актуальных проблем современной жизни.
				Ежегодно его проводит Образовательный центр «Сириус» для старшеклассников и студентов, которые занимаются
				научной и исследовательской деятельностью с целью включить талантливых детей в работу с технологиями и научить
				их применять свой талант на практике в реальных проектах.
			</p>

		</div>
	</div>
</template>
