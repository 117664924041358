<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Спортивный туризм»
		</h2>
		<p class="EventsPage-date">
			с 06 сентября по 19 сентября 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21410-programma-sportivnyi-turizm%20" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				Егорова Юлия Сергеевна
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				89246361877
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				yu.egorova@perseusirk.ru
			</p>
		</div>
		<hr>
		<p class="EventsPage-sub-title">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребенка» проводит  профильную смену «Спортивный туризм» для обучающихся 8-11-х классов общеобразовательных организаций и учреждений дополнительного образования Иркутской области.<br>
			Профильная смена состоится с 09 сентября по 19 сентября 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны).<br>
			Программа направлена на создание условий для повышения уровня индивидуальных и групповых технико-тактических действий обучающихся, позволяющих в дальнейшем стать конкурентно способным в рамках сборной команды, а также развитие физических кондиций, позволяющих освоить технико-тактические действия дистанций 3-4 класса в природной среде.<br>
			Для участников, прошедших на профильную смену необходима обязательная регистрация в системе Навигатор дополнительного образования Иркутской области <a href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21410-programma-sportivnyi-turizm">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21410-programma-sportivnyi-turizm</a><br>
		</p>
		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-sub-title">
			Куксенко Леонид Михайлович, тренер-преподаватель МБУДО «ДЮСШ №1» г. Усолье-Сибирское, старший тренер сборной команды Иркутской области по спортивному туризму
		</p>
		<p class="EventsPage-sub-title">
			Михалев Семен Олегович, педагог дополнительного образования МАОУ ДО г. Иркутска "Дворец творчества"
		</p>
		<p class="EventsPage-sub-title">
			Сибирякова Оксана Владимировна, учитель физической культуры МБОУ г. Иркутска СОШ №24
		</p>
		<p class="EventsPage-sub-title">
			Фёдоров Александр Владимирович, заместитель начальника Юго-Западного поисково-спасательного отряда г. Ангарска ОГКУ Аварийно-спасательная служба Иркутской области
		</p>
		<p class="EventsPage-sub-title">
			Фёдорова Жанетта Владиславовна, педагог дополнительного образования МБОУ «СОШ №36» г. Ангарска
		</p>
		<h2 class="EventsPage-title">
			Документы:
		</h2>
		<ul class="EventsPage-list">
			<li class="EventsPage-items">
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/h5OxfSmkmfiQPQ/%C2%AB%D0%A1%D0%BF%D0%BE%D1%80%D1%82%D0%B8%D0%B2%D0%BD%D1%8B%D0%B9%20%D1%82%D1%83%D1%80%D0%B8%D0%B7%D0%BC%C2%BB%20">
					Ссылки на сертификаты
				</a>
			</li>
		</ul>
	</div>
</template>