<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			08.03.2024
		</p>
		<h2 class="NewsPage-title">
			В Приангарье подвели итоги регионального этапа Всероссийской олимпиады школьников
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage53.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Иркутской области подвели итоги регионального этапа ВсОШ, который проходил с января по февраль 2024 года. Участие в нем приняли порядка 1500 школьников. Состязания проходили по 23 предметам из 24-х предложенных (не проводилась олимпиада по итальянскому языку).
			</p>
			<p class="NewsPage-sub-title">
				Самыми массовыми предметами в этом году стали английский язык участие в нем приняли 188 школьников, ОБЖ – 96, русский язык – 85 и физика – 83 участника. А в число территорий с самым большим количеством участников вошел Иркутск, Ангарск, Братск и Усолье-Сибирское.
			</p>
			<p class="NewsPage-sub-title">
				Сопровождением олимпиадного движения в Иркутской области занимается Региональный центр выявления и поддержки одаренных детей «Персей», созданный в рамках федерального проекта «Успех каждого ребенка» президентского национального проекта «Образование».
			</p>
			<p class="NewsPage-sub-title">
				Как рассказал директор Образовательного центра «Персей» Алексей Шестаков, помимо курирования хода проведения олимпиады, «Персеем» были организованы традиционные профильные смены и дистанционные курсы по дополнительной подготовке к региональному этапу, участниками которых стали более тысячи школьников.
			</p>
			<p class="NewsPage-sub-title">
				На заключительном этапе будут определены победители и призеры всероссийской олимпиады школьников. Состязания завершатся 3 мая олимпиадой по Английскому языку.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, дипломы победителей и призеров заключительного этапа ВсОШ в течение четырех лет дают право ребятам поступить в любой вуз страны без экзаменов по соответствующему профилю. Кроме того, победители регионального этапа, а также победители и призеры заключительного этапа всероссийской олимпиады школьников при поступлении в вузы Иркутской области имеют право на ежемесячную денежную выплату в размере 5 тысяч рублей.
			</p>

		</div>
	</div>
</template>
