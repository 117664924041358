<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			27.05.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» завершилась подготовка к отборочному этапу Всероссийской телевизионной олимпиады «Умницы и умники»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage61.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В региональном центре выявления и поддержки одаренных детей «Персей» завершилась подготовка к отборочному
				этапу всероссийской гуманитарной телевизионной олимпиады школьников «Умницы и умники».
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали организаторы, подготовка проходила в формате дистанционного курса, участниками которого стали
				более 100 десятиклассников региона. Все ребята являются участниками регионального этапа всероссийской олимпиады
				школьников по русскому языку, литературе, обществознанию, праву, экономике, истории и иностранным языкам.
			</p>
			<p class="NewsPage-sub-title">
				В завершении обучения на дистанционном курсе школьники написали эссе и прошли тестирования, по результатам
				которых будет определены участники финала региональной игры.
			</p>
			<p class="NewsPage-sub-title">
				Региональный финал телеолимпиады пройдет в Иркутске 31 мая на площадке школы №19 г. Иркутска. Темой игры в этом
				году станет:«Декабристы в Сибири».
			</p>
			<p class="NewsPage-sub-title">
				По итогам интеллектуальных состязаний будут определены участники, которые отправятся в Москву на съёмки 33
				сезона программы «Умницы и умники» и будут бороться за главный приз – поступление в МГИМО без экзаменов.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, что в прошлом сезоне ученица школы № 4 г. Железногорск-Илимского Алёна Хрущева вошла в число
				финалистов 32 сезона всероссийской гуманитарной телевизионной олимпиады «Умницы и умники». А в 2023 году победу
				в этой же олимпиаде одержал выпускник Центра образования № 47 г. Иркутска Артем Селиверстов. Сейчас Артем учится
				в Московском государственном институте международных отношений на направлении «Зарубежное регионоведение».
			</p>

		</div>
	</div>
</template>
