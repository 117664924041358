<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Сибирские наигрыши»
		</h2>
		<p class="EventsPage-date">
			с 12 июля по 01 августа 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/21306-programma-sibirskie-naigryshi"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит еженедельные курсы по программе «Сибирские наигрыши» для учащихся детских
			музыкальных школ и детских школ искусств Иркутской области в возрасте с 10 до 17 лет.
		</p>

		<p class="EventsPage-text">
			Актуальность программы «Сибирские наигрыши» связана с обучением игре на русских народных инструментах на основе
			коллективного музыкального творчества, посредством участия в музыкальном коллективе в качестве его участника.
			Программа направлена на комплексное развитие ребёнка – изучения народных музыкальных традиций, исполнения
			различных произведений народного и академического репертуара ансамблей русских народных инструментов, расширение и
			развитие мышления и музыкального кругозора. Программа формирует устойчивый интерес к русским народным традициям, а
			также академическому исполнению на русских народных инструментах, уважительное отношение к русской культуре.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Онищенко Андрей Николаевич, преподаватель и концертмейстер, заведующий народным отделением МБУ ДО «Детская школа
				искусств №7» г. Иркутск.
			</li>
			<li class="JuniorProfi-items">
				Подкорытова Юлия Васильевна, преподаватель и концертмейстер ГБПОУ «Братское музыкальное училище»
			</li>
			<li class="JuniorProfi-items">

			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Для участников, прошедших отбор на курсы, необходима обязательная регистрация в системе Навигатор
				дополнительного образования Иркутской области по ссылке:
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21306-programma-sibirskie-naigryshi.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>