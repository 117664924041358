<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Введение в программирование на языке Java»
		</h2>
		<p class="EventsPage-date">
			30 сентября по 4 ноября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=180" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25486-programma-vvedenie-v-programmirovanie-na-yazyke-java"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Введение в программирование на языке Java» для
			учащихся 8-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа «Программирование на Java» ориентирована на обучение базисным навыкам программирования на языке Java.
			Заложенная в его рамках основа может быть использована как для дальнейшего развития навыков программиста, так и
			для изучения специализированных направлений, необходимых в будущей профессии.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 30 сентября 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 30 сентября 2024 года на платформе Moodle, Образовательного центра
				«Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>