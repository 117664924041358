<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			08.12.2023
		</p>
		<h2 class="NewsPage-title">
			В Приангарье подвели итоги чемпионата школьников компетенции ЮниорПрофи
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage33.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>Восьмой региональный чемпионат «ЮниорПрофи» завершился в Иркутской области. Соревнования проходили на площадке регионального центра выявления и поддержки одаренных детей «Персей».</b>
			</p>
			<p class="NewsPage-sub-title">
				По информации регионального министерства образования, в чемпионате приняли участие около 100 школьников и 50 наставников из Иркутска, Ангарска, Черемхово, Братска, Усолье-Сибирского, Усть-Илимска, Аларского, Иркутского, Братского, Заларинского, Куйтунского, Нукутского, Тайшетского, Тулунского, Усольского, Слюдянского и Шелеховского районов.
			</p>
			<p class="NewsPage-sub-title">
				Соревнования проходили в двух возрастных категориях: 10-13 лет и 14-17 лет в восьми компетенциях: «Прототипирование», «Инженерный дизайн», «Агрономия», «Сити-Фермерство», «Ветеринария», «Мобильная робототехника», «Мультимедиакоммуникации» и «Электроника». В каждой компетенции участвовали команды в составе двух человек.
			</p>
			<p class="NewsPage-sub-title">
				Как отмечают эксперты чемпионата, движение ЮниорПрофи способствуют подготовке нового поколения профессионалов, способных решать задачи для развития России, а сами конкурсные испытания – максимально приближенные к реальным производственным задачам.
			</p>
			<p class="NewsPage-sub-title">
				К примеру, команды по компетенции «Ветеринария» должны были создать проект бизнес-плана собственной ветклиники, а также провести клиническую диагностику животных, лабораторные исследования, подготовить операционную и проконсультировать по содержанию и лечению домашних питомцев.
			</p>
			<p class="NewsPage-sub-title">
				А в компетенции «Сити-фермерство» перед школьниками стояла задача по созданию гидропонной установки, которая осуществляет автоматическую систему полива. В этом году одной из самых востребованных компетенций стала «Мультимедиакоммуникации». В ней перед участниками стояла задача по информационному освещению чемпионата.
			</p>
			<p class="NewsPage-sub-title">
				По итогам конкурсных испытаний определились команды победителей следующим образом:
			</p>
			<p class="NewsPage-sub-title">
				В компетенции «Прототипирование» в возрастной категории «14+» победу одержали Алексей Николаев и Илья Сирин, представляющие команду из Лицея №11 РЖД Слюдянского района.
			</p>
			<p class="NewsPage-sub-title">
				Команда из Иркутской школы № 64, также привезет первое место в компетенции «Электроника 14+». Его получили: Арсений Караченцев и Степан Петкевич.
			</p>
			<p class="NewsPage-sub-title">
				А команда Братского лицея №2 в составе Анастасии Кузнецовой и Кристины Шутко: стала победителем в компетенции «Мультимедиакоммуникации 14+».
			</p>
			<p class="NewsPage-sub-title">
				В компетенции «Агрономия 14+» лучшей признана команда Детского эколого-биологического центра г. Черемхово в составе: Ильи Решетникова и Кристины Джавахишвили. А в компетенции «Ветеринария 14+» ученики Тыргетуйской школы Аларского района: Евгений Степанов и Альберт Сакадеев.
			</p>
			<p class="NewsPage-sub-title">
				В компетенции «Сити-фермерство 14+» в этом году победила команда от Икейской школы Тулунского района в лице: Владислава Рединова и Алены Лыткиной.
			</p>
			<p class="NewsPage-sub-title">
				Победители в компетенциях «Агрономия», «Ветеринария» и «Сити-фермерство» получили сертификаты на бесплатное обучение в колледже Иркутского аграрного университета по любому направлению.
			</p>
			<p class="NewsPage-sub-title">
				Команда Гимназии №1 имени А. А. Иноземцева г. Братска в составе: Леонида Щукина и Моногарова Виталия – победитель в компетенции «Инженерный дизайн 14+».
			</p>
			<p class="NewsPage-sub-title">
				Лучшими в компетенции «Мобильная робототехника 10+» признана команда из «Точки будущего» в лице: Максима Попова и Савелия Морозова.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, чемпионат проходит при поддержке министерства образования Иркутской области и фонда поддержки социальных инноваций «Вольное дело». В следующем году команды-победители смогут принять участие в Национальном чемпионате «ЮниорПрофи».
			</p>
			
			
		</div>
	</div>
</template>
