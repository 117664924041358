<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Развитие физических способностей при подготовке к ГТО»
		</h2>
		<p class="EventsPage-date">
			Обучение проводится с 27 сентября по 10 октября 2023
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/22283-programma-razvitie-fizicheskikh-sposobnostei-pri-podgotovke-k-gto" target="_blank">
			Регистрация
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
				<h3 class="EventsPage-nte">
					Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Развитие физических способностей при подготовке к ГТО» для обучающихся общеобразовательных организаций.<br>
					Программа смены направлена на развитие основных физических качеств, улучшения общей физической подготовки, улучшение техники выполнения нормативов ВФСК ГТО, знакомство с историей ВФСК ГТО.<br>
					Профильная смена состоится <b>с 27 сентября по 10 октября 2023 года</b>, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
				</h3>

				<hr>
				<p class="EventsPage-nte-dis">
					<b>Контактное лицо:</b> Егорова Юлия Сергеевна<br>
					<b>Телефон:</b> 89246361877<br>
					<b>Эл. почта:</b> yulcha_23@mail.ru<br>
				</p>
				<hr>

				<h2 class="EventsPage-prof">
					Документы
				</h2>
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/e9OFgtdbU4qkDA" target="_blank">
					Сертификаты
				</a>
		</div>
	</div>
</template>