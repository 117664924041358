<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			10.01.2024
		</p>
		<h2 class="NewsPage-title">
			Полторы тысячи обучающихся школ Приангарья примут участие в региональном этапе Всероссийской олимпиады школьников
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage40.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Иркутской области стартовал региональный этап всероссийской олимпиады школьников (ВсОШ). Участие в нем примут порядка 1500 старшеклассников, набравшие достаточное количество баллов во время муниципальных туров, которые состоялись в регионе в конце прошлого года.
			</p>
			<p class="NewsPage-sub-title">
				Министр образования Иркутской области Максим Парфенов рассказал, что олимпиада по французскому языку открыла серию интеллектуальных испытаний сегодня, 10 января. В этой дисциплине участвуют 28 школьников. Всего же региональный этап включает 23 предмета из предложенных 24-х (не проводится олимпиада по итальянскому языку).
			</p>
			<p class="NewsPage-sub-title">
				– Олимпиадные задания едины для всех участников. Они разработаны центральными предметно-методическими комиссиями по каждому общеобразовательному предмету. До конца недели пройдут олимпиады по искусству (мировая художественная культура) и астрономии. Завершится региональный этап всероссийской олимпиады школьников 28 - 29 февраля олимпиадой по основам безопасности жизнедеятельности, – сообщил министр.
			</p>
			<p class="NewsPage-sub-title">
				Глава регионального минобра также сообщил, что оператором всероссийской олимпиады школьников в Иркутской области является Региональный центр выявления и поддержки одаренных детей «Персей», созданный в рамках федерального проекта «Успех каждого ребенка» президентского национального проекта «Образование». Сопровождение олимпиадного движения в Иркутской области является одной из основных задач образовательного центра.
			</p>
			<p class="NewsPage-sub-title">
				Максим Парфенов подчеркнул, что по итогам региональных олимпиад будут определены участники, которые представят Приангарье на заключительном этапе всероссийской олимпиады школьников. Они должны набрать определённое количество баллов, установленное Министерством просвещения Российской Федерации для каждого предмета.
			</p>
			<p class="NewsPage-sub-title">
				По итогам заключительного этапа в 2023 году у Иркутской области один победитель и семь призеров – все они старшеклассники из школ Иркутска и Братска. Напомним, дипломы победителей и призеров заключительного этапа ВсОШ в течение четырех лет дают право ребятам поступить в любой вуз страны без экзаменов по соответствующему профилю.
			</p>
			<p class="NewsPage-sub-title">
				Кроме того, победители регионального этапа, а также победители и призеры заключительного этапа всероссийской олимпиады школьников при поступлении в вузы Иркутской области имеют право на ежемесячную денежную выплату в размере 5 тысяч рублей.
			</p>

			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(5).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(6).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(7).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(8).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(9).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage40(10).webp" alt="Картинка">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
