<template>
	<div class="Intelligence _cover">
		<div class="Intelligence-container">
			<div class="Intelligence-content">
				<h2 class="Intelligence-title _cover-title">
					О нас
				</h2>

				<div class="Intelligence-block grid-container">
					<div class="Intelligence-block-one-sub-title grid-item">
						<p class="Intelligence-sub-title">
							Региональный центр выявления и поддержки одаренных детей создан в Иркутской области в 2020 году в рамках федерального проекта «Успех каждого ребенка» национального проекта «Образование» по модели Образовательного центра «Сириус».
						</p>
					</div>
					<div class="Intelligence-block-one-sub-title grid-item">
						<img loading="lazy" class="Intelligence-block-one-img" src="../assets/gif/Pers.gif" alt="Карта Персей - Сириус">
					</div>
				</div>

				<p class="Intelligence-sub-title">
					До 2023 года Центр являлся структурным подразделением Государственного автономного учреждения дополнительного профессионального образования Иркутской области «Региональный институт кадровой политики и непрерывного профессионального образования».
				</p>
				<p class="Intelligence-sub-title">
					С декабря 2022 года Образовательный центр «Персей» является самостоятельной организацией (распоряжение Правительства Иркутской области от 15 ноября 2022 года №2 633-рп «О создании Государственного автономного нетипового учреждения дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Персей»).
				</p>

				<hr>

				<div class="Intelligence-block">
					<h3 class="Intelligence-block-two-titel _cover-title">
						Цель Образовательного центра
					</h3>
					<p class="Intelligence-sub-title">
						Цель Образовательного центра «Персей» — выявление, развитие и сопровождение талантливых детей и молодежи от 10 до 17 лет, проживающих на территории Иркутской области.
					</p>
				</div>

				<hr>

				<div class="Intelligence-block">
					<h3 class="Intelligence-block-two-titel _cover-title">
						Основными направлениями деятельности Центра являются:
					</h3>
					<p class="Intelligence-sub-title">
						1. Выявление проявляющих выдающиеся способности и высокомотивированных детей и молодежи, проживающих в регионе, в том числе координация, организация и проведение особо значимых мероприятий в области образования и науки, искусства, спорта.
					</p>
					<p class="Intelligence-sub-title">
						2. Разработка и реализация образовательных программ для проявивших выдающиеся способности и высокомотивированных детей и молодежи, в том числе с использованием дистанционных образовательных технологий и электронного обучения.
					</p>
					<p class="Intelligence-sub-title">
						3. Обеспечение индивидуальной работы с проявившими выдающиеся способности и высокомотивированными детьми и молодежью по формированию и развитию их познавательных интересов, построению индивидуальных образовательных маршрутов.
					</p>
					<p class="Intelligence-sub-title">
						4. Развитие партнерской сети из промышленных предприятий, научных и образовательных организаций, организаций культуры и спорта, общественных организаций, ведущих свою деятельность в регионе для обеспечения сопровождения и дальнейшего развития проявивших выдающиеся способности и высокомотивированных детей и молодежи.
					</p>
					<p class="Intelligence-sub-title">
						5. Информирование общественности о целях и задачах работы с проявившими выдающиеся способности и высокомотивированными детьми и молодежью, возможностях по развитию их талантов и способностей в регионе.
					</p>
				</div>

				<hr>

				<img loading="lazy" class="Intelligence-block-three-img" src="../assets/img/jpg/Directions.webp" alt="Направления">

				<hr>

				<div class="Intelligence-block">
					<h3 class="Intelligence-block-six-title _cover-title">
						Инфраструктура
					</h3>
					<p class="Intelligence-sub-title">
						<b>Площадки в&nbsp;г. Иркутске</b><br>На&nbsp;базе площадок реализуется обучение по&nbsp;дополнительным
						общеобразовательным программам.<br>Адреса площадок:<br>Г.&nbsp;Иркутск, Угольный проезд, д.
						68/1<br><b>Кампус
							образовательного центра &laquo;Персей&raquo;</b><br>Реализация профильных смен осуществляется на&nbsp;базе
						кампуса.<br>Адрес: Иркутская область, Ангарский район, 8.351&nbsp;км автодороги Ангарск-Тальяны.
					</p>
				</div>

				<div class="Intelligence-block">
					<ul class="grid-container">
						<li class="grid-item">
							<img loading="lazy" class="grid-item-img" src="../assets/img/jpg/OlyandCom1.webp" alt="Картинка">
						</li>
						<li class="grid-item">
							<img loading="lazy" class="grid-item-img" src="../assets/img/jpg/OlyandCom2.webp" alt="Картинка">
						</li>
						<li class="grid-item">
							<img loading="lazy" class="grid-item-img" src="../assets/img/jpg/OlyandCom3.webp" alt="Картинка">
						</li>
					</ul>
				</div>

				<div class="Intelligence-block-video-box">
					<div class="Intelligence-block-video">
						<iframe class="Intelligence-block-video-items" src="https://www.youtube.com/embed/aWMnD53e6TI?si=C6sLfU_C9pxqmqeU"
							title="YouTube video player" frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen>
							Презентационный ролик «Пересей»
						</iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>