<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			29.11.2023
		</p>
		<h2 class="NewsPage-title">
			В Иркутске школьников познакомили с проектной деятельностью
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage29.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>Более 20 обучающихся 8-10 классов 33 школы г. Иркутска стали участниками программы «Проектная деятельность в русском языке и литературе», которая прошла в ноябре этого года в формате еженедельных курсов.</b>
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали организаторы курса, особенностью программы стало то, что ученикам 8-9 классов было предложено познакомиться с литературным творчеством Толстого, Островского, Некрасова и Достоевского. На занятиях в течение двух недель ребята изучали основы проектной деятельности и читали произведения авторов. А по окончании курса представляли свои проекты и проводили интеллектуальные игры для десятиклассников.
			</p>
			<p class="NewsPage-sub-title">
				– Идея создания этой программы возникла неслучайно. Десятиклассники, закончив изучение творчества Тургенева, разработали и провели интеллектуальную игру. За идею они взяли всем известную гуманитарную телевизионную олимпиаду «Умницы и умники». После этого мы предложили включиться в проект ученикам 8 и 9 класса. Изначально перед ребятами стояла задача изготовить ростовые фигуры писателей, но идея их настолько увлекла, что ребята захотели разработать свои проекты, – поделилась Ольга Юрьева, заместитель директора 33 школы г. Иркутска.
			</p>
			<p class="NewsPage-sub-title">
				Специалисты Образовательного центра «Персей» отмечают, что с каждым годом все больше школьников Приангарья включаются в проектную деятельность. Программы, которые реализуются в центре по проектному обучению, помогают учащимся приобретать новые навыки и компетенции, обобщать и систематизировать имеющиеся у них знания, а также давать опыт продуктивной деятельности.
			</p>
			<p class="NewsPage-sub-title">
				– Проектные программы «Персея» набирают все большую популярность среди школьников. По сравнению с прошлым годом количество программ, на которых ребята работают над созданием продукта увеличилось в два раза. В ближайших планах Образовательного центра проведение еженедельных курсов для школ Иркутска и близлежащих городов по проектной деятельности в предложенных ими областях. Мы готовы поддерживать идей и помогать школам выявлять талантливых и одаренных детей, используя все ресурсы, которые у нас есть, – подчеркнул директор Образовательного центра Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Продолжить работу над проектами школьники могут на очных профильных сменах в кампусе Образовательного центра под Ангарском.
			</p>
			<p class="NewsPage-sub-title">
				<a class="NewsPage-link" href="https://perseusirk.ru/ProfileBlock">Подробнее на сайте: https://perseusirk.ru/ProfileBlock</a>
			</p>

			
		</div>
	</div>
</template>
