<template>
	<div class="AdditionalProfessionalPrograms _cover">
		<h2 class="AdditionalProfessionalPrograms-title _cover-title">
			Дополнительные профессиональные программы (повышения квалификации)
		</h2>

		<ul class="AdditionalGneral-list">
			<li class="AdditionalGneral-list-items">
				<router-link to="/AdditionalProfessionalPrograms2023" class="AdditionalGneral-list-items-link">
					2023 год
				</router-link>
			</li>
			<li class="AdditionalGneral-list-items">
				<router-link to="/AdditionalProfessionalPrograms2024" class="AdditionalGneral-list-items-link">
					2024 год
				</router-link>
			</li>
		</ul>
	</div>
</template>