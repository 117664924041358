<template>
	<div class="BestStudentPage _cover">
		<h2 class="BestStudentPage-title _cover-title">
			Лучший ученик года
		</h2>

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/UGLogo.webp" alt="Логотип" />
		</div>

		<p class="JuniorProfi-sub-title" style="margin-bottom: 10px;">
			Задачи конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				выявление и поощрение наиболее активных и творческих обучающихся,
			</li>
			<li class="JuniorProfi-items">
				создание условий для самореализации и раскрытия творческого потенциала обучающихся,
			</li>
			<li class="JuniorProfi-items">
				стимулирование общественной, творческой и познавательной активности обучающихся.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title" style="margin-bottom: 10px;">
			Участники:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				В муниципальном этапе Конкурса на добровольной основе принимают участие обучающиеся 8-10-х классов
				образовательных организаций Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				В региональном этапе конкурса принимают участие победители муниципального этапа конкурса.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title" style="margin-bottom: 10px;">
			Формат проведения регионального этапа конкурса:
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Региональный этап пройдет очно, на базе кампуса Образовательного центра «Персей» по адресу: Иркутская область,
			Ангарский район, 8,321 км автодороги Ангарск-Тальяны.
		</p>

		<p class="JuniorProfi-sub-title" style="margin-bottom: 10px;">
			Условия участия:
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Положения конкурса и условия участия будут опубликованы в начале 2025 г.
		</p>

		<p class="JuniorProfi-sub-title" style="margin-bottom: 10px;">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>
	</div>

	<!-- Архив -->
	<ArchiveBestStudent />

</template>

<script>
import ArchiveBestStudent from '@/components/ArchiveBestStudent.vue';

export default {
	components: {
		ArchiveBestStudent,
	}
}

</script>