<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">Олимпиада</h3>
		<h2 class="EventsPage-title">«Золотой фонд Сибири» 2022-2023</h2>
		<a class="EventsPage-btn temp-btn" href="https://irk-olimp.ru/" target="_blank"> Подробнее </a>
		<hr />

		<p class="JuniorProfi-sub-title">Цели и задачи Олимпиады:</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">выявление и создание условий для поддержки одаренных обучающихся;</li>
			<li class="JuniorProfi-items">развития творческой инициативы и интереса к образовательному процессу;</li>
			<li class="JuniorProfi-items">развитие общеобразовательного кругозора учащихся;</li>
			<li class="JuniorProfi-items">пропаганда научных знаний;</li>
			<li class="JuniorProfi-items">привлечение внимания обучающихся к научной (научно-исследовательской) деятельности, к изучению технических, естественно-научных, социально-экономических, политических, правовых и культурных аспектов развития современного мира;</li>
			<li class="JuniorProfi-items">содействие воспитанию ценностных ориентаций у обучающихся;</li>
			<li class="JuniorProfi-items">создание комфортного мотивационного пространства для будущего профессионального выбора.</li>
		</ul>
		<hr />

		<p class="JuniorProfi-sub-title">Документы:</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/08/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0-%D0%97%D0%BE%D0%BB%D0%BE%D1%82%D0%BE%D0%B8%CC%86-%D1%84%D0%BE%D0%BD%D0%B4-%D0%A1%D0%B8%D0%B1%D0%B8%D1%80%D0%B8.pdf" target="_blank"> Положение Олимпиада Золотой фонд Сибири </a>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2022/08/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-%D0%97%D0%A4%D0%A1_2019.pdf" target="_blank"> Распоряжение об утверждении положения ЗФС_2019 </a>
		<hr />

		<p class="JuniorProfi-sub-title">Для кого предназначена ЗФС</p>
		<p class="EventsPage-text">К участию в олимпиаде приглашаются школьники 8-11 классов, а также студенты колледжей (среднее профессиональное образование).</p>
		<p class="EventsPage-text">
			<b>Участие в олимпиадах свободное и бесплатное!!!</b>
		</p>
		<hr />

		<p class="JuniorProfi-sub-title">Что дает ЗФС</p>
		<p class="EventsPage-text">Победители получают возможность государственной поддержки в виде денежных стипендий.</p>
		<p class="EventsPage-text">Победители и призеры получают уникальную возможность получить образовательные гранты.</p>
		<p class="EventsPage-text">Победители и призеры зарабатывают дополнительные одинаковые баллы к сумме ЕГЭ при поступлении в любой вуз-организатор (баллы за индивидуальные достижения).</p>
		<hr />

		<p class="JuniorProfi-sub-title">Об участии в Олимпиаде</p>
		<p class="EventsPage-text">Отборочный (дистанционный) этап. Отборочный этап проходит на сайте irk-olimp.ru. Состязания отборочного этапа олимпиад проходят в дистанционной форме согласно срокам, указанным в каждом Порядке проведения олимпиады.</p>
		<p class="EventsPage-text">Заключительный (очный) этап. К участию в заключительном этапе приглашаются победители и призеры отборочного этапа. Заключительный этап проходит очно на площадке проведения вуза-организатора Олимпиады.</p>
		<hr />

		<p class="JuniorProfi-sub-title">Учередитель олимпиады:</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">Министерство образования Иркутской области</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Ангарский государственный технический университет»</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Байкальский государственный университет»</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Иркутский государственный аграрный университет им. А.А. Ежевского»</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Иркутский государственный медицинский университет» Минздрава России</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Иркутский государственный университет путей сообщения»</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</li>
			<li class="JuniorProfi-items">ФГБОУ ВО «Иркутский государственный университет»</li>
			<li class="JuniorProfi-items">Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"</li>
		</ul>
		<hr>

		<table>
			<caption>
				Список олимпиад в рамках открытой региональной межвузовской олимпиады обучающихся «Золотой фонд Сибири» в 2022-2023 учебном году
			</caption>
			<thead align="center">
				<tr>
					<th>№ п/п</th>
					<th>Наименование ВУЗа</th>
					<th>Наименование олимпиады</th>
					<th>Срок проведения отборочного этапа</th>
					<th>Срок проведения заключительного этапа</th>
				</tr>
			</thead>
			<tbody align="center">
				<tr>
					<td>1.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Олимпиада по зарубежному страноведению и иностранным языкам</td>
					<td>05.11.2022-10.02.2023 г.</td>
					<td>17.02.2023</td>
				</tr>
				<tr>
					<td>2.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Финансовая грамотность для школьников</td>
					<td>10.11.2022-17.03.2023 г.</td>
					<td>24.03.2023 г.</td>
				</tr>
				<tr>
					<td>3.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Финансовая грамотность для студентов СПО</td>
					<td>01.11.2022-10.03.2023 г.</td>
					<td>17.03.2023 г.</td>
				</tr>
				<tr>
					<td>4.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Молодой бухгалтер</td>
					<td>01.11.2022-10.03.2023 г.</td>
					<td>22.03.2023 г.</td>
				</tr>
				<tr>
					<td>5.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Олимпиада по экономике и основам предпринимательства</td>
					<td>05.01.2023-11.03.2023 г.</td>
					<td>18.03.2023 г.</td>
				</tr>
				<tr>
					<td>6.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Олимпиада по экономике и основам предпринимательства для студентов СПО</td>
					<td>05.01.2023-17.03.2023 г.</td>
					<td>24.03.2023 г.</td>
				</tr>
				<tr>
					<td>7.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Лингвострановедческая олимпиада по иностранным языкам</td>
					<td>01.11.2022-18.02.2023 г.</td>
					<td>18.03.2023 г.</td>
				</tr>
				<tr>
					<td>8.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Олимпиада по экономической безопасности: безопасность бизнеса и личности</td>
					<td>04.01.2023-24.03.2023 г.</td>
					<td>01.04.2023 г.</td>
				</tr>
				<tr>
					<td>9.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>География Иркутской области</td>
					<td>01.02.2023-08.03.2023 г.</td>
					<td>16.03.2023 г.</td>
				</tr>
				<tr>
					<td>10.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Конституция – основной закон Российской Федерации</td>
					<td>01.12.2022-28.02.2023 г.</td>
					<td>18.03.2023 г.</td>
				</tr>
				<tr>
					<td>11.</td>
					<td>ФГБОУ ВО «Байкальский государственный университет»</td>
					<td>Олимпиада по таможенному делу</td>
					<td>05.01.2023-31.03.2023 г.</td>
					<td>07.04.2023 г.</td>
				</tr>
				<tr>
					<td colspan="3"><b>Итого БГУ</b></td>
					<td colspan="3"><b>11 олимпиад</b></td>
				</tr>
				<tr>
					<td>12.</td>
					<td>Иркутский Государственный Медицинский университет</td>
					<td>Химия для медицины</td>
					<td>15.01.2023-30.01.2023 г.</td>
					<td>12.02.2023 г.</td>
				</tr>
				<tr>
					<td>13.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Юный биолог</td>
					<td>01.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td>14.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Математический лидер</td>
					<td>01.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td>15.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Обществознание</td>
					<td>01.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td>16.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Я – успешный оратор</td>
					<td>01.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td>17.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Физика</td>
					<td>1.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td>18.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Экономика</td>
					<td>1.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td>19.</td>
					<td>ФГБОУ ВО Иркутский ГАУ имени А.А. Ежевского</td>
					<td>Информатика и информационные технологии</td>
					<td>1.02.2023-13.03.2023 г.</td>
					<td>25.03.2023 г.</td>
				</tr>
				<tr>
					<td colspan="3"><b>Итого Иркутский ГАУ имени А.А. Ежевского</b></td>
					<td colspan="2"><b>7 олимпиад</b></td>
				</tr>
				<tr>
					<td>20.</td>
					<td>ЧОУ Школа-интернат №23 среднего общего образования ОАО «РЖД» при поддержке ИрГУПС</td>
					<td></td>
					<td>17.01.2023 г.</td>
					<td>10.02.2023 г.</td>
				</tr>
				<tr>
					<td>21.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет путей сообщения»</td>
					<td>Инженерная и компьютерная графика</td>
					<td>06.02.2023-06.03.2023 г.</td>
					<td>17.03.2023 г.</td>
				</tr>
				<tr>
					<td>22.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет путей сообщения»</td>
					<td>«Ты – в бизнесе!»</td>
					<td>01.12.2022-31.01.2023 г.</td>
					<td>17.03.2023 г.</td>
				</tr>
				<tr>
					<td>23.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет путей сообщения»</td>
					<td>Олимпиада по информационным технологиям</td>
					<td>06.02.2023-05.03.2023 г.</td>
					<td>13.03.2023 г.</td>
				</tr>
				<tr>
					<td>24.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет путей сообщения»</td>
					<td>Конкурс Компьютерной графики и дизайна</td>
					<td>06.02.2023-05.03.2023 г.</td>
					<td>15.03.2023 г.</td>
				</tr>
				<tr>
					<td colspan="3"><b>Итого ИРГУПС</b></td>
					<td colspan="2"><b>5 олимпиад</b></td>
				</tr>
				<tr>
					<td>25.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по математике Очно/Заочно</td>
					<td>20.12.2022-12.02.23 г.</td>
					<td>09.04.2023 г.</td>
				</tr>
				<tr>
					<td>26.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по физике Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>08.04.2023 г.</td>
				</tr>
				<tr>
					<td>27.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по химии Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>01.04.2023 г.</td>
				</tr>
				<tr>
					<td>28.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по информатике Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>02.04.2023 г.</td>
				</tr>
				<tr>
					<td>29.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по техническому черчению Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>04.04.2023 г.</td>
				</tr>
				<tr>
					<td>30.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по 3D моделированию инженерных объектов Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>06.04.2023 г.</td>
				</tr>
				<tr>
					<td>31.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по современной энергетике Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>11.04.2023 г.</td>
				</tr>
				<tr>
					<td>32.</td>
					<td>ФГБОУ ВО «Иркутский национальный исследовательский технический университет»</td>
					<td>Межпредметная олимпиада школьников «Политехник» по современному самолетостроению Очно/Заочно</td>
					<td>20.12.2022-12.02.2023 г.</td>
					<td>13.04.2023</td>
				</tr>
				<tr>
					<td colspan="3"><b>Итого ИРНИТУ</b></td>
					<td colspan="2"><b>8 олимпиад</b></td>
				</tr>
				<tr>
					<td>33.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Географический факультет</td>
					<td>Водные ресурсы Земли</td>
					<td>24.10.2022-28.11.2022 г.</td>
					<td>19.12.2022</td>
				</tr>
				<tr>
					<td>34.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Географический факультет</td>
					<td>Климат нашей планеты: прошлое, настоящее, будущее</td>
					<td>24.10.2022-28.11.2022 г.</td>
					<td>19.12.2022</td>
				</tr>
				<tr>
					<td>35.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Географический факультет</td>
					<td>Экологические аспекты безопасности жизнедеятельности</td>
					<td>24.10.2022-28.11.2022 г.</td>
					<td>19.12.2022</td>
				</tr>
				<tr>
					<td>36.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Факультет бизнес-коммуникаций и информатики</td>
					<td>Бизнес-коммуникации</td>
					<td>14.11.2022-28.11.2022 г.</td>
					<td>03.12.2022 г.</td>
				</tr>
				<tr>
					<td>37.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Институт социальных наук</td>
					<td>Олимпиада по обществознанию «Лестница успеха»</td>
					<td>13.02.2023-27.02.2023 г.</td>
					<td>27.03.2023 г.</td>
				</tr>
				<tr>
					<td>38.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Педагогический институт</td>
					<td>Олимпиада по английскому языку для школьников «Навстречу миру»</td>
					<td>09.01.2023-07.02.2023 г.</td>
					<td>10.02.2023 г.</td>
				</tr>
				<tr>
					<td>39.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Биолого-почвенный факультет</td>
					<td>Роль почв в современном мире</td>
					<td>13.03.2023-17.03.2023 г.</td>
					<td>21.04.2023 г.</td>
				</tr>
				<tr>
					<td>40.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Факультет психологии</td>
					<td>«Юная Психея»</td>
					<td>23.01.2023-19.02.2023 г.</td>
					<td>21.03.2023 г.</td>
				</tr>
				<tr>
					<td>41.</td>
					<td>ФГБОУ ВО «Иркутский государственный университет», Юридический институт</td>
					<td>Правовые субботы</td>
					<td>06.03.2023-06.04.2023 г.</td>
					<td>15.04.2023 г.</td>
				</tr>
				<tr>
					<td colspan="3"><b>Итого ИГУ</b></td>
					<td colspan="2"><b>9 олимпиад</b></td>
				</tr>
			</tbody>
		</table>

	</div>
</template>
