<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			28.06.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» прошла профильная смена «Выбор Первых – ЗОЖ» в рамках летней оздоровительной кампании
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage69.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» завершилась летняя оздоровительная смена для 55 воспитанников специальных
				коррекционных школ-интернатов из Ангарска, Саянска и п.Целинные Земли. Организаторы смены – «Движение Первых»
				Иркутской области и Образовательный центр «Персей». Программа вошла в цикл летних профильных смен «Движения
				первых», которые проходят по всей стране.
			</p>
			<p class="NewsPage-sub-title">
				В этом году темой смены стал – здоровый образ жизни. По словам сотрудников «Движения Первых» – это одна из
				ценностей, которую активно продвигают ребята будучи частью Движения.
			</p>
			<p class="NewsPage-sub-title">
				Дети активно участвовали в различных спортивных мероприятиях, тренингах и мастер-классах, которые помогли им
				лучше понять, что такое здоровый образ жизни и почему он так важен. Они узнали о значении правильного питания,
				регулярных физических упражнений и психического благополучия.
			</p>
			<p class="NewsPage-sub-title">
				Кроме того, большое внимание уделялось воспитанию моральных и этических ценностей. Участники смены познакомились
				с принципами честности, справедливости и уважения друг к другу, что является основой философии «Первых». Эти
				занятия помогли детям понять, как важно быть ответственными, поддерживать друг друга и стремиться к личному и
				коллективному успеху.
			</p>
			<p class="NewsPage-sub-title">
				Интерактивные лекции и игровые тренинги позволили участникам смены не только получить новые знания, но и сразу
				же применить их на практике. Например, в ходе встречи, приуроченной к Дню русского языка, активисты школы
				жестового языка «Без границ. Байкал» научили ребят правильно показывать буквы с помощью дактиля (прим.ред.
				жестовая речь), а также базовым жестам, которые можно использовать при знакомстве с глухонемым человеком.
			</p>
			<p class="NewsPage-sub-title">
				Одним из ярких событий смены стали фотофестиваль, турнир по игре «Лазертаг», спартакиады, чемпионаты по
				настольному теннису, шашкам и шахматам, эко-конкурсы, а также встречи с интересными людьми.
			</p>
			<p class="NewsPage-sub-title">
				Среди гостей смены были сотрудники Ангарской государственной инспекции безопасности дорожного движения, штаба
				вожатых, медиаспециалисты и проектный отдел «Движения Первых», а также заместитель председателя совета
				регионального отделения Александр Неверов.
			</p>
			<p class="NewsPage-sub-title">
				Также участники смены встретились со специалистами отдела по работе с СПО, Молодежного крыла Народного фронта,
				российского детско-юношеского общественного движения «Юнармия» и командой Всероссийского проекта «Навигаторы
				Детства», а также с представителем «Волонтеров победы» – Иваном Комельковым.
			</p>
			<p class="NewsPage-sub-title">
				Также ребята в свободное время посещали клубы по интересам. В них школьники смогли развивать свои таланты и
				увлечения, находить новых друзей и получать удовольствие от занятий, которые им действительно нравятся.
			</p>
			<p class="NewsPage-sub-title">
				Клубы были разнообразными: от спортивной до творческой деятельности. Участники знакомились с основами здорового
				образа жизни, прокачивали физическую подготовку, танцевали, пели и занимались прикладным творчеством. К примеру,
				в клубе любителей природы изучали окружающую среду, узнавали о редких растениях и животных, а также учились
				бережному отношению к природе.
			</p>
			<p class="NewsPage-sub-title">
				Дети совершали познавательные экскурсии, проводили исследования местных экосистем и участвовали в проектах по
				охране окружающей среды. В ходе этих занятий школьники научились основам экологического мышления, узнали о
				значении сохранения биоразнообразия и о способах защиты природных ресурсов.
			</p>
			<p class="NewsPage-sub-title">
				– Профильная смена «Выбор Первых – ЗОЖ» стала уникальной площадкой для развития юных талантов и лидеров. Здесь
				наши ребята не только получили ценные знания и навыки, но и научились работать в команде, развили свои
				творческие способности, а также обрели активную гражданскую позицию. Мы уверены, что такие смены помогают
				воспитывать новое поколение, готовое к переменам и способное вносить значительный вклад в будущее нашего региона
				и страны в целом, – отметил председатель Иркутского отделения «Движение Первых» Сергей Перфильев.
			</p>
			<p class="NewsPage-sub-title">
				Сотрудники Образовательного центра «Персей» убеждены, что приобретенные знания и навыки помогут детям вести
				здоровый и активный образ жизни в будущем, а также стать настоящими лидерами, способными вдохновлять других на
				позитивные изменения.
			</p>
			<p class="NewsPage-sub-title">
				22 июня состоялось торжественное закрытие летней оздоровительной смены в Образовательном центре «Персей» для
				воспитанников специальных коррекционных школ-интернатов Приангарья. Это четвертая смена, которая прошла на базе
				кампуса «Персея», и вторая, которая была реализована совместно с Региональным отделением «Движения Первых».
			</p>

		</div>
	</div>
</template>
