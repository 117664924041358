<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«ПОГРУЖЕНИЕ ВНУТРЬ: ОСНОВЫ АНАТОМИИ ЧЕЛОВЕКА»
		</h2>
		<p class="EventsPage-date">
			с 16 октября по 11 декабря 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит дистанционный курс «Погружение внутрь: основы анатомии человека» для обучающихся 8-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа курса позволит учащимся изучить взаимосвязи между различными системами организма человека, научится распознавать и управлять процессами, связанные с основными гомеостатическими константами, а также сконцентрировать свое внимание на нарушения в деятельности различных систем.
		</p>
		<p class="EventsPage-text">
			Дистанционный курс состоится на бесплатной основе.
		</p>
		<hr>

		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
			<a class="EventsPage-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21973-programma-pogruzhenie-vnutr-osnovy-anatomii-cheloveka">
				Подать заявку в Навигаторе.
			</a>
			<a class="EventsPage-link" href=" https://moodle.perseusirk.ru/course/view.php?id=105">
				Зарегистрироваться и заполнить анкету в системе Moodle Образовательного Центра «Персей
			</a>
			Обучение будет проводиться в очно-заочной форме. Расписание занятий, материалы курса, ссылки на онлайн-подключения будут размещены на странице курса в системе Moodle Образовательного Центра «Персей»

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/GcAdsMCEFRuGpQ" target="_blank">
			Сертификаты
		</a>
	
	</div>
</template>