<template>
	<div class="GoldenFundPage _cover">
		<h2 class="GoldenFundPage-title _cover-title">
			Открытая региональная межвузовская олимпиада обучающихся Иркутской области<br> "Золотой фонд Сибири"
		</h2>

		<div class="DirectionBlock-img-box" style="width: 150px;">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/ZFSLogo.webp" alt="Наука" />
		</div>


		<a class="GoldenFundOfSiberia-btn temp-btn" href="https://irk-olimp.ru/">
			Сайт олимпиады
		</a>
		<hr>

		<h3 class="GoldenFundOfSiberia-title _cover-title">
			Учредитель олимпиады
		</h3>
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://irkobl.ru/sites/minobr/olimpiada/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/GoldenFundOfSiberia/лого-министерство.webp"
							alt="Логотип Министерство образования Иркутской области">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						Министерство образования Иркутской области
					</p>
				</div>
			</div>
		</div>

		<!-- Вузы -->
		<div class="grid-container">
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://bgu.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/2-1.webp"
							alt="Logo">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Байкальский государственный университет»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://irsau.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/3-1.webp"
							alt="Logo">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный аграрный университет им. А.А. Ежевского»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://isu.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/GoldenFundOfSiberia/WhatsApp-Image-2022-08-30-at-13.29.16.webp" alt="Logo">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный университет»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://irkgmu.ru/ismu/news.php">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/4.webp"
							alt="Logo">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный медицинский университет» Минздрава России
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://www.irgups.ru/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img" src="../assets/img/GoldenFundOfSiberia/logo.webp"
							alt="Logo">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский государственный университет путей сообщения»
					</p>
				</div>
			</div>
			<div class="grid-element">
				<div class="GoldenFundOfSiberia-utc">
					<a class="GoldenFundOfSiberia-utc-link" href="https://www.istu.edu/">
						<img loading="lazy" class="GoldenFundOfSiberia-utc-img"
							src="../assets/img/GoldenFundOfSiberia/WhatsApp-Image-2022-08-30-at-13.28.15.webp" alt="Logo">
					</a>
					<p class="GoldenFundOfSiberia-utc-dist">
						ФГБОУ ВО «Иркутский национальный исследовательский технический университет»
					</p>
				</div>
			</div>
		</div>

		<p class="JuniorProfi-sub-title">
			Цели олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				выявление и создание условий для поддержки одаренных обучающихся,
			</li>
			<li class="JuniorProfi-items">
				развитие творческой инициативы и интереса к образовательному процессу,
			</li>
			<li class="JuniorProfi-items">
				привлечение внимания обучающихся к научной деятельности, к изучению технических, естественно-научных,
				социально-экономических, политических, правовых и культурных аспектов развития современного мира,
			</li>
			<li class="JuniorProfi-items">
				создание комфортного мотивационного пространства для будущего профессионального выбора.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				В олимпиаде могут принять участие обучающиеся общеобразовательных организаций 8-11 классов и студенты
				колледжей и техникумов.
			</li>
			<li class="JuniorProfi-items">
				Обучающийся записывается на олимпиаду самостоятельно.
			</li>
			<li class="JuniorProfi-items">
				Требования к классу обучения могут быть различными для каждой из олимпиад. Подробнее о требованиях к участию и
				сроках проведения вы можете посмотреть в Порядке проведения олимпиады.
			</li>
			<li class="JuniorProfi-items">
				Участвовать можно в любом количестве олимпиад.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Регистрация на олимпиаду:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Ознакомьтесь с порядком проведения олимпиады. Узнайте сроки регистрации, даты проведения отборочного и
				заключительного этапов.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрируйтесь на сайте проведения олимпиады (в правом верхнем углу страницы «вход» – «регистрация»).
			</li>
			<li class="JuniorProfi-items">
				Запишитесь на курс олимпиады и заполните анкету участника.
			</li>
			<li class="JuniorProfi-items">
				Следуйте Порядку проведения олимпиады и инструкциям на курсе.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Этапы участия
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Отборочный этап. Обычно проводится в дистанционной форме на сайте Олимпиады. Может быть проведен в очном
				форме.
			</li>
			<li class="JuniorProfi-items">
				Заключительный этап. Проводится в очной форме или очной с применением дистанционных технологий на базе
				вуза-организатора.
			</li>
			<li class="JuniorProfi-items">
				Подробнее о каждом этапе смотрите в Порядке проведения Олимпиады.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Преимущества участия
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Победителям, призерам и участникам заключительного этапа олимпиады при приеме на обучение по образовательным
			программам в вузы-организаторы олимпиады начисляются дополнительные баллы за индивидуальные достижения:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Победителям Олимпиады – 10 баллов,
			</li>
			<li class="JuniorProfi-items">
				Призерам олимпиады II степени – 8 баллов,
			</li>
			<li class="JuniorProfi-items">
				Призерам олимпиады III степени – 6 баллов,
			</li>
			<li class="JuniorProfi-items">
				Участникам заключительного этапа олимпиады – 3 балла.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Перечень олимпиад
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Перечень олимпиад на 2024-2025 учебный год будет опубликован в сентябре 2024 г.
		</p>

		<p class="JuniorProfi-sub-title">
			Контакты
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Учредитель олимпиады: Министерство образования Иркутской области.
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Ответственное лицо Учредителя: Хайрулина Ольга Викторовна, начальник управления профессионального образования.
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Оператор Олимпиады: Образовательный Центр «Персей».
		</p>
		<p class="GoldenFundOfSiberia-sub-title">
			Ответственное лицо Оператора: Гавриленко Татьяна Георгиевна, заведующий отдела сопровождения олимпиадного и
			конкурсного движения школьников.
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Координаторы олимпиады от вузов-организаторов:
		</p>

		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>ФГОУ ВО «ИГУ»</td>
					<td>Баева Дарья Евгеньевна,
						ответственный секретарь центральной приемной комиссии</td>
					<td>8 (3952) 521-555, <br>
						8 (3952) 521-777</td>
					<td>priem@isu.ru</td>
				</tr>
				<tr>
					<td>ФГОУ ВО «БГУ»</td>
					<td>Образцова Маргарита Сергеевна,
						зам. начальника УЦПК</td>
					<td>8 (3952) 5-0000-8 доб. 384, <br>
						8 (950) 133-57-08</td>
					<td>ObraztsovaMS@bgu.ru, <br>
						priem@bgu.ru</td>
				</tr>
				<tr>
					<td>ФГОУ ВО «ИРНИТУ»</td>
					<td>Хадкевич Наталия Алексеевна,
						директор Центра профессиональной ориентации и довузовской подготовки</td>
					<td>8 (3952) 40-50-88</td>
					<td>bguep2015@yandex.ru</td>
				</tr>
				<tr>
					<td>ФГОУ ВО «ИРГУПС»</td>
					<td>Исаева Наталья Николаевна,
						начальник Малой транспортной академии</td>
					<td>8(3952) 638-399, доб. 0511</td>
					<td>omta@irgups.ru <br>
						isaeva_nn@irgups.ru</td>
				</tr>
				<tr>
					<td>ФГОУ ВО «ИРГАУ»</td>
					<td>Шарапиева Ирина Геннадьевна,
						начальник управления по социальным вопросам и связям с общественностью</td>
					<td>8 (3952) 237-204</td>
					<td>press@irsau.ru</td>
				</tr>
			</table>
		</div>

	</div>

	<!-- Архив -->
	<ArchiveGoldenFund />

</template>

<script>
import ArchiveGoldenFund from '@/components/ArchiveGoldenFund.vue';

export default {
	components: {
		ArchiveGoldenFund,
	}
}

</script>