<template>
	<div class="Trustees _cover">
		<div class="Trustees-container">
			<div class="Trustees-content">
				<h2 class="Trustees-title _cover-title">
					Попечительский совет
				</h2>
				<h3 class="Trustees-sub-title">
					Состав попечительского совета при Губернаторе Иркутской области по&nbsp;выявлению и&nbsp;поддержке одаренных
					детей в&nbsp;Иркутской области
				</h3>
				<div class="grid-container">
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/КобзевИгорьИванович.webp"
							alt="Кобзев Игорь Иванович">
						<h4 class="Trustees-list-items-title">
							Кобзев Игорь Иванович
						</h4>
						<p class="Trustees-list-items-sub-title">
							Губернатор Иркутской области, председатель совета, председатель попечительского совета при Губернаторе
							Иркутской области по&nbsp;выявлению и&nbsp;поддержке одаренных детей в&nbsp;Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ВобликоваВалентинаФеофановна.webp"
							alt="Вобликова Валентина Феофановна">
						<h4 class="Trustees-list-items-title">
							Вобликова Валентина Феофановна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Заместитель Председателя Правительства Иркутской области, заместитель председателя совета
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ШмелеваЕленаВладимировна.webp"
							alt="Шмелева Елена Владимировна">
						<h4 class="Trustees-list-items-title">
							Шмелева Елена Владимировна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Руководитель фонда &laquo;Талант и&nbsp;Успех&raquo;
						</p>
					</div>
				</div>

				<hr>

				<h3 class="Trustees-title _cover-title">
					Члены совета
				</h3>
				<div class="grid-container">
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ПарфеновМаксимАлександрович.webp"
							alt="Парфенов Максим Александрович">
						<h4 class="Trustees-list-items-title">
							Парфенов Максим Александрович
						</h4>
						<p class="Trustees-list-items-sub-title">
							Министр образования Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ПолунинаОлесяНиколаевна.webp"
							alt="Полунина Олеся Николаевна">
						<h4 class="Trustees-list-items-title">
							Полунина Олеся Николаевна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Министр культуры Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ЦыгановаМаргаритаМихайловна.webp"
							alt="Цыганова Маргарита Михайловна">
						<h4 class="Trustees-list-items-title">
							Цыганова Маргарита Михайловна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Министр по&nbsp;молодежной политике Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/БогатыревПавелАлександрович.webp"
							alt="Богатырев Павел Александрович">
						<h4 class="Trustees-list-items-title">
							Богатырев Павел Александрович
						</h4>
						<p class="Trustees-list-items-sub-title">
							Министр спорта Иркутской обрасти
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ГершунНатальяГеннадьевна.webp"
							alt="Гершун Наталья Геннадьевна">
						<h4 class="Trustees-list-items-title">
							Гершун Наталья Геннадьевна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Министр экономического развития и&nbsp;промышленности Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ЖилкинаНатальяГеннадьевна.webp"
							alt="Жилкина Наталья Геннадьевна">
						<h4 class="Trustees-list-items-title">
							Жилкина Наталья Геннадьевна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Первый заместитель министра сельского хозяйства Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ХоменкоАндрейПавлович.webp"
							alt="Хоменко Андрей Павлович">
						<h4 class="Trustees-list-items-title">
							Хоменко Андрей Павлович
						</h4>
						<p class="Trustees-list-items-sub-title">
							Председатель Совета ректоров Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ДмитриевНиколайНиколаевич.webp"
							alt="Дмитриев Николай Николаевич">
						<h4 class="Trustees-list-items-title">
							Дмитриев Николай Николаевич
						</h4>
						<p class="Trustees-list-items-sub-title">
							Ректор федерального государственного бюджетного учреждения высшего образования &laquo;Иркутский
							государственный аграрный университет&nbsp;им. А.А. Ежевского&raquo;
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ВладимировВадимВладимирович.webp"
							alt="Владимиров Вадим Владимирович">
						<h4 class="Trustees-list-items-title">
							Владимиров Вадим Владимирович
						</h4>
						<p class="Trustees-list-items-sub-title">
							Начальник Восточно-Сибирской железной дороги&nbsp;&mdash; филиала открытого акционерного общества
							&laquo;Российские железные дороги&raquo;
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/МакароваАлександраАнатольевна.webp"
							alt="Макарова Александра Анатольевна">
						<h4 class="Trustees-list-items-title">
							Макарова Александра Анатольевна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Руководитель дирекции публичного акционерного общества &laquo;Банк ВТБ&raquo; по&nbsp;Иркутской области
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ОкладниковаДиляраРамисовна.webp"
							alt="Окладникова Диляра Рамисовна">
						<h4 class="Trustees-list-items-title">
							Окладникова Диляра Рамисовна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Директор фонда поддержки и&nbsp;развития предпринимательства Иркутской области Центр &laquo;Мой
							бизнес&raquo;
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ГашоваВераАлександровна.webp"
							alt="Гашова Вера Александровна">
						<h4 class="Trustees-list-items-title">
							Гашова Вера Александровна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Исполнительный директор Благотворительного фонда имени Юрия Тена
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="../assets/img/svg/User.svg" alt="Банько Светлана Николаевна">
						<h4 class="Trustees-list-items-title">
							Банько Светлана Николаевна
						</h4>
						<p class="Trustees-list-items-sub-title">
							Шеф-редактор филиала Федерального государственного унитарного предприятия &laquo;Всероссийская
							государственная
							телевизионная и&nbsp;радиовещательная компания&raquo; Государственной телевизионной и&nbsp;радиовещательной
							компании &laquo;Иркутск&raquo;
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/ЗахаровВикторЛеонидович.webp"
							alt="Захаров Виктор Леонидович">
						<h4 class="Trustees-list-items-title">
							Захаров Виктор Леонидович
						</h4>
						<p class="Trustees-list-items-sub-title">
							Директор общества с&nbsp;ограниченной ответственностью &laquo;Сервико&raquo;
						</p>
					</div>
					<div class="grid-item">
						<img loading="lazy" class="Trustees-list-items-img" src="@/assets/img/jpg/БушуевКонстантинСергеевич.webp"
							alt="Бушуев Константин Сергеевич">
						<h4 class="Trustees-list-items-title">
							Бушуев Константин Сергеевич
						</h4>
						<p class="Trustees-list-items-sub-title">
							Исполнительный директор ассоциации &laquo;Байкальская виза&raquo;
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>