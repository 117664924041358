<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Спортивное пилотирование»
		</h2>
		<p class="EventsPage-date">
			с 14 по 23 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=146" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24629-programma-sportivnoe-pilotirovanie" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Спортивное пилотирование» для учащихся 9-11-х классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа направлена на новый вид воздушного спорта в рамках международной авиационной федерации. Гонки на дронах — это не только возможность обучиться прикладным знаниям в сфере беспилотной авиации, но и углубиться в мир робототехники и инженерии. Спортивное пилотирование учит ребенка быстро принимать решения, брать за них ответственность и осознавать последствия своих поступков, формирует усидчивость, концентрацию внимания и целеустремленность.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету и выполнить отборочное задание на платформе ОЦ «Персей», до 4 марта 2024 года на платформе обучения Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Конкурсное задание проводится в симуляторе DRONE RACING CHAMPIONSHIP
			</li>
			<li class="JuniorProfi-items">
				После прохождения трассы необходимо сделать скриншот экрана с результатом времени заезда и прикрепить его на платформе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, необходимо будет записаться на программу «Спортивное пилотирование» на ресурсе «Навигатор дополнительного образования Иркутской области»
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Профильная смена состоится с 14 марта 2024 года по 23 марта 2024 года на бесплатной основе в Образовательном центре «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 5 марта на сайте Образовательного центра «Персей».</b>
		</p>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 6 марта 2024 г. в 20.00, <a style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1708921820">ссылка на подключение</a>
		</p>

		<h2 class="JuniorProfi-sub-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену:
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Абдиахатов Эргаш Ортикжонович</li>
			<li class="EventsPage-number">Бухарова Екатерина Николаевна</li>
			<li class="EventsPage-number">Велюго Илья Юрьевич</li>
			<li class="EventsPage-number">Ермилов Константин Максимович</li>
			<li class="EventsPage-number">Зуев Алексей Тимофеевич</li>
			<li class="EventsPage-number">Иванова Александра Алексеевна</li>
			<li class="EventsPage-number">Иванова Лидия Андреевна</li>
			<li class="EventsPage-number">Клименко Анна Александровна</li>
			<li class="EventsPage-number">Кузнецова Вероника Евгеньевна</li>
			<li class="EventsPage-number">Никозов Иван Вадимович</li>
			<li class="EventsPage-number">Новинков Илья</li>
			<li class="EventsPage-number">Сабурова Мария Андреевна</li>
			<li class="EventsPage-number">Татаринов Артём</li>
			<li class="EventsPage-number">Тугарин Артём Романович</li>
			<li class="EventsPage-number">Тугарин Глеб Романович</li>
			<li class="EventsPage-number">Филиппов Ярослав Васильевич</li>
			<li class="EventsPage-number">Черемисин Даниил Александрович</li>
			<li class="EventsPage-number">Швецова Маргарита Андреевна</li>
		</ol>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/iD7K/ri7BBVSNM" target="_blank">
			Скачать
		</a>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/RGAcMdb1G71JIg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>