<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage15.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				ЮНЫЕ МУЗЫКАНТЫ ПРИАНГАРЬЯ ОСВОЯТ ПРОГРАММУ ПО ДУХОВОМУ ОРКЕСТРОВОМУ ИСПОЛНИТЕЛЬСТВУ
			</h2>
			<p class="NewsCard-date">
				16.10.2023
			</p>
		</div>
	</div>
</template>