<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Олимпиадная математика»
		</h2>
		<p class="EventsPage-date">
			с 17 по 27 июня 2024
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=172" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25278-programma-olimpiadnaya-matematika"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Олимпиадная математика» для учащихся 5-7-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			В общей системе естественнонаучного образования современного человека математика играет основополагающую роль. Под
			влиянием математики развиваются новые направления научных исследований, возникающие на стыке с другими науками,
			создаются техника и технологическая база инновационного развития общества.
		</p>
		<p class="EventsPage-text">
			На профильной смене учащиеся рассмотрят, разберут тренировочные олимпиадные задания с преподавателями по следующим
			блокам: «Числа и их свойства», «Методы решения олимпиадных задач», «Геометрия».
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (тестирование) до 9 июня 2024 года на платформе
				Образовательного центра «Персей», системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, нужно будет записаться на программу «Олимпиадная
				математика» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 10 июня на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 11 июня 2024 г. в 14:00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1717386250">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Афанасьев Владислав Антонович</li>
			<li class="EventsPage-number">Березовский Роман Михайлович</li>
			<li class="EventsPage-number">Богданов Кирилл Ильич</li>
			<li class="EventsPage-number">Большакова Наталья Данииловна</li>
			<li class="EventsPage-number">Борисов Сергей Денисович</li>
			<li class="EventsPage-number">Бубнов Мирон</li>
			<li class="EventsPage-number">Бутакова Алина Николаевна</li>
			<li class="EventsPage-number">Вашкин Владислав Владимрович</li>
			<li class="EventsPage-number">Войкин Матвей Павлович</li>
			<li class="EventsPage-number">Галкина Евгения Дмитриевна</li>
			<li class="EventsPage-number">Гейнц Александр</li>
			<li class="EventsPage-number">Голято Софья Валерьевна</li>
			<li class="EventsPage-number">Горбач Владислав Игоревич</li>
			<li class="EventsPage-number">Градобоев Александр Евгеньевич</li>
			<li class="EventsPage-number">Денисова Эвелина Николаевна</li>
			<li class="EventsPage-number">Дехтяр Александр Владимирович</li>
			<li class="EventsPage-number">Диженин Федор Михайлович</li>
			<li class="EventsPage-number">Дубино Лев</li>
			<li class="EventsPage-number">Зароднюк Ирэна</li>
			<li class="EventsPage-number">Зверева Анастасия Александровна</li>
			<li class="EventsPage-number">Иванов Кирилл</li>
			<li class="EventsPage-number">Ивкин Василий</li>
			<li class="EventsPage-number">Карасёв Семён Игоревич</li>
			<li class="EventsPage-number">Кирюхин Семён Кириллович</li>
			<li class="EventsPage-number">Ковыршин Михаил Сергеевич</li>
			<li class="EventsPage-number">Козлов Данил Евгеньевич</li>
			<li class="EventsPage-number">Комиссаров Макар Борисович</li>
			<li class="EventsPage-number">Кухта Тимофей Александрович</li>
			<li class="EventsPage-number">Логунов Илья Алексеевич</li>
			<li class="EventsPage-number">Лялин Петр Алексеевич</li>
			<li class="EventsPage-number">Малакичева Варвара</li>
			<li class="EventsPage-number">Наумов Иван Максимович</li>
			<li class="EventsPage-number">Нестеров Ярослав Сергеевич</li>
			<li class="EventsPage-number">Перфилов Всеволод Андреевич</li>
			<li class="EventsPage-number">Пестерев Никита Денисович</li>
			<li class="EventsPage-number">Петровская Александра Алексеевна</li>
			<li class="EventsPage-number">Полонова Светлана Владимировна</li>
			<li class="EventsPage-number">Полтавец Артем Сергеевич</li>
			<li class="EventsPage-number">Попов Егор Иванович</li>
			<li class="EventsPage-number">Попов Максим Алексеевич</li>
			<li class="EventsPage-number">Пригорнев Александр</li>
			<li class="EventsPage-number">Романов Федор Максимович</li>
			<li class="EventsPage-number">Секунда Серафим</li>
			<li class="EventsPage-number">Селиванов Александр</li>
			<li class="EventsPage-number">Сидорова Анастасия</li>
			<li class="EventsPage-number">Стешенко Юлия Андреевна</li>
			<li class="EventsPage-number">Татаринов Матвей Антонович</li>
			<li class="EventsPage-number">Томиловских Полина Андреевна</li>
			<li class="EventsPage-number">Цветик Тимофей Павлович</li>
			<li class="EventsPage-number">Цыдыпов Евгений Сергеевич</li>
			<li class="EventsPage-number">Чередниченко Ксения Константиновна</li>
			<li class="EventsPage-number">Черехович Артемий Артурович</li>
			<li class="EventsPage-number">Чуйко Егор Евгеньевич</li>
			<li class="EventsPage-number">Чуйко Иван Евгеньевич</li>
			<li class="EventsPage-number">Шевченко Сергей Валерьевич</li>
			<li class="EventsPage-number">Шишмарев Кирилл Сергеевич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/Dnr7wRXANIQAtw" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>