<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			Конкурс
		</h2>
		<h3 class="EventsCard-title">
			Конкурс ко «Дню защитника Отечества» для школьников с 10 до 17 лет
		</h3>
		<p class="EventsCard-sub-title">
			с 12 по 21 февраля 2024 года
		</p>
	</div>
</template>