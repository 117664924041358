<template>
	<div class="JuniorProfi _cover">
		<h2 class="JuniorProfi-title _cover-title">
			«Проектная деятельность» (подготовка к участию во Всероссийском конкурсе научно-технологических проектов «Большие вызовы»)
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			с 12 по 25 декабря 2023 года
		</h4>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24165-programma-proektnaya-deyatelnost" target="_blank">
			Регистрация
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Проектная деятельность» (подготовка к участию во Всероссийском конкурсе научно-технологических проектов «Большие вызовы» для обучающихся 7-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа направлена на подготовки школьников к участию во Всероссийском конкурсе научно-технологических проектов «Большие вызовы, который проводит Фонд «Талант и успех». Конкурс включает два трека: региональный и дистанционный. Они проходят параллельно. Можно участвовать в одном из них.
		</p>
		<p class="EventsPage-text">
			На профильной смене школьники познакомятся с основами проектной деятельности, а также выполнят проект по одному из выбранных направлений Конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Агропромышленные и биотехнологии
			</li>
			<li class="JuniorProfi-items">
				Большие данные, искусственный интеллект, финансовые технологии и машинное обучение.
			</li>
			<li class="JuniorProfi-items">
				Космические технологии.
			</li>
			<li class="JuniorProfi-items">
				Современная энергетика.
			</li>
			<li class="JuniorProfi-items">
				Умный город и безопасность.
			</li>
			<li class="JuniorProfi-items">
				Беспилотный транспорт и логистические системы.
			</li>
		</ul>
		<p class="EventsPage-text">
			По окончанию обучения состоится первый отборочный этап Конкурса, на котором обучающиеся представят свои проекты.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Для участия в профильной смене необходимо:
		</p>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=116" target="_blank">
			Зарегистрироваться и заполнить анкету участника в срок до 1 декабря 2023 года на платформе moodle по ссылке.
		</a>
		<p class="EventsPage-text">
			Профильная смена состоится с 12 по 25 декабря 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Дополнительная информация:
		</p>
		<p class="EventsPage-text">
			Список учащихся, приглашенных на профильную смену, будет опубликован на сайте ОЦ «Персей» 5 декабря 2023 года.
		</p>
		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание (5 декабря 2023 г. в 20.00,)
		</p>
		<a class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1701744546" target="_blank">
			Cсылка на подключение
		</a>
		<hr>
		
		<p class="JuniorProfi-sub-title">
			Задание для участников профильной смены:
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D0%BE%D0%B9-%D0%B8%D0%B4%D0%B5%D0%B8-1.docx" target="_blank">
			Описание проектной деятельности
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список обучающихся
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%BD%D0%B0-%D1%81%D0%B0%D0%B9%D1%82-1.docx" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/aciM5aERAmdlDg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/AnJKDWlWbyrFPg" target="_blank">
			Сертификаты
		</a>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>