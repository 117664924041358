<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Инженеры будущего»
		</h2>
		<p class="EventsPage-date">
			с 8 ноября по 21 ноября 2023 года
		</p>
		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребёнка» проводит профильную смену «Инженеры будущего» для обучающихся 5-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Программа смены направлена на получение учащимися знаний в области промышленной робототехники, системной инженерии, навыков поиска оптимальных и научно-обоснованных подходов. Основанная цель – овладеть всесторонним научным подходом и инженерными навыками на примере создания собственного проекта, а также формирование персональной траектории роста.
		</p>
		<hr>
		<h2 class="EventsPage-title">
			Для прохождения обучения:
		</h2>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/mod/feedback/view.php?id=675">
			Зарегистрироваться и заполнить анкету на платформе ОЦ «Персей» по ссылке
		</a>
		<a class="EventsPage-link" href="https://moodle.perseusirk.ru/course/view.php?id=110">
			Прикрепить краткое описание проекта (идеи проекта) (не менее 4-х предложений) и изображение изобретения/прототипа (при наличии) (в формате jpg, pdf или презентацию о проекте) на платформе обучения ОЦ «Персей» по ссылке
		</a>
		<hr>
		<p class="EventsPage-text">
			Профильная смена состоится с 8 ноября по 21 ноября 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<a class="EventsPage-link" href="https://pruffme.com/landing/Perseus/tmp1698125427">
			Список учащихся, приглашенных на профильную смену, будет опубликован на сайте ОЦ «Персей» 31 октября 2023 года. Для участников смены будет проведено организационное собрание (31 октября 2023 г. в 20.00, ссылка на подключение
		</a>
		<hr>
		<h2 class="EventsPage-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену «Инженеры будущего»
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Баженова Алиса Викторовна</li>
			<li class="EventsPage-number">Белов Юрий Александрович</li>
			<li class="EventsPage-number">Ворожцов Тимофей Александрович</li>
			<li class="EventsPage-number">Воронин Егор Алексеевич</li>
			<li class="EventsPage-number">Зверев Александр Михайлович</li>
			<li class="EventsPage-number">Исаков Иван</li>
			<li class="EventsPage-number">Картошкина Алиса Евгеньевна</li>
			<li class="EventsPage-number">Кох Вадим Михайлович</li>
			<li class="EventsPage-number">Краснов Олег Александрович</li>
			<li class="EventsPage-number">Кресс Варвара Вячеславовна</li>
			<li class="EventsPage-number">Кудашев Лев</li>
			<li class="EventsPage-number">Кудин Евгений Валерьевич</li>
			<li class="EventsPage-number">Луковников Никита Сергеевич</li>
			<li class="EventsPage-number">Людвиг Рената-Меная Денисовна</li>
			<li class="EventsPage-number">Молотков Арсений Сергеевич</li>
			<li class="EventsPage-number">Наумов Михаил Евгеньевич</li>
			<li class="EventsPage-number">Никитин Руслан Артурович</li>
			<li class="EventsPage-number">Новиков Александр Сергеевич</li>
			<li class="EventsPage-number">Новиков Святослав Сергеевич</li>
			<li class="EventsPage-number">Петров Иван Александрович</li>
			<li class="EventsPage-number">Полосухина Елизавета Андреевна</li>
			<li class="EventsPage-number">Селиванов Марк Артурович</li>
			<li class="EventsPage-number">Смолянинова Софья Михайловна</li>
			<li class="EventsPage-number">Тетерин Максим Евгеньевич</li>
			<li class="EventsPage-number">Харатян Екатерина Владиславовна</li>
			<li class="EventsPage-number">Хлыбов Дмитрий Русланович</li>
			<li class="EventsPage-number">Цвилинг Семён Алексеевич</li>
			<li class="EventsPage-number">Шувалов Иван Георгиевич</li>
			<li class="EventsPage-number">Шумаков Константин Олегович</li>
			<li class="EventsPage-number">Яковлев Владислав Игоревич</li>
		</ol>
		<hr>
		<p class="EventsPage-text">
			<a class="EventsPage-link" href="https://cloud.mail.ru/public/XTnj/rZrqQpsKi">
				Справки-вызовы
			</a>
		</p>
		<hr>
		<p class="EventsPage-text">
			<b>Участникам, создавшим личный кабинет, но не заполнившим анкету, необходимо присутствовать на организационном собрании.</b>
		</p>
		<p class="EventsPage-text">
			Примечание: Списочный состав участников смены сформирован экспертной комиссией на основании представленных данных о достижениях учащихся.
		</p>
		<hr>
		<p class="EventsPage-nte-dis">
			<b>Контактное лицо:</b> Толстихина Татьяна Павловн<br>
			<b>Телефон:</b> 8 (3952) 546-044 <br>
			<b>Эл. почта:</b> n.tolstihina@perseusirk.ru<br>
		</p>
		<hr>
		<h2 class="EventsPage-prof">
			Документы
		</h2>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/Xk8KyudEn06s2A" target="_blank">
			Сертификаты
		</a>
	</div>
</template>