<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage69.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» прошла профильная смена «Выбор Первых – ЗОЖ» в рамках летней оздоровительной кампании
			</h2>
			<p class="NewsCard-date">
				28.06.2024
			</p>
		</div>
	</div>
</template>