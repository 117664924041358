<template>
	<div class="SummerHolidayRecommendations _cover">
		<h2 class="SummerHolidayRecommendations _cover-title">
			Методические рекомендации
		</h2>

		<h3 class="Documents-sub-title">
			Полезные ссылки и материалы:
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://fcdtk.ru/" target="_blank">
					ФГБОУ ДО «Федеральный центр детско-юношеского туризма и краеведения»
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://sdorus.ru/" target="_blank">
					Межрегиональная общественная организация «Содействие детскому отдыху»
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://summercamp.ru/%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%B1%D0%B8%D0%B1%D0%BB%D0%B8%D0%BE%D1%82%D0%B5%D0%BA%D0%B0_%28%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%BA%D0%B8%29"
					target="_blank">
					Методические пособия для работы в организации отдыха и оздоровления
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%A1%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%BA-%D0%9E%D0%BA%D0%B5%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B5-%D0%B8%D0%B3%D1%80%D1%8B.pdf"
					target="_blank">
					Сборник «Океанские игры»
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D1%81%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%BA-%D0%B8%D0%B3%D1%80-%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B6%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B4%D0%BB%D1%8F-%D0%BE%D1%82%D1%80%D1%8F%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D0%B0.pdf"
					target="_blank">
					Методический сборник игр и упражнений для отрядного педагога
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9E%D1%81%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%B2-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F%D1%85-%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9-%D0%B8-%D0%B8%D1%85-%D0%BE%D0%B7%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
					target="_blank">
					Швецова Т.В. Осуществление образовательной деятельности в организациях отдыха детей и их оздоровления: – М.:
					Федеральное государственное бюджетное образовательное учреждение дополнительного образования «Федеральный
					центр дополнительного образования и организации отдыха и оздоровления детей»
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%A0%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-%D0%B4%D0%BB%D1%8F-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B9-%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9-%D0%B8-%D0%B8%D1%85-%D0%BE%D0%B7%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
					target="_blank">
					Леонов В.В. Разработка программ для организаций отдыха детей и их оздоровления: М.: Федеральное
					государственное бюджетное образовательное учреждение дополнительного образования «Федеральный центр
					дополнительного образования и организации отдыха и оздоровления детей»
				</a>
			</li>
		</ul>
	</div>
</template>