<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Конкурс
		</h3>
		<h2 class="EventsPage-title">
			Конкурс ко «Дню защитника Отечества» для школьников с 10 до 17 лет
		</h2>
		<p class="EventsPage-date">
			с 12 по 21 февраля
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=141" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://disk.yandex.com/d/HmmhZTw-WIeqZg" target="_blank">
			Итоги
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Персей» проводит конкурс ко «Дню защитника Отечества» для школьников с 10 до 17 лет.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Цель мероприятия:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				создать условия творческой состязательности;
			</li>
			<li class="JuniorProfi-items">
				выявить одаренных детей, активизировать их интеллектуальную и творческую деятельность;
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Конкурсные номинации (направления):
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				«Герои в моей семье»;
			</li>
			<li class="JuniorProfi-items">
				«Защитники Отечества»;
			</li>
			<li class="JuniorProfi-items">
				«Слава героям России»;
			</li>
			<li class="JuniorProfi-items">
				«Слава нашей армии»;
			</li>
			<li class="JuniorProfi-items">
				«Праздник доблести и чести»;
			</li>
			<li class="JuniorProfi-items">
				«Какой он, русский солдат»;
			</li>
			<li class="JuniorProfi-items">
				Своя тема.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сроки:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				с 12.02.2024 года по 21.02.2024 года включительно осуществляется сбор конкурсных работ. Конкурсные работы, поступившие после окончания срока приема конкурсных работ, к Конкурсу не допускаются;
			</li>
			<li class="JuniorProfi-items">
				с 21.02.2024 года по 22.02.2024 года включительно осуществляется подведение итогов.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5-23.02.2024.pdf" target="_blank">
			Положение о конкурсе 23.02.2024
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Итоги:
		</p>
		<p class="JuniorProfi-sub-title">
			Победители по каждой номинации:
		</p>
		<p class="EventsPage-text">
			Михаил Шарапов, Никита Филонов , Эвелина Леушкина , Алиса Мальцева, Алена Макарова, Максим Танкович, Семен Штокерт, Диана Кутенкова, Али Никольская, Иван Козмиренко
		</p>

		<p class="JuniorProfi-sub-title">
			Диплом Победителя:
		</p>
		<p class="EventsPage-text">
			Варвара Гергесова, Мария Нейман, Софья Корзун, Виктория Архипова, Екатерина Зимина , Яна Рябова, Артем Середин, Елизавета Белянинова, Анастасия Логинова, Людмила Калистова, Барбара Козмиренко, Любовь Проничева, Софья Тайбинова
		</p>

		<p class="JuniorProfi-sub-title">
			Призеры 2 степени:
		</p>
		<p class="EventsPage-text">
			Артемий Никонов, Мария Клеменищева, Ева Юртаева, Василиса Щипан, Анастасия Логинова, Матвей Коротаев, Валерия Давыдова, Елисей Курильский, Семён Аниканов, Елизавета Белянинова, Арина Заикина
		</p>

		<p class="EventsPage-text">
			Главный приз – обучение на профильной смене в «Персее» по направлению «Журналистика и новые медиа» без конкурсного отбора получают Михаил Шарапов, Никита Филонов , Эвелина Леушкина , Алиса Мальцева, Алена Макарова, Максим Танкович, Семен Штокерт, Али Никольская, Диана Кутенкова, Иван Козмиренко
		</p>

		<p class="EventsPage-text">
			Поздравляем победителей и призеров! А ребят, кто получил главный приз, просим написать нам в сообщения паблика ВК: <a style="color:blue" href="https://vk.com/perseusirk">https://vk.com/perseusirk</a>
		</p>

		<p class="EventsPage-text">
			<b>Сертификаты участников и дипломы призеров и победителей вы можете скачать по ссылке:</b> <a style="color:blue" href="https://disk.yandex.com/d/HmmhZTw-WIeqZg">https://disk.yandex.com/d/HmmhZTw-WIeqZg</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>