<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Актуальные программы
		</h2>
		<div class="grid-container">


			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage187' })">
						<events-card187></events-card187>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage186' })">
						<events-card186></events-card186>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage185' })">
						<events-card185></events-card185>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage184' })">
						<events-card184></events-card184>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage183' })">
						<events-card183></events-card183>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage180' })">
						<events-card180></events-card180>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage179' })">
						<events-card179></events-card179>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage178' })">
						<events-card178></events-card178>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage177' })">
						<events-card177></events-card177>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage174' })">
						<events-card174></events-card174>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage176' })">
						<events-card176></events-card176>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage175' })">
						<events-card175></events-card175>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage148' })">
						<events-card148></events-card148>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage146' })">
						<events-card146></events-card146>
					</a>
				</div>
			</div>
		
		</div>
		
		<!-- <router-link to="/ProfileBlock" class="Events-btn temp-btn">
				Все программы
		</router-link> -->
	</div>
</template>

<script>
import EventsCard146 from '../EventsCard/EventsCard146';
import EventsCard148 from '../EventsCard/EventsCard148';
import EventsCard174 from '../EventsCard/EventsCard174';
import EventsCard175 from '../EventsCard/EventsCard175';
import EventsCard176 from '../EventsCard/EventsCard176';
import EventsCard177 from '../EventsCard/EventsCard177';
import EventsCard178 from '../EventsCard/EventsCard178';
import EventsCard179 from '../EventsCard/EventsCard179';
import EventsCard180 from '../EventsCard/EventsCard180';
import EventsCard183 from '../EventsCard/EventsCard183';
import EventsCard184 from '../EventsCard/EventsCard184';
import EventsCard185 from '../EventsCard/EventsCard185';
import EventsCard186 from '../EventsCard/EventsCard186';
import EventsCard187 from '../EventsCard/EventsCard187';


	export default {
		components: {
			EventsCard146,
			EventsCard148,
			EventsCard174,
			EventsCard175,
			EventsCard176,
			EventsCard177,
			EventsCard178,
			EventsCard179,
			EventsCard180,
			EventsCard183,
			EventsCard184,
			EventsCard185,
			EventsCard186,
			EventsCard187,
		}
	}
</script>
