<template>
	<div class="JuniorProfi _cover">
		<h3 class="EventsPage-prof">
			Дистанционная викторина
		</h3>
		<h2 class="JuniorProfi-title _cover-title">
			«Праздник науки: викторина открытий»
		</h2>
		<h4 class="JuniorProfi-title _cover-title">
			Регистрация с 22.01.2024  по 31.01.2024 года
		</h4>
		<h4 class="JuniorProfi-title _cover-title">
			Участие с 01.02.2024 по 15.02.2024 года
		</h4>

		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=118" target="_blank">
			Викторина
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей «Персей» проводит межрегиональную дистанционную викторину "Праздник науки: викторина открытий" для школьников 8-10 классов Сибирского федерального округа
		</p>
		<p class="EventsPage-text">
			Партнер мероприятия ООО "Газпром Добыча Иркутск"
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Цель мероприятия:
		</p>
		<p class="EventsPage-text">
			Привлечение внимания обучающихся общеобразовательных организаций регионов Сибири к научной деятельности
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Задачи:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				воспитание у обучающихся гражданственности и патриотизма, любви к Родине, гордости за свою страну;
			</li>
			<li class="JuniorProfi-items">
				расширение знаний обучающихся о развитии науки в СФО;
			</li>
			<li class="JuniorProfi-items">
				выявление и поощрение обучающихся.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сроки:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				регистрация на мероприятие с 22.01.2024  по 31.01.2024 года
			</li>
			<li class="JuniorProfi-items">
				участие с 01.02.2024 по 15.02.2024 года
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Самодурова Вера Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: v.samodurova@perseusirk.ru
			</li>
		</ul>

	</div>
</template>