<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			28.06.2024
		</p>
		<h2 class="NewsPage-title">
			Школьники Иркутской области приняли участие в профильной смене «Олимпиадная математика»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage70.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» завершилась профильная смена по подготовке учащихся к олимпиадам по
				математике. Участниками образовательной программы стали 58 школьников из Иркутска, Братска, Киренска и Черемхово
				и Иркутского района. Программа проходит в Образовательном центре в первый раз.
			</p>
			<p class="NewsPage-sub-title">
				Смена была посвящена подготовке школьников к математическим олимпиадам. В течение программы участники
				знакомились с заданиями предыдущих этапов олимпиад регионального и всероссийского уровня. Изучали их решения и
				анализировали типичные ошибки, допускаемые участниками. Под руководством преподавателей Лицея ИГУ школьники
				знакомились с методами решения олимпиадных задач, а также углубляли свои знания и развивали математические
				способности.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали преподаватели, на занятиях ребята учились находить ошибки и правильные решения олимпиадных
				заданий, опираясь на свои знания. Участники активно работали в группах, решали задачи на логику и участвовали в
				математических дискуссиях. Особое внимание уделялось развитию критического мышления и способности решать
				нестандартные задачи, что является ключевым навыком для успешного участия в олимпиадах.
			</p>
			<p class="NewsPage-sub-title">
				Участники программы Федор Диженин и Ярослав Нестеров поделились своими впечатлениями от обучения: «Мы получили
				много новых знаний на этой смене и узнали о новых способах решения сложных математических задач, которые ранее
				казались нерешаемыми. Особенно запомнилась математическая олимпиада «Персея», где мы смогли применить полученные
				знания, проверить свои силы и оценить личный потенциал . А самое главное, мы поняли, что нам необходимо
				продолжать развиваться в олимпиадном движении и дальше».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, олимпиадные профильные смены Образовательного центра «Персей» направлены на подготовку учащихся к
				участию в школьных олимпиадах различного уровня. За 2023-2024 учебный год в «Персее» успешно прошли восемь смен
				олимпиадной направленности.
			</p>

		</div>
	</div>
</template>
