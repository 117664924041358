<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Period-link" @click="$router.push({ name: 'AllRussian' })">
						<EventsCard43></EventsCard43>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage56' })">
						<events-card56></events-card56>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage49' })">
						<events-card49></events-card49>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage33' })">
						<events-card33></events-card33>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard33 from '../EventsCard/EventsCard33';
import EventsCard49 from '../EventsCard/EventsCard49';
import EventsCard56 from '../EventsCard/EventsCard56';
import EventsCard43 from '../EventsCard/EventsCard43';


	export default {
		components: {
			EventsCard33,
			EventsCard49,
			EventsCard56,
			EventsCard43,
		}
	}
</script>
