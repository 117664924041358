<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage35.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Анатомия человека и экологическое просвещение. Итоги дистанционных курсов «Персея»
			</h2>
			<p class="NewsCard-date">
				18.12.2023
			</p>
		</div>
	</div>
</template>