<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			18.01.2024
		</p>
		<h2 class="NewsPage-title">
			Персей начинает год с творческих и научных программ
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage43.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Региональный центр выявления и поддержки одаренных детей «Персей» в 2024 году традиционно начинает год с программ по направлению «Наука» и «Искусство». На январские смены, которые стартовали 17 января, приехали участники четырех программ: «Физика», «Право», «Математика» и «Лаборатория творчества: техники современного танца».
			</p>
			<p class="NewsPage-sub-title">
				Как сообщили организаторы, в научных сменах принимают участие победители и призеры муниципального этапа Всероссийской олимпиады школьников. В первом блоке программ 50 школьников подготовятся к региональному этапу ВсОШ на базе «Персея».
			</p>
			<p class="NewsPage-sub-title">
				– Образовательный центром «Персей» ежегодно проводится подготовка школьников к олимпиаде. Начинается она с дистанционных курсов в сентябре. После этого ребята встречаются на очных занятиях в Иркутске, а  также в кампусе «Персея». Завершается она традиционно в январе – недельными профильными сменами, на которых школьники уже готовятся к региональному этапу, – рассказал директор Образовательного центра Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Как пояснили преподаватели, ребята изучат темы, в которых преимущественно школьники допускают типовые ошибки при решении олимпиадных задач, а также разберут задания прошлых лет.
			</p>
			<p class="NewsPage-sub-title">
				Следующий блок программ по подготовке к ВсОШ стартует 25 января. Он будет включать недельные смены по экологии, литературе, английскому языку, обществознанию и основам безопасности жизнедеятельности.
			</p>
			<p class="NewsPage-sub-title">
				В этом году открывает смены по «Искусству» программа «Лаборатория творчества: техники современного танца». Участие в ней примут более 30 юных хореографов из Иркутска и Иркутского района.
			</p>
			<p class="NewsPage-sub-title">
				Обучение будет проходить под руководством педагогов-хореографов  Иркутского регионального колледжа педагогического образования Натальи и Арины Бородихиных.
			</p>
			<p class="NewsPage-sub-title">
				— В начале прошлого года мы успешно реализовали совместную программу, в которой объединили два направления: хореография и актерское мастерство. Как хореографы мы видим интерес детей к современным танцевальным направлениям, поэтому в этому году мы решили запустить программы по современной хореографии, – поделилась преподаватель и автор программы Наталья Бородихина.
			</p>
			<p class="NewsPage-sub-title">
				Участники изучат историю возникновения и развития современного танца, технику «Релиз», контактную импровизацию, партнеринг, а также комбинации и поддержки. Итоги обучения школьники продемонстрируют на отчетном концерте, который состоится 29 января.
			</p>
			
			<div class="Intelligence-block">
				<ul class="grid-container">
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(1).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(2).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(3).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(4).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(5).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(6).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(7).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(8).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(9).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(10).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(11).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(12).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(13).webp" alt="Картинка">
					</li>
					<li class="grid-item">
						<img loading="lazy" class="grid-item-img" src="../assets/Newsimg/NewsPage43(14).webp" alt="Картинка">
					</li>
				</ul>
			</div>

		</div>
	</div>
</template>
