<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-3">
			Искусство
		</h2>
		<h3 class="EventsCard-title">
			«Беседы о хореографическом искусстве» (Модуль 2)
		</h3>
		<p class="EventsCard-sub-title">
			с 8 апреля по 28 мая 2023 года
		</p>
	</div>
</template>