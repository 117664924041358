<template>
	<div class="Sirius_Artificial_Intelligence _cover">
		<h2 class="Sirius_Artificial_Intelligence-title _cover-title">
			Сириус. Искусственный интеллект
		</h2>

		<div class="Sirius_Artificial_Intelligence-logo">
			<img loading="lazy" class="Sirius_Artificial_Intelligence-logo-img"
				src="../assets/img/svg/Sirius_Artificial_Intelligence-logo-img.svg" alt="Логотип Уроки настоящего">
		</div>

		<h2 class="Sirius_Artificial_Intelligence-title _cover-title">
			<a class="Sirius_Artificial_Intelligence-link" href="https://sochisirius.ru/obuchenie/distant/smena1758/8141">
				О программе
			</a>
		</h2>

		<p class="Sirius_Artificial_Intelligence-text">
			Всероссийская научно-технологическая программа по решению проектных задач в области искусственного интеллекта и
			смежных дисциплин «Сириус.ИИ»
		</p>
		<p class="Sirius_Artificial_Intelligence-text">
			Это научно-технологическая программа проектных команд, где участники представят решение проектной задачи в области
			искусственного интеллекта и смежных дисциплин
		</p>

		<p class="Sirius_Artificial_Intelligence-text">
			Здесь школьники могут найти задачу для проектной работы, связанную с актуальными, современными проблемами науки,
			технологии, бизнеса, а также наставника, который поможет разобраться в задаче и будет сопровождать школьника в
			течение всего проекта.
		</p>


		<h3 class="Sirius_Artificial_Intelligence-title _cover-title">
			Как попасть
		</h3>
		<ul class="Sirius_Artificial_Intelligence-list">
			<li class="Sirius_Artificial_Intelligence-items">
				Подготовительный этап. Зарегистрироваться на программу «Сириус.ИИ» на платформе Сириус. Выбрать одну их тем
				проектного задания о партнёров ОЦ Сириус. Создать команду: 1 капитан + 1-3 участника
			</li>
			<li class="Sirius_Artificial_Intelligence-items">
				Отборочный этап. 1 тур. Выбрать задачу в системе «Сириус.Онлайн» и проработать её решение. Итоговое решение,
				оформленное в презентацию, капитан проектной команды загружает в заявку.
			</li>
			<li class="Sirius_Artificial_Intelligence-items">
				Отборочный этап. 2 тур. Представление своего решения участниками проектной команды партнерам - заказчикам
				проектных задач - по видеоконференцсвязи.
			</li>
			<p class="Sirius_Artificial_Intelligence-text">
				По результатам второго этапа отборочного тура определяется список участников проектных команд, приглашенных для
				участия в итоговом этапе Программы «Сириус.ИИ».
			</p>
			<li class="Sirius_Artificial_Intelligence-items">
				Итоговый этап. Доработка своих решений на базе Образовательного центра «Сириус» в очном формате. Команды под
				очным руководством экспертов доведут свои проекты до MVP и представят их на итоговой защите.
			</li>
		</ul>

		<div class="Sirius_Artificial_Intelligence-link-box">
			<a class="Sirius_Artificial_Intelligence-link" href="https://sochisirius.ru/obuchenie/distant/smena1758/8144">
				Положение
			</a>
			<a class="Sirius_Artificial_Intelligence-link" href="https://vk.com/wall-195976317_2080">
				Советы участников
			</a>
		</div>

		<h3 class="Sirius_Artificial_Intelligence-title _cover-title">
			Как проходит программа сейчас (второй сезон)
		</h3>
		<a class="Sirius_Artificial_Intelligence-link" href="https://sochisirius.ru/obuchenie/distant/smena1758/8143">
			Сезон 2024
		</a>

		<details class="AdditionalGneral-details" open>
			<summary class="AdditionalGneral-summary">
				<b>2024</b>
			</summary>

			<p class="Sirius_Artificial_Intelligence-text">
				Онлайн-консультация по программе «Сириус.ИИ»
			</p>
			<a class="Sirius_Artificial_Intelligence-link" href="https://vk.com/video-71991592_456242605">
				1 тур
			</a>
			<a class="Sirius_Artificial_Intelligence-link"
				href="https://vk.com/video-71991592_456242634?list=64a8c94e9d83160b49">
				2 тур
			</a>
			<a class="Sirius_Artificial_Intelligence-link" href="https://perseusirk.ru/News/NewsPage32">
				Информации в СМИ
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				<b>Архив 2023</b>
			</summary>

			<p class="Sirius_Artificial_Intelligence-text">
				Онлайн-консультация по программе «Сириус.ИИ»
			</p>
			<a class="Sirius_Artificial_Intelligence-link" href="https://vk.com/video-71991592_456242485">
				1 тур
			</a>
			<a class="Sirius_Artificial_Intelligence-link" href="https://vk.com/video-71991592_456242492">
				2 тур
			</a>
		</details>

		<h3 class="Sirius_Summer-title _cover-title">
			Прямо сейчас доступна запись на курсы
		</h3>

		<div class="Sirius_Summer-contact-box">
			<a class="EventsPage-btn temp-btn" href="https://edu.sirius.online/course/aiintro" target="_blank">
				«Знакомство с ИИ»
			</a>
			<a class="EventsPage-btn temp-btn" href="https://edu.sirius.online/course/nlpintro" target="_blank">
				«Обработка естественного языка»
			</a>
		</div>

		<p class="Sirius_Artificial_Intelligence-text">
			Больше информации про курсы по искусственному интеллекту — на странице Навигатора «Сириуса» по искусственному
			интеллекту <a style="display: inline;" class="Sirius_Artificial_Intelligence-link"
				href="https://edu.sirius.online/ai-navigator">(ссылка)</a>
		</p>

		<h3 class="Sirius_Summer-title _cover-title">
			Контакты
		</h3>

		<div class="Sirius_Summer-contact-box">
			<ul class="Sirius_Summer-contact-list">
				<li class="Sirius_Summer-contact-items">
					Самодурова Вера Геннадьевна
				</li>
				<li class="Sirius_Summer-contact-items">
					Телефон: 8 (3952) 546-044
				</li>
				<li class="Sirius_Summer-contact-items">
					Эл. почта: v.samodurova@perseusirk.ru
				</li>
			</ul>

			<ul class="Sirius_Summer-contact-list">
				<li class="Sirius_Summer-contact-items">
					Шведина Светлана Александровна
				</li>
				<li class="Sirius_Summer-contact-items">
					Телефон: 8 (3952) 546-044
				</li>
				<li class="Sirius_Summer-contact-items">
					Эл. почта: s.shvedina@perseusirk.ru
				</li>
			</ul>
		</div>

	</div>
</template>