<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage49.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В «Персее» подвели итоги профильных смен «Агенты погоды» и «Весенняя интеллектуальная общеразвивающая школа»
			</h2>
			<p class="NewsCard-date">
				11.03.2024
			</p>
		</div>
	</div>
</template>