<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Реализм в русском балете первой половины XIX столетия»
		</h2>
		<p class="EventsPage-date">
			с 10 мая по 14 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=162" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24753-programma-realizm-v-russkom-balete-pervoi-poloviny-xix-stoletiya" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребенка» проводит курсы с использованием дистанционных образовательных технологий «Реализм в русском балете первой половины XIX столетия».
		</p>

		<p class="EventsPage-text">
			Программа «Реализм в русском балете первой половины XIX столетия» направлена на развитие творческих способностей детей в области хореографических дисциплин, формирование знаний по истории русского балета первой половины 19 столетия, о появлении романтических сюжетов и развития реалистических тенденций, формирование устойчивого интереса к самостоятельной деятельности в сфере изучения истории хореографического искусства.
		</p>

		<p class="EventsPage-text">
			В образовательной программе могут принять участие обучающиеся в возрасте 10-17 лет образовательных организаций Иркутской области и участники творческих хореографических коллективов.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Намаконова Лариса Владимировна, главный балетмейстер ГБУ ДО ИО ЦРДТЮ «Узорочье»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 10 мая 2024 года на платформе Навигатор дополнительного образования Иркутской области по ссылке: https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24753-programma-realizm-v-russkom-balete-pervoi-poloviny-xix-stoletiya.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и обязательно заполнить АНКЕТУ до 10 мая 2024 на платформе дистанционного обучения Образовательного центра «Персей» в системе Moodle по ссылке: https://moodle.perseusirk.ru/course/view.php?id=162.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>