<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-3">
			Еженедельный курс
		</h2>
		<h3 class="EventsCard-title">
			«Игры звука»
		</h3>
		<p class="EventsCard-sub-title">
			с 12 июля по 01 августа 2023 года
		</p>
	</div>
</template>