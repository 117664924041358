<template>
	<div class="Logistics _cover">
		<h2 class="_cover-title">Материально-техническое обеспечение и оснащенность образовательного процесса</h2>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Фотографии учебных кабинетов, объектов спорта и питания обучающихся
			</summary>
			<h3 class="Logistics-dist _cover-title">г. Иркутск, Угольный проезд 68/1</h3>
			<div class="grid-container">
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/1.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/2.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/3.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/4.webp" alt="Учебная аудитория" />
				</div>
			</div>
			<h3 class="Logistics-dist _cover-title">ОЦ «Персей», Ангарский район, 8,351 км автодороги Ангарск-Тальяны</h3>
			<div class="grid-container">
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/5.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/6.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/7.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/8.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/9.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/10.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/11.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/12.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/13.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/14.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/15.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/16.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/17.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/18.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/19.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/20.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/21.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/22.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/23.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/24.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/25.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/27.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/28.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/29.webp" alt="Учебная аудитория" />
				</div>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об оборудованных учебных кабинетах</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tr>
						<th>Адрес</th>
						<th>Наименование оборудованного учебного кабинета</th>
						<th>Оснащенность оборудованного учебного кабинета</th>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Химическая лаборатория</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория микробиологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория разработки и эксплуатации беспилотных авиационных систем</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория рационального использования водных биоресурсов и экологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по геоэкологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория малоглубинной геофизики</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Медицинский пункт</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория сейсмического мониторинга</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по геологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по гидрогеологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по географии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория спектрального анализа</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория физики</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория виртуальной и дополненной реальности</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория анатомии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об объектах для проведения практических занятий</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование объекта</td>
							<td>Оснащенность объекта</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Химическая лаборатория</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория микробиологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория разработки и эксплуатации беспилотных авиационных систем</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория рационального использования водных биоресурсов и экологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по геоэкологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория малоглубинной геофизики</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория сейсмического мониторинга</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по геологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по гидрогеологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по географии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория спектрального анализа</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория физики</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория виртуальной и дополненной реальности</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория анатомии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, ул. Рабочего Штаба, 15</td>
							<td>Конференц-зал, лаборатория электродинамики и звуковые волны</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, ул. Рабочего Штаба, 15</td>
							<td>Компьютерный класс, лаборатория робототехники</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, ул. Рабочего Штаба, 15</td>
							<td>Компьютерный класс, лаборатория возобновляемой энергетики</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория промышленных агротехнологий</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Цифровая лаборатория нейротехнологий</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория «Интернет вещей в быту и производстве»</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория электротехники и электроники</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория «Умный город и безопасность»</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория «Разработки виртуальной и дополненной реальности, 3D моделирования и графики»)</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация о библиотеке</summary>
			<p class="Logistics-details-dist">
				Слушателям и преподавателям обеспечен доступ к электронно-библиотечной системе <a class="Logistics-link"
					href="https://biblioclub.ru/index.php?page=book_blocks&view=main_ub">«Университетская библиотека online»</a>,
				которая насчитывает 130 тысяч экземпляров образовательной, научной, интеллектуальной, деловой литературы.
			</p>
			<p class="Logistics-details-dist">
				<a class="Logistics-link" href="https://biblioclub.ru/index.php?page=book_blocks&view=main_ub">Университетская
					библиотека online</a> – это электронная библиотека, обеспечивающая слушателям доступ к наиболее востребованным
				материалам по всем отраслям знаний от российских ведущих издательств, это собрание учебной, специальной и
				справочной литературы самого разнообразного профессионального профиля, необходимое каждому обучающемуся.
				Предлагается обширная подборка словарей, справочников и энциклопедий.
			</p>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об объектах спорта</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование объекта спорта</td>
							<td>Оснащенность объекта</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей», 4 корпус</td>
							<td>Кабинет №10 (тренажерный зал)</td>
							<td>
								Дорожка беговая NordicTrack Commercial 245;<br>
								Велотренажер Freemotion u 10.2;<br>
								Прямая скамья для пресса;<br>
								Тренажер для мышц спины;<br>
								Комплект гантелей (20 шт.);<br>
								Стойка для приседаний (скамья для силовых тренировок) WEINDER PRO 7500;<br>
								Медболы разных весов;<br>
								Гиря «Титан», вес от 6 кг. до 32 кг.;<br>
								Силовой тренажер со встроенными весами (кроссовер со стеком 60 кг., силовая рама);<br>
								Гантели сборные;<br>
								Скамейка с регулируемым уровнем спинки<br>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об условиях питания обучающихся</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование помещения</td>
							<td>Характеристика помещения</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Столовая</td>
							<td>Площадь столовой – 1183,9 м², количество посадочных мест – 280</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об условиях охраны здоровья обучающихся</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование помещения</td>
							<td>Характеристика помещения</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Медицинский пункт</td>
							<td>Площадь медицинского пункта – 266,2 м²</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация о доступе к информационным системам и
				информационно-телекоммуникационным сетям</summary>
			<p class="Logistics-details-dist">
				Каждый обучающийся в течение всего периода обучения обеспечен индивидуальным неограниченным доступом к
				электронной информационно-образовательной среде образовательного центра из любой точки, в которой имеется доступ
				к информационно-телекоммуникационной сети «Интернет», как на территории образовательного центра, так и за его
				пределами.
			</p>
			<p class="Logistics-details-dist">
				Все виды учебных занятий и организация самостоятельной работы обучающихся предусматривают широкое применение в
				учебном процессе вычислительной техники и современных информационных технологий (мультимедийное оборудование,
				вычислительная техника, интерактивные доски, принтеры, сканеры, ноутбуки, графические планшеты, документ-камера
				и т.д.).
			</p>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об электронных образовательных ресурсах, к которым
				обеспечивается доступ обучающихся</summary>
			<p class="Logistics-details-dist">
				Информационные системы, используемые в ГАНУДО ИО Образовательный центр «Персей», позволяют автоматизировать
				процессы обучения.
			</p>
			<p class="Logistics-details-dist">
				Обучающимся доступны следующие электронные образовательные и информационные ресурсы:
			</p>

			<div class="StructureAndOrgans-table">
				<table width="100%">
					<tr>
						<th>№</th>
						<th>Наименование ресурса</th>
						<th>Порядок доступа</th>
						<th>Адрес ресурса</th>
					</tr>
					<tr>
						<td>1</td>
						<td>Официальный сайт Образовательного центра «Персей»</td>
						<td>Свободный доступ с любого устройства, подключенного к сети Интернет</td>
						<td><a href="https://perseusirk.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>2</td>
						<td>Электронная информационно -образовательная среда Образовательного центра «Персей»</td>
						<td>Свободный доступ с любого устройства, подключенного к сети Интернет</td>
						<td><a href="https://moodle.perseusirk.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
				</table>
			</div>

		</details>
	</div>
</template>
