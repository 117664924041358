<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Архив
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage182' })">
						<events-card182></events-card182>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage54' })">
						<events-card54></events-card54>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage31' })">
						<events-card31></events-card31>
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import EventsCard31 from '../EventsCard/EventsCard31';
import EventsCard54 from '../EventsCard/EventsCard54';
import EventsCard182 from '../EventsCard/EventsCard182';

	export default {
		components: {
			EventsCard31,
			EventsCard54,
			EventsCard182,
		}
	}
</script>
