<template>
	<div class="BestPrograms _cover">
		<h2 class="BestPrograms-title _cover-title">
			Реестр лучших программ отдыха детей и их оздоровления Иркутской области
		</h2>
		<hr>

		<p class="EventsPage-text">
			На основании письма министерства образования Иркутской области от 06.03.2023 г. №02-55-2485/23 ГАНУ ДО ИО Региональный центр выявления и поддержки одаренных детей "Образовательный центр "Персей" была проведена экспертиза программ организаций отдыха детей и их оздоровления. Всего на экспертизу было представлено 190 программ из 42 МО Иркутской области.
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				151 программа лагеря дневного пребывания
			</li>
			<li class="JuniorProfi-items">
				23 программы загородных лагерей
			</li>
			<li class="JuniorProfi-items">
				3 программы лагеря палаточного типа
			</li>
			<li class="JuniorProfi-items">
				13 программ лагерей государственных учреждений
			</li>
		</ul>
		<hr>

		<p class="Education-dist">
			Лучшие программы:
		</p>
		<ul class="AccessibleEnvironment-doc">
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82%D0%B8%D0%B7%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-1.pdf" target="_blank" download>
					Распоряжение об экспертизе программ
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/1.-%D0%9C%D0%91%D0%9E%D0%A3-%D0%A1%D0%9E%D0%A8-%E2%84%96-1-48.pdf" target="_blank" download>
					МБОУ СОШ № 1
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/06/%D0%A1%D0%9E%D0%A8-%E2%84%965-%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8F-%D0%A1%D0%B8%D0%B1%D0%B8%D1%80%D1%8F%D1%87%D0%BE%D0%BA-2023-1.pdf" target="_blank" download>
					СОШ №5 Программа лагеря Сибирячок 2023
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/3.-%D0%A8%D1%83%D0%BC%D1%81%D0%BA%D0%B0%D1%8F-%D0%A1%D0%9E%D0%A8.-%D0%97%D0%BE%D0%BB%D0%BE%D1%82%D1%8B%D0%B5-%D1%80%D1%83%D1%87%D0%BA%D0%B8-47.pdf" target="_blank" download>
					Шумская СОШ. Золотые ручки
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/4.-%D0%9C%D0%91%D0%9E%D0%A3-%D0%A3%D1%81%D1%82%D1%8C-%D0%A3%D0%B4%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F-%D0%A1%D0%9E%D0%A8-%E2%84%962-47.pdf" target="_blank" download>
					МБОУ Усть-Удинская СОШ №2
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/5.-%D0%9C%D0%9A%D0%9E%D0%A3-%D0%91%D0%B0%D1%80%D0%BB%D1%83%D0%BA%D1%81%D0%BA%D0%B0%D1%8F-%D0%A1%D0%9E%D0%A8-%D1%81.-%D0%91%D0%B0%D1%80%D0%BB%D1%83%D0%BA-46.pdf" target="_blank" download>
					МКОУ Барлукская СОШ с. Барлук
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/6.-%D0%9C%D0%9A%D0%9E%D0%A3-%D0%A6%D0%9E-%D0%9A%D0%B0%D1%80%D0%B0%D0%B7%D0%B5%D0%B9-47.pdf" target="_blank" download>
					МКОУ ЦО Каразей
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/7.-%D0%90%D0%BD%D0%B3%D0%B0%D1%80%D1%81%D0%BA-%D1%88%D0%BA-4-%D0%A1%D1%82%D1%80%D0%B0%D0%BD%D0%B0-%D0%BE%D1%80%D0%BB%D1%8F%D1%82-48.pdf" target="_blank" download>
					Ангарск шк 4 Страна орлят
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/8.-%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AF-%D0%9B%D0%B8%D0%B4%D0%B5%D1%80-2023-%D0%94%D0%94%D0%A2-48.pdf" target="_blank" download>
					Программа Я-Лидер 2023 ДДТ
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/9.-%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0-%D0%B8-%D0%BE%D0%B7%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9.%D0%A1%D0%9E%D0%A8-7.-%D0%93.%D0%A3%D1%81%D1%82%D1%8C-%D0%98%D0%BB%D0%B8%D0%BC%D1%81%D0%BA-48.pdf" target="_blank" download>
					Программа организации отдыха и оздоровления детей.СОШ 7. Г.Усть-Илимск
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/10.-%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0-%D0%B8-%D0%BE%D0%B7%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9.%D0%A1%D0%9E%D0%A8-15.-%D0%93.%D0%A3%D1%81%D1%82%D1%8C-%D0%98%D0%BB%D0%B8%D0%BC%D1%81%D0%BA-49.pdf" target="_blank" download>
					Программа организации отдыха и оздоровления детей.СОШ 15. Г.Усть-Илимск
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/11.-%D0%93%D0%9E%D0%9A%D0%A3-%D0%A1%D0%9A%D0%A8-%D0%A3%D1%81%D1%82%D1%8C-%D0%98%D0%BB%D0%B8%D0%BC%D1%81%D0%BA-52.pdf" target="_blank" download>
					ГОКУ СКШ Усть Илимск
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/12.-%D0%9C%D0%9A%D0%9E%D0%A3-%D0%A2%D1%83%D0%BB%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F-%D0%A1%D0%9E%D0%A8-45.pdf" target="_blank" download>
					МКОУ Тулинская СОШ
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/13.-%D0%9C%D0%91%D0%9E%D0%A3-%D0%A1%D0%9E%D0%A8-%D0%9D%D0%BE%D0%B2%D0%B0%D1%8F-%D0%AD%D1%80%D0%B0-45.pdf" target="_blank" download>
					МБОУ СОШ Новая Эра
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/14.-%D0%9C%D0%91%D0%9E%D0%A3-%D0%94%D0%9E-%D0%9A%D0%B0%D1%82%D0%B0%D0%BD%D0%B3%D1%81%D0%BA%D0%B8%D0%B9-%D0%A6%D0%94%D0%9E-45.pdf" target="_blank" download>
					МБОУ ДО Катангский ЦДО
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/15.-%D0%A1%D0%AE%D0%9D-%D0%97%D0%B5%D0%BB%D0%B5%D0%BD%D0%B0%D1%8F-%D0%BF%D0%BB%D0%B0%D0%BD%D1%82%D0%B0-44-1.pdf" target="_blank" download>
					СЮН Зеленая планета
				</a>
			</li>
			<li class="AccessibleEnvironment-file">
				<a class="Financial-doc" href="https://starsite.perseusirk.ru/wp-content/uploads/2023/05/16.-%D0%9C%D0%90%D0%A3-%D0%94%D0%9E-%D0%94%D0%9E%D0%9E%D0%A6-%D0%9D%D0%B0%D0%B4%D0%B5%D0%B6%D0%B4%D0%B0-2023.pdf" target="_blank" download>
					МАУ ДО ДООЦ Надежда 2023
				</a>
			</li>
		</ul>
	</div>
</template>