<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			23.10.2023
		</p>
		<h2 class="NewsPage-title">
			Образовательный К.Л.И.К.
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage18.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Фонде «Золотое сечение» состоялась межрегиональная конференция для представителей образовательных центров по модели «Сириус».
			</p>
			<p class="NewsPage-sub-title">
				В Екатеринбурге с 18 по 20 октября 2023 года прошла межрегиональная конференция К.Л.И.К.: Коммуникации, Лидерство, Идея, Команда. Участие в ней приняли эксперты организаций дополнительного образования из Иркутской, Ростовской, Московской, Тульской, Свердловской, Воронежской, Тамбовской, Кемеровской, Новгородской, Челябинской областей, г. Санкт-Петербург, Республики Удмуртия, Забайкальского и Пермского краёв, а также Федеральной территории «Сириус».
			</p>
			<p class="NewsPage-sub-title">
				По словам организаторов конференции, основная цель мероприятия – создание новых возможностей для творческого роста и профессионального развития школьников. Для этого необходимо налаживать связи между педагогами разных федеральных округов, экспертами из различных региональных центров, делиться опытом, масштабировать удачные проекты, обмениваться лучшими практиками. Должен появиться концепт проекта, в который будут зашиты смыслы: образовательный туризм для детей и педагогов, обмен лучшими практиками, демонстрация достижений наставников и обучающихся.
			</p>
			<p class="NewsPage-sub-title">
				За три дня участники успели побывать на нескольких семинарах, в Загородном образовательном центре «Таватуй», где для них специально была организована выставка работ обучающихся по направлениям «Искусство» и «Народные промыслы». Здесь же эксперты смогли посмотреть, как ребята занимаются на интенсивах. Также для участников прошли лекции по медиа-сопровождению и PR-технологиям от специалистов ЦУР Свердловской области и Фонда «Талант и Успех».
			</p>
			<p class="NewsPage-sub-title">
				В последний день конференции эксперты из разных региональных центров делились своими идеями по межрегиональному взаимодействию, обсуждали общие для всех точки роста в плане развития педагогов и обучающихся, работу по сопровождению выпускников образовательных программ и конкурсов.
			</p>
			<p class="NewsPage-sub-title">
				– Активное межрегиональное сотрудничество поможет развитию не только центов, но и популяризации самой системы выявления, поддержки и развития одарённых детей по всей России. Есть опыт и практики, которые мы можем перенимать друг у друга. Есть и идеи, которые мы можем реализовывать сообща. Все это нам поможет в достижении одной общей цели: создать условия, в котором каждый одарённый ребенок сможет реализовать свой потенциал на благо нашей страны, – отметила участница конференции К.Л.И.К., специалист по связям с общественностью Образовательного центра «Персей» Анастасия Говорова.
			</p>
			<p class="NewsPage-sub-title">
				По итогам работы будет разработана дорожная карта, нового глобального межрегионального проекта, который расширит коммуникативные возможности региональных центров, педагогов и обучающихся, а также будут проработаны идеи программ для образовательного туризма.
			</p>
			
		</div>
	</div>
</template>
