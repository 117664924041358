<template>
	<div class="MainOlympics _cover">
		<div class="MainOlympics-box">
			<div class="MainOlympics-img-box">
				<a class="MainOlympics-img-link" @click="$router.push({ name: 'VsoshPage' })">
					<img class="MainOlympics-img" src="@/assets/img/svg/ВСОШ.svg"
						alt="«Всероссийская олимпиада школьников» 2023-2024г»" loading="lazy"/>
				</a>
			</div>
			<div class="MainOlympics-img-box">
				<a class="MainOlympics-img-link" @click="$router.push({ name: 'GoldenFundPage' })">
					<img class="MainOlympics-img" src="@/assets/img/Logo/ZFSLogo.webp"
						alt="«Золотой фонд Сибири» 2023-2024" loading="lazy"/>
				</a>
			</div>
			<div class="MainOlympics-img-box">
				<a class="MainOlympics-img-link" @click="$router.push({ name: 'BigChallengesPage' })">
					<img class="MainOlympics-img" src="@/assets/img/svg/болвыз.webp" alt="«Большие вызовы» 2023-2024г" loading="lazy"/>
				</a>
			</div>
			<div class="MainOlympics-img-box">
				<a class="MainOlympics-img-link" @click="$router.push({ name: 'JuniorProfiChampionshipPage' })">
					<img class="MainOlympics-img" src="@/assets/img/svg/юниор.webp"
						alt="VII Региональный чемпионат компетенций ЮниорПрофи Иркутской области" loading="lazy"/>
				</a>
			</div>
			<div class="MainOlympics-img-box">
				<a class="MainOlympics-img-link" @click="$router.push({ name: 'OlympicPage' })">
					<img class="MainOlympics-img" src="@/assets/img/svg/Олимпик.webp" alt="«Олимпик»" loading="lazy"/>
				</a>
			</div>
		</div>
	</div>
</template>
