<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage53.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				В Приангарье подвели итоги регионального этапа Всероссийской олимпиады школьников
			</h2>
			<p class="NewsCard-date">
				08.03.2024
			</p>
		</div>
	</div>
</template>