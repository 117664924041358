<template>
	<div class="RecommendationsForTeachers _cover">
		<h2 class="RecommendationsForTeachers _cover-title">
			Рекомендации для педагогов при работе с одарёнными детьми
		</h2>

		<ul class="AccessibleEnvironment-list">
			<li class="AccessibleEnvironment-items">
				Изучайте индивидуальные особенности, особенности поведения одарённого ребёнка.
			</li>
			<li class="AccessibleEnvironment-items">
				Совершенствовать систему развития способностей
			</li>
			<li class="AccessibleEnvironment-items">
				Должное внимание уделять индивидуальности и дифференциации обучения на уроках и во внеурочное время, уменьшив
				нагрузку в расписании и выделив большее количество часов на кружковую и индивидуальную работу с одаренными
				детьми. При этом должен присутствовать принцип добровольности выбора внеурочных занятий
			</li>
			<li class="AccessibleEnvironment-items">
				Активно использовать проблемно-исследовательский метод, развивая познавательные и творческие способности
				учащихся. Известно, что активная самостоятельная работа мысли начинается тогда, когда перед учащимся возникает
				проблема. Обучение должно носить не репродуктивный, а творческий характер
			</li>
			<li class="AccessibleEnvironment-items">
				Необходимо создавать приложения к своим программам в виде набора оригинальных заданий, развивающих творческие
				способности, воображение, фантазию учащихся
			</li>
			<li class="AccessibleEnvironment-items">
				Преподавать на высоком уровне сложности, чтобы ученики все время поднимались до своего «потолка», тем самым
				поднимая свою планку все выше. Ориентация должна быть на опережение уже достигнутого уровня способностей,
				положительную мотивацию
			</li>
			<li class="AccessibleEnvironment-items">
				Для развития способностей нужна высокая познавательная активность подростка, причем не всякая деятельность
				развивает способности, а только эмоционально приятная. Поэтому занятия должны проходить в доброжелательной
				обстановке. Обязательно должна создаваться ситуация успеха
			</li>
			<li class="AccessibleEnvironment-items">
				Уважайте и обсуждайте любую идею
			</li>
			<li class="AccessibleEnvironment-items">
				Подготавливаясь к занятиям с одарёнными детьми, помните о необходимости серьёзной умственной нагрузке
				одарённого ребёнка. Самостоятельность мышления, вопросы к учителю, а потом и к самому себе – обязательные
				составные части успешности уроков
			</li>
			<li class="AccessibleEnvironment-items">
				Подумайте о методике обучения. Одаренные ученики требуют принципиально иной подготовки, поскольку их отличает
				необычайное стремление к перепроверке, к “уяснению для себя”, экспериментированию
			</li>
			<li class="AccessibleEnvironment-items">
				Центральной задачей педагога в работе с одаренным ребёнком является привитие вкуса к серьёзной творческой
				работе
			</li>
			<li class="AccessibleEnvironment-items">
				Развивайте в себе чувство юмора. Но необходимо помнить, что одарённые дети очень самолюбивы, ранимы, с
				обостренной чувствительностью – и не очень удачная шутка может их надолго выбить из колеи
			</li>
			<li class="AccessibleEnvironment-items">
				Постарайтесь создать благоприятную атмосферу работы с детьми. Будьте доброжелательными, не критикуйте.
				Одаренные дети наиболее восприимчивы
			</li>
			<li class="AccessibleEnvironment-items">
				Экспериментируйте на уроке. Не бойтесь оказаться смешными и в то же время докажите, что вас нужно уважать, а
				не бояться
			</li>
			<li class="AccessibleEnvironment-items">
				Позволяйте детям вести себя свободно и задавать вопросы. Если ребенок чем-то интересуется, значит, он думает,
				а если он думает, значит, учитель кое-чего достиг. После окончания школы, ученик может чего-то достичь, или
				просто стать хорошим человеком, и, следовательно, учитель свои обязанности выполнил
			</li>
		</ul>

		<h3 class="AccessibleEnvironment-title-h3">
			Качества, необходимые учителю для работы с одарёнными детьми:
		</h3>
		<ul class="AccessibleEnvironment-list">
			<li class="AccessibleEnvironment-items">
				Разбираться в психологических особенностях одаренных детей, учитывать их потребности и интересы
			</li>
			<li class="AccessibleEnvironment-items">
				Быть доброжелательным и чутким
			</li>
			<li class="AccessibleEnvironment-items">
				Уметь строить обучение в соответствии с результатами диагностического обследования ребенка
			</li>
			<li class="AccessibleEnvironment-items">
				Быть зрелым, т.е. четко осознавать свои цели и задачи, обладать обширными знаниями и опытом применения методик
				и стратегий обучения
			</li>
			<li class="AccessibleEnvironment-items">
				Быть эмоционально стабильным, т.е. необходимо быть собранным и хорошо владеть своими эмоциями и чувствами
			</li>
			<li class="AccessibleEnvironment-items">
				Иметь высокий уровень интеллектуального развития, широкий круг интересов и умений и стремление к постоянному
				самосовершенствованию
			</li>
			<li class="AccessibleEnvironment-items">
				Обладать чувством юмора
			</li>
			<li class="AccessibleEnvironment-items">
				Быть готовым к работе с одаренными детьми и к приобретению специальных знаний;
			</li>
			<li class="AccessibleEnvironment-items">
				Проявлять настойчивость, целеустремленность и обстоятельность
			</li>
			<li class="AccessibleEnvironment-items">
				Стимулировать когнитивные способности учащихся
			</li>
		</ul>
	</div>
</template>