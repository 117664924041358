<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			29.02.2024
		</p>
		<h2 class="NewsPage-title">
			Стартовал набор заявок на вторую программу в области ИИ для школьных команд
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage47.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				<b>В Образовательном центре во второй раз состоится <a class="NewsPage-link" href="https://sochisirius.ru/obuchenie/distant/smena1758/8142">программа «Сириус.ИИ»</a>. Прием заявок для участия в конкурсном отборе открыт до 5 марта 2024 года. По условиям программы, ребята из 7–11-х классов должны организовать команду и реализовать на выбор один из проектов, предложенных партнерами «Сириуса». Далее школьники защищают свою идею перед экспертами. Авторов лучших разработок пригласят в мае на очный этап в «Сириус», где школьники смогут доработать свои идеи под руководством наставников.</b>
			</p>
			<p class="NewsPage-sub-title">
				На <b>первом этапе – подготовительном</b> – до 5 марта ребятам в своих городах необходимо сформировать команду, состоящую из капитана и участников (от одного до трех), а также выбрать проектную задачу.
			</p><br>
			<p class="NewsPage-sub-title">
				Регистрация капитана команды доступна по ссылке: <a class="NewsPage-link" href="https://q.sirius.ru/t/n7PZM">https://q.sirius.ru/t/n7PZM</a>
			</p>
			<p class="NewsPage-sub-title">
				Регистрация участника команды доступна по ссылке:  <a class="NewsPage-link" href="https://q.sirius.ru/t/WNR6W">https://q.sirius.ru/t/WNR6W</a>
			</p>
			<p class="NewsPage-sub-title">
				<b>Второй – отборочный этап</b> – состоит из двух туров. С 19 февраля по 5 марта команды будут работать над решением выбранной задачи. Итоги необходимо оформить в презентацию и загрузить в заявку. С 5 по 10 марта будет проходить экспертиза проектов – специалисты будут оценивать решения ребят.
			</p>
			<p class="NewsPage-sub-title">
				Далее стартует второй тур отборочного этапа. С 11 по 24 марта команды – победители первого тура – смогут доработать свои проектные решения. А 25–27 марта команды должны будут защитить идею своего решения перед экспертами в формате онлайн-конференции.
			</p>
			<p class="NewsPage-sub-title">
				Итоговый список команд-победительниц, которые приедут на очную программу в «Сириус», будет опубликован <b>28 марта 2024 года.</b>
			</p>
			<p class="NewsPage-sub-title">
				<b>Итоговый – очный этап программы</b> – в Образовательном центре будет проходить с 29 апреля по 5 мая 2024 года. Школьники получат возможность доработать свои идеи под руководством экспертов со стороны индустриальных и академических партнеров «Сириуса».
			</p>
			<p class="NewsPage-sub-title">
				С задачами можно ознакомиться на <a class="NewsPage-link" href="https://sochisirius.ru/obuchenie/distant/smena1758/8143">странице программы</a> на сайте «Сириуса».
			</p>
			<p class="NewsPage-sub-title">
				По заданию от <b>Научно-технологического университета «Сириус»</b> школьникам нужно будет создать систему видеоаналитики для управления очередью в ресторане Университета, где обедают многочисленные сотрудники, студенты и гости.
			</p>
			<p class="NewsPage-sub-title">
				По условиям задачи от <b>«Яндекса» и факультета компьютерных наук Высшей школы экономики</b> командам предстоит создать прототип сервиса для оценки стоимости недвижимости, в основу которого ляжет модель машинного обучения.
			</p>
			<p class="NewsPage-sub-title">
				По заданию от <b>Банка ВТБ</b> школьники будут работать над алгоритмом, который, используя искусственный интеллект, будет анализировать данные о транзакциях криптовалюты в сети блокчейн и предотвращать подозрительные операции.
			</p>
			<p class="NewsPage-sub-title">
				<b>VK</b> предлагает командам создать прототип системы, которая сможет «читать» многочисленные сообщения в групповых чатах и выделять для пользователя самое важное из переписки.
			</p>
			<p class="NewsPage-sub-title">
				Первая программа по искусственному интеллекту для школьных команд проходила в Образовательном центре в 2023 году. Всего было подано свыше двух тысяч заявок со всей страны. В очном этапе приняли участие 72 школьника, которые объединились в 22 команды, в их числе и школьники из Братска.
			</p>
			<p class="NewsPage-sub-title">
				Команда из школы № 46 г. Братска в лице Андрея Калячко, Ильи Рукавицына и Льва Журавлева включились в работу над задачей от «Тинькофф Банка». Ребята расширяли функциональность разговорного помощника «Тинькофф Джуниор».
			</p>
			<p class="NewsPage-sub-title">
				Чат-боты в банковских приложениях выполняют функцию ассистента, который может проконсультировать пользователей. «Тинькофф Джуниор» способен отвечать на простые и популярные вопросы, что полезно в том числе юным пользователям, ведь они только начинают знакомство с банковской сферой. Для решения задачи участникам предстояло предложить и продумать идеи, как интегрировать новые навыки для чат-бота.
			</p>
			<p class="NewsPage-sub-title">
				Школьники за три дня внедрили в искусственный интеллект полезные навыки, которые помогают детям в решении сложных вопросов: от помощи в выполнении домашнего задания до совершения банковских операций. Заказчик высоко оценил программные решения ребят.
			</p>
			<p class="NewsPage-sub-title">
				Все подробности об условиях подачи заявок, сроках и задачах от партнеров представлены на странице <a class="NewsPage-link" href="https://sochisirius.ru/obuchenie/distant/smena1758/8142">программы «Сириус.ИИ»</a> Образовательного центра «Сириус».
			</p>

			
		</div>
	</div>
</template>
