<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Волонтёры культуры»
		</h2>
		<p class="EventsPage-date">
			с 27 сентября по 10 октября 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21762-programma-volontery-kultury" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/" target="_blank">
			Регистрация в MOODLE
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				Сыроватская Ангелина Геннадьевна
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				89148810258
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				perseus@perseusirk.ru
			</p>
		</div>
		<hr>
		<h2 class="EventsPage-title">
			Списочный состав обучающихся, прошедших отбор и приглашённых на очную профильную смену
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Новикова Елизавета Андреевна</li>
			<li class="EventsPage-number">Желтякова Анна Евгеньевна</li>
			<li class="EventsPage-number">Габдрашитова Кристина Мовсесовна</li>
			<li class="EventsPage-number">Усатенко Лиана Сергеевна</li>
			<li class="EventsPage-number">Гурьянова Ангелина Дмитриевна</li>
			<li class="EventsPage-number">Герасимова Екатерина Евгеньевна</li>
			<li class="EventsPage-number">Осмоналиев Даниель Даниярович</li>
			<li class="EventsPage-number">Коньков Николай Николаевич</li>
			<li class="EventsPage-number">Липасова Полина Егоровна</li>
			<li class="EventsPage-number">Тапхарова Дарья Викторовна</li>
			<li class="EventsPage-number">Мясовская Ирина Игоревна</li>
			<li class="EventsPage-number">Небогатова Жанна Олеговна</li>
			<li class="EventsPage-number">Новолодский Егор Олегович</li>
			<li class="EventsPage-number">Дьячук Виктория Сергеевна</li>
			<li class="EventsPage-number">Свидинская Александра Дмитриевна</li>
			<li class="EventsPage-number">Семке Александра Евгеньевна</li>
			<li class="EventsPage-number">Эрленбуш Татьяна Ярославовна</li>
			<li class="EventsPage-number">Господарик Виктория Викторовна</li>
			<li class="EventsPage-number">Сотина Дарина Сергеевна</li>
			<li class="EventsPage-number">Шандакова Евгения Павловна</li>
			<li class="EventsPage-number">Кувшинов Артур Максимович</li>
			<li class="EventsPage-number">Коблова Варвара Михайловна</li>
			<li class="EventsPage-number">Торшина Светлана Игоревна</li>
			<li class="EventsPage-number">Непомнящая Арина Сергеевна</li>
			<li class="EventsPage-number">Гриценко Анна Вадимовна</li>
			<li class="EventsPage-number">Матвеева Екатерина Витальевна</li>
			<li class="EventsPage-number">Сингилёва Елизавета Романовна</li>
			<li class="EventsPage-number">Хмелева Анастасия Геннадьевна</li>
			<li class="EventsPage-number">Демушкина Екатерина Дмитриевна</li>
			<li class="EventsPage-number">Анисимова Алёна Николаевна</li>
			<li class="EventsPage-number">Мешкова Кира Вадимовна</li>
			<li class="EventsPage-number">Грехова Анастасия Сергеевна</li>
			<li class="EventsPage-number">Митрачкова Евдокия Алексеевна</li>
			<li class="EventsPage-number">Колтович Тимур Данилович</li>
			<li class="EventsPage-number">Макарова Арина Дмитриевна</li>
			<li class="EventsPage-number">Кураленко Ирина Дмитриевна</li>
			<li class="EventsPage-number">Белогрудова Елизавета Андреевна</li>
			<li class="EventsPage-number">Живулина Анна Ивановна</li>
			<li class="EventsPage-number">Маковеев Евгений Игоревич</li>
			<li class="EventsPage-number">Миронова Ирина Викторовна</li>
			<li class="EventsPage-number">Байбородина Дарья Алексеевна</li>
			<li class="EventsPage-number">Волков Марк Янович</li>
			<li class="EventsPage-number">Краснова Яна Андреевна</li>
			<li class="EventsPage-number">Савченко Диана Васильевна</li>
			<li class="EventsPage-number">Мартынова Яна Анатольевна</li>
			<li class="EventsPage-number">Мухаметова Татьяна Андреевна</li>
			<li class="EventsPage-number">Ушакова Екатерина Николаевна</li>
			<li class="EventsPage-number">Мартынова Ангелина Евгеньевна</li>
			<li class="EventsPage-number">Латышев Руслан Андреевич</li>
			<li class="EventsPage-number">Павлова Алина Павловна</li>
			<li class="EventsPage-number">Свирина Анастасия Викторовна</li>
			<li class="EventsPage-number">Дубовикова Полина Петровна</li>
			<li class="EventsPage-number">Муртазина Полина Рафиковна</li>
			<li class="EventsPage-number">Плотникова Ева Михайловна</li>
			<li class="EventsPage-number">Захарова Светлана Дмитриевна</li>
			<li class="EventsPage-number">Зайцев Иван Александрович</li>
			<li class="EventsPage-number">Распутин Семён Иванович</li>
			<li class="EventsPage-number">Борисова Светлана Евгеньевна</li>
			<li class="EventsPage-number">Михайличенко Юлия Олеговна</li>
			<li class="EventsPage-number">Башкирова Полина Дмитриевна</li>
			<li class="EventsPage-number">Латышева Анастасия Валерьевна</li>
			<li class="EventsPage-number">Ярцева Ульяна Евгеньевна</li>
			<li class="EventsPage-number">Гончаровская Александра Андреевна</li>
			<li class="EventsPage-number">Москалёв Данил Анатольевич</li>
			<li class="EventsPage-number">Безносов Владислав Фёдорович</li>
			<li class="EventsPage-number">Купряков Денис Александрович</li>
			<li class="EventsPage-number">Чукова Екатерина Сергеевна</li>
			<li class="EventsPage-number">Константинова Яна Андреевна</li>
			<li class="EventsPage-number">Филиппов Трофим Александрович</li>
			<li class="EventsPage-number">Ковалева Мария Константиновна</li>
			<li class="EventsPage-number">Шипицин Никита Сергеевич</li>
			<li class="EventsPage-number">Баренцева Милана Владимировна</li>
			<li class="EventsPage-number">Бухарова Юлия Васильевна</li>
			<li class="EventsPage-number">Юркина Анастасия Игоревна</li>
			<li class="EventsPage-number">Соколова Валерия Олеговна</li>
			<li class="EventsPage-number">Грабарчук Лидия Ивановна</li>
			<li class="EventsPage-number">Лысов Илья Евгеньевич</li>

		</ol>
		<hr>
		<p class="EventsPage-sub-title">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену «Волонтёры культуры» для обучающихся 14-17 лет общеобразовательных организаций.
			Дополнительная общеразвивающая программа «Волонтёры культуры» ориентирована на обучающихся образовательных организаций Иркутской области в возрасте от 14 до 17 лет, проявивших себя в проектной, творческой деятельности и направлена на повышение общекультурных, коммуникативных, технологических, психологических и специальных компетенций подростков, интегрированных под специфику и направления добровольческой деятельности.
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-sub-title">
			Горчакова Александра Сергеевна, заместитель директора ГБУК «Иркутская областная юношеская библиотека им. И.П. Уткина»;<br>
			Шантанов Родион Викторович, руководитель штаба волонтеров Фестиваля современного искусства «Территория. Иркутск» Иркутской региональной общественной организации по сохранению и развитию этнокультурного наследия «Возрождение».<br>
			Профильная смена состоится с 27 сентября по 10 октября 2023 года на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).<br>
		</p>
	</div>
</template>