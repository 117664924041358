<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дополнительная профессиональная программа повышения квалификации
		</h3>
		<h2 class="EventsPage-title">
			«Применение цифровых ресурсов в организации отдыха детей и их оздоровления»
		</h2>
		<hr>

		<p class="EventsPage-text">
			Целью курса является совершенствование профессиональных компетенций слушателей в сфере применения цифровых
			ресурсов в организациях отдыха детей и их оздоровления.
		</p>
		<p class="EventsPage-text">
			Объем программы: 72 часа
		</p>
		<p class="EventsPage-text">
			Категория слушателей: воспитатели организаций отдыха детей и их оздоровления педагоги-организаторы, вожатые
		</p>
		<p class="EventsPage-text">
			Аннотация: в рамках изучения программы повышения квалификации, слушатели изучат цифровые программные продукты и
			познакомятся с педагогическими техниками и методами, основанными на цифровых ресурсах. А также получат
			представление о возможностях использования цифровых ресурсов в различных направленностях воспитательной работы в
			организациях отдыха детей и их оздоровления.
		</p>
		<p class="EventsPage-text">
			По итогам изучения курса слушатели должны представить разработку воспитательного мероприятия с использованием
			современных цифровых ресурсов
		</p>
		<p class="EventsPage-text">
			Слушатели, успешно завершившие курсовую подготовку, получают удостоверение установленного образца о повышении
			квалификации.
		</p>
		<p class="EventsPage-text">
			<b>Обучение проходит в очно-заочной форме с использованием дистанционных образовательных ресурсов на платформе
				Мудл (Moodle)
			</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сорока Сергей Сергеевич, заведующий отделом выявления и сопровождения одаренных детей, образовательного центра
				«Персей»
			</li>
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, руководитель отдела информационно-технического обеспечения
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Соловьева Дарья Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: d.soloveva@perseusirk.ru
			</li>
		</ul>

	</div>
</template>