<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			04.03.2024
		</p>
		<h2 class="NewsPage-title">
			Итогом февральской программы по хореографии стала победа школьников на Первенстве Иркутской области
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage48.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В феврале этого года в Образовательном центре «Персей» прошла первая профильная смена «Лаборатория творчества: Street Dance», где 33 юных танцора изучали хип-хоп хореографию и готовились к предстоящим танцевальным соревнованиям: Чемпионат и Первенство Иркутской области по фитнес-аэробике, Областной фестиваль «Hip Hop Unite BAIKAL» и Областной фестиваль фитнес дисциплин «Я выбираю спорт», на которых они заняли победные и призовые места.
			</p>
			<p class="NewsPage-sub-title">
				Как проходила работа на смене и подготовка к соревнованиям, читайте в нашем материале.
			</p>
			<p class="NewsPage-sub-title">
				Обучение было насыщено практическими занятиями. Как рассказал преподаватель программы Ольга Лелюк, смена в Образовательном центре совпала с календарным планом подготовки танцевальных спортивных команд к стартам 2024 года.
			</p>
			<p class="NewsPage-sub-title">
				– На смене был реализован предсоревновательный мезоцикл,  который предназначен для окончательного становления спортивной формы танцора. Работа на занятиях велась за счет устранения отдельных недостатков, выявленных в ходе подготовки спортсменов, и направлена на совершенствование технического и исполнительского мастерства, – подчеркнул педагог.
			</p>
			<p class="NewsPage-sub-title">
				Школьникам за две недели удалось усовершенствовать общую физическую подготовку, базовые навыки в стилях хип-хоп хореографии: локинг, брейкинг, хип-хоп, а также освоить исполнение трюковой акробатики.
			</p>
			<p class="NewsPage-sub-title">
				–  Для данного мезоцикла характерна высокая интенсивность тренировочного процесса. В утренние часы была силовая нагрузка и база танцевальных стилей. После обеда – практическое применение, а это работа над постановкой и «чисткой» программ для выступления. Много времени мы выделили на психическую и тактическую подготовку танцоров, а также  на обеспечение восстановления после предшествующей напряженной подготовки и создание условий для завершения формирования реакций адаптации, – дополнила Ольга.
			</p>
			<p class="NewsPage-sub-title">
				Результаты подготовки ребята продемонстрировали на отчетном концерте.   Там танцоры исполнили свои программы и сошлись в командном баттле, подготовкой которого участники программы занимались самостоятельно по заданию тренера.
			</p>
			<p class="NewsPage-sub-title">
				После завершения обучения ребята выступили на Чемпионате и Первенстве Иркутской области по фитнес-аэробике в дисциплинах: хип-хоп и хип-хоп большая группа, где заняли первое и второе место. А также приняли участие в Областном Фестивале «Hip-Hop Unite BAIKAL» и Областном Фестивале фитнес дисциплин «Я выбираю спорт».
			</p>
			<p class="NewsPage-sub-title">
				Напомним, смена проходила в кампусе Образовательного центра «Персей» с 7 по 20 февраля, участие в ней приняли 33 школьника из Иркутска и Ангарска. Сюжет с отчетного концерта участников программы вы можете посмотреть в наших социальных сетях: <a class="NewsPage-link" href="https://vk.com/perseusirk">https://vk.com/perseusirk</a>
			</p>

		</div>
	</div>
</template>
