<template>
	<div class="Sing">
		<h2 class="Sing-title">
			Авторизация
		</h2>
		<form class="Sing-form" id="loginForm">
			<label class="Sing-parameter" for="email">
				Почта:
			</label>
			<input class="Sing-data" type="email" id="email" placeholder="@mail.ru" required>

			<label class="Sing-parameter" for="password">
				Пароль:
			</label>
			<input class="Sing-data" type="password" id="password" required>

			<div class="checkbox-block">
				<input class="checkbox" type="checkbox" id="rememberMe">
				<label class="checkbox-title" for="rememberMe">
					Запомнить меня
				</label>
			</div>


			<button class="Sing-btn temp-btn" type="submit">
				Войти
			</button>
		</form>
	</div>
</template>