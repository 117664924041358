<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			11.01.2024
		</p>
		<h2 class="NewsPage-title">
			Школьница из Приангарья прошла в полуфинал всероссийской телеолимпиады «Умницы и умники»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage41.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				Ученица 11 класса школы № 4 г. Железногорск-Илимского Алена Хрущева вошла в число полуфиналистов 32 сезона всероссийской гуманитарной телевизионной олимпиады «Умницы и умники».
			</p>
			<p class="NewsPage-sub-title">
				Также участие в четвертьфинале приняли школьницы: Кристина Зелинская из средней школы № 21 г. Иркутска им. Ю.А. Гагарина, Анна Юрина из Иркутской гимназии № 3 и Дарья Протопопова из Каменской средней школы Боханского района. Все они вошли в число победителей регионального этапа телеолимпиады, который проходил в мае прошлого года в Образовательном центре «Персей». Напомним, Алена Хрущева стала абсолютным победителем регионального этапа.
			</p>
			<p class="NewsPage-sub-title">
				По информации организаторов телеолимпиады, одинадцатиклассницы участвовали в съемках программ по темам:  Эпоха при правлении Александра III, 1944 год во всем мире и Российская Арктика: география, история, экономика, народы и люди.
			</p>
			<p class="NewsPage-sub-title">
				В четвертьфинале Алена получила два ордена за правильные ответы по теме: эпоха Александра III, тем самым обеспечив себе выход в полуфинал, съемки, которого пройдут в феврале этого года.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказывает сама школьница, подготовка к состязанию проходила комплексно и плодотворно. В этом ей помогали учителя и библиотекари.
			</p>
			<p class="NewsPage-sub-title">
				– Страха не было, было желание пройти в следующий этап. Но в то же время я понимала, что это игра. Помимо знаний, здесь нужно и простое везение. Я изучала статьи, читала книги и учебники, смотрела документальные фильмы, которые мне помогли найти учителя и библиотекари. Главная сложность в том, что нам не дают списки источников, где будут ответы на вопросы: поэтому приходится работать с большим объемом информации. За такой короткий срок всё изучить невозможно. Но чем больше ты прочитаешь, тем больше шансов. На агоне (прим. ред. на дорожке) я не прошла дальше, но ответила на вопросы уже из зала. Очень рада, что прошла дальше и, конечно, хочу побороться за место в МГИМО. Темы полуфинала нам пока не сообщили, поэтому сейчас повторяю старое, так как будет игра «Шанс» по прошлым темам, – поделилась школьница.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, «Умницы и Умники» – интеллектуальная гуманитарная олимпиада для старшеклассников, где школьники в открытой борьбе состязаются за право стать студентами Московского Государственного института международных отношений. В прошлом году победителем стал выпускник Центра образования № 47 г. Иркутска Артем Селиверстов.
			</p>
			<p class="NewsPage-sub-title">
				Для справки: участие в региональном этапе телеолимпиады «Умницы и умники» могут принять школьники 10 классов. Для этого необходимо подать заявку на отбор в одноименной профильной смене, которая пройдет в июне 2024 года в кампусе Образовательного центра «Персей». По итогам обучения школьники смогут побороться за выход в четвертьфинал 33 сезона передачи. Информация по участию в образовательной программе будет опубликована не раньше мая 2024 года на сайте «Персея» во вкладке: <a class="NewsPage-link" href="https://perseusirk.ru/ProfileBlock">профильные смены</a>.
			</p>

		</div>
	</div>
</template>
