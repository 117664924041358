<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			26.12.2023
		</p>
		<h2 class="NewsPage-title">
			В «Персее» завершился дистанционный курс по фотографии
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage37.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В ноябре этого года в Образовательном центре «Персей» стартовал дистанционный курс «Секреты фотосъемки». Участие в программе приняли 56 школьников.
			</p>
			<p class="NewsPage-sub-title">
				В программе курса были включены модули по изучению истории фотографии, оборудования фотографа, основа экспозиции, композиции в фотографии, жанров фотографии, а также постобработки и продвижению работ.
			</p>
			<p class="NewsPage-sub-title">
				Как поясняют преподаватели курса, главными задачами было объяснить ребятам взаимосвязь между светом и фотографией, дать понятия по основным терминам, рассказать об оборудовании современного фотографа и поделиться своим опытом в разножанровых съемках. Так ребята изучили теоретические основы репортажной, предметной, портретной, студийной и пейзажной съемки.
			</p>
			<p class="NewsPage-sub-title">
				– Фотография – это больше про практику. В условиях дистанционного обучения, не имея возможности показать ребенку, как нужно сделать, было бы тяжело объяснить все тонкости и нюансы съемок. Поэтому мы решили сделать упор на личный опыт работы и рассказать ребятам теоретические основы через советы, которые были полезны всем начинающим фотографам. Задача была в том, чтобы, когда школьник взял в руки камеру, он смог сделать кадр обдуманно и по всем правилам, которые мы изучали. Для этого мы решали кейсы, придумывали сюжеты для съемок и думали, как бы в этих условиях строили свою работу. Поэтому, когда ребята уже выйдут на практические занятия или продолжат самостоятельно изучать фотографию, они уже будут знать, как им нужно выставить свет, настройки камеры, а самое главное – как и что снимать,  – рассказал Сергей Хранивский, преподаватель курса.
			</p>
			<p class="NewsPage-sub-title">
				Дистанционный курс – эта первая программа в блоке по изучению фотографии в Образовательном центре. В 2024 году школьники уже продолжат знакомиться фотографией на еженедельных курсах и профильных сменах в «Персее» где научатся применять теоретические знания на практике.
			</p>

		</div>
	</div>
</template>
