<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Классический танец»
		</h2>
		<p class="EventsPage-date">
			с 03 июля по 16 июля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=169" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25065-programma-klassicheskii-tanets"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену
			«Классический танец» для обучающихся организаций дополнительного образования по направлению хореографическое
			искусство от 10 до 12 лет, владеющих навыками в области классической хореографии.
		</p>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				готовность к занятиям суставно-связочного аппарата обучающегося (растяжка, постановка корпуса, развитая
				координация);
			</li>
			<li class="JuniorProfi-items">
				наличие навыков и знаний в области ОФП и классического танца;
			</li>
			<li class="JuniorProfi-items">
				умение ориентироваться в классе и на сцене;
			</li>
			<li class="JuniorProfi-items">
				наличие практического сценического опыта выступлений, а именно знания основ построения рисунка, танцевальную
				терминологию основных движений классического танца, и правильное их исполнение.
			</li>
		</ul>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Дегтерева Наталья Викторовна, преподаватель ГБПОУ Иркутский областной колледж культуры
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
			Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе
				электронной информационно-образовательной среды сайта ОЦ «Персей» до 21 июня 2024 года по ссылке:
				https://moodle.perseusirk.ru/course/view.php?id=169, где необходимо прикрепить:
			</li>
			<p class="EventsPage-text">
				Портфолио с дипломами, грамотами за участие в конкурсах регионального и всероссийского уровня в номинации
				«Классический танец» за 2023 и 2024 год;
			</p>
			<p class="EventsPage-text">
				Мотивационное письмо на тему «Классический танец в моей жизни», в котором написать о себе, о наиболее значимых
				мероприятиях, в которых вы принимали участие как исполнитель классической хореографии (не более 1000 символов).
			</p>
			<li class="JuniorProfi-items">
				Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке6
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25065-programma-klassicheskii-tanets.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 25 июня 2024г. в 19.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1716015146">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Агапитова Александра Николаевна</li>
			<li class="EventsPage-number">Войцеховская Ангелина Викторовна</li>
			<li class="EventsPage-number">Глушкова София Эрнестовна</li>
			<li class="EventsPage-number">Дугарова Алиса Константиновна</li>
			<li class="EventsPage-number">Дудакова Ольга Александровна</li>
			<li class="EventsPage-number">Зайнулина Арина Рамилевна</li>
			<li class="EventsPage-number">Залыгина Лилиана Алексеевна</li>
			<li class="EventsPage-number">Коновалюк Татьяна Сергеевна</li>
			<li class="EventsPage-number">Крохалева Карина Андреевна</li>
			<li class="EventsPage-number">Махлай Юлиана Сергеева</li>
			<li class="EventsPage-number">Марутина Мария Ильинична</li>
			<li class="EventsPage-number">Москвитина Карина Васильевна</li>
			<li class="EventsPage-number">Непокрытова Александра Владимировна</li>
			<li class="EventsPage-number">Пекарева Полина Евгеньевна</li>
			<li class="EventsPage-number">Семенова Юлия Геннадьевна</li>
			<li class="EventsPage-number">Таксаторова Александра Радиевна</li>
			<li class="EventsPage-number">Фефелова Василиса Владимировна</li>
			<li class="EventsPage-number">Цыганкова Дарья Павловна</li>
			<li class="EventsPage-number">Шишкина Анастасия Михайловна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>