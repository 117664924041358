<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-3">
			Еженедельный курс
		</h2>
		<h3 class="EventsCard-title">
			«Танцевальная импровизация»
		</h3>
		<p class="EventsCard-sub-title">
			с 15 февраля по 16 апреля 2024 года
		</p>
	</div>
</template>