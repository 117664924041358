<template>
	<div class="Preparation _cover">
		<h2 class="Preparation-title _cover-title">
			«Подготовка к олимпиаде» 2022-2023 г.
		</h2>
		<a class="temp-btn" @click="$router.push({ name: 'AllRussian' })">
			ВСОШ
		</a>
		<h3 class="Preparation-sub-title">
			Задания школьного этапа ВСОШ
		</h3>
		<p class="Preparation-dist">
			На образовательном сайте «Сириус.Курсы» собраны варианты заданий школьного этапа олимпиады.<br>
			Выбирайте класс и предмет, знакомьтесь с вариантами, решайте и отправляйте на проверку.<br>
			Подробнее: <a style="color: blue;" href="https://edu.sirius.online/vos/">https://edu.sirius.online/vos/</a><br>
		</p>
		<h3 class="Preparation-sub-title">
			Дистанционные курсы по подготовке к ВСОШ
		</h3>
		<p class="Preparation-dist">
			К участию приглашаются обучающиеся Иркутской области.<br>
			Формат проведения курсов – дистанционный, объем – 32 часа, ориентировочная дата начала – 18 октября 2023 года.<br>
		</p>
		<a class="Preparation-btn temp-btn" @click="$router.push({ name: 'EventsPage1' })">
			Дистанционные курсы «Подготовка обучающихся к участию во ВСОШ»
		</a>
		<h2 class="Preparation-title _cover-title">
			Интервью с призерами заключительного этапа ВСОШ
		</h2>
		<div class="Preparation-iframe-box">
			<iframe class="Preparation-iframe" width="560" height="315" src="https://www.youtube.com/embed/6FQpwX4tSos?si=pbNWmYYxW_7HzCE2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315" src="https://www.youtube.com/embed/d2LlTjzGK74?si=NIqyeyA6s6iLrQhA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
	</div>
</template>