<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Самбо»
		</h2>
		<p class="EventsPage-date">
			с 01 по 14 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24900-programma-sambo" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит профильную смену «Самбо» для обучающихся 5-11-х классов
			общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Самбо — относительно молодой, но довольно популярный и интенсивно развивающийся вид спортивного единоборства.
			Основанием технического арсенала самбо служит комплекс наиболее эффективных приёмов защиты и нападения, отобранных
			из различных видов боевых искусств и национальной борьбы многих народов мира. Число приёмов в арсенале самбо
			непрерывно прирастает по мере развития этого вида спортивного единоборства.
		</p>

		<p class="EventsPage-text">
			Философия самбо подразумевает, что самбо — не только вид спортивного единоборства и система противодействия
			противнику без применения оружия, но и система воспитания, способствующая развитию морально-волевых качеств.
		</p>

		<p class="EventsPage-text">
			Программа направлена на усовершенствование технико-тактических навыков спортсменов, подготовку к соревнованиям по
			самбо.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Участникам, прошедших на профильную смену необходимо подать заявку на платформе Навигатор дополнительного
				образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели профильной смены:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Костенко Алексей Владимирович, педагог дополнительного образования, тренер-преподаватель ГКУДО Спортивная школа
				по самбо;
			</li>
			<li class="JuniorProfi-items">
				Борхонов Семён Вячеславович, педагог дополнительного образования, тренер-преподаватель ГКУДО Спортивная школа по
				самбо;
			</li>
			<li class="JuniorProfi-items">
				Ольховой Илья Валерьевич, педагог дополнительного образования, тренер-преподаватель ГКУДО Спортивная школа по
				самбо;
			</li>
			<li class="JuniorProfi-items">
				Ожиганов Александр Евгеньевич, педагог дополнительного образования, тренер-преподаватель ГКУДО Спортивная школа
				по самбо;
			</li>
			<li class="JuniorProfi-items">
				Мошонов Григорий Владимирович, педагог дополнительного образования, тренер-преподаватель ГКУДО Спортивная школа
				по самбо.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/HMTnnH2Wd3zUMg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Капустина Екатерина Станиславна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: e.kapustina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>