<template>
	<div class="AdditionalGneral _cover">
		<h2 class="AdditionalGneral-title _cover-title">Перечень реализуемых дополнительных общеразвивающих программ</h2>

		<ul class="AdditionalGneral-list">
			<li class="AdditionalGneral-list-items">
				<router-link to="/AdditionalGneral2023" class="AdditionalGneral-list-items-link">
					2023 год
				</router-link>
				</li>
			<li class="AdditionalGneral-list-items">
				<router-link to="/AdditionalGneral2024" class="AdditionalGneral-list-items-link">
					2024 год
				</router-link>
			</li>
		</ul>
	</div>
</template>
