<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дополнительная профессиональная программа повышения квалификации
		</h3>
		<h2 class="EventsPage-title">
			«Проектирование программ отдыха детей и их оздоровления»
		</h2>
		<hr>

		<p class="EventsPage-text">
			Целью курса является совершенствование профессиональной компетенции слушателей по проектированию программы отдыха
			детей и их оздоровления.
		</p>

		<p class="EventsPage-text">
			Объем программы: 72 часа
		</p>

		<p class="EventsPage-text">
			Категория слушателей: руководители организаций отдыха детей и их оздоровления
		</p>

		<p class="EventsPage-text">
			Аннотация: участники курсовой подготовки познакомятся с нормативно-правовыми основами организации отдыха детей и
			их оздоровления, изучат современные методы и средства воспитания и ознакомятся со структурой и содержанием
			программы отдыха детей и их оздоровления в рамках современных требований
		</p>

		<p class="EventsPage-text">
			По итогам изучения курса слушатели разработают программу организации отдыха детей для своего учреждения.
		</p>

		<p class="EventsPage-text">
			Слушатели, успешно завершившие курсовую подготовку, получают удостоверение установленного образца о повышении
			квалификации.
		</p>

		<p class="EventsPage-text">
			<b>Обучение проходит в очно-заочной форме с использованием дистанционных образовательных ресурсов на платформе
				Мудл (Moodle)</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели дистанционного курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских инициатив
				образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Соболева Елена Владимировна, заведующий учебно-методическим отделом образовательного центра "Персей"
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Соловьева Дарья Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: d.soloveva@perseusirk.ru
			</li>
		</ul>

	</div>
</template>