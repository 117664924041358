<template>
	<div class="NewsPage _cover">
		<h2 class="NewsPage-title _cover-title">
			Новости
		</h2>
		<div class="grid-container">

			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage78' })">
					<news-card78></news-card78>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage77' })">
					<news-card77></news-card77>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage75' })">
					<news-card75></news-card75>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage76' })">
					<news-card76></news-card76>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage74' })">
					<news-card74></news-card74>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage73' })">
					<news-card73></news-card73>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage72' })">
					<news-card72></news-card72>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage71' })">
					<news-card71></news-card71>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage70' })">
					<news-card70></news-card70>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage69' })">
					<news-card69></news-card69>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage68' })">
					<news-card68></news-card68>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage67' })">
					<news-card67></news-card67>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage66' })">
					<news-card66></news-card66>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage65' })">
					<news-card65></news-card65>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage64' })">
					<news-card64></news-card64>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage63' })">
					<news-card63></news-card63>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage62' })">
					<news-card62></news-card62>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage61' })">
					<news-card61></news-card61>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage60' })">
					<news-card60></news-card60>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage59' })">
					<news-card59></news-card59>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage58' })">
					<news-card58></news-card58>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage57' })">
					<news-card57></news-card57>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage56' })">
					<news-card56></news-card56>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage55' })">
					<news-card55></news-card55>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage54' })">
					<news-card54></news-card54>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage52' })">
					<news-card52></news-card52>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage51' })">
					<news-card51></news-card51>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage50' })">
					<news-card50></news-card50>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage49' })">
					<news-card49></news-card49>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage53' })">
					<news-card53></news-card53>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage48' })">
					<news-card48></news-card48>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage47' })">
					<news-card47></news-card47>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage46' })">
					<news-card46></news-card46>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage45' })">
					<news-card45></news-card45>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage44' })">
					<news-card44></news-card44>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage43' })">
					<news-card43></news-card43>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage42' })">
					<news-card42></news-card42>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage41' })">
					<news-card41></news-card41>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage40' })">
					<news-card40></news-card40>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage39' })">
					<news-card39></news-card39>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage38' })">
					<news-card38></news-card38>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage37' })">
					<news-card37></news-card37>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage36' })">
					<news-card36></news-card36>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage35' })">
					<news-card35></news-card35>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage34' })">
					<news-card34></news-card34>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage33' })">
					<news-card33></news-card33>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage32' })">
					<news-card32></news-card32>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage31' })">
					<news-card31></news-card31>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage30' })">
					<news-card30></news-card30>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage29' })">
					<news-card29></news-card29>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage28' })">
					<news-card28></news-card28>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage27' })">
					<news-card27></news-card27>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage26' })">
					<news-card26></news-card26>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage25' })">
					<news-card25></news-card25>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage24' })">
					<news-card24></news-card24>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage23' })">
					<news-card23></news-card23>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage22' })">
					<news-card22></news-card22>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage21' })">
					<news-card21></news-card21>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage20' })">
					<news-card20></news-card20>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage19' })">
					<news-card19></news-card19>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage18' })">
					<news-card18></news-card18>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage17' })">
					<news-card17></news-card17>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage16' })">
					<news-card16></news-card16>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage15' })">
					<news-card15></news-card15>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage14' })">
					<news-card14></news-card14>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage13' })">
					<news-card13></news-card13>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage12' })">
					<news-card12></news-card12>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage11' })">
					<news-card11></news-card11>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage10' })">
					<news-card10></news-card10>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage9' })">
					<news-card9></news-card9>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage8' })">
					<news-card8></news-card8>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage7' })">
					<news-card7></news-card7>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage6' })">
					<news-card6></news-card6>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage5' })">
					<news-card5></news-card5>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage4' })">
					<news-card4></news-card4>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage3' })">
					<news-card3></news-card3>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage2' })">
					<news-card2></news-card2>
				</a>
			</div>
			<div class="grid-element">
				<a class="News-items-link" @click="$router.push({ name: 'NewsPage1' })">
					<news-card1></news-card1>
				</a>
			</div>

		</div>

			<a class="GetIn-link temp-btn" href="https://starsite.perseusirk.ru/news/" target="_blank">Архив новостей</a>
	</div>
</template>

<script>
import NewsCard1 from '@/NewsCard/NewsCard1';
import NewsCard2 from '@/NewsCard/NewsCard2';
import NewsCard3 from '@/NewsCard/NewsCard3';
import NewsCard4 from '@/NewsCard/NewsCard4';
import NewsCard5 from '@/NewsCard/NewsCard5';
import NewsCard6 from '@/NewsCard/NewsCard6';
import NewsCard7 from '@/NewsCard/NewsCard7';
import NewsCard8 from '@/NewsCard/NewsCard8';
import NewsCard9 from '@/NewsCard/NewsCard9';
import NewsCard10 from '@/NewsCard/NewsCard10';
import NewsCard11 from '@/NewsCard/NewsCard11';
import NewsCard12 from '@/NewsCard/NewsCard12';
import NewsCard13 from '@/NewsCard/NewsCard13';
import NewsCard14 from '@/NewsCard/NewsCard14';
import NewsCard15 from '@/NewsCard/NewsCard15';
import NewsCard16 from '@/NewsCard/NewsCard16';
import NewsCard17 from '@/NewsCard/NewsCard17';
import NewsCard18 from '@/NewsCard/NewsCard18';
import NewsCard19 from '@/NewsCard/NewsCard19';
import NewsCard20 from '@/NewsCard/NewsCard20';
import NewsCard21 from '@/NewsCard/NewsCard21';
import NewsCard22 from '@/NewsCard/NewsCard22';
import NewsCard23 from '@/NewsCard/NewsCard23';
import NewsCard24 from '@/NewsCard/NewsCard24';
import NewsCard25 from '@/NewsCard/NewsCard25';
import NewsCard26 from '@/NewsCard/NewsCard26';
import NewsCard27 from '@/NewsCard/NewsCard27';
import NewsCard28 from '@/NewsCard/NewsCard28';
import NewsCard29 from '@/NewsCard/NewsCard29';
import NewsCard30 from '@/NewsCard/NewsCard30';
import NewsCard31 from '@/NewsCard/NewsCard31';
import NewsCard32 from '@/NewsCard/NewsCard32';
import NewsCard33 from '@/NewsCard/NewsCard33';
import NewsCard34 from '@/NewsCard/NewsCard34';
import NewsCard35 from '@/NewsCard/NewsCard35';
import NewsCard36 from '@/NewsCard/NewsCard36';
import NewsCard37 from '@/NewsCard/NewsCard37';
import NewsCard38 from '@/NewsCard/NewsCard38';
import NewsCard39 from '@/NewsCard/NewsCard39';
import NewsCard40 from '@/NewsCard/NewsCard40';
import NewsCard41 from '@/NewsCard/NewsCard41';
import NewsCard42 from '@/NewsCard/NewsCard42';
import NewsCard43 from '@/NewsCard/NewsCard43';
import NewsCard44 from '@/NewsCard/NewsCard44';
import NewsCard45 from '@/NewsCard/NewsCard45';
import NewsCard46 from '@/NewsCard/NewsCard46';
import NewsCard47 from '@/NewsCard/NewsCard47';
import NewsCard48 from '@/NewsCard/NewsCard48';
import NewsCard53 from '@/NewsCard/NewsCard53';
import NewsCard49 from '@/NewsCard/NewsCard49';
import NewsCard50 from '@/NewsCard/NewsCard50';
import NewsCard51 from '@/NewsCard/NewsCard51';
import NewsCard52 from '@/NewsCard/NewsCard52';
import NewsCard54 from '@/NewsCard/NewsCard54';
import NewsCard55 from '@/NewsCard/NewsCard55';
import NewsCard56 from '@/NewsCard/NewsCard56';
import NewsCard57 from '@/NewsCard/NewsCard57';
import NewsCard58 from '@/NewsCard/NewsCard58';
import NewsCard59 from '@/NewsCard/NewsCard59';
import NewsCard60 from '@/NewsCard/NewsCard60';
import NewsCard61 from '@/NewsCard/NewsCard61';
import NewsCard62 from '@/NewsCard/NewsCard62';
import NewsCard63 from '@/NewsCard/NewsCard63';
import NewsCard64 from '@/NewsCard/NewsCard64';
import NewsCard65 from '@/NewsCard/NewsCard65';
import NewsCard66 from '@/NewsCard/NewsCard66';
import NewsCard67 from '@/NewsCard/NewsCard67';
import NewsCard68 from '@/NewsCard/NewsCard68';
import NewsCard69 from '@/NewsCard/NewsCard69';
import NewsCard70 from '@/NewsCard/NewsCard70';
import NewsCard71 from '@/NewsCard/NewsCard71';
import NewsCard72 from '@/NewsCard/NewsCard72';
import NewsCard73 from '@/NewsCard/NewsCard73';
import NewsCard74 from '@/NewsCard/NewsCard74';
import NewsCard75 from '@/NewsCard/NewsCard75';
import NewsCard76 from '@/NewsCard/NewsCard76';
import NewsCard77 from '@/NewsCard/NewsCard77';
import NewsCard78 from '@/NewsCard/NewsCard78';

	export default {
		components: {
			NewsCard1,
			NewsCard2,
			NewsCard3,
			NewsCard4,
			NewsCard5,
			NewsCard6,
			NewsCard7,
			NewsCard8,
			NewsCard9,
			NewsCard10,
			NewsCard11,
			NewsCard12,
			NewsCard13,
			NewsCard14,
			NewsCard15,
			NewsCard16,
			NewsCard17,
			NewsCard18,
			NewsCard19,
			NewsCard20,
			NewsCard21,
			NewsCard22,
			NewsCard23,
			NewsCard24,
			NewsCard25,
			NewsCard26,
			NewsCard27,
			NewsCard28,
			NewsCard29,
			NewsCard30,
			NewsCard31,
			NewsCard32,
			NewsCard33,
			NewsCard34,
			NewsCard35,
			NewsCard36,
			NewsCard37,
			NewsCard38,
			NewsCard39,
			NewsCard40,
			NewsCard41,
			NewsCard42,
			NewsCard43,
			NewsCard44,
			NewsCard45,
			NewsCard46,
			NewsCard47,
			NewsCard48,
			NewsCard53,
			NewsCard49,
			NewsCard50,
			NewsCard51,
			NewsCard52,
			NewsCard54,
			NewsCard55,
			NewsCard56,
			NewsCard57,
			NewsCard58,
			NewsCard59,
			NewsCard60,
			NewsCard61,
			NewsCard62,
			NewsCard63,
			NewsCard64,
			NewsCard65,
			NewsCard66,
			NewsCard67,
			NewsCard68,
			NewsCard69,
			NewsCard70,
			NewsCard71,
			NewsCard72,
			NewsCard73,
			NewsCard74,
			NewsCard75,
			NewsCard76,
			NewsCard77,
			NewsCard78,
		}
	}
</script>