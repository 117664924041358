<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«ART ПЕРФОМАНС»
		</h2>
		<p class="EventsPage-date">
			с 03 июля по 16 июля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=168" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24918-programma-art-perfomans" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену
			«Art перфоманс» для обучающихся организаций дополнительного образования по направлению изобразительное искусство
			от 12 до 17 лет, заявившие в добровольном порядке свое намерение участвовать в мероприятиях смены и проявивших
			себя в творческой деятельности в области изобразительного искусства, а также, владеющих знаниями и навыками основ
			построения рисунка.
		</p>

		<p class="EventsPage-text">
			Обучение по программе предполагает:
			готовность к занятиям декоративно-прикладным искусством;
			наличие навыков и знаний в построении рисунка;
			наличие стремления обучаться навыкам в области театрально-сценической деятельности и актерского мастерства.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Лобыгина Наталия Николаевна, преподаватель художественно-эстетических дисциплин ГБПОУ ИО ИРКПО
			</li>
			<li class="JuniorProfi-items">
				Алексеюк Виктория Викторовна, преподаватель театральных дисциплин ГБПОУ ИО ИРКПО.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
			Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Создать личный кабинет участника (зарегистрироваться) и обязательно заполнить анкету участника на платформе
				электронной информационно-образовательной среды сайта ОЦ «Персей» до 21 июня 2024 года по ссылке:
				https://moodle.perseusirk.ru/course/view.php?id=168, где необходимо прикрепить портфолио с фотографиями
				творческих работ на свободную тему, выполненные в течение текущего учебного года:
			</li>
			<p class="EventsPage-text">
				живописная работа (А3, гуашь);
			</p>
			<p class="EventsPage-text">
				живописная работа (А3, акварель);
			</p>
			<p class="EventsPage-text">
				выразительное прочтение стихотворения или басни (видео на 1-1,5 минут).
			</p>
			<li class="JuniorProfi-items">
				Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке6
				https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24918-programma-art-perfomans.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 25 июня 2024г. в 19.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1716015146">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Бабенко Леонид Евгеньевич</li>
			<li class="EventsPage-number">Бурдуковская Алиса Сергеевна</li>
			<li class="EventsPage-number">Гранин Владислав Юрьевич</li>
			<li class="EventsPage-number">Дубленых Михаил Николаевич</li>
			<li class="EventsPage-number">Клемешова Арина Вячеславовна</li>
			<li class="EventsPage-number">Кобозов Роман Николаевич</li>
			<li class="EventsPage-number">Курдубакина Анастасия Александровна</li>
			<li class="EventsPage-number">Лобыгин Владислав Александрович</li>
			<li class="EventsPage-number">Палубисок Марина Владимировна</li>
			<li class="EventsPage-number">Пивоварова Полина Андреевна</li>
			<li class="EventsPage-number">Попова Мария Александровна</li>
			<li class="EventsPage-number">Попова Ольга Александровна</li>
			<li class="EventsPage-number">Прокофьев Прохор Алексеевич</li>
			<li class="EventsPage-number">Рженева Лада Александровна</li>
			<li class="EventsPage-number">Сахаровская Наталья Вячеславовна</li>
			<li class="EventsPage-number">Сергеева Таисия Вадимовна</li>
			<li class="EventsPage-number">Серебрякова Виктория Андреевна</li>
			<li class="EventsPage-number">Сумкина Надежда Александровна</li>
			<li class="EventsPage-number">Тирских Арина Сергеевна</li>
			<li class="EventsPage-number">Токарева Арина Юрьевна</li>
			<li class="EventsPage-number">Топорова София Алексеевна</li>
			<li class="EventsPage-number">Тютрина Варвара Александровна</li>
			<li class="EventsPage-number">Улитина Диана Павловна</li>
			<li class="EventsPage-number">Ханхатова Анастасия Анатольевна</li>
			<li class="EventsPage-number">Черникова Варвара Дмитриевна</li>
			<li class="EventsPage-number">Швец Алиса Вячеславовна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>