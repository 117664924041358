<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			08.06.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» завершился еженедельный курс по основам фотосъемки
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage65.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В региональном центре выявления и поддержки одаренных детей «Персей» прошла первая еженедельная образовательная
				программа по фотографии. Обучение проходило в новой фотостудии «Персея», которая была открыта в рамках
				реализации федерального проекта «Успех каждого ребенка» президентского национального проекта «Образование».
			</p>
			<p class="NewsPage-sub-title">
				В программе приняли участие 12 школьников из Иркутска, которые успешно закончили обучение на дистанционном курсе
				в конце прошлого года. Два месяца ребята изучали основы студийной, репортажной, портретной, пейзажной и
				предметной съемки, а также осваивали основы композиции и постобработки фотографий.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали преподаватели курса, перед участниками курса стояла сложная задача: за короткий период времени
				ребята должны были изучить не только жанровые особенности фотографии, но и научится работать в ручном режиме
				съемке и выставлять свет.
			</p>
			<p class="NewsPage-sub-title">
				– Когда мы говорим о профессиональной фотографии, то мы должны понимать, что все базовые настройки при
				фотографировании выставляет сам фотограф. В фотографии есть такие понятия, как «ручной режим» съемки и «авто
				режимы». Во втором случае фотоаппарат сам решает, какие настройки экспозиции будут применены, фотографу только
				остается сделать кадр. А в «ручном режиме» приходится самостоятельно выставлять выдержку, диафрагму и ISO.
				Сложность в том, что все они отвечают за определенную функцию при съемке: размытый или четкий фон, фиксация
				движения. Но при этом между собой они взаимосвязаны тем, что контролируют количество света, которое попадет на
				матрицу фотоаппарата. А как мы знаем, свет – это и есть фотография. На начальных этапах нам было важно объяснить
				ребятам эту взаимосвязь. Порой ее не могут сразу понять даже взрослые, которые пришли учиться в фотошколу, –
				рассказала Анастасия Говорова, преподаватель курса.
			</p>
			<p class="NewsPage-sub-title">
				Помимо изучения основ, школьники смогли углубить свои знания в жанровом разнообразии фотографии. Во время
				обучения ребята провели профессиональные съемки в студии при искусственном освещении, фотопрогулки и съемки на
				улице в условиях естественного освещения. Им удалось отработать событийный и повседневный репортаж, а также
				предметную фотографию и пейзажную съемку.
			</p>
			<p class="NewsPage-sub-title">
				– Наш курс был построен на получении практического опыта. Когда фотограф только начинает свои путь, ему важно
				понять, какой жанр ему ближе. Поэтому, когда мы изучали жанры, ребята приобрели практический опыт съемок:
				понимали, хотят ли они работать с моделями, ближе работа на мероприятиях или в уединении с природой. Также они
				смогли понять, как им выстраивать свои дальнейший творческий путь в выбранном направлении, с чего начинать и с
				какими трудностями они могут столкнуться и как их решить, – пояснил Сергей Хранивский, преподаватель курса.
			</p>
			<p class="NewsPage-sub-title">
				Помимо этого, школьники учились работать в редакторах, изучали частотное разложение цвета, основы ретуши и
				цветокоррекции. Также отдельный модуль был посвящен оборудованию фотографа и работе со студийным светом.
			</p>
			<p class="NewsPage-sub-title">
				Итогом программы стали работы учеников, которые можно посмотреть в сообществе Образовательного центра «Персей»
				Вконтакте: <a class="NewsPage-link"
					href="https://vk.com/album-195976317_301868632">https://vk.com/album-195976317_301868632</a>
			</p>
			<p class="NewsPage-sub-title">
				Следующей частью обучения станет еженедельная программа, где ребята смогут отработать навыки студийной и
				портретной съемки в формате фотодней. Также для участников курса пройдут воркшопы и мастер-классы по работе со
				светом, моделями и продюсированию съемок.
			</p>

		</div>
	</div>
</template>
