<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage42.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Более 30 тысяч школьников Приангарья в 2024 году смогут принять участие в мероприятиях «Персея»
			</h2>
			<p class="NewsCard-date">
				15.01.2024
			</p>
		</div>
	</div>
</template>