<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Страна железных дорог»
		</h2>
		<p class="EventsPage-date">
			с 10 марта по 23 марта 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21392-programma-strana-zheleznykh-dorog%20" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>
		<div class="EventsPage-disc">
			<h2 class="EventsPage-prof">
				О программе
			</h2>
			<h3 class="EventsPage-nte">
				Контактное лицо:
			</h3>
			<p class="EventsPage-nte-dis">
				ФИО
			</p>
			<h3 class="EventsPage-nte">
				Телефон:
			</h3>
			<p class="EventsPage-nte-dis">
				Номер
			</p>
			<h3 class="EventsPage-nte">
				Почта:
			</h3>
			<p class="EventsPage-nte-dis">
				@
			</p>
		</div>
		<hr>
		<p class="EventsPage-sub-title">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого ребенка» проводит профильную смену «Страна железных дорог» для обучающихся 9-11-х классов общеобразовательных организаций Иркутской области, проявляющих интерес к техническим и естественнонаучным дисциплинам, связанным с системами связи, робототехникой, геоинформатикой и экологией.<br>
			Профильная смена состоится с 6 сентября по 19 сентября 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны).<br>
			Железнодорожная отрасль сегодня это не только пассажирские и грузовые перевозки, это стабильно функционирующая система, которая включает в себя также вопросы строительства, эксплуатации и обслуживания железнодорожного комплекса. Данная программа позволит познакомиться с инновационными разработками в рамках железных дорог, расширить спектр своих возможностей, открыть для себя перечень профессий и направлений подготовки железнодорожного вуза.<br>
		</p>
		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-sub-title">
			Скоробогатов М.Э., к.т.н. ФГБОУ ВО ИрГУПС<br>
			Федоров М.Э., старший преподаватель ФГБОУ ВО ИрГУПС<br>
			Кириллова Т.К., зав кафедрой «ИСиЗИ» ФГБОУ ВО ИрГУПС<br>
			Халетская С.А., зав. кафедрой ФиСМ ФГБОУ ВО ИрГУПС<br>
			Вихорева М.В., зав. кафедрой ЭиУЖТ ФГБОУ ВО ИрГУПС<br>
			Аршинский Л.В., профессор каф «ИСиЗИ» ФГБОУ ВО ИрГУПС<br>
			Мозолевская А.Н., ст. преподаватель каф «ИСиЗИ» ФГБОУ ВО ИрГУПС<br>
			Купитман Ю.О., ст. преподаватель каф «ИСиЗИ» ФГБОУ ВО ИрГУПС<br>
			Кармадонов В.Ю. ассистент каф «ИСиЗИ» ФГБОУ ВО ИрГУПС<br>
		</p>
		<h2 class="EventsPage-title">
			Документы:
		</h2>
		<ul class="EventsPage-list">
			<li class="EventsPage-items">
				<a class="EventsPage-link" href="https://disk.yandex.ru/d/h5OxfSmkmfiQPQ/%C2%AB%D0%A1%D1%82%D1%80%D0%B0%D0%BD%D0%B0%20%D0%B6%D0%B5%D0%BB%D0%B5%D0%B7%D0%BD%D1%8B%D1%85%20%D0%B4%D0%BE%D1%80%D0%BE%D0%B3%C2%BB%20">
					Ссылки на сертификаты
				</a>
			</li>
		</ul>
	</div>
</template>