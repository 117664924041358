<template>
	<div class="NewsCard-container">
		<div class="NewsCard-img-box">
			<img class="NewsCard-img" src="../assets/Newsimg/NewsPage64.webp" alt="Обложка новости" loading="lazy">
		</div>
		<div class="NewsCard-discription">
			<h2 class="NewsCard-title">
				Иркутские школьники вошли в число победителей Всероссийского конкурса проектов «Большие вызовы»
			</h2>
			<p class="NewsCard-date">
				05.06.2024
			</p>
		</div>
	</div>
</template>