<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Школа казачьей культуры»
		</h2>
		<p class="EventsPage-date">
			с 06 сентября по 19 сентября 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/21314-programma-shkola-kazachei-kultury" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>
		<p class="EventsPage-sub-title">
			Образовательный центр «Персей» проводит профильную смену «Школа казачьей культуры» для учащихся кадетских классов казачьей направленности общеобразовательных организаций Иркутской области в возрасте с 10 до 15 лет.<br>
			Программа «Школа казачьей культуры» ориентирована на создание системы школы выживания, патриотического воспитания, условий и предпосылок для присвоения подростком – участником программы, позиции хозяина своего Отечества и его охранителя через создание новых образцов жизни на основе возрождающейся казачьей культуры и этноса, духовности, нравственности и патриотизма.<br>
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей программы.<br>
		</p>
		<h2 class="EventsPage-title">
			Преподаватели:
		</h2>
		<p class="EventsPage-sub-title">
			Берестнев Л.П. – начальник штаба Иркутского войскового казачьего общества, казачий полковник;<br>
			О. Владимир Кузьминых — Религиовед, настоятель казачьего прихода во имя Святителя Николая Чудотворца г. Ангарска, протоиерей;<br>
			Лузан Е.Н. — Руководитель военно-патриотического клуба «Добрыня» с. Тулюшка, казак-наставник кадетского класса казачьей направленности, походный атаман окружного казачьего общества «Ангаро-Ленский казачий округ»;<br>
			Машуков А.А. – товарищ атамана Иркутского войскового казачьего общества, казачий полковник;<br>
			Минеев В.В. – руководитель военно-патриотического (военно-тактического) клуба «Диверсант» г.Ангарска;<br>
			Поляков А.С. – Заместитель атамана – начальник штаба Иркутского войскового казачьего общества, есаул;<br>
			Савостьянов А.С. — Атаман Иркутского городского казачьего общества «Вознесенское», Президент ИРФСОО «Байкальская федерация айкидо», Эксперт Общественной палаты Иркутской области комиссии по делам молодежи, спорту и патриотическому воспитанию, Педагог дополнительного образования МАУ дополнительного образования города Иркутска «Станция юных натуралистов», высшая квалификационная категория;<br>
			Щербак В.Н. – художественный руководитель Муниципального Автономного учреждения городского культурного центра г. Улан-Удэ, Улан-Удэнское городское казачье общество «Верхнеудинская станица»;<br>
			Агафонова Е.Г. – педагог дополнительного образования по народному вокалу ГБУ ДО ИО ЦРТДЮ «Узорочье»;<br>
			Просвирин Э.В. – концертмейстер ВКК, музыкальный руководитель ВКК ГБУ ДО ИО ЦРТДЮ «Узорочье».<br>
			В рамках профильной смены пройдет обучение по дополнительной общеразвивающей программе в содержание которой входит знакомство со специальностями гидрометеорологической службы, выполнение гидрологических и метеорологических наблюдений, проведение экологического мониторинга.<br>
			Профильная смена состоится с 08 февраля по 21 февраля 2023 года, на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны).<br>
			Подать заявку на участие можно до 29 января 2023г, результаты отбора будут опубликованы не позднее 31 января 2023г.<br>
		</p>
	</div>
</template>