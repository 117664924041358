<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Основы пленэра»
		</h2>
		<p class="EventsPage-date">
			с 15 мая по 20 июня 2023 года
		</p>
		<a class="EventsPage-btn temp-btn" href="http://moodle.center-prof38.ru/course/view.php?id=78" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/20626-programma-osnovy-plenera"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Программа составлена для детей 10-15 лет, занимающихся в организациях дополнительного образования (ДШИ,
			объединениях художественной направленности), владеющих представлениями о линейной и воздушной перспективе, о
			последовательности ведения зарисовки, этюда и имеющих первоначальные навыки передачи солнечного освещения,
			изменения локального цвета.
		</p>

		<p class="EventsPage-text">
			Учебные занятия проводятся заочно с периодичностью 2 раза в неделю по 3 часа. Итоговое занятие состоится очно 20
			июня 2023 года в кампусе образовательного центра «Персей» с представлением выставки учащихся.
		</p>

		<p class="EventsPage-text">
			По итогам обучения выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Кайгородова Елена Владимировна, преподаватель высшей категории МБУДО ДХШ№1 г. Ангарска.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться в системе Навигатор дополнительного образования Иркутской области по ссылке:
				https://р38.навигатор.дети/program/20626-programma-osnovy-plenera.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться в электронной информационно-образовательной системе на сайте ОЦ «Персей» и обязательно
				заполнить АНКЕТУ по ссылке: http://moodle.center-prof38.ru/course/view.php?id=78
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>