<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-4">
			ДПП ПК
		</h2>
		<h3 class="EventsCard-title">
			«Особенности работы с одаренными детьми в области инструментального исполнительства» (струнно-смычковые
			инструменты)
		</h3>
	</div>
</template>